import { useCallback } from 'react';
import { Edge, NodeProps, useReactFlow } from 'reactflow';
import { useUndoRedoContext } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import useConnectedEdgesAndNodes from 'pages/app-pages/chatbot-builder-page/hooks/useConnectedEdgesAndNodes';
import useSaveToServer from 'pages/app-pages/chatbot-builder-page/hooks/useSaveToServer';
import useValidateTreeStructure from 'pages/app-pages/chatbot-builder-page/hooks/useValidateTreeStructure';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { EdgeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { useDirtyStore } from 'store';
import { BaseNodeProps } from 'types';

const useDeleteNode = () => {
  const connectedEdgesAndNodes = useConnectedEdgesAndNodes();

  const saveToServer = useSaveToServer();

  const { setNodes, setEdges, getEdges, getNodes } = useReactFlow<BaseNodeProps>();

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  const { takeSnapshot, undo } = useUndoRedoContext();

  const validateTreeStructure = useValidateTreeStructure();

  const { setSelectedStepType, closeToolbox, setSelectedNodeId, selectedNodeId } = useBuilderStore(
    (state) => ({
      setSelectedStepType: state.setSelectedStepType,
      openToolbox: state.openToolbox,
      closeToolbox: state.closeToolbox,
      setActiveToolboxTab: state.setActiveToolboxTab,
      setSelectedNodeId: state.setSelectedNodeId,
      selectedNodeId: state.selectedNodeId,
    }),
  );

  return useCallback(
    async ({ id }: { id: NodeProps['id'] }) => {
      // eslint-disable-next-line no-console
      takeSnapshot();

      setIsDirty(false);
      // Get the index of the deleted node in the allNodes array
      const currentNode = getNodes().find((node) => node.id === id);

      const sourceEdges = getEdges().filter((edge) => edge.source === id);
      const targetEdges = getEdges().filter((edge) => edge.target === id);

      const currentNodeStepType = currentNode?.data.selectedStep;

      const currentNodeEdgeType = currentNodeStepType
        ? StepsConfig[currentNodeStepType].edgeType
        : undefined;

      const isStepCategoryIsMultiNode = currentNodeEdgeType === EdgeType.MultiEdge;

      //  find all next connected nodes and edges
      const nextConnectedEdgesAndNodes = connectedEdgesAndNodes(currentNode?.id || '');
      const nextConnectedNodes = nextConnectedEdgesAndNodes.nodes;
      const nextConnectedEdges = nextConnectedEdgesAndNodes.edges;

      if (sourceEdges.length === 1 && targetEdges.length === 1) {
        const edgesToRemove = sourceEdges.map((e) => e.id).concat(targetEdges.map((e) => e.id));

        const newEdge: Edge = {
          id: `${targetEdges[0].source}->${sourceEdges[0].target}`,
          source: targetEdges[0].source,
          target: sourceEdges[0].target,
          animated: targetEdges[0].animated,
          label: targetEdges[0].label,
          type: targetEdges[0].type,
        };

        // set edges - remove edgesToRemove and add newEdge
        setEdges((es) => {
          const filteredEdges = es.filter((e) => !edgesToRemove.includes(e.id));
          return [...filteredEdges, newEdge];
        });

        setNodes(
          (ns) =>
            ns
              .map((node) => {
                // Skip the node being deleted for direct modification, it will be filtered out later.
                if (node.id === id) {
                  return node;
                }

                // Deep copy to avoid mutating original state directly
                const newData = JSON.parse(JSON.stringify(node.data));

                // Directly check and update nodeId in formData, if it exists and matches the id of the node being deleted.
                if (newData.formData?.nodeId === id) {
                  newData.formData.nodeId = sourceEdges[0].target; // Keep as sourceEdges[0].target
                }

                // Check and update nodeId in menu items, if menu exists and any menuItem's nodeId matches the id of the node being deleted.
                if (newData.formData?.menu) {
                  newData.formData.menu = newData.formData.menu.map(
                    (menuItem: { nodeId: string }) => {
                      if (menuItem.nodeId === id) {
                        return { ...menuItem, nodeId: sourceEdges[0].target }; // Keep as sourceEdges[0].target
                      }
                      return menuItem;
                    },
                  );
                }

                return { ...node, data: newData };
              })
              .filter((n) => n.id !== id), // Finally, filter out the node that is being deleted.
        ); // This removes the deleted node      } else if (sourceEdges.length === 0 && targetEdges.length === 1) {
        setNodes((ns) =>
          ns.map((n) => {
            if (n.id === id) {
              return {
                ...n,
                data: {
                  ...n.data,
                  selectedStep: null,
                  formData: undefined,
                },
              };
            }
            return n;
          }),
        );
      }
      // if last node is deleted convert it to selectedStep: null and make the source edge of type default
      else if (sourceEdges.length === 0) {
        setNodes((ns) =>
          ns.map((n) => {
            if (n.id === id) {
              return {
                ...n,
                data: {
                  ...n.data,
                  selectedStep: null,
                  formData: undefined,
                },
              };
            }
            return n;
          }),
        );
      } else if (isStepCategoryIsMultiNode) {
        setNodes((ns) =>
          ns
            .map((n) => {
              if (n.id === id) {
                return {
                  ...n,
                  data: {
                    ...n.data,
                    selectedStep: null,
                    formData: undefined,
                  },
                };
              }
              return n;
            })
            .filter((n) => !nextConnectedNodes.includes(n)),
        );

        setEdges((es) => es.filter((e) => !nextConnectedEdges.includes(e)));
      }

      if (selectedNodeId === id) {
        setSelectedNodeId(null);
        closeToolbox();
        setSelectedStepType(null);
      }

      if (!validateTreeStructure()) {
        undo();
        return;
      }

      await saveToServer(); // You wait for the promise to resolve here
    },
    [
      takeSnapshot,
      setIsDirty,
      getNodes,
      getEdges,
      connectedEdgesAndNodes,
      selectedNodeId,
      validateTreeStructure,
      saveToServer,
      setEdges,
      setNodes,
      setSelectedNodeId,
      closeToolbox,
      setSelectedStepType,
      undo,
    ],
  );
};

export default useDeleteNode;
