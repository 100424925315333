import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormSection, FormTextField, FormTextFieldInlineEdit } from '@common-components';

import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import {
  QuizSummaryFormFields,
  QuizSummaryFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/summary/types';
import {
  QuizSummaryFieldsConfig,
  quizSummaryFormSchema,
  quizSummaryFormValues,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/summary/utils';

import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface QuizSummaryProps {
  id: string;
  defaultValues?: Partial<QuizSummaryFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}

const QuizSummary = ({ defaultValues, mentions, commands, id }: QuizSummaryProps) => {
  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...quizSummaryFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    menu: isEmpty(defaultValues?.menu)
      ? [
          { option: 'Winner', nodeId: uuid() },
          { option: 'Loser', nodeId: uuid() },
        ]
      : defaultValues?.menu,
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<QuizSummaryFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<QuizSummaryFormFields>({
    schema: quizSummaryFormSchema,
    defaultValues: localDefaultValues,
  });

  const { setValue, getValues, watch, reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const passScoreValue = watch(QuizSummaryFormFieldsNames.PassScore);

  useEffect(() => {
    const currentMenu = getValues(QuizSummaryFormFieldsNames.Menu);
    let newMenu = [...currentMenu];
    if (passScoreValue.toString() === '0') {
      newMenu = newMenu.filter((item) => item.option !== 'Loser');
      if (!newMenu.some((item) => item.option === 'Winner')) {
        newMenu.push({ option: 'Winner', nodeId: uuid() });
      }
    } else {
      if (!newMenu.some((item) => item.option === 'Winner')) {
        newMenu.push({ option: 'Winner', nodeId: uuid() });
      }
      if (!newMenu.some((item) => item.option === 'Loser')) {
        newMenu.push({ option: 'Loser', nodeId: uuid() });
      }
    }

    setValue(QuizSummaryFormFieldsNames.Menu, newMenu);
  }, [passScoreValue, setValue, getValues]);

  const handleOnSubmit = async (formData: QuizSummaryFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { name, labels, variable, passScore, input } = QuizSummaryFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />

          <FormVariableField {...variable} />
        </Stack>
        <FormSection
          title='Minimum Correct Answers to Pass'
          infoIcon={{
            icon: 'info',
            tooltip:
              'Provide a number between 0 and the number of questions, By providing 0, the user will be able to pass the quiz without answering any questions',
          }}
        >
          <FormTextField {...passScore} size='small' InputProps={{ inputProps: { min: 0 } }} />
        </FormSection>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons initiallyEnabled formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(QuizSummary);
