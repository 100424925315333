import { Box, Tooltip } from '@mui/material';
import { IconButton } from '@common-components';

interface StepBackButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

const StepBackButton = ({ onClick, text, disabled }: StepBackButtonProps) => (
  <Tooltip title={text} placement='top'>
    <Box>
      <IconButton edge='start' icon='arrow_back' onClick={onClick} disabled={disabled} />
    </Box>
  </Tooltip>
);

export default StepBackButton;
