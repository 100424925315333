import { AlertColor } from '@mui/material';
import { useSnackbar } from 'notistack';

interface ToastProps {
  message: string;
  variant?: AlertColor;
}
export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  return ({ message, variant = 'info' }: ToastProps) => enqueueSnackbar(message, { variant });
};
