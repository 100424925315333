import { AutoCompleteLabel } from 'types';

export enum QuizSummaryFormFieldsNames {
  Name = 'name',
  Input = 'input',
  PassScore = 'passScore',
  Variable = 'variable',
  Menu = 'menu',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export enum QuizSummaryFormFieldsMenuNames {
  Option = 'option',
  NodeId = 'nodeId',
}

export interface QuizSummaryFormFields {
  [QuizSummaryFormFieldsNames.NodeId]: string;
  [QuizSummaryFormFieldsNames.Name]: string;
  [QuizSummaryFormFieldsNames.Menu]: QuizSummaryFormFieldsMenu[];
  [QuizSummaryFormFieldsNames.Input]?: string;
  [QuizSummaryFormFieldsNames.PassScore]: number;
  [QuizSummaryFormFieldsNames.Variable]?: string;
  [QuizSummaryFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export interface QuizSummaryFormFieldsMenu {
  [QuizSummaryFormFieldsMenuNames.Option]: string;
  [QuizSummaryFormFieldsMenuNames.NodeId]: string;
}
