import { Stack, Tooltip, tooltipClasses } from '@mui/material';
import { Image } from 'mui-image';

const tooltipImageHeight = 160;

interface ImagePreviewProps {
  imageUrl: string;
  size: number;
}
const ImagePreview = ({ imageUrl, size }: ImagePreviewProps) => (
  <Tooltip
    arrow
    placement='top'
    PopperProps={{
      sx: {
        [`& .${tooltipClasses.tooltip}`]: { p: 0.5 },
      },
    }}
    title={
      <Stack
        borderRadius={0.5}
        overflow='hidden'
        justifyContent='center'
        alignItems='center'
        width={tooltipImageHeight}
        height={tooltipImageHeight}
        minWidth={tooltipImageHeight}
      >
        <Image
          width={tooltipImageHeight}
          height={tooltipImageHeight}
          errorIcon={false}
          duration={0}
          fit='cover'
          src={imageUrl}
          alt='file'
        />
      </Stack>
    }
  >
    <Stack
      borderRadius={1}
      overflow='hidden'
      justifyContent='center'
      alignItems='center'
      width={size}
      height={size}
      minWidth={size}
    >
      <Image
        width={size}
        height={size}
        errorIcon={false}
        title='Preview'
        duration={0}
        fit='cover'
        src={imageUrl}
        alt='file'
      />
    </Stack>
  </Tooltip>
);
export default ImagePreview;
