import { Avatar } from '@mui/material';
import { User } from 'types';

interface AvatarIconProps extends Pick<User, 'fullName' | 'profileImageUrl'> {
  size?: number;
}
const AvatarIcon = ({ fullName, profileImageUrl, size = 38 }: AvatarIconProps) => (
  <Avatar
    alt={fullName}
    src={profileImageUrl}
    sx={{
      width: size,
      height: size,
      border: 1,
      borderColor: 'divider',
    }}
  >
    {!profileImageUrl && `TF`}
  </Avatar>
);

export default AvatarIcon;
