import { Box, Fab, FabProps, Tooltip } from '@mui/material';
import Icon from 'components/Icon';
import { useIsMobile } from 'hooks';

interface FloatingActionButtonProps extends FabProps {
  label: string;
}
const FloatingActionButton = ({ sx, label, variant, ...rest }: FloatingActionButtonProps) => {
  const { isSmallMobile } = useIsMobile();
  const localVariant = isSmallMobile ? 'circular' : 'extended';
  return (
    <Box sx={{ ...sx }}>
      <Tooltip title={isSmallMobile ? label : ''} placement='top' arrow>
        <Fab
          variant={variant || localVariant}
          color='primary'
          aria-label='add'
          sx={{
            pr: isSmallMobile ? 0 : 3,
          }}
          {...rest}
        >
          <Icon icon='add' sx={{ mr: isSmallMobile ? 0 : 1 }} />
          {isSmallMobile ? '' : label}
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default FloatingActionButton;
