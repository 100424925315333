import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  OpenEndQuestionFormFields,
  OpenEndQuestionFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/open-end-question/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue, variableValidation } from 'utils';

export const openEndQuestionFormSchema: ObjectSchema<OpenEndQuestionFormFields> = object({
  [OpenEndQuestionFormFieldsNames.NodeId]: string().required(),
  [OpenEndQuestionFormFieldsNames.Name]: string().required('Name is required'),
  [OpenEndQuestionFormFieldsNames.Input]: editorValidation,
  [OpenEndQuestionFormFieldsNames.Variable]: variableValidation,
  [OpenEndQuestionFormFieldsNames.Answer]: string().required('Answer is required'),
  [OpenEndQuestionFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultOpenEndQuestionFormValues: OpenEndQuestionFormFields = {
  [OpenEndQuestionFormFieldsNames.NodeId]: '',
  [OpenEndQuestionFormFieldsNames.Name]: 'Quiz Open-End Question',
  [OpenEndQuestionFormFieldsNames.Input]: emptyEditorValue,
  [OpenEndQuestionFormFieldsNames.Answer]: '',
  [OpenEndQuestionFormFieldsNames.Labels]: [],
};

export const openEndQuestionFormFieldsConfig: Record<
  OpenEndQuestionFormFieldsNames,
  FormFieldProps
> = {
  [OpenEndQuestionFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: OpenEndQuestionFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [OpenEndQuestionFormFieldsNames.Input]: {
    placeholder: '* Question',
    name: OpenEndQuestionFormFieldsNames.Input,
    type: 'texarea',
  },
  [OpenEndQuestionFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: OpenEndQuestionFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [OpenEndQuestionFormFieldsNames.Answer]: {
    label: '* Answer',
    placeholder: 'Answer for the question',
    name: OpenEndQuestionFormFieldsNames.Answer,
    autoComplete: 'off',
    type: 'text',
  },

  [OpenEndQuestionFormFieldsNames.NodeId]: {
    name: OpenEndQuestionFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [OpenEndQuestionFormFieldsNames.Labels]: labelFormFieldConfig,
};
