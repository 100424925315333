import { Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useBoolean, useLocalStorage } from 'usehooks-ts';
import { BasicModal, FormTextField, SuccessMessage } from '@common-components';
import { api, ApiKey } from 'api';
import { LocalStorageKey } from 'config';
import { useAuth, useFormProvider, useServerError } from 'hooks';
import { useDirtyStore } from 'store';
import { User } from 'types';
import { CouponFormFields } from './types';
import { couponFormFieldsConfig, couponSchema, defaultCouponFormValues } from './utils';

const ApplyCouponModal = () => {
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const { coupon } = couponFormFieldsConfig;
  const navigate = useNavigate();
  const formMethods = useFormProvider<CouponFormFields>({
    reValidateMode: 'onChange',
    schema: couponSchema,
    defaultValues: defaultCouponFormValues,
  });
  const toastServerError = useServerError();
  const { handleSubmit, formState } = formMethods;
  const [showCoupon, setShowCoupon] = useLocalStorage(LocalStorageKey.ShowCoupon, false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { setUser } = useAuth();

  const {
    value: isApplyingCoupon,
    setTrue: setIsApplyingCoupon,
    setFalse: setIsNotApplyingCoupon,
  } = useBoolean(false);

  const handleOnSubmit = async (formData: CouponFormFields) => {
    setIsDirty(false);
    setIsApplyingCoupon();
    try {
      const res = await api.post<User>(ApiKey.ApplyCoupon, formData);
      setUser?.(res.data);
      setSuccessMessage(true);
      setIsNotApplyingCoupon();
    } catch (error: any) {
      setIsNotApplyingCoupon();
      toastServerError(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        cancelText='Skip'
        cancelButton={!successMessage}
        disableEscapeKeyDown
        onSubmit={handleSubmit(handleOnSubmit)}
        title='Unlock Pro Plan Benefits 🚀'
        label='ai_prompt-dialog'
        maxWidth='sm'
        submittingText='Applying coupon...'
        mainAction={
          !successMessage
            ? {
                children: 'Apply Coupon',
                type: 'submit',
                isSubmitting: formState.isSubmitting || isApplyingCoupon,
                color: 'info',
              }
            : {
                children: 'Close',
                onClick: () => {
                  setShowCoupon(false);
                  navigate('./..', { replace: true });
                },
              }
        }
        handleClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown' && reason !== 'swipeDown') {
            setShowCoupon(false);
            navigate('./..', { replace: true });
          }
        }}
        blockCloseAttempt={(formState.isSubmitting || isApplyingCoupon) && !successMessage}
        open={showCoupon}
      >
        <Stack width={1} gap={2}>
          {successMessage ? (
            <SuccessMessage
              title='Thank You! Enjoy Your Upgrade'
              description={
                <Typography variant='body1' align='center'>
                  Success! You&apos;ve unlocked the <b>Pro Plan</b> with more chats, emails, bots,
                  and features. Get ready to explore and enjoy the full power of Teleform. Welcome
                  aboard!
                </Typography>
              }
            />
          ) : (
            <>
              <Typography mb={2} color='text.secondary' variant='body2'>
                Enter your coupon code below to activate your Teleform Pro Plan. Don&apos;t have a
                coupon code?
                <Link target='_blank' href='https://t.me/TeleformProCouponBot'>
                  {' '}
                  Get one here
                </Link>
              </Typography>
              <FormTextField {...coupon} />
            </>
          )}
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default ApplyCouponModal;
