import { useState } from 'react';
import { mutate } from 'swr';
import { IconButton, IconButtonProps } from '@common-components';
import { api, ApiKey } from 'api';
import { useServerError } from 'hooks';
import { Chat } from 'types';

interface IsImportantProps extends Pick<IconButtonProps, 'edge'> {
  id: string;
  isImportant: boolean;
}
function IsImportant({ id, isImportant, ...rest }: IsImportantProps) {
  const toastServerError = useServerError();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IconButton
      tooltipProps={{
        arrow: true,
      }}
      tooltipPlacement='top'
      tooltip={isImportant ? 'Remove from Important' : 'Mark as Important'}
      onClick={async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsLoading(true);
        await mutate(
          `${ApiKey.Chat}`,
          (data: Chat[] | undefined) => {
            if (data) {
              return data.map((chat) => {
                if (chat.id === id) {
                  return { ...chat, isImportant: !isImportant };
                }
                return chat;
              });
            }
            return data;
          },
          false,
        );
        await mutate(
          `${ApiKey.Chat}/${ApiKey.Ticket}`,
          (data: Chat[] | undefined) => {
            if (data) {
              return data.map((chat) => {
                if (chat.id === id) {
                  return { ...chat, isImportant: !isImportant };
                }
                return chat;
              });
            }
            return data;
          },
          false,
        );

        try {
          await api.patch<Chat>(`${ApiKey.Chat}/${id}`, { isImportant: !isImportant });
          // Confirm the optimistic update
          await mutate(`${ApiKey.Chat}/${id}`);
          setIsLoading(false);
        } catch (error: any) {
          // Revert the optimistic update in case of an error
          await mutate(
            `${ApiKey.Chat}`,
            (data: Chat[] | undefined) => {
              if (data) {
                return data.map((chat) => {
                  if (chat.id === id) {
                    return { ...chat, isImportant };
                  }
                  return chat;
                });
              }
              return data;
            },
            false,
          );
          setIsLoading(false);
          toastServerError(error);
        }
      }}
      loading={isLoading}
      size='large'
      icon={isImportant ? 'stars' : 'star_outline'}
      color={isImportant ? 'warning' : 'default'}
      iconProps={{
        fontSize: 'small',
        sx: { transform: isImportant ? 'translateY(0)' : 'translateY(-1px)' },
      }}
      {...rest}
    />
  );
}

export default IsImportant;
