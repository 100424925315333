import { Box, CircularProgress, Stack } from '@mui/material';
import AppIcon from './AppIcon';

const AppLoader = () => (
  <Stack justifyContent='center' alignItems='center' height={1}>
    <CircularProgress sx={{ color: 'secondary.main' }} size={60} thickness={2} />
    <Box position='absolute'>
      <AppIcon />
    </Box>
  </Stack>
);
export default AppLoader;
