import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

export default CustomColumnMenu;
