import { findLast } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useMatchNavigation<T extends Record<string, string>>(
  tabsList: T,
): T[keyof T] | undefined;
export function useMatchNavigation<T extends Record<string, string>>(
  tabsList: T,
  defaultValue: T[keyof T],
): T[keyof T];
export function useMatchNavigation<T extends Record<string, string>>(
  tabsList: T,
  defaultValue?: T[keyof T],
) {
  const location = useLocation();

  return useMemo(
    () =>
      findLast(location.pathname.split('/'), (subpath) =>
        Object.values(tabsList).includes(subpath),
      ) || defaultValue,
    [location, defaultValue, tabsList],
  );
}
