import { Box, SelectProps, Stack, Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';
import { FormSelect } from 'components/form-fields/FormSelect';
import IconButton from 'components/IconButton';
import { TooltipWrapperProps } from 'theme';
import { ObjectWithRequiredProp } from 'utils';

export interface FormSelectInlineEditProps<T>
  extends Omit<SelectProps, 'id' | 'variant'>,
    TooltipWrapperProps {
  name: string;
  textVariant?: Variant;
  icon?: string;
  text?: string;
  startAdornment?: boolean;
  options: T[];
}

const FormTextFieldInlineSelect = <
  T extends string | ObjectWithRequiredProp<'value' | 'label', string>,
>({
  name,
  textVariant = 'h6',
  icon = 'border_color',
  text = '',
  startAdornment,
  options,
  tooltip = '',
  tooltipPlacement,
  tooltipProps,
  ...rest
}: FormSelectInlineEditProps<T>) => {
  const { value: editing, setTrue: startEditing, setFalse: stopEditing } = useBoolean();

  const {
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchName = watch(name);

  const closeEditField = async () => {
    await trigger(name);
    if (!errors[name]) {
      stopEditing();
    }
  };

  return (
    <Box width={1}>
      {editing ? (
        <FormSelect
          variant='standard'
          autoFocus
          isOpen
          confirmCallBack={closeEditField}
          name={name}
          options={options}
          {...rest}
        />
      ) : (
        <Tooltip title={tooltip} placement={tooltipPlacement} arrow {...tooltipProps}>
          <Stack direction='row' alignItems='center' gap={0.5}>
            {startAdornment && <IconButton size='small' icon={icon} onClick={startEditing} />}
            <Typography
              color={!isEmpty(watchName) ? 'text.primary' : 'text.secondary'}
              noWrap
              variant={textVariant}
              onClick={startEditing}
            >
              {isEmpty(watchName) ? <em>{text}</em> : watchName}
            </Typography>
            {!startAdornment && <IconButton size='small' icon={icon} onClick={startEditing} />}
          </Stack>
        </Tooltip>
      )}
    </Box>
  );
};

export default FormTextFieldInlineSelect;
