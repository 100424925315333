import { alpha, Palette } from '@mui/material/styles';

// APP COLORS
const CHATBOT = {
  lighter: '#EFE6FC', // color-info-100
  light: '#C7B1F2', // color-info-300
  main: '#9076D4', // color-info-500
  dark: '#4F3B98', // color-info-700
  darker: '#231665', // color-info-900
  contrastText: '#fff',
};

const MESSENGER = {
  lighter: '#FCE4E8', // 100
  light: '#F0ACC5', // 300
  main: '#D06DA5', // 500
  dark: '#95367F', // 700
  darker: '#631461', // 900
  contrastText: '#fff',
};

const BULK = {
  lighter: '#D7FBE3', // color-success-100
  light: '#85E8BB', // color-success-300
  main: '#34B593', // color-success-600
  dark: '#1A827B', // color-success-700
  darker: '#094D56', // color-success-900
  contrastText: '#fff',
};

// EXTRA COLORS
const PURPLE = {
  lighter: '#FCE3D9', // 100
  light: '#F1958D', // 300
  main: '#D1414F', // 500
  dark: '#962041', // 700
  darker: '#640C35', // 900
  contrastText: '#fff',
};

const RED = {
  lighter: '#FFE9D8', // 100
  light: '#FFAC8A', // 300
  main: '#DB362C', // 500
  dark: '#B71E22', // 700
  darker: '#7A0B1F', // 900
  contrastText: '#fff',
};

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  50: '#F5F6F8',
  100: '#EBECEE',
  200: '#D5DADD',
  300: '#AAB4BE',
  400: '#8396A7',
  500: '#5D738B',
  600: '#485E75',
  700: '#354A5F',
  800: '#22344A',
  900: '#1A2632',
  A100: '#EBECEE',
  A200: '#D5DADD',
  A400: '#8396A7',
  A700: '#354A5F',
};

const PRIMARY = {
  lighter: '#DBEFFC', // 100
  light: '#91C3F2', // 300
  main: '#4784D4', // 500
  dark: '#234C98', // 700
  darker: '#0D2465', // 900
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#EFE6FC', // color-info-100
  light: '#C7B1F2', // color-info-300
  main: '#9076D4', // color-info-500
  dark: '#4F3B98', // color-info-700
  darker: '#231665', // color-info-900
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D7FCFC', // color-info-100
  light: '#87E2F1', // color-info-300
  main: '#39A7D1', // color-info-500
  dark: '#1C6496', // color-info-700
  darker: '#0A3364', // color-info-900
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#F3FBDE', // color-success-100
  light: '#CBEA98', // color-success-300
  main: '#86B94D', // color-success-500
  dark: '#4F8526', // color-success-700
  darker: '#27580E', // color-success-900
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FEF6D8', // color-warning-100
  light: '#F9DC8B', // color-warning-300
  main: '#EEB43F', // color-warning-500
  dark: '#AB731F', // color-warning-700
  darker: '#72420C', // color-warning-900
  contrastText: '#fff',
};

const ERROR = {
  lighter: '#FFEAD5', // 100
  light: '#FFB081', // 300
  main: '#E0533E', // 500
  dark: '#B72516', // 700
  darker: '#7A080E', // 900
  contrastText: '#fff',
};

// @ts-ignore
const palette: Palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  purple: PURPLE,
  red: RED,
  chatbot: CHATBOT,
  messenger: MESSENGER,
  bulk: BULK,
  divider: alpha('#000', 0.12),
  text: {
    primary: GREY[900],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: '#fff',
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.04,
    disabledOpacity: 0.48,
    selectedOpacity: 0.08,
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};

export default palette;
