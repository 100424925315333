import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LocalStorageKey } from 'config';

export enum LayoutDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
export interface LayoutDirectionStore {
  layoutDirection: LayoutDirection;
  setLayoutDirection: (direction: LayoutDirection) => void;
  toggleLayoutDirection: () => void;
}

export const useLayoutDirection = create(
  persist<LayoutDirectionStore>(
    (set, get) => ({
      layoutDirection: get()?.layoutDirection || LayoutDirection.Horizontal,
      setLayoutDirection: (direction) => set({ layoutDirection: direction }),
      toggleLayoutDirection: () =>
        set((state) => ({
          layoutDirection:
            state.layoutDirection === LayoutDirection.Horizontal
              ? LayoutDirection.Vertical
              : LayoutDirection.Horizontal,
        })),
    }),

    {
      name: LocalStorageKey.BuilderLayoutDirection,
    },
  ),
);
