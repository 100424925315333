import { object, ObjectSchema, string } from 'yup';
import { FormFieldProps } from 'types';
import { AiFormFields, AiFormFieldsNames } from './types';

export const aiSchema: ObjectSchema<AiFormFields> = object({
  [AiFormFieldsNames.AiPrompt]: string()
    .min(
      10,
      'To analyze and build your bot accurately, the AI needs a description of at least 10 characters.',
    )
    .required('AI Prompt is required'),
});

export const defaultAiFormValues: AiFormFields = {
  [AiFormFieldsNames.AiPrompt]: '',
};

export const aiFormFieldsConfig: Record<AiFormFieldsNames, FormFieldProps> = {
  [AiFormFieldsNames.AiPrompt]: {
    label: 'Describe Your Dream Chatbot',
    placeholder:
      "Tell us what your bot should do, like 'A customer support bot that answers questions about our products'",
    name: AiFormFieldsNames.AiPrompt,
    autoComplete: 'off',
    type: 'text',
  },
};
