import { GridRenderCellParams } from '@mui/x-data-grid';

import StatusForm from 'pages/activity-page/status/StatusForm';
import { ChatTableProps } from 'types';

const StatusWrapper = ({ row }: GridRenderCellParams<ChatTableProps>) => {
  const defaultValues = row.status;

  return <StatusForm id={row.id} key={defaultValues} defaultValues={defaultValues} />;
};

export default StatusWrapper;
