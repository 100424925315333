import { AutoCompleteLabel, UserDisplay } from 'types';

export enum HumanHandoffStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  NotificationMessage = 'notificationMessage',
  Labels = 'labels',
  NotifyList = 'notifyList',
}

export interface HumanHandoffStepFormFields {
  [HumanHandoffStepFormFieldsNames.Name]: string;
  [HumanHandoffStepFormFieldsNames.Input]: string;
  [HumanHandoffStepFormFieldsNames.NotificationMessage]: string;
  [HumanHandoffStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
  [HumanHandoffStepFormFieldsNames.NotifyList]?: UserDisplay[];
}
