import { alpha, Box, iconClasses, Menu, MenuItem } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import IconButton from 'components/IconButton';
import { useMenu } from 'hooks';
import { menuMinWidth } from 'theme/utils';

export interface FormColorPickerProps {
  name: string;
  options: string[];
  label?: string;
}
export const FormColorPicker = (props: FormColorPickerProps) => {
  const { name, options, label, ...rest } = props;
  const { control, watch } = useFormContext();
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();

  const color = watch(name);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
        <>
          <IconButton
            tooltip={label}
            tooltipPlacement='top'
            ref={ref}
            size='large'
            sx={{
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
              bgcolor: alpha(color, 0.12),
              [`& .${iconClasses.root}`]: {
                color,
              },
              '&:hover': {
                bgcolor: alpha(color, 0.24),
              },
            }}
            {...field}
            {...rest}
            onClick={openMenu}
            onKeyUp={(e) => {
              if (e.code === 'Space') {
                openMenu(e);
              }
            }}
            icon='contrast'
          />
          <Menu
            className='notranslate'
            id='demo-positioned-menu'
            aria-labelledby='demo-positioned-button'
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            slotProps={{
              paper: { sx: { minWidth: menuMinWidth, maxWidth: 280 } },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                onClick={() => {
                  onChange(option);
                  closeMenu();
                }}
              >
                <Box bgcolor={option} width={1} height={1} borderRadius={0.5}>
                  &#8205;
                </Box>
              </MenuItem>
            ))}
          </Menu>
          <FormCollapseHelperText error={error} />
        </>
      )}
    />
  );
};

export default memo(FormColorPicker);
