import { alpha, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import Icon from 'components/Icon';
import { useBuilderStore, useLayoutDirection } from 'pages/app-pages/chatbot-builder-page/store';
import { truncateMultiLineText } from 'utils';

const StartNode = () => {
  const { layoutDirection } = useLayoutDirection((state) => ({
    layoutDirection: state.layoutDirection,
  }));

  const { selectedCommand } = useBuilderStore((state) => ({
    selectedCommand: state.selectedCommand,
  }));

  return (
    <>
      <Stack
        justifyContent='center'
        alignItems='center'
        bgcolor='background.default'
        width={220}
        height={132}
        border={1}
        borderRadius={1}
        borderColor='grey.200'
        p={2}
      >
        <Stack
          borderRadius={1}
          gap={0.5}
          width={1}
          p={0.5}
          height={1}
          justifyContent='center'
          alignItems='center'
          bgcolor={(theme) => alpha(theme.palette.primary.light, 0.12)}
        >
          <Icon icon={selectedCommand?.icon} fontSize='large' color='primary' />
          <Typography
            sx={{
              ...truncateMultiLineText(1),
            }}
            textTransform='capitalize'
            variant='caption'
            fontWeight='bolder'
            color='primary'
            textAlign='center'
          >
            {selectedCommand?.name} Command Builder
          </Typography>
        </Stack>
      </Stack>
      <Handle
        type='source'
        position={layoutDirection === 'horizontal' ? Position.Right : Position.Bottom}
      />
    </>
  );
};

export default memo(StartNode);
