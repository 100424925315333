import { Box, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import {
  BasicModal,
  ConfirmationDialog,
  FormIconPicker,
  FormSwitch,
  FormTextField,
} from '@common-components';
import { api, ApiKey } from 'api';

import { useFormProvider, useIsMobile, useMenu, useServerError, useToast } from 'hooks';
import { CreateCommandFormFields } from 'pages/app-pages/chatbot-builder-page/dialogs/command/types';
import { useDeleteCommand } from 'pages/app-pages/chatbot-builder-page/dialogs/command/useDeleteCommand';
import {
  commandIconsConfig,
  createCommandFieldsConfig,
  createCommandFormValues,
  createCommandSchema,
} from 'pages/app-pages/chatbot-builder-page/dialogs/command/utils';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { SubmitButton } from 'pages/auth-pages/components';
import { useDirtyStore } from 'store';
import { Command, Project } from 'types';
import { lowerCaseUnderscoreAndDigits } from 'utils';

const CommandModal = () => {
  const { setSelectedCommand, selectedCommand, setActiveToolboxTab } = useBuilderStore((state) => ({
    setSelectedCommand: state.setSelectedCommand,
    selectedCommand: state.selectedCommand,
    setActiveToolboxTab: state.setActiveToolboxTab,
  }));
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));

  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();

  const toastServerError = useServerError();
  const toast = useToast();

  const { isMobile } = useIsMobile();

  const { openMenu: openDeletePrompt, ...restDeletePrompt } = useMenu();

  const command = location.state?.command as Command | undefined;

  const { deletingCommand, deleteCommand } = useDeleteCommand(command);

  const formMethods = useFormProvider<CreateCommandFormFields>({
    schema: createCommandSchema,
    defaultValues: command || createCommandFormValues,
  });

  const { name, icon, description, isMenuItem } = createCommandFieldsConfig;

  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = async (formData: CreateCommandFormFields) => {
    setIsDirty(false);
    try {
      if (command) {
        await api.patch<Command>(
          `${ApiKey.Project}/${id}/${ApiKey.Bot}/${ApiKey.Command}/${command.id}`,
          formData,
        );
      } else {
        await api.post<Command>(
          `${ApiKey.Project}/${id}/${ApiKey.Bot}/${ApiKey.Command}`,
          formData,
        );
      }
      const project = await mutate<Project>(`${ApiKey.Project}/${id}`);
      const newCommand = project?.bot?.draft.commands.find((c) => c.name === formData.name);
      if (!command || newCommand?.id === selectedCommand?.id) {
        setSelectedCommand(newCommand || null);
      }
      setActiveToolboxTab(ToolboxTabs.CommandsSettings);

      toast({
        message: command ? 'Command updated successfully' : 'Command created successfully',
        variant: 'success',
      });
      navigate('./../');
    } catch (error) {
      toastServerError(error);
    }
  };
  return (
    <FormProvider {...formMethods}>
      <BasicModal
        onSubmit={handleSubmit(handleOnSubmit)}
        title={command ? 'Edit Command' : 'Create Command'}
        label='create-command-dialog'
        cancelButton
        mainAction={{
          children: command ? 'Save Command' : 'Create Command',
          type: 'submit',
          isSubmitting: formState.isSubmitting,
          disabled: !formState.isDirty,
        }}
        open
        blockCloseAttempt={formState.isSubmitting}
      >
        <Stack width={1} gap={2} alignItems='flex-start'>
          <Stack
            width={1}
            direction='row'
            gap={5}
            justifyContent='space-between'
            alignItems='center'
          >
            <FormIconPicker {...icon} options={commandIconsConfig} />
            <Box mr={1.5}>
              <FormSwitch labelPlacement='start' {...isMenuItem} />
            </Box>
          </Stack>
          <FormTextField
            autoFocus={!isMobile}
            {...name}
            valueManipulator={lowerCaseUnderscoreAndDigits}
            disabled={command?.name === 'start'}
          />
          <FormTextField multiline rows={2} {...description} />
          {command && command?.name !== 'start' && (
            <SubmitButton
              variant='text'
              size='small'
              submitting={deletingCommand}
              submittingText='Deleting...'
              color='error'
              onClick={openDeletePrompt}
            >
              Delete Command
            </SubmitButton>
          )}
          <ConfirmationDialog
            text='Are you sure you want to delete this command?'
            onConfirm={deleteCommand}
            submittingText='Deleting...'
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            {...restDeletePrompt}
          />
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default CommandModal;
