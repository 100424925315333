import { Box } from '@mui/material';
import Quill from 'quill';
// @ts-ignore
import Emoji from 'quill-emoji';
// @ts-ignore
import Mention from 'quill-mention';
import React, {
  forwardRef,
  memo,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'quill-mention/dist/quill.mention.css';
import 'quill-emoji/dist/quill-emoji.css';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { useMount } from 'hooks';
import palette from 'theme/palette';
import typography from 'theme/typography';

const captionStyle = typography.caption;
const captionFontFamily = typography.fontFamily || '"Roboto", "Helvetica", "Arial", sans-serif'; // Assuming you have a global font family
const captionLineHeight = captionStyle?.lineHeight?.toString() || '1.5'; // Convert lineHeight to string with a fallback

const { fontSize } = typography.caption || {};
const formattedFontSize = typeof fontSize === 'number' ? `${fontSize}rem` : fontSize;

Quill.register('modules/mention', Mention);

Quill.register('modules/command', Mention); // Registering same module but with a different key

Quill.register('modules/emoji', Emoji);
// Quill.register('modules/emoji-shortname', Emoji.ShortName);
interface QuillEditorWithMentionProps extends ReactQuillProps {
  mentions?: Mention[];
  commands?: Mention[];
  autoFocus?: boolean; // Step 1: Add this prop
  error?: boolean;
  onEditorReady?: () => void;
  minHeight?: number;
  maxHeight?: number;
  showToolbarOnFocus?: boolean;
  emojiToolbar?: boolean;
  emojiTextarea?: boolean;
  characterLimit?: number;
}

const generateUniqueId = () => `quill-editor-${Math.random().toString(36).substring(2, 15)}`;

const QuillEditorWithMention = forwardRef<ReactQuill, QuillEditorWithMentionProps>(
  (
    {
      value,
      onChange,
      placeholder,
      onBlur,
      onFocus,
      mentions,
      commands,
      autoFocus,
      error,
      onEditorReady,
      minHeight = 120,
      maxHeight,
      showToolbarOnFocus,
      emojiToolbar = true,
      emojiTextarea = false,
      characterLimit,
    },
    ref: Ref<ReactQuill>,
  ) => {
    const reactQuillRef = useRef<ReactQuill>(null);
    const [isFocused, setIsFocused] = useState(false); // Initial height

    const [characterCount, setCharacterCount] = useState(0);

    const editorId = useMemo(() => generateUniqueId(), []); // This ensures the ID is persistent across re-renders but unique per instance

    useImperativeHandle(ref, () => reactQuillRef.current!);

    useMount(() => {
      const quill = reactQuillRef.current?.getEditor();
      if (autoFocus) {
        const length = quill?.getLength();
        quill?.setSelection(length || 0, 0);
        quill?.focus();
        setIsFocused(true);
      }

      // Function to update character count
      const updateCharacterCount = () => {
        if (!quill) {
          return;
        }

        const contents = quill?.getContents();
        let textLength = 0;
        contents?.ops?.forEach((op) => {
          if (typeof op.insert === 'string') {
            textLength += op.insert.length;
          } else if (typeof op.insert === 'object' && op.insert.mention) {
            textLength += op.insert.mention.denotationChar.length + op.insert.mention.value.length;
          }
        });
        setCharacterCount(textLength - 1); // Adjust for Quill's newline
        const editor = quill.root;
        // Temporarily reset editor height to minimal to force recalculation of scrollHeight
        editor.style.height = 'auto';
        editor.style.height = `${editor?.scrollHeight}px`;
      };

      updateCharacterCount();

      quill?.on('text-change', updateCharacterCount);

      onEditorReady?.();

      const Delta = Quill?.import('delta');

      quill?.clipboard.addMatcher(Node.ELEMENT_NODE, (_, delta) => {
        const allowedFormats = ['bold', 'italic', 'underline', 'strike', 'link'];

        const newDelta = new Delta();

        delta?.ops?.forEach((op) => {
          if (op.attributes) {
            // Filter out any attributes not in the allowed list
            Object.keys(op.attributes).forEach((attribute) => {
              if (!allowedFormats.includes(attribute)) {
                delete op?.attributes?.[attribute];
              }
            });
            // If no allowed attributes are left, convert to plain text
            if (Object.keys(op.attributes).length === 0) {
              newDelta.insert(op.insert);
            } else {
              newDelta.push(op);
            }
          } else if (typeof op.insert === 'string' || !op.insert.image) {
            newDelta.insert(op.insert);
          }
        });

        return newDelta;
      });
    });

    const exceedsLimit = characterLimit !== undefined && characterCount > characterLimit;

    useMount(() => {
      const toolbar = document.querySelector(`#${editorId} .ql-toolbar`);
      if (toolbar) {
        const counterElement = document.createElement('span');
        counterElement.setAttribute('id', `${editorId}-quill-character-count`);
        counterElement.style.cssText = 'margin-left: auto;'; // Adjust positioning as needed
        // Apply the caption styles to the counterElement
        counterElement.style.fontSize = formattedFontSize || '1rem';
        counterElement.style.fontFamily = captionFontFamily;
        counterElement.style.lineHeight = captionLineHeight;
        toolbar.appendChild(counterElement);
      }
    });

    // Update the character count element's text and styling
    useEffect(() => {
      const counterElement = document.getElementById(`${editorId}-quill-character-count`);
      if (counterElement) {
        counterElement.textContent = `${characterLimit ? characterCount : ''}${characterLimit ? `/${characterLimit}` : ''}`;
        counterElement.style.color = exceedsLimit ? palette.error.main : palette.text.secondary; // Change color if limit exceeded
      }
    }, [characterCount, characterLimit, exceedsLimit, editorId]);

    const mentionModule = useMemo(
      () => ({
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source(
          searchTerm: string,
          renderList: (data: any[], searchTerm: string) => void,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          _mentionChar: string,
        ) {
          const values =
            searchTerm.length === 0
              ? mentions
              : mentions?.filter((item) =>
                  item.value.toLowerCase().includes(searchTerm.toLowerCase()),
                );

          renderList(values || [], searchTerm);
        },
      }),
      [mentions],
    );

    const commandModule = useMemo(
      () => ({
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['/'],
        source(
          searchTerm: string,
          renderList: (data: any[], searchTerm: string) => void,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          _mentionChar: string,
        ) {
          const values =
            searchTerm.length === 0
              ? commands
              : commands?.filter((item) =>
                  item.value.toLowerCase().includes(searchTerm.toLowerCase()),
                );

          renderList(values || [], searchTerm);
        },
      }),
      [commands],
    );

    const editorModules = useMemo(
      () => ({
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['link', 'emoji'], // add this
        ],
        keyboard: {
          bindings: {
            'list autofill': {
              key: ' ',
              shiftKey: null,
              prefix: /^\d+\.$/, // Matches number followed by dot
              handler: (range: { index: number }, context: { prefix: string }) => {
                // We manually check if the prefix is exactly a number followed by a dot
                if (context.prefix.match(/^\d+\.$/)) {
                  // Insert a plain space without triggering a list
                  const quill = reactQuillRef.current?.getEditor();
                  quill?.insertText(range.index, ' '); // Insert a space at the cursor position
                  quill?.setSelection(range.index + 1, 0); // Move the cursor right after the space
                  return false; // Prevent the Quill default handler
                }
                return true; // Default handler for other cases
              },
            },
          },
        },
        mention: mentionModule,
        command: commandModule,
        'emoji-toolbar': emojiToolbar,
        'emoji-textarea': emojiTextarea,
        'emoji-shortname': true,
      }),
      [commandModule, emojiTextarea, emojiToolbar, mentionModule],
    );

    return (
      <Box
        id={editorId}
        className='notranslate'
        sx={{
          '& .ql-toolbar': {
            display: isFocused || !showToolbarOnFocus ? 'flex' : 'none',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            bgcolor: 'grey.50',
            borderColor: 'grey.200',
            alignItems: 'center',
            borderBottom: 0,
          },
          '& .ql-tooltip': {
            position: 'initial',
            transform: 'translateY(1px) !important',
            borderColor: 'grey.200',
            boxShadow: 'none',
            borderTop: 2,
            borderTopColor: 'primary.main',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            mx: '-1px',
            mt: '0 !important',
            ...typography.body2,
            '& .ql-preview': {
              ...typography.body2,
              width: 'calc(100% - 180px)',
            },
          },
          '& .ql-container': {
            bgcolor: 'grey.50',
            border: (theme) =>
              error
                ? `1px solid ${theme.palette.error.main} !important`
                : `1px solid ${theme.palette.grey[200]} !important`,
            borderColor: error ? 'error.main' : 'grey.200',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderTopRightRadius: isFocused || !showToolbarOnFocus ? 0 : 6,
            borderTopLeftRadius: isFocused || !showToolbarOnFocus ? 0 : 6,
            ...typography.body2,
            '&:hover': {
              boxShadow: error
                ? (theme) => `inset 0 0 0 0px ${theme.palette.error.main}`
                : (theme) => `inset 0 0 0 1px ${theme.palette.primary.main}`,
            },
            '&:focus-within': {
              boxShadow: error
                ? (theme) => `inset 0 0 0 1px ${theme.palette.error.main}`
                : (theme) => `inset 0 0 0 2px ${theme.palette.primary.darker}`,
            },
          },
          '& .ql-editor': {
            minHeight,
            maxHeight: maxHeight || 'auto',
            overflowY: maxHeight ? 'auto' : 'visible',
          },
        }}
      >
        <ReactQuill
          className='quill-editor-container'
          ref={reactQuillRef}
          modules={editorModules}
          value={value}
          onChange={onChange}
          onBlur={(previousSelection, source, editor) => {
            onBlur?.(previousSelection, source, editor);
            setIsFocused(false);
          }}
          onFocus={(selection, source, editor) => {
            onFocus?.(selection, source, editor);
            setIsFocused(true);
          }}
          placeholder={placeholder}
          theme='snow'
        />
      </Box>
    );
  },
);

export default memo(QuillEditorWithMention);
