import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export const useDeepCompareMemo = <T>(array: T[]): T[] => {
  const prevArrayRef = useRef<T[] | undefined>();

  useEffect(() => {
    if (!isEqual(prevArrayRef.current, array)) {
      prevArrayRef.current = array;
    }
  }, [array]);

  return prevArrayRef.current || array;
};
