import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomDataGridSlots from 'components/data-grid/CustomDataGridSlots';
import { RoutePath } from 'enums';
import { Mode } from 'forms/create-project/types';
import { tableStyle } from 'style';
import { Project } from 'types';
import { listViewConfig } from './listViewConfig';

export type GridViewProps = {
  bots: Project[];
  isLoading: boolean;
  sortModel: GridSortModel;
  onSortModelChange: (newSortModel: GridSortModel) => void;
};

const ListView = ({ bots, isLoading, sortModel, onSortModelChange }: GridViewProps) => {
  const navigate = useNavigate();

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      sortingOrder={['asc', 'desc']}
      rows={bots}
      loading={isLoading}
      columns={listViewConfig}
      rowHeight={70}
      disableRowSelectionOnClick
      disableColumnMenu
      onRowClick={({ row }) => {
        navigate(`/${RoutePath.ProjectManager}/${row.id}`);
      }}
      sx={tableStyle({ clickable: true })}
      slots={CustomDataGridSlots({
        noRowsOverlayProps: {
          text: 'No Projects found',
          onClick: () => {
            navigate(RoutePath.CreateProject, {
              state: {
                mode: Mode.Create,
              },
            });
          },
          buttonTitle: 'Create Project',
        },
      })}
    />
  );
};

export default ListView;
