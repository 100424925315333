import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import {
  FreeQuestionFormFields,
  FreeQuestionFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/free-question/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import {
  editorValidation,
  emptyEditorValue,
  validationOptionsValidation,
  variableValidation,
} from 'utils';

export const freeQuestionFormSchema: ObjectSchema<FreeQuestionFormFields> = object({
  [FreeQuestionFormFieldsNames.NodeId]: string().required(),
  [FreeQuestionFormFieldsNames.Name]: string().required('Name is required'),
  [FreeQuestionFormFieldsNames.Input]: editorValidation,
  [FreeQuestionFormFieldsNames.Variable]: variableValidation,
  [FreeQuestionFormFieldsNames.Validation]: validationOptionsValidation,
  [FreeQuestionFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultFreeQuestionFormValues: FreeQuestionFormFields = {
  [FreeQuestionFormFieldsNames.NodeId]: '',
  [FreeQuestionFormFieldsNames.Name]: 'Open-End Question',
  [FreeQuestionFormFieldsNames.Input]: emptyEditorValue,
  [FreeQuestionFormFieldsNames.Labels]: [],
};

export const freeQuestionFormFieldsConfig: Record<FreeQuestionFormFieldsNames, FormFieldProps> = {
  [FreeQuestionFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: FreeQuestionFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [FreeQuestionFormFieldsNames.Input]: {
    placeholder: '* Ask a question',
    name: FreeQuestionFormFieldsNames.Input,
    type: 'texarea',
  },
  [FreeQuestionFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: FreeQuestionFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [FreeQuestionFormFieldsNames.Validation]: {
    placeholder: 'Validation',
    name: FreeQuestionFormFieldsNames.Validation,
    autoComplete: 'off',
    type: 'text',
  },

  [FreeQuestionFormFieldsNames.NodeId]: {
    name: FreeQuestionFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [FreeQuestionFormFieldsNames.Labels]: labelFormFieldConfig,
};
