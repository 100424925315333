import { useCallback } from 'react';
import { getOutgoers, NodeProps, useReactFlow } from 'reactflow';
import { BaseNodeProps } from 'types';

const useConnectedEdgesAndNodes = () => {
  const { getNode, getNodes, getEdges } = useReactFlow<BaseNodeProps>();
  const connectedEdgesAndNodes = useCallback(
    (id: NodeProps['id']) => {
      const node = getNode(id);

      if (!node) {
        return { edges: [], nodes: [] };
      }

      const outgoers = getOutgoers(node, getNodes(), getEdges());
      const edgesFromNode = getEdges().filter((edge) => edge.source === node.id);

      const result = {
        nodes: [node],
        edges: edgesFromNode,
      };

      outgoers.forEach((outgoer) => {
        const childResult = connectedEdgesAndNodes(outgoer.id);
        result.nodes.push(...childResult.nodes);
        result.edges.push(...childResult.edges);
      });

      return result;
    },
    [getNode, getNodes, getEdges],
  );

  return connectedEdgesAndNodes;
};

export default useConnectedEdgesAndNodes;
