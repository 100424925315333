import { FormControlLabel, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { List, ListItem, OnOffSwitch } from '@common-components';
import { ApiKey } from 'api';
import { AppTypes } from 'enums';
import { usePauseApp, useUpdateBooleanProperty } from 'hooks';
import LinkBox from 'pages/app-pages/chatbot-builder-page/dialogs/publish/LinkBox';

import ToolboxLayout from 'pages/app-pages/chatbot-builder-page/toolbox/ToolboxLayout';
import { Project } from 'types';

const BotSettings = () => {
  const { id } = useParams();

  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`, {
    revalidateOnMount: false,
  });

  const { togglePause, botIsActive } = usePauseApp({
    project,
    appType: AppTypes.Chatbot,
  });

  const { entity: quizAllowOneAttemptValue, updateProperty: toggleQuizAllowOneAttempt } =
    useUpdateBooleanProperty<Project>({
      id: id || '',
      property: 'quizAllowOneAttempt',
      successMessage: 'Quiz Allow One Attempt updated successfully',
      apiKey: ApiKey.Project,
    });

  const { entity: quizShowCorrectAnswerValue, updateProperty: toggleQuizShowCorrectAnswer } =
    useUpdateBooleanProperty<Project>({
      id: id || '',
      property: 'quizShowCorrectAnswer',
      successMessage: 'Quiz Allow One Attempt updated successfully',
      apiKey: ApiKey.Project,
    });

  return (
    <ToolboxLayout title='Chatbot App Settings'>
      <Stack width={1} alignItems='flex-start' gap={4} px={2}>
        <List divider dense label='General'>
          <ListItem>
            <FormControlLabel
              control={
                <OnOffSwitch
                  disabled={!project?.token}
                  color='success'
                  checked={botIsActive}
                  onClick={async () => {
                    await togglePause();
                  }}
                />
              }
              label={botIsActive ? 'Chatbot App Is Active' : 'Chatbot App Is Paused'}
            />
          </ListItem>
        </List>
        <List divider dense label='Quiz'>
          <ListItem>
            <FormControlLabel
              control={
                <OnOffSwitch
                  color='success'
                  checked={quizAllowOneAttemptValue}
                  onClick={async () => {
                    await toggleQuizAllowOneAttempt(!project?.quizAllowOneAttempt);
                  }}
                />
              }
              label='Allow Only One Attempt Per User'
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <OnOffSwitch
                  color='success'
                  checked={quizShowCorrectAnswerValue}
                  onClick={async () => {
                    await toggleQuizShowCorrectAnswer(!project?.quizShowCorrectAnswer);
                  }}
                />
              }
              label='Show Correct Answer After Each Question'
            />
          </ListItem>
        </List>
        {project?.botUsername && (
          <Stack width={1} gap={2}>
            <LinkBox
              value={`https://t.me/${project.botUsername}`}
              label='Direct Bot Link'
              copyText='Bot Link copied'
              icon='smart_toy'
              infoIcon
              helpText='This is the direct link to your bot. Share it with your users to start a conversation.'
            />
            <LinkBox
              value={project?.trackingUrl || ''}
              copyText='Magic Link copied'
              label='Magic Link'
              icon='link'
              infoIcon
              helpText={`This magic link captures the visitor's IP, browser information, and any additional URL parameters before redirecting to your Telegram bot. You can utilize this data within your chatbot builder and forward it to your Webhook for enhanced interaction.`}
            />
          </Stack>
        )}
      </Stack>
    </ToolboxLayout>
  );
};

export default BotSettings;
