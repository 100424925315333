import { Container, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { NoDataOverlay } from 'components/index';

import { useGetChatActivity } from 'hooks';
import { ScrollAreaLayout } from 'layout';
import ActivityTable from 'pages/activity-page/ActivityTable';
import useChatTable from 'pages/activity-page/useChatTable';

function ActivityPage() {
  const { chats, isLoading, error } = useGetChatActivity();

  const {
    chatData,
    sortModel,
    onSortModelChange,
    projectNames,
    assigneeOptions,
    isImportantFilter,
    setIsImportantFilter,
    selectedLabels,
    setSelectedLabels,
    selectedProjectNames,
    setSelectedProjectNames,
    selectedAssignees,
    setSelectedAssignees,
    selectedStatuses,
    setSelectedStatuses,
    showArchived,
    setShowArchived,
    userLabels,
    filteredChatData,
    projectName,
  } = useChatTable(chats);

  if (error) {
    return (
      <NoDataOverlay
        text='Error Loading Activity, Please Reload the Page'
        onClick={() => {
          window.location.reload();
        }}
        buttonTitle='Reload'
      />
    );
  }

  return (
    <>
      <Container maxWidth='lg'>
        <Stack>
          <ScrollAreaLayout subtract={0}>
            <ActivityTable
              isLoading={isLoading}
              chatData={chatData}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              projectNames={projectNames}
              assigneeOptions={assigneeOptions}
              isImportantFilter={isImportantFilter}
              setIsImportantFilter={setIsImportantFilter}
              selectedLabels={selectedLabels}
              setSelectedLabels={setSelectedLabels}
              selectedProjectNames={selectedProjectNames}
              setSelectedProjectNames={setSelectedProjectNames}
              selectedAssignees={selectedAssignees}
              setSelectedAssignees={setSelectedAssignees}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              userLabels={userLabels}
              filteredChatData={filteredChatData}
              projectName={projectName}
            />
          </ScrollAreaLayout>
        </Stack>
      </Container>
      <Outlet />
    </>
  );
}

export default ActivityPage;
