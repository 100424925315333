import { boolean, object, ObjectSchema, string } from 'yup';
import {
  CreateCommandFieldsNames,
  CreateCommandFormFields,
} from 'pages/app-pages/chatbot-builder-page/dialogs/command/types';
import { FormFieldProps } from 'types';
import { variableValidation } from 'utils';

export const createCommandSchema: ObjectSchema<CreateCommandFormFields> = object({
  [CreateCommandFieldsNames.Name]: variableValidation.required('Command Name is required'),
  [CreateCommandFieldsNames.Icon]: string().required('Icon is required'),
  [CreateCommandFieldsNames.Description]: string().when(
    CreateCommandFieldsNames.IsMenuItem,
    (isMenuItem: boolean[]) => {
      if (isMenuItem[0]) {
        return string()
          .max(64, 'Maximum length of the description is 64 characters.')
          .required('In order to make it a menu item, you must provide a description.');
      }
      return string().optional();
    },
  ),
  [CreateCommandFieldsNames.IsMenuItem]: boolean().optional(),
});

export const createCommandFormValues: CreateCommandFormFields = {
  [CreateCommandFieldsNames.Name]: '',
  [CreateCommandFieldsNames.Icon]: 'flag',
  [CreateCommandFieldsNames.Description]: '',
  [CreateCommandFieldsNames.IsMenuItem]: true,
};

export const createCommandFieldsConfig: Record<CreateCommandFieldsNames, FormFieldProps> = {
  [CreateCommandFieldsNames.Name]: {
    label: '* Name',
    placeholder: 'Lowercase letters, no spaces, no special characters',
    name: CreateCommandFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [CreateCommandFieldsNames.Icon]: {
    label: 'Icon',
    placeholder: 'Icon',
    name: CreateCommandFieldsNames.Icon,
    type: 'text',
  },
  [CreateCommandFieldsNames.Description]: {
    label: 'Description',
    placeholder: 'Description ( Max 64 characters )',
    name: CreateCommandFieldsNames.Description,
    autoComplete: 'off',
    type: 'textarea',
  },
  [CreateCommandFieldsNames.IsMenuItem]: {
    label: 'Show in menu',
    name: CreateCommandFieldsNames.IsMenuItem,
    type: 'checkbox',
  },
};
export const commandIconsConfig = [
  {
    label: 'Start',
    value: 'flag',
  },
  {
    label: 'Campaign',
    value: 'campaign',
  },
  {
    label: 'Search',
    value: 'search',
  },
  {
    label: 'Settings',
    value: 'settings',
  },
  {
    label: 'Help',
    value: 'help',
  },
  {
    label: 'Info',
    value: 'info',
  },
  {
    label: 'List',
    value: 'list',
  },
  {
    label: 'Star',
    value: 'star',
  },
  {
    label: 'Navigation',
    value: 'navigation',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Person',
    value: 'person',
  },
  {
    label: 'School',
    value: 'school',
  },
];
