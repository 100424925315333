import { Checkbox, CheckboxProps, FormControlLabel, Stack, Typography } from '@mui/material';
import { memo, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import IconWithTooltip from 'components/IconWithTooltip';
import { TooltipWrapperProps } from 'theme';

export interface FormCheckBoxProps extends Omit<CheckboxProps, 'id'> {
  name: string;
  infoIcon?: {
    tooltip: string;
    icon: string;
    tooltipProps?: TooltipWrapperProps;
  };

  label?: string | ReactNode;
}
export const FormCheckBox = (props: FormCheckBoxProps) => {
  const { name, label, size = 'small', defaultValue, infoIcon, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ref, ...field }, fieldState: { error } }) => (
        <FormControlLabel
          disableTypography
          className='notranslate'
          sx={{
            alignSelf: 'flex-start',
            width: 1,
            mr: 0,
            ...(error && {
              color: 'error.main',
            }),
            '& *': {
              ...(error && {
                color: (theme) => `${theme.palette.error.main} !important`,
              }),
            },
          }}
          control={
            <Checkbox
              inputRef={ref}
              {...field}
              id={name}
              onChange={onChange}
              checked={value ?? false}
              size={size}
              {...rest}
            />
          }
          label={
            <Stack width={1} direction='row' gap={1} alignItems='center'>
              <Typography variant='body2'>{label}</Typography>
              {infoIcon && (
                <IconWithTooltip
                  icon={infoIcon.icon}
                  tooltip={infoIcon.tooltip}
                  tooltipPlacement='top'
                  tooltipProps={{
                    arrow: true,
                    ...infoIcon.tooltipProps,
                  }}
                />
              )}
            </Stack>
          }
        />
      )}
    />
  );
};

export default memo(FormCheckBox);
