import { Tooltip } from '@mui/material';
import { IconProps as MuiIconProps } from '@mui/material/Icon/Icon';
import { ReactNode } from 'react';
import Icon from 'components/Icon';

import { TooltipWrapperProps } from 'theme';

interface IconWithTooltipProps extends TooltipWrapperProps {
  tooltip: ReactNode;
  icon: string;
  iconProps?: Omit<MuiIconProps, 'children' | 'icon' | 'ref'>;
}

const IconWithTooltip = ({
  icon,
  tooltip,
  tooltipProps,
  tooltipPlacement,
  iconProps,

  ...rest
}: IconWithTooltipProps) => (
  <Tooltip title={tooltip} {...tooltipProps} placement={tooltipPlacement} {...rest}>
    <Icon icon={icon} color={iconProps?.color || 'grey.500'} fontSize='small' {...iconProps} />
  </Tooltip>
);

export default IconWithTooltip;
