import { AppTypes, RoutePath } from 'enums';
import { BasicColors } from 'theme';

export enum CreateProjectFieldsNames {
  Name = 'name',
  Description = 'description',
  SelectedApp = 'selectedApp',
}
export interface CreateProjectFields {
  [CreateProjectFieldsNames.Name]: string;
  [CreateProjectFieldsNames.Description]?: string;
  [CreateProjectFieldsNames.SelectedApp]: AppTypes | null;
}

export enum CreateProjectSteps {
  ProjectDetails = 'projectDetails',
  SelectApp = 'selectApp',
  Success = 'success',
}

export enum Mode {
  Create = 'create',
  Connect = 'connect',
}

export interface AppProps {
  type: AppTypes;
  label: string;
  description: string;
  appUrl: RoutePath;
  cta: string;
  icon: string;
  color: BasicColors;
  comingSoon?: boolean;
  isActive?: boolean;
  showInCreate?: boolean;
}
