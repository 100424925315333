// Extend the Window interface to include dataLayer
declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const gtmCustomEvent = (eventName: string, eventData: Record<string, any>): void => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn('GTM dataLayer is not defined.');
  }
};
