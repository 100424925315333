import { Box, Container, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NotFound } from 'assets/images';
import Button from 'components/Button';

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Container maxWidth='lg'>
      <Stack alignItems='center' justifyContent='center' gap={2} height={1}>
        <Box
          sx={{
            '& svg': {
              width: '100%',
            },
          }}
        >
          <NotFound />
        </Box>
        <Button
          variant='contained'
          onClick={() => {
            navigate('/');
          }}
        >
          Back To Home
        </Button>
      </Stack>
    </Container>
  );
}

export default NotFoundPage;
