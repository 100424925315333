import { Chip, ListItemText, MenuItem, Paper, Popover } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';
import { api, ApiKey } from 'api';
import { FormAutoComplete } from 'components/index';
import {
  OptionsMenuState,
  useFormProvider,
  useGetUserLabels,
  useOptimisticUpdate,
  useProcessNewLabels,
  useServerError,
} from 'hooks';
import { LabelsFormFields, LabelsFormFieldsNames } from 'pages/activity-page/labels/types';
import {
  labelsFieldsConfig,
  labelsFormSchema,
  labelsNoteDefaultValues,
} from 'pages/activity-page/labels/utils';

import { useDirtyStore } from 'store';
import { AutoCompleteLabel, Chat, UserLabel } from 'types';
import {
  filterOptions,
  getUserLabelsByAutoCompleteLabels,
  mapLabelsAutocompleteOptions,
} from 'utils';

interface LabelFormProps {
  defaultValues: AutoCompleteLabel[];
  menuProps: Omit<OptionsMenuState, 'openMenu'>;
  chatId: string;
}

const borderRadius = '4px';

const LabelForm = ({ defaultValues, menuProps, chatId }: LabelFormProps) => {
  const { anchorEl, isMenuOpen, closeMenu } = menuProps;
  const { labels: userLabels } = useGetUserLabels();

  const optimisticUpdateChatList = useOptimisticUpdate<Chat>();

  const autoCompleteOptions = useMemo(
    () => mapLabelsAutocompleteOptions(userLabels).sort((a, b) => a.label.localeCompare(b.label)),
    [userLabels],
  );

  const {
    value: isLabelDropdownOpen,
    setTrue: openLabelDropdown,
    setFalse: closeLabelDropdown,
  } = useBoolean();

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  const formMethods = useFormProvider<LabelsFormFields>({
    reValidateMode: 'onChange',
    schema: labelsFormSchema,
    defaultValues: {
      [LabelsFormFieldsNames.Labels]:
        defaultValues || labelsNoteDefaultValues[LabelsFormFieldsNames.Labels],
    },
  });
  const toastServerError = useServerError();
  const processNewLabels = useProcessNewLabels();

  const handleOnSubmit = async (formData: LabelsFormFields) => {
    const { foundLabels, notFoundLabels } = getUserLabelsByAutoCompleteLabels(
      formData[LabelsFormFieldsNames.Labels] || [],
      userLabels,
    );

    const newLabelsForOptimisticUpdate = notFoundLabels.map(
      (label) =>
        ({
          id: uuid(),
          name: label,
          color: '#dee0e7',
          icon: 'label',
        }) as UserLabel,
    );

    await optimisticUpdateChatList({
      key: ApiKey.Chat,
      id: chatId,
      path: 'labels',
      data: [...foundLabels, ...newLabelsForOptimisticUpdate],
    });

    setIsDirty(false);

    const labelIds = await processNewLabels(formData.labels);

    try {
      await api.patch<Chat>(`${ApiKey.Chat}/${chatId}`, {
        labels: labelIds,
      });
      await mutate(ApiKey.Chat);
      await mutate(`${ApiKey.Chat}/${chatId}`);
    } catch (error: any) {
      toastServerError(error);
    }
  };

  const { labels } = labelsFieldsConfig;
  return (
    <FormProvider {...formMethods}>
      <Popover
        slotProps={{
          paper: {
            sx: {
              minWidth: {
                xs: 'calc(100% - 32px)',
                sm: 400,
              },
              borderRadius,
              maxWidth: {
                xs: 'calc(100% - 32px)',
                sm: 400,
              },
            },
          },
        }}
        id='delete-prompt-menu'
        autoFocus={false}
        aria-labelledby='delete-prompt-menu'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => {
          setIsDirty(false);
          closeMenu();
          closeLabelDropdown();
        }}
        TransitionProps={{
          onEntered: openLabelDropdown, // Ensures dropdown opens after Popover enters
          onExit: closeLabelDropdown, // Reset the dropdown state when Popover begins to exit
        }}
      >
        <FormAutoComplete<AutoCompleteLabel>
          onChangeCallback={async () => {
            await handleOnSubmit(formMethods.getValues());
          }}
          size='small'
          PaperComponent={(props) => <Paper {...props} sx={{ borderRadius }} />}
          open={isLabelDropdownOpen}
          autoFocus
          freeSolo
          multiple
          filterSelectedOptions
          filterOptions={filterOptions}
          options={autoCompleteOptions}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object' && typeof value === 'object') {
              return option.value.toLowerCase() === value.value.toLowerCase();
            }
            if (typeof option === 'string' && typeof value === 'string') {
              return option === value;
            }
            return false;
          }}
          textFieldProps={{
            sx: {
              '& .MuiOutlinedInput-root': {
                borderRadius,
              },
            },
          }}
          renderOption={(props, option) => (
            <MenuItem key={option.value} {...props}>
              <ListItemText disableTypography>
                <Chip size='small' label={option.label} sx={{ backgroundColor: option.color }} />
              </ListItemText>
            </MenuItem>
          )}
          {...labels}
        />
      </Popover>
    </FormProvider>
  );
};

export default LabelForm;
