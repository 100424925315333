export enum LocalStorageKey {
  LoggedInBefore = 'logged-in-before',
  RedirectUrl = 'redirect-url',
  ShowCoupon = 'show-coupon',
  BotsViewType = 'bots-view-type',
  DashboardChartType = 'dashboard-chart-type',
  BotsViewSort = 'bots-view-sort',
  ChatViewSort = 'chat-view-sort',
  MessagesViewSort = 'messages-view-sort',
  BuilderTourState = 'builder-tour-state',
  BuilderLayoutDirection = 'builder-layout-direction',
}
