import { isEmpty } from 'lodash';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormAutoComplete,
  FormSection,
  FormTextField,
  FormTextFieldInlineEdit,
} from '@common-components';
import { useAuth, useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { SendEmailFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-email/types';
import {
  defaultSendEmailFormValues,
  sendEmailFormFieldsConfig,
  sendEmailFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-email/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface SendEmailProps {
  id: string;
  defaultValues?: Partial<SendEmailFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}

const SendEmail = ({ defaultValues, mentions, commands, id }: SendEmailProps) => {
  const { user } = useAuth();

  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultSendEmailFormValues,
    ...defaultValues,
    emailAddress: isEmpty(defaultValues?.emailAddress)
      ? [user?.email]
      : defaultValues?.emailAddress,

    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<SendEmailFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<SendEmailFormFields>({
    schema: sendEmailFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: SendEmailFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, labels, input, emailAddress, subject } = sendEmailFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <FormSection title='Recipients List'>
          <FormAutoComplete
            freeSolo
            multiple
            autoSelect
            options={[]}
            {...emailAddress}
            size='small'
          />
        </FormSection>
        <FormSection title='Subject'>
          <FormTextField {...subject} size='small' />
        </FormSection>
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(SendEmail);
