import { Box, CircularProgress, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import { useState } from 'react';
import { mutate } from 'swr';
import { ConfirmationDialog, IconButton, Label } from '@common-components';
import { api, ApiKey } from 'api';
import { ExtendedUserRole, UserRole } from 'enums';
import { roleOptions } from 'forms/invite-to-organization/utils';
import { useFeatureAccess, useMenu, useServerError, useToast } from 'hooks';
import UserTableItem from 'pages/organization-page/UserTableItem';
import { menuMinWidth } from 'theme/utils';
import { User } from 'types';

const ActiveUserItem = ({ id, email, role, isMe, isOwner, telegram }: User & { isMe: boolean }) => {
  const toastServerError = useServerError();
  const { openMenu: openRemovePrompt, ...restRemovePrompt } = useMenu();
  const [updatingRole, setUpdatingRole] = useState<UserRole | null>(null);

  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();
  const toast = useToast();

  const hasAccessToEditUser = useFeatureAccess({
    minRole: ExtendedUserRole.Admin,
  });

  return (
    <>
      <UserTableItem
        hasNotification={!!telegram}
        title={email}
        subtitle={`
        ${isMe ? 'Me | ' : ''}
        ${role}
      `}
        icon='person_check'
        iconColor='secondary.main'
        suffix={
          <>
            <Stack>
              {!isOwner && hasAccessToEditUser && (
                <IconButton edge='end' icon='more_vert' onClick={openMenu} />
              )}
            </Stack>
            {isOwner && <Label color='secondary'>Owner</Label>}
          </>
        }
      />
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        <NestedMenuItem
          sx={{
            '&.MuiMenuItem-root p': {
              typography: 'body2',
            },
          }}
          label='Change Role'
          parentMenuOpen={isMenuOpen}
        >
          {roleOptions.map((option) => (
            <MenuItem
              selected={role === option.value}
              key={option.value}
              onClick={async () => {
                setUpdatingRole(option.value); // Set the currently updating role

                try {
                  await api.patch(`${ApiKey.Users}/${id}`, {
                    role: option.value,
                  });
                  await mutate(`${ApiKey.Users}`);
                  toast({
                    message: 'Role updated',
                    variant: 'success',
                  });
                } catch (error: any) {
                  toastServerError(error);
                } finally {
                  setUpdatingRole(null); // Reset updating role state
                  closeMenu();
                }
              }}
            >
              <ListItemText>{option.label}</ListItemText>
              <Box ml={2} width={10}>
                {updatingRole === option.value && <CircularProgress size={10} color='inherit' />}
              </Box>
            </MenuItem>
          ))}
        </NestedMenuItem>
        {!isMe && (
          <MenuItem sx={{ color: 'error.main' }} onClick={openRemovePrompt}>
            <ListItemText>Remove</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        text='Are you sure you want to remove this user?'
        onConfirm={async () => {
          try {
            await api.patch(`${ApiKey.Users}/${ApiKey.DeactivateOrganizationUser}/${id}`);
            await mutate(`${ApiKey.Users}`);
            closeMenu();
          } catch (error: any) {
            toastServerError(error);
            closeMenu();
          }
        }}
        submittingText='Removing...'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...restRemovePrompt}
      />
    </>
  );
};

export default ActiveUserItem;
