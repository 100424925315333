export enum CreateCommandFieldsNames {
  Name = 'name',
  Icon = 'icon',
  Description = 'description',
  IsMenuItem = 'isMenuItem',
}

export interface CreateCommandFormFields {
  [CreateCommandFieldsNames.Name]: string;
  [CreateCommandFieldsNames.Icon]: string;
  [CreateCommandFieldsNames.Description]?: string;
  [CreateCommandFieldsNames.IsMenuItem]?: boolean;
}
