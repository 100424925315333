import { planConfig, roleConfig } from 'config';
import { AccountPlanType, ExtendedUserRole } from 'enums';
import { useAuth } from './useAuth';

export const useFeatureAccess = ({
  minPlan,
  minRole,
}: { minPlan?: AccountPlanType; minRole?: ExtendedUserRole } = {}): boolean => {
  const { user } = useAuth();

  if (!user) {
    return false;
  } // No user found

  // Determine the effective role level
  let userRoleLevel = 0;
  if (user.isOwner) {
    userRoleLevel = roleConfig.Owner.level;
  } else if (user.role && roleConfig[user.role]) {
    userRoleLevel = roleConfig[user.role].level;
  }

  const minRoleLevel = minRole ? roleConfig[minRole]?.level : 0;

  // Plan level checks
  const userPlanLevel = user.organization?.accountPlan
    ? planConfig[user.organization.accountPlan.type].level
    : 0;
  const minPlanLevel = minPlan ? planConfig[minPlan].level : 0;

  // Check if the user's plan and role meet or exceed the minimum required levels
  return userPlanLevel >= minPlanLevel && userRoleLevel >= minRoleLevel;
};
