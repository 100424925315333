import { Stack, Typography } from '@mui/material';
import { List, ListItem, ReusableSwitcher } from '@common-components';
import { ApiKey } from 'api';
import { User } from 'types';

const soundOnIconPath =
  'M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z'; // Example path for "Sound On" icon
const soundOffIconPath =
  'M4.27 3L3 4.27l9 9v.28c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4v-1.73L19.73 21 21 19.73 4.27 3zM14 7h4V3h-6v5.18l2 2z'; // Example path for "Sound Off" icon

interface NotificationsSettingsProps {
  user: User;
  setUser: (user: User) => void;
}

const NotificationsSettings = ({ user, setUser }: NotificationsSettingsProps) => (
  <Stack gap={0.5}>
    <Typography variant='subtitle1'>Telegram Notifications Settings</Typography>
    <Typography variant='body2' color='text.secondary' mb={2}>
      By turning off the sound, you will still receive notifications via our Telegram bot, but they
      will be silent.
    </Typography>
    <List dense divider>
      <ListItem>
        <ReusableSwitcher
          data={user}
          setData={setUser}
          propertyPath='telegram.newChatNotification.sound' // String path
          apiEndpoint={`${ApiKey.Users}/${user?.id}/${ApiKey.Telegram}`}
          patchData={{
            newChatNotification: { sound: !user?.telegram?.newChatNotification?.sound },
          }}
          label='New Conversation Sound'
          onIconPath={soundOnIconPath}
          offIconPath={soundOffIconPath}
        />
      </ListItem>
      <ListItem>
        <ReusableSwitcher
          data={user}
          setData={setUser}
          propertyPath='telegram.userReplyNotification.sound'
          apiEndpoint={`${ApiKey.Users}/${user?.id}/${ApiKey.Telegram}`}
          patchData={{
            userReplyNotification: { sound: !user?.telegram?.userReplyNotification?.sound },
          }}
          label='User Reply Sound'
          onIconPath={soundOnIconPath}
          offIconPath={soundOffIconPath}
        />
      </ListItem>
    </List>
  </Stack>
);

export default NotificationsSettings;
