import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormDateTimePickerProps extends DateTimePickerProps<Date> {
  name: string;
  disabled?: boolean;
  // Add other props that you need to pass to the DateTimePicker
}

export const FormDateTimePicker = ({ name, disabled, ...rest }: FormDateTimePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref, ...field }, fieldState: { error } }) => (
        <DateTimePicker
          ampm={false}
          value={value || null}
          onChange={onChange}
          disabled={disabled}
          slotProps={{
            textField: {
              inputRef: ref,
              error: !!error,
              helperText: error?.message,
              fullWidth: true,
            },
          }}
          {...rest}
          {...field}
        />
      )}
    />
  );
};

export default React.memo(FormDateTimePicker);
