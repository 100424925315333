import { Skeleton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useGetOrganizationActiveUser } from 'hooks';
import Assignee from 'pages/activity-page/assignee/Assignee';
import { ChatTableProps } from 'types';

const AssigneeWrapper = ({ row }: GridRenderCellParams<ChatTableProps>) => {
  const { activeUsers, isLoading: loadingActiveUsers } = useGetOrganizationActiveUser();

  const defaultValues = activeUsers.find((u) => u.id === row.assignee);

  if (loadingActiveUsers) {
    return <Skeleton variant='text' width='70%' />;
  }

  return (
    <Assignee
      id={row.id}
      options={activeUsers}
      isLoading={loadingActiveUsers}
      key={row.assignee}
      defaultValues={defaultValues}
    />
  );
};

export default AssigneeWrapper;
