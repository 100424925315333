import { Stack } from '@mui/material';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormTextField, FormTextFieldInlineEdit } from 'components/index';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { OpenEndQuestionFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/open-end-question/types';
import {
  defaultOpenEndQuestionFormValues,
  openEndQuestionFormFieldsConfig,
  openEndQuestionFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/open-end-question/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface FreeQuestionProps {
  id: string;
  defaultValues?: Partial<OpenEndQuestionFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}

const OpenEndQuestion = ({ defaultValues, mentions, commands, id }: FreeQuestionProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultOpenEndQuestionFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<OpenEndQuestionFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<OpenEndQuestionFormFields>({
    schema: openEndQuestionFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(
    mentions?.filter((mention) => mention.id !== 'score' && mention.id !== 'quiz_summary'),
  );
  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };
  const handleOnSubmit = async (formData: OpenEndQuestionFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  const { name, labels, variable, answer, input } = openEndQuestionFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />

          <Stack direction='row' gap={0.5}>
            <FormVariableField {...variable} />
          </Stack>
        </Stack>
        <FormTextField {...answer} size='small' />
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(OpenEndQuestion);
