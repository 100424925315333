import { Divider, Skeleton, Stack } from '@mui/material';
import { times } from 'lodash';

const TableSkeleton = () => (
  <Stack
    justifyContent='flex-start'
    alignItems='center'
    height={1}
    width={1}
    divider={<Divider flexItem />}
  >
    {times(10, (i) => (
      <Stack
        key={i}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={2}
        width={1}
        px={2}
        py={3}
      >
        <Skeleton variant='circular' width={20} height={20} />
        <Stack direction='row' alignItems='center' gap={1} flex={1}>
          <Skeleton variant='rounded' width='100%' height={20} />
        </Stack>
      </Stack>
    ))}
  </Stack>
);
export default TableSkeleton;
