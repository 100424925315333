import { Box, Button } from '@mui/material';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type HeaderMenuItemProps = {
  label: string;
  to?: string;
  onClick?: () => void;
  endIcon?: ReactNode;
};

function HeaderMenuItem({ label, to, onClick, endIcon }: HeaderMenuItemProps) {
  return (
    <Button
      color='inherit'
      endIcon={endIcon}
      component={onClick ? 'button' : NavLink}
      to={onClick ? undefined : to}
      onClick={onClick}
      sx={{
        color: 'grey.600',
        whiteSpace: 'nowrap',
        '&.active': {
          color: 'grey.900',
          bgcolor: 'grey.100',
        },
      }}
    >
      <Box
        component='span'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Box>
    </Button>
  );
}

export default HeaderMenuItem;
