export enum GroupFormFieldsNames {
  Id = 'id',
  Name = 'name',
  Project = 'project',
}

export interface GroupFormFields {
  [GroupFormFieldsNames.Id]: string;
  [GroupFormFieldsNames.Name]?: string;
  [GroupFormFieldsNames.Project]: string;
}
