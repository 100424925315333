import { Box, Link, Stack, Typography } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { AppLogo, GoogleButton } from '@common-components';
import { RoutePath } from 'enums';
import { AuthDialogWrapper, OrDivider } from 'pages/auth-pages/components';
import LoginForm from 'pages/auth-pages/login/LoginForm';

const LoginPage = () => (
  <AuthDialogWrapper areaLabel='login-form'>
    <Box mb={2}>
      <AppLogo height={42} />
    </Box>
    <Stack mb={2}>
      <Typography align='center' variant='h4' component='h1'>
        Welcome back!
      </Typography>
      <Typography align='center' variant='body1'>
        Please login to continue to the app.
      </Typography>
    </Stack>
    <GoogleButton />
    <OrDivider />
    <LoginForm />
    <Typography align='center' variant='subtitle2'>
      Don&apos;t have an account?{' '}
      <Link component={RouterLink} variant='subtitle2' to={`/${RoutePath.SignUp}`} underline='none'>
        Sign up
      </Link>
    </Typography>
  </AuthDialogWrapper>
);

export default LoginPage;
