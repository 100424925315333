import { object, ObjectSchema, string } from 'yup';
import { AssigneeFormFields, AssigneeFormFieldsNames } from 'pages/activity-page/assignee/types';

import { FormFieldProps } from 'types';

export const assigneeFormSchema: ObjectSchema<AssigneeFormFields> = object({
  [AssigneeFormFieldsNames.User]: object({
    value: string().required('User value is required'),
    label: string().required('User label is required'),
  })
    .optional()
    .nullable(),
});

export const assigneeFieldsConfig: Record<AssigneeFormFieldsNames, FormFieldProps> = {
  [AssigneeFormFieldsNames.User]: {
    placeholder: 'Unassigned',
    name: AssigneeFormFieldsNames.User,
    type: 'text',
  },
};
