export enum LoginFormFieldsNames {
  Email = 'email',
  Password = 'password',
  RememberMe = 'rememberMe',
}

export interface LoginFormFields {
  [LoginFormFieldsNames.Email]: string;
  [LoginFormFieldsNames.Password]: string;
  [LoginFormFieldsNames.RememberMe]?: boolean;
}
