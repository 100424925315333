import { PickEnum } from 'utils';

export enum BotColumns {
  Name = 'name',
  Description = 'description',
  Apps = 'apps',
  UpdatedAt = 'updatedAt',
  IsActive = 'isActive',
}
export type BotSortableKeys = PickEnum<
  BotColumns,
  BotColumns.Name | BotColumns.UpdatedAt | BotColumns.IsActive
>;
