import { ExtendedUserRole, UserRole } from 'enums';
import { RoleProps } from 'types';

export const roleConfig: Record<ExtendedUserRole, RoleProps> = {
  Owner: {
    name: 'Owner',
    shortName: 'Owner',
    role: 'Owner',
    level: 4,
    color: 'success',
  },
  [UserRole.Admin]: {
    name: 'Administrator',
    shortName: 'Admin',
    role: UserRole.Admin,
    level: 3,
    color: 'success',
  },
  [UserRole.Editor]: {
    name: 'Editor',
    shortName: 'Editor',
    role: UserRole.Editor,
    level: 2,
    color: 'info',
  },
  [UserRole.Agent]: {
    name: 'Agent',
    shortName: 'Agent',
    role: UserRole.Agent,
    level: 1,
    color: 'info',
  },
};
