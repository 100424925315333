import { Navigate } from 'react-router-dom';
import { AppTypes, ExtendedUserRole, RoutePath } from 'enums';
import CreateProjectModal from 'forms/create-project/CreateProjectModal';
import InviteToOrganizationModal from 'forms/invite-to-organization/InviteToOrganizationModal';
import RenameProjectModal from 'forms/rename-project/RenameProjectModal';
import { AnonymousLayout, AppLayout, SimpleLayout } from 'layout';
import { ApplyCouponModal, ChatActivityModal } from 'modals';
import AcceptInvitationModal from 'modals/accept-invitation/AcceptInvitationModal';
import {
  ActivityPage,
  ChatbotBuilderPage,
  DashboardPage,
  ForgotPasswordPage,
  LoginPage,
  ManageAccountPage,
  MessagesSchedulerPage,
  NotFoundPage,
  OrganizationPage,
  ProjectManagerPage,
  ProjectsPage,
  SignUpPage,
  TicketingPage,
} from 'pages';
import BulkOnboarding from 'pages/app-pages/chatbot-builder-page/dialogs/bulk-onboarding/BulkOnboarding';
import CommandModal from 'pages/app-pages/chatbot-builder-page/dialogs/command/CommandModal';
import ConnectGroups from 'pages/app-pages/chatbot-builder-page/dialogs/connect-groups/ConnectGroups';
import MessengerOnboarding from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/MessengerOnboarding';
import PublishModal from 'pages/app-pages/chatbot-builder-page/dialogs/publish/PublishModal';
import SelectTemplate from 'pages/app-pages/chatbot-builder-page/SelectTemplate';
import MessageFormModal from 'pages/app-pages/messages-scheduler-page/message-modal/MessageFormModal';
import BulkMessageFormModal from 'pages/app-pages/private-bulk-message-page/message-modal/BulkMessageFormModal';
import PrivateBulkPage from 'pages/app-pages/private-bulk-message-page/PrivateBulkPage';
import { RouteConfig } from 'types';

export const routesConfig: RouteConfig[] = [
  {
    component: AppLayout,
    childRoutes: [
      {
        path: '/',
        component: Navigate,
        props: { to: `/${RoutePath.Dashboard}` },
      },
      {
        path: RoutePath.Dashboard,
        minUserRole: ExtendedUserRole.Agent,
        component: DashboardPage,
        childRoutes: [
          { path: RoutePath.ApplyCoupon, component: ApplyCouponModal },
          { path: RoutePath.AcceptInvitation, component: AcceptInvitationModal },
          { path: RoutePath.RenameProject, component: RenameProjectModal },
          { path: `${RoutePath.ChatActivity}/:chatId`, component: ChatActivityModal },
          { path: RoutePath.CreateProject, component: CreateProjectModal },
          { path: RoutePath.ConnectApp, component: CreateProjectModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
      {
        path: RoutePath.Projects,
        component: ProjectsPage,
        minUserRole: ExtendedUserRole.Editor,
        childRoutes: [
          { path: RoutePath.RenameProject, component: RenameProjectModal },
          { path: RoutePath.CreateProject, component: CreateProjectModal },
          { path: RoutePath.ConnectApp, component: CreateProjectModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
      {
        path: RoutePath.ManageAccount,
        component: ManageAccountPage,
        minUserRole: ExtendedUserRole.Agent,
        childRoutes: [
          { path: RoutePath.ApplyCoupon, component: ApplyCouponModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
      {
        path: RoutePath.Organization,
        component: OrganizationPage,
        minUserRole: ExtendedUserRole.Agent,
        childRoutes: [
          { path: RoutePath.InviteToOrganization, component: InviteToOrganizationModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
      {
        path: `/${RoutePath.ProjectManager}/:id`,
        component: ProjectManagerPage,
        minUserRole: ExtendedUserRole.Editor,
        childRoutes: [
          { path: RoutePath.RenameProject, component: RenameProjectModal },
          { path: RoutePath.Publish, component: PublishModal },
          { path: RoutePath.ConnectApp, component: CreateProjectModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
      {
        path: RoutePath.Activity,
        component: ActivityPage,
        minUserRole: ExtendedUserRole.Agent,
        childRoutes: [
          { path: `${RoutePath.ChatActivity}/:chatId`, component: ChatActivityModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
      {
        path: RoutePath.Tickets,
        component: TicketingPage,
        minUserRole: ExtendedUserRole.Agent,
        childRoutes: [
          { path: `${RoutePath.ChatActivity}/:chatId`, component: ChatActivityModal },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
        ],
      },
    ],
  },

  {
    path: `/${RoutePath.ChatbotBuilder}/:id`,
    component: ChatbotBuilderPage,
    minUserRole: ExtendedUserRole.Editor,
    childRoutes: [
      { path: RoutePath.Command, component: CommandModal },
      {
        path: RoutePath.Publish,
        component: PublishModal,
        props: { appType: AppTypes.Chatbot },
      },
      { path: RoutePath.Template, component: SelectTemplate },
      { path: RoutePath.RenameProject, component: RenameProjectModal },
      { path: RoutePath.NotFound, component: NotFoundPage },
      { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
    ],
  },
  {
    path: `/${RoutePath.Messenger}/:id`,
    component: MessagesSchedulerPage,
    minUserRole: ExtendedUserRole.Editor,
    childRoutes: [
      {
        path: RoutePath.Publish,
        component: PublishModal,
        props: { appType: AppTypes.Messenger },
      },
      { path: RoutePath.Composer, component: MessageFormModal },
      { path: RoutePath.ConnectGroups, component: ConnectGroups },
      { path: RoutePath.Onboarding, component: MessengerOnboarding },
      { path: RoutePath.RenameProject, component: RenameProjectModal },
      { path: RoutePath.NotFound, component: NotFoundPage },
      { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
    ],
  },
  {
    path: `/${RoutePath.PrivateChatBulkMessages}/:id`,
    component: PrivateBulkPage,
    minUserRole: ExtendedUserRole.Editor,
    childRoutes: [
      {
        path: RoutePath.Publish,
        component: PublishModal,
        props: {
          appType: AppTypes.PrivateChatBulkMessages,
          title: 'Activate Telegram bot in two simple steps',
        },
      },
      { path: RoutePath.Composer, component: BulkMessageFormModal },
      { path: RoutePath.Onboarding, component: BulkOnboarding },
      { path: RoutePath.NotFound, component: NotFoundPage },
      { path: '*', component: Navigate, props: { to: `/${RoutePath.NotFound}` } },
    ],
  },
  {
    // This is a catch-all route for the AppLayout
    path: '*',
    component: NotFoundPage,
  },
  {
    // This is a catch-all route for everything outside AppLayout
    component: SimpleLayout,
    childRoutes: [
      {
        component: AnonymousLayout,
        childRoutes: [
          { path: RoutePath.Login, component: LoginPage },
          { path: RoutePath.SignUp, component: SignUpPage },
          { path: RoutePath.ForgotPassword, component: ForgotPasswordPage },
          { path: RoutePath.NotFound, component: NotFoundPage },
          { path: '*', component: Navigate, props: { to: RoutePath.NotFound, replace: true } },
        ],
      },
    ],
  },
];
