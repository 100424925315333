import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { ApiKey } from 'api';
import { Icon, IconButton, MenuSectionHeader } from 'components/index';
import { AppTypes, RoutePath } from 'enums';
import { appConfig } from 'forms/create-project/utils';
import { useMenu } from 'hooks';
import { menuMinWidth } from 'theme/utils';
import { Project } from 'types';

const AppsSwitcher = ({ currentApp }: { currentApp: AppTypes }) => {
  const { openMenu, isMenuOpen, anchorEl, closeMenu } = useMenu();
  const navigate = useNavigate();
  const currentAppProps = appConfig[currentApp];
  const { id } = useParams();
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  return (
    <>
      <IconButton
        iconProps={{
          sx: { fontSize: '1.7rem' },
        }}
        color='inherit'
        icon={currentAppProps.icon}
        size='large'
        onClick={openMenu}
      />
      <Menu
        open={isMenuOpen}
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        <MenuSectionHeader title='Apps' />
        {project?.apps?.map((app) => (
          <MenuItem
            selected={app.type === currentApp}
            key={app.type}
            onClick={() => {
              closeMenu();
              navigate(`/${appConfig[app.type].appUrl}/${id}`);
            }}
          >
            <ListItemIcon>
              <Icon icon={appConfig[app.type].icon} fontSize='small' />
            </ListItemIcon>
            <ListItemText>{appConfig[app.type].label}</ListItemText>
          </MenuItem>
        ))}
        {project?.apps && project?.apps.length > 0 && <Divider />}
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate(`/${RoutePath.ProjectManager}/${project?.id}`);
          }}
        >
          <ListItemIcon>
            <Icon icon='exit_to_app' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Back to Project</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppsSwitcher;
