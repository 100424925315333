import { IconButton, IconButtonProps } from '@mui/material';
import { Icon } from 'components/index';
import { useMenu } from 'hooks';
import ProjectDropdownMenu from 'pages/projects-page/ProjectDropdownMenu';

interface BotMenuWrapperProps extends IconButtonProps {
  id: string;
  icon: string;
}

const ProjectDropdownWrapper = ({ id, icon, ...rest }: BotMenuWrapperProps) => {
  const { openMenu, isMenuOpen, ...restMenu } = useMenu();

  return (
    <>
      <IconButton onClick={openMenu} color='primary' aria-label='settings' {...rest}>
        <Icon icon={icon} fontSize='small' />
      </IconButton>
      {isMenuOpen && (
        <ProjectDropdownMenu openMenu={openMenu} isMenuOpen={isMenuOpen} id={id} {...restMenu} />
      )}
    </>
  );
};

export default ProjectDropdownWrapper;
