import { compact } from 'lodash';
import { useCallback } from 'react';
import { mutate } from 'swr';
import { api, ApiKey } from 'api';
// eslint-disable-next-line import/no-internal-modules
import { useServerError } from 'hooks/useServerError';
import { AutoCompleteLabel, UserLabel } from 'types';
import { getRandomColor } from 'utils';
import { useGetUserLabels } from './useGetUserLabels';

// Your custom hook
export const useProcessNewLabels = () => {
  const { labels: userLabels } = useGetUserLabels();
  const toastServerError = useServerError();

  return useCallback(
    async (labels: AutoCompleteLabel[] = []) => {
      const existingLabelIds: string[] = [];
      const newLabelValues: string[] = [];

      // Splitting formData.labels into existing and new ones
      labels?.forEach((label) => {
        if (userLabels.some((userLabel) => userLabel.id === label.value)) {
          existingLabelIds.push(label.value);
        } else {
          newLabelValues.push(label as unknown as string);
        }
      });

      // Attempt to create new labels and get their IDs
      const newLabelIds = await Promise.all(
        newLabelValues.map(async (newValue) => {
          try {
            const response = await api.post<UserLabel>(`${ApiKey.UserLabel}`, {
              name: newValue,
              icon: 'label',
              color: getRandomColor(),
            });
            return response.data.id;
          } catch (error) {
            toastServerError(error);
            return null;
          }
        }),
      );

      await mutate(ApiKey.UserLabel);

      // Filter out any null values from failed creations
      const filteredNewLabelIds = compact(newLabelIds);

      // Combine existing IDs with new ones
      return [...existingLabelIds, ...filteredNewLabelIds];
    },
    [toastServerError, userLabels],
  );
};
