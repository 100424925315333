import { AccountPlanType, BotTemplate } from 'enums';
import { BotTemplatesProps } from 'types';

export const botTemplateConfig: Record<BotTemplate, BotTemplatesProps> = {
  [BotTemplate.Blank]: {
    name: 'Blank - Start from scratch',
    shortName: 'Blank',
    template: BotTemplate.Blank,
    description:
      'Build your bot from the ground up. You can add your own commands, questions and answers.',
    plan: AccountPlanType.Basic,
  },
  [BotTemplate.Basic]: {
    name: 'Basic - Telegram recommended commands',
    shortName: 'Basic',
    template: BotTemplate.Basic,
    description: 'Simple bot for your business. Includes basic commands, questions and answers.',
    plan: AccountPlanType.Basic,
  },
  [BotTemplate.LeadCollector]: {
    name: 'Lead Collector - Generate leads for your business',
    shortName: 'Lead Collector',
    template: BotTemplate.LeadCollector,
    description:
      'Bot for lead collection. Includes everything you need to collect leads. Collect emails, phone numbers, and more.',
    plan: AccountPlanType.Basic,
  },
  [BotTemplate.Quiz]: {
    name: 'Quiz - Create any quiz or test',
    shortName: 'Quiz',
    template: BotTemplate.Quiz,
    description:
      'Includes everything you need to create a quiz. Supports multiple choice, true/false, and open-ended questions.',
    plan: AccountPlanType.Basic,
  },
  /* [BotTemplate.LeadGeneration]: {
    name: 'Lead Generation',
    template: BotTemplate.LeadGeneration,
    description: 'Bot for lead generation',
    plan: Plan.Pro,
    info: 'Bot for lead generation. Includes everything you need to generate leads.',
  },
  [BotTemplate.Support]: {
    name: 'Support',
    template: BotTemplate.Support,
    description: 'Bot for customer support',
    plan: Plan.Pro,
    info: 'Bot for customer support. Includes everything you need to support your customers.',
  },
  [BotTemplate.Education]: {
    name: 'Education',
    template: BotTemplate.Education,
    description: 'Bot for education',
    plan: Plan.Pro,
    info: 'Bot for education. Includes everything you need to educate your customers.',
  },
  [BotTemplate.Travel]: {
    name: 'Travel',
    template: BotTemplate.Travel,
    description: 'Bot for travel',
    plan: Plan.Pro,
    info: 'Bot for travel. Includes everything you need to help your customers with travel.',
  },
  [BotTemplate.RealEstate]: {
    name: 'Real Estate',
    template: BotTemplate.RealEstate,
    description: 'Bot for real estate',
    plan: Plan.Business,
    info: 'Bot for real estate. Includes everything you need to help your customers with real estate.',
  },
  [BotTemplate.Restaurant]: {
    name: 'Restaurant',
    template: BotTemplate.Restaurant,
    description: 'Bot for restaurant',
    plan: Plan.Business,
    info: 'Bot for restaurant. Includes everything you need to help your customers with restaurant.',
  },
  [BotTemplate.Legal]: {
    name: 'Legal',
    template: BotTemplate.Legal,
    description: 'Bot for legal',
    plan: Plan.Business,
    info: 'Bot for legal. Includes everything you need to help your customers with legal.',
  },
  [BotTemplate.Fitness]: {
    name: 'Fitness',
    template: BotTemplate.Fitness,
    description: 'Bot for fitness',
    plan: Plan.Business,
    info: 'Bot for fitness. Includes everything you need to help your customers with fitness.',
  },
  [BotTemplate.Beauty]: {
    name: 'Beauty',
    template: BotTemplate.Beauty,
    description: 'Bot for beauty',
    plan: Plan.Business,
    info: 'Bot for beauty. Includes everything you need to help your customers with beauty.',
  }, */
};
