import { RefObject, useEffect } from 'react';

// Define the hook's parameters and callback type
export const useSwipeDown = (
  elementRef: RefObject<HTMLElement>, // Use generic HTMLElement or a more specific element type if needed
  onSwipeDown: (e: Object) => void,
  threshold: number = 50, // Optional threshold parameter to customize swipe sensitivity
): void => {
  useEffect(() => {
    const element = elementRef.current;

    let touchStartY = 0;
    let touchEndY = 0;

    const onTouchStart = (e: TouchEvent) => {
      touchStartY = e.targetTouches[0].clientY;
    };

    const onTouchEnd = (e: TouchEvent) => {
      touchEndY = e.changedTouches[0].clientY;
      if (touchEndY > touchStartY + threshold) {
        onSwipeDown(e);
      }
    };

    element?.addEventListener('touchstart', onTouchStart);
    element?.addEventListener('touchend', onTouchEnd);

    return () => {
      element?.removeEventListener('touchstart', onTouchStart);
      element?.removeEventListener('touchend', onTouchEnd);
    };
  }, [elementRef, onSwipeDown, threshold]); // Include threshold if you decide to make it adjustable
};
