import { Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { BasicModal } from '@common-components';
import { api, ApiKey } from 'api';

import { useFormProvider, useMount, useNavigateBack, useServerError } from 'hooks';
import { useDirtyStore } from 'store';
import { App, Project, ProjectCreateProps } from 'types';
import BackButton from './components/BackButton';
import ProjectDetailsStep from './steps/ProjectDetailsStep';
import SelectAppStep from './steps/SelectAppStep';
import { CreateProjectFields, CreateProjectFieldsNames, CreateProjectSteps, Mode } from './types';
import { appConfig, createProjectSchema, defaultCreateProjectFormValues } from './utils';

const CreateProjectModal = () => {
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(CreateProjectSteps.ProjectDetails);

  const metadata = location.state as
    | {
        mode: Mode;
        projectId?: string;
        apps?: Project['apps'];
        projectName?: string;
      }
    | undefined;

  const formMethods = useFormProvider<CreateProjectFields>({
    reValidateMode: 'onChange',
    schema: createProjectSchema,
    defaultValues: defaultCreateProjectFormValues,
  });
  const toastServerError = useServerError();
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, isValid },
    trigger,
  } = formMethods;

  const projectName = watch(CreateProjectFieldsNames.Name);

  const handleOnSubmit = async (formData: CreateProjectFields) => {
    setIsDirty(false);
    try {
      const response =
        metadata?.mode === Mode.Connect
          ? await api.post<Project, AxiosResponse<Project>, Partial<App>>(
              `${ApiKey.Project}/${metadata.projectId}/${ApiKey.App}`,
              {
                type: formData.selectedApp!,
              },
            )
          : await api.post<Project, AxiosResponse<Project>, Partial<ProjectCreateProps>>(
              ApiKey.Project,
              {
                name: formData.name,
                description: formData.description,
                apps: [
                  {
                    type: formData.selectedApp!,
                  },
                ],
              },
            );
      await mutate(ApiKey.Project);
      await mutate(`${ApiKey.Project}/${response.data.id}`);
      const { appUrl } = appConfig[formData.selectedApp!];
      navigate(`/${appUrl}/${response.data.id}`, { replace: true });
    } catch (error: any) {
      toastServerError(error);
    }
  };

  const changeStep = (step: CreateProjectSteps) => {
    setActiveStep(step);
  };

  useMount(() => {
    if (metadata?.mode === Mode.Connect) {
      setValue(CreateProjectFieldsNames.Name, metadata?.projectName || '');
      reset({ ...getValues() }, { keepValues: true });
      setActiveStep(CreateProjectSteps.SelectApp);
    }
  });

  const onAppSelection = () => {
    handleSubmit(handleOnSubmit)();
  };
  const validateAndGoToAppSelect = async () => {
    const detailsStepIsValid = await trigger(['name', 'description']);
    if (detailsStepIsValid) {
      changeStep(CreateProjectSteps.SelectApp);
    }
  };

  if (!metadata?.mode) {
    navigateBack();
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        onSubmit={handleSubmit(handleOnSubmit)}
        titleComponent={
          <Stack direction='row' alignItems='center' gap={1}>
            <BackButton
              setValue={setValue}
              mode={metadata?.mode}
              activeStep={activeStep}
              changeStep={changeStep}
              isSubmitting={isSubmitting}
            />
            <Typography variant='h6' component='h2'>
              {metadata.mode === Mode.Connect
                ? `Connect new App to ${projectName}`
                : `Create ${projectName} project`}
            </Typography>
          </Stack>
        }
        label='create-new-project-dialog'
        cancelButton={
          metadata?.mode === Mode.Connect || activeStep === CreateProjectSteps.ProjectDetails
        }
        blockCloseAttempt={isSubmitting}
        maxWidth='sm'
        mainAction={
          activeStep === CreateProjectSteps.ProjectDetails
            ? {
                children: 'Agree & Continue',
                type: 'button',
                onClick: validateAndGoToAppSelect,
                isSubmitting,
                color: isValid ? 'secondary' : 'primary',
              }
            : undefined
        }
        open
      >
        {activeStep === CreateProjectSteps.ProjectDetails && <ProjectDetailsStep />}
        {activeStep === CreateProjectSteps.SelectApp && (
          <SelectAppStep mode={metadata?.mode} apps={metadata?.apps} selectApp={onAppSelection} />
        )}
      </BasicModal>
    </FormProvider>
  );
};

export default CreateProjectModal;
