import { array, object, ObjectSchema, string } from 'yup';
import {
  FileStepFormFields,
  FileStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/file/types';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import {
  customYupValidateImageOrFile,
  customYupValidateURL,
  editorValidationOptional,
  emptyEditorValue,
} from 'utils';

export const fileStepFormSchema: ObjectSchema<FileStepFormFields> = object({
  [FileStepFormFieldsNames.NodeId]: string().required(),
  [FileStepFormFieldsNames.Name]: string().required('Name is required'),
  [FileStepFormFieldsNames.FileUrl]: string()
    .required('File Url is required')
    .test('customYupValidateURL', 'customYupValidateURL', customYupValidateURL())
    .test(
      'customYupValidateImageOrFile',
      'customYupValidateImageOrFile',
      customYupValidateImageOrFile(),
    ),
  [FileStepFormFieldsNames.Input]: editorValidationOptional.max(1024),
  [FileStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultFileStepFormValues: Partial<FileStepFormFields> = {
  [FileStepFormFieldsNames.Input]: emptyEditorValue,
  [FileStepFormFieldsNames.NodeId]: '',
  [FileStepFormFieldsNames.Labels]: [],
};

export const fileFormFieldsConfig: Record<FileStepFormFieldsNames, FormFieldProps> = {
  [FileStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: FileStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [FileStepFormFieldsNames.Input]: {
    placeholder: 'Caption',
    name: FileStepFormFieldsNames.Input,
    type: 'text',
  },
  [FileStepFormFieldsNames.FileUrl]: {
    label: 'File Url',
    placeholder: '* Image/Document link',
    name: FileStepFormFieldsNames.FileUrl,
    type: 'text',
  },

  [FileStepFormFieldsNames.NodeId]: {
    name: FileStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [FileStepFormFieldsNames.Labels]: labelFormFieldConfig,
};
