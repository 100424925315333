import { array, object, ObjectSchema } from 'yup';
import { LabelsFormFields, LabelsFormFieldsNames } from 'pages/activity-page/labels/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';

export const labelsFormSchema: ObjectSchema<LabelsFormFields> = object({
  [LabelsFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().defined(),
});

export const labelsNoteDefaultValues: LabelsFormFields = {
  [LabelsFormFieldsNames.Labels]: [],
};

export const labelsFieldsConfig: Record<LabelsFormFieldsNames, FormFieldProps> = {
  [LabelsFormFieldsNames.Labels]: {
    placeholder: 'Add Labels, Type to Create',
    name: LabelsFormFieldsNames.Labels,
    type: 'text',
  },
};
