import useSWR from 'swr';
import { ApiKey } from 'api';
import { Project } from 'types';

const defaultProjects: Project[] = [];

export const useGetProjects = () => {
  const { data, error, isLoading } = useSWR<Project[]>(ApiKey.Project);

  return {
    projects: data ?? defaultProjects,
    isLoading,
    error,
  };
};
