import { ButtonBase, CircularProgress, Menu, Stack, Tooltip, Typography } from '@mui/material';
import { memo } from 'react';
import { Handle, NodeProps, NodeToolbar, Position, useReactFlow } from 'reactflow';
import { ConfirmationDialog, Icon, IconButton } from '@common-components';
import { StepType } from 'enums';
import { useMenu } from 'hooks';
import useDeleteNode from 'pages/app-pages/chatbot-builder-page/hooks/useDeleteNode';
import { useDuplicateNode } from 'pages/app-pages/chatbot-builder-page/hooks/useDuplicateNode';
import { useSetViewToNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSetViewToNode';
import useUpdateNodeStepType from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeStepType';
import useYupValidation from 'pages/app-pages/chatbot-builder-page/hooks/useYupValidation';
import NodeWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/NodeWrapper';

import { StepCategoryConfig, StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';

import { addStepMenuProps } from 'pages/app-pages/chatbot-builder-page/nodes/input-node/config';
import StepSelector from 'pages/app-pages/chatbot-builder-page/nodes/input-node/StepSelector';
import StepSelectorItem from 'pages/app-pages/chatbot-builder-page/nodes/input-node/StepSelectorItem';
import { StepCategory } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { useBuilderStore, useLayoutDirection } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { TourSteps } from 'pages/app-pages/chatbot-builder-page/tour/config';
import { useDirtyStore } from 'store';
import { BaseNodeProps } from 'types';

const InputNode = ({ id }: NodeProps<BaseNodeProps>) => {
  const updateNodeStepType = useUpdateNodeStepType();
  const deleteNode = useDeleteNode();
  const duplicateNode = useDuplicateNode();
  const { getNode } = useReactFlow<BaseNodeProps>();
  const setViewToNode = useSetViewToNode();

  const node = getNode(id);

  const isValid = useYupValidation(
    node?.data.selectedStep
      ? StepsConfig[node?.data.selectedStep].schema
      : StepsConfig[StepType.Message].schema,
    node?.data.formData,
  );

  const { setSelectedStepType, selectedNodeId } = useBuilderStore((state) => ({
    setSelectedStepType: state.setSelectedStepType,
    selectedNodeId: state.selectedNodeId,
  }));

  const { isDirty, isSavingToServer } = useDirtyStore((state) => ({
    isDirty: state.isDirty,
    isSavingToServer: state.isSavingToServer,
  }));

  const { layoutDirection } = useLayoutDirection((state) => ({
    layoutDirection: state.layoutDirection,
  }));

  const {
    anchorEl: stepMenuAnchorEl,
    closeMenu: closeStepMenu,
    isMenuOpen: isStepMenuOpen,
    openMenu: openStepMenu,
  } = useMenu();

  const { openMenu: openDeletePrompt, ...restDeletePrompt } = useMenu();

  if (!node) {
    return null;
  }

  const { label, selectedStep, formData } = node.data;

  const handleStepTypeChange = async (step: StepType) => {
    closeStepMenu();
    setViewToNode(id);
    await updateNodeStepType({ id, stepType: step });
  };

  const onNodeClick = () => {
    setSelectedStepType(selectedStep);
  };

  const selectedStepConfig = selectedStep && StepsConfig[selectedStep];

  return (
    <>
      <Handle
        type='target'
        position={layoutDirection === 'horizontal' ? Position.Left : Position.Top}
      />

      {selectedStepConfig ? (
        <>
          <NodeWrapper
            selected={selectedNodeId === id}
            onClick={onNodeClick}
            id={id}
            toolboxTab={ToolboxTabs.NodeEditor}
          >
            <Stack width={1} gap={0.5} px={2} pb={2} pt={1} className={TourSteps.EditStep}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='subtitle2' color='text.secondary'>
                  {label}
                </Typography>
                <Tooltip title={isValid ? 'Step Completed' : 'Step Incomplete'} placement='top'>
                  <Icon
                    icon={isValid ? 'check_circle' : 'error'}
                    color={isValid ? 'success' : 'warning'}
                    fontSize='small'
                  />
                </Tooltip>
              </Stack>
              <StepSelectorItem
                label={formData?.name || selectedStepConfig.name}
                description=''
                icon={selectedStepConfig.icon}
                color={StepCategoryConfig[selectedStepConfig.category].color}
                selected={selectedNodeId === id}
                type={selectedStep}
              />
            </Stack>
          </NodeWrapper>
          <NodeToolbar
            isVisible={selectedNodeId === id}
            position={layoutDirection === 'horizontal' ? Position.Top : Position.Right}
          >
            <Stack
              direction={layoutDirection === 'horizontal' ? 'row' : 'column'}
              alignItems='center'
            >
              <IconButton
                tooltipPlacement={layoutDirection === 'horizontal' ? 'top' : 'right'}
                tooltip='Change Node Type'
                icon='rebase_edit'
                size='small'
                onClick={openStepMenu}
                disabled={isSavingToServer}
              />
              {selectedStepConfig.category !== StepCategory.MultiNodeInput && isValid && (
                <IconButton
                  tooltipPlacement={layoutDirection === 'horizontal' ? 'top' : 'right'}
                  tooltip={isDirty ? 'Save Node before Duplicate' : 'Duplicate Node'}
                  icon='content_copy'
                  size='small'
                  disabled={isDirty || isSavingToServer}
                  onClick={async () => {
                    await duplicateNode(id);
                  }}
                />
              )}

              <IconButton
                tooltipPlacement={layoutDirection === 'horizontal' ? 'top' : 'right'}
                tooltip='Delete Node'
                icon='delete'
                size='small'
                disabled={isSavingToServer}
                onClick={openDeletePrompt}
              />
            </Stack>
          </NodeToolbar>
        </>
      ) : (
        <Stack
          justifyContent='center'
          alignItems='center'
          width={220}
          height={132}
          borderRadius={1}
          border={(theme) => `1px dashed ${theme.palette.grey[400]}`}
          disabled={isSavingToServer}
          component={ButtonBase}
          onClick={(event) => {
            event.stopPropagation();
            openStepMenu(event);
          }}
          sx={{
            transition: (theme) =>
              theme.transitions.create(['border-color', 'box-shadow'], {
                duration: theme.transitions.duration.shortest,
              }),
            cursor: 'pointer',
            '&:hover': {
              border: (theme) => `1px dashed ${theme.palette.grey[700]}`,
            },
          }}
        >
          {isSavingToServer ? (
            <CircularProgress thickness={1} size={34} color='info' />
          ) : (
            <Tooltip placement='top' title='Add Step' arrow>
              <Stack
                width={34}
                height={34}
                justifyContent='center'
                alignItems='center'
                sx={{
                  border: 1,
                  borderRadius: 2,
                  borderColor: 'info.main',
                }}
              >
                <Icon icon='add' fontSize='medium' color='info' />
              </Stack>
            </Tooltip>
          )}
        </Stack>
      )}

      <Menu
        {...addStepMenuProps}
        id='step-selector-menu'
        aria-labelledby='step-selector-menu'
        anchorEl={stepMenuAnchorEl}
        open={isStepMenuOpen}
        onClose={() => {
          closeStepMenu();
        }}
        PopoverClasses={{
          paper: TourSteps.StepSelect,
        }}
      >
        <StepSelector handleChange={handleStepTypeChange} currentStep={selectedStep} />
      </Menu>
      <ConfirmationDialog
        {...restDeletePrompt}
        submittingText='Deleting...'
        text={
          node.data.formData?.menu?.length && node.data.formData.menu.length > 1
            ? 'Caution! By deleting this step, you will lose all the connected nodes and data\n' +
              'associated with it. Are you sure you want to delete this step?'
            : 'Are you sure you want to delete this step?'
        }
        onConfirm={async () => {
          restDeletePrompt.closeMenu();
          await deleteNode({ id });
        }}
      />
      <Handle
        style={{
          visibility: formData?.nodeId ? 'visible' : 'hidden',
        }}
        type='source'
        position={layoutDirection === 'horizontal' ? Position.Right : Position.Bottom}
      />
    </>
  );
};

export default memo(InputNode);
