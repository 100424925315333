export enum BuilderSettingsFormFieldsNames {
  Name = 'name',
  Type = 'type',
  Rank = 'rank',
  Rules = 'rules',
  MenuOptions = 'menuOptions',
  Mode = 'mode',
  Active = 'active',
  Rating = 'rating',
  SyncOptions = 'syncOptions',
  SelectOptions = 'selectOptions',
  FlatSelectOptions = 'flatSelectOptions',
  Slider = 'slider',
  MultiSlider = 'multiSlider',
}

export enum BuilderType {
  Bot = 'bot',
  Skill = 'skill',
  Action = 'action',
  Trigger = 'trigger',
  Intent = 'intent',
  Entity = 'entity',
  Flow = 'flow',
  Form = 'form',
  Story = 'story',
  Response = 'response',
  Rule = 'rule',
  Config = 'config',
  Domain = 'domain',
  ActionServer = 'actionServer',
}

export interface BuilderSettingsFields {
  [BuilderSettingsFormFieldsNames.Name]: string;
  [BuilderSettingsFormFieldsNames.Type]: BuilderType | '';
  [BuilderSettingsFormFieldsNames.Rank]: string | '';
  [BuilderSettingsFormFieldsNames.Rules]: string[];
  [BuilderSettingsFormFieldsNames.MenuOptions]: string[];
  [BuilderSettingsFormFieldsNames.Mode]: string | '';
  [BuilderSettingsFormFieldsNames.Active]: boolean;
  [BuilderSettingsFormFieldsNames.Rating]: number | null;
  [BuilderSettingsFormFieldsNames.SyncOptions]: string | '';
  [BuilderSettingsFormFieldsNames.SelectOptions]: string[];
  [BuilderSettingsFormFieldsNames.FlatSelectOptions]: string | '';
  [BuilderSettingsFormFieldsNames.Slider]: number;
  [BuilderSettingsFormFieldsNames.MultiSlider]: number[];
}
