import { boolean, object, ObjectSchema, string } from 'yup';
import { FormFieldProps } from 'types';
import { UserLabelFieldsNames, UserLabelFormFields } from './types';

export const userLabelSchema: ObjectSchema<UserLabelFormFields> = object({
  [UserLabelFieldsNames.Name]: string().required('Label Name is required'),
  [UserLabelFieldsNames.Color]: string().optional(),
  [UserLabelFieldsNames.IsDisabled]: boolean().optional(),
});

export const userLabelDefaultValues: UserLabelFormFields = {
  [UserLabelFieldsNames.Name]: '',
  [UserLabelFieldsNames.Color]: '',
  [UserLabelFieldsNames.IsDisabled]: false,
};

export const userLabelFieldsConfig: Record<UserLabelFieldsNames, FormFieldProps> = {
  [UserLabelFieldsNames.Name]: {
    label: '* Name',
    placeholder: 'Project Name',
    name: UserLabelFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [UserLabelFieldsNames.Color]: {
    label: 'Description',
    placeholder: 'Project Description',
    name: UserLabelFieldsNames.Color,
    autoComplete: 'off',
    type: 'text',
  },
  [UserLabelFieldsNames.IsDisabled]: {
    label: 'Disabled',
    placeholder: 'Project Description',
    name: UserLabelFieldsNames.IsDisabled,
    type: 'checkbox',
  },
};
