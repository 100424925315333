import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { BasicModal } from '@common-components';
import { api, ApiKey } from 'api';
import { botTemplateConfig, planConfig } from 'config';
import { AppTypes, BotTemplate } from 'enums';
import SelectCard from 'forms/create-project/components/SelectCard';
import { useServerError, useToast } from 'hooks';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { App, Project } from 'types';

const SelectTemplate = () => {
  const items = Object.values(botTemplateConfig);
  const { id } = useParams();
  const { value: selecting, setTrue: startSelecting, setFalse: stopSelecting } = useBoolean();
  const [selectedTemplate, setSelectedTemplate] = useState<BotTemplate | null>(null);
  const toastServerError = useServerError();
  const navigate = useNavigate();
  const toast = useToast();
  const { setSelectedCommand } = useBuilderStore((state) => ({
    setSelectedCommand: state.setSelectedCommand,
  }));

  const onSelect = async ({
    template,
    selectTemplateShown,
  }: {
    template?: BotTemplate;
    selectTemplateShown?: true;
  }) => {
    startSelecting();

    const requestBody: Partial<App> = {};

    // Add properties conditionally
    if (template) {
      requestBody.template = template;
      requestBody.selectTemplateShown = true;
    }
    if (selectTemplateShown) {
      requestBody.selectTemplateShown = true;
    }

    try {
      await api.patch<Project, AxiosResponse<Project>, Partial<App>>(
        `${ApiKey.Project}/${id}/${ApiKey.App}/${AppTypes.Chatbot}`,
        requestBody,
      );
      const project = await mutate<Project>(`${ApiKey.Project}/${id}`);

      setSelectedCommand(
        project?.bot?.draft.commands.find((command) => command.name === 'start') || null,
      );

      stopSelecting();

      if (template) {
        toast({
          message: `${botTemplateConfig[template!].shortName} template selected successfully`,
          variant: 'info',
        });
      }

      navigate('./..', { replace: true });
    } catch (error) {
      toastServerError(error);
      stopSelecting();
    }
  };

  return (
    <BasicModal
      blockCloseAttempt={selecting}
      open
      title='Welcome to Chatbot Builder'
      label='Select Template'
      handleClose={async () => {
        await onSelect({ selectTemplateShown: true });
      }}
    >
      <Stack gap={2}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='body1' noWrap>
            Select a template to start with
          </Typography>
          <Button
            startIcon={
              selecting && selectedTemplate === null ? <CircularProgress size={16} /> : null
            }
            onClick={async () => {
              await onSelect({ selectTemplateShown: true });
            }}
          >
            Skip
          </Button>
        </Stack>

        {items.map(({ name, template, plan, description }) => {
          const { name: planName, icon, color } = planConfig[plan];

          return (
            <SelectCard
              disabled={selecting}
              key={name}
              color={color}
              iconDescription={planName}
              icon={icon}
              name={name}
              description={description}
              loading={selecting && selectedTemplate === template}
              onClick={async () => {
                setSelectedTemplate(template);
                await onSelect({ template });
              }}
            />
          );
        })}
      </Stack>
    </BasicModal>
  );
};

export default SelectTemplate;
