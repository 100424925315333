import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

// Custom Hook for validation with TypeScript
function useYupValidation<T>(schema: yup.ObjectSchema<any>, object: T): boolean {
  const [isValid, setIsValid] = useState(false);

  const validateObject = useCallback(async (s: yup.ObjectSchema<any>, o: T) => {
    try {
      await s.validate(o, { abortEarly: false });
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
    }
  }, []);

  useEffect(() => {
    validateObject(schema, object);
  }, [object, schema, validateObject]);

  return isValid;
}

export default useYupValidation;
