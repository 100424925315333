import { Stack, Typography } from '@mui/material';
import Icon from 'components/Icon';
import { IconButton } from 'components/index';
import Label from 'components/Label';
import { ChatGroup } from 'types';

interface GroupsListProps {
  groups?: ChatGroup[];
}
const GroupsList = ({ groups }: GroupsListProps) => {
  if (!groups) {
    return null;
  }
  const renderGroup = (group: ChatGroup) => (
    <Stack
      key={group.id}
      title={group.name}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      width={1}
      gap={1}
      sx={{
        border: 1,
        bgcolor: 'grey.200',
        px: 2,
        py: 1.5,
        width: 1,
        borderRadius: 1,
        borderColor: 'transparent',
      }}
    >
      <Stack direction='row' alignItems='center' gap={1} flex={1} overflow='hidden'>
        <Icon icon='group' fontSize='small' />
        <Typography noWrap fontWeight='bolder' variant='body2'>
          {group.name}
        </Typography>
      </Stack>
      {group.isVerified ? (
        <Label color='success'>Verified</Label>
      ) : (
        <IconButton
          edge='end'
          tooltipPlacement='top'
          color='primary'
          tooltip='edit'
          icon='edit'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
    </Stack>
  );

  return <Stack gap={1}>{groups.map((group) => renderGroup(group))}</Stack>;
};

export default GroupsList;
