import { useCallback } from 'react';
import { NodeProps, useReactFlow } from 'reactflow';
import {
  LayoutDirection,
  LayoutDirectionStore,
  useLayoutDirection,
} from 'pages/app-pages/chatbot-builder-page/store';

const layoutDirectionSelector = (state: LayoutDirectionStore) => state.layoutDirection;

export const useSetViewToNode = () => {
  const { setCenter, getNode } = useReactFlow();
  const layoutDirection = useLayoutDirection(layoutDirectionSelector);

  return useCallback(
    (id: NodeProps['id'], next = false) => {
      const n = getNode(id);
      const nPosX = n?.position.x || 0;
      const nPosY = n?.position.y || 0;
      let finalX = nPosX;
      let finalY = nPosY;

      if (next) {
        finalX = layoutDirection === LayoutDirection.Horizontal ? nPosX + 400 : nPosX;
        finalY = layoutDirection === LayoutDirection.Horizontal ? nPosY : nPosY + 250;
      }

      setCenter(finalX, finalY, {
        duration: 800,
        zoom: 1,
      });
    },
    [getNode, layoutDirection, setCenter],
  );
};
