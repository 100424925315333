import { Typography } from '@mui/material';
import { useState } from 'react';
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button';
import { api, ApiKey } from 'api';
import Button from 'components/Button';
import { SuccessMessage } from 'components/index';
import { GtmEventsConfig } from 'config';
import { useAuth, useServerError } from 'hooks';
import { User } from 'types';
import { gtmCustomEvent } from 'utils';

interface NotificationIntegrationProps {
  callback?: () => void;
  updateUser: (u: User) => void;
  title?: string;
  description?: string;
}
const NotificationIntegration = ({
  updateUser,
  callback,
  title = 'Stay Connected with Instant Chat Notifications!',
  description = `Never miss a beat in your conversations! Connect with Telegram now and get real-time alerts whenever a new chat sparks up. Simply click below to enable this seamless integration.`,
}: NotificationIntegrationProps) => {
  const { user, setUser } = useAuth();
  const toastServerError = useServerError();
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const handleTelegramLogin = async (tUser: TelegramUser) => {
    try {
      const queryParams = Object.entries(tUser).reduce(
        (acc, [key, value]) => {
          // Only add the property if it's a known key of TelegramUser and the value is not undefined
          if (value !== undefined) {
            acc[key] = String(value);
          }
          return acc;
        },
        {} as Record<string, string>,
      );

      const queryString = new URLSearchParams(queryParams).toString();
      const res = await api.get<User>(`${ApiKey.TelegramLogin}/?${queryString}`);
      const eventData = {
        user: user?.id,
        timestamp: new Date().toISOString(), // ISO string for the publication time
      };

      // Fire the GTM custom event
      gtmCustomEvent(GtmEventsConfig.SubscribeToNotifications, eventData);
      setTriggerConfetti(true);
      setUser?.(res.data);
    } catch (error) {
      toastServerError(error);
    }
  };

  return (
    <>
      <SuccessMessage
        icon={triggerConfetti ? 'check_circle' : 'notifications_active'}
        iconColor={triggerConfetti ? 'success' : 'info'}
        triggerConfetti={triggerConfetti}
        title={triggerConfetti ? 'You’re Successfully Connected with Telegram!' : title}
        description={
          <Typography variant='body1' align='center' mb={1}>
            {description}
          </Typography>
        }
      />
      {triggerConfetti ? (
        <Button
          onClick={() => {
            if (user) {
              updateUser(user);
            }
            callback?.();
          }}
          variant='contained'
        >
          Close and Continue
        </Button>
      ) : (
        <TelegramLoginButton
          cornerRadius={12}
          botName={
            process.env.REACT_APP_ENV === 'production'
              ? 'TeleformOfficialBot'
              : 'teleform_notifications_qa_bot'
          }
          dataOnauth={handleTelegramLogin}
        />
      )}
    </>
  );
};

export default NotificationIntegration;
