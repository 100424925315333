import { Badge, Menu, Stack } from '@mui/material';
import { useState } from 'react';
import { IconButton } from 'components/index';
import { useAuth, useMenu } from 'hooks';
import { menuMinWidth } from 'theme/utils';
import { User } from 'types';
import NotificationIntegration from './NotificationIntegration';
import NotificationsSettings from './NotificationsSettings';

const NotificationsMenu = () => {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();
  const { user, setUser } = useAuth();
  const [localUser, setLocalUser] = useState<User | null>(user);

  const onLocalUserUpdate = (u: User) => {
    setUser?.(u);
    setLocalUser(u);
  };

  return (
    <>
      <Badge
        invisible={!!localUser?.telegram?.id}
        badgeContent={1}
        overlap='circular'
        color='error'
      >
        <IconButton
          onClick={openMenu}
          color={!localUser?.telegram?.id ? 'error' : 'default'}
          icon='notifications'
        />
      </Badge>
      <Menu
        id='notifications-menu'
        aria-labelledby='notifications-menu-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => {
          setLocalUser(user);
          closeMenu();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth, maxWidth: 420 } },
        }}
      >
        {!localUser?.telegram?.id ? (
          <Stack p={3} alignItems='center'>
            <NotificationIntegration callback={closeMenu} updateUser={onLocalUserUpdate} />
          </Stack>
        ) : (
          <Stack px={3} pt={1} pb={2} alignItems='center'>
            <NotificationsSettings user={localUser} setUser={onLocalUserUpdate} />
          </Stack>
        )}
      </Menu>
    </>
  );
};

export default NotificationsMenu;
