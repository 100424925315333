import { Alert, Collapse, Menu, Stack } from '@mui/material';
import { useState } from 'react';
import { Button } from 'components/index';
import { useAuth, useIsMobile, useMenu } from 'hooks';
import NotificationIntegration from 'layout/app-header/NotificationIntegration';
import { menuMinWidth } from 'theme/utils';
import { User } from 'types';

const ConnectWithTelegram = () => {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();
  const { user } = useAuth();
  const [localUser, setLocalUser] = useState<User | null>(user);
  const { isMobile } = useIsMobile();
  const onLocalUserUpdate = (u: User) => {
    setLocalUser(u);
  };
  return (
    <>
      <Collapse in={!localUser?.telegram?.id}>
        <Alert
          sx={{ cursor: 'pointer', border: 1, borderColor: 'divider' }}
          action={
            <Button color='inherit' size='small'>
              Login
            </Button>
          }
          icon={false}
          onClick={openMenu}
          severity='error'
        >
          In order to receive notifications, you need to authenticate with Telegram account.
        </Alert>
      </Collapse>

      {!localUser?.telegram?.id && (
        <Menu
          id='notifications-menu'
          aria-labelledby='notifications-menu-button'
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={() => {
            setLocalUser(user);
            closeMenu();
          }}
          anchorOrigin={
            isMobile
              ? { vertical: 'bottom', horizontal: 'right' }
              : { vertical: 'top', horizontal: 'left' }
          }
          transformOrigin={
            isMobile
              ? { vertical: 'top', horizontal: 'center' }
              : { vertical: 'top', horizontal: 'right' }
          }
          slotProps={{
            paper: { sx: { minWidth: menuMinWidth, maxWidth: 420 } },
          }}
        >
          <Stack p={3} alignItems='center'>
            <NotificationIntegration callback={closeMenu} updateUser={onLocalUserUpdate} />
          </Stack>
        </Menu>
      )}
    </>
  );
};

export default ConnectWithTelegram;
