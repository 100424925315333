import { useCallback } from 'react';
import { Node, useReactFlow } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { StepType } from 'enums';
import { useUndoRedoContext } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import useSaveToServer from 'pages/app-pages/chatbot-builder-page/hooks/useSaveToServer';
import { useSetViewToNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSetViewToNode';
import useValidateTreeStructure from 'pages/app-pages/chatbot-builder-page/hooks/useValidateTreeStructure';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { NodeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { BaseNodeProps } from 'types';

const useEdgeClick = () => {
  const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
  const { takeSnapshot, undo } = useUndoRedoContext();
  const setViewToNode = useSetViewToNode();

  const saveToServer = useSaveToServer();
  const validateTreeStructure = useValidateTreeStructure();

  const { setSelectedStepType, openToolbox, setActiveToolboxTab, setSelectedNodeId } =
    useBuilderStore((state) => ({
      setSelectedStepType: state.setSelectedStepType,
      openToolbox: state.openToolbox,
      setActiveToolboxTab: state.setActiveToolboxTab,
      setSelectedNodeId: state.setSelectedNodeId,
    }));

  return useCallback(
    async (edgeId: string, stepType: StepType) => {
      const allNodes = getNodes();
      const allEdges = getEdges();
      const originalEdge = allEdges.find((e) => e.id === edgeId);
      if (!originalEdge) {
        return;
      } // Edge not found, exit early

      const sourceNode = allNodes.find((n) => n.id === originalEdge.target);

      if (!sourceNode) {
        return;
      } // Source node not found, exit early

      takeSnapshot();

      const sourceNodeIndex = getNodes().findIndex((node) => node.id === sourceNode.id);

      const newId = uuid();
      const newNode: Node<BaseNodeProps> = {
        id: sourceNode.id,
        position: { x: sourceNode.position.x, y: sourceNode.position.y },
        selected: true,
        data: {
          label: 'New Step',
          step: sourceNode.data.step + 1,
          selectedStep: stepType,
          formData: {
            nodeId: newId || '', // Set the nodeId to the ID of the duplicate node
            name: StepsConfig[stepType].name,
          },
          mentions: sourceNode.data.mentions,
        },
        type: NodeType.Input,
        draggable: false,
      };

      setNodes((nds) => {
        // Find the original node and create a modified copy with updated formData.nodeId
        const updatedOriginalNode = {
          ...nds[sourceNodeIndex],
          selected: false,
          id: newId,
        };

        // Insert the updated original node and the new node into the nodes array
        return [
          ...nds.slice(0, sourceNodeIndex), // Include all nodes up to the original node
          newNode, // Insert the new duplicate node after the original node
          updatedOriginalNode, // Insert the updated original node
          ...nds.slice(sourceNodeIndex + 1), // Include the rest of the nodes after the original node
        ];
      });
      setEdges((eds) => {
        // Step 1: Update existing edges that were connected to the original node
        // to make them connected from the new node to their original targets.
        const updatedEdges = eds.map((edge) => {
          if (edge.source === sourceNode.id) {
            // For edges where the original node was the source, update the source to the new node's ID.
            return { ...edge, source: newId };
          }
          return edge;
        });

        // Step 2: Create a new edge from the new node (which now occupies the position of the original node and thus has its ID)
        // to the original node (which now has a new ID and is effectively a new entity in the flow).
        const newEdgeConnectingNewNodeToOriginal = {
          id: `${sourceNode.id}->${newId}`, // Since sourceNode.id is now the new node's ID and newId is the original node's new ID
          source: sourceNode.id, // New node's ID (previously original node's ID)
          target: newId, // Original node's new ID
          deletable: false, // Or true, depending on your application logic
        };

        // Include this new edge in the updated edges array
        return [...updatedEdges, newEdgeConnectingNewNodeToOriginal];
      });

      if (!validateTreeStructure()) {
        undo();
        return;
      }

      setViewToNode(sourceNode.id);
      setSelectedStepType(stepType);
      openToolbox();
      setActiveToolboxTab(ToolboxTabs.NodeEditor);
      setSelectedNodeId(sourceNode.id);

      await saveToServer(); // You wait for the promise to resolve here
    },
    [
      getNodes,
      getEdges,
      takeSnapshot,
      setNodes,
      setEdges,
      validateTreeStructure,
      setViewToNode,
      setSelectedStepType,
      openToolbox,
      setActiveToolboxTab,
      setSelectedNodeId,
      saveToServer,
      undo,
    ],
  );
};

export default useEdgeClick;
