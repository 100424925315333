import { CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BasicColors } from 'theme';

interface DisplayBoxProps {
  title: string;
  value: number;
  tooltip: string;
  color?: BasicColors;
  loading: boolean;
}
const DisplayBox = ({
  title,
  value,

  //  color = 'primary',

  tooltip,
  loading,
}: DisplayBoxProps) => {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    let startTimestamp: number | null = null;

    const step = (timestamp: number) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      // Calculate progress
      const progress = Math.min((timestamp - startTimestamp) / 1000, 1);
      const animatedValue = Math.floor(progress * value);

      setDisplayValue(animatedValue);

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, [value]);

  return (
    <Tooltip title={tooltip} placement='top' arrow>
      <Stack
        gap={{
          xs: 1,
          md: 0.5,
        }}
        px={1}
        py={1}
        alignItems='center'
        justifyContent='center'
        borderRadius={1}
        border={1}
        borderColor='divider'
        bgcolor='background.default'
        overflow='hidden'
        flex={1}
        direction={{
          xs: 'row-reverse',
          md: 'column',
        }}
      >
        {loading ? (
          <CircularProgress size={30} color='primary' />
        ) : (
          <>
            <Typography
              order={{ xs: 1, md: 0 }}
              // color={`${color}.main`}
              color='primary.main'
              sx={{
                fontSize: { xs: 32, md: 44 },
                fontWeight: 900,
                lineHeight: 1,
              }}
            >
              {displayValue}
            </Typography>
            <Typography
              order={{ xs: 0, md: 1 }}
              noWrap
              lineHeight='normal'
              variant='caption'
              fontWeight='bolder'
              // color={`${color}.main`}
              color='primary.dark'
            >
              {title}
            </Typography>
          </>
        )}
      </Stack>
    </Tooltip>
  );
};

export default DisplayBox;
