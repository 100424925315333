import { compact, find, map } from 'lodash';
import { string, StringSchema, TestContext } from 'yup';
import { validationOptions } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/types';
import { AutocompleteObjectProps } from 'types';

const allowedSymbols = '_';
export const supportedImagesTypes = ['jpeg', 'jpg', 'png'];
export const supportedFilesTypes = ['pdf', 'gif', 'doc'];

export const characterLimitValue = 1024;

export function isImageUrl(url: string) {
  // Regular expression to match common image file extensions
  const imageExtensions = /\.(jpeg|jpg|png)$/i;

  // Check if the URL ends with an image extension
  return imageExtensions.test(url);
}

export const stripHtml = (html: string) => {
  // Remove HTML tags
  let text = html.replace(/<[^>]*>?/gm, '');

  // Decode HTML entities
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  text = textArea.value;

  // Remove zero-width spaces and other non-printable characters
  text = text.replace(/[\u200B-\u200D\uFEFF]/g, '');

  return text;
};

export const createHtmlStrippedLengthTest = (maxLength: number): StringSchema =>
  string().test(
    'strip-html-and-length',
    `Message must be ${maxLength} characters or less`,
    (value) => {
      const strippedValue = stripHtml(value || '');
      return strippedValue.length <= maxLength;
    },
  );

export function isDocumentUrl(url: string) {
  // Regular expression to match common image file extensions
  const imageExtensions = /\.(pdf|gif|doc)$/i;

  // Check if the URL ends with an image extension
  return imageExtensions.test(url);
}

export const customYupValidateURL = () => (value: string, context: TestContext) => {
  const { path, createError } = context;
  const urlRegex =
      /^(http(s)?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;
  const tgRegex = /^tg:\/\/user\?id=\d+$/;

  if (value && !(urlRegex.test(value) || tgRegex.test(value))) {
    return createError({
      path,
      message: 'Invalid Url',
    });
  }

  return true;
};

export const customYupValidateImageOrFile = () => (value: string, context: TestContext) => {
  const { path, createError } = context;

  if (value && !isDocumentUrl(value) && !isImageUrl(value)) {
    return createError({
      path,
      message: `Supported files: ${[...supportedImagesTypes, ...supportedFilesTypes].join(', ')}`,
    });
  }

  return true;
};

export const variableValidation = string().matches(
  /^[a-z\d_]*$/,
  'Only use lowercase letters, digits, and the following symbols: "_", make sure "_" is not at the beginning or end of the variable name',
);

export const yupPasswordValidation = string()
  .required('No password provided.')
  .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
  .matches(/\d/, 'Must contain at least one digit')
  .test(
    'allowed-characters',
    `Only use letters, digits, and the following symbols: ${allowedSymbols}`,
    (value) =>
      new RegExp(`^[A-Za-z\\d${allowedSymbols.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}]*$`).test(
        value,
      ),
  )
  .min(8, 'Password must be at least 8 characters long')
  .max(32, 'Password can be at most 32 characters long');

export const yupRequiredEmailValidation = string()
  .email('Email should have correct format.')
  .required('No email provided.');

// Function that finds label or value in array of objects
type ArrayWithLabelAndValue = AutocompleteObjectProps[];
type QueryParams = { label: string | string[] } | { value: any | any[] };
export const findLabelOrValue = <T extends ArrayWithLabelAndValue>(
  arr: T,
  query: QueryParams,
): string | any | string[] | any[] | null => {
  if ('label' in query) {
    const labels = Array.isArray(query.label) ? query.label : [query.label];
    const results = labels.map((label) => {
      const foundItem = find(arr, { label });
      return foundItem ? foundItem.value : label;
    });
    return labels.length === 1 ? results[0] : compact(results);
  }
  if ('value' in query) {
    const values = Array.isArray(query.value) ? query.value : [query.value];
    const results = values.map((value) => {
      const foundItem = find(arr, { value });
      return foundItem ? foundItem.label : null;
    });
    return values.length === 1 ? results[0] : compact(results);
  }
  return null;
};

export const isEmptyEditorContent = (value: string) =>
  !value || value === '<p></p>' || value === '<br>' || value === '<p><br></p>';

export const editorValidation = createHtmlStrippedLengthTest(1024)
  .transform((value, originalValue) => (isEmptyEditorContent(originalValue) ? '' : value))
  .required('Field is required');

export const editorValidationOptional = createHtmlStrippedLengthTest(1024).transform(
  (value, originalValue) => (isEmptyEditorContent(originalValue) ? '' : value),
);

export const validationOptionsValidation = string().oneOf([...map(validationOptions, 'label'), '']);

export const emptyEditorValue = '<p><br></p>';

export const maxTelegramButtonLength = 120;
