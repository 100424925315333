import { Alert, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import SelectCard from 'forms/create-project/components/SelectCard';
import StepTitle from 'forms/create-project/components/StepTitle';
import { CreateProjectFields, CreateProjectFieldsNames, Mode } from 'forms/create-project/types';
import { appConfig } from 'forms/create-project/utils';
import { useMount } from 'hooks';
import { Project } from 'types';

interface SelectAppStepProps {
  selectApp(): void;
  mode: Mode;
  apps?: Project['apps'];
}
const SelectAppStep = ({ selectApp, mode, apps }: SelectAppStepProps) => {
  const {
    setValue,
    watch,
    getValues,
    reset,
    formState: { isSubmitting },
  } = useFormContext<CreateProjectFields>();
  const selectedApp = watch(CreateProjectFieldsNames.SelectedApp);

  useMount(() => {
    if (mode === Mode.Connect) {
      reset({ ...getValues(), [CreateProjectFieldsNames.SelectedApp]: null }, { keepValues: true });
    }
  });

  const filteredAppConfig = Object.values(appConfig).filter(({ type }) =>
    mode === Mode.Connect ? !apps?.find((app) => app.type === type) : true,
  );

  return (
    <Stack gap={2}>
      <StepTitle
        title={mode === Mode.Connect ? 'Select an app to connect' : 'Select an app to get started'}
      />
      {filteredAppConfig.map(({ type, label, ...rest }) => (
        <SelectCard
          loading={isSubmitting && type === selectedApp}
          key={type}
          name={label}
          {...rest}
          onClick={() => {
            setValue(CreateProjectFieldsNames.SelectedApp, type, { shouldDirty: true });
            selectApp();
          }}
        />
      ))}

      <Alert severity='info' sx={{ border: 1, borderColor: 'divider' }}>
        {mode === Mode.Create
          ? 'You can add more apps to your project later'
          : 'Exciting New Apps Coming Soon to Enhance Your Telegram Bots!'}
      </Alert>
    </Stack>
  );
};

export default SelectAppStep;
