import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import { planConfig } from 'config';
import { AccountPlanType, RoutePath, StepType } from 'enums';
import { useAuth } from 'hooks';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { stepSelectorItemProps } from 'pages/app-pages/chatbot-builder-page/nodes/input-node/utils';
import { BasicColors } from 'theme';
import { isBasicColorValid } from 'theme/utils';

interface StepSelectorItemProps {
  label: string;
  icon: string;
  color: BasicColors;
  description: string;
  type: StepType;
  plan?: AccountPlanType;
  onClick?: () => void;
  selected?: boolean;
  focused?: boolean;
}
const StepSelectorItem = ({
  label,
  description,
  icon,
  color,
  selected,
  focused,
  onClick,
  type,
  plan,
  ...rest
}: StepSelectorItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();

  const subscribed = StepsConfig[type]?.plan?.includes(
    user?.organization?.accountPlan?.type || AccountPlanType.Basic,
  );
  const textColor = isBasicColorValid(color) ? theme.palette[color].main : color;
  return (
    <Tooltip
      arrow
      title={subscribed ? description : 'Switch to a Pro plan to use this feature'}
      placement='top'
    >
      <Stack
        {...stepSelectorItemProps({
          color: subscribed ? color : theme.palette.grey[500],
          clickable: !!onClick,
          selected: selected || focused,
        })}
        key={label}
        onClick={() => {
          // TODO set user plan
          if (subscribed) {
            onClick?.();
          } else {
            navigate(`/${RoutePath.ManageAccount}`);
          }
        }}
        {...rest}
      >
        <Icon icon={icon} fontSize='large' color={subscribed ? color : 'grey.700'} />
        <Typography
          width={1}
          title={label}
          noWrap
          textTransform='capitalize'
          variant='caption'
          fontWeight='bolder'
          sx={{
            color: subscribed ? textColor : 'grey.700',
          }}
        >
          {label}
        </Typography>
        {plan && plan !== AccountPlanType.Basic && (
          <Tooltip title={`${planConfig[plan].name} Plan Feature`} arrow placement='top'>
            <Box position='absolute' top={6} right={6} borderRadius={1}>
              <Icon
                color={planConfig[plan].color}
                sx={{
                  fontSize: 16,
                }}
                icon={planConfig[plan].icon}
              />
            </Box>
          </Tooltip>
        )}
      </Stack>
    </Tooltip>
  );
};

export default StepSelectorItem;
