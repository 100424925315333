export const validationOptions = [
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
  { value: 'url', label: 'Url' },
  { value: 'date', label: 'Date' },
  { value: 'card', label: 'Credit Card' },
  { value: 'numeric', label: 'Numeric' },
];

export enum CommonFormFieldsNames {
  Labels = 'labels',
}
