import { Popper, PopperProps } from '@mui/material';

const CustomAutocompletePopper = (props: PopperProps) => (
  <Popper
    {...props}
    sx={{ width: 'auto', minWidth: 240, marginTop: '-16px !important' }}
    placement='bottom-start'
  />
);

export default CustomAutocompletePopper;
