import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { planConfig, roleConfig } from 'config';
import { AccountPlanType, ExtendedUserRole, RoutePath } from 'enums';
import { useAuth } from 'hooks';

interface ProtectedRouteProps {
  children: ReactNode;
  minUserRole?: ExtendedUserRole;
  minUserPlan?: AccountPlanType;
}

const ProtectedRoute = ({ children, minUserRole, minUserPlan }: ProtectedRouteProps) => {
  const { user } = useAuth();

  if (!user) {
    // Redirect to login if no user is logged in
    return <Navigate to={`/${RoutePath.Login}`} replace />;
  }

  // Determine the effective role level
  const userRoleLevel = user.role && roleConfig[user.role] ? roleConfig[user.role].level : 0;
  const requiredRoleLevel = minUserRole ? roleConfig[minUserRole]?.level || 0 : 0;

  // Plan level checks
  const userPlanLevel = user.organization?.accountPlan
    ? planConfig[user.organization.accountPlan.type]?.level || 0
    : 0;
  const requiredPlanLevel = minUserPlan ? planConfig[minUserPlan]?.level || 0 : 0;

  // Check if the user's plan and role meet or exceed the minimum required levels
  if (userRoleLevel < requiredRoleLevel || userPlanLevel < requiredPlanLevel) {
    // If the user's role level or plan level is not sufficient, redirect to a "not allowed" or similar page
    return <Navigate to={`/${RoutePath.NotFound}`} replace />;
  }

  // If all checks pass, render the children components (the protected route's content)
  return children;
};

export default ProtectedRoute;
