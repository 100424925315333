import { Box } from '@mui/material';
import * as React from 'react';
import { IconButton, IconButtonProps } from 'components/index';

interface FormArrayRemoveButtonProps extends Omit<IconButtonProps, 'icon'> {
  icon?: string;
}

const FormArrayRemoveButton = ({
  onClick,
  icon = 'delete',
  ...rest
}: FormArrayRemoveButtonProps) => (
  <Box width={28}>
    <IconButton
      edge='end'
      icon={icon}
      onClick={onClick}
      iconProps={{ fontSize: 'small', sx: { fontSize: 18 } }}
      {...rest}
    />
  </Box>
);

export default FormArrayRemoveButton;
