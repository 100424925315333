import { Stack } from '@mui/material';
import * as React from 'react';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { FormTextField } from 'components/index';
import FormArrayRemoveButton from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormArrayRemoveButton';
import { NavigateStepFormFieldsLinksNames } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/navigate/types';
import { NavigateFormOptionsFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/navigate/utils';

const NavigateFormArrayField = ({ getFieldName, index, remove, length }: FormArrayFieldProps) => {
  const { name, label, ...rest } =
    NavigateFormOptionsFieldsConfig[NavigateStepFormFieldsLinksNames.Text];

  const {
    name: urlName,
    label: urlLabel,
    ...urlRest
  } = NavigateFormOptionsFieldsConfig[NavigateStepFormFieldsLinksNames.Url];

  const answerFieldName = getFieldName(name);
  const urlFieldName = getFieldName(urlName);

  return (
    <Stack direction='row' alignItems='flex-start' gap={1}>
      <Stack direction='row' gap={0.5} flex={1}>
        <FormTextField
          size='small'
          placeholder={`${label} ${index + 1}`}
          name={answerFieldName}
          {...rest}
        />
        <FormTextField
          size='small'
          placeholder={`${urlLabel} ${index + 1}`}
          name={urlFieldName}
          {...urlRest}
        />
      </Stack>
      <FormArrayRemoveButton onClick={() => remove(index)} disabled={length === 1} />
    </Stack>
  );
};

export default NavigateFormArrayField;
