import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { size } from 'lodash';
import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { ApiKey, fetcher } from 'api';
import FailedChatsTooltip from 'pages/app-pages/private-bulk-message-page/FailedChatsTooltip';
import useMessageStatus from 'pages/app-pages/private-bulk-message-page/useMessageStatus';

import { PrivateChatBulkMessage } from 'types';

function RecipientsCell({ row }: GridRenderCellParams<PrivateChatBulkMessage>) {
  const { isMessageHasError, isDraftMessage, isFutureMessage } = useMessageStatus(row);

  const [nextFetchCounter, setNextFetchCounter] = useState(30); // Counter for next fetch, starting from 60 seconds.

  // Depend on shouldFetch so the effect is run when it changes

  const sentChats = row.sentChats?.length || 0;
  const failedSentChats = row.failedSentChats?.length || 0;
  const totalChats = sentChats + failedSentChats;
  const totalChatsExpected = row.totalChatsCount || 0; // Assuming this is the total number of chats expected to be sent
  const remainingMessages = totalChatsExpected - totalChats;
  const immediateMessages = 30;
  const additionalMinutes = Math.max(0, Math.ceil((remainingMessages - immediateMessages) / 30));

  const isTakingTooLong =
    row.startDate && new Date(row.startDate) < new Date(Date.now() - 3 * 60 * 60 * 1000);
  const shouldFetch =
    (row.totalChatsCount || 0) > size(row?.failedSentChats) + size(row?.sentChats) &&
    !isTakingTooLong;

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;

    if (shouldFetch) {
      // Reset counter when starting the fetch
      setNextFetchCounter(30);

      // Start the countdown
      timerId = setInterval(() => {
        setNextFetchCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 30));
      }, 1000);
    }

    // Cleanup on unmount or when shouldFetch changes
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [shouldFetch]);

  const approximateTimeRemaining =
    shouldFetch && `${additionalMinutes}:${remainingMessages > immediateMessages ? '00' : '30'}`;

  useSWR<PrivateChatBulkMessage[]>(
    shouldFetch ? `${ApiKey.PrivateChatBulkMessage}/${row.id}` : null,
    fetcher,
    {
      refreshInterval: 30000,
      onSuccess: async (data) => {
        // Use mutate to read, update, and write back to the cache
        await mutate(
          `${ApiKey.PrivateChatBulkMessage}?projectId=${row.project.id}`,
          async (currentGlobalData) => {
            // Ensure we have current data to work with, if not, return an empty array
            if (!currentGlobalData) {
              return [];
            }

            // Find the index of the message to update
            const rowIndex = currentGlobalData.findIndex(
              (chat: PrivateChatBulkMessage) => chat.id === row.id,
            );

            if (rowIndex === -1) {
              // If for some reason we can't find the row, return the current data unchanged
              return currentGlobalData;
            }

            // Create a new array with the updated item
            const updatedGlobalData = [...currentGlobalData];
            updatedGlobalData[rowIndex] = { ...updatedGlobalData[rowIndex], ...data };

            // Return the updated data array, which will be used to update the cache
            return updatedGlobalData;
          },
          false,
        ); // false to not re-fetch after mutating
      },
    },
  );
  // Calculate the progress percentage
  const progressPercentage = totalChatsExpected > 0 ? (sentChats / totalChatsExpected) * 100 : 0;
  const failedPercentage =
    totalChatsExpected > 0 ? (failedSentChats / totalChatsExpected) * 100 : 0;

  const title = isTakingTooLong
    ? `Delivered: ${sentChats}/${totalChats}`
    : `${shouldFetch ? 'In Progress' : 'Successfully Delivered'}: ${sentChats}/${totalChats}`;

  if (isMessageHasError || isDraftMessage || isFutureMessage) {
    return null;
  }

  return (
    <FailedChatsTooltip failedSentChats={row.failedSentChats}>
      <Stack width={1} gap={0.5} pr={4}>
        <Typography
          noWrap
          title={title}
          variant='caption'
          fontWeight='bolder'
          color='text.secondary'
        >
          {title}
        </Typography>
        <Box bgcolor='grey.200' borderRadius={1}>
          <LinearProgress
            color='info'
            variant={shouldFetch ? 'buffer' : 'determinate'}
            value={shouldFetch ? failedPercentage : progressPercentage}
            valueBuffer={progressPercentage}
            sx={{
              height: 6,
              borderRadius: 1,
              // static bg
              bgcolor: 'grey.200',
              [`& .MuiLinearProgress-bar`]: {
                backgroundColor: shouldFetch ? 'error.main' : 'bulk.main',
              },
              [`& .MuiLinearProgress-bar2Buffer`]: {
                backgroundColor: 'bulk.main',
              },
              [`& .MuiLinearProgress-dashed`]: {
                backgroundColor: 'grey.200',
                backgroundSize: '10px 10.5px',
                backgroundImage:
                  'radial-gradient(rgb(52 181 147) 0%, rgb(52 181 147) 16%, #d5dadd 42%)',
              },
            }}
          />
        </Box>
        {shouldFetch && (
          <Typography noWrap variant='caption' color='text.secondary'>
            {`${approximateTimeRemaining ? `ETA: ${approximateTimeRemaining} min | ` : ''}`}
            Next update in {nextFetchCounter > 10 ? `0:${nextFetchCounter}` : nextFetchCounter}
          </Typography>
        )}
      </Stack>
    </FailedChatsTooltip>
  );
}

export default RecipientsCell;
