import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import {
  RequestContactFormFields,
  RequestContactFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-contact/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue } from 'utils';

export const requestContactStepFormSchema: ObjectSchema<RequestContactFormFields> = object({
  [RequestContactFormFieldsNames.NodeId]: string().required(),
  [RequestContactFormFieldsNames.Name]: string().required('Name is required'),
  [RequestContactFormFieldsNames.Button]: string().required('Button is required'),
  [RequestContactFormFieldsNames.Input]: editorValidation,
  [RequestContactFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultContactDataFormValues: Partial<RequestContactFormFields> = {
  [RequestContactFormFieldsNames.Input]: emptyEditorValue,
  [RequestContactFormFieldsNames.NodeId]: '',
  [RequestContactFormFieldsNames.Button]: 'Share Contact',
  [RequestContactFormFieldsNames.Labels]: [
    {
      label: 'Contact Shared',
      color: '#DBEEDB',
      icon: 'account_box',
      value:
        process.env.REACT_APP_ENV === 'production'
          ? '6609a37a42362ebd0e619bf9'
          : '6609645963ebf0a979048594',
    },
  ],
};

export const requestContactFieldsConfig: Record<RequestContactFormFieldsNames, FormFieldProps> = {
  [RequestContactFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: RequestContactFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [RequestContactFormFieldsNames.Input]: {
    placeholder: '* Message',
    name: RequestContactFormFieldsNames.Input,
    type: 'text',
  },
  [RequestContactFormFieldsNames.Button]: {
    placeholder: 'Will be displayed on the button',
    name: RequestContactFormFieldsNames.Button,
    autoComplete: 'off',
    type: 'text',
  },
  [RequestContactFormFieldsNames.NodeId]: {
    name: RequestContactFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [RequestContactFormFieldsNames.Labels]: labelFormFieldConfig,
};
