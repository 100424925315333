import { create } from 'zustand';
import { StepType } from 'enums';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { Command } from 'types';

export type ToolboxState = {
  isToolboxOpen: boolean;
  closeToolbox: () => void;
  openToolbox: () => void;

  actionCount: number;
  incrementActionCount: () => void;

  draftLastUpdatedAt: Date | null;
  setDraftLastUpdatedAt: (date: Date | null) => void;

  publishedLastUpdatedAt: Date | null;
  setPublishedLastUpdatedAt: (date: Date | null) => void;

  selectedCommand: Command | null;
  setSelectedCommand: (command: Command | null) => void;

  activeToolboxTab: ToolboxTabs;
  setActiveToolboxTab: (tab: ToolboxTabs) => void;

  selectedNodeId: string | null;
  setSelectedNodeId: (id: string | null) => void;

  selectedStepType: StepType | null;
  setSelectedStepType: (stepType: StepType | null) => void;

  editorIsFocused: boolean;
  setEditorIsFocused: (isFocused: boolean) => void;

  saving: boolean;
  setSaving: (saving: boolean) => void;
};

export const useBuilderStore = create<ToolboxState>((set) => ({
  isToolboxOpen: false,
  closeToolbox: () => set({ isToolboxOpen: false }),
  openToolbox: () => set({ isToolboxOpen: true }),

  activeToolboxTab: ToolboxTabs.BotSettings,
  setActiveToolboxTab: (tab) => set({ activeToolboxTab: tab }),

  selectedNodeId: null,
  setSelectedNodeId: (id) => set({ selectedNodeId: id }),

  selectedStepType: null,
  setSelectedStepType: (stepType) => set({ selectedStepType: stepType }),
  editorIsFocused: false,
  setEditorIsFocused: (isFocused) => set({ editorIsFocused: isFocused }),
  saving: false,
  setSaving: (saving) => set({ saving }),

  selectedCommand: null,
  setSelectedCommand: (command) => set({ selectedCommand: command }),

  draftLastUpdatedAt: null,
  setDraftLastUpdatedAt: (date) => set({ draftLastUpdatedAt: date }),

  publishedLastUpdatedAt: null,
  setPublishedLastUpdatedAt: (date) => set({ publishedLastUpdatedAt: date }),

  actionCount: 0,
  incrementActionCount: () => set((state) => ({ actionCount: state.actionCount + 1 })),
}));
