import { BoxProps } from '@mui/material';
import { useEffect } from 'react';
import { DefaultValues, FieldValues, FormProvider } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { FormTextFieldInlineEditProps } from 'components/form-fields/FormTextFieldInlineEdit';
import { FormTextFieldInlineEdit } from 'components/index';
import { useFormProvider, useServerError } from 'hooks';
import { useDirtyStore } from 'store';

interface SingleTextFieldEditorFromProps<DataType extends FieldValues> {
  fieldProps: Omit<FormTextFieldInlineEditProps, 'onSubmit'>;
  schema: AnyObjectSchema;
  emptyStateText?: string;
  defaultValues?: DefaultValues<DataType>;
  onSubmit?: (formData: DataType) => Promise<void>;
  boxProps?: BoxProps;
  disabled?: boolean;
}

const SingleTextFieldEditorFrom = <T extends FieldValues>(
  props: SingleTextFieldEditorFromProps<T>,
) => {
  const {
    fieldProps,
    boxProps,
    onSubmit,
    defaultValues,
    schema,
    emptyStateText = '',
    disabled,
  } = props;
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const toastServerError = useServerError();

  const formMethods = useFormProvider<T>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    schema,
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;
  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const handleOnSubmit = async (formData: T) => {
    setIsDirty(false);
    if (formData[fieldProps.name] !== defaultValues?.[fieldProps.name]) {
      try {
        await onSubmit?.(formData);
      } catch (error) {
        toastServerError(error);
      }
    } else {
      reset(formData);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <FormTextFieldInlineEdit
        {...fieldProps}
        disabled={disabled}
        text={emptyStateText}
        onFinishedEditing={() => handleSubmit(handleOnSubmit)()}
        variant='standard'
        size='small'
        boxProps={{
          flex: 1,
          ...boxProps,
        }}
      />
    </FormProvider>
  );
};

export default SingleTextFieldEditorFrom;
