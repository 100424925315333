import { AutoCompleteLabel } from 'types';

export enum RequestEmailFormFieldsNames {
  Name = 'name',
  Input = 'input',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface RequestEmailFormFields {
  [RequestEmailFormFieldsNames.NodeId]: string;
  [RequestEmailFormFieldsNames.Name]: string;
  [RequestEmailFormFieldsNames.Input]: string;
  [RequestEmailFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
