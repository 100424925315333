import { useCallback } from 'react';
import { useToast } from './useToast';

export const useServerError = () => {
  const toast = useToast();
  return useCallback(
    (error: any) => {
      toast({
        message: error?.response?.data?.general?.message || 'Something went wrong',
        variant: 'error',
      });
    },
    [toast],
  );
};
