import { array, number, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  RandomMenuStepFormFields,
  RandomMenuStepFormFieldsMenuNames,
  RandomMenuStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { maxTelegramButtonLength } from 'utils';

export const RandomMenuStepFormSchema: ObjectSchema<RandomMenuStepFormFields> = object({
  [RandomMenuStepFormFieldsNames.NodeId]: string().required('Node is required'),
  [RandomMenuStepFormFieldsNames.Name]: string().required('Name is required'),
  [RandomMenuStepFormFieldsNames.Menu]: array(
    object({
      [RandomMenuStepFormFieldsMenuNames.Option]: number()
        .max(
          maxTelegramButtonLength,
          `Option should be less than ${maxTelegramButtonLength} characters`,
        )
        .required('Option is required'),
      [RandomMenuStepFormFieldsMenuNames.NodeId]: string().required('Node is required'),
      [RandomMenuStepFormFieldsMenuNames.Name]: string().required('Name is required'),
    }),
  ).required(),
  [RandomMenuStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultRandomMenuStepFormValues: RandomMenuStepFormFields = {
  [RandomMenuStepFormFieldsNames.NodeId]: '',
  [RandomMenuStepFormFieldsNames.Name]: 'Randomizer',
  [RandomMenuStepFormFieldsNames.Menu]: [],
  [RandomMenuStepFormFieldsNames.Labels]: [],
};

export const RandomMenuFormFieldsConfig: Record<RandomMenuStepFormFieldsNames, FormFieldProps> = {
  [RandomMenuStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: RandomMenuStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [RandomMenuStepFormFieldsNames.Menu]: {
    label: 'Distribute The traffic by percentage',
    placeholder: 'Menu',
    name: RandomMenuStepFormFieldsNames.Menu,
    autoComplete: 'off',
    type: 'text',
  },
  [RandomMenuStepFormFieldsNames.NodeId]: {
    name: RandomMenuStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [RandomMenuStepFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const RandomMenuFormMenuFieldsConfig: Record<
  RandomMenuStepFormFieldsMenuNames,
  FormFieldProps
> = {
  [RandomMenuStepFormFieldsMenuNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: RandomMenuStepFormFieldsMenuNames.Name,
    autoComplete: 'off',
    type: 'text',
  },

  [RandomMenuStepFormFieldsMenuNames.Option]: {
    placeholder: 'Option',
    name: RandomMenuStepFormFieldsMenuNames.Option,
    autoComplete: 'off',
    type: 'number',
  },
  [RandomMenuStepFormFieldsMenuNames.NodeId]: {
    name: RandomMenuStepFormFieldsMenuNames.NodeId,
    type: 'hidden',
  },
};
