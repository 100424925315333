import { useEffect } from 'react';
import { useDirtyStore } from 'store';
import { usePrompt } from './usePrompt';

export const useUnsavedChangesWarning = (): void => {
  const message = 'You have unsaved changes. Are you sure you want to leave?';

  const { isSavingToServer, isDirty } = useDirtyStore((state) => ({
    isSavingToServer: state.isSavingToServer,
    isDirty: state.isDirty,
  }));

  usePrompt({ when: isSavingToServer || isDirty, message });

  function handleBeforeUnload(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = message;
  }
  useEffect(() => {
    if (isSavingToServer || isDirty) {
      // Attach the event listener
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      // Clean up the event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, isSavingToServer]);
};
