import Publish from 'pages/app-pages/chatbot-builder-page/toolbox/Publish';
import BotSettings from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/bot-settings/BotSettings';
import CommandsManager from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/commands-manager/CommandsManager';
import NodeEditor from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/NodeEditor';
import StartNodeEditor from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/start-node-editor/StartNodeEditor';
import { ToolboxTabProps, ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';

export const ToolboxTabsConfig: Record<ToolboxTabs, ToolboxTabProps> = {
  [ToolboxTabs.NodeEditor]: {
    label: 'Node Editor',
    value: ToolboxTabs.NodeEditor,
    component: NodeEditor,
  },
  [ToolboxTabs.BotSettings]: {
    label: 'Bot Settings',
    value: ToolboxTabs.BotSettings,
    component: BotSettings,
  },
  [ToolboxTabs.Publish]: {
    label: 'Publish',
    value: ToolboxTabs.Publish,
    component: Publish,
  },
  [ToolboxTabs.StartNode]: {
    label: 'Start Node',
    value: ToolboxTabs.StartNode,
    component: StartNodeEditor,
  },
  [ToolboxTabs.CommandsSettings]: {
    label: 'Commands Settings',
    value: ToolboxTabs.CommandsSettings,
    component: CommandsManager,
  },
};
