import { Box, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { api, ApiKey } from 'api';
import Button from 'components/Button';
import ToolboxHeader from 'components/ToolboxHeader';
import { RoutePath } from 'enums';
import { useFormProvider, useIsMobile, useServerError, useToast } from 'hooks';
import GroupsList from 'pages/app-pages/messages-scheduler-page/groups-drawer/GroupsList';
import { GroupFormFields } from 'pages/app-pages/messages-scheduler-page/groups-drawer/types';
import {
  groupFormDefaultValues,
  groupFormSchema,
} from 'pages/app-pages/messages-scheduler-page/groups-drawer/utils';
import { useGroupsDrawer } from 'pages/app-pages/messages-scheduler-page/store/useGroupsDrawer';
import { Project } from 'types';

const GroupsDrawer = () => {
  const { closeDrawer } = useGroupsDrawer((state) => ({
    closeDrawer: state.closeDrawer,
  }));
  const formMethods = useFormProvider<GroupFormFields>({
    schema: groupFormSchema,
    defaultValues: groupFormDefaultValues,
  });
  const { isMobile } = useIsMobile();
  const { id } = useParams();
  const toast = useToast();
  const serverError = useServerError();
  const navigate = useNavigate();
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const { handleSubmit, reset } = formMethods;

  const handleOnSubmit = async (formData: GroupFormFields) => {
    try {
      await api.post('/email-message/contact-us', formData);
      toast({
        message: 'Thank you for contacting us. We will get back to you shortly.',
        variant: 'success',
      });
      reset();
    } catch (error) {
      serverError(error);
    }
  };

  return (
    <Stack height={1}>
      <Stack
        alignItems='flex-start'
        position='sticky'
        top={0}
        bgcolor='background.default'
        width={1}
      >
        <ToolboxHeader
          title='Groups Manager'
          subtitle='Connected Groups'
          onClose={closeDrawer}
          additionalActions={[
            {
              icon: 'add_circle',
              color: 'info',
              onClick: () => {
                navigate(`/${RoutePath.Messenger}/${id}/${RoutePath.ConnectGroups}`);
              },
            },
          ]}
        />
      </Stack>
      <Stack flex={1} overflow='auto'>
        <Box py={2} height={1}>
          <FormProvider {...formMethods}>
            <Stack
              width={1}
              height={1}
              gap={2}
              px={2}
              component='form'
              noValidate
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <Stack gap={2} width={1} flex={isMobile ? 1 : '0 1 auto'} overflow='auto'>
                {isEmpty(project?.chatGroups) ? (
                  <Typography gutterBottom variant='caption' color='text.secondary'>
                    No groups connected
                  </Typography>
                ) : (
                  <GroupsList groups={project?.chatGroups} />
                )}

                {isEmpty(project?.chatGroups) && (
                  <Button
                    onClick={() => {
                      navigate(`/${RoutePath.Messenger}/${id}/${RoutePath.ConnectGroups}`);
                    }}
                    variant='outlined'
                    color='info'
                    fullWidth
                  >
                    Connect Groups
                  </Button>
                )}
              </Stack>
            </Stack>
          </FormProvider>
        </Box>
      </Stack>
    </Stack>
  );
};

export default GroupsDrawer;
