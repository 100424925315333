import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatDistanceToNowStrict } from 'date-fns';
import { TextCell } from '@common-components';
import UserDetailsCell from 'components/data-grid/UserDetailsCell';
import ActivityDropdownWrapper from 'pages/activity-page/ActivityDropdownWrapper';
import ActivityNote from 'pages/activity-page/ActivityNote';
import AssigneeWrapper from 'pages/activity-page/assignee/AssigneeWrapper';
import IsImportant from 'pages/activity-page/IsImportant';
import LabelCell from 'pages/activity-page/labels/LabelCell';
import StatusWrapper from 'pages/activity-page/status/StatusWrapper';
import { ChatColumns } from 'pages/activity-page/types';
import { sortByDate } from 'utils';



export const columnsConfig: GridColDef[] = [
  {
    field: ChatColumns.Id,
    headerName: '',
    maxWidth: 52,
    minWidth: 52,
    align: 'center',
    sortable: false,
    renderCell: (params) => <ActivityDropdownWrapper {...params} />,
  },
  {
    field: ChatColumns.IsImportant,
    headerName: '',
    maxWidth: 44,
    minWidth: 44,
    align: 'center',
    renderCell: ({ row }) => <IsImportant id={row.id} isImportant={row.isImportant} />,
  },
  {
    field: ChatColumns.Status,
    headerName: 'Status',

    width: 140,
    renderCell: StatusWrapper,
  },

  {
    field: ChatColumns.ProjectName,
    headerName: 'Bot Name',
    flex: 1,
    minWidth: 140,
    renderCell: TextCell,
  },
  {
    field: ChatColumns.FirstName,
    flex: 1,
    minWidth: 140,
    headerName: 'User Details',
    valueGetter: (params: any) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''} ${params.row.userName ? `@${params.row.userName}` : ''}`,
    renderCell: UserDetailsCell,
  },
  {
    field: ChatColumns.Labels,
    headerName: 'Labels',
    width: 140,
    valueGetter: (params: any) =>
        `${params.row.labels?.map((label:any) => label.name).join(', ') || ''} `,

    renderCell: LabelCell,
  },
  {
    field: ChatColumns.Assignee,
    headerName: 'Assignee',
    flex: 1,
    minWidth: 140,
    renderCell: AssigneeWrapper,
  },
  {
    field: ChatColumns.Note,
    headerName: 'Internal Note',
    flex: 1,
    minWidth: 140,
    renderCell: ActivityNote,
  },
  {
    field: ChatColumns.ConversationCount,
    headerName: 'Count',
    width: 60,
    renderCell: ({ value }) => (
      <Typography noWrap variant='body2'>
        {value}
      </Typography>
    ),
  },
  {
    field: ChatColumns.UpdatedAt,
    headerName: 'Date',
    sortComparator: sortByDate,
    valueFormatter: ({ value }) => formatDistanceToNowStrict(new Date(value), { addSuffix: true }),
    width: 100,
    align: 'right',
    headerAlign: 'right',
    renderCell: TextCell,
  },
  /* {
    field: ChatColumns.IsRead,
    headerName: '',
    maxWidth: 44,
    minWidth: 44,
    align: 'center',
    renderCell: ({ row }) => <IsRead id={row.id} isRead={row.isRead} />,
  },
  {
    field: ChatColumns.IsArchived,
    headerName: '',
    maxWidth: 44,
    minWidth: 44,
    align: 'center',
    renderCell: ({ row }) => <IsArchived id={row.id} isArchived={row.isArchived} />,
  }, */
];

export const getUpdatedColumns = (columnsToHide: string | string[]) => {
  // Ensure columnsToHide is always an array
  const columnsToHideArray = Array.isArray(columnsToHide) ? columnsToHide : [columnsToHide];
  return columnsConfig.filter((column) => !columnsToHideArray.includes(column.field));
};
