import { Box } from '@mui/material';
import { AppIcon as AppIconSvg } from 'assets/images';

function AppIcon({ height = 24 }: { height?: number }) {
  return (
    <Box
      height={height}
      width={height}
      sx={{
        '& svg': {
          width: 1,
          height: 1,
        },
      }}
    >
      <AppIconSvg />
    </Box>
  );
}

export default AppIcon;
