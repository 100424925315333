import { TicketStatus } from 'enums';
import { TicketStatusProps } from 'types';

export const ticketStatusConfig: Record<TicketStatus, TicketStatusProps> = {
  [TicketStatus.Open]: {
    name: 'New',
    status: TicketStatus.Open,
    color: 'error',
    icon: 'pending',
  },
  [TicketStatus.InProgress]: {
    name: 'In Progress',
    status: TicketStatus.InProgress,
    color: 'info',
    icon: 'play_circle',
  },
  [TicketStatus.Closed]: {
    name: 'Closed',
    status: TicketStatus.Closed,
    color: 'success',
    icon: 'check_circle',
  },
};
