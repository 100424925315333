import { Link, Typography } from '@mui/material';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormRadioGroup,
  FormSection,
  FormTextField,
  FormTextFieldInlineEdit,
  Icon,
  List,
  ListItem,
} from '@common-components';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { InviteFormFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/invite/types';
import {
  defaultInviteFormValues,
  inviteFieldsConfig,
  inviteStepFormSchema,
  inviteTypeOptions,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/invite/utils';

import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface InviteProps {
  id: string;
  defaultName: string;
  defaultValues?: Partial<InviteFormFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}

const Invite = ({ defaultValues, mentions, commands, defaultName, id }: InviteProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultInviteFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<InviteFormFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<InviteFormFormFields>({
    schema: inviteStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const processNewLabels = useProcessNewLabels();

  const { reset, formState } = formMethods;

  const onReset = () => {
    reset(localDefaultValues);
  };
  const handleOnSubmit = async (formData: InviteFormFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, labels, button, channelId, type, input } = inviteFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
        <FormSection
          title='Invitation Type'
          infoIcon={{
            tooltip:
              'One-time invitation: The user can join the channel only once. Join request: The user can join the channel multiple times.',
            icon: 'info',
          }}
        >
          <List divider disablePadding>
            <ListItem>
              <FormRadioGroup size='small' row options={inviteTypeOptions} {...type} />
            </ListItem>
          </List>
        </FormSection>
        <FormSection title='Button Text'>
          <FormTextField {...button} size='small' />
        </FormSection>
        <FormSection title='Channel ID'>
          <FormTextField {...channelId} size='small' />
        </FormSection>
        <Typography variant='body2' color='text.secondary'>
          <span>
            <b>⚠️ Make sure the bot is an admin of the channel.</b>
            <br /> You can find the channel ID with the help of the&nbsp;
            <br />
            <Link
              variant='subtitle2'
              href='https://t.me/username_to_id_bot'
              target='_blank'
              rel='noopener noreferrer'
              display='inline-flex'
              alignItems='center'
            >
              Id Bot&nbsp;
              <Icon icon='open_in_new' color='primary' fontSize='small' />
            </Link>
            &nbsp;on Telegram.
          </span>
        </Typography>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(Invite);
