type LanguageCode =
  | 'EN'
  | 'ES'
  | 'ZH'
  | 'HI'
  | 'AR'
  | 'PT'
  | 'RU'
  | 'JA'
  | 'DE'
  | 'KO'
  | 'FR'
  | 'TR'
  | 'IT'
  | 'PL'
  | 'NL'
  | 'HE';
type CountryCode =
  | 'GB'
  | 'ES'
  | 'CN'
  | 'IN'
  | 'SA'
  | 'PT'
  | 'RU'
  | 'JP'
  | 'DE'
  | 'KR'
  | 'FR'
  | 'TR'
  | 'IT'
  | 'PL'
  | 'NL'
  | 'IL'
  | 'US';

const languageToCountryMap: Record<LanguageCode, CountryCode> = {
  EN: 'GB',
  ES: 'ES',
  ZH: 'CN',
  HI: 'IN',
  AR: 'SA',
  PT: 'PT',
  RU: 'RU',
  JA: 'JP',
  DE: 'DE',
  KO: 'KR',
  FR: 'FR',
  TR: 'TR',
  IT: 'IT',
  PL: 'PL',
  NL: 'NL',
  HE: 'IL',
};

export function mapLanguageToCountry(langCode: string): CountryCode {
  const code = langCode.toUpperCase() as LanguageCode;
  return languageToCountryMap[code] || 'GB';
}
