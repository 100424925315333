import { Stack } from '@mui/material';
import { DataGrid, GridRowClassNameParams, GridSortModel } from '@mui/x-data-grid';
import CustomDataGridSlots from 'components/data-grid/CustomDataGridSlots';
import HeaderWithSearchBar from 'components/HeaderWithSearchBar';
import { ChatGroupMessageStatus } from 'enums';
import { useIsMobile } from 'hooks';
import { ScrollAreaLayout } from 'layout';
import { messagesTableConfig } from 'pages/app-pages/messages-scheduler-page/messagesTableConfig';
import SortByMessages from 'pages/app-pages/messages-scheduler-page/SortByMessages';
import useMessageStatus from 'pages/app-pages/messages-scheduler-page/useMessageStatus';
import { tableStyle } from 'style';
import { ChatGroupMessage } from 'types';

interface MessagesTableProps {
  drawerIsOpen: boolean;
  toggleDrawer: () => void;
  messages: ChatGroupMessage[];
  isLoading: boolean;
  sortModel: GridSortModel;
  onSortModelChange: (newSortModel: GridSortModel) => void;
  onCreateMessage: () => void;
  handleSearchChange: (value: string) => void;
  search: string;
}

const MessagesTableWrapper = ({
  toggleDrawer,
  messages,
  isLoading,
  sortModel,
  handleSearchChange,
  search,
  onCreateMessage,
  onSortModelChange,
}: MessagesTableProps) => {
  const { isSmallMobile } = useIsMobile();

  const { getIsExpiredMessage } = useMessageStatus();

  return (
    <Stack px={{ xs: 2, md: 3 }}>
      <HeaderWithSearchBar
        mainAction={{
          label: 'Create Message',
          icon: 'add',
          onClick: onCreateMessage,
        }}
        secondaryAction={{
          label: 'Manage Groups',
          icon: 'groups',
          onClick: toggleDrawer,
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        handleSearchChange={handleSearchChange}
        search={search}
        headerText='Scheduled Messages'
        SortBy={SortByMessages}
      />
      <ScrollAreaLayout subtract={isSmallMobile ? 130 : 160}>
        <DataGrid
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          sortingOrder={['asc', 'desc']}
          rows={messages}
          loading={isLoading}
          columns={messagesTableConfig}
          rowHeight={70}
          disableRowSelectionOnClick
          disableColumnMenu
          sx={tableStyle({ clickable: false })}
          slots={CustomDataGridSlots({
            noRowsOverlayProps: {
              onClick: onCreateMessage,
              buttonTitle: 'Create Message',
            },
          })}
          getRowClassName={(params: GridRowClassNameParams<ChatGroupMessage>) => {
            if (getIsExpiredMessage(params.row)) {
              return 'error-row';
            }
            if (params.row.sendAttempt && params.row.status !== ChatGroupMessageStatus.Draft) {
              return 'sent-row';
            }
            if (params.row.status === ChatGroupMessageStatus.Draft) {
              return 'draft-row';
            }
            return 'message-row';
          }}
        />
      </ScrollAreaLayout>
    </Stack>
  );
};

export default MessagesTableWrapper;
