import { styled, Switch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';

interface CustomSwitchProps extends SwitchProps {
  onIconPath?: string; // SVG path for "on" state icon
  offIconPath?: string; // SVG path for "off" state icon
}

const defaultOnIconPath = 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z';
const defaultOffIconPath = 'M19,13H5V11H19V13Z';

const OnOffSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'onIconPath' && prop !== 'offIconPath',
})<CustomSwitchProps>(
  ({ theme, onIconPath = defaultOnIconPath, offIconPath = defaultOffIconPath }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="${onIconPath}"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="${offIconPath}"/></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }),
);

export default OnOffSwitch;
