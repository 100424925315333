import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { ConfirmationDialog } from 'components/index';
import { useMenu, useServerError } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import UserTableItem from 'pages/organization-page/UserTableItem';
import { PendingUser } from 'types';

const PendingUserItem = ({ id, email, role }: PendingUser) => {
  const toastServerError = useServerError();
  const {
    value: removingPendingUser,
    setTrue: startRemovePendingUser,
    setFalse: stopRemovePendingUser,
  } = useBoolean();

  const { openMenu: openCancelPrompt, ...restCancelPrompt } = useMenu();

  return (
    <>
      <UserTableItem
        title={email}
        subtitle={role}
        icon='person_add'
        suffix={
          <SubmitButton
            size='small'
            submitting={removingPendingUser}
            submittingText='Removing...'
            color='error'
            variant='outlined'
            onClick={openCancelPrompt}
          >
            Cancel Invite
          </SubmitButton>
        }
      />
      <ConfirmationDialog
        text='Are you sure you want to cancel this invitation?'
        onConfirm={async () => {
          startRemovePendingUser();
          try {
            await api.delete(`${ApiKey.Users}/${ApiKey.OrganizationPendingUser}/${id}`);
            await mutate(`${ApiKey.Users}/${ApiKey.OrganizationPendingUser}`);
            stopRemovePendingUser();
          } catch (error: any) {
            toastServerError(error);
            stopRemovePendingUser();
          }
        }}
        submittingText='Removing...'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...restCancelPrompt}
      />
    </>
  );
};

export default PendingUserItem;
