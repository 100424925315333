import { Alert, Container, Grid, Stack, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { mutate } from 'swr';
import { api, ApiKey } from 'api';
import { Button } from 'components/index';

import { AccountPlanType, ExtendedUserRole, RoutePath } from 'enums';
import { SingleTextFieldEditorFrom } from 'forms';
import {
  useAuth,
  useFeatureAccess,
  useGetOrganizationActiveUser,
  useServerError,
  useTableHeight,
  useToast,
} from 'hooks';
import { HeaderWithBreadcrumbs } from 'layout';
import SectionWrapper from 'pages/manage-account-page/SectionWrapper';
import ActiveUsers from 'pages/organization-page/ActiveUsers';
import PendingUsers from 'pages/organization-page/PendingUsers';
import { OrganizationFormFieldsNames } from 'pages/organization-page/types';
import { organizationSchema } from 'pages/organization-page/utils';
import { Organization, User } from 'types';

const OrganizationPage = () => {
  const gridTableHeight = useTableHeight({ subtract: -24 });
  const { user, setUser } = useAuth();
  const { activeUsers } = useGetOrganizationActiveUser();

  const hasAccessToInviteUsers = useFeatureAccess({
    minRole: ExtendedUserRole.Admin,
  });

  const hasAccessToEditOrganization = useFeatureAccess({
    minRole: ExtendedUserRole.Owner,
  });

  const hasAccessToAssigneePlan = useFeatureAccess({
    minPlan: AccountPlanType.Premium,
  });

  const toastServerError = useServerError();
  const toast = useToast();

  const onEdit = async (updateProps: Partial<Organization>) => {
    try {
      const res = await api.patch<User>(`${ApiKey.Organization}`, updateProps);
      await mutate(ApiKey.User);
      await mutate(`${ApiKey.Users}/${user?.id}`);
      setUser?.(res.data);
      toast({
        message: `Organization name updated successfully`,
        variant: 'success',
      });
    } catch (error: any) {
      toastServerError(error);
    }
  };

  if (!user) {
    return null;
  }
  return (
    <>
      <Container maxWidth='md'>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          height={{
            xs: 'auto',
            md: gridTableHeight,
          }}
          gap={{
            xs: 0,
            md: 8,
          }}
        >
          <Stack flex={1}>
            <HeaderWithBreadcrumbs
              breadcrumbs={[
                { name: 'home', url: `/${RoutePath.Dashboard}` },
                { name: 'manage organization' },
              ]}
            />
            <Grid container spacing={{ xs: 2, md: 3 }} pb={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <SectionWrapper title='Organization Details'>
                  <Stack
                    gap={{
                      xs: 2,
                      md: 3,
                    }}
                    direction={{
                      xs: 'column',
                      md: 'row',
                    }}
                  >
                    <Stack flex={1}>
                      <Typography variant='body1'>Organization Name</Typography>
                      <Stack height={30} justifyContent='center'>
                        <SingleTextFieldEditorFrom
                          disabled={!hasAccessToEditOrganization}
                          boxProps={{ flex: 'auto', display: 'flex' }}
                          fieldProps={{
                            name: OrganizationFormFieldsNames.Name,
                            placeholder: 'Project Description',
                            autoFocus: true,
                            textVariant: 'body2',
                            multiline: true,
                            fullWidth: true,
                          }}
                          defaultValues={{
                            name: user.organization?.name || '',
                          }}
                          emptyStateText='Not provided'
                          onSubmit={onEdit}
                          schema={organizationSchema}
                        />
                      </Stack>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant='body1'>Organization Email</Typography>
                      <Stack height={30} justifyContent='center'>
                        <Typography variant='body2' color='textSecondary'>
                          {activeUsers?.find((activeUser) => activeUser.isOwner)?.email}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </SectionWrapper>
              </Grid>
              {hasAccessToInviteUsers && hasAccessToAssigneePlan && <PendingUsers />}
              {hasAccessToAssigneePlan && <ActiveUsers />}
              {!hasAccessToAssigneePlan && (
                <Grid item xs={12}>
                  <SectionWrapper title='Unlock Organization Features'>
                    <Alert
                      severity='info'
                      action={
                        <Button
                          color='inherit'
                          size='small'
                          onClick={() => {
                            window.open('https://t.me/erezz87', '_blank');
                          }}
                        >
                          Talk to Sales
                        </Button>
                      }
                    >
                      To unlock organization features, please upgrade to a premium plan.
                    </Alert>
                  </SectionWrapper>
                </Grid>
              )}
            </Grid>
          </Stack>
        </Stack>
      </Container>
      <Outlet />
    </>
  );
};

export default OrganizationPage;
