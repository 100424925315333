import { Skeleton, Stack } from '@mui/material';

const AppCardSkeleton = () => (
  <Stack
    p={2}
    bgcolor='background.default'
    height={1}
    border={1}
    borderRadius={1}
    borderColor='divider'
  >
    <Stack mb={1.5} direction='row' justifyContent='space-between' alignItems='center' gap={1}>
      <Stack flex={1}>
        <Stack direction='row' gap={1} flex={1} justifyContent='flex-start' alignItems='center'>
          <Skeleton variant='rounded' width={38} height={38} />
          <Stack flex={1}>
            <Skeleton variant='text' sx={{ fontSize: '1.2rem' }} width='40%' />
            <Skeleton variant='text' sx={{ fontSize: '0.8rem' }} width='30%' />
          </Stack>
          <Skeleton variant='text' width={40} sx={{ alignSelf: 'flex-start' }} />
        </Stack>
      </Stack>
    </Stack>
    <Stack mb={2}>
      <Skeleton variant='text' width='100%' />
      <Skeleton variant='text' width='66%' />
    </Stack>
    <Skeleton sx={{ alignSelf: 'flex-end' }} variant='rounded' width={140} height={30} />
  </Stack>
);

export default AppCardSkeleton;
