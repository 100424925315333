import { DraggableProvidedDragHandleProps, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { Stack, Typography } from '@mui/material';
import Icon from 'components/Icon';
import { IconButton } from 'components/index';
import Label from 'components/Label';

type Command = {
  name: string;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  dragSnapshot?: DraggableStateSnapshot | null;
  disableDrag?: boolean;
  icon?: string;
  active?: boolean;
  selected?: boolean;
  description?: string;
  inMenu?: boolean;
  onClick: () => void;
  onEdit?: () => void;
};

const CommandItem = ({
  name,
  selected,
  dragSnapshot,
  onClick,
  onEdit,
  icon,
  inMenu,
  description,
  dragHandleProps,
  disableDrag,
}: Command) => {
  const borderColor = () => {
    if (selected) {
      return 'grey.400';
    }
    if (dragSnapshot?.isDragging) {
      return 'primary.main';
    }
    return 'grey.200';
  };

  return (
    <Stack
      title={description}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      width={1}
      gap={1}
      onClick={onClick}
      sx={{
        border: dragSnapshot?.isDragging ? 2 : 1,
        borderStyle: dragSnapshot?.isDragging ? 'dashed' : 'solid',
        borderColor,
        bgcolor: 'grey.100',
        pr: 2,
        pl: 1,
        py: 0.5,
        width: 1,
        borderRadius: 1,
        '&:hover': {
          bgcolor: 'grey.50',
        },
        transition: (theme) =>
          theme.transitions.create(['background-color'], {
            duration: theme.transitions.duration.shortest,
          }),
      }}
    >
      <Stack alignSelf='stretch' justifyContent='center' {...dragHandleProps}>
        <Icon
          color={disableDrag ? 'grey.300' : 'grey.600'}
          fontSize='small'
          icon='drag_indicator'
          sx={{ cursor: disableDrag ? 'not-allowed' : 'grab' }}
        />
      </Stack>

      <Stack direction='row' alignItems='center' gap={1} flex={1} overflow='hidden'>
        <Icon icon={icon} fontSize='small' />
        <Typography noWrap fontWeight='bolder' variant='body2'>
          / {name}
        </Typography>
      </Stack>
      {inMenu && <Label color='info'>In Menu</Label>}

      <IconButton
        edge='end'
        tooltipPlacement='top'
        color='primary'
        tooltip='edit'
        icon='edit'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onEdit?.();
        }}
      />
    </Stack>
  );
};

export default CommandItem;
