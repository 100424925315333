import { object, ObjectSchema } from 'yup';

import { FormFieldProps } from 'types';
import { editorValidationOptional, emptyEditorValue } from 'utils';
import { ChatNoteFormFields, ChatNoteFormFieldsNames } from './types';

export const chatNoteFormSchema: ObjectSchema<ChatNoteFormFields> = object({
  [ChatNoteFormFieldsNames.Note]: editorValidationOptional,
});

export const chatNoteDefaultValues: ChatNoteFormFields = {
  [ChatNoteFormFieldsNames.Note]: emptyEditorValue,
};

export const chatNoteFieldsConfig: Record<ChatNoteFormFieldsNames, FormFieldProps> = {
  [ChatNoteFormFieldsNames.Note]: {
    placeholder: 'Add an internal note, if needed',
    name: ChatNoteFormFieldsNames.Note,
    autoComplete: 'off',
    type: 'text',
  },
};
