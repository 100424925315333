import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormTextFieldInlineEdit } from 'components/index';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { RequestEmailFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-email/types';

import {
  defaultRequestEmailFormValues,
  requestEmailFieldsConfig,
  requestEmailStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-email/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface RequestEmailProps {
  id: string;
  defaultValues?: Partial<RequestEmailFormFields>;
  defaultName: string;
  mentions?: Mention[];
  commands?: Mention[];
}

const RequestEmail = ({
  defaultValues,
  mentions,
  commands,
  id,
  defaultName,
}: RequestEmailProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultRequestEmailFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || defaultRequestEmailFormValues.labels || [],
  });

  const formMethods = useFormProvider<RequestEmailFormFields>({
    schema: requestEmailStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<RequestEmailFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const processNewLabels = useProcessNewLabels();

  const { reset } = formMethods;

  const handleOnSubmit = async (formData: RequestEmailFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, labels, input } = requestEmailFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formMethods.formState} reset={reset} />
    </StepFormWrapper>
  );
};

export default memo(RequestEmail);
