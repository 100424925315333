export enum NavigationTabs {
  Home = 'home',
  Dashboard = 'Dashboard',
  Projects = 'Projects',
  Activity = 'Activity',
  ProjectManager = 'ProjectManager',
  ManageAccount = 'ManageAccount',
  Tickets = 'Tickets',
}

interface NavigationTabsProps {
  label: string;
  value: NavigationTabs;
  icon: string;
}

export const navigationTabs: Record<NavigationTabs, NavigationTabsProps> = {
  [NavigationTabs.Home]: {
    label: 'Home',
    value: NavigationTabs.Home,
    icon: 'home',
  },
  [NavigationTabs.Dashboard]: {
    label: 'Dashboard',
    value: NavigationTabs.Dashboard,
    icon: 'dashboard',
  },
  [NavigationTabs.Projects]: {
    label: 'Projects',
    value: NavigationTabs.Projects,
    icon: 'smart_toy',
  },
  [NavigationTabs.Activity]: {
    label: 'Activity',
    value: NavigationTabs.Activity,
    icon: 'analytics',
  },
  [NavigationTabs.ProjectManager]: {
    label: 'Project Manager',
    value: NavigationTabs.ProjectManager,
    icon: 'smart_toy',
  },
  [NavigationTabs.ManageAccount]: {
    label: 'Manage Account',
    value: NavigationTabs.ManageAccount,
    icon: 'manage_accounts',
  },
  [NavigationTabs.Tickets]: {
    label: 'Tickets',
    value: NavigationTabs.Tickets,
    icon: 'receipt',
  },
};
