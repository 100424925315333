import { alpha, IconButton, InputBase, Stack, useTheme } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useIsMobile } from 'hooks';
import Icon from './Icon';

interface SearchInputProps {
  handleSearchChange: (value: string) => void;
  search: string;
  placeholder?: string;
  areaLabel?: string;
}
const SearchField = ({
  search,
  handleSearchChange,
  placeholder = 'Search',
  areaLabel = 'search-field',
}: SearchInputProps) => {
  const theme = useTheme();
  const { isSmallMobile } = useIsMobile();
  const { value: focused, setTrue: setInputFocused, setFalse: setInputNotFocused } = useBoolean();

  const ref = useRef<HTMLInputElement>(null);

  const inputShouldBeWide = !isSmallMobile || focused || search;

  const handleFocus = () => {
    setInputFocused();
    ref.current?.focus();
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      px={inputShouldBeWide ? 1 : 0.375}
      gap={inputShouldBeWide ? 1 : 0}
      sx={{
        bgcolor: alpha(theme.palette.primary.main, 0.08),
        borderRadius: 1,
        '&:hover': {
          bgcolor: alpha(theme.palette.primary.main, 0.12),
        },
      }}
    >
      <IconButton
        disableRipple
        sx={{ p: 0.5 }}
        size='small'
        aria-label={`${areaLabel}-search`}
        onClick={() => {
          handleFocus();
        }}
      >
        <Icon icon='search' fontSize='small' color={inputShouldBeWide ? 'disabled' : 'primary'} />
      </IconButton>

      <InputBase
        inputRef={ref}
        sx={{
          flex: 1,
          typography: 'body2',
          height: 34,
          width: inputShouldBeWide ? 1 : 0,
        }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': areaLabel }}
        value={search}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleSearchChange(event.target.value);
        }}
        onBlur={() => {
          setInputNotFocused();
        }}
      />

      {inputShouldBeWide && (
        <IconButton
          disableRipple={search === ''}
          sx={{ p: 0.5, cursor: search ? 'pointer' : 'default' }}
          size='small'
          aria-label={`${areaLabel}-clear`}
          onClick={() => {
            if (search === '') {
              handleFocus();
            } else {
              handleSearchChange('');
            }
          }}
        >
          <Icon
            icon='close'
            fontSize='small'
            color='disabled'
            sx={{
              visibility: search ? 'visible' : 'hidden',
            }}
          />
        </IconButton>
      )}
    </Stack>
  );
};

export default SearchField;
