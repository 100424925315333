import { alpha, Popover, PopoverProps, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useBoolean } from 'usehooks-ts';
import Button from 'components/Button';
import { OptionsMenuState } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import { menuMinWidth } from 'theme/utils';

const promptProps = (
  anchorOrigin?: PopoverProps['anchorOrigin'],
  transformOrigin?: PopoverProps['transformOrigin'],
  maxWidth?: number | 'auto',
): Partial<PopoverProps> => ({
  anchorOrigin: anchorOrigin || {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: transformOrigin || {
    vertical: 'bottom',
    horizontal: 'left',
  },
  slotProps: {
    paper: {
      sx: {
        minWidth: menuMinWidth,
        maxWidth: {
          xs: 'calc(100% - 32px)',
          sm: maxWidth,
        },
      },
    },
  },
  sx: {
    '& .MuiBackdrop-root': {
      backgroundColor: (theme) => alpha(theme.palette.common.black, 0.24),
    },
  },
});

interface ConfirmationDialogProps extends Omit<OptionsMenuState, 'openMenu'> {
  onConfirm: () => void | Promise<void>;
  onClose?: () => void;
  text?: string;
  node?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  submittingText?: string;
  submitButtonColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'info'
    | 'warning'
    | 'inherit';
  disabled?: boolean;
  hideCancelButton?: boolean;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  disableAutoClose?: boolean;
  maxWidth?: number | 'auto';
}
const ConfirmationDialog = ({
  anchorEl,
  isMenuOpen,
  closeMenu,
  onClose,
  onConfirm,
  text,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  submittingText = 'Submitting...',
  submitButtonColor = 'error',
  hideCancelButton,
  anchorOrigin,
  transformOrigin,
  disabled,
  node,
  maxWidth = 480,
  disableAutoClose,
}: ConfirmationDialogProps) => {
  const { value: loading, setTrue: startLoading, setFalse: stopLoading } = useBoolean(false);

  return (
    <Popover
      {...promptProps(anchorOrigin, transformOrigin, maxWidth)}
      id='delete-prompt-menu'
      autoFocus={false}
      aria-labelledby='delete-prompt-menu'
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={() => {
        onClose?.();
        if (!disableAutoClose) {
          closeMenu();
        }
      }}
    >
      <Stack gap={2} px={2} py={1}>
        {text && (
          <Typography variant='body2' fontWeight='bolder'>
            {text}
          </Typography>
        )}
        {node}
        <Stack direction='row' gap={1} justifyContent='flex-end'>
          {!hideCancelButton && (
            <Button
              size='small'
              onClick={() => {
                onClose?.();
                if (!disableAutoClose) {
                  closeMenu();
                }
              }}
            >
              {cancelText}
            </Button>
          )}
          <SubmitButton
            disabled={disabled}
            variant='text'
            color={submitButtonColor}
            size='small'
            submittingText={submittingText}
            submitting={loading}
            onClick={async () => {
              startLoading();
              await onConfirm();
              stopLoading();
              if (!disableAutoClose) {
                closeMenu();
              }
            }}
          >
            {confirmText}
          </SubmitButton>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default ConfirmationDialog;
