import useSWR from 'swr';
import { ApiKey } from 'api';
import { PendingUser } from 'types';

const defaultPendingUsers: PendingUser[] = [];

export const useGetOrganizationPendingUser = () => {
  const { data, error, isLoading } = useSWR<PendingUser[]>(
    `${ApiKey.Users}/${ApiKey.OrganizationPendingUser}`,
  );

  return {
    pendingUsers: data ?? defaultPendingUsers,
    isLoading,
    error,
  };
};
