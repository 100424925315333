import { AutoCompleteLabel } from 'types';

export enum YesNoStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Variable = 'variable',
  Menu = 'menu',
  RemoveAnswerOptions = 'removeAnswerOptions',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export enum YesNoStepFormFieldsMenuNames {
  Option = 'option',
  NodeId = 'nodeId',
}

export interface YesNoStepFormFields {
  [YesNoStepFormFieldsNames.NodeId]: string;
  [YesNoStepFormFieldsNames.Name]: string;
  [YesNoStepFormFieldsNames.Menu]: YesNoStepFormFieldsMenu[];
  [YesNoStepFormFieldsNames.RemoveAnswerOptions]?: boolean;
  [YesNoStepFormFieldsNames.Input]: string;
  [YesNoStepFormFieldsNames.Variable]?: string;
  [YesNoStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export interface YesNoStepFormFieldsMenu {
  [YesNoStepFormFieldsMenuNames.Option]: string;
  [YesNoStepFormFieldsMenuNames.NodeId]: string;
}
