import { FormControl, FormLabel, InputBase, Rating, RatingProps } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';

export interface FormRatingProps extends Omit<RatingProps, 'id'> {
  name: string;
  label?: string;
}
export const FormRadioGroup = (props: FormRatingProps) => {
  const { name, label, sx, ...rest } = props;
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ref, onChange, ...field }, fieldState: { error } }) => (
        <FormControl className='notranslate'>
          {label && <FormLabel>{label}</FormLabel>}
          <InputBase
            // hide input but available focus
            sx={{ height: 0, width: 0, position: 'absolute' }}
            inputRef={ref}
          />
          <Rating
            {...rest}
            sx={{ ...sx, py: 0.75 }}
            id={name}
            value={value}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            {...field}
          />
          <FormCollapseHelperText error={error} />
        </FormControl>
      )}
    />
  );
};

export default memo(FormRadioGroup);
