import { cloneDeep } from 'lodash';
import { mutate } from 'swr';

// Defining the types for clarity and safety
type UpdateSpec<T> = {
  key: string;
  id: string;
  path?: keyof T;
  data: Partial<T> | any; // Partial<T> for updating parts of T, any if path is provided
};

export const useOptimisticUpdate =
  <T extends { id: string }>() =>
  async ({ key, id, path, data }: UpdateSpec<T>) => {
    await mutate(
      key,
      async (currentData: T[] | undefined) => {
        if (!currentData) {
          return undefined;
        }

        // Deep clone currentData to avoid mutating the original objects
        const dataClone = cloneDeep(currentData);

        // Find and update the specific item
        return dataClone.map((item) => {
          if (item.id === id) {
            // If a path is provided, update only that part of the item
            if (path) {
              return { ...item, [path]: data };
            }
            // No path provided, replace the whole item with data
            return { ...item, ...data };
          }
          return item;
        });
      },
      false,
    ); // Pass 'false' to not revalidate immediately
  };
