import { object, ObjectSchema, string } from 'yup';
import { OrganizationFormFields, OrganizationFormFieldsNames } from 'pages/organization-page/types';
import { FormFieldProps } from 'types';

export const organizationSchema: ObjectSchema<OrganizationFormFields> = object({
  [OrganizationFormFieldsNames.Name]: string(),
});

export const defaultOrganizationFormValues: OrganizationFormFields = {
  [OrganizationFormFieldsNames.Name]: '',
};
export const organizationFormFieldsConfig: Record<OrganizationFormFieldsNames, FormFieldProps> = {
  [OrganizationFormFieldsNames.Name]: {
    label: 'Organization Name',
    placeholder: 'Organization Name',
    name: OrganizationFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
};
