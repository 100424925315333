import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import Icon from 'components/Icon';
import MenuSectionHeader from 'components/menu/MenuSectionHeader';
import { useIsMobile, useMenu } from 'hooks';
import { MessageColumns, MessageSortableKeys } from 'pages/app-pages/messages-scheduler-page/types';
import { menuMinWidth } from 'theme/utils';
import { ChatGroupMessage } from 'types';
import { sortByDate } from 'utils';

export const sortByMenuItems: {
  field: MessageSortableKeys;
  label: string;
  sorter: (a: ChatGroupMessage, b: ChatGroupMessage) => number;
}[] = [
  {
    field: MessageColumns.Message,
    label: 'Alphabetical',
    sorter: (a, b) => (a.message || '').localeCompare(b.message || ''),
  },
  {
    field: MessageColumns.UpdatedAt,
    label: 'Last Modified',
    sorter: (a, b) => sortByDate(a.updatedAt, b.updatedAt),
  },
  {
    field: MessageColumns.DeliveryDate,
    label: 'Delivery Date',
    sorter: (a, b) => {
      if (a.deliveryDate && b.deliveryDate) {
        return sortByDate(a.deliveryDate, b.deliveryDate);
      }
      if (!a.deliveryDate) {
        return 1;
      }
      if (!b.deliveryDate) {
        return -1;
      }
      return 0;
    },
  },
];

const orderMenuItems: {
  field: GridSortDirection;
  label: { [key in MessageSortableKeys]: string };
}[] = [
  {
    field: 'asc',
    label: {
      [MessageColumns.Message]: 'A-Z',
      [MessageColumns.UpdatedAt]: 'Newest First',
      [MessageColumns.DeliveryDate]: 'Newest First',
    },
  },
  {
    field: 'desc',
    label: {
      [MessageColumns.Message]: 'Z-A',
      [MessageColumns.UpdatedAt]: 'Oldest First',
      [MessageColumns.DeliveryDate]: 'Oldest First',
    },
  },
];

interface SortByProps {
  sortModel: GridSortModel;
  onSortModelChange: (newSortModel: GridSortModel) => void;
}
const SortByMessages = ({ sortModel, onSortModelChange }: SortByProps) => {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();
  const { isMobile } = useIsMobile();

  return (
    <>
      <Button
        onClick={openMenu}
        sx={{
          '& .MuiButton-endIcon': {
            ml: 0.5,
          },
        }}
        size={isMobile ? 'small' : 'medium'}
        endIcon={<Icon icon='expand_more' fontSize='small' />}
        startIcon={
          <Icon
            icon={sortModel[0]?.sort === 'desc' ? 'arrow_downward' : 'arrow_upward'}
            fontSize='small'
          />
        }
      >
        <Box
          component='span'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {sortByMenuItems.find((item) => item.field === sortModel[0]?.field)?.label}
        </Box>
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        <MenuSectionHeader title='Sort By' />
        {sortByMenuItems.map((item) => (
          <MenuItem
            key={item.field}
            onClick={() => {
              onSortModelChange(
                sortModel.map((sortItem) => ({
                  field: item.field,
                  sort: sortItem.sort,
                })),
              );
              closeMenu();
            }}
          >
            <ListItemIcon>
              {sortModel.find((sortItem) => sortItem.field === item.field) && (
                <Icon icon='check' fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuSectionHeader title='Order' />
        {orderMenuItems.map((item) => (
          <MenuItem
            key={item.field}
            onClick={() => {
              onSortModelChange(
                sortModel.map((sortItem) => ({
                  field: sortItem.field,
                  sort: item.field,
                })),
              );
              closeMenu();
            }}
          >
            <ListItemIcon>
              {sortModel.find((sortItem) => sortItem.sort === item.field) && (
                <Icon icon='check' fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>
              {
                orderMenuItems.find((orderItem) => orderItem.field === item.field)?.label[
                  sortModel[0]?.field as MessageSortableKeys
                ]
              }
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SortByMessages;
