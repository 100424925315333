import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import { IconBadge } from 'components/index';
import { RoutePath } from 'enums';
import { MessengerOnboardingSteps } from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/types';
import { onboardingStepsConfig } from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/utils';
import {
  FormMode,
  GroupMessageFormLocalState,
} from 'pages/app-pages/messages-scheduler-page/message-modal/types';
import { ChatGroup } from 'types';

const OnboardingStep = ({
  step,
  id,
  isActive,
  done,
  chatGroups,
}: {
  step: MessengerOnboardingSteps;
  id: string;
  isActive: boolean;
  done?: boolean;
  chatGroups: ChatGroup[];
}) => {
  const stepProps = onboardingStepsConfig(id)[step];
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (step === MessengerOnboardingSteps.Messages) {
      navigate(`/${RoutePath.Messenger}/${id}/${RoutePath.Composer}`, {
        replace: true,
        state: {
          projectId: id,
          mode: FormMode.Create,
          chatGroups: chatGroups || [],
          initialValues: {
            project: id,
          },
        } as GroupMessageFormLocalState,
      });
    } else {
      navigate(`/${stepProps.route}`);
    }
  };

  return (
    <Stack alignItems='center' px={2} flex={1} gap={1} width={1} maxWidth={{ xs: 400, md: 1 }}>
      {done ? (
        <IconBadge icon='check' />
      ) : (
        <IconBadge
          bgcolor={isActive ? 'secondary.main' : 'grey.200'}
          color={isActive ? 'primary.contrastText' : 'primary.main'}
          fontWeight={isActive ? 'bold' : 'normal'}
        >
          {stepProps.stepNumber}
        </IconBadge>
      )}

      <Typography
        textAlign='center'
        variant='h6'
        pt={1}
        color={isActive ? 'text.primary' : 'text.secondary'}
      >
        {stepProps.title}
      </Typography>
      <Typography
        color={isActive ? 'text.primary' : 'text.secondary'}
        textAlign='center'
        variant='body2'
        flex={1}
      >
        {stepProps.description}
      </Typography>

      <Button
        sx={{ mt: 4 }}
        disabled={!isActive}
        startIcon={<Icon icon={stepProps.icon} fontSize='small' />}
        variant='contained'
        color='info'
        onClick={handleNavigate}
      >
        {stepProps.buttonText}
      </Button>
    </Stack>
  );
};

export default OnboardingStep;
