import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { rossListItemClasses } from 'theme';

interface ListItemProps {
  children: ReactNode;
}
const ListItem = ({ children }: ListItemProps) => (
  <Stack width={1} className={rossListItemClasses.root}>
    {children}
  </Stack>
);

export default ListItem;
