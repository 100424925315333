import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { mutate } from 'swr';
import { api, ApiKey } from 'api';
import { FormAutoComplete } from 'components/index';
import { useFormProvider, useServerError, useToast } from 'hooks';
import CustomAutocompletePopper from 'pages/activity-page/assignee/CustomAutocompletePopper';
import { AssigneeFormFields, AssigneeFormFieldsNames } from 'pages/activity-page/assignee/types';
import { assigneeFieldsConfig, assigneeFormSchema } from 'pages/activity-page/assignee/utils';
import { User } from 'types';

const Assignee = ({
  defaultValues,
  id,
  options,
  isLoading,
}: {
  id: string;
  options: User[];
  isLoading: boolean;
  defaultValues?: User;
}) => {
  const toast = useToast();
  const serverError = useServerError();

  const { user } = assigneeFieldsConfig;

  const formMethods = useFormProvider<AssigneeFormFields>({
    reValidateMode: 'onChange',
    schema: assigneeFormSchema,
    defaultValues: {
      [AssigneeFormFieldsNames.User]: defaultValues
        ? {
            value: defaultValues?.id || '',
            label: defaultValues?.email || '',
          }
        : undefined,
    },
  });

  const handleOnSubmit = async (formData: AssigneeFormFields) => {
    try {
      await api.patch(`${ApiKey.Chat}/${id}`, {
        ticketAssignee: formData[AssigneeFormFieldsNames.User]?.value || null,
      });
      await mutate(ApiKey.Chat);
      await mutate(`${ApiKey.Chat}/${ApiKey.Ticket}`);
      toast({
        variant: 'success',
        message: 'Assignee updated successfully',
      });
    } catch (error) {
      serverError(error);
    }
  };

  return (
    <Stack onClick={(e) => e.stopPropagation()} width={1} height={1}>
      <FormProvider {...formMethods}>
        <FormAutoComplete
          {...user}
          size='small'
          variant='standard'
          options={options.map((u) => ({ value: u.id, label: u.email }))}
          loading={isLoading}
          onChangeCallback={() => {
            formMethods.handleSubmit(handleOnSubmit)();
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          sx={{
            height: 1,
          }}
          PopperComponent={CustomAutocompletePopper} // Using the custom Popper component
          textFieldWrapperSx={{
            height: 1,
            justifyContent: 'center',
          }}
          textFieldProps={{
            sx: {
              '&.MuiFormControl-root': {
                height: 1,
              },
              '& .MuiAutocomplete-inputRoot': {
                height: 1,
                bgcolor: 'transparent',
                '&:before': {
                  display: 'none',
                },
                '&:after': {
                  display: 'none',
                },
              },
              '& .MuiAutocomplete-input': {
                height: 1,
                p: '0 !important',
              },
            },
          }}
        />
      </FormProvider>
    </Stack>
  );
};

export default Assignee;
