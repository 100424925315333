import { ComponentType } from 'react';

export enum ToolboxTabs {
  NodeEditor = 'NodeEditor',
  BotSettings = 'BotSettings',
  StartNode = 'StartNode',
  CommandsSettings = 'CommandsSettings',
  Publish = 'Publish',
}

export interface ToolboxTabProps {
  label: string;
  value: ToolboxTabs;
  component: ComponentType;
}
