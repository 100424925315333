import { useMediaQuery, useTheme } from '@mui/material';

export const useIsMobile = () => {
  const theme = useTheme();
  return {
    isMobile: !useMediaQuery(theme.breakpoints.up('md')),
    isSmallMobile: !useMediaQuery(theme.breakpoints.up('sm')),
    isDesktop: useMediaQuery(theme.breakpoints.up('lg')),
  };
};
