import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useConfirmUnsavedNodeChanges } from 'pages/app-pages/chatbot-builder-page/hooks/useConfirmUnsavedNodeChanges';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { useDirtyStore } from 'store';

interface NodeWrapperProps {
  children: ReactNode;
  id: string;
  toolboxTab: ToolboxTabs;
  selected?: boolean;
  onClick?: () => void;
}
const NodeWrapper = ({ children, selected, onClick, toolboxTab, id }: NodeWrapperProps) => {
  const { setSelectedNodeId, openToolbox, setActiveToolboxTab } = useBuilderStore((state) => ({
    openToolbox: state.openToolbox,
    setSelectedNodeId: state.setSelectedNodeId,
    setActiveToolboxTab: state.setActiveToolboxTab,
  }));

  const { isDirty } = useDirtyStore((state) => ({
    isDirty: state.isDirty,
  }));

  const confirmUnsavedNodeChanges = useConfirmUnsavedNodeChanges();

  const handleNodeClick = () => {
    setActiveToolboxTab(toolboxTab);
    setSelectedNodeId(id);
    openToolbox();
    onClick?.();
  };

  const onNodeClick = () => {
    if (isDirty) {
      confirmUnsavedNodeChanges(() => {
        handleNodeClick();
      });
    } else {
      handleNodeClick();
    }
  };

  return (
    <Stack
      justifyContent='center'
      onClick={onNodeClick}
      alignItems='center'
      bgcolor='background.default'
      width={220}
      border={1}
      gap={0.5}
      borderRadius={1}
      borderColor='grey.200'
      sx={{
        boxShadow: (theme) => (selected ? theme.shadows[6] : 'none'),
        transition: (theme) =>
          theme.transitions.create(['background-color', 'box-shadow'], {
            duration: theme.transitions.duration.shortest,
          }),
        cursor: 'pointer',
        '&:hover': {
          boxShadow: (theme) => theme.shadows[6],
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default NodeWrapper;
