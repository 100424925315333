import { array, bool, object, ObjectSchema, string } from 'yup';
import messages from 'messages';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  TrueFalseQuestionStepFormFields,
  TrueFalseQuestionStepFormFieldsNames,
  TrueFalseQuestionStepFormFieldsOptionsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/true-false-question/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue, variableValidation } from 'utils';

export const trueFalseQuestionStepFormSchema: ObjectSchema<TrueFalseQuestionStepFormFields> =
  object({
    [TrueFalseQuestionStepFormFieldsNames.NodeId]: string().required('Node is required'),
    [TrueFalseQuestionStepFormFieldsNames.Name]: string().required('Name is required'),
    [TrueFalseQuestionStepFormFieldsNames.Input]: editorValidation,
    [TrueFalseQuestionStepFormFieldsNames.Variable]: variableValidation,
    [TrueFalseQuestionStepFormFieldsNames.Answers]: array(
      object({
        [TrueFalseQuestionStepFormFieldsOptionsNames.Option]:
          string().required('Option is required'),
        [TrueFalseQuestionStepFormFieldsOptionsNames.NodeId]: string().required('Node is required'),

        [TrueFalseQuestionStepFormFieldsOptionsNames.Name]: string().required('Name is required'),
        [TrueFalseQuestionStepFormFieldsOptionsNames.IsCorrect]: bool().required('Is correct?'),
      }),
    ).required(),
    [TrueFalseQuestionStepFormFieldsNames.RemoveAnswerOptions]: bool(),
    [TrueFalseQuestionStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
  });

export const defaultTrueFalseQuestionStepFormValues: TrueFalseQuestionStepFormFields = {
  [TrueFalseQuestionStepFormFieldsNames.NodeId]: '',
  [TrueFalseQuestionStepFormFieldsNames.Name]: 'Quiz True/False Question',
  [TrueFalseQuestionStepFormFieldsNames.Input]: emptyEditorValue,
  [TrueFalseQuestionStepFormFieldsNames.Answers]: [],
  [TrueFalseQuestionStepFormFieldsNames.RemoveAnswerOptions]: false,
  [TrueFalseQuestionStepFormFieldsNames.Labels]: [],
};

export const TrueFalseQuestionFormFieldsConfig: Record<
  TrueFalseQuestionStepFormFieldsNames,
  FormFieldProps
> = {
  [TrueFalseQuestionStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: TrueFalseQuestionStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [TrueFalseQuestionStepFormFieldsNames.Input]: {
    placeholder: '* Question',
    name: TrueFalseQuestionStepFormFieldsNames.Input,
    type: 'textarea',
  },
  [TrueFalseQuestionStepFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: TrueFalseQuestionStepFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [TrueFalseQuestionStepFormFieldsNames.Answers]: {
    label: 'Answers',
    placeholder: 'Add Answer',
    name: TrueFalseQuestionStepFormFieldsNames.Answers,
    autoComplete: 'off',
    type: 'text',
  },
  [TrueFalseQuestionStepFormFieldsNames.NodeId]: {
    name: TrueFalseQuestionStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [TrueFalseQuestionStepFormFieldsNames.RemoveAnswerOptions]: {
    label: messages.builder.removeAnswerOptions,
    name: TrueFalseQuestionStepFormFieldsNames.RemoveAnswerOptions,
    type: 'checkbox',
  },
  [TrueFalseQuestionStepFormFieldsNames.Labels]: labelFormFieldConfig,
};
