import { Alert, Container, Grid, Link, Stack, Typography } from '@mui/material';
import { isEmpty, times } from 'lodash';
import { Outlet, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { CardHeader } from '@common-components';
import { ApiKey } from 'api';
import { RoutePath } from 'enums';
import { appConfig } from 'forms/create-project/utils';
import { useGetChatActivity, useTableHeight } from 'hooks';
import AppCard from 'pages/project-manager-page/app-card/AppCard';
import AppCardSkeleton from 'pages/project-manager-page/app-card/AppCardSkeleton';
import BotSettings from 'pages/project-manager-page/BotSettings';
import Header from 'pages/project-manager-page/Header';
import { mainBackground } from 'theme/utils';
import { Project } from 'types';

const ProjectManagerPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const gridTableHeight = useTableHeight({ subtract: -24 });

  const { data: project, isLoading } = useSWR<Project>(`${ApiKey.Project}/${id}`);
  const { chats } = useGetChatActivity();
  const projectChats = chats.filter((chat) => chat.projectId === id);

  const projectFound = isLoading ? undefined : !!project;

  if (projectFound === false) {
    navigate(`/${RoutePath.NotFound}`, { replace: true });
  }

  return (
    <>
      <Container maxWidth='lg'>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          height={{
            xs: 'auto',
            md: gridTableHeight,
          }}
          gap={{
            xs: 0,
            md: 8,
          }}
        >
          <Stack flex={1}>
            <Header
              isLoading={isLoading}
              name={project?.name || ''}
              description={project?.description || ''}
              onClick={() => {
                navigate(`${RoutePath.RenameProject}`, {
                  state: {
                    project,
                  },
                });
              }}
            />
            <Stack overflow={{ xs: 'visible', md: 'auto' }}>
              {!isLoading && (
                <Typography
                  position='sticky'
                  top={0}
                  variant='subtitle1'
                  component='h2'
                  noWrap
                  minHeight={40}
                  bgcolor={mainBackground}
                  pb={2}
                >
                  Browse Apps
                </Typography>
              )}
              <Grid container spacing={{ xs: 2, sm: 3 }} pb={{ xs: 2, sm: 3 }}>
                {isLoading &&
                  times(2, (i) => (
                    <Grid item xs={12} sm={6} md={12} lg={6} key={i}>
                      <AppCardSkeleton />
                    </Grid>
                  ))}

                {projectFound === true &&
                  Object.values(appConfig)
                    .filter(({ comingSoon }) => !comingSoon)
                    .sort((a, b) => {
                      // First, sort by connection status
                      const aFound = !!project?.apps?.find((app) => app.type === a.type);
                      const bFound = !!project?.apps?.find((app) => app.type === b.type);
                      if (aFound && !bFound) {
                        return -1;
                      }
                      if (!aFound && bFound) {
                        return 1;
                      }

                      // Then, sort alphabetically by label
                      return a.label.localeCompare(b.label);
                    })
                    .map(({ type }) => {
                      const foundApp = project?.apps?.find((app) => app.type === type);
                      const isConnected = !!foundApp;
                      const isActive = foundApp ? foundApp.isActive : false;

                      return (
                        <Grid key={type} item xs={12} sm={6} md={12} lg={6}>
                          <AppCard
                            connected={isConnected}
                            isActive={isActive}
                            project={project!}
                            type={type}
                            updateAt={foundApp?.updatedAt}
                          />
                        </Grid>
                      );
                    })}
                <Grid item xs={12}>
                  <Alert severity='info' sx={{ border: 1, borderColor: 'divider' }}>
                    Exciting New Apps Coming Soon to Enhance Your Telegram Bots! Stay Tuned for More
                    Innovation, No Coding Required.
                  </Alert>
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <Stack
            flex={{
              xs: 'auto',
              md: '0 0 360px',
            }}
            pt={{
              xs: 0,
              md: 6,
            }}
            pb={3}
          >
            <Stack
              p={2}
              height={1}
              borderRadius={1}
              border={1}
              borderColor='divider'
              bgcolor='background.default'
              position='relative'
              overflow={{ xs: 'visible', md: 'auto' }}
            >
              <CardHeader title='Telegram Bot Configuration' />
              <BotSettings project={project} isLoading={isLoading} />
              {!isEmpty(projectChats) && (
                <Stack mt={3}>
                  <CardHeader title='Chatbot Activity' />
                  <Typography variant='body2' noWrap>
                    There are{' '}
                    <Link
                      component={RouterLink}
                      to={`/${RoutePath.Activity}`}
                      state={{ projectName: project?.name }}
                      underline='none'
                      variant='body2'
                    >
                      {projectChats.length} messages
                    </Link>{' '}
                    in this chatbot
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Outlet />
    </>
  );
};

export default ProjectManagerPage;
