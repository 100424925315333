import { Dialog, DialogContent, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useIsMobile } from 'hooks';

type AuthDialogWrapperProps = {
  children: ReactNode;
  areaLabel: string;
};
const AuthDialogWrapper = ({ children, areaLabel }: AuthDialogWrapperProps) => {
  const { isSmallMobile } = useIsMobile();

  return (
    <Dialog
      transitionDuration={0}
      maxWidth='sm'
      fullScreen={isSmallMobile}
      // eslint-disable-next-line react/jsx-boolean-value
      open={true}
      aria-labelledby={areaLabel}
    >
      <DialogContent sx={{ width: isSmallMobile ? 1 : 400 }}>
        <Stack width={1} py={1} alignItems='center' gap={2}>
          {children}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialogWrapper;
