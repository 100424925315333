import { Box, Stack, Typography } from '@mui/material';
import { RobotSleep } from 'assets/images';
import Button from 'components/Button';

const ErrorPage = () => (
  <Stack alignItems='center' justifyContent='center' gap={2} height={1}>
    <Box
      sx={{
        textAlign: 'center',
        '& svg': {
          width: '100%',
        },
      }}
    >
      <Typography align='center' variant='h6' mb={3}>
        Something went wrong...
      </Typography>
      <RobotSleep />
    </Box>
    <Button
      variant='contained'
      onClick={() => {
        window.location.reload();
      }}
    >
      Reload
    </Button>
  </Stack>
);

export default ErrorPage;
