import { useNavigate } from 'react-router-dom';
import { useHistoryLength } from 'store';

export const useNavigateBack = (fallbackUrl?: string) => {
  const navigate = useNavigate();

  const { historyLength } = useHistoryLength((state) => ({
    historyLength: state.length,
  }));

  return () => {
    if (historyLength > 1) {
      navigate(-1);
    } else if (fallbackUrl) {
      navigate(fallbackUrl, { replace: true });
    } else {
      navigate('./..', { replace: true });
    }
  };
};
