import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export interface Breadcrumb {
  name: string;
  url?: string;
}
interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}
const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => (
  <MuiBreadcrumbs aria-label='breadcrumb'>
    {breadcrumbs.map(({ name, url }) =>
      url ? (
        <Link
          key={name}
          component={RouterLink}
          to={url}
          variant='subtitle2'
          fontWeight='normal'
          underline='none'
        >
          {name}
        </Link>
      ) : (
        <Typography key={name} variant='subtitle2' fontWeight='normal'>
          {name}
        </Typography>
      ),
    )}
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
