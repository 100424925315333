import { alpha, Box, Stack, Tooltip, Typography } from '@mui/material';
import { Image } from 'mui-image';
import Icon from 'components/Icon';
import QuillContentDisplay from 'components/QuillContentDisplay';

import { isBasicColorValid } from 'theme/utils';
import { ConversationMessageType, Sender } from 'types';
import { MessageOrder, MessagePosition } from './types';
import {
  avatarSize,
  bubbleStyle,
  getColorByMessageType,
  getIconByMessageType,
  getTooltipByMessageType,
} from './utils';

interface ChatBubbleProps {
  nodeType: ConversationMessageType;
  caption: string;
  sender: Sender;
  position: MessagePosition;
  order: MessageOrder;
  showAvatar: boolean;
  message?: string;
  fileUrl?: string;
}

const ChatBubble = ({
  message,
  position,
  order,
  caption,
  sender,
  nodeType,
  showAvatar,
  fileUrl,
}: ChatBubbleProps) => {
  const color = sender === Sender.Bot ? getColorByMessageType(nodeType) : 'primary';

  return (
    <Stack direction='column'>
      <Stack direction={position === MessagePosition.Right ? 'row-reverse' : 'row'} gap={1}>
        <Box width={avatarSize} height={avatarSize}>
          {showAvatar ? (
            <Tooltip title={getTooltipByMessageType(nodeType)} placement='top' arrow>
              <Box
                width={avatarSize}
                height={avatarSize}
                bgcolor={(theme) =>
                  alpha(isBasicColorValid(color) ? theme.palette[color].light : color, 0.18)
                }
                color={(theme) => (isBasicColorValid(color) ? theme.palette[color].main : color)}
                display='flex'
                alignItems='center'
                justifyContent='center'
                borderRadius='50%'
              >
                <Icon icon={getIconByMessageType(nodeType)} color='inherit' fontSize='small' />
              </Box>
            </Tooltip>
          ) : (
            <Box width={avatarSize} height={avatarSize} />
          )}
        </Box>
        <Stack
          direction='column'
          width={1}
          alignItems={position === MessagePosition.Right ? 'flex-end' : 'flex-start'}
        >
          <Box sx={{ ...bubbleStyle(order, position) }}>
            {fileUrl && (
              <Box
                width='100%'
                maxWidth={200}
                height='fit-content'
                mt={2}
                mb={message ? 1 : 2}
                onClick={() => {
                  window.open(fileUrl, '_blank');
                }}
              >
                <Image
                  style={{
                    borderRadius: 10,
                  }}
                  src={fileUrl}
                  alt='image'
                />
              </Box>
            )}
            {message && <QuillContentDisplay content={message} height='auto' />}
          </Box>
          {(order === MessageOrder.Last || order === MessageOrder.Only) && (
            <Typography maxWidth={0.8} px={0.5} variant='caption' color='text.secondary'>
              {caption}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChatBubble;
