import { ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { mutate } from 'swr';
import { api, ApiKey } from 'api';
import Icon from 'components/Icon';
import { Label } from 'components/index';
import { ticketStatusConfig } from 'config/ticket-status.config';
import { TicketStatus } from 'enums';
import { useFormProvider, useMenu, useServerError, useToast } from 'hooks';
import { StatusFormFields, StatusFormFieldsNames } from 'pages/activity-page/status/types';
import { defaultStatusFormValues, statusFormSchema } from 'pages/activity-page/status/utils';
import { menuMinWidth } from 'theme/utils';

const StatusForm = ({ defaultValues, id }: { id: string; defaultValues?: TicketStatus }) => {
  const toast = useToast();
  const serverError = useServerError();

  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();

  const formMethods = useFormProvider<StatusFormFields>({
    reValidateMode: 'onChange',
    schema: statusFormSchema,
    defaultValues: {
      [StatusFormFieldsNames.Status]:
        defaultValues || defaultStatusFormValues[StatusFormFieldsNames.Status],
    },
  });

  const handleOnSubmit = async (formData: StatusFormFields) => {
    try {
      await api.patch(`${ApiKey.Chat}/${id}`, {
        ticketStatus: formData[StatusFormFieldsNames.Status],
      });
      await mutate(ApiKey.Chat);
      await mutate(`${ApiKey.Chat}/${ApiKey.Ticket}`);
      toast({
        variant: 'success',
        message: 'Status updated successfully',
      });
    } catch (error) {
      serverError(error);
    }
  };

  return (
    <Stack
      sx={{
        cursor: 'default',
      }}
      onClick={(e) => e.stopPropagation()}
      width={1}
      height={1}
    >
      <FormProvider {...formMethods}>
        <Stack alignItems='flex-start' justifyContent='center' height={1}>
          <Label
            startIcon={ticketStatusConfig[formMethods.watch(StatusFormFieldsNames.Status)].icon}
            onClick={openMenu}
            color={ticketStatusConfig[formMethods.watch(StatusFormFieldsNames.Status)].color}
            endIcon='arrow_drop_down'
          >
            {ticketStatusConfig[formMethods.watch(StatusFormFieldsNames.Status)].name}
          </Label>
        </Stack>

        <Menu
          id='demo-positioned-menu'
          aria-labelledby='demo-positioned-button'
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: { sx: { minWidth: menuMinWidth } },
          }}
        >
          {Object.values(ticketStatusConfig).map((item) => (
            <MenuItem
              selected={formMethods.watch(StatusFormFieldsNames.Status) === item.status}
              key={item.name}
              onClick={() => {
                formMethods.setValue(StatusFormFieldsNames.Status, item.status);
                formMethods.handleSubmit(handleOnSubmit)();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <Icon icon={item.icon} fontSize='small' />
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </FormProvider>
    </Stack>
  );
};

export default StatusForm;
