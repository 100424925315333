import { IconButton, InputAdornment } from '@mui/material';
import Icon from 'components/Icon';

interface ShowPasswordAndormentProps {
  passwordVisible: boolean;
  showPassword: () => void;
  hidePassword: () => void;
}
const ShowPasswordAdornment = (props: ShowPasswordAndormentProps) => {
  const { passwordVisible, showPassword, hidePassword } = props;

  return (
    <InputAdornment position='end'>
      <IconButton
        onMouseDown={(event) => {
          event.preventDefault();
          showPassword();
        }}
        onMouseLeave={(event) => {
          event.preventDefault();
          hidePassword();
        }}
        onMouseUp={(event) => {
          event.preventDefault();
          hidePassword();
        }}
        onTouchStart={(event) => {
          event.preventDefault();
          showPassword();
        }}
        onTouchEnd={(event) => {
          event.preventDefault();
          hidePassword();
        }}
      >
        <Icon icon={passwordVisible ? 'visibility' : 'visibility_off'} fontSize='small' />
      </IconButton>
    </InputAdornment>
  );
};

export default ShowPasswordAdornment;
