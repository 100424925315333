import { array, bool, object, ObjectSchema, string } from 'yup';
import messages from 'messages';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  MultiChoiceQuestionStepFormFields,
  MultiChoiceQuestionStepFormFieldsNames,
  MultiChoiceQuestionStepFormFieldsOptionsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import {
  editorValidation,
  emptyEditorValue,
  maxTelegramButtonLength,
  variableValidation,
} from 'utils';

export const multiChoiceQuestionStepFormSchema: ObjectSchema<MultiChoiceQuestionStepFormFields> =
  object({
    [MultiChoiceQuestionStepFormFieldsNames.NodeId]: string().required('Node is required'),
    [MultiChoiceQuestionStepFormFieldsNames.Name]: string().required('Name is required'),
    [MultiChoiceQuestionStepFormFieldsNames.Input]: editorValidation,
    [MultiChoiceQuestionStepFormFieldsNames.Variable]: variableValidation,
    [MultiChoiceQuestionStepFormFieldsNames.Answers]: array(
      object({
        [MultiChoiceQuestionStepFormFieldsOptionsNames.Option]: string()
          .max(
            maxTelegramButtonLength,
            `Option should be less than ${maxTelegramButtonLength} characters`,
          )
          .required('Option is required'),
        [MultiChoiceQuestionStepFormFieldsOptionsNames.NodeId]:
          string().required('Node is required'),

        [MultiChoiceQuestionStepFormFieldsOptionsNames.Name]: string().required('Name is required'),
        [MultiChoiceQuestionStepFormFieldsOptionsNames.IsCorrect]: bool().required('Is correct?'),
      }),
    )
      .required()
      .test('is-correct-check', 'At least one answer must be marked as correct', (answers) =>
        answers.some((answer) => answer.isCorrect),
      ),
    [MultiChoiceQuestionStepFormFieldsNames.RemoveAnswerOptions]: bool(),
    [MultiChoiceQuestionStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
  });

export const defaultMultiChoiceQuestionStepFormValues: MultiChoiceQuestionStepFormFields = {
  [MultiChoiceQuestionStepFormFieldsNames.NodeId]: '',
  [MultiChoiceQuestionStepFormFieldsNames.Name]: 'Quiz Multiple Choice Question',
  [MultiChoiceQuestionStepFormFieldsNames.Input]: emptyEditorValue,
  [MultiChoiceQuestionStepFormFieldsNames.Answers]: [],
  [MultiChoiceQuestionStepFormFieldsNames.RemoveAnswerOptions]: false,
  [MultiChoiceQuestionStepFormFieldsNames.Labels]: [],
};

export const MultiChoiceQuestionFormFieldsConfig: Record<
  MultiChoiceQuestionStepFormFieldsNames,
  FormFieldProps
> = {
  [MultiChoiceQuestionStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: MultiChoiceQuestionStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [MultiChoiceQuestionStepFormFieldsNames.Input]: {
    placeholder: '* Question',
    name: MultiChoiceQuestionStepFormFieldsNames.Input,
    type: 'textarea',
  },
  [MultiChoiceQuestionStepFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: MultiChoiceQuestionStepFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [MultiChoiceQuestionStepFormFieldsNames.Answers]: {
    label: 'Answers',
    placeholder: 'Add Answer',
    name: MultiChoiceQuestionStepFormFieldsNames.Answers,
    autoComplete: 'off',
    type: 'text',
  },
  [MultiChoiceQuestionStepFormFieldsNames.NodeId]: {
    name: MultiChoiceQuestionStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [MultiChoiceQuestionStepFormFieldsNames.RemoveAnswerOptions]: {
    label: messages.builder.removeAnswerOptions,
    name: MultiChoiceQuestionStepFormFieldsNames.RemoveAnswerOptions,
    type: 'checkbox',
  },
  [MultiChoiceQuestionStepFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const MultiChoiceQuestionFormMultiChoiceFieldsConfig: Record<
  MultiChoiceQuestionStepFormFieldsOptionsNames,
  FormFieldProps
> = {
  [MultiChoiceQuestionStepFormFieldsOptionsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: MultiChoiceQuestionStepFormFieldsOptionsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [MultiChoiceQuestionStepFormFieldsOptionsNames.Option]: {
    label: 'Answer',
    name: MultiChoiceQuestionStepFormFieldsOptionsNames.Option,
    autoComplete: 'off',
    type: 'text',
  },
  [MultiChoiceQuestionStepFormFieldsOptionsNames.NodeId]: {
    name: MultiChoiceQuestionStepFormFieldsOptionsNames.NodeId,
    type: 'hidden',
  },
  [MultiChoiceQuestionStepFormFieldsOptionsNames.IsCorrect]: {
    name: MultiChoiceQuestionStepFormFieldsOptionsNames.IsCorrect,
    type: 'checkbox',
  },
};
