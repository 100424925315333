import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { useServerError, useToast } from 'hooks';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { Command, Project } from 'types';

export const useDeleteCommand = (command: Command | undefined) => {
  const toast = useToast();
  const toastServerError = useServerError();

  const { id } = useParams();
  const { setSelectedCommand, selectedCommand } = useBuilderStore((state) => ({
    setSelectedCommand: state.setSelectedCommand,
    selectedCommand: state.selectedCommand,
  }));

  const { data } = useSWR<Project>(`${ApiKey.Project}/${id}`);
  const commandsList = data?.bot?.draft.commands;

  const startCommand = commandsList?.find((c) => c.name === 'start');

  const navigate = useNavigate();

  const {
    value: deletingCommand,
    setTrue: startDeletingCommand,
    setFalse: stopDeletingCommand,
  } = useBoolean(false);
  const deleteCommand = async () => {
    startDeletingCommand();
    try {
      await api.delete(`${ApiKey.Project}/${id}/${ApiKey.Bot}/${ApiKey.Command}/${command?.id}`);
      await mutate<Project>(`${ApiKey.Project}/${id}`);
      if (command?.id === selectedCommand?.id) {
        setSelectedCommand(startCommand || null);
      }
      stopDeletingCommand();
      toast({ message: `${command?.name} command deleted successfully!`, variant: 'info' });
      navigate('./../');
    } catch (error) {
      stopDeletingCommand();
      toastServerError(error);
    }
  };
  return { deleteCommand, deletingCommand };
};
