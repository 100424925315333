import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ImagePreview } from 'components/index';
import QuillContentDisplay from 'components/QuillContentDisplay';

import { PrivateChatBulkMessage } from 'types';

function MessageCell({ value, row }: GridRenderCellParams<PrivateChatBulkMessage>) {
  return (
    <Stack direction='row' height={1} alignItems='center' gap={2}>
      <QuillContentDisplay content={value} />
      {row.fileUrl && <ImagePreview size={38} imageUrl={row.fileUrl} />}
    </Stack>
  );
}

export default MessageCell;
