import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import useUndoRedo, {
  UseUndoRedoType,
} from 'pages/app-pages/chatbot-builder-page/hooks/useUndoRedo';

const UndoRedoContext = createContext<UseUndoRedoType | undefined>(undefined); // Use UseUndoRedo type here

export const useUndoRedoContext = (): UseUndoRedoType => {
  const context = useContext(UndoRedoContext);
  if (!context) {
    throw new Error('useUndoRedoContext must be used within an UndoRedoProvider');
  }
  return context;
};

interface UndoRedoProviderProps {
  children: ReactNode;
}

export const UndoRedoProvider = ({ children }: UndoRedoProviderProps): ReactElement => {
  const undoRedo = useUndoRedo(); // Use your existing useUndoRedo hook

  return <UndoRedoContext.Provider value={undoRedo}>{children}</UndoRedoContext.Provider>;
};
