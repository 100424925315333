import { Divider, Grid, Stack } from '@mui/material';
import { times } from 'lodash';
import { useAuth, useGetOrganizationActiveUser } from 'hooks';
import SectionWrapper from 'pages/manage-account-page/SectionWrapper';
import ActiveUserItem from 'pages/organization-page/ActiveUserItem';
import UserItemSkeleton from 'pages/organization-page/UserItemSkeleton';

const ActiveUsers = () => {
  const { activeUsers, isLoading: loadingActiveUsers } = useGetOrganizationActiveUser();
  const { user } = useAuth();

  return (
    <Grid item xs={12}>
      <SectionWrapper disablePadding title='Active Members'>
        <Stack divider={<Divider />}>
          {loadingActiveUsers && times(3, (i) => <UserItemSkeleton key={i} />)}
          {(() => {
            // Separate the 'me' item from others
            const meItem = activeUsers?.find((activeUser) => user?.email === activeUser.email);
            const otherItems = activeUsers?.filter(
              (activeUser) => user?.email !== activeUser.email,
            );

            // Sort the non-me items by date
            const sortedItems = otherItems?.sort(
              (a, b) =>
                new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime(),
            );

            // Combine the 'me' item with the sorted items
            const finalItems = meItem ? [meItem, ...sortedItems] : sortedItems;

            return finalItems?.map((activeUser) => (
              <ActiveUserItem
                isMe={user?.email === activeUser.email}
                key={activeUser.id}
                {...activeUser}
              />
            ));
          })()}
        </Stack>
      </SectionWrapper>
    </Grid>
  );
};

export default ActiveUsers;
