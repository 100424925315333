import { IconButton, IconButtonProps } from '@mui/material';
import { Icon } from 'components/index';
import { AppTypes } from 'enums';
import { useMenu } from 'hooks';
import AppDropdownMenu from 'pages/project-manager-page/app-card/AppDropdownMenu';
import { Project } from 'types';

interface BotMenuWrapperProps extends IconButtonProps {
  project: Project;
  icon: string;
  appType: AppTypes;
}

const AppDropdownWrapper = ({ project, appType, icon, ...rest }: BotMenuWrapperProps) => {
  const { openMenu, isMenuOpen, ...restMenu } = useMenu();

  return (
    <>
      <IconButton onClick={openMenu} color='primary' aria-label='settings' {...rest}>
        <Icon icon={icon} fontSize='small' />
      </IconButton>
      {isMenuOpen && (
        <AppDropdownMenu
          openMenu={openMenu}
          isMenuOpen={isMenuOpen}
          project={project}
          appType={appType}
          {...restMenu}
        />
      )}
    </>
  );
};

export default AppDropdownWrapper;
