import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { size } from 'lodash';
import { ReactNode } from 'react';
import { FailedSentChats } from 'types';

interface FailedChatsTooltipProps {
  failedSentChats?: FailedSentChats[];
  children?: ReactNode;
  hideTooltip?: boolean;
}
function FailedChatsTooltip({ failedSentChats, children, hideTooltip }: FailedChatsTooltipProps) {
  const tooltipContent =
    failedSentChats && failedSentChats.length > 0 ? (
      <Stack gap={1}>
        <Typography variant='body2' fontWeight='bolder'>
          Failed to deliver {size(failedSentChats)} messages
          {size(failedSentChats) > 5 && ' (Showing last 5)'}
        </Typography>
        <Stack>
          {failedSentChats.slice(Math.max(failedSentChats.length - 5, 0)).map((chat) => (
            <Typography variant='caption' key={chat.chat.id} sx={{ margin: '4px 0' }}>
              {chat.chat.firstName} {chat.chat.lastName} (@{chat.chat.userName}): {chat.reason}
            </Typography>
          ))}
        </Stack>
      </Stack>
    ) : (
      'All messages delivered successfully.'
    );

  return (
    <Tooltip title={hideTooltip ? '' : tooltipContent} arrow placement='top'>
      <Box width={1} component='span' style={{ cursor: hideTooltip ? 'default' : 'help' }}>
        {children}
      </Box>
    </Tooltip>
  );
}

export default FailedChatsTooltip;
