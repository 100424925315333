import { object, ObjectSchema, string } from 'yup';

export enum AppUpdateDescriptionFieldsNames {
  Description = 'description',
}

export interface AppUpdateDescriptionFields {
  [AppUpdateDescriptionFieldsNames.Description]?: string;
}
export const appUpdateSchema: ObjectSchema<AppUpdateDescriptionFields> = object({
  [AppUpdateDescriptionFieldsNames.Description]: string().optional(),
});
