import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormArrayFields,
  FormSection,
  FormSwitch,
  FormTextFieldInlineEdit,
  List,
  ListItem,
} from 'components/index';

import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import messages from 'messages';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { YesNoStepFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/types';
import {
  YesNoFormFieldsConfig,
  yesNoPlateStepFormValues,
  yesNoStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/utils';
import YesNoFormArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/YesNoFormArrayField';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface YesNoStepProps {
  id: string;
  defaultValues?: Partial<YesNoStepFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}

const YesNoMenu = ({ defaultValues, mentions, commands, id }: YesNoStepProps) => {
  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...yesNoPlateStepFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    menu: isEmpty(defaultValues?.menu)
      ? [
          { option: 'Yes', nodeId: uuid() },
          { option: 'No', nodeId: uuid() },
        ]
      : defaultValues?.menu,
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<YesNoStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<YesNoStepFormFields>({
    schema: yesNoStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const { reset, formState } = formMethods;
  const processNewLabels = useProcessNewLabels();

  const handleOnSubmit = async (formData: YesNoStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { name, labels, variable, menu, input, removeAnswerOptions } = YesNoFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit
          tooltipPlacement='left'
          tooltip='Click to edit the name of the step'
          {...name}
          variant='standard'
          size='small'
        />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />

        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />

          <FormVariableField {...variable} />
        </Stack>
        <FormSection title={menu.label}>
          <FormArrayFields
            noAppend
            name={menu.name}
            FormArrayFieldComponent={YesNoFormArrayField}
          />
        </FormSection>
        <List divider label='Aditional Options'>
          <ListItem>
            <FormSwitch
              {...removeAnswerOptions}
              infoIcon={{
                tooltip: messages.builder.removeAnswerOptionsTooltip,
                icon: 'info',
              }}
            />
          </ListItem>
        </List>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={reset} />
    </StepFormWrapper>
  );
};

export default memo(YesNoMenu);
