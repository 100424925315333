import { ReactNode } from 'react';
import {
  ChatFilterDirection,
  ChatFilterMatching,
  ChatGroupMessageStatus,
  GroupMessageSentStatus,
} from 'enums';
import { ChatFilterFieldsNames } from 'pages/app-pages/private-bulk-message-page/message-modal/types';
import { BasicColors } from 'theme';
import { ChatFilter, UserLabel } from 'types';

interface GroupMessageSentStatusProps {
  status: GroupMessageSentStatus;
  icon: string;
  label: string;
  color: BasicColors;
}

interface ChatGroupMessageStatusProps {
  status: ChatGroupMessageStatus;
  icon: string;
  label: string;
  color: BasicColors;
  index: number;
}

export const groupMessageSentStatusConfig: Record<
  GroupMessageSentStatus,
  GroupMessageSentStatusProps
> = {
  [GroupMessageSentStatus.PreSent]: {
    status: GroupMessageSentStatus.PreSent,
    icon: 'schedule',
    label: 'Pre-sent',
    color: 'info',
  },
  [GroupMessageSentStatus.Success]: {
    status: GroupMessageSentStatus.Success,
    icon: 'check_circle',
    label: 'Sent',
    color: 'success',
  },
  [GroupMessageSentStatus.Error]: {
    status: GroupMessageSentStatus.Error,
    icon: 'warning',
    label: 'Error',
    color: 'error',
  },
};

export const chatGroupMessageStatusConfig: Record<
  ChatGroupMessageStatus,
  ChatGroupMessageStatusProps
> = {
  [ChatGroupMessageStatus.Draft]: {
    status: ChatGroupMessageStatus.Draft,
    icon: 'draft',
    label: 'Draft',
    color: 'warning',
    index: 0,
  },
  [ChatGroupMessageStatus.Published]: {
    status: ChatGroupMessageStatus.Published,
    icon: 'send',
    label: 'Published',
    color: 'success',
    index: 1,
  },
};

export const generateMessage = (
  future: boolean,
  filter?: ChatFilter[],
  allLabels: UserLabel[] = [], // An array of all possible labels
  excludeArchived: boolean = false, // New boolean prop to exclude archived users
): ReactNode => {
  const verb = future ? 'will be sent to' : 'sent to';
  const archivedText = excludeArchived ? (
    <span>
      , excluding <b>archived</b> users
    </span>
  ) : (
    ''
  );

  if (!filter?.[0]) {
    return (
      <span>
        The message {verb} <b>all users</b>
        {archivedText} without specific label criteria.
      </span>
    );
  }

  const direction =
    filter[0][ChatFilterFieldsNames.Direction] === ChatFilterDirection.Include
      ? 'including'
      : 'excluding';
  const matching =
    filter[0][ChatFilterFieldsNames.Matching] === ChatFilterMatching.All ? 'all' : 'any';

  // Find the label names based on the IDs provided in the filter

  const labels = filter[0].labelIds
    .map((labelId) => allLabels.find((label) => label.id === labelId)?.name)
    .filter((labelName) => labelName !== undefined) // Filter out any undefined entries
    .join(', ');

  // Handle the case where no labels are specified
  if (filter[0].labelIds.length === 0) {
    return (
      <span>
        The message {verb} <b>all users</b>
        {archivedText} without specific label criteria.
      </span>
    );
  }

  return (
    <span>
      The message {verb} users <b>{direction}</b> those with <b>{matching}</b> of the following
      labels: <b>&quot;{labels}&quot;</b>
      {archivedText}
    </span>
  );
};
