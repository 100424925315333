import { alpha, ButtonBase, Stack, StackProps, Tooltip } from '@mui/material';
import { Icon } from 'components/index';
import { BasicColors, TooltipWrapperProps } from 'theme';
import { isBasicColorValid } from 'theme/utils';

export interface AppBoxProps extends TooltipWrapperProps, StackProps {
  icon: string;
  color: BasicColors;
  isActive?: boolean;
  isPublic?: boolean;
  borderStyle?: 'solid' | 'dashed' | 'dotted';
}
const AppBox = ({
  icon,
  color,
  borderStyle = 'solid',
  sx,
  tooltip,
  isActive,
  isPublic,
  ...rest
}: AppBoxProps) => (
  <Tooltip title={tooltip} placement='top' arrow>
    <Stack
      sx={{
        border: 1,
        flexDirection: 'row',
        borderStyle,
        borderRadius: 1,
        borderColor: (theme) =>
          alpha(isBasicColorValid(color) ? theme.palette[color].light : color, 0.18),
        p: 1,
        height: 1,
        cursor: 'pointer',
        pointerEvents: 'auto',
        position: 'relative',
        backgroundColor: (theme) =>
          alpha(isBasicColorValid(color) ? theme.palette[color].light : color, 0.18),
        transition: (theme) =>
          theme.transitions.create(['background-color', 'box-shadow'], {
            duration: theme.transitions.duration.shortest,
          }),
        '&:after': {
          content: isPublic ? '""' : 'none',
          position: 'absolute',
          borderRadius: '50%',
          width: 8,
          height: 8,
          top: -2,
          right: -2,
          backgroundColor: isActive ? 'success.main' : 'error.main',
        },
        '&:hover': {
          boxShadow: (theme) => theme.shadows[4],
        },
        ...sx,
      }}
      component={ButtonBase}
      {...rest}
    >
      <Icon icon={icon} fontSize='small' color={color} />
    </Stack>
  </Tooltip>
);

export default AppBox;
