import { mixed, object, ObjectSchema, string } from 'yup';
import { AppTypes, RoutePath } from 'enums';
import { FormFieldProps } from 'types';
import { AppProps, CreateProjectFields, CreateProjectFieldsNames } from './types';

export const createProjectSchema: ObjectSchema<CreateProjectFields> = object({
  [CreateProjectFieldsNames.Name]: string().required('Bot Name is required'),
  [CreateProjectFieldsNames.Description]: string().optional(),
  [CreateProjectFieldsNames.SelectedApp]: mixed<AppTypes>()
    .oneOf(Object.values(AppTypes))
    .required(),
});

export const defaultCreateProjectFormValues: CreateProjectFields = {
  [CreateProjectFieldsNames.Name]: '',
  [CreateProjectFieldsNames.Description]: '',
  [CreateProjectFieldsNames.SelectedApp]: AppTypes.Chatbot,
};

export const createProjectFieldsConfig: Record<CreateProjectFieldsNames, FormFieldProps> = {
  [CreateProjectFieldsNames.Name]: {
    label: '* Name',
    placeholder: 'Project Name',
    name: CreateProjectFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [CreateProjectFieldsNames.Description]: {
    label: 'Description',
    placeholder: 'Project Description',
    name: CreateProjectFieldsNames.Description,
    autoComplete: 'off',
    type: 'text',
  },
  [CreateProjectFieldsNames.SelectedApp]: {
    label: 'App',
    placeholder: 'Select App',
    name: CreateProjectFieldsNames.SelectedApp,
    type: 'select',
  },
};

export const appConfig: Record<AppTypes, AppProps> = {
  [AppTypes.Chatbot]: {
    type: AppTypes.Chatbot,
    appUrl: RoutePath.ChatbotBuilder,
    label: 'Chatbot Builder',
    description:
      'An intuitive, user-friendly tool for creating interactive Telegram chatbot effortlessly.',
    cta: 'Chatbot Builder',
    icon: 'account_tree',
    color: 'chatbot',
    showInCreate: true,
  },
  [AppTypes.Messenger]: {
    type: AppTypes.Messenger,
    appUrl: RoutePath.Messenger,
    label: 'Groups & Channels Broadcaster',
    description:
      'Effortlessly schedule and distribute messages across multiple Telegram groups and channels at once.',
    cta: 'Groups Broadcaster',
    icon: 'campaign',
    color: 'messenger',
    showInCreate: true,
  },
  [AppTypes.PrivateChatBulkMessages]: {
    type: AppTypes.PrivateChatBulkMessages,
    appUrl: RoutePath.PrivateChatBulkMessages,
    label: 'Bulk Messenger',
    description:
      'Send bulk messages to contacts that have been interacted with the chatbot in the past.',
    cta: 'Bulk Messenger',
    icon: 'cell_tower',
    color: 'bulk',
    showInCreate: false,
  },
};
