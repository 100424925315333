import { useParams } from 'react-router-dom';
import { useEdges, useReactFlow } from 'reactflow';
import useSWR from 'swr';
import { ApiKey } from 'api';
import AppLoader from 'components/AppLoader';
import { useDeepCompareMemo, useGetUserLabels } from 'hooks';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import ToolboxLayout from 'pages/app-pages/chatbot-builder-page/toolbox/ToolboxLayout';
import { BaseNodeProps, Project } from 'types';
import { mapLabelsAutocompleteOptions, truncateText } from 'utils';

const NodeEditor = () => {
  const { selectedNodeId, selectedStepType } = useBuilderStore((state) => ({
    selectedNodeId: state.selectedNodeId,
    selectedStepType: state.selectedStepType,
  }));

  const { getNode } = useReactFlow<BaseNodeProps>();
  const edges = useEdges();

  const { id } = useParams();

  const { data: project, isLoading } = useSWR<Project>(`${ApiKey.Project}/${id}`);
  const commandsList = project?.bot?.draft.commands;

  const { labels, isLoading: labelsIsLoading } = useGetUserLabels();

  const commands = useDeepCompareMemo(
    commandsList?.map((command) => ({
      id: command.name,
      value: command.name,
    })) || [],
  );

  const selectedNode = selectedNodeId ? getNode(selectedNodeId) : undefined;
  const selectedNodeLabels = selectedNode?.data.formData?.labels
    ? labels.filter((object) => selectedNode?.data.formData?.labels?.includes(object.id))
    : undefined;

  const selectedNodeWithLabels = {
    ...selectedNode,
    data: {
      ...selectedNode?.data,
      formData: {
        ...selectedNode?.data.formData,
        labels: selectedNodeLabels ? mapLabelsAutocompleteOptions(selectedNodeLabels) : undefined,
      },
    },
  };

  const incomingEdge = edges.find((edge) => edge.target === selectedNode?.id);

  const incomingLabel = incomingEdge?.label
    ? `${truncateText({
        input: incomingEdge.label.toString(),
        length: 6,
      })} ↣`
    : '';

  const StepComponent = selectedStepType && StepsConfig[selectedStepType].stepComponent;

  if (!StepComponent || !selectedNode || isLoading || labelsIsLoading) {
    return <AppLoader />;
  }

  return (
    <ToolboxLayout title={`${incomingLabel} ${selectedNode.data.label}`}>
      <StepComponent
        key={`${selectedNode?.id}${selectedStepType}`}
        id={selectedNode?.id}
        defaultValues={selectedNodeWithLabels?.data.formData}
        mentions={selectedNode?.data.mentions}
        commands={commands}
        defaultName={StepsConfig[selectedStepType].name}
        backButton={selectedNode?.data.backButton}
      />
    </ToolboxLayout>
  );
};

export default NodeEditor;
