import { StepType } from 'enums';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { getCategoryColorByNodeType } from 'pages/app-pages/chatbot-builder-page/nodes/utils';
import { ConversationMessageType } from 'types';
import { MessageOrder, MessagePosition } from './types';

export const avatarSize = 36;
export const baseRadius = 24;
export const flatSide = 0;
export const getBorderRadius = (order: MessageOrder, position: MessagePosition) => {
  switch (order) {
    case MessageOrder.First:
      return position === MessagePosition.Right
        ? {
            borderTopLeftRadius: baseRadius,
            borderTopRightRadius: baseRadius,
            borderBottomLeftRadius: baseRadius,
            borderBottomRightRadius: flatSide,
          }
        : {
            borderTopLeftRadius: baseRadius,
            borderTopRightRadius: baseRadius,
            borderBottomLeftRadius: flatSide,
            borderBottomRightRadius: baseRadius,
          };
    case MessageOrder.Middle:
      return position === MessagePosition.Right
        ? {
            borderTopLeftRadius: baseRadius,
            borderTopRightRadius: flatSide,
            borderBottomLeftRadius: baseRadius,
            borderBottomRightRadius: flatSide,
          }
        : {
            borderTopLeftRadius: flatSide,
            borderTopRightRadius: baseRadius,
            borderBottomLeftRadius: flatSide,
            borderBottomRightRadius: baseRadius,
          };
    case MessageOrder.Last:
      return position === MessagePosition.Right
        ? {
            borderTopLeftRadius: baseRadius,
            borderTopRightRadius: flatSide,
            borderBottomLeftRadius: baseRadius,
            borderBottomRightRadius: baseRadius,
          }
        : {
            borderTopLeftRadius: flatSide,
            borderTopRightRadius: baseRadius,
            borderBottomLeftRadius: baseRadius,
            borderBottomRightRadius: baseRadius,
          };
    case MessageOrder.Only:
    default:
      return {
        borderTopLeftRadius: baseRadius,
        borderTopRightRadius: baseRadius,
        borderBottomLeftRadius: baseRadius,
        borderBottomRightRadius: baseRadius,
      };
  }
};

export const borderRadius = (order: MessageOrder, position: MessagePosition) =>
  getBorderRadius(order, position);

export const bubbleStyle = (order: MessageOrder, position: MessagePosition) => ({
  maxWidth: 0.8,
  px: 2,
  py: 0,
  marginBottom: 0.25,
  backgroundColor: position === MessagePosition.Right ? 'primary.main' : 'grey.100',
  color: position === MessagePosition.Right ? 'common.white' : 'text.primary',
  alignSelf: position === MessagePosition.Right ? 'flex-end' : 'flex-start',
  ...borderRadius(order, position),
});

function isStepType(type: any): type is StepType {
  return Object.values(StepType).includes(type);
}

export const messageConfig = {
  validation: {
    color: 'error',
    icon: 'error',
    tooltip: 'Validation Message',
  },
  command: {
    color: 'primary',
    icon: 'flag',
    tooltip: 'User Command',
  },
  userResponse: {
    color: 'primary',
    icon: 'person',
    tooltip: 'User Response',
  },
  quizAnswer: {
    color: 'messenger',
    icon: 'info',
    tooltip: 'Quiz Answer',
  },
  system: {
    color: 'info',
    icon: 'smart_toy',
    tooltip: 'System Message',
  },
  botOwnerManual: {
    color: 'info',
    icon: 'keyboard',
    tooltip: 'Manual Message',
  },
  botOwnerPrivateBulk: {
    color: 'bulk',
    icon: 'cell_tower',
    tooltip: 'Bulk Message',
  },
};

export function getIconByMessageType(messageType: ConversationMessageType): string {
  if (isStepType(messageType)) {
    const stepConfig = StepsConfig[messageType];
    return stepConfig.icon;
  }
  return messageConfig[messageType]?.icon || messageConfig.userResponse.icon;
}

export function getColorByMessageType(messageType: ConversationMessageType): string {
  if (isStepType(messageType)) {
    return getCategoryColorByNodeType(messageType);
  }
  return messageConfig[messageType]?.color || messageConfig.userResponse.color;
}

export function getTooltipByMessageType(messageType: ConversationMessageType): string {
  if (isStepType(messageType)) {
    const stepConfig = StepsConfig[messageType];
    return stepConfig.name;
  }
  return messageConfig[messageType]?.tooltip || messageConfig.userResponse.tooltip;
}
