import { FormControlLabel } from '@mui/material';
import { cloneDeep, get, set } from 'lodash'; // Import lodash
import React, { useState } from 'react';
import { api } from 'api';
import OnOffSwitch from 'components/form-fields/components/OnOffSwitch';
import { useServerError } from 'hooks';

interface ReusableSwitcherProps<T> {
  data: T;
  setData: (data: T) => void;
  propertyPath: string;
  apiEndpoint: string;
  patchData: any;
  label: string;
  onIconPath?: string;
  offIconPath?: string;
}

const ReusableSwitcher = <T extends object>({
  data,
  setData,
  propertyPath,
  apiEndpoint,
  patchData,
  label,
  onIconPath,
  offIconPath,
}: ReusableSwitcherProps<T>) => {
  const [updating, setUpdating] = useState(false);
  const toastServerError = useServerError();

  const toggleProperty = async () => {
    setUpdating(true);
    // Use lodash to toggle the property safely
    const currentValue = get(data, propertyPath);
    if (typeof currentValue === 'boolean') {
      const newData = cloneDeep(data); // Deep clone the data
      set(newData, propertyPath, !currentValue); // Toggle the property
      setData(newData); // Optimistically update UI

      try {
        const res = await api.patch<T>(apiEndpoint, patchData);
        setData(res.data); // Update with response data
      } catch (error) {
        toastServerError(error);
        setData(data); // Revert to original data on error
      }
    }
    setUpdating(false);
  };

  // Use lodash to safely determine isChecked
  const isChecked = !!get(data, propertyPath);

  return (
    <FormControlLabel
      control={
        <OnOffSwitch
          disabled={updating}
          onIconPath={onIconPath}
          offIconPath={offIconPath}
          color='success'
          checked={isChecked}
          onClick={toggleProperty}
        />
      }
      label={label}
    />
  );
};

export default ReusableSwitcher;
