import {
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import IconWithTooltip from 'components/IconWithTooltip';
import { TooltipWrapperProps } from 'theme';

export interface FormSwitchProps extends Omit<SwitchProps, 'id'> {
  name: string;
  infoIcon?: {
    tooltip: string;
    icon: string;
    tooltipProps?: TooltipWrapperProps;
  };
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}
export const FormSwitch = (props: FormSwitchProps) => {
  const { name, labelPlacement, label, defaultValue, infoIcon, size = 'small', ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ref, ...field }, fieldState: { error } }) => (
        <FormControl>
          <FormControlLabel
            labelPlacement={labelPlacement}
            sx={{ alignSelf: 'flex-start', width: 1 }}
            control={
              <Switch
                size={size}
                inputRef={ref}
                {...field}
                id={name}
                onChange={onChange}
                checked={value}
                {...rest}
              />
            }
            label={
              <Stack width={1} direction='row' gap={1} alignItems='center'>
                <Typography variant='body2'>{label}</Typography>
                {infoIcon && (
                  <IconWithTooltip
                    icon={infoIcon.icon}
                    tooltip={infoIcon.tooltip}
                    tooltipPlacement='top'
                    tooltipProps={{
                      arrow: true,
                      ...infoIcon.tooltipProps,
                      onClick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      },
                    }}
                    iconProps={{ sx: { fontSize: 16 } }}
                  />
                )}
              </Stack>
            }
          />
          <FormCollapseHelperText error={error} />
        </FormControl>
      )}
    />
  );
};

export default memo(FormSwitch);
