import { Box, Collapse, FormHelperText, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormArrayFields,
  FormSection,
  FormSwitch,
  FormTextFieldInlineEdit,
  List,
  ListItem,
} from 'components/index';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import messages from 'messages';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import MultiChoiceAnswersFormArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/MultiChoiceAnswersFormArrayField';
import {
  MultiChoiceQuestionStepFormFields,
  MultiChoiceQuestionStepFormFieldsNames,
  MultiChoiceQuestionStepFormFieldsOptionsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/types';
import {
  defaultMultiChoiceQuestionStepFormValues,
  MultiChoiceQuestionFormFieldsConfig,
  multiChoiceQuestionStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface OptionsQuestionProps {
  id: string;
  defaultValues?: Partial<MultiChoiceQuestionStepFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}
const MultiChoiceQuestion = ({ defaultValues, id, mentions, commands }: OptionsQuestionProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultMultiChoiceQuestionStepFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
    answers: isEmpty(defaultValues?.answers)
      ? [
          { name: 'Answer 1', option: '', isCorrect: true, nodeId: uuid() },
          { name: 'Answer 2', option: '', isCorrect: false, nodeId: uuid() },
          { name: 'Answer 3', option: '', isCorrect: false, nodeId: uuid() },
          { name: 'Answer 4', option: '', isCorrect: false, nodeId: uuid() },
        ]
      : defaultValues?.answers,
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<MultiChoiceQuestionStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<MultiChoiceQuestionStepFormFields>({
    schema: multiChoiceQuestionStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const { name, labels, variable, input, answers, removeAnswerOptions } =
    MultiChoiceQuestionFormFieldsConfig;

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(
    mentions?.filter((mention) => mention.id !== 'score' && mention.id !== 'quiz_summary'),
  );

  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: MultiChoiceQuestionStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  const atLeastOneCorrectAnswer = formMethods
    .watch(MultiChoiceQuestionStepFormFieldsNames.Answers)
    .some((answer) => answer[MultiChoiceQuestionStepFormFieldsOptionsNames.IsCorrect]);

  const answersWatch = formMethods.watch(MultiChoiceQuestionStepFormFieldsNames.Answers);

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />
          <FormVariableField {...variable} />
        </Stack>
        <FormSection title={answers.label}>
          <Collapse
            in={
              !atLeastOneCorrectAnswer &&
              !!formState.errors[MultiChoiceQuestionStepFormFieldsNames.Answers]?.root?.message
            }
          >
            <Box px={2} pb={2}>
              <FormHelperText error>
                {formState.errors[MultiChoiceQuestionStepFormFieldsNames.Answers]?.root?.message}
              </FormHelperText>
            </Box>
          </Collapse>
          <FormArrayFields
            name={answers.name}
            FormArrayFieldComponent={MultiChoiceAnswersFormArrayField}
            addButtonText='Add Another Answer'
            appendValue={{
              name: `Answer ${answersWatch.length + 1}`,
              option: '',
              isCorrect: false,
              nodeId: uuid(),
            }}
          />
          <Box px={2}>
            <FormHelperText>Mark at least one correct answer with a checkmark.</FormHelperText>
          </Box>
        </FormSection>

        <List divider dense label='Aditional Options'>
          <ListItem>
            <FormSwitch
              {...removeAnswerOptions}
              infoIcon={{
                tooltip: messages.builder.removeAnswerOptionsTooltip,
                icon: 'info',
              }}
            />
          </ListItem>
        </List>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formMethods.formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(MultiChoiceQuestion);
