import { AutoCompleteLabel } from 'types';

export enum RandomMenuStepFormFieldsNames {
  Name = 'name',
  Menu = 'menu',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface RandomMenuStepFormFields {
  [RandomMenuStepFormFieldsNames.Name]: string;
  [RandomMenuStepFormFieldsNames.NodeId]: string;
  [RandomMenuStepFormFieldsNames.Menu]: RandomMenuStepFormFieldsMenu[];
  [RandomMenuStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export enum RandomMenuStepFormFieldsMenuNames {
  Name = 'name',
  Option = 'option',
  NodeId = 'nodeId',
}

export interface RandomMenuStepFormFieldsMenu {
  [RandomMenuStepFormFieldsMenuNames.Name]: string;
  [RandomMenuStepFormFieldsMenuNames.Option]: number;
  [RandomMenuStepFormFieldsMenuNames.NodeId]: string;
}
