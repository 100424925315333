import { Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Breadcrumbs, IconButton } from '@common-components';
import { RoutePath } from 'enums';
import { useAppBarHeight } from 'hooks';
import { mainBackground } from 'theme/utils';
import { Project } from 'types';
import { truncateMultiLineText } from 'utils';

interface HeaderProps {
  name: Project['name'];
  description: Project['description'];
  onClick: () => void;
  isLoading: boolean;
}
const Header = ({ name, description, onClick, isLoading }: HeaderProps) => {
  const appBarHeight = useAppBarHeight();
  //  const { isSmallMobile } = useIsMobile();

  const [expandedDescription, setExpandedDescription] = useState(false);

  return (
    <Stack
      position={{ xs: 'static', md: 'sticky' }}
      top={appBarHeight}
      justifyContent='flex-end'
      bgcolor={mainBackground}
      zIndex={1}
      pt={{ xs: 4, sm: 7 }}
    >
      <Breadcrumbs
        breadcrumbs={[
          { name: 'home', url: `/${RoutePath.Dashboard}` },
          { name: 'projects', url: `/${RoutePath.Projects}` },
          { name: 'project manager' },
        ]}
      />
      <Stack direction='row' alignItems='flex-end' pb={6} pt={2} justifyContent='space-between'>
        <Stack flex={1} gap={1} direction='row' alignItems='flex-start'>
          <Stack
            direction='row'
            flex={1}
            alignItems='center'
            gap={2}
            justifyContent='space-between'
          >
            <Stack flex={1}>
              {isLoading ? (
                <Skeleton variant='text' sx={{ fontSize: '1.2rem' }} width='33%' />
              ) : (
                <Typography variant='h4' component='h1'>
                  {name}
                </Typography>
              )}
              {isLoading ? (
                <Skeleton variant='text' sx={{ fontSize: '1.2rem' }} width='66%' />
              ) : (
                <Typography
                  title={description}
                  onClick={() => {
                    setExpandedDescription((prevState) => !prevState);
                  }}
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    cursor: expandedDescription ? 'n-resize' : 's-resize',
                    ...(!expandedDescription && truncateMultiLineText(3)),
                  }}
                >
                  {description}
                </Typography>
              )}
            </Stack>
            <IconButton
              edge='end'
              icon='edit'
              tooltip='Rename project'
              tooltipPlacement='left'
              tooltipProps={{
                arrow: true,
              }}
              onClick={onClick}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
