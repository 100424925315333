import { Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { ApiKey } from 'api';
import Icon from 'components/Icon';
import { BasicModal } from 'components/index';
import { useIsMobile } from 'hooks';
import { useGetChatGroupMessages } from 'hooks/api/messenger/useGetChatGroupMessages';
import OnboardingStep from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/OnboardingStep';

import { MessengerOnboardingSteps } from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/types';
import { onboardingStepsConfig } from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/utils';
import { Project } from 'types';

const MessengerOnboarding = () => {
  const { id } = useParams();
  const { isMobile } = useIsMobile();
  const [activeStep, setActiveStep] = useState(MessengerOnboardingSteps.Token);
  const { data: project, isLoading } = useSWR<Project>(`${ApiKey.Project}/${id}`);
  const { chatGroupMessages, isLoading: isMessagesLoading } = useGetChatGroupMessages({
    projectId: id || '',
  });
  useEffect(() => {
    if (isLoading || isMessagesLoading) {
      return;
    }
    if (!project?.token) {
      setActiveStep(MessengerOnboardingSteps.Token);
    } else if (!project?.chatGroups?.length) {
      setActiveStep(MessengerOnboardingSteps.Groups);
    } else {
      setActiveStep(MessengerOnboardingSteps.Messages);
    }
  }, [
    chatGroupMessages?.length,
    isLoading,
    isMessagesLoading,
    project?.chatGroups?.length,
    project?.token,
  ]);

  const stepProps = onboardingStepsConfig(id || '')[activeStep];

  return (
    <BasicModal
      title='Start Broadcasting in 3 Simple Steps'
      label='create-new-project-dialog'
      maxWidth='lg'
      open
      cancelButton={isMobile}
    >
      <Stack
        alignItems={isMobile ? 'center' : 'stretch'}
        pt={2}
        pb={4}
        direction={isMobile ? 'column' : 'row'}
        divider={
          <Divider
            sx={{
              '& .MuiDivider-wrapper': {
                display: 'flex',
              },
            }}
            orientation={isMobile ? 'horizontal' : 'vertical'}
            flexItem
          >
            <Icon
              icon={isMobile ? 'arrow_circle_down' : 'arrow_circle_right'}
              fontSize='large'
              color='primary.main'
            />
          </Divider>
        }
        gap={isMobile ? 4 : 2}
      >
        {Object.values(MessengerOnboardingSteps).map((step) => (
          <OnboardingStep
            key={step}
            step={step}
            isActive={step === activeStep}
            id={id || ''}
            chatGroups={project?.chatGroups || []}
            done={stepProps.stepNumber > onboardingStepsConfig(id || '')[step].stepNumber}
          />
        ))}
      </Stack>
    </BasicModal>
  );
};

export default MessengerOnboarding;
