import {
  AppBar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppIcon, AppLogo, CustomerSupportIconButton } from '@common-components';
import Icon from 'components/Icon';
import { ExtendedUserRole, RoutePath } from 'enums';
import { useFeatureAccess, useIsMobile, useMenu } from 'hooks';
import HeaderMenuItem from './HeaderMenuItem';
import NotificationsMenu from './NotificationsMenu';
import UserAvatar from './UserAvatar';
import { NavigationTabs, navigationTabs } from './utils';

function AppHeader() {
  const { isMobile, isSmallMobile } = useIsMobile();
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();

  const navigate = useNavigate();
  const location = useLocation();

  const allowSeeProjects = useFeatureAccess({
    minRole: ExtendedUserRole.Editor,
  });

  const getLabelForCurrentRoute = () => {
    if (location.pathname.startsWith(`/${RoutePath.Dashboard}`)) {
      return navigationTabs[NavigationTabs.Dashboard].label;
    }
    if (location.pathname.startsWith(`/${RoutePath.Projects}`)) {
      return navigationTabs[NavigationTabs.Projects].label;
    }
    if (location.pathname.startsWith(`/${RoutePath.Activity}`)) {
      return navigationTabs[NavigationTabs.Activity].label;
    }
    if (location.pathname.startsWith(`/${RoutePath.ProjectManager}`)) {
      return navigationTabs[NavigationTabs.ProjectManager].label;
    }
    if (location.pathname.startsWith(`/${RoutePath.ManageAccount}`)) {
      return navigationTabs[NavigationTabs.ManageAccount].label;
    }
    // Default label for any other path
    return navigationTabs[NavigationTabs.Home].label;
  };

  const currentLabel = getLabelForCurrentRoute();

  return (
    <AppBar
      position='fixed'
      color='default'
      elevation={0}
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        backgroundColor: 'background.default',
      }}
    >
      <Toolbar sx={{ gap: 2 }}>
        <Stack gap={2} flex={1} alignItems='center' direction='row'>
          <Box component={Link} to='/'>
            {isMobile ? <AppIcon /> : <AppLogo />}
          </Box>
          <CustomerSupportIconButton color='default' />
        </Stack>
        {isSmallMobile ? (
          <>
            <HeaderMenuItem
              endIcon={<Icon fontSize='large' icon={isMenuOpen ? 'expand_less' : 'expand_more'} />}
              label={currentLabel}
              onClick={openMenu}
            />
            <Menu
              id='main-menu'
              aria-labelledby='main-menu-button'
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              slotProps={{
                paper: { sx: { minWidth: `calc(100% - 32px)` } },
              }}
            >
              <MenuItem
                selected={currentLabel === navigationTabs[NavigationTabs.Dashboard].label}
                onClick={() => {
                  closeMenu();
                  navigate(RoutePath.Dashboard);
                }}
              >
                <ListItemIcon>
                  <Icon icon={navigationTabs[NavigationTabs.Dashboard].icon} />
                </ListItemIcon>
                <ListItemText>{navigationTabs[NavigationTabs.Dashboard].label}</ListItemText>
              </MenuItem>
              {allowSeeProjects && (
                <MenuItem
                  selected={currentLabel === navigationTabs[NavigationTabs.Projects].label}
                  onClick={() => {
                    closeMenu();
                    navigate(RoutePath.Projects);
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={navigationTabs[NavigationTabs.Projects].icon} />
                  </ListItemIcon>
                  <ListItemText>{navigationTabs[NavigationTabs.Projects].label}</ListItemText>
                </MenuItem>
              )}

              <MenuItem
                selected={currentLabel === navigationTabs[NavigationTabs.Activity].label}
                onClick={() => {
                  closeMenu();
                  navigate(RoutePath.Activity);
                }}
              >
                <ListItemIcon>
                  <Icon icon={navigationTabs[NavigationTabs.Activity].icon} />
                </ListItemIcon>
                <ListItemText>{navigationTabs[NavigationTabs.Activity].label}</ListItemText>
              </MenuItem>
              <MenuItem
                selected={currentLabel === navigationTabs[NavigationTabs.Tickets].label}
                onClick={() => {
                  closeMenu();
                  navigate(RoutePath.Tickets);
                }}
              >
                <ListItemIcon>
                  <Icon icon={navigationTabs[NavigationTabs.Tickets].icon} />
                </ListItemIcon>
                <ListItemText>{navigationTabs[NavigationTabs.Tickets].label}</ListItemText>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Stack gap={1} direction='row'>
            <HeaderMenuItem
              label={navigationTabs[NavigationTabs.Dashboard].label}
              to={RoutePath.Dashboard}
            />
            {allowSeeProjects && (
              <HeaderMenuItem
                label={navigationTabs[NavigationTabs.Projects].label}
                to={RoutePath.Projects}
              />
            )}
            <HeaderMenuItem
              label={navigationTabs[NavigationTabs.Activity].label}
              to={RoutePath.Activity}
            />
            <HeaderMenuItem
              label={navigationTabs[NavigationTabs.Tickets].label}
              to={RoutePath.Tickets}
            />
          </Stack>
        )}

        <Stack gap={1} flex={1} direction='row' alignItems='center' justifyContent='flex-end'>
          <NotificationsMenu />
          <UserAvatar />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
