import { Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';

function TitleCell({ formattedValue }: GridRenderCellParams<GridTreeNodeWithRender, string>) {
  return (
    <Typography noWrap variant='subtitle2' title={formattedValue}>
      {formattedValue}
    </Typography>
  );
}

export default TitleCell;
