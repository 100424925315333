import { array, bool, date, mixed, object, ObjectSchema, string, TestContext } from 'yup';

import { ChatFilterDirection, ChatFilterMatching } from 'enums';
import {
  ChatFilterFieldsNames,
  PrivateMessageFormFields,
  PrivateMessageFormFieldsNames,
} from 'pages/app-pages/private-bulk-message-page/message-modal/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import {
  createHtmlStrippedLengthTest,
  customYupValidateImageOrFile,
  customYupValidateURL,
  emptyEditorValue,
} from 'utils';

export const directionOptions: { value: ChatFilterDirection; label: string }[] = [
  { value: ChatFilterDirection.Include, label: 'Include' },
  { value: ChatFilterDirection.Exclude, label: 'Exclude' },
];

export const matchOptions: { value: ChatFilterMatching; label: string }[] = [
  { value: ChatFilterMatching.Any, label: 'Any can match' },
  { value: ChatFilterMatching.All, label: 'All must match' },
];

const now = new Date();
const oneMinuteLater = new Date(now.getTime() + 60000);

export const messageSchema: ObjectSchema<PrivateMessageFormFields> = object({
  [PrivateMessageFormFieldsNames.StartDate]: date()
    .min(oneMinuteLater, 'The date must be in the future')
    .optional(),
  [PrivateMessageFormFieldsNames.ExcludeArchived]: bool().optional(),
  [PrivateMessageFormFieldsNames.Message]: createHtmlStrippedLengthTest(1024),
  [PrivateMessageFormFieldsNames.FileUrl]: string()
    .default('')
    .when('message', {
      is: (val: any) => val === emptyEditorValue,
      then: (schema) => schema.required('Image url Required when Message is empty'),
    })
    .test('customYupValidateURL', 'customYupValidateURL', customYupValidateURL())
    .test(
      'customYupValidateImageOrFile',
      'customYupValidateImageOrFile',
      customYupValidateImageOrFile(),
    ),

  [PrivateMessageFormFieldsNames.Project]: string().required('Project is required'),
  [PrivateMessageFormFieldsNames.LinkText]: string()
    .default('')
    .when('linkUrl', {
      is: (val: any) => val !== '',
      then: (schema) => schema.required('Link Text Required when Link URL is provided'),
    })
    .max(100, 'Link Text must be less than 100 characters'),

  [PrivateMessageFormFieldsNames.LinkUrl]: string()
    .default('')
    .test('customYupValidateURL', 'customYupValidateURL', customYupValidateURL())
    .test(
      'linkText-url-dependency',
      'LinkUrl is required when LinkText is provided',
      function linkTextUrlDependency(this: TestContext, value?: string) {
        const { linkText } = this.parent as PrivateMessageFormFields; // Assuming LinkText is part of the parent object
        // Check if LinkText is provided and non-empty, then LinkUrl must also be provided and valid
        if (linkText && !value) {
          return this.createError({
            path: this.path,
            message: 'LinkUrl is required when LinkText is provided',
          });
        }
        return true;
      },
    ),
  [PrivateMessageFormFieldsNames.ChatFilter]: array(
    object({
      [ChatFilterFieldsNames.Direction]: mixed<ChatFilterDirection>()
        .oneOf(Object.values(ChatFilterDirection))
        .required('The Type is required.'),
      [ChatFilterFieldsNames.Labels]: array<AutoCompleteLabel[]>().defined(),
      [ChatFilterFieldsNames.Matching]: mixed<ChatFilterMatching>()
        .oneOf(Object.values(ChatFilterMatching))
        .required('The Type is required.'),
    }),
  ).required(),
});

export const messageDefaultValues: PrivateMessageFormFields = {
  [PrivateMessageFormFieldsNames.Message]: emptyEditorValue,
  [PrivateMessageFormFieldsNames.FileUrl]: undefined,
  [PrivateMessageFormFieldsNames.Project]: '',
  [PrivateMessageFormFieldsNames.ChatFilter]: [
    {
      [ChatFilterFieldsNames.Direction]: ChatFilterDirection.Include,
      [ChatFilterFieldsNames.Labels]: [],
      [ChatFilterFieldsNames.Matching]: ChatFilterMatching.Any,
    },
  ],
};

export const bulkMessagesFieldsConfig: Record<PrivateMessageFormFieldsNames, FormFieldProps> = {
  [PrivateMessageFormFieldsNames.Message]: {
    placeholder: 'Message',
    name: PrivateMessageFormFieldsNames.Message,
    autoComplete: 'off',
    type: 'text',
  },
  [PrivateMessageFormFieldsNames.FileUrl]: {
    placeholder: 'https://example.com/image.jpg',
    name: PrivateMessageFormFieldsNames.FileUrl,
  },
  [PrivateMessageFormFieldsNames.StartDate]: {
    label: 'Schedule Date',
    name: PrivateMessageFormFieldsNames.StartDate,
    autoComplete: 'off',
    type: 'date',
  },
  [PrivateMessageFormFieldsNames.LinkText]: {
    placeholder: 'Text to display for the link',
    name: PrivateMessageFormFieldsNames.LinkText,
    type: 'text',
  },
  [PrivateMessageFormFieldsNames.LinkUrl]: {
    placeholder: 'https://example.com',
    name: PrivateMessageFormFieldsNames.LinkUrl,
    type: 'text',
  },
  [PrivateMessageFormFieldsNames.Project]: {
    name: PrivateMessageFormFieldsNames.Project,
    type: 'text',
  },
  [PrivateMessageFormFieldsNames.ExcludeArchived]: {
    label: 'Exclude Archived Recipients',
    name: PrivateMessageFormFieldsNames.ExcludeArchived,
    type: 'checkbox',
  },
  [PrivateMessageFormFieldsNames.ChatFilter]: {
    name: PrivateMessageFormFieldsNames.ChatFilter,
  },
};

export const chatFilterFieldsConfig: Record<ChatFilterFieldsNames, FormFieldProps> = {
  [ChatFilterFieldsNames.Labels]: {
    placeholder: 'Select Labels, Leave empty to send to all users',
    name: ChatFilterFieldsNames.Labels,
    type: 'select',
  },
  [ChatFilterFieldsNames.Direction]: {
    // label: 'Direction',
    placeholder: 'Direction',
    name: ChatFilterFieldsNames.Direction,
    type: 'select',
  },
  [ChatFilterFieldsNames.Matching]: {
    // label: 'Matching',
    placeholder: 'Matching',
    name: ChatFilterFieldsNames.Matching,
    type: 'select',
  },
};
