import { AutoCompleteLabel } from 'types';

export enum MessageStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  NodeId = 'nodeId',
  Labels = 'labels',
  DisablePreviewLink = 'disablePreviewLink',
}

export interface MessageStepFormFields {
  [MessageStepFormFieldsNames.NodeId]: string;
  [MessageStepFormFieldsNames.Name]: string;
  [MessageStepFormFieldsNames.Input]: string;
  [MessageStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
  [MessageStepFormFieldsNames.DisablePreviewLink]?: boolean;
}
