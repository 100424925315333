export const namespace = process.env.REACT_APP_ENV === 'production' ? 'api' : 'qaapi';
export enum ApiKey {
  // project endpoints
  Project = 'project',
  ChatGroupMessage = 'chat-group-message',
  PrivateChatBulkMessage = 'private-chat-bulk-message',
  SendMessage = 'send-message',
  Bot = 'bot',
  Command = 'command',
  Order = 'order',
  Telegram = 'telegram',
  Chat = 'chat',
  TestMessage = 'test-message',
  App = 'app',
  Ticket = 'ticket',
  // auth endpoints
  User = 'users/me',
  Users = 'users',
  Organization = 'organization',
  OrganizationPendingUser = 'organization-pending-user',
  DeactivateOrganizationUser = 'deactivate-organization-user',
  OrganizationDecision = 'organization-decision',
  OrganizationDashboardData = 'organization/dashboard-data',
  ApplyCoupon = 'users/join-by-coupon',
  UserLogin = 'auth/login',
  GoogleLogin = 'auth/google',
  UserLogout = 'auth/logout',
  UserRegister = 'auth/register',
  TelegramLogin = 'users/telegram-notifications',
  UserLabel = 'user-label',
}
