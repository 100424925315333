import { LinearProgress, Stack, Typography } from '@mui/material';

interface UsageBoxProps {
  title: string;
  subtitle: string;
  feature: string;
  outOf: string;
  value: number;
  color: 'warning' | 'success' | 'error';
}
const UsageBox = ({ title, subtitle, feature, color, value, outOf }: UsageBoxProps) => (
  <Stack
    pb={3}
    alignItems='center'
    justifyContent='center'
    borderRadius={1}
    border={1}
    borderColor='divider'
    bgcolor='background.default'
    overflow='hidden'
    flex={1}
  >
    <LinearProgress
      variant='determinate'
      value={value}
      sx={{
        height: 6,
        width: '100%',
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        bgcolor: 'divider',
        '& .MuiLinearProgress-bar': {
          bgcolor: `${color}.main`,
        },
      }}
    />
    <Stack direction='row' mt={2} alignItems='baseline'>
      <Typography variant='h3' color='text.primary'>
        {feature}
      </Typography>
      <Typography variant='caption' color='text.secondary'>
        /{outOf}
      </Typography>
    </Stack>

    <Stack px={1} flex={1} gap={1}>
      <Typography textAlign='center' lineHeight='normal' variant='subtitle2'>
        {title}
      </Typography>
      <Typography textAlign='center' lineHeight='normal' variant='caption' color='text.secondary'>
        {subtitle}
      </Typography>
    </Stack>
  </Stack>
);

export default UsageBox;
