import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { LocalStorageKey } from 'config';
import { RoutePath } from 'enums';
import { User } from 'types';
import { readFromLocalStorage } from 'utils';
import { useServerError } from './useServerError';

// eslint-disable-next-line no-console

export interface AuthState {
  user: User | null;
  login?: (data: User) => void;
  logout?: () => void;
  setUser?: (data: User | null) => void;
}

const AuthContext = createContext<AuthState>({ user: null });

export const AuthProvider = ({ children, userData }: { children: ReactNode; userData: User }) => {
  const [user, setUser] = useState<User | null>(userData);
  const navigate = useNavigate();
  const [, setLoggedInBefore] = useLocalStorage(LocalStorageKey.LoggedInBefore, false);
  const redirectUrl =
    readFromLocalStorage(LocalStorageKey.RedirectUrl) || `/${RoutePath.Dashboard}`;
  const toastServerError = useServerError();
  const login = async (data: User) => {
    setLoggedInBefore(true);
    setUser(data);
    navigate(redirectUrl, { replace: true });
  };

  const logout = async () => {
    try {
      await api.post(ApiKey.UserLogout);
    } catch (error) {
      toastServerError(error);
    }
    window.open(`https://www.teleform.io`, '_self');
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
