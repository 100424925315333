import { AutoCompleteLabel } from 'types';

export enum SendTelegramNotificationStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface SendTelegramNotificationStepFormFields {
  [SendTelegramNotificationStepFormFieldsNames.Name]: string;
  [SendTelegramNotificationStepFormFieldsNames.Input]: string;
  [SendTelegramNotificationStepFormFieldsNames.NodeId]: string;
  [SendTelegramNotificationStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
