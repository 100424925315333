import { Stack } from '@mui/material';
import { map } from 'lodash';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import {
  FormArrayFields,
  FormAutoComplete,
  FormSection,
  FormSelect,
  FormTextField,
  FormTextFieldInlineEdit,
} from 'components/index';
import { useFormProvider, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';

import SendDataFormArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/SendDataFormArrayField';
import SendDataFormHeaderArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/SendDataFormHeaderArrayField';
import SendDataMagicFormArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/SendDataFormMagicArrayField';
import {
  Method,
  SendDataFormFields,
  SendDataFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/types';
import {
  defaultSendDataFormValues,
  formatMenuOptions,
  sendDataFieldsConfig,
  sendDataFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/utils';
import { Mention } from 'types';
import { findLabelOrValue } from 'utils';

interface MessageProps {
  id: string;
  defaultValues?: Partial<SendDataFormFields>;
  defaultName: string;
  mentions?: Mention[];
}

const SendData = ({ defaultValues, mentions, id, defaultName }: MessageProps) => {
  const formatLabel = findLabelOrValue(formatMenuOptions, { value: defaultValues?.format || '' });
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultSendDataFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    format: formatLabel || defaultValues?.format || defaultSendDataFormValues.format,
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<SendDataFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<SendDataFormFields>({
    schema: sendDataFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: SendDataFormFields) => {
    const { format, labels, ...rest } = formData;
    const labelIds = await processNewLabels(labels);

    const formatValue = findLabelOrValue(formatMenuOptions, { label: format });
    await updateNodeFormData({ id, formData: { ...rest, labels: labelIds, format: formatValue } });
  };

  const {
    name,
    labels,
    variable,
    propFields,
    headers,
    magicPropFields,
    webHookUrl,
    method,
    format,
  } = sendDataFieldsConfig;

  const ObjectPropsFormFieldsWrapper = useCallback(
    (props: FormArrayFieldProps) => (
      <SendDataFormArrayField {...props} options={mentions?.map((mention) => mention.value)} />
    ),
    [mentions],
  );

  const MagicPropsFormFieldsWrapper = useCallback(
    (props: FormArrayFieldProps) => (
      <SendDataMagicFormArrayField {...props} options={['Ip', 'User Agent']} />
    ),
    [],
  );

  const HeadersFormFieldsWrapper = useCallback(
    (props: FormArrayFieldProps) => <SendDataFormHeaderArrayField {...props} />,
    [],
  );

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <FormSection
          title='WebHook URL'
          infoIcon={{
            tooltip: 'The URL to send the request to.',
            icon: 'info',
          }}
        >
          <FormTextField {...webHookUrl} size='small' />
        </FormSection>
        <FormSection
          title='Method'
          infoIcon={{
            tooltip: 'The HTTP method to use when sending the request.',
            icon: 'info',
          }}
        >
          <Stack gap={2} width={1}>
            <FormSelect
              {...method}
              options={Object.values(Method)}
              variant='outlined'
              size='small'
              nonEmpty
            />
          </Stack>
        </FormSection>
        <FormSection
          title='Request Format'
          infoIcon={{
            tooltip:
              'Select a provider or enter a custom query using $data to incorporate dynamic data. For example, {"records": [ { "fields":$data } ]}',
            icon: 'info',
          }}
        >
          <FormAutoComplete
            size='small'
            freeSolo
            options={map(formatMenuOptions, 'label')}
            {...format}
          />
        </FormSection>

        <FormTextField
          infoIcon={{
            tooltip: 'The variable to hold the response from the request.',
            icon: 'info',
          }}
          {...variable}
          size='small'
        />
        <FormSection
          title={sendDataFieldsConfig[SendDataFormFieldsNames.PropFields].label}
          infoIcon={{
            tooltip:
              'Collection of key-value pairs to send with the request, use built-in variables to send dynamic data or enter a custom value.',
            icon: 'info',
          }}
        >
          <FormArrayFields
            name={propFields.name}
            addButtonText='Add Property'
            FormArrayFieldComponent={ObjectPropsFormFieldsWrapper}
          />
        </FormSection>

        <FormSection
          title='HTTP Headers'
          infoIcon={{
            tooltip: 'HTTP headers to send with the request.',
            icon: 'info',
          }}
        >
          <FormArrayFields
            addButtonText='Add Header'
            name={headers.name}
            FormArrayFieldComponent={HeadersFormFieldsWrapper}
          />
        </FormSection>
        <FormSection
          title={sendDataFieldsConfig[SendDataFormFieldsNames.MagicPropFields].label}
          infoIcon={{
            tooltip:
              'Collection of key-value pairs to send with the request, that was generated by the magic URL, or enter a custom value.',
            icon: 'info',
          }}
        >
          <FormArrayFields
            addButtonText='Add Magic Property'
            name={magicPropFields.name}
            FormArrayFieldComponent={MagicPropsFormFieldsWrapper}
          />
        </FormSection>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default SendData;
