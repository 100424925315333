import { differenceInDays, format, intervalToDuration, isToday, isYesterday } from 'date-fns';

export const formatChatDate = (date: Date) => {
  const now = new Date();
  const inputDate = new Date(date);
  const daysDifference = differenceInDays(now, inputDate);

  // Use relative time format within a week
  const thresholdDays = 7;

  if (isToday(inputDate)) {
    return `Today ${format(inputDate, 'h:mm a')}`;
  }
  if (isYesterday(inputDate)) {
    return `Yesterday ${format(inputDate, 'h:mm a')}`;
  }
  if (daysDifference < thresholdDays) {
    return `${daysDifference} days ago`;
  }
  return format(inputDate, 'MMM d, yyyy h:mm a');
};

export const sortByDate = (v1: Date, v2: Date) => {
  const date1 = new Date(v1);
  const date2 = new Date(v2);
  return date2.getTime() - date1.getTime();
};

export const sortByBoolean = (a: boolean, b: boolean) => {
  if (a === b) {
    return 0;
  }
  return a ? -1 : 1;
};

export const formatShortTimeDifference = (endDate: Date, startDate: Date = new Date()): string => {
  const duration = intervalToDuration({ start: startDate, end: endDate });
  let formatted = '';

  if (duration.days && duration.days > 0) {
    formatted += `${duration.days}d `;
  }
  if (duration.hours && duration.hours > 0) {
    formatted += `${duration.hours}h `;
  }
  if (duration.minutes && duration.minutes > 0) {
    formatted += `${duration.minutes}m`;
  }

  return formatted.trim(); // Remove any trailing space
};

export const daysUntilPlanEnds = (endDate: Date): number => {
  const currentDate: Date = new Date(); // Get the current date
  const endDateFormatted = new Date(endDate);
  return differenceInDays(endDateFormatted, currentDate);
};

export const showBannerLimit = 14;
export const planIsOnGoing = (endDate: Date): boolean =>
  daysUntilPlanEnds(endDate) > showBannerLimit;
