import { useMediaQuery, useTheme } from '@mui/material';

export const useAppBarHeight = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // >= md
  const isMobileLandscape = useMediaQuery('(orientation: landscape) and (max-width: 600px)'); // landscape mobile
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm')); // <= xs

  if (isDesktop) {
    return 64;
  }
  if (isMobileLandscape) {
    return 48;
  }
  if (isSmallMobile) {
    return 56;
  }

  return 64; // default height if none of the conditions match, though it shouldn't reach here given the breakpoints
};
