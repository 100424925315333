import { DialogActions, Link, Stack, Typography } from '@mui/material';
import { times } from 'lodash';
import { useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { BasicModal, IconButton } from '@common-components';
import { ApiKey, fetcher } from 'api';
import NoDataOverlay from 'components/NoDataOverlay';
import { useIsMobile, useUnmount, useUpdateBooleanProperty } from 'hooks';
import IsImportant from 'pages/activity-page/IsImportant';
import { Chat, Conversation } from 'types';
import { formatChatDate, formatFullNameWithInitial } from 'utils';
import ChatForm from './chat-form/ChatForm';
import ChatBubble from './ChatBubble';
import ChatSkeleton from './ChatSkeleton';
import { MessageOrder, MessagePosition } from './types';

const ChatActivityModal = () => {
  const navigate = useNavigate();

  const { chatId } = useParams();
  const chatCount = useRef<number | null>(null);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  // flag first time render
  const firstRender = useRef(true);
  const { data: chatActivity, isLoading } = useSWR<Chat>(`${ApiKey.Chat}/${chatId}`, fetcher, {
    refreshInterval: 2000,
  });

  const { isMobile } = useIsMobile();

  const { updateProperty: toggleChatRead } = useUpdateBooleanProperty<Chat>({
    id: chatActivity?.id || '',
    property: 'isRead',
    apiKey: ApiKey.Chat,
  });

  useUnmount(() => {
    const fetchData = async () => {
      if (chatActivity?.id && chatCount.current !== chatActivity?.conversationCount) {
        await toggleChatRead(true);
      }
    };
    fetchData();
    mutate(`${ApiKey.Chat}/${ApiKey.Ticket}`);
  });

  useEffect(() => {
    const fetchData = async () => {
      if (
        chatActivity?.id &&
        chatActivity?.conversationCount > 0 &&
        chatCount.current !== chatActivity?.conversationCount
      ) {
        chatCount.current = chatActivity?.conversationCount || 0;
        await toggleChatRead(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatActivity?.conversationCount]); //

  const scrollToBottom = ({ behavior = 'smooth' }: { behavior?: ScrollBehavior } = {}) => {
    messagesEndRef.current?.scrollIntoView({ behavior });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom(firstRender.current ? { behavior: 'instant' } : { behavior: 'smooth' });
      firstRender.current = false;
    }, 0); // Adjust delay as needed

    return () => clearTimeout(timer); // Cleanup timeout
  }, [chatActivity?.conversation]);

  if (isLoading) {
    return (
      <BasicModal
        headerProps={{ sx: { boxShadow: (theme) => theme.shadows[6] } }}
        title='Loading Conversation'
        label='chat-activity-dialog-loading'
        open
      >
        <Stack gap={2}>
          {times(8, (i) => (
            <ChatSkeleton key={i} />
          ))}
        </Stack>
      </BasicModal>
    );
  }

  if (!chatActivity?.id || !chatId) {
    navigate('./../..', { replace: true }); // This will navigate back if there is no chatActivity
    return null; // This prevents the rest of the component from rendering
  }

  // Function to group messages
  const groupMessages = (messages: Conversation[]): Conversation[][] =>
    messages.reduce(
      (groups: Conversation[][], message: Conversation, index: number, array: Conversation[]) => {
        if (index === 0) {
          groups.push([message]); // Start the first group with the first message
          return groups;
        }

        const lastMessage = array[index - 1];
        const lastGroup = groups[groups.length - 1];
        const timeDiff =
          new Date(message.createdAt).getTime() - new Date(lastMessage.createdAt).getTime();
        const timeDiffInMinutes = timeDiff / (1000 * 60);

        // Start a new group if sender is different or time difference is more than 5 minutes
        const shouldStartNewGroup = lastMessage.sender !== message.sender || timeDiffInMinutes > 5;

        if (shouldStartNewGroup) {
          groups.push([message]);
        } else {
          lastGroup.push(message);
        }

        return groups;
      },
      [],
    );

  const messageGroups = groupMessages(chatActivity.conversation || []);

  return (
    <BasicModal
      open
      contentProps={{ sx: { px: isMobile ? 2 : 3 } }}
      handleClose={() => {
        navigate('./../..', { replace: true });
      }}
      headerProps={{ sx: { boxShadow: (theme) => theme.shadows[6] } }}
      titleComponent={
        <Stack
          width={1}
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          gap={1}
        >
          <Stack width={1} overflow='hidden'>
            <Typography
              noWrap
              variant='h6'
              component='h2'
              title={formatFullNameWithInitial(chatActivity.firstName, chatActivity.lastName)}
            >
              {`from ${formatFullNameWithInitial(chatActivity.firstName, chatActivity.lastName)}`}{' '}
              {chatActivity.userName && (
                <Link
                  component={RouterLink}
                  to={`https://t.me/${chatActivity.userName}`}
                  underline='none'
                  target='_blank'
                  variant='h6'
                  sx={{
                    '&:hover': {
                      color: 'primary.dark',
                    },
                  }}
                >
                  {`@${chatActivity.userName}`}
                </Link>
              )}
            </Typography>
            <Typography variant='body2' noWrap title={chatActivity.project?.name} component='h3'>
              via {chatActivity.project?.name}
            </Typography>
          </Stack>
          <Stack direction='row' gap={1}>
            <IsImportant edge='end' id={chatActivity.id} isImportant={chatActivity.isImportant} />
            <IconButton
              size='large'
              edge='end'
              iconProps={{
                fontSize: 'small',
              }}
              icon='close'
              onClick={() => navigate('./../..')}
            />
          </Stack>
        </Stack>
      }
      label='chat-activity-dialog'
      customActions={
        <DialogActions
          sx={{
            boxShadow: (theme) => theme.shadows[6],
            px: 0.5,
            py: 1,
          }}
        >
          <ChatForm isBlocked={chatActivity?.isBlocked} />
        </DialogActions>
      }
    >
      <Stack minHeight={400} gap={3}>
        {!chatActivity.conversation?.length && (
          <Stack mb={8}>
            <NoDataOverlay
              text='No Chat Activity Records Found'
              onClick={() => navigate('./../..')}
              buttonTitle='Close'
            />
          </Stack>
        )}

        {messageGroups.map((group, groupIndex) => {
          // TypeScript-safe key for each group based on the first message
          const firstMessage = group[0];
          const groupKey = `${firstMessage.sender}-${firstMessage.createdAt}-${groupIndex}`;

          return (
            <Stack key={groupKey}>
              {group.map((message, index, arr) => {
                const position =
                  message.sender === 'bot' ? MessagePosition.Left : MessagePosition.Right;

                // Logic for isFirst, isLast, and isOnly
                const isFirst = index === 0;
                const isLast = index === arr.length - 1;
                const isOnly = arr.length === 1;

                // Adjust order based on isFirst, isLast, and isOnly
                let order: MessageOrder;
                if (isOnly) {
                  order = MessageOrder.Only;
                } else if (isFirst) {
                  order = MessageOrder.First;
                } else if (isLast) {
                  order = MessageOrder.Last;
                } else {
                  order = MessageOrder.Middle;
                }

                // Caption display logic
                const showCaption = isLast || isOnly;

                const caption =
                  message.sender === 'bot'
                    ? `${chatActivity.project?.name} • ${formatChatDate(message.createdAt)}`
                    : `${formatChatDate(message.createdAt)} • ${formatFullNameWithInitial(
                        chatActivity.firstName,
                        chatActivity.lastName,
                      )}`;

                return (
                  <ChatBubble
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${message.createdAt}-${index}`}
                    message={message.message}
                    nodeType={message.type}
                    sender={message.sender}
                    fileUrl={message.fileUrl}
                    position={position}
                    order={order}
                    caption={showCaption ? caption : ''}
                    showAvatar={isFirst || isOnly}
                  />
                );
              })}
              <div ref={messagesEndRef} />
            </Stack>
          );
        })}
      </Stack>
    </BasicModal>
  );
};

export default ChatActivityModal;
