import { RoutePath } from 'enums';
import { MessengerOnboardingSteps } from 'pages/app-pages/chatbot-builder-page/dialogs/messenger-onboarding/types';

export interface MessengerOnboardingStepConfig {
  title: string;
  description: string;
  stepNumber: number;
  buttonText: string;
  icon: string;
  route?: string;
}
export const onboardingStepsConfig = (
  id: string,
): Record<MessengerOnboardingSteps, MessengerOnboardingStepConfig> => ({
  [MessengerOnboardingSteps.Token]: {
    title: 'Activate Token',
    description: 'Connect your Telegram bot token to your bot to start sending messages.',
    stepNumber: 1,
    buttonText: 'Activate Token',
    icon: 'token',
    route: `${RoutePath.Messenger}/${id}/${RoutePath.Publish}`,
  },
  [MessengerOnboardingSteps.Groups]: {
    title: 'Connect Groups',
    description: 'Connect groups and channels to your bot to start sending messages.',
    stepNumber: 2,
    buttonText: 'Connect Groups',
    icon: 'group',
    route: `${RoutePath.Messenger}/${id}/${RoutePath.ConnectGroups}`,
  },
  [MessengerOnboardingSteps.Messages]: {
    title: 'Broadcast Messages',
    description: 'Send your first message to your group or channel.',
    stepNumber: 3,
    buttonText: 'Broadcast Messages',
    icon: 'message',
    route: `${RoutePath.Messenger}/${id}/${RoutePath.Composer}`,
  },
});
