import useSWR from 'swr';
import { ApiKey, fetchChatData } from 'api';
import { ChatTableProps } from 'types';

const defaultChat: ChatTableProps[] = [];

export const useGetChatActivity = () => {
  const { data, isLoading, error } = useSWR<ChatTableProps[]>(ApiKey.Chat, fetchChatData);
  return {
    chats: data ?? defaultChat,
    isLoading,
    error,
  };
};
