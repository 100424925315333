import { Box, ButtonGroup, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { CustomerSupportIconButton, GroupButtonItem } from '@common-components';
import { ApiKey } from 'api';
import { AppTypes } from 'enums';
import { appConfig } from 'forms/create-project/utils';
import { useAppBarHeight, useIsMobile, usePauseApp } from 'hooks';
import AppsSwitcher from 'pages/app-pages/components/AppsSwitcher';
import { Project } from 'types';

interface SchedulerTopBarProps {
  name: string;
}
const BulkMessageTopBar = ({ name }: SchedulerTopBarProps) => {
  const appBarHeight = useAppBarHeight();
  const { isMobile } = useIsMobile();
  const { id } = useParams();
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const app = project?.apps.find((a) => a.type === AppTypes.PrivateChatBulkMessages);

  const { togglePause, pausing } = usePauseApp({
    project,
    appType: AppTypes.PrivateChatBulkMessages,
  });

  return (
    <Stack
      bgcolor='bulk.dark'
      direction='row'
      alignItems='center'
      width={1}
      p={2}
      gap={1}
      color='bulk.contrastText'
      height={appBarHeight}
    >
      <Box flex={isMobile ? 'inherit' : 1}>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          justifyContent='flex-start'
          overflow='hidden'
        >
          <AppsSwitcher currentApp={AppTypes.PrivateChatBulkMessages} />
          {!isMobile && (
            <Typography noWrap variant='subtitle2'>
              {appConfig[AppTypes.PrivateChatBulkMessages].label}
            </Typography>
          )}
        </Stack>
      </Box>
      <Stack
        direction='row'
        gap={1}
        alignItems={isMobile ? 'flex-start' : 'center'}
        overflow='hidden'
      >
        <Typography noWrap variant={isMobile ? 'subtitle2' : 'subtitle1'}>
          {name}
        </Typography>
      </Stack>
      <Stack justifyContent='flex-end' flex={1} direction='row' gap={2} alignItems='center'>
        <Stack direction='row' gap={2}>
          <CustomerSupportIconButton />
          {project?.token && (
            <ButtonGroup color='inherit' variant='outlined' size='small'>
              <GroupButtonItem
                iconColor={app?.isActive ? 'inherit' : 'grey.800'}
                tooltip={app?.isActive ? 'Deactivate Bulk Messenger' : 'Activate Bulk Messenger'}
                onClick={async () => {
                  await togglePause();
                }}
                icon='power_settings_new'
                loading={pausing}
              />
            </ButtonGroup>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BulkMessageTopBar;
