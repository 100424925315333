import { array, bool, object, ObjectSchema, string } from 'yup';
import messages from 'messages';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  YesNoStepFormFields,
  YesNoStepFormFieldsMenuNames,
  YesNoStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import {
  editorValidation,
  emptyEditorValue,
  maxTelegramButtonLength,
  variableValidation,
} from 'utils';

export const yesNoStepFormSchema: ObjectSchema<YesNoStepFormFields> = object({
  [YesNoStepFormFieldsNames.NodeId]: string().required(),
  [YesNoStepFormFieldsNames.Name]: string().required('Name is required'),
  [YesNoStepFormFieldsNames.Variable]: variableValidation,
  [YesNoStepFormFieldsNames.Input]: editorValidation,
  [YesNoStepFormFieldsNames.Menu]: array(
    object({
      [YesNoStepFormFieldsMenuNames.Option]: string()
        .max(
          maxTelegramButtonLength,
          `Option should be less than ${maxTelegramButtonLength} characters`,
        )
        .required('Option is required'),
      [YesNoStepFormFieldsMenuNames.NodeId]: string().required('Node is required'),
    }),
  ).required(),
  [YesNoStepFormFieldsNames.RemoveAnswerOptions]: bool(),
  [YesNoStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const yesNoPlateStepFormValues: YesNoStepFormFields = {
  [YesNoStepFormFieldsNames.Name]: 'Yes/No Question',
  [YesNoStepFormFieldsNames.Input]: emptyEditorValue,
  [YesNoStepFormFieldsNames.NodeId]: '',
  [YesNoStepFormFieldsNames.Menu]: [],
  [YesNoStepFormFieldsNames.RemoveAnswerOptions]: false,
  [YesNoStepFormFieldsNames.Labels]: [],
};

export const YesNoFormFieldsConfig: Record<YesNoStepFormFieldsNames, FormFieldProps> = {
  [YesNoStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: YesNoStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [YesNoStepFormFieldsNames.Input]: {
    placeholder: '* Ask a Question and Provide 2 Options',
    name: YesNoStepFormFieldsNames.Input,
    type: 'textarea',
  },
  [YesNoStepFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: YesNoStepFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [YesNoStepFormFieldsNames.Menu]: {
    label: 'Menu',
    placeholder: 'Menu',
    name: YesNoStepFormFieldsNames.Menu,
    autoComplete: 'off',
    type: 'text',
  },
  [YesNoStepFormFieldsNames.NodeId]: {
    name: YesNoStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [YesNoStepFormFieldsNames.RemoveAnswerOptions]: {
    label: messages.builder.removeAnswerOptions,
    name: YesNoStepFormFieldsNames.RemoveAnswerOptions,
    type: 'checkbox',
  },
  [YesNoStepFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const YesNoFormMenuFieldsConfig: Record<YesNoStepFormFieldsMenuNames, FormFieldProps> = {
  [YesNoStepFormFieldsMenuNames.Option]: {
    label: 'Option',
    name: YesNoStepFormFieldsMenuNames.Option,
    autoComplete: 'off',
    type: 'text',
  },

  [YesNoStepFormFieldsMenuNames.NodeId]: {
    name: YesNoStepFormFieldsMenuNames.NodeId,
    type: 'hidden',
  },
};
