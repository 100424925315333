export enum BotFormFieldsNames {
  Name = 'name',
  Description = 'description',
}
export interface BotFormFields {
  [BotFormFieldsNames.Name]: string;
  [BotFormFieldsNames.Description]?: string;
}

export interface ProjectUpdateDescriptionFields {
  [BotFormFieldsNames.Description]?: string;
}
