import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToTop() {
  const location = useLocation();
  const prevPath = useRef(location.pathname); // Store the previous pathname

  useEffect(() => {
    const currentPathParts = location.pathname.split('/');
    const prevPathParts = prevPath.current.split('/');

    // Check if the first part of the path has changed
    if (currentPathParts[1] !== prevPathParts[1]) {
      window.scrollTo(0, 0);
    }

    // Update the previous path
    prevPath.current = location.pathname;
  }, [location.pathname]); // Trigger the effect only when the pathname changes
}
