import { Box, Stack, Tooltip } from '@mui/material';
import { Image } from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
import supportIcon from 'assets/images/support-icon.svg';
import messages from 'messages';
import { supportBotLink } from 'utils';

interface CustomerSupportButtonProps {
  customerSupportUrl?: string;
  customerSupportText?: string;
}

const CustomerSupportFloatingButton = ({
  customerSupportUrl = supportBotLink,
  customerSupportText = messages.general.customerSupportText,
}: CustomerSupportButtonProps) => {
  const coordinates = { bottom: 16, right: 16 };

  return (
    <Box position='fixed' {...coordinates} zIndex={1000}>
      <Tooltip title={customerSupportText} placement='top-end' arrow>
        <Stack
          width={50}
          height={50}
          bgcolor='white'
          alignItems='center'
          justifyContent='center'
          border={1}
          borderColor='grey.200'
          boxShadow={(theme) => theme.shadows[8]}
          borderRadius={20}
          component={RouterLink}
          to={customerSupportUrl}
          target='_blank'
        >
          <Image width={30} height={30} duration={0} src={supportIcon} />
        </Stack>
      </Tooltip>
    </Box>
  );
};

export default CustomerSupportFloatingButton;
