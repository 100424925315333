import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicModal, Button, IconBadge } from '@common-components';
import Icon from 'components/Icon';
import { RoutePath } from 'enums';
import { useGetChatActivity, useNavigateBack } from 'hooks';

const BulkOnboarding = () => {
  const { id } = useParams();

  const navigateBack = useNavigateBack();
  const navigate = useNavigate();

  const { chats } = useGetChatActivity();
  const projectChats = chats.filter((chat) => chat.projectId === id);

  useEffect(() => {
    if (projectChats.length > 1) {
      navigateBack();
    }
  }, [navigateBack, projectChats.length]);

  return (
    <BasicModal
      title='Bulk Message Distributor'
      label='no-chats'
      open
      cancelButton={false}
      blockCloseAttempt
      contentProps={{ sx: { textAlign: 'center', pt: 0, alignItems: 'center' } }}
    >
      <Stack
        alignItems='center'
        px={2}
        flex={1}
        gap={1}
        width={1}
        alignSelf='center'
        maxWidth={{ xs: 400, md: 1 }}
      >
        <IconBadge icon='check' bgcolor='warning.main' />
        <Typography textAlign='center' variant='h6' pt={1} color='text.primary'>
          Recipients Needed
        </Typography>
        <Typography color='text.primary' textAlign='center' variant='body2' flex={1}>
          {`Looks like you're ready to send bulk messages — that's great! However, it seems your
            recipients list is currently empty. To use this feature, please ensure that there are
            users who have interacted with the chatbot. Come back here once your recipients list is
            populated, and you'll be all set to send your messages.`}
        </Typography>

        <Button
          sx={{ mt: 4 }}
          startIcon={<Icon icon='arrow_back' fontSize='small' />}
          variant='contained'
          color='info'
          onClick={() => navigate(`/${RoutePath.ProjectManager}/${id}`, { replace: true })}
        >
          Back To Project
        </Button>
      </Stack>
    </BasicModal>
  );
};

export default BulkOnboarding;
