import { object, string } from 'yup';
import { FormFieldProps } from 'types';
import {
  customYupValidateImageOrFile,
  customYupValidateURL,
  emptyEditorValue,
  isEmptyEditorContent,
} from 'utils';
import { ChatMessageFormFields, ChatMessageFormFieldsNames } from './types';

export const messageSchema = object({
  [ChatMessageFormFieldsNames.Message]: string()
    .max(1024)
    .transform((value, originalValue) => (isEmptyEditorContent(originalValue) ? '' : value))
    .test(
      'messageOrFileRequired',
      'You must provide either a message, a file, or both',
      function messageOrFileRequired(value) {
        const { fileUrl } = this.parent;
        const messageIsEmpty = isEmptyEditorContent(value || '');
        const fileUrlIsEmpty = !fileUrl;
        return !(messageIsEmpty && fileUrlIsEmpty);
      },
    ),
  [ChatMessageFormFieldsNames.FileUrl]: string()
    .default('')
    .test('customYupValidateURL', 'customYupValidateURL', customYupValidateURL())
    .test(
      'customYupValidateImageOrFile',
      'customYupValidateImageOrFile',
      customYupValidateImageOrFile(),
    ),
});

export const messageDefaultValues: ChatMessageFormFields = {
  [ChatMessageFormFieldsNames.Message]: emptyEditorValue,
  [ChatMessageFormFieldsNames.FileUrl]: undefined,
};

export const groupsMessagesFieldsConfig: Record<ChatMessageFormFieldsNames, FormFieldProps> = {
  [ChatMessageFormFieldsNames.Message]: {
    placeholder: 'Message',
    name: ChatMessageFormFieldsNames.Message,
    autoComplete: 'off',
    type: 'text',
  },
  [ChatMessageFormFieldsNames.FileUrl]: {
    placeholder: 'https://example.com/image.jpg',
    name: ChatMessageFormFieldsNames.FileUrl,
    autoComplete: 'off',
    label: '* Image/File Url',
  },
};
