import { AccountPlanType } from 'enums';

export interface AccountPlansConfig {
  title: string;
  subtitle: string;
  body: string;
  caption: string;
  planId: string;
}

export const accountPlansConfig: { [key in AccountPlanType]?: AccountPlansConfig } = {
  [AccountPlanType.Pro]: {
    title: 'Teleform Pro',
    subtitle: 'Unlock Professional Features with Teleform Pro',
    body:
      ' Step up your game with our Pro Plan. Gain access to advanced\n' +
      '                                    features like External integration Node, Human Handoff, enhanced\n' +
      '                                    support, and increased limits to boost your productivity and\n' +
      '                                    efficiency.',
    caption: '$5.99/Month',
    planId: process.env.REACT_APP_PAYPAL_PRO_PLAN_ID!,
  },
  [AccountPlanType.Premium]: {
    title: 'Teleform Premium Team',
    subtitle: 'Empower Your Team with Teleform Premium Team',
    body:
      'Bring your team together with the Premium Team Plan. This plan\n' +
      '                                    includes everything in Pro, plus powerful collaboration tools like\n' +
      '                                    Ticketing System, shared resources, and administrative controls to\n' +
      '                                    manage team roles and permissions.',
    caption: '$9.99/Month',
    planId: process.env.REACT_APP_PAYPAL_PREMIUM_PLAN_ID!,
  },
};
