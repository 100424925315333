import { PickEnum } from 'utils';

export enum MessageColumns {
  SendAttempt = 'sendAttempt',
  Message = 'message',
  Groups = 'groups',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  DeliveryDate = 'deliveryDate',
}

export type MessageSortableKeys = PickEnum<
  MessageColumns,
  MessageColumns.Message | MessageColumns.DeliveryDate | MessageColumns.UpdatedAt
>;
