import { useCallback, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api } from 'api';
// eslint-disable-next-line import/no-internal-modules
import { useServerError } from 'hooks/useServerError';
// eslint-disable-next-line import/no-internal-modules
import { useToast } from 'hooks/useToast';

interface UpdateBooleanProperty<T> {
  property: keyof T;
  id: string;
  apiKey: string;
  successMessage?: string;
  mutateKey?: string[];
}

export const useUpdateBooleanProperty = <T extends { id: string }>({
  id,
  property,
  apiKey,
  successMessage,
  mutateKey = [],
}: UpdateBooleanProperty<T>) => {
  const { data: entity, isLoading } = useSWR<T>(`${apiKey}/${id}`);

  const { value: loading, setTrue: setLoading, setFalse: setStopLoading } = useBoolean(false);

  const { value: localeEntity, setValue: setLocaleEntity } = useBoolean(!!entity?.[property]);

  useEffect(() => {
    setLocaleEntity(!!entity?.[property]);
  }, [entity, property, setLocaleEntity]);

  const toast = useToast();
  const toastServerError = useServerError();

  const updateProperty = useCallback(
    async (newValue: boolean) => {
      setLocaleEntity(newValue);
      setLoading();
      try {
        const data = { [property]: newValue };
        await api.patch<T>(`${apiKey}/${id}`, data);

        // After successful patch, revalidate both item and collection
        await mutate(`${apiKey}/${id}`);
        await mutate(apiKey);

        if (mutateKey.length > 0) {
          await Promise.all(mutateKey.map((key) => mutate(key)));
        }

        if (successMessage) {
          toast({
            message: successMessage,
            variant: 'success',
          });
        }
      } catch (error) {
        // Revert on error
        toastServerError(error);
      } finally {
        setStopLoading();
      }
    },
    [
      setLocaleEntity,
      setLoading,
      property,
      apiKey,
      id,
      successMessage,
      toast,
      toastServerError,
      setStopLoading,
      mutateKey,
    ],
  );

  return {
    entity: localeEntity,
    updateProperty,
    isLoading: loading || isLoading,
  };
};
