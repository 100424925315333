import { appConfig } from 'forms/create-project/utils';
import { App } from 'types';

export const areAllAppsConnected = (apps: App[]): boolean => {
  // Obtain all app types that should show in create
  const appTypesToShowInCreate = Object.values(appConfig).map((config) => config.type);

  // Check if every app type that should show in create is connected
  return appTypesToShowInCreate.every((appType) => apps.some((app) => app.type === appType));
};

export const supportBotLink = 'https://t.me/TeleformSupportBot';
