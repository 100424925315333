import { Stack } from '@mui/material';
import Quill from 'quill';
import { FC, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';

interface QuillContentDisplayProps {
  content: string;
  height?: number | string;
}

const QuillContentDisplay: FC<QuillContentDisplayProps> = ({ content, height }) => {
  const quillRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (quillRef.current) {
      // Insert the content directly into the editor container
      quillRef.current.innerHTML = content;

      // Initialize Quill
      const quill = new Quill(quillRef.current, {
        readOnly: true,
        theme: 'snow', // or 'bubble'
        modules: {
          toolbar: false, // Disable the toolbar
        },
      });

      return () => {
        quill.disable();
      };
    }

    // Return an empty function for the case when quillRef.current is null
    return () => {};
  }, [content]);

  return (
    <Stack
      ref={quillRef}
      sx={{
        fontWeight: 'initial',
        border: 'none',
        height: 1,
        '&.ql-container.ql-snow': {
          border: 'none',
          height: height ?? 1,
          justifyContent: 'center',
          maxHeight: 1,
          overflow: 'auto',
        },
        '& .ql-editor': {
          padding: 0,
          py: 1,
          height: 'auto',
          fontSize: (theme) => theme.typography.body2.fontSize,
        },
      }}
    />
  );
};

export default QuillContentDisplay;
