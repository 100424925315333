import { array, mixed, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  InviteFormFieldsNames,
  InviteFormFormFields,
  InviteType,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/invite/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue, maxTelegramButtonLength } from 'utils';

export const inviteStepFormSchema: ObjectSchema<InviteFormFormFields> = object({
  [InviteFormFieldsNames.NodeId]: string().required(),
  [InviteFormFieldsNames.Name]: string().required('Name is required'),
  [InviteFormFieldsNames.Button]: string()
    .max(maxTelegramButtonLength, `Text should be less than ${maxTelegramButtonLength} characters`)
    .required('Button is required'),
  [InviteFormFieldsNames.ChannelId]: string()
    .matches(
      /^[-\d]+$/,
      `Channel ID must contain only numbers and '-'. Use the link below to get the channel ID.`,
    )
    .required(`Channel ID is required`),
  [InviteFormFieldsNames.Type]: mixed<InviteType>()
    .oneOf(Object.values(InviteType))
    .required('The Type is required.'),
  [InviteFormFieldsNames.Input]: editorValidation,
  [InviteFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultInviteFormValues: Partial<InviteFormFormFields> = {
  [InviteFormFieldsNames.Input]: emptyEditorValue,
  [InviteFormFieldsNames.NodeId]: '',
  [InviteFormFieldsNames.Type]: InviteType.OneTime,
  [InviteFormFieldsNames.ChannelId]: '',
  [InviteFormFieldsNames.Button]: 'Click To Join',
  [InviteFormFieldsNames.Labels]: [],
};

export const inviteFieldsConfig: Record<InviteFormFieldsNames, FormFieldProps> = {
  [InviteFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: InviteFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [InviteFormFieldsNames.Input]: {
    placeholder: '* Message',
    name: InviteFormFieldsNames.Input,
    type: 'text',
  },
  [InviteFormFieldsNames.Button]: {
    placeholder: 'Will be displayed on the link button',
    name: InviteFormFieldsNames.Button,
    autoComplete: 'off',
    type: 'text',
  },
  [InviteFormFieldsNames.ChannelId]: {
    placeholder: 'Telegram Channel ID',
    name: InviteFormFieldsNames.ChannelId,
    autoComplete: 'off',
    type: 'text',
  },
  [InviteFormFieldsNames.Type]: {
    //  label: 'Invitation Type',
    name: InviteFormFieldsNames.Type,
    type: 'radio',
  },
  [InviteFormFieldsNames.NodeId]: {
    name: InviteFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [InviteFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const inviteTypeOptions = [
  { value: InviteType.OneTime, label: 'One Time Link' },
  { value: InviteType.JoinRequest, label: 'Join Request' },
];
