import { Menu } from '@mui/material';
import { memo, MouseEvent } from 'react';
import { EdgeProps, getBezierPath } from 'reactflow';
import { useMenu } from 'hooks';
import useEdgeClick from 'pages/app-pages/chatbot-builder-page/hooks/useEdgeClick';
import { addStepMenuProps } from 'pages/app-pages/chatbot-builder-page/nodes';
import StepSelector from 'pages/app-pages/chatbot-builder-page/nodes/input-node/StepSelector';
import { EdgeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { TourSteps } from 'pages/app-pages/chatbot-builder-page/tour/config';
import { useDirtyStore } from 'store';
import styles from './EdgeTypes.module.css';

const NEXT_COMMAND =
  'M259-200v-60h310q70 0 120.5-46.5T740-422q0-69-50.5-115.5T569-584H274l114 114-42 42-186-186 186-186 42 42-114 114h294q95 0 163.5 64T800-422q0 94-68.5 158T568-200H259Z';
const ADD_ICON = 'M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z';
const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  label,
  markerEnd,
}: EdgeProps) => {
  // see the hook for implementation details
  // onClick adds a node in between the nodes that are connected by this edge
  const onClick = useEdgeClick();

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const isString = (value: any): value is string => typeof value === 'string';

  const isCommand = isString(label) && label.startsWith('/');
  const prefixText = 'redirect to ';
  // Apply the prefix if it's a command
  const labelText = isCommand ? `${prefixText}${label}` : label;

  // Adjust the calculation for label width
  // You may need to experiment with these values for the best fit
  const baseWidth = 50; // Base width to start from
  const charWidth = isCommand ? 2.5 : 6; // Adjust character width assumption based on command or regular label
  const extraWidth = isCommand ? prefixText.length * charWidth : 3; // Adjust extra width for commands

  const labelWidth = isString(labelText) ? labelText.length * charWidth + extraWidth : baseWidth;

  const labelHeight = 20; // Fixed height, adjust as needed
  const adjustmentY = -labelHeight - 16; // Move up by the height of the label plus some margin

  const {
    anchorEl: stepMenuAnchorEl,
    closeMenu: closeStepMenu,
    isMenuOpen: isStepMenuOpen,
    openMenu: openStepMenu,
  } = useMenu();

  const { isSavingToServer } = useDirtyStore((state) => ({
    isSavingToServer: state.isSavingToServer,
  }));

  // Function to handle the "+" button click, which will open the menu
  const handlePlusButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    // Prevent the default context menu from opening
    event.preventDefault();
    // If the form is saving to the server, don't open the menu
    if (isSavingToServer) {
      return;
    }
    // Call openStepMenu with the event to set the menu's anchor position
    openStepMenu(event);
  };

  return (
    <>
      <path id={id} style={style} className={styles.edgePath} d={edgePath} markerEnd={markerEnd} />

      {isCommand ? (
        <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
          <rect
            x={-10}
            y={-10}
            width={20}
            ry={20}
            rx={10}
            height={20}
            className={styles.edgeButtonDisabled}
          />
          {/* Position the path (icon) relative to the rectangle. Adjust the translate values as needed. */}
          <path
            transform='translate(-8, 8) scale(0.016)' // Adjust scale value as needed
            d={NEXT_COMMAND}
            fill='currentColor' // Assuming you want to use the current text color
            className={styles.edgeButtonTextTerminated}
          />
        </g>
      ) : (
        <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
          <rect
            onClick={(e) => handlePlusButtonClick(e as unknown as MouseEvent<HTMLDivElement>)} // Attach this event handler
            x={-10}
            y={-10}
            width={20}
            ry={10}
            rx={10}
            height={20}
            className={styles.edgeButton}
          />
          <path
            transform='translate(-10, 10) scale(0.02)' // Adjust scale value as needed
            d={ADD_ICON}
            fill='currentColor' // Assuming you want to use the current text color
            className={isSavingToServer ? styles.edgeButtonTextDisabled : styles.edgeButtonText}
          />
        </g>
      )}

      {label && (
        <g transform={`translate(${edgeCenterX - labelWidth / 2}, ${edgeCenterY + adjustmentY})`}>
          <rect
            x={0}
            y={0}
            width={labelWidth}
            height={labelHeight}
            rx={2}
            ry={2}
            className={styles.edgeLabelBackground}
          />
          <text
            x={labelWidth / 2}
            y={labelHeight / 2 + 3} // Center text vertically within the label
            className={styles.edgeLabelText} // Add this class for text styling
            textAnchor='middle' // Centers the text horizontally
          >
            {labelText}
          </text>
        </g>
      )}
      <Menu
        {...addStepMenuProps}
        id='step-selector-menu'
        aria-labelledby='step-selector-menu'
        anchorEl={stepMenuAnchorEl}
        open={isStepMenuOpen}
        onClose={() => {
          closeStepMenu();
        }}
        PopoverClasses={{
          paper: TourSteps.StepSelect,
        }}
      >
        <StepSelector
          handleChange={async (step) => {
            closeStepMenu();
            await onClick(id, step);
          }}
          filterBySingleEdge={EdgeType.SingleEdge}
          title='Select option from the list to insert a new step between the nodes.
          ⚠️ Note: You can only add single edge steps here.'
        />
      </Menu>
    </>
  );
};

export default memo(CustomEdge);
