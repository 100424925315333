import { Stack, Typography } from '@mui/material';
import { useCopyToClipboard } from 'usehooks-ts';
import { Icon, IconButton } from '@common-components';
import IconWithTooltip from 'components/IconWithTooltip';
import { useToast } from 'hooks';

interface LinkBoxProps {
  value: string;
  icon?: string;
  copyText?: string;
  label: string;
  ctaIcon?: string;
  infoIcon?: boolean;
  hideCta?: boolean;
  helpText?: string;
}
const LinkBox = ({
  value,
  copyText,
  label,
  helpText,
  infoIcon,
  icon = 'link',
  ctaIcon = 'open_in_new',
  hideCta,
}: LinkBoxProps) => {
  const [, copy] = useCopyToClipboard();
  const toast = useToast();
  return (
    <Stack gap={0.5} width={1}>
      {(label || (helpText && infoIcon)) && (
        <Stack direction='row' alignItems='center'>
          {label && (
            <Typography px={1} noWrap variant='body2' color='text.primary'>
              {label}
            </Typography>
          )}
          {helpText && infoIcon && (
            <IconWithTooltip
              icon='info'
              tooltip={helpText}
              tooltipPlacement='top'
              tooltipProps={{ arrow: true }}
              iconProps={{ sx: { fontSize: 16 } }}
            />
          )}
        </Stack>
      )}

      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        border={1}
        borderRadius={0.5}
        borderColor='divider'
        px={1}
        py={0.321}
        bgcolor='grey.50'
      >
        <Icon icon={icon} fontSize='small' color='grey.600' />
        <Typography
          component={Stack}
          py={0.5}
          height={1}
          justifyContent='center'
          flex={1}
          noWrap
          variant='body2'
          color={copyText ? 'text.primary' : 'text.secondary'}
          sx={{
            cursor: copyText ? 'copy' : 'default',
          }}
          {...(copyText && {
            onClick: async () => {
              await copy(value);
              toast({
                message: copyText,
                variant: 'success',
              });
            },
          })}
        >
          {value}
        </Typography>
        {!hideCta && (
          <IconButton
            color='info'
            size='small'
            icon={ctaIcon}
            onClick={() => window.open(value, '_blank')}
          />
        )}
      </Stack>
      {helpText && !infoIcon && (
        <Typography px={1} variant='caption' color='text.secondary'>
          {helpText}
        </Typography>
      )}
    </Stack>
  );
};

export default LinkBox;
