import { AutoCompleteLabel } from 'types';

export enum RequestLocationFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Button = 'button',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface RequestLocationFormFields {
  [RequestLocationFormFieldsNames.NodeId]: string;
  [RequestLocationFormFieldsNames.Name]: string;
  [RequestLocationFormFieldsNames.Button]: string;
  [RequestLocationFormFieldsNames.Input]: string;
  [RequestLocationFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
