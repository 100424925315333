import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { Icon } from '@common-components';
import { api, ApiKey } from 'api';
import ConfirmationDialog from 'components/modals/ConfirmationDialog';
import { ExtendedUserRole, RoutePath } from 'enums';
import { appConfig } from 'forms/create-project/utils';
import {
  OptionsMenuState,
  useFeatureAccess,
  useGetProjects,
  useMenu,
  useServerError,
  useToast,
} from 'hooks';
import { menuMinWidth } from 'theme/utils';

interface BotItemMenuProps extends OptionsMenuState {
  id: string;
}
function ProjectDropdownMenu({ id, closeMenu, anchorEl, isMenuOpen }: BotItemMenuProps) {
  const navigate = useNavigate();

  const allowDelete = useFeatureAccess({
    minRole: ExtendedUserRole.Admin,
  });

  const { openMenu: openDeletePrompt, ...restDeletePrompt } = useMenu();

  const { projects } = useGetProjects();

  const project = projects.find((item) => item.id === id);

  const toastServerError = useServerError();
  const toast = useToast();

  const connectedAppConfig = Object.values(appConfig).filter(({ type }) =>
    project?.apps?.find((app) => app.type === type),
  );

  const {
    value: isDuplicating,
    setTrue: startDuplicating,
    setFalse: stopDuplicating,
  } = useBoolean(false);

  const deleteProject = async () => {
    try {
      await api.delete(`${ApiKey.Project}/${id}`);
      await mutate(ApiKey.Project);
      toast({
        message: `Project ${project?.name} deleted successfully`,
        variant: 'info',
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  const duplicateProject = async () => {
    try {
      startDuplicating();
      await api.post(`${ApiKey.Project}/duplicate`, {
        projectId: id,
      });
      await mutate(ApiKey.Project);
      stopDuplicating();
      toast({
        message: 'Bot duplicated successfully',
        variant: 'success',
      });
    } catch (error) {
      stopDuplicating();
      toastServerError(error);
    }
  };

  return (
    <>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        {Object.values(connectedAppConfig).map(({ icon, label, appUrl, cta }) => (
          <MenuItem
            key={label}
            onClick={() => {
              closeMenu();
              navigate(`/${appUrl}/${id}`);
            }}
          >
            <ListItemIcon>
              <Icon icon={icon} fontSize='small' />
            </ListItemIcon>
            <ListItemText>{cta}</ListItemText>
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate(`/${RoutePath.Activity}`, {
              state: {
                projectName: project?.name,
              },
            });
          }}
        >
          <ListItemIcon>
            <Icon icon='history' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Project Activity</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate(`${RoutePath.RenameProject}`, {
              state: {
                project,
              },
            });
          }}
        >
          <ListItemIcon>
            <Icon icon='edit_note' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Rename Project</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await duplicateProject();
            closeMenu();
          }}
        >
          <ListItemIcon>
            {isDuplicating ? (
              <CircularProgress color='inherit' size='1rem' />
            ) : (
              <Icon icon='content_copy' fontSize='small' />
            )}
          </ListItemIcon>
          <ListItemText>{isDuplicating ? 'Duplicating...' : 'Duplicate Project'}</ListItemText>
        </MenuItem>

        {allowDelete && (
          <>
            <Divider />
            <MenuItem onClick={openDeletePrompt}>
              <ListItemIcon>
                <Icon icon='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete Project</ListItemText>
            </MenuItem>
          </>
        )}
      </Menu>
      <ConfirmationDialog
        {...restDeletePrompt}
        submittingText='Deleting...'
        text='Are you sure you want to delete this project? All associated data and connected Apps will be deleted as well. This action cannot be undone.'
        onConfirm={async () => {
          await deleteProject();
          closeMenu();
        }}
      />
    </>
  );
}

export default ProjectDropdownMenu;
