import { AutoCompleteLabel } from 'types';

export enum InviteFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Button = 'button',
  ChannelId = 'channelId',
  Type = 'type',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export enum InviteType {
  OneTime = 'oneTime',
  JoinRequest = 'joinRequest',
}

export interface InviteFormFormFields {
  [InviteFormFieldsNames.NodeId]: string;
  [InviteFormFieldsNames.Name]: string;
  [InviteFormFieldsNames.Button]: string;
  [InviteFormFieldsNames.ChannelId]: string;
  [InviteFormFieldsNames.Type]: InviteType;
  [InviteFormFieldsNames.Input]: string;
  [InviteFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
