import { Box, Button, Stack, Typography } from '@mui/material';
import Icon from 'components/Icon';

const SuccessMessage = ({ onClick }: { onClick: () => void }) => (
  <Stack gap={3} alignItems='center'>
    <Icon icon='check_circle' fontSize='large' color='success' />
    <Typography variant='h5'>Thanks for sharing your idea!</Typography>
    <Typography variant='body1' align='center'>
      While our AI is learning new tricks every day, it seems we&apos;re not quite there yet for
      this type of bot.
      <br />
      <br />
      But don&apos;t worry – you can still bring your vision to life with our manual bot builder.
      It&apos;s intuitive, user-friendly, and gives you full control over the bot creation process.
    </Typography>
    <Button size='large' onClick={onClick} variant='contained' color='info'>
      <Box
        component='span'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        Let&apos;s get started!
      </Box>
    </Button>
  </Stack>
);

export default SuccessMessage;
