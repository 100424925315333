export enum PrivateChatBulkMessageStatus {
  Draft = 'Draft',
  Published = 'Published',
}

export enum ChatFilterDirection {
  Exclude = 'exclude',
  Include = 'include',
}

export enum ChatFilterMatching {
  All = 'all',
  Any = 'any',
}

export default PrivateChatBulkMessageStatus;
