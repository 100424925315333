import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import {
  SendEmailFormFields,
  SendEmailFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-email/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation } from 'utils';

export const sendEmailFormSchema: ObjectSchema<SendEmailFormFields> = object({
  [SendEmailFormFieldsNames.NodeId]: string().required(),
  [SendEmailFormFieldsNames.Name]: string().required('Name is required'),
  [SendEmailFormFieldsNames.Input]: editorValidation,
  [SendEmailFormFieldsNames.Subject]: string().required('Name is required'),
  [SendEmailFormFieldsNames.EmailAddress]: array()
    .of(string().email('Invalid email address').required('Email address is required'))
    .min(1, 'At least one valid email address is required')
    .test('general-error', 'Email Address Must be Valid', (value) => {
      if (!Array.isArray(value) || value.length === 0) {
        return true; // Indicate an error for no values or not an array
      }
      // Check if each element in the array is a valid email address
      return value.every((email) => string().email().isValidSync(email)); // Indicate an error if any email is invalid
    })
    .defined('At least one valid email address is required'),
  [SendEmailFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultSendEmailFormValues: SendEmailFormFields = {
  [SendEmailFormFieldsNames.NodeId]: '',
  [SendEmailFormFieldsNames.Name]: 'Send To Email',
  [SendEmailFormFieldsNames.Input]:
    '<p>Data from <span class="mention" data-index="0" data-denotation-char="@" data-id="firstName" data-value="First Name"><span contenteditable="false">@First Name</span></span></p>',
  [SendEmailFormFieldsNames.Subject]: '',
  [SendEmailFormFieldsNames.EmailAddress]: [],
  [SendEmailFormFieldsNames.Labels]: [],
};

export const sendEmailFormFieldsConfig: Record<SendEmailFormFieldsNames, FormFieldProps> = {
  [SendEmailFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: SendEmailFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [SendEmailFormFieldsNames.Input]: {
    label: '* Email Body',
    placeholder: 'Email Body',
    name: SendEmailFormFieldsNames.Input,
    type: 'text',
  },
  [SendEmailFormFieldsNames.Subject]: {
    placeholder: 'Email Subject',
    name: SendEmailFormFieldsNames.Subject,
    autoComplete: 'off',
    type: 'text',
  },
  [SendEmailFormFieldsNames.EmailAddress]: {
    placeholder: 'Add Email Address',
    name: SendEmailFormFieldsNames.EmailAddress,
    type: 'text',
  },
  [SendEmailFormFieldsNames.NodeId]: {
    name: SendEmailFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [SendEmailFormFieldsNames.Labels]: labelFormFieldConfig,
};
