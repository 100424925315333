import { AutoCompleteLabel } from 'types';

export enum FileStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  FileUrl = 'fileUrl',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface FileStepFormFields {
  [FileStepFormFieldsNames.NodeId]: string;
  [FileStepFormFieldsNames.Name]: string;
  [FileStepFormFieldsNames.FileUrl]: string;
  [FileStepFormFieldsNames.Input]?: string;
  [FileStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
