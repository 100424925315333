import { alpha, CircularProgress, Stack, Typography } from '@mui/material';
import { Icon } from 'components/index';
import { BasicColors } from 'theme';
import { selectCardProps } from './select-card-props';

interface SelectCardProps {
  name: string;
  icon: string;
  color: BasicColors;
  description: string;
  iconDescription?: string;
  onClick?: () => void;
  loading?: boolean;
  comingSoon?: boolean;
  disabled?: boolean;
}
const SelectCard = ({
  name,
  description,
  onClick,
  icon,
  iconDescription,
  color,
  loading,
  comingSoon,
  disabled,
}: SelectCardProps) => (
  <Stack
    {...selectCardProps({ disabled: comingSoon || disabled })}
    onClick={() => {
      if (!loading && !comingSoon && !disabled) {
        onClick?.();
      }
    }}
  >
    <Stack alignItems='center' px={1}>
      <Stack borderRadius={1} p={1} bgcolor={(theme) => alpha(theme.palette[color].light, 0.18)}>
        <Icon icon={icon} fontSize='small' color={color} />
      </Stack>

      {iconDescription && (
        <Typography variant='caption' fontWeight='bolder' noWrap>
          {iconDescription}
        </Typography>
      )}
    </Stack>
    <Stack flex={1} alignItems='flex-start'>
      <Typography variant='subtitle1'>{name}</Typography>
      <Typography variant='body2' color='text.secondary'>
        {description}
      </Typography>
    </Stack>
    {loading ? (
      <CircularProgress size={20} />
    ) : (
      <Icon icon={comingSoon ? '' : 'chevron_right'} color={comingSoon ? 'disabled' : 'primary'} />
    )}
  </Stack>
);

export default SelectCard;
