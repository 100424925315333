import { Stack } from '@mui/material';
import { useState } from 'react';
import { ConfirmationDialog } from 'components/index';
import { useAuth, useMenu } from 'hooks';
import NotificationIntegration from 'layout/app-header/NotificationIntegration';
import { SubmitButton } from 'pages/auth-pages/components';
import { User } from 'types';

interface TestBulkButtonProps {
  disabled: boolean;
  onClick: () => void;
  submitting: boolean;
}
const TestBulkButton = ({ disabled, onClick, submitting }: TestBulkButtonProps) => {
  const { openMenu, closeMenu, ...rest } = useMenu();
  const { user, setUser } = useAuth();

  const [localUser, setLocalUser] = useState<User | null>(user);
  const onLocalUserUpdate = (u: User) => {
    setUser?.(u);
    setLocalUser(u);
  };

  return (
    <>
      <SubmitButton
        submitting={submitting}
        disabled={disabled}
        size='large'
        variant='outlined'
        color='secondary'
        onClick={async (e) => {
          if (!localUser?.telegram?.id) {
            openMenu(e);
          } else {
            onClick();
          }
        }}
      >
        Preview
      </SubmitButton>
      <ConfirmationDialog
        hideCancelButton
        confirmText='Close'
        submitButtonColor='inherit'
        onConfirm={closeMenu}
        node={
          <Stack px={1} py={2} gap={1} width={320} alignItems='center'>
            <NotificationIntegration
              title='Activate "Test & Preview"'
              description={
                'You need to be subscribed to Telegram notifications to use the "Test & Preview" feature. Subscribe now to test and receive messages directly through Telegram!'
              }
              callback={closeMenu}
              updateUser={onLocalUserUpdate}
            />
          </Stack>
        }
        submittingText='Deleting...'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        closeMenu={closeMenu}
        {...rest}
      />
    </>
  );
};

export default TestBulkButton;
