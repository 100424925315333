import { Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { groupBy } from 'lodash';
import { Image } from 'mui-image';
import { useRef } from 'react';
import tutorialIcon from 'assets/images/gifs/youtubeIcon.gif';
import Icon from 'components/Icon';
import { StepType } from 'enums';
import { StepCategoryConfig, StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import StepSelectorItem from 'pages/app-pages/chatbot-builder-page/nodes/input-node/StepSelectorItem';
import {
  EdgeType,
  StepCategory,
  StepProps,
} from 'pages/app-pages/chatbot-builder-page/nodes/types';

interface StepSelectorProps {
  handleChange: (step: StepType) => void;
  currentStep?: StepType | null;
  filterBySingleEdge?: EdgeType;
  title?: string;
}
const StepSelector = ({
  handleChange,
  currentStep,
  filterBySingleEdge,
  title = 'Select option from the list to build the next step.',
}: StepSelectorProps) => {
  const steps = Object.values(StepsConfig);
  const filterStepsByEdgeTypeAndCategory = (stepsConfig: StepProps[], edgeTypeFilter?: EdgeType) =>
    Object.values(stepsConfig)
      .filter((step) => (edgeTypeFilter ? step.edgeType === edgeTypeFilter : true))
      .filter((step) => (filterBySingleEdge ? step.edgeType !== EdgeType.MultiEdge : true));

  const stepsByCategory = groupBy(
    filterStepsByEdgeTypeAndCategory(steps, filterBySingleEdge),
    'category',
  );

  const localStep = useRef<StepType | null | undefined>(currentStep);

  return (
    <Box>
      <Typography
        color={localStep.current ? 'error.main' : 'text.secondary'}
        variant='caption'
        fontWeight='bolder'
        component='div'
        p={2}
        pt={1}
      >
        {localStep.current
          ? '⚠️ Caution! By changing the type of this step, from split flow to a regular step or vice versa, you will lose all the data including the connections to this step.'
          : title}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Stack gap={3} px={2} py={1}>
        {Object.keys(stepsByCategory).map((category) => (
          <Stack key={category} direction='column'>
            <Stack
              direction='row'
              alignItems='center'
              gap={1}
              mb={StepCategoryConfig[category as StepCategory].tutorial ? 0.5 : 1}
              color='text.secondary'
            >
              <Icon
                icon={StepCategoryConfig[category as StepCategory].icon}
                color='inherit'
                fontSize='small'
              />
              <Typography variant='subtitle1' color='text.secondary'>
                {StepCategoryConfig[category as StepCategory].name}
              </Typography>
              {StepCategoryConfig[category as StepCategory].tutorial && (
                <Tooltip title='View Tutorial' arrow placement='top'>
                  <IconButton
                    color='primary'
                    size='small'
                    onClick={() => {
                      window.open(StepCategoryConfig[category as StepCategory].tutorial, '_blank');
                    }}
                  >
                    <Image width={24} duration={0} src={tutorialIcon} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <Stack flexWrap='wrap' direction='row' gap={1}>
              {stepsByCategory[category].map((step) => {
                const {
                  category: stepCategory,
                  description,
                  type,
                  name,
                  icon,
                } = StepsConfig[step.type];

                return (
                  <Box key={step.name} width={120}>
                    <StepSelectorItem
                      color={StepCategoryConfig[stepCategory].color}
                      label={name}
                      description={description}
                      icon={icon}
                      onClick={() => handleChange(type)}
                      focused={localStep.current === type}
                      type={type}
                      plan={StepsConfig[type]?.plan[0]}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default StepSelector;
