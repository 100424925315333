import { Chat, ChatTableProps } from 'types';

export const transformChatData = (data: Chat[]): ChatTableProps[] =>
  data.map((chat) => ({
    id: chat.id,
    languageCode: chat.languageCode,
    firstName: chat.firstName,
    lastName: chat.lastName,
    userName: chat.userName,
    createdAt: chat.createdAt,
    updatedAt: chat.updatedAt,
    projectName: chat.project?.name || '',
    projectId: chat.project?.id || '',
      conversationCount:chat.conversationCount,
    isRead: chat.isRead,
    isImportant: chat.isImportant,
    notifications: chat.notifications,
    note: chat.note,
    isBlocked: chat.isBlocked,
    labels: chat.labels,
    isArchived: chat.isArchived,
    assignee: chat.ticket?.assignee?.id,
    status: chat.ticket?.status,
  }));
