import {
  Box,
  CircularProgress,
  IconProps,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
} from '@mui/material';
import { ComponentProps, forwardRef } from 'react';
import Icon from 'components/Icon';
import { TooltipWrapperProps } from 'theme';

export interface IconButtonProps
  extends Omit<MuiIconButtonProps, 'children' | 'ref'>,
    TooltipWrapperProps {
  icon: string;
  iconProps?: Omit<IconProps, 'children' | 'ref'>;
  boxProps?: Omit<ComponentProps<typeof Box>, 'children' | 'ref'>;
  loading?: boolean;
}

const IconButton = forwardRef<HTMLDivElement, IconButtonProps>(
  (
    { tooltip = '', tooltipPlacement, icon, tooltipProps, iconProps, boxProps, loading, ...other },
    ref,
  ) => (
    <Tooltip title={tooltip} ref={ref} placement={tooltipPlacement} {...tooltipProps}>
      <Box {...boxProps}>
        <MuiIconButton {...other}>
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            <Icon icon={icon} fontSize={iconProps?.fontSize ?? 'small'} {...iconProps} />
          )}
        </MuiIconButton>
      </Box>
    </Tooltip>
  ),
);

export default IconButton;
