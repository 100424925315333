import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ReactCountryFlag from 'react-country-flag';
import { Icon } from 'components/index';

import { Chat } from 'types';
import { mapLanguageToCountry } from 'utils';

function UserDetailsCell({ formattedValue, row }: GridRenderCellParams<Chat>) {
  const name = `${formattedValue} ${row.lastName || ''}`;
  return (
    <Stack direction='row' width={1} gap={1.5} alignItems='center'>
      <Stack direction='row' gap={0.5} alignItems='center'>
        <ReactCountryFlag
          countryCode={mapLanguageToCountry(row.languageCode || '')}
          style={{
            fontSize: '1.1em',
            lineHeight: '1',
          }}
          aria-label={row.languageCode}
        />
        <Typography noWrap variant='body2' title={name} lineHeight={1}>
          {name}
        </Typography>
        <Stack direction='row' gap={0.5} alignItems='center'>
          {row.isBlocked && (
            <Tooltip title='User has blocked the bot' arrow placement='top'>
              <Icon icon='app_blocking' fontSize='small' color='error' />
            </Tooltip>
          )}
          {row.userName && (
            <Link
              href={`https://t.me/${row.userName}`}
              underline='none'
              variant='body2'
              fontWeight='bolder'
              noWrap
              title={`@${row.userName}`}
              target='_blank'
              lineHeight={1}
              onClick={(e) => e.stopPropagation()}
            >
              {`@${row.userName}`}
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default UserDetailsCell;
