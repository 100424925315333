import {
  Box,
  Chip,
  darken,
  Divider,
  lighten,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Icon from 'components/Icon';
import { Button, IconButton } from 'components/index';
import { ticketStatusConfig } from 'config/ticket-status.config';
import { TicketStatus } from 'enums';
import { useAppBarHeight, useIsMobile } from 'hooks';
import { ActivityTableProps, ChatColumns, ChatSortableKeys } from 'pages/activity-page/types';
import {
  iOS,
  toolboxDrawerConfig,
} from 'pages/app-pages/chatbot-builder-page/toolbox/props-config';
import { Chat } from 'types';
import { sortByBoolean, sortByDate } from 'utils';

export const sortByMenuItems: {
  field: ChatSortableKeys;
  label: string;
  sorter: (a: Chat, b: Chat) => number;
}[] = [
  {
    field: ChatColumns.UpdatedAt,
    label: 'Activity',
    sorter: (a, b) => sortByDate(a.updatedAt, b.updatedAt),
  },
  {
    field: ChatColumns.ProjectName,
    label: 'Alphabetical',
    sorter: (a, b) => a.project?.name.localeCompare(b.project?.name || '') || 0,
  },
  {
    field: ChatColumns.IsImportant,
    label: 'Importance',
    sorter: (a, b) => sortByBoolean(a.isImportant, b.isImportant),
  },
  {
    field: ChatColumns.IsRead,
    label: 'Read Status',
    sorter: (a, b) => sortByBoolean(a.isRead, b.isRead),
  },
];

const orderMenuItems: {
  field: GridSortDirection;
  label: { [key in ChatSortableKeys]: string };
  icon: { [key in ChatSortableKeys]: string };
}[] = [
  {
    field: 'asc',
    label: {
      [ChatColumns.ProjectName]: 'A-Z',
      [ChatColumns.UpdatedAt]: 'Newest',
      [ChatColumns.IsImportant]: 'No',
      [ChatColumns.IsRead]: 'Unread',
    },
    icon: {
      [ChatColumns.ProjectName]: 'sort_by_alpha',
      [ChatColumns.UpdatedAt]: 'calendar_clock',
      [ChatColumns.IsImportant]: 'star_outline',
      [ChatColumns.IsRead]: 'mark_unread_chat_alt',
    },
  },
  {
    field: 'desc',
    label: {
      [ChatColumns.ProjectName]: 'Z-A',
      [ChatColumns.UpdatedAt]: 'Oldest',
      [ChatColumns.IsImportant]: 'Yes',
      [ChatColumns.IsRead]: 'Read',
    },
    icon: {
      [ChatColumns.ProjectName]: 'sort_by_alpha',
      [ChatColumns.UpdatedAt]: 'calendar_clock',
      [ChatColumns.IsImportant]: 'stars',
      [ChatColumns.IsRead]: 'mark_chat_read',
    },
  },
];

interface ActivityDrawerProps
  extends Omit<ActivityTableProps, 'isLoading' | 'chatData' | 'filteredChatData' | 'projectName'> {
  isToolboxOpen: boolean;
  openToolbox: () => void;
  closeToolbox: () => void;
  chatCount: number;
  filteredChatCount: number;
  archivedChatCount: number;
}
const ActivityDrawer = ({
  isToolboxOpen,
  openToolbox,
  closeToolbox,
  userLabels,
  selectedLabels,
  setSelectedLabels,
  setIsImportantFilter,
  isImportantFilter,
  setSelectedProjectNames,
  projectNames,
  selectedProjectNames,
  chatCount,
  filteredChatCount,
  sortModel,
  onSortModelChange,
  archivedChatCount,
  selectedStatuses,
  setSelectedStatuses,
  selectedAssignees,
  setSelectedAssignees,
  assigneeOptions,
  showArchived,
  setShowArchived,
}: ActivityDrawerProps) => {
  const { isMobile } = useIsMobile();
  const appBarHeight = useAppBarHeight();

  const currentSortField = sortModel[0]?.field;
  const currentSortDirection = sortModel[0]?.sort;

  const chipSize = isMobile ? 'small' : 'medium';

  const isOtherFilterApplied =
    selectedProjectNames.length > 0 ||
    isImportantFilter ||
    selectedLabels.length > 0 ||
    (selectedAssignees?.length && selectedAssignees?.length > 0) ||
    (selectedStatuses && selectedStatuses?.length > 0);

  // Determine if the display should show the clear button and badge
  // Check against archivedChatCount to determine if only archived chats are filtered
  const shouldShowClearAndBadge =
    isOtherFilterApplied || (showArchived && filteredChatCount !== archivedChatCount);

  const getButtonTitle = () => {
    if (filteredChatCount === 0) {
      return 'No Chats';
    }
    if (filteredChatCount !== chatCount) {
      return `View (${filteredChatCount}/${chatCount})`;
    }

    return 'All Chats';
  };

  return (
    <SwipeableDrawer
      className='notranslate'
      disableBackdropTransition={!iOS}
      disableDiscovery
      disableSwipeToOpen
      open={isToolboxOpen}
      onOpen={openToolbox}
      onClose={closeToolbox}
      {...toolboxDrawerConfig({ isMobile, appBarHeight })}
    >
      <Stack height={1}>
        <Stack alignItems='flex-start' p={2} width={1}>
          <Stack width={1} direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='h6'>Sort And Filter</Typography>
            <Stack direction='row' alignItems='center' gap={1}>
              <IconButton icon='close' onClick={closeToolbox} edge='end' />
            </Stack>
          </Stack>
        </Stack>
        <Stack flex={1} overflow='auto'>
          <Box pb={2} height={1}>
            <Stack gap={4} mt={2} px={2} pb={2}>
              <Stack gap={1}>
                <Typography variant='subtitle2'>Projects</Typography>
                <Stack flexWrap='wrap' direction='row' gap={1}>
                  {projectNames.map((projectName) => (
                    <Chip
                      size={chipSize}
                      onClick={() => {
                        setSelectedProjectNames((prev) =>
                          prev.includes(projectName)
                            ? prev.filter((name) => name !== projectName)
                            : [...prev, projectName],
                        );
                      }}
                      variant={selectedProjectNames?.includes(projectName) ? 'outlined' : 'filled'}
                      key={projectName}
                      label={projectName}
                    />
                  ))}
                </Stack>
              </Stack>
              {selectedAssignees && setSelectedAssignees && (
                <Stack gap={1}>
                  <Typography variant='subtitle2'>Assignee</Typography>
                  <Stack flexWrap='wrap' direction='row' gap={1}>
                    {assigneeOptions?.map((user) => (
                      <Chip
                        key={user.id}
                        label={user.email}
                        size={chipSize}
                        clickable
                        color={
                          selectedAssignees.includes({
                            id: user.id,
                            email: user.email,
                          })
                            ? 'primary'
                            : 'default'
                        }
                        onClick={() => {
                          setSelectedAssignees((prev) => {
                            if (prev.some((assignee) => assignee.id === user.id)) {
                              return prev.filter((assignee) => assignee.id !== user.id);
                            }
                            return [...prev, user];
                          });
                        }}
                        variant={
                          selectedAssignees.some((assignee) => assignee.id === user.id)
                            ? 'outlined'
                            : 'filled'
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
              {selectedStatuses && setSelectedStatuses && (
                <Stack gap={1}>
                  <Typography variant='subtitle2'>Ticket Status</Typography>
                  <Stack flexWrap='wrap' direction='row' gap={1}>
                    {Object.values(TicketStatus).map((status) => (
                      <Chip
                        key={status}
                        size={chipSize}
                        label={ticketStatusConfig[status].name}
                        clickable
                        color={
                          selectedStatuses.includes(status)
                            ? ticketStatusConfig[status].color
                            : 'default'
                        }
                        onClick={() => {
                          setSelectedStatuses((prevStatuses) => {
                            if (prevStatuses.includes(status)) {
                              return prevStatuses.filter((s) => s !== status); // Remove status if already selected
                            }
                            return [...prevStatuses, status]; // Add status if not already selected
                          });
                        }}
                        icon={<Icon fontSize='small' icon={ticketStatusConfig[status].icon} />}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}

              <Stack gap={1}>
                <Typography variant='subtitle2'>Filters</Typography>
                <Stack flexWrap='wrap' direction='row' gap={1}>
                  <Chip
                    size={chipSize}
                    sx={{
                      bgcolor: isImportantFilter ? 'warning.light' : 'grey.200',
                      borderColor: (theme) =>
                        darken(
                          isImportantFilter ? theme.palette.warning.light : theme.palette.grey[200],
                          0.24,
                        ),
                      '&:hover': {
                        bgcolor: (theme) =>
                          lighten(
                            isImportantFilter
                              ? theme.palette.warning.light
                              : theme.palette.grey[200],
                            0.3,
                          ),
                      },
                    }}
                    label='Important'
                    icon={
                      <Icon fontSize='small' icon={isImportantFilter ? 'stars' : 'star_outline'} />
                    }
                    onClick={() => setIsImportantFilter(!isImportantFilter)}
                  />
                  <Divider orientation='vertical' flexItem variant='middle' />
                  <Chip
                    size={chipSize}
                    sx={{
                      bgcolor: showArchived ? 'info.light' : 'grey.200',
                      borderColor: (theme) =>
                        darken(
                          showArchived ? theme.palette.info.light : theme.palette.grey[200],
                          0.24,
                        ),
                      '&:hover': {
                        bgcolor: (theme) =>
                          lighten(
                            showArchived ? theme.palette.info.light : theme.palette.grey[200],
                            0.3,
                          ),
                      },
                    }}
                    label='Archived'
                    icon={
                      <Icon
                        fontSize='small'
                        icon={showArchived ? 'visibility' : 'visibility_off'}
                      />
                    }
                    onClick={() => setShowArchived(!showArchived)}
                  />
                  <Divider orientation='vertical' flexItem variant='middle' />
                  {userLabels.map((label) => (
                    <Chip
                      size={chipSize}
                      sx={{
                        bgcolor: label.color,
                        borderColor: darken(label.color || 'grey.200', 0.24),
                        '&:hover': {
                          bgcolor: `${lighten(label.color || 'grey.200', 0.3)} !important`,
                        },
                      }}
                      variant={
                        selectedLabels.some((labelObj) => labelObj.id === label.id)
                          ? 'outlined'
                          : 'filled'
                      }
                      icon={<Icon fontSize='small' icon={label.icon} />}
                      onClick={() => {
                        setSelectedLabels((prev) =>
                          prev.some((labelObj) => labelObj.id === label.id)
                            ? prev.filter((labelObj) => labelObj.id !== label.id)
                            : [...prev, label],
                        );
                      }}
                      key={label.id}
                      label={label.name}
                    />
                  ))}
                </Stack>
              </Stack>

              <Stack gap={1}>
                <Typography variant='subtitle2'>Sort</Typography>
                <Stack flexWrap='wrap' direction='row' gap={1}>
                  {sortByMenuItems.map((sortItem) =>
                    orderMenuItems.map((orderItem) => (
                      <Chip
                        size={chipSize}
                        key={`${sortItem.field}-${orderItem.field}`}
                        icon={<Icon icon={orderItem.icon[sortItem.field]} fontSize='small' />}
                        label={`${sortItem.label} (${orderItem.label[sortItem.field]})`}
                        variant={
                          currentSortField === sortItem.field &&
                          currentSortDirection === orderItem.field
                            ? 'outlined'
                            : 'filled'
                        }
                        onClick={() =>
                          onSortModelChange([{ field: sortItem.field, sort: orderItem.field }])
                        }
                      />
                    )),
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>

        <Stack direction='row' gap={1} p={2} justifyContent='flex-end'>
          {isMobile ? (
            <>
              {shouldShowClearAndBadge && (
                <Button
                  onClick={() => {
                    setSelectedLabels([]);
                    setIsImportantFilter(false);
                    setSelectedProjectNames([]);
                    setSelectedStatuses?.([]);
                    setSelectedAssignees?.([]);
                  }}
                >
                  Clear Filters
                </Button>
              )}

              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  closeToolbox();
                }}
              >
                {getButtonTitle()}
              </Button>
            </>
          ) : (
            <Button
              fullWidth
              variant='contained'
              disabled={!shouldShowClearAndBadge}
              color='primary'
              onClick={() => {
                setSelectedLabels([]);
                setIsImportantFilter(false);
                setSelectedProjectNames([]);
                setShowArchived(false);
                setSelectedStatuses?.([]);
                setSelectedAssignees?.([]);
              }}
            >
              Clear Filters
            </Button>
          )}
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ActivityDrawer;
