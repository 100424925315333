import { Divider, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { truncateMultiLineText } from 'utils';

interface SectionWrapperProps {
  children: ReactNode;
  title: string;
  cta?: ReactNode;
  disablePadding?: boolean;
}
const SectionWrapper = ({ children, title, cta, disablePadding }: SectionWrapperProps) => (
  <Stack
    borderRadius={1}
    border={1}
    borderColor='divider'
    bgcolor='background.default'
    position='relative'
    divider={<Divider />}
  >
    <Stack direction='row' alignItems='center' justifyContent='space-between' px={2} height={56}>
      <Typography
        variant='subtitle1'
        component='h1'
        sx={{
          ...truncateMultiLineText(1),
        }}
      >
        {title}
      </Typography>
      {cta}
    </Stack>

    <Stack p={disablePadding ? 0 : 3}>{children}</Stack>
  </Stack>
);

export default SectionWrapper;
