import { Box, Stack, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { Button, Icon, Label } from '@common-components';
import { api, ApiKey } from 'api';
import { ExtendedUserRole, RoutePath } from 'enums';
import { SingleTextFieldEditorFrom } from 'forms';
import { BotFormFieldsNames } from 'forms/rename-project/types';
import { projectUpdateDescriptionSchema } from 'forms/rename-project/utils';
import { useFeatureAccess, useServerError, useToast } from 'hooks';
import AppsIconsList from 'pages/projects-page/AppsIconsList';
import ProjectDropdownWrapper from 'pages/projects-page/ProjectDropdownWrapper';
import { Project } from 'types';

const GridCard = (project: Project & { hideDescription?: boolean }) => {
  const { id, name, description, updatedAt, hideDescription, apps } = project;
  const hasToken = !!project?.token;
  const toastServerError = useServerError();
  const toast = useToast();

  const allowSeeMenu = useFeatureAccess({
    minRole: ExtendedUserRole.Editor,
  });

  const onEdit = async (updateProps: Partial<Project>) => {
    try {
      await api.patch<Project>(`${ApiKey.Project}/${project?.id}`, updateProps);
      await mutate(ApiKey.Project);
      await mutate(`${ApiKey.Project}/${project?.id}`);
      toast({
        message: `Description updated successfully`,
        variant: 'success',
      });
    } catch (error: any) {
      toastServerError(error);
    }
  };

  const navigate = useNavigate();
  return (
    <Stack
      px={2}
      py={2}
      bgcolor='background.default'
      height={1}
      border={1}
      borderRadius={1}
      borderColor='divider'
      gap={2}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        gap={1}
        width={1}
      >
        <Stack width={1} overflow='hidden'>
          <Stack direction='row' alignItems='center' gap={1} width={1}>
            <Typography noWrap variant='subtitle1' title={name}>
              {name}
            </Typography>
            {hasToken && <Label color='success'>Published</Label>}
          </Stack>
          <Typography color='text.secondary' noWrap variant='caption' component='div'>
            modified {formatDistanceToNowStrict(new Date(updatedAt), { addSuffix: true })}
          </Typography>
        </Stack>
        {allowSeeMenu && <ProjectDropdownWrapper id={id} icon='more_vert' edge='end' />}
      </Stack>
      {!hideDescription && (
        <SingleTextFieldEditorFrom
          fieldProps={{
            name: BotFormFieldsNames.Description,
            placeholder: 'Project Description',
            autoFocus: true,
            textVariant: 'body2',
            multiline: true,
            fullWidth: true,
          }}
          defaultValues={{ description }}
          emptyStateText='No description provided'
          onSubmit={onEdit}
          schema={projectUpdateDescriptionSchema}
        />
      )}
      {allowSeeMenu && (
        <>
          <AppsIconsList id={id} apps={apps} projectName={name} isPublic={hasToken} />
          <Box alignSelf='flex-end'>
            <Button
              size='small'
              onClick={() => navigate(`/${RoutePath.ProjectManager}/${id}`)}
              variant='outlined'
              color='primary'
              endIcon={<Icon icon='arrow_forward' />}
            >
              View Project
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default GridCard;
