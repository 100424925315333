import { AutoCompleteLabel } from 'types';

export enum SendDataFormFieldsNames {
  Name = 'name',
  NodeId = 'nodeId',
  WebHookUrl = 'webHookUrl',
  Method = 'method',
  Format = 'format',
  PropFields = 'propFields',
  MagicPropFields = 'magicPropFields',
  Headers = 'headers',
  Labels = 'labels',
  Variable = 'variable',
}

export enum SendDataPropFieldsNames {
  Key = 'key',
  Value = 'value',
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export interface SendDataFormFields {
  [SendDataFormFieldsNames.NodeId]: string;
  [SendDataFormFieldsNames.Name]: string;
  [SendDataFormFieldsNames.WebHookUrl]: string;
  [SendDataFormFieldsNames.PropFields]: SendDataPropFields[];
  [SendDataFormFieldsNames.Headers]: SendDataPropFields[];
  [SendDataFormFieldsNames.Method]: Method;
  [SendDataFormFieldsNames.Format]: string;
  [SendDataFormFieldsNames.Variable]?: string;

  [SendDataFormFieldsNames.MagicPropFields]?: SendDataPropFields[];
  [SendDataFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export interface SendDataPropFields {
  [SendDataPropFieldsNames.Key]: string;
  [SendDataPropFieldsNames.Value]: string | null;
}
