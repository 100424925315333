import { Stack, Typography } from '@mui/material';
import { IconButton, IconButtonProps } from 'components/index';

export interface ToolboxHeaderProps {
  title: string;
  subtitle?: string;
  onClose?: () => void;
  additionalActions?: IconButtonProps[];
}

const ToolboxHeader = ({ title, subtitle, onClose, additionalActions }: ToolboxHeaderProps) => (
  <Stack alignItems='flex-start' p={2} width={1}>
    <Stack width={1} direction='row' alignItems='center' justifyContent='space-between'>
      <Typography variant='h6'>{title}</Typography>
      <Stack direction='row' alignItems='center' gap={1}>
        {additionalActions?.map((action) => (
          <IconButton edge='end' {...action} key={action.icon} />
        ))}
        {onClose && <IconButton icon='close' onClick={onClose} edge='end' />}
      </Stack>
    </Stack>
    {subtitle && (
      <Typography variant='body2' color='text.secondary'>
        {subtitle}
      </Typography>
    )}
  </Stack>
);

export default ToolboxHeader;
