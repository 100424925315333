import { Link, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { FormCheckBox, FormTextField } from '@common-components';
import { api, ApiKey } from 'api';
import { useAuth, useFormProvider, useServerError } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import { LoginFormFields } from 'pages/auth-pages/login/types';
import {
  defaultLoginFormValues,
  loginFormFieldsConfig,
  loginSchema,
} from 'pages/auth-pages/login/utils';
import { useDirtyStore } from 'store';

interface LoginFormProps {
  defaultValues?: Partial<LoginFormFields>;
}

const LoginForm = ({ defaultValues = defaultLoginFormValues }: LoginFormProps) => {
  const { login } = useAuth();
  const toastServerError = useServerError();
  const formMethods = useFormProvider<LoginFormFields>({
    schema: loginSchema,
    defaultValues,
  });

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = async (formData: LoginFormFields) => {
    setIsDirty(false);
    try {
      const response = await api.post(ApiKey.UserLogin, formData);
      login?.({
        ...response.data,
      });
    } catch (error) {
      toastServerError(error);
    }
  };
  // create promise to simulate async validation

  const { email, password, rememberMe } = loginFormFieldsConfig;

  return (
    <FormProvider {...formMethods}>
      <Stack width={1} gap={2} component='form' noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <FormTextField size='small' {...email} />
        <Stack width={1} gap={1}>
          <FormTextField size='small' {...password} />
          <Link
            variant='subtitle2'
            href='https://t.me/TeleformSupportBot'
            target='_blank'
            underline='none'
          >
            Forgot password?
          </Link>
        </Stack>
        <Stack width={1}>
          <FormCheckBox {...rememberMe} />
          <SubmitButton
            sx={{
              py: 2,
            }}
            variant='contained'
            color='primary'
            fullWidth
            type='submit'
            size='medium'
            submitting={formState.isSubmitting}
          >
            Login
          </SubmitButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default LoginForm;
