import { Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';

function TextCell({ formattedValue }: GridRenderCellParams<GridTreeNodeWithRender, string>) {
  return (
    <Typography noWrap variant='body2' title={formattedValue}>
      {formattedValue}
    </Typography>
  );
}

export default TextCell;
