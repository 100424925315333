import { DataGrid, GridRowClassNameParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomDataGridSlots from 'components/data-grid/CustomDataGridSlots';
import { RoutePath } from 'enums';
import ActivityCustomToolbar from 'pages/activity-page/activity-toolbar/ActivityCustomToolbar';
import { getUpdatedColumns } from 'pages/activity-page/columnsConfig';
import { tableStyle } from 'pages/activity-page/styles';
import { ActivityTableProps, ChatColumns } from 'pages/activity-page/types';
import { ChatTableProps } from 'types';

const columnsToHide = [ChatColumns.Assignee, ChatColumns.Status];

const ActivityTable = ({
  isLoading,
  chatData,
  sortModel,
  onSortModelChange,
  projectNames,
  setSelectedProjectNames,
  projectName,
  selectedProjectNames,
  setIsImportantFilter,
  isImportantFilter,
  userLabels,
  showArchived,
  selectedLabels,
  setSelectedLabels,
  filteredChatData,
  setShowArchived,
}: ActivityTableProps) => {
  const navigate = useNavigate();


  const updatedColumns = getUpdatedColumns(columnsToHide);

  return (
    <DataGrid
      loading={isLoading}
      rows={filteredChatData || []}
      columns={updatedColumns}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      rowHeight={70}
      sortingOrder={['asc', 'desc']}
      sx={tableStyle()}
      disableRowSelectionOnClick
      disableColumnSelector
      disableDensitySelector
      disableColumnFilter
      disableColumnMenu
      getRowClassName={(params: GridRowClassNameParams<ChatTableProps>) => {
        if (!params.row.isRead) {
          return 'unread-row';
        }
        if (params.row.isArchived) {
          return 'archived-row';
        }
        return 'chat-row';
      }}
      slots={{
        ...CustomDataGridSlots({
          noRowsOverlayProps: {
            text: 'Every chat conversation will appear here',
          },
        }),
        toolbar: ActivityCustomToolbar,
      }}
      onRowClick={({ row }) => {
        navigate(`${RoutePath.ChatActivity}/${row.id}`);
      }}
      slotProps={{
        toolbar: {
          projectNames,
          isImportantFilter,
          setIsImportantFilter,
          initialValue: projectName, // Set your initial value here
          userLabels,
          selectedLabels,
          setSelectedLabels,
          setSelectedProjectNames,
          selectedProjectNames,
          chatCount: chatData.length,
          archivedChatCount: chatData.filter((item) => item.isArchived).length,
          filteredChatCount: filteredChatData.length,
          sortModel,
          onSortModelChange,
          showArchived,
          setShowArchived,
          title: 'Chat Activity',
        },
      }}
    />
  );
};

export default ActivityTable;
