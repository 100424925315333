import { useAppBarHeight } from './useAppBarHeight';
import { useIsMobile } from './useIsMobile';

export const useTableHeight = ({ subtract }: { subtract: number }) => {
  const { isMobile } = useIsMobile();

  const appBarHeight = useAppBarHeight();

  const reduceHeight = appBarHeight + subtract + (isMobile ? 16 : 24);

  return `calc(var(--vh, 1vh) * 100 - ${reduceHeight}px)`;
};
