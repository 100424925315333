import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

const BulletWrapper = ({ children }: { children: ReactNode }) => (
  <Stack direction='row' alignItems='flex-start' gap={0.5}>
    <Typography variant='body1' component='span'>
      {'\u2022'}
    </Typography>
    {children}
  </Stack>
);

export default BulletWrapper;
