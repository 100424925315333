import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { ComponentType } from 'react';
import Icon from 'components/Icon';
import SearchField from 'components/SearchField';
import { ViewTypes } from 'enums';

type SearchBarProps = {
  sortModel: GridSortModel;
  onSortModelChange: (newSortModel: GridSortModel) => void;
  handleSearchChange: (value: string) => void;
  search: string;
  view?: ViewTypes;
  handleViewChange?: (value: ViewTypes) => void;
  SortBy: ComponentType<{
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
  }>;
};

const SearchBar = ({
  view,
  handleViewChange,
  sortModel,
  onSortModelChange,
  search,
  handleSearchChange,
  SortBy,
}: SearchBarProps) => (
  <Stack height={60} direction='row' alignItems='center' justifyContent='space-between'>
    <SearchField search={search} handleSearchChange={handleSearchChange} />
    <Stack direction='row' alignItems='center' gap={2}>
      <SortBy onSortModelChange={onSortModelChange} sortModel={sortModel} />
      {view && handleViewChange && (
        <ToggleButtonGroup
          color='primary'
          size='small'
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              border: 0,
              '&.Mui-disabled': {
                border: 0,
              },
              '&:not(:first-of-type)': {
                borderRadius: 1,
                ml: 0.5,
              },
              '&:first-of-type': {
                borderRadius: 1,
              },
            },
          }}
          value={view}
          exclusive
          onChange={(_, value) => {
            if (!value) {
              return;
            }
            handleViewChange(value);
          }}
          aria-label='text alignment'
        >
          <ToggleButton value='grid' aria-label='grid'>
            <Icon icon='grid_view' fontSize='small' />
          </ToggleButton>
          <ToggleButton value='list' aria-label='list'>
            <Icon icon='view_list' fontSize='small' />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  </Stack>
);

export default SearchBar;
