import { Box, Link, Typography } from '@mui/material';
import { Image } from 'mui-image';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormTextField, FormTextFieldInlineEdit } from '@common-components';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import {
  FileStepFormFields,
  FileStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/file/types';
import {
  defaultFileStepFormValues,
  fileFormFieldsConfig,
  fileStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/file/utils';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';

import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';

import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface MessageProps {
  id: string;
  defaultValues?: Partial<FileStepFormFields>;
  defaultName: string;
  mentions?: Mention[];
  commands?: Mention[];
}

const FileNode = ({ defaultValues, mentions, commands, id, defaultName }: MessageProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultFileStepFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const formMethods = useFormProvider<FileStepFormFields>({
    schema: fileStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<FileStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const processNewLabels = useProcessNewLabels();

  const { reset, formState, watch } = formMethods;

  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: FileStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const fileLink = watch(FileStepFormFieldsNames.FileUrl);

  const { name, input, labels, fileUrl } = fileFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />

        <FormTextField
          infoIcon={{
            tooltip: (
              <Box>
                Include a link to a publicly accessible image or file; for images, you might
                consider using{' '}
                <Link target='_blank' href='https://postimages.org/' rel='noreferrer'>
                  PostImage.
                </Link>
              </Box>
            ),

            icon: 'info',
          }}
          cta={{
            icon: 'add_photo_alternate',
            tooltip: 'Open PostImage to upload an image or file',
            onClick: () => {
              window.open('https://postimages.org/', '_blank');
            },
          }}
          {...fileUrl}
          size='small'
        />
        {fileLink && (
          <Box height={90} width='fit-content' mt={1} mb={5}>
            <Typography mb={1} color='text.secondary' variant='body2'>
              Preview
            </Typography>
            <Image
              errorIcon={false}
              title='Preview'
              duration={0}
              fit='contain'
              src={fileLink}
              alt='file'
            />
          </Box>
        )}

        <NodesFormTextEditor
          label='Caption'
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(FileNode);
