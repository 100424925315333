import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

function LinkCell({
  formattedValue,
  route,
  target,
}: GridRenderCellParams<GridTreeNodeWithRender, string> & {
  route: string;
  target?: '_blank' | '_self';
}) {
  return formattedValue ? (
    <Stack
      width={1}
      height={1}
      justifyContent='center'
      sx={{
        textDecoration: 'none',
        color: 'primary.main',
        cursor: 'pointer',
        '&:hover': {
          color: 'primary.dark',
        },
      }}
      component={Link}
      target={target}
      to={route}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography noWrap variant='subtitle2' title={formattedValue}>
        {formattedValue}
      </Typography>
    </Stack>
  ) : null;
}

export default LinkCell;
