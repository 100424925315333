import { Stack, Typography } from '@mui/material';

import { GridToolbarExport, GridToolbarProps } from '@mui/x-data-grid';
import { FC } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useIsMobile, useMount } from 'hooks';
import FilterToggleButton from 'pages/activity-page/activity-toolbar/FilterToggleButton';
import ToolbarSearchInput from 'pages/activity-page/activity-toolbar/ToolbarSearchInput';
import ActivityDrawer from 'pages/activity-page/ActivityDrawer';
import { ActivityTableProps, ChatColumns } from 'pages/activity-page/types';
import { mainBackground } from 'theme/utils';

interface CustomToolbarProps extends GridToolbarProps, ActivityTableProps {
  title: string;
  chatCount: number;
  archivedChatCount: number;
  filteredChatCount: number;
}
const ActivityCustomToolbar: FC<CustomToolbarProps> = ({
  setSelectedProjectNames,
  setIsImportantFilter,
  isImportantFilter,
  userLabels,
  selectedLabels,
  setSelectedLabels,
  selectedProjectNames,
  projectNames,
  chatCount,
  filteredChatCount,
  archivedChatCount,
  sortModel,
  onSortModelChange,
  title,
  selectedStatuses,
  setSelectedStatuses,
  selectedAssignees,
  setSelectedAssignees,
  assigneeOptions,
  showArchived,
  setShowArchived,
}) => {
  const { isSmallMobile } = useIsMobile();

  const { value: isDrawerOpen, setTrue: openDrawer, setFalse: closeDrawer } = useBoolean();

  useMount(() => {
    setSelectedProjectNames(selectedProjectNames);
  });

  return (
    <>
      <Stack sx={{ cursor: 'default' }}>
        <Stack
          direction='row'
          alignItems='flex-end'
          pb={2}
          justifyContent='space-between'
          height={isSmallMobile ? 70 : 100}
        >
          <Typography noWrap variant='h6' component='h1'>
            {title}
          </Typography>
          <GridToolbarExport
            csvOptions={{
              fileName: `chatbot_history${selectedProjectNames && `_${selectedProjectNames.join('_').toLowerCase().replace(/\s+/g, '_')}`}_${new Date().toLocaleDateString()}`,
              fields: [
                ChatColumns.ProjectName,
                ChatColumns.FirstName,
                ChatColumns.UserName,
                ChatColumns.LanguageCode,
                ChatColumns.Labels,
                ChatColumns.UpdatedAt,
              ],
            }}
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='flex-end'
          justifyContent='space-between'
          width={1}
          gap={1}
          bgcolor={mainBackground}
          py={1.5}
        >
          <ToolbarSearchInput />
          <FilterToggleButton
            setSelectedLabels={setSelectedLabels}
            setIsImportantFilter={setIsImportantFilter}
            setSelectedProjectNames={setSelectedProjectNames}
            setShowArchived={setShowArchived}
            setSelectedStatuses={setSelectedStatuses}
            setSelectedAssignees={setSelectedAssignees}
            chatCount={chatCount}
            filteredChatCount={filteredChatCount}
            archivedChatCount={archivedChatCount}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            userLabels={userLabels}
            selectedLabels={selectedLabels}
            selectedProjectNames={selectedProjectNames}
            projectNames={projectNames}
            isImportantFilter={isImportantFilter}
            showArchived={showArchived}
            selectedStatuses={selectedStatuses}
            selectedAssignees={selectedAssignees}
            assigneeOptions={assigneeOptions}
            closeDrawer={closeDrawer}
            openDrawer={openDrawer}
          />
        </Stack>
      </Stack>
      <ActivityDrawer
        closeToolbox={closeDrawer}
        isToolboxOpen={isDrawerOpen}
        openToolbox={openDrawer}
        setSelectedProjectNames={setSelectedProjectNames}
        userLabels={userLabels}
        selectedLabels={selectedLabels}
        setSelectedLabels={setSelectedLabels}
        isImportantFilter={isImportantFilter}
        setIsImportantFilter={setIsImportantFilter}
        selectedProjectNames={selectedProjectNames}
        projectNames={projectNames}
        chatCount={chatCount}
        filteredChatCount={filteredChatCount}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
        archivedChatCount={archivedChatCount}
        setSelectedStatuses={setSelectedStatuses}
        selectedStatuses={selectedStatuses}
        selectedAssignees={selectedAssignees}
        setSelectedAssignees={setSelectedAssignees}
        assigneeOptions={assigneeOptions}
      />
    </>
  );
};

export default ActivityCustomToolbar;
