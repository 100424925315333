import { array, date, object, ObjectSchema, string } from 'yup';
import {
  GroupMessageFormFields,
  GroupMessageFormFieldsNames,
  MessageTypes,
  MessageTypesConfig,
} from 'pages/app-pages/messages-scheduler-page/message-modal/types';
import { FormFieldProps } from 'types';
import {
  customYupValidateImageOrFile,
  customYupValidateURL,
  emptyEditorValue,
  isEmptyEditorContent,
} from 'utils';

const now = new Date();
const oneMinuteLater = new Date(now.getTime() + 60000);

export const messageSchema: ObjectSchema<
  Omit<GroupMessageFormFields, GroupMessageFormFieldsNames.Type>
> = object({
  [GroupMessageFormFieldsNames.Message]: string()
    .max(1024)
    .when('type', {
      is: 'message',
      then: (schema) =>
        schema
          .transform((value, originalValue) => (isEmptyEditorContent(originalValue) ? '' : value))
          .max(4096)
          .required('Field is required'),
    }),
  [GroupMessageFormFieldsNames.StartDate]: date()
    .min(oneMinuteLater, 'The date must be in the future')
    .optional(),
  [GroupMessageFormFieldsNames.Photo]: string()
    .default('')
    .when('type', {
      is: 'photo',
      then: (schema) => schema.required('Image url Required'),
    })
    .test('customYupValidateURL', 'customYupValidateURL', customYupValidateURL())
    .test(
      'customYupValidateImageOrFile',
      'customYupValidateImageOrFile',
      customYupValidateImageOrFile(),
    ),

  [GroupMessageFormFieldsNames.Project]: string().required('Project is required'),
  [GroupMessageFormFieldsNames.Groups]: array().min(1).required('Groups are required'),
});

export const messageDefaultValues: GroupMessageFormFields = {
  message: emptyEditorValue,
  photo: undefined,
  type: MessageTypes.Message,
  project: '',
  groups: [],
};

export const groupsMessagesFieldsConfig: Record<GroupMessageFormFieldsNames, FormFieldProps> = {
  [GroupMessageFormFieldsNames.Type]: {
    placeholder: 'type',
    name: GroupMessageFormFieldsNames.Type,
    label: 'Type',
    type: 'radio',
  },
  [GroupMessageFormFieldsNames.Message]: {
    placeholder: 'Message',
    name: GroupMessageFormFieldsNames.Message,
    autoComplete: 'off',
    type: 'text',
  },
  [GroupMessageFormFieldsNames.Photo]: {
    placeholder: 'https://example.com/image.jpg',
    name: GroupMessageFormFieldsNames.Photo,
    label: '* Image/File Url',
  },
  [GroupMessageFormFieldsNames.StartDate]: {
    label: 'Schedule Date',
    name: GroupMessageFormFieldsNames.StartDate,
    autoComplete: 'off',
    type: 'date',
  },
  [GroupMessageFormFieldsNames.Project]: {
    name: GroupMessageFormFieldsNames.Project,
    type: 'text',
  },
  [GroupMessageFormFieldsNames.Groups]: {
    label: '* Recipients Groups',
    placeholder: 'Select Groups',
    name: GroupMessageFormFieldsNames.Groups,
    type: 'select',
  },
};

export const messageTypesConfig: Record<MessageTypes, MessageTypesConfig> = {
  [MessageTypes.Message]: {
    value: MessageTypes.Message,
    label: 'Text Message',
  },
  [MessageTypes.Photo]: {
    value: MessageTypes.Photo,
    label: 'Image/File',
  },
};
