import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { isFuture } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { Icon } from '@common-components';
import { api, ApiKey } from 'api';
import ConfirmationDialog from 'components/modals/ConfirmationDialog';
import { RoutePath } from 'enums';
import { OptionsMenuState, useMenu, useServerError, useToast } from 'hooks';
import { useGetChatGroupMessages } from 'hooks/api/messenger/useGetChatGroupMessages';
import {
  FormMode,
  GroupMessageFormLocalState,
} from 'pages/app-pages/messages-scheduler-page/message-modal/types';
import useMessageStatus from 'pages/app-pages/messages-scheduler-page/useMessageStatus';
import { menuMinWidth } from 'theme/utils';
import { ChatGroupMessage, Project } from 'types';

interface BotItemMenuProps extends OptionsMenuState {
  id: string;
}
function MessageDropdownMenu({ id: messageId, closeMenu, anchorEl, isMenuOpen }: BotItemMenuProps) {
  const navigate = useNavigate();
  const { id } = useParams();

  const { openMenu: openDeletePrompt, ...restDeletePrompt } = useMenu();

  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const toastServerError = useServerError();
  const toast = useToast();
  const { chatGroupMessages } = useGetChatGroupMessages({ projectId: id || '' });

  const message = chatGroupMessages.find((item) => item.id === messageId);

  const { isDraftMessage, isExpiredMessage, isFutureMessage, isScheduleMessage } =
    useMessageStatus(message);

  const {
    value: publishing,
    setTrue: startPublishing,
    setFalse: stopPublishing,
  } = useBoolean(false);

  const deleteMessage = async () => {
    try {
      await api.delete(`${ApiKey.ChatGroupMessage}/${messageId}`);
      await mutate(`${ApiKey.ChatGroupMessage}?projectId=${id}`);
      toast({
        message: `Message deleted successfully`,
        variant: 'info',
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  const publishMessage = async () => {
    startPublishing();
    try {
      await api.post<ChatGroupMessage>(`${ApiKey.ChatGroupMessage}/publish`, {
        projectId: id,
        id: messageId,
      });

      await mutate(`${ApiKey.Project}/${id}`);
      await mutate(`${ApiKey.ChatGroupMessage}?projectId=${id}`);
      toast({
        message: 'Message published successfully',
        variant: 'info',
      });
      stopPublishing();
    } catch (error) {
      toastServerError(error);
      stopPublishing();
    }
  };

  return (
    <>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        {message && isScheduleMessage && isFuture(new Date(message?.startDate || '')) && (
          <MenuItem
            onClick={() => {
              closeMenu();
              navigate(RoutePath.Composer, {
                state: {
                  projectId: id,
                  mode: FormMode.Edit,
                  chatGroups: project?.chatGroups || [],
                  messageStatus: message.status,
                  messageId: message.id,
                  initialValues: {
                    project: id,
                    message: message?.message,
                    photo: message?.photo || message?.document,
                    startDate: new Date(message?.startDate || ''),
                    groups: message?.groups.map((item) => item.chatGroup.name) || [],
                  },
                } as GroupMessageFormLocalState,
              });
            }}
          >
            <ListItemIcon>
              <Icon icon='edit_note' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Edit Message</ListItemText>
          </MenuItem>
        )}

        {/* <MenuItem onClick={closeMenu}>
          <ListItemIcon>
            <Icon icon='insert_chart' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Statistics</ListItemText>
        </MenuItem> */}
        {isDraftMessage && !isExpiredMessage && (
          <MenuItem
            onClick={async () => {
              await publishMessage();
              closeMenu();
            }}
          >
            <ListItemIcon>
              {publishing ? (
                <CircularProgress color='inherit' size='1rem' />
              ) : (
                <Icon icon={isFutureMessage ? 'schedule_send' : 'send'} fontSize='small' />
              )}
            </ListItemIcon>
            {isFutureMessage ? (
              <ListItemText>{publishing ? 'Activating...' : 'Activate'}</ListItemText>
            ) : (
              <ListItemText>{publishing ? 'Sending...' : 'Send Now'}</ListItemText>
            )}
          </MenuItem>
        )}
        <MenuItem
          onClick={async () => {
            navigate(RoutePath.Composer, {
              replace: true,
              state: {
                projectId: id,
                mode: FormMode.Create,
                chatGroups: project?.chatGroups || [],
                initialValues: {
                  project: id,
                  photo: message?.photo || message?.document,
                  message: message?.message,
                },
              } as GroupMessageFormLocalState,
            });
            closeMenu();
          }}
        >
          <ListItemIcon>
            <Icon icon='content_copy' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={openDeletePrompt}>
          <ListItemIcon>
            <Icon icon='delete' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        {...restDeletePrompt}
        text='Are you sure you want to delete this message?'
        submittingText='Deleting...'
        onConfirm={async () => {
          await deleteMessage();
          closeMenu();
        }}
      />
    </>
  );
}

export default MessageDropdownMenu;
