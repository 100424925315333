import {
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Navigate,
  Route,
} from 'react-router-dom';
import { RoutePath } from 'enums';
import { AuthLayout, ProtectedRoute } from 'layout';

import { getUserData } from 'utils';
import { routesConfig } from './config/routes.config';

import { RouteConfig } from './types';
// Recursive function to render routes
function renderRoutes(routes: RouteConfig[], protect: boolean = false) {
  return routes.map(
    ({ path, component: Component, props, childRoutes, minUserRole, minUserPlan }) => {
      // Determine if the route should be protected based on the presence of role or plan requirements
      const shouldProtect = protect || minUserRole !== undefined || minUserPlan !== undefined;

      const element = shouldProtect ? (
        <ProtectedRoute minUserRole={minUserRole} minUserPlan={minUserPlan}>
          <Component {...props} />
        </ProtectedRoute>
      ) : (
        <Component {...props} />
      );

      return (
        <Route key={path || 'default'} path={path} element={element}>
          {childRoutes && renderRoutes(childRoutes, shouldProtect)}
        </Route>
      );
    },
  );
}

const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} loader={() => defer({ userPromise: getUserData() })}>
      {renderRoutes(routesConfig)}
      <Route path='*' element={<Navigate to='/not-found' replace />} />
      <Route path='*' element={<Navigate to={RoutePath.NotFound} replace />} />
    </Route>,
  ),
);

export default AppRouter;
