import { Chip, Stack, Tooltip } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';

import { Group } from 'types';

function GroupsCell({ formattedValue }: GridRenderCellParams<GridTreeNodeWithRender, Group[]>) {
  return (
    <Tooltip
      placement='top-start'
      title={formattedValue?.map((group: Group) => group.chatGroup.name).join(', ')}
    >
      <Stack direction='row' flexWrap='wrap' gap={1}>
        {formattedValue?.map((group: Group) => (
          <Chip size='small' key={group.id} label={group.chatGroup.name} />
        ))}
      </Stack>
    </Tooltip>
  );
}

export default GroupsCell;
