import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { FormAutocomplete } from 'components/form-fields/FormAutoComplete';
import { FormTextField } from 'components/index';
import FormArrayRemoveButton from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormArrayRemoveButton';

import { SendDataPropFieldsNames } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/types';
import { SendDataPropFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/utils';

const SendDataFormArrayField = ({
  getFieldName,
  index,
  remove,
  length,
  options,
}: FormArrayFieldProps & { options?: string[] }) => {
  const { name, label, ...rest } = SendDataPropFieldsConfig[SendDataPropFieldsNames.Value];

  const {
    name: keyName,
    label: keyLabel,
    ...keyRest
  } = SendDataPropFieldsConfig[SendDataPropFieldsNames.Key];

  const valueFieldName = getFieldName(name);
  const keyFieldName = getFieldName(keyName);

  return (
    <Stack direction='row' alignItems='flex-start' gap={0.5}>
      <Box flex={2}>
        <FormTextField name={keyFieldName} size='small' {...keyRest} />
      </Box>
      <Typography pt={0.75} variant='body2' color='text.secondary'>
        :
      </Typography>
      <Box flex={3}>
        <FormAutocomplete
          freeSolo
          autoSelect
          options={options || []}
          name={valueFieldName}
          size='small'
          {...rest}
        />
      </Box>
      <FormArrayRemoveButton onClick={() => remove(index)} disabled={length === 1} />
    </Stack>
  );
};

export default SendDataFormArrayField;
