import { useCallback } from 'react';
import { Edge, getOutgoers, Node, useReactFlow } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { useToast } from 'hooks';
import { useUndoRedoContext } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import { getNodesIds } from 'pages/app-pages/chatbot-builder-page/helpers';
import useConnectedEdgesAndNodes from 'pages/app-pages/chatbot-builder-page/hooks/useConnectedEdgesAndNodes';
import useSaveToServer from 'pages/app-pages/chatbot-builder-page/hooks/useSaveToServer';
import { useSetViewToNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSetViewToNode';
import useValidateTreeStructure from 'pages/app-pages/chatbot-builder-page/hooks/useValidateTreeStructure';
import { NodeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { useDirtyStore } from 'store';
import { BaseNodeProps, FormDataProps } from 'types';
import { truncateText } from 'utils';

const useUpdateNodeFormData = () => {
  const { getNodes, getEdges, getNode, setNodes, setEdges } = useReactFlow<BaseNodeProps>();
  const { takeSnapshot, undo } = useUndoRedoContext();
  const connectedEdgesAndNodes = useConnectedEdgesAndNodes();
  const saveToServer = useSaveToServer();
  const toast = useToast();
  const validateTreeStructure = useValidateTreeStructure();
  const setViewToNode = useSetViewToNode();

  const { closeToolbox, setEditorIsFocused, setSelectedNodeId, setSelectedStepType } =
    useBuilderStore((state) => ({
      closeToolbox: state.closeToolbox,
      setEditorIsFocused: state.setEditorIsFocused,
      setSelectedNodeId: state.setSelectedNodeId,
      setSelectedStepType: state.setSelectedStepType,
    }));

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  return useCallback(
    async ({
      id,
      formData,
      oldVariable,
    }: {
      id: string;
      formData: FormDataProps | undefined;
      oldVariable?: string;
    }): Promise<void> => {
      const node = getNode(id);

      if (!node) {
        return;
      }

      setIsDirty(false);

      const outGoersNodes = getOutgoers(node, getNodes(), getEdges());

      const outGoersIds = getNodesIds(outGoersNodes);

      const formDataNodeIds = formData?.menu?.map((menuItem) => menuItem.nodeId) || [
        formData?.nodeId,
      ];

      const formDataNodes = formData?.menu || [formData];

      const notInFormOutGoersIds = outGoersIds?.filter(
        (outGoerId) => !formDataNodeIds?.includes(outGoerId),
      );

      const outGoersIdsToKeep = outGoersIds?.filter((outGoerId) =>
        formDataNodeIds?.includes(outGoerId),
      );

      const allNotInFormOutGoersChild: {
        nodes: Node<BaseNodeProps>[];
        edges: Edge[];
      } = {
        nodes: [],
        edges: [],
      };

      notInFormOutGoersIds.forEach((outgoerId) => {
        const childResult = connectedEdgesAndNodes(outgoerId);
        allNotInFormOutGoersChild.nodes.push(...childResult.nodes);
        allNotInFormOutGoersChild.edges.push(...childResult.edges);
      });

      const newNodes = formDataNodes
        ?.filter((formDataItem) => !outGoersIdsToKeep?.includes(formDataItem.nodeId))
        .filter((formDataItem) => formDataItem.nodeId)
        .map(
          (formDataNode): Node<BaseNodeProps> => ({
            id: formDataNode.nodeId,
            position: { x: node.position.x, y: node.position.y },
            data: {
              label: '',
              step: formDataNode.step,
              selectedStep: null,
            },
            type: NodeType.Input,
            draggable: false,
          }),
        );

      const newEdges = formDataNodes
        ?.filter((formDataItem) => formDataItem.nodeId)
        .map(
          (formDataItem): Edge => ({
            id: `${uuid()}-${formDataItem.nodeId}`,
            source: id,
            target: formDataItem.nodeId,
            animated: formData?.menu && formData?.menu.length > 1,
            label: truncateText({
              input: formDataItem.option || '',
              length: 20,
            }),
            deletable: false,
          }),
        );

      takeSnapshot();

      setNodes((nodes) => {
        const updatedNodes = nodes.map((un) => {
          if (un.id === id) {
            return {
              ...un, // Spread the existing node
              data: {
                ...un.data, // Spread the existing node data
                formData, // Update formData
              },
            };
          }
          return un;
        });

        const newNo = updatedNodes
          .filter((n) => !allNotInFormOutGoersChild.nodes?.includes(n))
          .concat(newNodes);

        newNo.forEach((n) => {
          if (n.data?.mentions) {
            // Filter out the mentions by their ID
            n.data.mentions = n.data.mentions.filter((mention) => mention.id !== oldVariable);
          }
        });

        return newNo;
      });

      setEdges((edges) =>
        edges
          .filter((edge) => edge.source !== id)
          .filter((edge) => !allNotInFormOutGoersChild?.edges.includes(edge))
          .concat(newEdges),
      );

      if (!validateTreeStructure()) {
        undo();
        return;
      }

      setViewToNode(id, !!formData?.nodeId);
      setSelectedStepType(null);
      closeToolbox();
      setSelectedNodeId(null);
      setEditorIsFocused(false);
      await saveToServer();
      toast({ message: 'Node updated successfully', variant: 'success' });
    },
    [
      getNode,
      setIsDirty,
      getNodes,
      getEdges,
      takeSnapshot,
      setNodes,
      setEdges,
      validateTreeStructure,
      setViewToNode,
      setSelectedStepType,
      closeToolbox,
      setSelectedNodeId,
      setEditorIsFocused,
      saveToServer,
      toast,
      connectedEdgesAndNodes,
      undo,
    ],
  );
};

export default useUpdateNodeFormData;
