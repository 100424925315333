import { object, ObjectSchema } from 'yup';
import {
  ForgotPasswordFieldsNames,
  ForgotPasswordFormFields,
} from 'pages/auth-pages/forgot-password/types';
import { FormFieldProps } from 'types';
import { yupRequiredEmailValidation } from 'utils';

export const forgotPasswordSchema: ObjectSchema<ForgotPasswordFormFields> = object({
  [ForgotPasswordFieldsNames.Email]: yupRequiredEmailValidation,
});

export const defaultForgotPasswordFormValues: ForgotPasswordFormFields = {
  [ForgotPasswordFieldsNames.Email]: '',
};

export const forgotPasswordFormFieldsConfig: Record<ForgotPasswordFieldsNames, FormFieldProps> = {
  [ForgotPasswordFieldsNames.Email]: {
    placeholder: 'Email',
    name: ForgotPasswordFieldsNames.Email,
    autoComplete: 'email',
    type: 'email',
  },
};
