import { SxProps, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';

type TableStyleProps = {
  clickable?: boolean;
};

export const tableStyle = (props?: TableStyleProps): SxProps<Theme> => ({
  bgcolor: 'background.default',
  cursor: props?.clickable ? 'pointer' : 'default',
  [`& .${gridClasses.cell}:focus-within`]: {
    outline: 'none !important',
  },
  [`& .${gridClasses.columnHeader}`]: {
    outline: 'none !important',
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
  [`& .${gridClasses.row}`]: {
    '&.message-row': {
      backgroundColor: (theme) => alpha(theme.palette.info.light, 0.04),
    },
    '&.error-row': {
      backgroundColor: (theme) => alpha(theme.palette.error.light, 0.04),
    },
    '&.draft-row': {
      backgroundColor: (theme) => alpha(theme.palette.warning.light, 0.04),
    },
    '&.sent-row': {
      backgroundColor: (theme) => alpha(theme.palette.success.light, 0.04),
    },
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    typography: (theme) => ({ ...theme.typography.caption }),
  },
  [`& .${gridClasses.panelFooter}`]: {
    outline: 'none !important',
  },
});
