import { SxProps, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';

export const tableStyle = (): SxProps<Theme> => ({
  bgcolor: 'grey.50',
  width: '100%',
  cursor: 'pointer',
  border: 'none',
  [`& .${gridClasses.main}`]: {
    bgcolor: 'background.default',
    boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.grey[200]}`,
    borderRadius: (theme) => `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    borderBottom: 'none',
  },
  [`& .${gridClasses.cell}:focus-within`]: {
    outline: 'none !important',
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
  [`& .${gridClasses.footerContainer}`]: {
    bgcolor: 'background.default',
    border: '1px solid',
    borderColor: 'grey.200',
    borderRadius: (theme) => `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    borderTop: 'none',
  },
  [`& .${gridClasses.columnHeader}`]: {
    outline: 'none !important',
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    typography: (theme) => ({ ...theme.typography.caption }),
  },
  [`& .${gridClasses.panelFooter}`]: {
    outline: 'none !important',
  },
  [`& .${gridClasses.row}`]: {
    '&.unread-row': {
      backgroundColor: (theme) => alpha(theme.palette.info.light, 0.06),
    },
    '&.archived-row': {
      backgroundColor: (theme) => alpha(theme.palette.warning.light, 0.06),
    },
  },
});
