import { Collapse, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

interface FormCollapseHelperTextProps {
  error?: FieldError;
  helperText?: ReactNode | string;
}
const FormCollapseHelperText = ({ error, helperText }: FormCollapseHelperTextProps) => (
  <Collapse in={!!error || !!helperText}>
    <FormHelperText variant='outlined' error={!!error}>
      {error !== undefined ? error.message : helperText}
    </FormHelperText>
  </Collapse>
);

export default FormCollapseHelperText;
