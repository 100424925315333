import { Box, ButtonGroup, Stack, Tooltip, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { GroupButtonItem, Icon } from 'components/index';
import { AppTypes, RoutePath } from 'enums';
import { appConfig } from 'forms/create-project/utils';
import {
  useAppBarHeight,
  useIsMobile,
  useMenu,
  usePauseApp,
  useServerError,
  useToast,
} from 'hooks';
import messages from 'messages';
import useAllNodesValidation from 'pages/app-pages/chatbot-builder-page/hooks/useAllNodesValidation';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import CommandsMenu from 'pages/app-pages/chatbot-builder-page/top-bar/CommandsMenu';
import AppsSwitcher from 'pages/app-pages/components/AppsSwitcher';
import { Project } from 'types';

interface BuilderBarProps {
  name: string;
}

const BuilderTopBar = ({ name }: BuilderBarProps) => {
  const navigate = useNavigate();
  const { isMobile, isSmallMobile } = useIsMobile();
  const appBarHeight = useAppBarHeight();
  const toast = useToast();
  const toastServerError = useServerError();
  const { id } = useParams();
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const chatbotIsActive = !!project?.apps.find((app) => app.type === AppTypes.Chatbot)?.isActive;
  const {
    saving,
    selectedCommand,
    publishedLastUpdatedAt,
    draftLastUpdatedAt,
    setPublishedLastUpdatedAt,
  } = useBuilderStore((state) => ({
    selectedCommand: state.selectedCommand,
    saving: state.saving,
    draftLastUpdatedAt: state.draftLastUpdatedAt,
    publishedLastUpdatedAt: state.publishedLastUpdatedAt,
    setPublishedLastUpdatedAt: state.setPublishedLastUpdatedAt,
  }));

  const [isAllValid, errorMessage] = useAllNodesValidation();

  const draftDate = useMemo(
    () => new Date(draftLastUpdatedAt || project?.bot?.draft?.updatedAt || ''),
    [draftLastUpdatedAt, project?.bot?.draft?.updatedAt],
  );

  const publishDate = useMemo(
    () => new Date(publishedLastUpdatedAt || project?.bot?.published?.updatedAt || ''),
    [publishedLastUpdatedAt, project?.bot?.published?.updatedAt],
  );

  const publishIcon = useMemo(() => {
    if (!project?.bot?.published) {
      return 'rocket';
    }
    if (project?.bot?.published && draftDate > publishDate) {
      return 'published_with_changes';
    }
    return 'done_all';
  }, [project?.bot?.published, draftDate, publishDate]);

  const shouldShowPublishWarning = project?.bot?.published && draftDate > publishDate;

  const commandsMenuState = useMenu();

  const {
    value: publishing,
    setTrue: startPublishing,
    setFalse: stopPublishing,
  } = useBoolean(false);

  const onPublish = async () => {
    if (!isAllValid) {
      toast({ message: errorMessage, variant: 'error' });
      return;
    }

    if (!project?.token) {
      navigate(`/${RoutePath.ChatbotBuilder}/${id}/${RoutePath.Publish}`, {
        replace: true,
      });
      return;
    }
    startPublishing();
    try {
      await api.post(`${ApiKey.Project}/${id}/publish`, {
        appType: AppTypes.Chatbot,
      });
      const response = await mutate<Project>(`${ApiKey.Project}/${id}`);
      setPublishedLastUpdatedAt(response?.bot?.published?.updatedAt || null);
      toast({ message: messages.builder.toast.publishSuccess, variant: 'success' });
      stopPublishing();
    } catch (error) {
      toastServerError(error);
      stopPublishing();
    }
  };

  const { pausing, togglePause } = usePauseApp({ project, appType: AppTypes.Chatbot });

  return (
    <>
      <Stack
        bgcolor='chatbot.dark'
        direction='row'
        alignItems='center'
        width={1}
        p={2}
        gap={2}
        color='primary.contrastText'
        height={appBarHeight}
      >
        <Box flex={isMobile ? 'inherit' : 1}>
          <Stack
            direction='row'
            gap={1}
            alignItems='center'
            justifyContent='flex-start'
            overflow='hidden'
          >
            <AppsSwitcher currentApp={AppTypes.Chatbot} />
            {!isMobile && (
              <Typography noWrap variant='subtitle2'>
                {appConfig[AppTypes.Chatbot].label}
              </Typography>
            )}
          </Stack>
        </Box>
        <Stack
          direction='row'
          onClick={commandsMenuState.openMenu}
          gap={1}
          alignItems={isMobile ? 'flex-start' : 'center'}
          overflow='hidden'
          sx={{ cursor: 'pointer' }}
        >
          <Typography noWrap variant={isMobile ? 'subtitle2' : 'subtitle1'}>
            {name} / {selectedCommand?.name}
          </Typography>
          <Icon icon='expand_more' fontSize='small' />
        </Stack>
        <Stack justifyContent='flex-end' flex={1} direction='row' gap={2} alignItems='center'>
          <Tooltip
            title={saving ? messages.general.saving : messages.general.changesSaved}
            placement='top'
            arrow
          >
            <Icon icon={saving ? 'cloud_sync' : 'cloud_done'} color='inherit' />
          </Tooltip>
          <Stack direction='row' gap={2}>
            {!isSmallMobile && project?.token && (
              <ButtonGroup color='inherit' variant='outlined' size='small'>
                <GroupButtonItem
                  iconColor={chatbotIsActive ? 'inherit' : 'grey.800'}
                  disabled={!project?.bot?.published}
                  tooltip={chatbotIsActive ? 'Deactivate Bot' : 'Activate Bot'}
                  onClick={async () => {
                    await togglePause();
                  }}
                  icon='power_settings_new'
                  loading={pausing}
                />
              </ButtonGroup>
            )}
            <ButtonGroup color='inherit' variant='outlined' size='small'>
              <GroupButtonItem
                disabled={saving}
                loading={publishing}
                tooltip={
                  shouldShowPublishWarning ? 'Don’t forget to publish your changes' : 'Publish Bot'
                }
                onClick={onPublish}
                label='Publish'
                icon={publishIcon}
                iconSx={{
                  ...(shouldShowPublishWarning && {
                    animation: 'myAnim 1s ease 1s infinite normal forwards',
                    '@keyframes myAnim': {
                      '0%': {
                        transform: 'scale(1)',
                      },
                      '50%': {
                        transform: 'scale(1.05)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                      },
                    },
                  }),
                }}
              />
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>
      <CommandsMenu {...commandsMenuState} />
    </>
  );
};

export default memo(BuilderTopBar);
