import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import { useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { Outlet, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean, useLocalStorage } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import Button from 'components/Button';
import { Label } from 'components/index';
import { LocalStorageKey, planConfig } from 'config';
import { AccountPlanType, ExtendedUserRole, RoutePath } from 'enums';
import { SingleTextFieldEditorFrom } from 'forms';
import { useAuth, useFeatureAccess, useServerError, useTableHeight, useToast } from 'hooks';
import { HeaderWithBreadcrumbs } from 'layout';
import AvatarIcon from 'layout/app-header/AvatarIcon';
import SubmitButton from 'pages/auth-pages/components/SubmitButton';
import {
  accountPlansConfig,
  AccountPlansConfig,
} from 'pages/manage-account-page/account-plans-config';
import SectionWrapper from 'pages/manage-account-page/SectionWrapper';
import { ProfileFormFieldsNames } from 'pages/manage-account-page/types';
import UsageBox from 'pages/manage-account-page/UsageBox';
import { profileSchema, useApplyPaypalSubscription } from 'pages/manage-account-page/utils';
import { User } from 'types';
import { calculateColorTemperature, planIsOnGoing } from 'utils';

const ManageAccountPage = () => {
  const gridTableHeight = useTableHeight({ subtract: -24 });
  const { user, setUser } = useAuth();
  const applyPaypalSubscription = useApplyPaypalSubscription();
  const toastServerError = useServerError();
  const toast = useToast();
  const navigate = useNavigate();
  const [, setShowCoupon] = useLocalStorage(LocalStorageKey.ShowCoupon, false);
  const [paypalLoaded, setPaypalLoaded] = useState(false);

  const {
    value: requestingCancellation,
    setTrue: startRequestingCancellation,
    setFalse: stopRequestingCancellation,
  } = useBoolean();

  const hasAccessToEditPlan = useFeatureAccess({
    minRole: ExtendedUserRole.Owner,
  });

  const hasAccessToProPlan = useFeatureAccess({
    minPlan: AccountPlanType.Pro,
  });

  const hasAccessToPremiumPlan = useFeatureAccess({
    minPlan: AccountPlanType.Premium,
  });

  const plan = user?.organization?.accountPlan?.type || AccountPlanType.Basic;

  const getPlansToOffer = () => {
    const plansToOffer: AccountPlansConfig[] = [];
    if (
      !hasAccessToProPlan ||
      (user?.organization?.accountPlan?.endDate &&
        !planIsOnGoing(user?.organization?.accountPlan?.endDate))
    ) {
      plansToOffer.push(accountPlansConfig[AccountPlanType.Pro]!);
    }
    if (
      !hasAccessToPremiumPlan ||
      (user?.organization?.accountPlan?.endDate &&
        !planIsOnGoing(user?.organization?.accountPlan?.endDate))
    ) {
      plansToOffer.push(accountPlansConfig[AccountPlanType.Premium]!);
    }
    return plansToOffer;
  };

  const plansToOffer = getPlansToOffer();

  const onEdit = async (updateProps: Partial<User>, hideToast?: boolean) => {
    try {
      const res = await api.patch<User>(`${ApiKey.Users}/${user?.id}`, updateProps);
      await mutate(ApiKey.User);
      await mutate(`${ApiKey.Users}/${user?.id}`);
      setUser?.(res.data);
      if (!hideToast) {
        toast({
          message: `Profile updated successfully`,
          variant: 'success',
        });
      }
    } catch (error: any) {
      toastServerError(error);
    }
  };

  if (!user) {
    return null;
  }
  return (
    <>
      <Container maxWidth='md'>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          height={{
            xs: 'auto',
            md: gridTableHeight,
          }}
          gap={{
            xs: 0,
            md: 8,
          }}
        >
          <Stack flex={1}>
            <HeaderWithBreadcrumbs
              breadcrumbs={[
                { name: 'home', url: `/${RoutePath.Dashboard}` },
                { name: 'manage account' },
              ]}
            />
            <Grid container spacing={{ xs: 2, md: 3 }} pb={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <SectionWrapper title='Profile Details'>
                  <Stack
                    gap={{
                      xs: 2,
                      md: 3,
                    }}
                    direction={{
                      xs: 'column',
                      md: 'row',
                    }}
                  >
                    <Box mb={1}>
                      <AvatarIcon
                        size={54}
                        fullName={user.fullName}
                        profileImageUrl={user.profileImageUrl}
                      />
                    </Box>
                    <Stack flex={1}>
                      <Typography variant='body1'>Full Name</Typography>
                      <Stack height={30} justifyContent='center'>
                        <SingleTextFieldEditorFrom
                          disabled={!hasAccessToEditPlan}
                          boxProps={{ flex: 'auto', display: 'flex' }}
                          fieldProps={{
                            name: ProfileFormFieldsNames.FullName,
                            placeholder: 'Project Description',
                            autoFocus: true,
                            textVariant: 'body2',
                            multiline: true,
                            fullWidth: true,
                          }}
                          defaultValues={{ fullName: user.fullName || '' }}
                          emptyStateText='No description provided'
                          onSubmit={onEdit}
                          schema={profileSchema}
                        />
                      </Stack>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant='body1'>Email</Typography>
                      <Stack height={30} justifyContent='center'>
                        <Typography variant='body2' color='textSecondary'>
                          {user.email}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  {user.telegram?.id && (
                    <Typography mt={2} variant='body2' color='textSecondary'>
                      You are actively subscribed to receive chat notifications via Telegram.
                    </Typography>
                  )}
                </SectionWrapper>
              </Grid>

              {((hasAccessToEditPlan && !hasAccessToProPlan) ||
                (hasAccessToEditPlan && !hasAccessToPremiumPlan) ||
                (user?.organization?.accountPlan?.endDate &&
                  !planIsOnGoing(user?.organization?.accountPlan?.endDate))) && (
                <Grid item xs={12}>
                  <SectionWrapper title='Upgrade Options'>
                    <Stack width={1} gap={4}>
                      {plansToOffer.map((planToOffer, index) =>
                        paypalLoaded || index === 0 ? (
                          <Stack
                            key={planToOffer.title}
                            gap={{
                              xs: 2,
                              md: 3,
                            }}
                            direction={{
                              xs: 'column',
                              md: 'row',
                            }}
                          >
                            <Stack
                              width={1}
                              direction={{ xs: 'column', md: 'row' }}
                              justifyContent='space-between'
                              alignItems={{ xs: 'stretch', md: 'center' }}
                              gap={{ xs: 4, md: 6 }}
                            >
                              <Stack flex={7} alignItems='flex-start' alignSelf='flex-start'>
                                <Typography variant='h4'>{planToOffer.title}</Typography>
                                <Typography variant='subtitle1'>{planToOffer.subtitle}</Typography>

                                <Typography variant='body2'>{planToOffer.body}</Typography>
                              </Stack>
                              <Stack
                                flex={3}
                                gap={2}
                                direction='row'
                                alignItems='center'
                                justifyContent='center'
                                bgcolor='grey.100'
                                p={2}
                                borderRadius={1}
                              >
                                <Stack
                                  width={1}
                                  maxWidth={320}
                                  justifyContent='center'
                                  alignItems='center'
                                  gap={1}
                                >
                                  <Typography variant='caption' color='textSecondary'>
                                    {planToOffer.caption}
                                  </Typography>
                                  <PayPalButton
                                    options={{
                                      vault: true,
                                      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                    }}
                                    onButtonReady={() => {
                                      if (!paypalLoaded) {
                                        setPaypalLoaded(true);
                                      }
                                    }}
                                    createSubscription={(
                                      _: any,
                                      actions: {
                                        subscription: {
                                          create: (arg0: {
                                            plan_id: string;
                                            custom_id: string;
                                          }) => any;
                                        };
                                      },
                                    ) =>
                                      actions.subscription.create({
                                        plan_id: planToOffer.planId,
                                        custom_id: user.organization!.id.toString(),
                                      })
                                    }
                                    onApprove={(
                                      data: { orderID: any; subscriptionID: any },
                                      actions: { subscription: { get: () => Promise<any> } },
                                    ) =>
                                      // Capture the funds from the transaction
                                      actions.subscription.get().then(() => {
                                        applyPaypalSubscription(data.subscriptionID);
                                      })
                                    }
                                  />
                                </Stack>
                              </Stack>
                            </Stack>
                          </Stack>
                        ) : null,
                      )}
                    </Stack>
                  </SectionWrapper>
                </Grid>
              )}

              {user.organization && (
                <Grid item xs={12}>
                  <SectionWrapper title='Active Plan'>
                    <Stack
                      mb={2}
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent='space-between'
                      alignItems={{ xs: 'stretch', md: 'center' }}
                      gap={{ xs: 2, md: 4 }}
                    >
                      <Stack flex={5} alignItems='flex-start'>
                        <Typography variant='h6'>{planConfig[plan].name} Plan</Typography>
                        <Typography variant='body2'>
                          You are currently on the {planConfig[plan].name} plan
                          {user?.organization.accountPlan?.coupon?.provider
                            ? ` via ${user?.organization.accountPlan?.coupon?.provider}.`
                            : '.'}
                          {plan === AccountPlanType.Basic
                            ? ' Upgrade to get more features.'
                            : ' Enjoy all features.'}
                        </Typography>
                        {plan !== AccountPlanType.Basic &&
                          user.organization.accountPlan?.endDate && (
                            <Box my={0.5}>
                              <Label color='primary'>
                                Ends on{' '}
                                {format(new Date(user.organization.accountPlan?.endDate), 'PP')}
                              </Label>
                            </Box>
                          )}
                      </Stack>
                      <Stack
                        flex={4}
                        gap={2}
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        bgcolor='grey.100'
                        p={2}
                        borderRadius={1}
                      >
                        {hasAccessToEditPlan ? (
                          <>
                            <Stack width={1} justifyContent='space-between'>
                              <Typography variant='subtitle2'>
                                {plan === AccountPlanType.Basic
                                  ? 'Upgrade to Pro Plan 🚀'
                                  : `${planConfig[plan].name} Plan Active 🚀`}
                              </Typography>
                              <Typography variant='caption' color='text.secondary'>
                                {plan === AccountPlanType.Basic
                                  ? '10,000 Chats, All Features and more. No credit card required.'
                                  : `You have all the features of the ${planConfig[plan].name} Plan.`}
                              </Typography>
                            </Stack>
                            <Stack gap={1} alignItems='center'>
                              {(plan === AccountPlanType.Basic ||
                                (user.organization.accountPlan?.subscription &&
                                  !user.organization.accountPlan.endDate)) && (
                                <SubmitButton
                                  submitting={requestingCancellation}
                                  variant='contained'
                                  onClick={async () => {
                                    if (!user.organization?.accountPlan?.subscription) {
                                      window.open('https://t.me/TeleformProCouponBot', '_blank');
                                    } else {
                                      try {
                                        startRequestingCancellation();
                                        const res = await api.post(
                                          `${ApiKey.Organization}/paypal-subscription/cancel`,
                                          {
                                            organizationId: user.organization?.id,
                                          },
                                        );
                                        await mutate(ApiKey.User);

                                        await mutate(`${ApiKey.Users}/${user?.id}`);

                                        setUser?.(res.data);
                                        toast({
                                          message: 'Your plan has been cancelled.',
                                          variant: 'success',
                                        });
                                      } catch (error: any) {
                                        toastServerError(error);
                                      } finally {
                                        stopRequestingCancellation();
                                      }
                                    }
                                  }}
                                  color={
                                    !user.organization?.accountPlan?.subscription
                                      ? 'primary'
                                      : 'error'
                                  }
                                >
                                  {!user.organization?.accountPlan?.subscription
                                    ? 'Start Trial'
                                    : 'Cancel Plan'}
                                </SubmitButton>
                              )}

                              {plan === AccountPlanType.Basic && (
                                <Button
                                  size='small'
                                  variant='text'
                                  onClick={async () => {
                                    setShowCoupon(true);
                                    navigate(`${RoutePath.ApplyCoupon}`);
                                  }}
                                >
                                  Apply Coupon
                                </Button>
                              )}
                            </Stack>
                          </>
                        ) : (
                          <Stack width={1} justifyContent='space-between'>
                            <Typography variant='subtitle2'>
                              {plan === AccountPlanType.Basic
                                ? 'Get Pro Plan 🚀'
                                : 'Pro Plan Active 🚀'}
                            </Typography>
                            <Typography variant='caption' color='text.secondary'>
                              The plan is managed by the organization owner.
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                    <Stack gap={2}>
                      <Stack>
                        <Typography variant='body1'>Current Usage</Typography>
                        <Typography variant='body2' color='textSecondary'>
                          Resets on{' '}
                          {format(new Date(user.organization.limitationsPeriod.end || ''), 'PPP')}{' '}
                          in{' '}
                          {differenceInDays(
                            new Date(user.organization.limitationsPeriod.end || ''),
                            new Date(),
                          ) + 1}{' '}
                          days
                        </Typography>
                      </Stack>

                      <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
                        <UsageBox
                          title='Chat Conversations'
                          subtitle='Total number of chats allowed per month.'
                          feature={user.organization.limitations.chats.used.toString()}
                          color={calculateColorTemperature(
                            (user.organization.limitations.chats.used /
                              user.organization.limitations.chats.total) *
                              100,
                          )}
                          value={
                            (user.organization.limitations.chats.used /
                              user.organization.limitations.chats.total) *
                            100
                          }
                          outOf={user.organization.limitations.chats.total.toString()}
                        />
                        <UsageBox
                          title='Bulk Messages'
                          subtitle='Total number of bulk messages you can send per month.'
                          feature={user.organization.limitations.privateBulk.used.toString()}
                          color={calculateColorTemperature(
                            (user.organization.limitations.privateBulk.used /
                              user.organization.limitations.privateBulk.total) *
                              100,
                          )}
                          value={
                            (user.organization.limitations.privateBulk.used /
                              user.organization.limitations.privateBulk.total) *
                            100
                          }
                          outOf={user.organization.limitations.privateBulk.total.toString()}
                        />
                        <UsageBox
                          title='Emails sent'
                          subtitle='Total number of emails you receive per month.'
                          feature={user.organization.limitations.emails.used.toString()}
                          color={calculateColorTemperature(
                            (user.organization.limitations.emails.used /
                              user.organization.limitations.emails.total) *
                              100,
                          )}
                          value={
                            (user.organization.limitations.emails.used /
                              user.organization.limitations.emails.total) *
                            100
                          }
                          outOf={user.organization.limitations.emails.total.toString()}
                        />
                      </Stack>
                    </Stack>
                  </SectionWrapper>
                </Grid>
              )}
            </Grid>
          </Stack>
        </Stack>
      </Container>
      <Outlet />
    </>
  );
};

export default ManageAccountPage;

// todo: ross
// <Button onClick={()=>{
//   api.post(`${ApiKey.Organization}/paypal-subscription/cancel`);
//
// }}>
//   Cancel subscription
// </Button>
