import { ChatFilterDirection, ChatFilterMatching, PrivateChatBulkMessageStatus } from 'enums';
import { AutoCompleteLabel } from 'types';

export enum ChatFilterFieldsNames {
  Direction = 'direction',
  Labels = 'labels',
  Matching = 'matching',
}

export interface ChatFilterFormFields {
  [ChatFilterFieldsNames.Direction]: ChatFilterDirection;
  [ChatFilterFieldsNames.Labels]: AutoCompleteLabel[];
  [ChatFilterFieldsNames.Matching]: ChatFilterMatching;
}

export enum PrivateMessageFormFieldsNames {
  ChatFilter = 'chatFilter',
  Message = 'message',
  FileUrl = 'fileUrl',
  LinkText = 'linkText',
  LinkUrl = 'linkUrl',
  StartDate = 'startDate',
  Project = 'project',
  ExcludeArchived = 'excludeArchived',
}

export interface PrivateMessageFormFields {
  [PrivateMessageFormFieldsNames.ChatFilter]: ChatFilterFormFields[];
  [PrivateMessageFormFieldsNames.Message]?: string;
  [PrivateMessageFormFieldsNames.FileUrl]?: string;
  [PrivateMessageFormFieldsNames.StartDate]?: Date | null;
  [PrivateMessageFormFieldsNames.LinkText]?: string;
  [PrivateMessageFormFieldsNames.LinkUrl]?: string;
  [PrivateMessageFormFieldsNames.Project]: string;
  [PrivateMessageFormFieldsNames.ExcludeArchived]?: boolean;
}

export enum FormMode {
  Create = 'create',
  Edit = 'edit',
}
export interface PrivateBulkMessageFormLocalState {
  projectId: string;
  initialValues?: Partial<PrivateMessageFormFields>;
  messageStatus?: PrivateChatBulkMessageStatus;
  messageId?: string;
  mode: FormMode;
}
