import { useCallback } from 'react';
import messages from 'messages';
import { useDirtyStore } from 'store';

export const useConfirmUnsavedNodeChanges = () => {
  const { isDirty, setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
    isDirty: state.isDirty,
  }));

  return useCallback(
    (callback: Function) => {
      if (isDirty) {
        // eslint-disable-next-line no-alert
        const userResponse = window.confirm(messages.builder.confirmLoseNodeChanges);
        if (userResponse) {
          setIsDirty(false);
          callback();
        }
      } else {
        callback();
      }
    },
    [isDirty, setIsDirty],
  );
};
