export const chartOptions = [
  {
    label: 'Last Week',
    value: 'lastWeek',
    xAxisLabel: 'Day',
  },
  {
    label: 'Semi-Annual',
    value: 'last6Months',
    xAxisLabel: 'Month',
  },
];

export interface ChartData {
  data: number[];
  xAxis: string[];
}
