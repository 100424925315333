import { Stack, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { ConfirmationDialog, FormTextEditor } from 'components/index';
import { OptionsMenuState, useFormProvider, useServerError } from 'hooks';
import { ChatNoteFormFields } from 'pages/activity-page/note/types';
import {
  chatNoteDefaultValues,
  chatNoteFieldsConfig,
  chatNoteFormSchema,
} from 'pages/activity-page/note/utils';
import { useConfirmUnsavedNodeChanges } from 'pages/app-pages/chatbot-builder-page/hooks/useConfirmUnsavedNodeChanges';
import { useDirtyStore } from 'store';
import { Chat } from 'types';

interface NoteFormProps {
  chatId: string;
  menuProps: Omit<OptionsMenuState, 'openMenu'>;
  defaultNote?: string;
}
const NoteForm = ({ chatId, defaultNote, menuProps }: NoteFormProps) => {
  const toastServerError = useServerError();
  const {
    value: submitting,
    setTrue: startSubmitting,
    setFalse: stopSubmitting,
  } = useBoolean(false);

  const formMethods = useFormProvider<ChatNoteFormFields>({
    reValidateMode: 'onChange',
    schema: chatNoteFormSchema,
    defaultValues: defaultNote
      ? {
          note: defaultNote,
        }
      : chatNoteDefaultValues,
  });

  const { handleSubmit, formState } = formMethods;
  const { setIsDirty, isDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
    isDirty: state.isDirty,
  }));

  const { note } = chatNoteFieldsConfig;

  const confirmUnsavedNodeChanges = useConfirmUnsavedNodeChanges();

  const handleOnSubmit = async (formData: ChatNoteFormFields) => {
    setIsDirty(false);
    startSubmitting();
    try {
      await api.patch<Chat>(`${ApiKey.Chat}/${chatId}`, formData);
      await mutate(`${ApiKey.Chat}`);
      await mutate(`${ApiKey.Chat}/${chatId}`);
      menuProps.closeMenu();
      stopSubmitting();
    } catch (error: any) {
      stopSubmitting();
      toastServerError(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <ConfirmationDialog
        {...menuProps}
        disableAutoClose
        disabled={submitting || !formState.isDirty}
        submitButtonColor='primary'
        submittingText='Saving...'
        confirmText='Save Note'
        node={
          <Stack px={1} py={2} gap={1} width={320}>
            <Typography variant='subtitle1'>Add Note</Typography>
            <FormTextEditor autoFocus {...note} />
          </Stack>
        }
        onConfirm={handleSubmit((data) => handleOnSubmit(data))}
        onClose={() => {
          if (isDirty) {
            confirmUnsavedNodeChanges(() => {
              menuProps.closeMenu();
            });
          } else {
            menuProps.closeMenu();
          }
        }}
      />
    </FormProvider>
  );
};

export default NoteForm;
