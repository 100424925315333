import { useCallback, useEffect } from 'react';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { AppTypes } from 'enums';
// eslint-disable-next-line import/no-internal-modules
import { useServerError } from 'hooks/useServerError';
// eslint-disable-next-line import/no-internal-modules
import { useToast } from 'hooks/useToast';
import messages from 'messages';
import { Project } from 'types';

export const usePauseApp = ({ project, appType }: { project?: Project; appType: AppTypes }) => {
  const { id } = project ?? {};

  const { value: pausing, setTrue: startPausing, setFalse: stopPausing } = useBoolean(false);

  const appActivity = !!project?.apps.find((app) => app.type === appType)?.isActive;

  const { value: appIsActive, setValue: setAppIsActive } = useBoolean(appActivity);

  useEffect(() => {
    setAppIsActive(appActivity);
  }, [appActivity, setAppIsActive]);

  const toast = useToast();
  const toastServerError = useServerError();
  const togglePause = useCallback(async () => {
    startPausing();
    setAppIsActive(!appActivity);
    try {
      await api.patch<Project>(`${ApiKey.Project}/${id}/${ApiKey.App}/${appType}`, {
        isActive: !appActivity,
      });
      await mutate<Project>(`${ApiKey.Project}/${id}`);
      await mutate<Project>(ApiKey.Project);
      toast({
        message: !appIsActive
          ? messages.builder.toast.playSuccess
          : messages.builder.toast.pausedSuccess,
        variant: 'success',
      });
      stopPausing();
    } catch (error) {
      toastServerError(error);
      stopPausing();
    }
  }, [
    startPausing,
    setAppIsActive,
    appActivity,
    id,
    appType,
    toast,
    appIsActive,
    stopPausing,
    toastServerError,
  ]);

  return {
    pausing,
    togglePause,
    botIsActive: appIsActive,
  };
};
