import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import { BasicColors } from 'theme';
import Icon from './Icon';

export type LabelProps = {
  children: ReactNode;
  color?: BasicColors;
  startIcon?: string;
  endIcon?: string;
  iconSx?: SxProps<Theme>;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

const Label = forwardRef<HTMLDivElement, LabelProps>(
  ({ children, color, onClick, startIcon, endIcon, iconSx, ...other }, ref) => {
    const theme = useTheme();

    const iconStyle = {
      color: color ? theme.palette[color].main : theme.palette.text.primary,
      fontSize: 16,
      ...iconSx,
    };

    return (
      <Box
        ref={ref}
        component='span'
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 0.5,
          color: color ? theme.palette[color].dark : theme.palette.text.primary,
          bgcolor: color ? theme.palette[color].lighter : theme.palette.grey[200],
          px: 1,
          py: 0.25,
          typography: 'caption',
          whiteSpace: 'nowrap',
          fontWeight: theme.typography.fontWeightMedium,
          cursor: onClick ? 'pointer' : 'default',
          ...(startIcon && { pl: 0.75 }),
          ...(endIcon && { pr: 0.75 }),
        }}
        onClick={onClick}
        {...other}
      >
        {startIcon && <Icon icon={startIcon} sx={{ mr: 0.75, ...iconStyle }} />}
        {children}
        {endIcon && <Icon icon={endIcon} sx={{ ml: 0.75, ...iconStyle }} />}
      </Box>
    );
  },
);

export default Label;
