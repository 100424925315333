export { default as ActivityPage } from './activity-page/ActivityPage';
export { default as ManageAccountPage } from 'pages/manage-account-page/ManageAccountPage';
export { default as OrganizationPage } from 'pages/organization-page/OrganizationPage';
export { default as DashboardPage } from 'pages/dashboard-page/DashboardPage';
export { default as ChatbotBuilderPage } from 'pages/app-pages/chatbot-builder-page/ChatbotBuilderPage';
export { default as MessagesSchedulerPage } from 'pages/app-pages/messages-scheduler-page/MessagesSchedulerPage';
export { default as ProjectsPage } from 'pages/projects-page/ProjectsPage';
export { default as TicketingPage } from 'pages/ticketing-page/TicketingPage';
export { default as ProjectManagerPage } from './project-manager-page/ProjectManagerPage';
export { default as NotFoundPage } from './NotFoundPage';
export { default as LoginPage } from './auth-pages/login/LoginPage';
export { default as SignUpPage } from './auth-pages/sign-up/SignUpPage';
export { default as ForgotPasswordPage } from './auth-pages/forgot-password/ForgotPasswordPage';
