import { Edge, Node } from 'reactflow';
import { NodeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { LayoutDirection } from 'pages/app-pages/chatbot-builder-page/store';
import { BaseNodeProps } from 'types';
import { defaultMentions } from 'utils';

interface DefaultNodeProps {
  direction: LayoutDirection;
  label: string;
}
export const defaultNodes = ({ direction, label }: DefaultNodeProps): Node<BaseNodeProps>[] => {
  const step2Position =
    direction === LayoutDirection.Horizontal ? { x: 400, y: 0 } : { x: 0, y: 250 };
  return [
    {
      id: '1',
      position: { x: 0, y: 0 },
      data: {
        label,
        step: 1,
        selectedStep: null,
        mentions: defaultMentions,
      },
      type: NodeType.Start,
    },
    {
      id: '2',
      position: step2Position,
      data: { label: 'Step 1', step: 2, selectedStep: null },
      type: NodeType.Input,
    },
  ];
};
export const defaultEdges: Edge[] = [{ id: 'e1-2', source: '1', target: '2', deletable: false }];

export const fitViewOptions = {
  padding: 0.95,
};
