import { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton/IconButton';
import IconButton from 'components/IconButton';
import messages from 'messages';
import { supportBotLink } from 'utils';

const CustomerSupportIconButton = ({
  color = 'inherit',
}: {
  color?: MuiIconButtonProps['color'];
}) => (
  <IconButton
    tooltip={messages.general.customerSupportText}
    tooltipPlacement='bottom-start'
    tooltipProps={{
      arrow: true,
    }}
    color={color}
    icon='support_agent'
    onClick={() => window.open(supportBotLink, '_blank')}
  />
);

export default CustomerSupportIconButton;
