import { useCallback } from 'react';
import { NodeProps, useReactFlow } from 'reactflow';
import { StepType } from 'enums';
import { useUndoRedoContext } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import useConnectedEdgesAndNodes from 'pages/app-pages/chatbot-builder-page/hooks/useConnectedEdgesAndNodes';
import useSaveToServer from 'pages/app-pages/chatbot-builder-page/hooks/useSaveToServer';
import useValidateTreeStructure from 'pages/app-pages/chatbot-builder-page/hooks/useValidateTreeStructure';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { EdgeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { BaseNodeProps } from 'types';

const useUpdateNodeStepType = () => {
  const connectedEdgesAndNodes = useConnectedEdgesAndNodes();
  const saveToServer = useSaveToServer();
  const { takeSnapshot, undo } = useUndoRedoContext();
  const validateTreeStructure = useValidateTreeStructure();

  const { setNodes, setEdges, getNodes } = useReactFlow<BaseNodeProps>();

  const { setSelectedStepType, openToolbox, setActiveToolboxTab, setSelectedNodeId } =
    useBuilderStore((state) => ({
      setSelectedStepType: state.setSelectedStepType,
      openToolbox: state.openToolbox,
      setActiveToolboxTab: state.setActiveToolboxTab,
      setSelectedNodeId: state.setSelectedNodeId,
    }));

  return useCallback(
    async ({ id, stepType: newStepType }: { id: NodeProps['id']; stepType: StepType }) => {
      // eslint-disable-next-line no-console
      takeSnapshot();
      // Get the index of the deleted node in the allNodes array
      const currentNode = getNodes().find((node) => node.id === id);

      const currentNodeStepType = currentNode?.data.selectedStep;

      if (currentNodeStepType === newStepType) {
        return;
      }

      const currentNodeEdgeType = currentNodeStepType
        ? StepsConfig[currentNodeStepType].edgeType
        : undefined;
      const newStepTypeEdgeType = StepsConfig[newStepType].edgeType;

      const isBothStepsCategoryIsNotMultiNode =
        currentNodeEdgeType !== EdgeType.MultiEdge &&
        newStepTypeEdgeType !== EdgeType.MultiEdge &&
        newStepTypeEdgeType !== EdgeType.DeadEnd;

      //  find all next connected nodes and edges
      const nextConnectedEdgesAndNodes = connectedEdgesAndNodes(currentNode?.id || '');
      const nextConnectedNodes = nextConnectedEdgesAndNodes.nodes;
      const nextConnectedEdges = nextConnectedEdgesAndNodes.edges;

      setNodes((ns) =>
        ns
          .map((n) => {
            if (n.id === id) {
              return {
                ...n,
                data: {
                  ...n.data,
                  selectedStep: newStepType,
                  formData: {
                    name: StepsConfig[newStepType].name,
                    nodeId: isBothStepsCategoryIsNotMultiNode ? n.data.formData?.nodeId ?? '' : '',
                  },
                },
              };
            }
            return n;
          })
          .filter((n) => isBothStepsCategoryIsNotMultiNode || !nextConnectedNodes.includes(n)),
      );

      if (!isBothStepsCategoryIsNotMultiNode) {
        setEdges((es) => es.filter((e) => !nextConnectedEdges.includes(e)));
      }

      setSelectedStepType(newStepType);
      openToolbox();
      setActiveToolboxTab(ToolboxTabs.NodeEditor);
      setSelectedNodeId(id);

      if (!validateTreeStructure()) {
        undo();
        return;
      }

      await saveToServer(); // You wait for the promise to resolve here
    },
    [
      takeSnapshot,
      connectedEdgesAndNodes,
      getNodes,
      validateTreeStructure,
      saveToServer,
      setSelectedStepType,
      setSelectedNodeId,
      setEdges,
      setNodes,
      openToolbox,
      setActiveToolboxTab,
      undo,
    ],
  );
};

export default useUpdateNodeStepType;
