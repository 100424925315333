import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
} from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import { getValue } from 'components/form-fields/helpers';
import { ObjectWithRequiredProp } from 'utils';

export interface FormRadioGroupProps<T> extends Omit<RadioProps, 'id'> {
  name: string;
  label?: string;
  row?: boolean;
  options: T[];
}
export const FormRadioGroup = <T extends string | ObjectWithRequiredProp<'label', string>>(
  props: FormRadioGroupProps<T>,
) => {
  const { name, label, row, options, ...rest } = props;
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ref, ...field }, fieldState: { error } }) => (
        <FormControl className='notranslate'>
          {label && <FormLabel>{label}</FormLabel>}
          <RadioGroup
            onChange={(_, data) => {
              const selectedValue = data as string | { label: string };
              const selectedOption =
                typeof selectedValue === 'string' ? selectedValue : selectedValue.label;
              onChange(selectedOption);
            }}
            row={row}
            id={name}
            {...field}
            value={getValue(value)}
          >
            {options.map((option, index) => {
              const optionLabel = typeof option === 'string' ? option : option.label;
              return (
                <FormControlLabel
                  key={optionLabel}
                  sx={{ alignSelf: 'flex-start' }}
                  value={typeof option === 'string' ? option : option.value}
                  control={<Radio {...(index === 0 && { inputRef: ref })} {...rest} size='small' />}
                  label={optionLabel}
                />
              );
            })}
          </RadioGroup>
          <FormCollapseHelperText error={error} />
        </FormControl>
      )}
    />
  );
};

export default memo(FormRadioGroup);
