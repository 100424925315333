import { Skeleton, Stack } from '@mui/material';

const UserItemSkeleton = () => (
  <Stack p={2} flex={1} direction='row' alignItems='center' gap={2}>
    <Stack direction='row' alignItems='center' gap={2} flex={1}>
      <Skeleton variant='circular' width={36} height={36} />
      <Stack width={1} height={42}>
        <Skeleton variant='text' sx={{ fontSize: '0.875rem' }} width='40%' />
        <Skeleton variant='text' sx={{ fontSize: '0.8rem' }} width='30%' />
      </Stack>
    </Stack>
  </Stack>
);

export default UserItemSkeleton;
