import { Box } from '@mui/material';
import { memo } from 'react';

import { FormTextEditorProps } from 'components/form-fields/FormTextEditor';
import { FormSection, FormTextEditor } from 'components/index';

const NodesFormTextEditor = ({ label = 'Message', ...props }: FormTextEditorProps) => (
  <FormSection
    title={label}
    infoIcon={{
      tooltip: (
        <Box>
          type @ to use a variable or / to add a command <br />
          (e.g., /start to restart the flow)
        </Box>
      ),
      icon: 'info',
    }}
  >
    <FormTextEditor {...props} />
  </FormSection>
);

export default memo(NodesFormTextEditor);
