import { AutoCompleteLabel } from 'types';

export enum SendEmailFormFieldsNames {
  Name = 'name',
  EmailAddress = 'emailAddress',
  Subject = 'subject',
  Input = 'input',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface SendEmailFormFields {
  [SendEmailFormFieldsNames.NodeId]: string;
  [SendEmailFormFieldsNames.Name]: string;
  [SendEmailFormFieldsNames.Input]: string;
  [SendEmailFormFieldsNames.Subject]: string;
  [SendEmailFormFieldsNames.EmailAddress]: string[];
  [SendEmailFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
