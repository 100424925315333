export enum UserRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Agent = 'Agent',
}

export enum ExtendedUserRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Agent = 'Agent',
  Owner = 'Owner',
}
