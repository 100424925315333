import { Box, Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import { MouseEvent } from 'react';
import { Icon } from 'components/index';
import { TooltipPlacement } from 'theme';

type GroupButtonItemProps = {
  label?: string;
  tooltip?: string;
  onClick?: ((e: MouseEvent<HTMLButtonElement>) => void) | (() => void);
  icon?: string;
  tooltipPlacement?: TooltipPlacement;
  disabled?: boolean;
  loading?: boolean;
};
const BuilderPanelButtonItem = ({
  label,
  onClick,
  tooltip = '',
  tooltipPlacement = 'bottom',
  icon,
  disabled,
  loading,
}: GroupButtonItemProps) => (
  <Button
    disabled={disabled}
    onClick={(e) => {
      onClick?.(e);
    }}
    sx={{
      p: 0,
      lineHeight: 1,
      backgroundColor: 'grey.300',
      '&:hover': {
        backgroundColor: 'grey.200',
      },
    }}
  >
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Stack
        position='relative'
        direction='row'
        justifyContent='center'
        gap={1}
        width={1}
        height={1}
        alignItems='center'
        py={0.75}
        px={1.5}
      >
        {loading && (
          <Box
            position='absolute'
            top={0}
            bottom={0}
            left={0}
            right={0}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <CircularProgress color='inherit' size='1rem' />
          </Box>
        )}
        {label && (
          <Box px={0.5} visibility={loading ? 'hidden' : 'visible'}>
            {label}
          </Box>
        )}
        <Icon icon={icon} fontSize='small' />
      </Stack>
    </Tooltip>
  </Button>
);

export default BuilderPanelButtonItem;
