import { Typography } from '@mui/material';

interface StepTitleProps {
  title: string;
}
const StepTitle = ({ title }: StepTitleProps) => (
  <Typography variant='body1' noWrap>
    {title}
  </Typography>
);

export default StepTitle;
