import { Stack, Typography } from '@mui/material';

const EmptyState = ({ text }: { text: string }) => (
  <Stack width={1} justifyContent='center' alignItems='center' p={3}>
    <Typography variant='body2' color='textSecondary'>
      {text}
    </Typography>
  </Stack>
);

export default EmptyState;
