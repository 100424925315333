import { Box, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import {
  BasicModal,
  BulletWrapper,
  Button,
  FormTextField,
  SuccessMessage,
} from '@common-components';
import { api, ApiKey } from 'api';
import Icon from 'components/Icon';
import { GtmEventsConfig } from 'config';
import { AppTypes } from 'enums';
import { useFormProvider, useIsMobile, useServerError } from 'hooks';
import LinkBox from 'pages/app-pages/chatbot-builder-page/dialogs/publish/LinkBox';
import TutorialStep from 'pages/app-pages/chatbot-builder-page/dialogs/publish/TutorialStep';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { BotSettingsFormFields } from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/_bot-settings/types';
import {
  botSettingsFormFieldsConfig,
  botSettingsSchema,
  defaultBotSettingsFormValues,
} from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/_bot-settings/utils';
import { useDirtyStore } from 'store';
import { Bot, Project } from 'types';
import { gtmCustomEvent } from 'utils';

interface PublishModalProps {
  appType?: AppTypes;
  title?: string;
}

const PublishModal = ({
  appType,
  title = 'Activate Telegram bot in two simple steps',
}: PublishModalProps) => {
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState(false);

  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const { setPublishedLastUpdatedAt } = useBuilderStore((state) => ({
    setPublishedLastUpdatedAt: state.setPublishedLastUpdatedAt,
  }));

  const { isMobile } = useIsMobile();

  const formMethods = useFormProvider<BotSettingsFormFields>({
    reValidateMode: 'onChange',
    schema: botSettingsSchema,
    defaultValues: defaultBotSettingsFormValues,
  });

  const { handleSubmit, formState } = formMethods;
  const { token } = botSettingsFormFieldsConfig;
  const toastServerError = useServerError();
  const navigate = useNavigate();

  const onSupportClick = () => {
    window.open('https://www.buymeacoffee.com/teleform', '_blank');
  };

  const handleOnSubmit = async (formData: BotSettingsFormFields) => {
    setIsDirty(false);
    // TODO - fix response
    try {
      await api.patch<Bot>(`${ApiKey.Project}/${id}`, {
        token: formData.token,
      });
      if (appType) {
        await api.post(`${ApiKey.Project}/${id}/publish`, {
          appType,
        });
      }

      const response = await mutate<Project>(`${ApiKey.Project}/${id}`);
      setPublishedLastUpdatedAt(response?.bot?.published?.updatedAt || null);
      const eventData = {
        botId: id,
        timestamp: new Date().toISOString(), // ISO string for the publication time
      };

      // Fire the GTM custom event
      gtmCustomEvent(GtmEventsConfig.PublishBot, eventData);

      setSuccessMessage(true);
    } catch (error: any) {
      toastServerError(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        onSubmit={handleSubmit(handleOnSubmit)}
        title={successMessage ? ' ' : title}
        label='create-command-dialog'
        open
        cancelButton={!successMessage}
        maxWidth='md'
        submittingText='Activating...'
        mainAction={
          !successMessage
            ? {
                children: 'Activate',
                type: 'submit',
                isSubmitting: formState.isSubmitting,
              }
            : {
                children: 'Close',
                onClick: () => {
                  navigate('./..', { replace: true });
                },
              }
        }
        cancelText='Cancel'
      >
        <Stack width={1}>
          {successMessage ? (
            <SuccessMessage
              title='Your bot is now active!'
              description={
                <Stack gap={2} width={1}>
                  <Typography variant='body1' align='center' mb={2}>
                    Your bot is now active and ready to start conversations.
                  </Typography>
                  {project?.botUsername && (
                    <LinkBox
                      value={`https://t.me/${project.botUsername}`}
                      copyText='Bot Link copied'
                      label='Direct Bot Link'
                      icon='smart_toy'
                      helpText='This is the direct link to your bot. Share it with your users to start a conversation.'
                    />
                  )}
                  <LinkBox
                    value={project?.trackingUrl || ''}
                    copyText='Magic Link copied'
                    label='Magic Link'
                    icon='link'
                    helpText={`This magic link captures the visitor's IP, browser information, and any additional URL parameters before redirecting to your Telegram bot. You can utilize this data within your chatbot builder and forward it to your Webhook for enhanced interaction.`}
                  />
                  <Stack gap={2} width={1} mt={2} alignItems='center'>
                    <Typography variant='body1' align='center' fontWeight='bolder'>
                      If you like Teleform, please consider supporting us by buying us a coffee.
                    </Typography>
                    <Button
                      size='large'
                      color='secondary'
                      variant='outlined'
                      startIcon={<Icon icon='volunteer_activism' />}
                      onClick={onSupportClick}
                    >
                      Support Us
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ) : (
            <Stack
              width={1}
              direction={isMobile ? 'column' : 'row'}
              gap={5}
              divider={<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />}
            >
              <TutorialStep />
              <Stack flex={1} gap={1} alignItems='flex-start'>
                <Typography variant='h6' gutterBottom>
                  2. Paste the token and activate
                </Typography>
                <BulletWrapper>
                  <Typography variant='body2' mb={1}>
                    Paste the API token in the field below and click activate.
                  </Typography>
                </BulletWrapper>
                <Box width={1}>
                  <FormTextField
                    fullWidth
                    size='small'
                    {...token}
                    helperText='You can always change the token later in the bot settings.'
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default PublishModal;
