enum RoutePath {
  // Root
  Dashboard = 'dashboard',
  Projects = 'projects',
  Activity = 'activity',
  Tickets = 'tickets',
  ManageAccount = 'manage-account',
  NotFound = 'not-found',

  // Auth
  Login = 'login',
  SignUp = 'sign-up',
  ForgotPassword = 'forgot-password',

  // Modals
  CreateProject = 'create-project',
  ConnectApp = 'connect-app',
  RenameProject = 'rename-project',
  ChatActivity = 'chat-activity',
  ApplyCoupon = 'apply-coupon',
  ManageLabels = 'manage-labels',

  // Builder
  ChatbotBuilder = 'chatbot-builder',
  Command = 'command',
  Publish = 'publish',
  Template = 'template',

  // Messenger
  Messenger = 'messenger',
  Composer = 'composer',
  Onboarding = 'onboarding',
  ConnectGroups = 'connect-groups',

  // Private Chat Bulk Messages
  PrivateChatBulkMessages = 'private-bulk-messages',
  Contacts = 'contacts',
  Schedule = 'schedule',

  // Moderator
  Moderator = 'moderator',

  // Project Manager
  ProjectManager = 'project-manager',
  // Organization
  Organization = 'organization',
  InviteToOrganization = 'invite-to-organization',
  AcceptInvitation = 'accept-invitation',
}

export default RoutePath;
