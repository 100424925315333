import { Alert, Stack, Typography } from '@mui/material';
import { ConfirmationDialog } from '@common-components';
import { OptionsMenuState } from 'hooks';
import { useGetUserLabels } from 'hooks/api/labels/useGetUserLabels';
import { useConfirmUnsavedNodeChanges } from 'pages/app-pages/chatbot-builder-page/hooks/useConfirmUnsavedNodeChanges';
import { useDirtyStore } from 'store';
import LabelEditor from './LabelEditor';

interface ManageLabelsPopoverProps {
  menuProps: Omit<OptionsMenuState, 'openMenu'>;
}
const ManageLabelsPopover = ({ menuProps }: ManageLabelsPopoverProps) => {
  const { labels } = useGetUserLabels();

  const { isDirty } = useDirtyStore((state) => ({
    isDirty: state.isDirty,
  }));

  const confirmUnsavedNodeChanges = useConfirmUnsavedNodeChanges();

  const handleClose = () => {
    if (isDirty) {
      confirmUnsavedNodeChanges(() => {
        menuProps.closeMenu();
      });
    } else {
      menuProps.closeMenu();
    }
  };

  return (
    <ConfirmationDialog
      {...menuProps}
      maxWidth='auto'
      submitButtonColor='primary'
      hideCancelButton
      confirmText='Close'
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      node={
        <Stack
          minWidth={{
            xs: 'calc(100% - 32px)',
            sm: 480,
          }}
          width={{
            xs: 'calc(100% - 32px)',
            sm: 480,
          }}
          minHeight={250}
          px={1}
          py={2}
          gap={1}
        >
          <Typography variant='subtitle1'>Manage Labels</Typography>

          <Stack width={1} gap={1} direction='row' flexWrap='wrap'>
            {labels?.map((chat) => <LabelEditor key={chat.id} label={chat} icon='edit' />)}
            <LabelEditor key={labels.length} icon='add_circle' />
          </Stack>
          <Alert severity='warning' sx={{ mt: 1 }}>
            This is a global action. Changes made here will affect labels across the entire App.
          </Alert>
        </Stack>
      }
      onConfirm={handleClose}
    />
  );
};

export default ManageLabelsPopover;
