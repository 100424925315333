import { useCallback } from 'react';
import { getOutgoers, Node, NodeProps, useReactFlow } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { useUndoRedoContext } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import useSaveToServer from 'pages/app-pages/chatbot-builder-page/hooks/useSaveToServer';
import { useSetViewToNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSetViewToNode';
import useValidateTreeStructure from 'pages/app-pages/chatbot-builder-page/hooks/useValidateTreeStructure';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { BaseNodeProps } from 'types';

export const useDuplicateNode = () => {
  const { setEdges, setNodes, getNodes, getNode } = useReactFlow<BaseNodeProps>();
  const setViewToNode = useSetViewToNode();
  const { takeSnapshot, undo } = useUndoRedoContext();
  const validateTreeStructure = useValidateTreeStructure();

  const { setSelectedStepType, closeToolbox, setSelectedNodeId, setEditorIsFocused } =
    useBuilderStore((state) => ({
      setSelectedStepType: state.setSelectedStepType,
      closeToolbox: state.closeToolbox,
      setSelectedNodeId: state.setSelectedNodeId,
      setEditorIsFocused: state.setEditorIsFocused,
    }));

  const saveToServer = useSaveToServer();

  return useCallback(
    async (id: NodeProps['id']) => {
      const duplicate = getNode(id);

      if (!duplicate) {
        return;
      }

      takeSnapshot();

      // get original node index
      const originalNodeIndex = getNodes().findIndex((node) => node.id === duplicate.id);

      const newId = uuid();
      const newNode: Node<BaseNodeProps> = {
        id: newId,
        position: { x: duplicate.position.x, y: duplicate.position.y },
        selected: true,
        data: {
          ...duplicate.data,
          formData: {
            ...duplicate.data.formData,
            nodeId: duplicate.data.formData?.nodeId || '', // Set the nodeId to the ID of the duplicate node
            name: `${duplicate.data.formData?.name} (copy)`,
          },
        },
        type: duplicate.type,
        draggable: false,
      };

      setNodes((nds) => {
        // Find the original node and create a modified copy with updated formData.nodeId
        const updatedOriginalNode = {
          ...nds[originalNodeIndex],
          selected: false,
          data: {
            ...nds[originalNodeIndex].data,
            formData: {
              ...nds[originalNodeIndex].data.formData,
              name: nds[originalNodeIndex].data.formData?.name || '',
              nodeId: newId, // Update the nodeId to point to the new duplicate node
            },
          },
        };

        // Insert the updated original node and the new node into the nodes array
        return [
          ...nds.slice(0, originalNodeIndex), // Include all nodes up to the original node
          updatedOriginalNode, // Insert the updated original node
          newNode, // Insert the new duplicate node after the original node
          ...nds.slice(originalNodeIndex + 1), // Include the rest of the nodes after the original node
        ];
      });
      setEdges((eds) => {
        const filteredEdges = eds.filter((edge) => edge.source !== duplicate.id);

        // add new edge from original node to new node
        const newEdges = eds
          .filter((edge) => edge.source === duplicate.id)
          .map((edge) => ({
            id: `${edge.source}->${newId}`,
            source: edge.source,
            target: newId,
            deletable: false,
          }));

        // add new edge from new node to original node outgoers
        const outgoers = getOutgoers(duplicate, getNodes(), eds);
        const newEdges2 = outgoers.map((outgoer) => ({
          id: `${newId}->${outgoer.id}`,
          source: newId,
          target: outgoer.id,
          deletable: false,
        }));

        return filteredEdges.concat(newEdges).concat(newEdges2);
      });

      if (!validateTreeStructure()) {
        undo();
        return;
      }
      setViewToNode(id, true);
      setEditorIsFocused(false);
      setSelectedStepType(null);
      closeToolbox();
      setSelectedNodeId(null);

      await saveToServer(); // You wait for the promise to resolve here
    },
    [
      getNode,
      takeSnapshot,
      getNodes,
      setNodes,
      setEdges,
      validateTreeStructure,
      setViewToNode,
      setEditorIsFocused,
      setSelectedStepType,
      closeToolbox,
      setSelectedNodeId,
      saveToServer,
      undo,
    ],
  );
};
