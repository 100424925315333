import { AutoCompleteLabel } from 'types';

export enum OptionsQuestionStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Variable = 'variable',
  Answers = 'answers',
  RemoveAnswerOptions = 'removeAnswerOptions',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface OptionsQuestionStepFormFields {
  [OptionsQuestionStepFormFieldsNames.Name]: string;
  [OptionsQuestionStepFormFieldsNames.Input]: string;
  [OptionsQuestionStepFormFieldsNames.Variable]?: string;
  [OptionsQuestionStepFormFieldsNames.NodeId]: string;
  [OptionsQuestionStepFormFieldsNames.Answers]: OptionsQuestionStepFormFieldsOptions[];
  [OptionsQuestionStepFormFieldsNames.RemoveAnswerOptions]?: boolean;
  [OptionsQuestionStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export enum OptionsQuestionStepFormFieldsOptionsNames {
  Name = 'name',
  Answer = 'option',
  NodeId = 'nodeId',
}

export interface OptionsQuestionStepFormFieldsOptions {
  [OptionsQuestionStepFormFieldsOptionsNames.Name]: string;
  [OptionsQuestionStepFormFieldsOptionsNames.Answer]: string;
  [OptionsQuestionStepFormFieldsOptionsNames.NodeId]: string;
}
