import {
  autocompleteClasses,
  inputBaseClasses,
  inputLabelClasses,
  outlinedInputClasses,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteOptions {
    purple: PaletteColor;
    red: PaletteColor;
    chatbot: PaletteColor;
    messenger: PaletteColor;
    bulk: PaletteColor;
  }

  interface Palette {
    purple: PaletteColor;
    red: PaletteColor;
    chatbot: PaletteColor;
    messenger: PaletteColor;
    bulk: PaletteColor;
  }
}

export const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  typography: {
    ...typography,
  },
  shadows: {
    ...shadows(),
  },
  palette: {
    ...palette,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '8px',
          padding: '8px 12px',
          maxWidth: '360px',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          borderRadius: '6px', // Add this line to set the border radius
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...typography.body2,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px', // Add this line to set the border radius
          backgroundColor: palette.grey[50],
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.grey[200],
          },
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.primary.dark,
          },
          [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.primary.darker,
          },
          [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.error.main,
          },
          [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.grey[200],
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: 'text.primary',
          cursor: 'pointer',
          '&:hover': {
            color: 'primary.dark',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          ...typography.body2,
        },
        primary: {
          ...typography.body2,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body2,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...typography.body2, // Apply body2 styles to all input base components
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          [`& .${inputBaseClasses.input}`]: {
            ...typography.body2, // Ensures text fields also use body2
          },

          [`& .${inputLabelClasses.root}`]: {
            ...typography.body2, // Apply body2 styles to TextField labels
          },
          [`& .${inputLabelClasses.shrink}`]: {
            ...typography.body2, // Apply body2 styles to the shrink state
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          ...typography.body2, // Apply body2 styles to all select components
        },
      },
    },

    // Overrides for Autocomplete dropdown
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          [`& .${inputBaseClasses.input}`]: {
            ...typography.body2, // Apply body2 styles to autocomplete input
          },
          [`&[class*="${inputBaseClasses.root}"] .${autocompleteClasses.input}, & .${autocompleteClasses.input}:first-of-type`]:
            {
              ...typography.body2, // Specifically for outlined variant and first child
            },
        },
        listbox: {
          ...typography.body2,
        },
        option: {
          ...typography.body2,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...typography.caption,
          fontWeight: typography.fontWeightMedium,
          borderRadius: '4px',
        },
      },
    },
  },
});
