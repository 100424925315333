import { GridColDef } from '@mui/x-data-grid';
import { formatDistanceToNowStrict } from 'date-fns';
import { TextCell } from '@common-components';
import QuillContentDisplay from 'components/QuillContentDisplay';
import GroupsCell from 'pages/app-pages/messages-scheduler-page/GroupsCell';
import MessageDropdownWrapper from 'pages/app-pages/messages-scheduler-page/MessageDropdownWrapper';
import ScheduleDateCell from 'pages/app-pages/messages-scheduler-page/ScheduleDateCell';
import StatusCell from 'pages/app-pages/messages-scheduler-page/StatusCell';
import { MessageColumns } from 'pages/app-pages/messages-scheduler-page/types';

export const messagesTableConfig: GridColDef[] = [
  {
    field: 'id',
    headerName: '',
    width: 64,
    align: 'right',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => <MessageDropdownWrapper id={value} icon='more_vert' />,
  },
  {
    field: MessageColumns.Status,
    headerName: '',
    headerAlign: 'center',
    width: 64,
    align: 'left',
    renderCell: StatusCell,
    sortable: false,
  },
  {
    field: MessageColumns.DeliveryDate,
    headerName: 'Delivery Date & Time',
    renderCell: ScheduleDateCell,
    width: 300,
  },

  {
    field: MessageColumns.Message,
    headerName: 'Message',
    flex: 1,
    minWidth: 200,
    renderCell: ({ value }) => <QuillContentDisplay content={value} />,
  },

  {
    field: MessageColumns.Groups,
    headerName: 'Recipients',
    flex: 1,
    minWidth: 200,
    sortable: false,
    renderCell: GroupsCell,
  },

  {
    field: MessageColumns.UpdatedAt,
    headerName: 'Modified',
    width: 200,
    valueFormatter: ({ value }) => formatDistanceToNowStrict(new Date(value), { addSuffix: true }),
    renderCell: TextCell,
  },
];
