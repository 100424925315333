import { AutoCompleteLabel } from 'types';

export enum FreeQuestionFormFieldsNames {
  Name = 'name',
  Input = 'input',
  NodeId = 'nodeId',
  Variable = 'variable',
  Validation = 'validation',
  Labels = 'labels',
}

export interface FreeQuestionFormFields {
  [FreeQuestionFormFieldsNames.NodeId]: string;
  [FreeQuestionFormFieldsNames.Name]: string;
  [FreeQuestionFormFieldsNames.Input]: string;
  [FreeQuestionFormFieldsNames.Variable]?: string;
  [FreeQuestionFormFieldsNames.Validation]?: string;
  [FreeQuestionFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
