import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LocalStorageKey } from 'config';

interface TourStore {
  run: boolean;
  stopRun: () => void;
  startRun: () => void;
  currentStep: number;
  tourFinished: boolean;
  setTourFinished: () => void;
  setStep: (step: number) => void;
  maxStep: number;
}

export const useTourStore = create(
  persist<TourStore>(
    (set, get) => ({
      run: false,
      tourFinished: get()?.tourFinished || false,
      setTourFinished: () => set({ tourFinished: true }),
      stopRun: () => set({ run: false }),
      startRun: () => set({ run: true }),
      setStep: (step) => set({ currentStep: step }),
      currentStep: get()?.currentStep || 0,
      maxStep: 4,
    }),
    {
      name: LocalStorageKey.BuilderTourState,
    },
  ),
);
