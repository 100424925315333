import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import ToolboxHeader, { ToolboxHeaderProps } from 'components/ToolboxHeader';
import { useConfirmUnsavedNodeChanges } from 'pages/app-pages/chatbot-builder-page/hooks/useConfirmUnsavedNodeChanges';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { TourSteps } from 'pages/app-pages/chatbot-builder-page/tour/config';

interface ToolboxLayoutProps extends ToolboxHeaderProps {
  subtitle?: string;
  children: ReactNode;
}
const ToolboxLayout = ({ children, ...rest }: ToolboxLayoutProps) => {
  const { setSelectedStepType, closeToolbox } = useBuilderStore((state) => ({
    closeToolbox: state.closeToolbox,
    setSelectedStepType: state.setSelectedStepType,
  }));
  const confirmUnsavedNodeChanges = useConfirmUnsavedNodeChanges();

  return (
    <Stack height={1} className={TourSteps.Toolbox}>
      <ToolboxHeader
        onClose={() => {
          confirmUnsavedNodeChanges(() => {
            closeToolbox();
            setSelectedStepType(null);
          });
        }}
        {...rest}
      />
      <Stack flex={1} overflow='auto'>
        <Box pb={2} height={1}>
          {children}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ToolboxLayout;
