import { AccountPlanType, StepType } from 'enums';
import {
  FileNode,
  FreeQuestion,
  HumanHandoff,
  Invite,
  Message,
  MultiChoiceQuestion,
  Navigate,
  OpenEndQuestion,
  OptionsMenu,
  OptionsQuestion,
  QuizSummary,
  RandomMenu,
  RequestContact,
  RequestEmail,
  RequestLocation,
  SendData,
  SendEmail,
  SendTelegramNotification,
  TrueFalseQuestion,
  WaitForTime,
  YesNoMenu,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps';
import { fileStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/file/utils';
import { freeQuestionFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/free-question/utils';
import { humanHandoffStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/human-handoff/utils';
import { inviteStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/invite/utils';
import { messageStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/message/utils';
import { navigateStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/navigate/utils';
import { OptionsMenuStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-menu/utils';
import { OptionsQuestionStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-question/utils';
import { multiChoiceQuestionStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/utils';
import { openEndQuestionFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/open-end-question/utils';
import { quizSummaryFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/summary/utils';
import { trueFalseQuestionStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/true-false-question/utils';
import { RandomMenuStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/utils';
import { requestContactStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-contact/utils';
import { requestEmailStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-email/utils';
import { sendDataFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/utils';
import { sendEmailFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-email/utils';
import { telegramNotificationStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-telegram-notification/utils';
import { waitForTimeStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/wait-for-time/utils';
import { yesNoStepFormSchema } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/utils';
import {
  EdgeType,
  StepCategory,
  StepCategoryProps,
  StepProps,
} from 'pages/app-pages/chatbot-builder-page/nodes/types';

export const StepsConfig: Record<StepType, StepProps> = {
  [StepType.OptionsMenu]: {
    name: 'Options Menu',
    description: 'Split the flow based on user multiple choice input',
    type: StepType.OptionsMenu,
    edgeType: EdgeType.MultiEdge,
    stepComponent: OptionsMenu,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'list',
    category: StepCategory.MultiNodeInput,
    mentions: [],
    schema: OptionsMenuStepFormSchema,
  },
  [StepType.YesNo]: {
    name: 'Yes/No',
    description: 'Split the flow based on user yes/no input',
    type: StepType.YesNo,
    edgeType: EdgeType.MultiEdge,
    stepComponent: YesNoMenu,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'arrow_split',
    category: StepCategory.MultiNodeInput,
    mentions: [],
    schema: yesNoStepFormSchema,
  },
  [StepType.File]: {
    name: 'Image/Document',
    description: 'Send a image or document to the user',
    type: StepType.File,
    edgeType: EdgeType.SingleEdge,
    stepComponent: FileNode,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'image',
    category: StepCategory.Action,
    mentions: [],
    schema: fileStepFormSchema,
  },
  [StepType.Message]: {
    name: 'Message',
    description: 'Send a message to the user',
    type: StepType.Message,
    edgeType: EdgeType.SingleEdge,
    stepComponent: Message,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'message',
    category: StepCategory.Action,
    mentions: [],
    schema: messageStepFormSchema,
  },
  [StepType.Navigate]: {
    name: 'Navigate to URLs',
    description: 'Provide navigation links to external URLs',
    type: StepType.Navigate,
    edgeType: EdgeType.SingleEdge,
    stepComponent: Navigate,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'link',
    category: StepCategory.Action,
    mentions: [],
    schema: navigateStepFormSchema,
  },
  [StepType.Invite]: {
    name: 'Invite To Private Channel',
    description: 'Invite users to private channels or groups',
    type: StepType.Invite,
    edgeType: EdgeType.SingleEdge,
    stepComponent: Invite,
    plan: [AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'person_add',
    category: StepCategory.Action,
    mentions: [],
    schema: inviteStepFormSchema,
  },
  [StepType.FreeQuestion]: {
    name: 'Open-End Question',
    description: 'Ask a question that requires a free-form answer',
    type: StepType.FreeQuestion,
    edgeType: EdgeType.SingleEdge,
    stepComponent: FreeQuestion,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'edit_note',
    category: StepCategory.SingleNodeInput,
    mentions: [],
    schema: freeQuestionFormSchema,
  },
  [StepType.OptionsQuestion]: {
    name: 'Multiple Choice Question',
    description: 'Ask a question with multiple choice answers',
    type: StepType.OptionsQuestion,
    edgeType: EdgeType.SingleEdge,
    stepComponent: OptionsQuestion,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'widgets',
    category: StepCategory.SingleNodeInput,
    mentions: [],
    schema: OptionsQuestionStepFormSchema,
  },
  [StepType.Email]: {
    name: 'Request Email',
    description: 'Request the user to provide an email address',
    type: StepType.Email,
    edgeType: EdgeType.SingleEdge,
    stepComponent: RequestEmail,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'email',
    category: StepCategory.Request,
    mentions: [{ id: 'email', value: 'Email' }],
    schema: requestEmailStepFormSchema,
  },
  [StepType.Contact]: {
    name: 'Request Contact',
    description: 'Request the user to provide a phone number',
    type: StepType.Contact,
    edgeType: EdgeType.SingleEdge,
    stepComponent: RequestContact,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'account_box',
    category: StepCategory.Request,
    mentions: [{ id: 'phone', value: 'Phone' }],
    schema: requestContactStepFormSchema,
  },
  [StepType.Location]: {
    name: 'Request Location',
    description: 'Request the user to provide his current location',
    type: StepType.Location,
    edgeType: EdgeType.SingleEdge,
    stepComponent: RequestLocation,
    plan: [AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'location_on',
    category: StepCategory.Request,
    mentions: [
      { id: 'country', value: 'Country' },
      { id: 'city', value: 'City' },
    ],
    schema: requestContactStepFormSchema,
  },
  [StepType.WaitForTime]: {
    name: 'Wait for Time',
    description: 'Wait for a specific time before continuing the flow',
    type: StepType.WaitForTime,
    edgeType: EdgeType.SingleEdge,
    stepComponent: WaitForTime,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'update',
    category: StepCategory.Logic,
    mentions: [],
    schema: waitForTimeStepFormSchema,
  },
  [StepType.QuizOpenQuestion]: {
    name: 'Open-End Question',
    description: 'Ask a quiz question that requires a free-form answer',
    type: StepType.QuizOpenQuestion,
    edgeType: EdgeType.SingleEdge,
    stepComponent: OpenEndQuestion,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'help_center',
    category: StepCategory.Quiz,
    mentions: [
      { id: 'score', value: 'Quiz Score' },
      { id: 'quiz_summary', value: 'Quiz Summary' },
    ],
    schema: openEndQuestionFormSchema,
  },
  [StepType.QuizMultipleChoiceQuestion]: {
    name: 'Multiple Choice',
    description: 'Ask a quiz question with multiple choice answers',
    type: StepType.QuizMultipleChoiceQuestion,
    stepComponent: MultiChoiceQuestion,
    edgeType: EdgeType.SingleEdge,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'quiz',
    category: StepCategory.Quiz,
    mentions: [
      { id: 'score', value: 'Score' },
      { id: 'quiz_summary', value: 'Quiz Summary' },
    ],
    schema: multiChoiceQuestionStepFormSchema,
  },
  [StepType.QuizTrueFalseQuestion]: {
    name: 'True/False',
    description: 'Ask a quiz question with true/false answers',
    type: StepType.QuizTrueFalseQuestion,
    stepComponent: TrueFalseQuestion,
    edgeType: EdgeType.SingleEdge,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'rule',
    category: StepCategory.Quiz,
    mentions: [
      { id: 'score', value: 'Score' },
      { id: 'quiz_summary', value: 'Quiz Summary' },
    ],
    schema: trueFalseQuestionStepFormSchema,
  },
  [StepType.QuizSummary]: {
    name: 'Announce Winner',
    description: 'Announce and determine the winner of the quiz, split the flow based on the score',
    type: StepType.QuizSummary,
    edgeType: EdgeType.MultiEdge,
    stepComponent: QuizSummary,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'campaign',
    category: StepCategory.Quiz,
    mentions: [],
    schema: quizSummaryFormSchema,
  },
  [StepType.RandomSplit]: {
    name: 'Randomizer',
    description: 'Split the flow randomly',
    type: StepType.RandomSplit,
    edgeType: EdgeType.MultiEdge,
    stepComponent: RandomMenu,
    plan: [AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'shuffle',
    category: StepCategory.Logic,
    mentions: [],
    schema: RandomMenuStepFormSchema,
  },
  [StepType.SendEmail]: {
    name: 'Send to Email',
    description: 'Send collected data to an email recipients',
    type: StepType.SendEmail,
    edgeType: EdgeType.SingleEdge,
    stepComponent: SendEmail,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    icon: 'email',
    category: StepCategory.Integration,
    mentions: [],
    schema: sendEmailFormSchema,
  },
  [StepType.SendTelegramNotification]: {
    name: 'Telegram Notification',
    description: 'Notify me via Teleform Notifications Bot',
    type: StepType.SendTelegramNotification,
    edgeType: EdgeType.SingleEdge,
    plan: [AccountPlanType.Basic, AccountPlanType.Pro, AccountPlanType.Premium],
    stepComponent: SendTelegramNotification,
    icon: 'notifications_active',
    category: StepCategory.Integration,
    mentions: [],
    schema: telegramNotificationStepFormSchema,
  },
  [StepType.SendData]: {
    name: 'External Integrations',
    description: 'Send collected data to external services',
    type: StepType.SendData,
    edgeType: EdgeType.SingleEdge,
    plan: [AccountPlanType.Pro, AccountPlanType.Premium],
    stepComponent: SendData,
    icon: 'send',
    category: StepCategory.Integration,
    mentions: [],
    schema: sendDataFormSchema,
  },
  [StepType.HumanHandoff]: {
    name: 'Human Handoff',
    description:
      'Notify me via Teleform Notifications Bot and terminate the flow for human intervention.  Make sure you have subscribed to the our bot.',
    type: StepType.HumanHandoff,
    edgeType: EdgeType.DeadEnd,
    plan: [AccountPlanType.Pro, AccountPlanType.Premium],
    stepComponent: HumanHandoff,
    icon: 'support_agent',
    category: StepCategory.Logic,
    mentions: [],
    schema: humanHandoffStepFormSchema,
  },
};

export const StepCategoryConfig: Record<StepCategory, StepCategoryProps> = {
  [StepCategory.SingleNodeInput]: {
    name: 'Ask a Question',
    color: 'primary',
    icon: 'arrow_right_alt',
  },
  [StepCategory.MultiNodeInput]: {
    name: 'Split the Flow Based on User Input',
    color: 'bulk',
    icon: 'arrow_split',
  },
  [StepCategory.Action]: {
    name: 'Provide Information or Action',
    color: 'secondary',
    icon: 'reply_all',
  },
  [StepCategory.Request]: {
    name: 'Request Data from User',
    color: 'info',
    icon: 'assignment',
  },
  [StepCategory.Quiz]: {
    name: 'Create a Quiz',
    color: 'messenger',
    icon: 'psychology_alt',
    tutorial: 'https://youtu.be/8HF142Lu1u4?si=Es3TgSvpa-QwefFL',
  },
  [StepCategory.Logic]: {
    name: 'Logic',
    color: 'warning',
    icon: 'manufacturing',
  },
  [StepCategory.Integration]: {
    name: 'Integrate with External Services',
    color: 'bulk',
    icon: 'integration_instructions',
  },
};
