import { Alert, Box } from '@mui/material';
import { Step } from 'react-joyride';
import palette from 'theme/palette';
import typography from 'theme/typography';

export enum TourSteps {
  AddStep = 'builder-tour-add-step',
  StepSelect = 'builder-tour-step-select',
  EditStep = 'builder-tour-edit-step',
  Toolbox = 'builder-tour-toolbox',
}

const commonTourStepProps: Partial<Step> = {
  disableBeacon: true,
  disableOverlayClose: true,
  disableCloseOnEsc: true,
  hideFooter: true,
  spotlightClicks: true,
  styles: {
    tooltip: {
      padding: '8px',
    },
    tooltipTitle: {
      padding: '16px 16px 8px',
    },
    tooltipContent: {
      padding: '0px 8px 8px',
      ...typography.body1,
      color: palette.text.secondary,
    },
  },
};

export const builderTourSteps: Step[] = [
  {
    ...commonTourStepProps,
    target: `.${TourSteps.AddStep}`,
    title: 'Welcome to the Bot Builder!',
    content: 'Click on the "+" button to add the first step to your bot.',
    placement: 'bottom',
    styles: {
      ...commonTourStepProps.styles,
      spotlight: {
        borderRadius: '50%',
      },
    },
  },
  {
    ...commonTourStepProps,
    target: `.${TourSteps.StepSelect}`,
    title: 'Select a Step',
    content: 'Select the type of the step you want to add.',
    placement: 'top',
  },
  {
    ...commonTourStepProps,
    target: `.${TourSteps.Toolbox}`,
    title: 'The Toolbox',
    content: (
      <Box>
        Here you can edit and customize the step you just created.
        <br />
        <Alert icon={false} severity='info' sx={{ mt: 2 }}>
          That is all for now! See you when you are ready to publish your bot.
        </Alert>
      </Box>
    ),
    placement: 'left',
    disableOverlayClose: false,
    disableCloseOnEsc: false,
  },
];
