import { Navigate, useOutlet } from 'react-router-dom';
import { RoutePath } from 'enums';
import { useAuth } from 'hooks';

const AnonymousLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  if (user) {
    return <Navigate to={RoutePath.Dashboard} />;
  }
  return outlet;
};

export default AnonymousLayout;
