import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { FormCheckBox, FormTextField } from 'components/index';
import FormArrayRemoveButton from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormArrayRemoveButton';
import { MultiChoiceQuestionStepFormFieldsOptionsNames } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/types';
import { MultiChoiceQuestionFormMultiChoiceFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/multi-choice-question/utils';

const MultiChoiceAnswersFormArrayField = ({
  getFieldName,
  index,
  remove,
  length,
}: FormArrayFieldProps) => {
  const { name, label, ...rest } =
    MultiChoiceQuestionFormMultiChoiceFieldsConfig[
      MultiChoiceQuestionStepFormFieldsOptionsNames.Option
    ];

  const {
    name: IsCorrectName,
    label: IsCorrectLabel,
    ...IsCorrectRest
  } = MultiChoiceQuestionFormMultiChoiceFieldsConfig[
    MultiChoiceQuestionStepFormFieldsOptionsNames.IsCorrect
  ];

  const answerFieldName = getFieldName(name);
  const IsCorrectFieldName = getFieldName(IsCorrectName);

  return (
    <Stack direction='row' alignItems='flex-start' gap={0.5} pl={1}>
      <Box width={28}>
        <FormCheckBox size='small' name={IsCorrectFieldName} {...IsCorrectRest} />
      </Box>
      <FormTextField
        size='small'
        placeholder={`${label} ${index + 1}`}
        name={answerFieldName}
        {...rest}
      />
      <FormArrayRemoveButton onClick={() => remove(index)} disabled={length === 2} />
    </Stack>
  );
};

export default MultiChoiceAnswersFormArrayField;
