import { SelectProps } from '@mui/material';
import { map } from 'lodash';
import FormTextFieldInlineSelect from 'components/form-fields/FormTextFieldInlineSelect';
import { validationOptions } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/types';

interface FormValidationFieldProps extends Omit<SelectProps, 'variant'> {
  name: string;
  startAdornment?: boolean;
}
const FormValidationField = (props: FormValidationFieldProps) => (
  <FormTextFieldInlineSelect
    size='small'
    textVariant='caption'
    startAdornment
    options={map(validationOptions, 'label')}
    text='add validation'
    icon='fact_check'
    tooltipPlacement='left'
    tooltip='Click to add a validation to the step, this will be used to validate the user input'
    {...props}
  />
);

export default FormValidationField;
