import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { FormSlider, FormTextField } from 'components/index';
import FormArrayRemoveButton from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormArrayRemoveButton';
import { RandomMenuStepFormFieldsMenuNames } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/types';
import { RandomMenuFormMenuFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/utils';

function numberToLetter(number: number): string {
  const asciiCodeOfA = 97;
  const offset = number - 1; // Since 1 should map to 'a', which is 97 in ASCII
  return String.fromCharCode(asciiCodeOfA + offset);
}
const RandomMenuFormArrayField = ({
  getFieldName,
  index,
  remove,
  length,
  onBlurCallback,
}: FormArrayFieldProps) => {
  const { watch } = useFormContext();

  const { name, label, ...rest } =
    RandomMenuFormMenuFieldsConfig[RandomMenuStepFormFieldsMenuNames.Option];

  const value = watch(getFieldName(name));

  const menuFieldName = getFieldName(name);

  return (
    <Stack direction='row' alignItems='center' gap={0.5} pl={1}>
      <Typography
        fontWeight='bolder'
        variant='body2'
        color='text.secondary'
        textTransform='uppercase'
      >
        {numberToLetter(index + 1)}
      </Typography>
      <Stack flex={1}>
        <FormSlider
          onBlurCallback={() => {
            onBlurCallback?.(index, value);
          }}
          track='inverted'
          valueLabelDisplay='auto'
          size='small'
          name={menuFieldName}
          step={1}
          {...rest}
        />
      </Stack>
      <Box width={80}>
        <FormTextField
          onBlurCallback={() => {
            onBlurCallback?.(index, value);
          }}
          InputProps={{
            endAdornment: (
              <Typography variant='body2' color='text.secondary'>
                %
              </Typography>
            ),
            inputProps: {
              min: 0,
              max: 100,
            },
          }}
          size='small'
          name={menuFieldName}
          {...rest}
        />
      </Box>
      <FormArrayRemoveButton onClick={() => remove(index)} disabled={length === 2} />
    </Stack>
  );
};

export default RandomMenuFormArrayField;
