import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AppLogo, GoogleButton } from '@common-components';
import { RoutePath } from 'enums';
import { AuthDialogWrapper, OrDivider } from 'pages/auth-pages/components';
import SignUpForm from 'pages/auth-pages/sign-up/SignUpForm';

const SignUpPage = () => (
  <AuthDialogWrapper areaLabel='sign-up-form'>
    <Box mb={2}>
      <AppLogo height={42} />
    </Box>
    <Stack mb={2}>
      <Typography align='center' variant='h4' component='h1'>
        Welcome!
      </Typography>
      <Typography align='center' variant='body1'>
        Sign Up, and Create Your Bot
      </Typography>
    </Stack>
    <GoogleButton />
    <OrDivider />
    <SignUpForm />
    <Typography align='center' variant='subtitle2'>
      Already have an account?{' '}
      <Link component={RouterLink} variant='subtitle2' to={`/${RoutePath.Login}`} underline='none'>
        Login
      </Link>
    </Typography>
  </AuthDialogWrapper>
);

export default SignUpPage;
