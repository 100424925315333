import axios from 'axios';
import { namespace } from './config';
import { transformChatData } from './helpers';

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? `https://api.teleform.io/${namespace}/`
      : `https://qaapi.teleform.io/${namespace}/`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetcher = (url: string) => api.get(url).then((res) => res.data);

export const fetchChatData = (url: string) =>
  api.get(url).then((res) => transformChatData(res.data));
