export enum SignUpFormFieldsNames {
  Email = 'email',
  Password = 'password',
  AgreeToTerms = 'agreeToTerms',
}

export interface SignUpFormFields {
  [SignUpFormFieldsNames.Email]: string;
  [SignUpFormFieldsNames.Password]: string;
  [SignUpFormFieldsNames.AgreeToTerms]: boolean;
}
