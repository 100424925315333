import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { format, formatDistanceToNowStrict } from 'date-fns';
import useMessageStatus from 'pages/app-pages/private-bulk-message-page/useMessageStatus';

import { PrivateChatBulkMessage } from 'types';

function useFormattedDate(deliveryDate: Date | undefined) {
  if (!deliveryDate) {
    return ['To be Determined', 'To be Determined', ''];
  }

  const date = new Date(deliveryDate);
  const formattedSendDate = format(date, "do 'of' MMM yyyy"); // More detailed date format
  const formattedDateAndTime = format(date, 'PPp'); // Date and time for display
  const relativeTime = formatDistanceToNowStrict(date, { addSuffix: true });

  return [formattedSendDate, formattedDateAndTime, relativeTime];
}

function ScheduleDateCell({ row }: GridRenderCellParams<PrivateChatBulkMessage>) {
  const { deliveryDate } = row;
  const { isMessageHasError, isDraftMessage } = useMessageStatus(row);
  const [formattedSendDate, formattedDateAndTime, relativeTime] = useFormattedDate(deliveryDate);

  const errorMessage = isMessageHasError ? row.error : '';
  const draftMessage = isDraftMessage && !isMessageHasError ? 'draft' : '';
  const messageDetails = `${errorMessage} ${relativeTime} ${draftMessage}`.trim();

  return (
    <Stack width={1}>
      <Typography noWrap variant='body2' title={formattedSendDate}>
        {formattedDateAndTime}
      </Typography>
      <Typography noWrap variant='caption' color='text.secondary' title={messageDetails}>
        {messageDetails}
      </Typography>
    </Stack>
  );
}

export default ScheduleDateCell;
