import { FormControl, Typography } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import { useIsMobile } from 'hooks';
import { Mention } from 'types';
import QuillEditorWithMention from './QuillEditorWithMention';
// Path to the QuillEditorWithMention component
export interface FormTextEditorProps {
  name: string;
  autoFocus?: boolean;
  placeholder?: string;
  label?: string;
  mentions?: Mention[];
  commands?: Mention[];
  minHeight?: number;
  maxHeight?: number;
  showToolbarOnFocus?: boolean;
  characterLimit?: number;
}

function FormTextEditor({
  name,
  label,
  mentions,
  commands,
  placeholder,
  autoFocus,
  minHeight,
  maxHeight,
  showToolbarOnFocus,
  characterLimit,
}: FormTextEditorProps) {
  const { control, getValues, reset } = useFormContext();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const editorInitiallyRested = useRef(false); // Ref to track initialization
  const { isMobile } = useIsMobile();
  const calculateMaxHeight = isMobile ? 200 : 300;
  useEffect(() => {
    if (editorLoaded && !editorInitiallyRested.current) {
      // Reset the form state (dirty, touched) without changing values
      reset({ ...getValues() }, { keepValues: true });
      editorInitiallyRested.current = true;
    }
  }, [editorLoaded, getValues, reset]);

  return (
    <FormControl className='notranslate'>
      {label && (
        <Typography variant='body2' pb={1} color='text.primary'>
          {label}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <QuillEditorWithMention
              {...field}
              error={!!error}
              mentions={mentions}
              commands={commands}
              placeholder={placeholder}
              autoFocus={autoFocus}
              minHeight={minHeight}
              maxHeight={maxHeight ?? calculateMaxHeight}
              showToolbarOnFocus={showToolbarOnFocus}
              emojiToolbar={false}
              emojiTextarea={false}
              characterLimit={characterLimit}
              onEditorReady={() => {
                setEditorLoaded(true);
              }}
            />
            <FormCollapseHelperText error={error} />
          </>
        )}
      />
    </FormControl>
  );
}

export default memo(FormTextEditor);
