import { useCopyToClipboard } from 'usehooks-ts';
import IconButton, { IconButtonProps } from 'components/IconButton';
import { useToast } from 'hooks';

interface CopyTextIconButtonProps extends Omit<IconButtonProps, 'icon' | 'onClick'> {
  text: string;
  successMessage?: string;
}
const CopyTextIconButton = ({
  text,
  sx,
  successMessage = 'Copied to clipboard',
  ...rest
}: CopyTextIconButtonProps) => {
  const [, copy] = useCopyToClipboard();
  const toast = useToast();
  return (
    <IconButton
      sx={{
        cursor: 'copy',
        ...sx,
      }}
      icon='content_copy'
      onClick={async () => {
        await copy(text);
        toast({
          message: successMessage,
          variant: 'success',
        });
      }}
      {...rest}
    />
  );
};

export default CopyTextIconButton;
