import { TooltipProps } from '@mui/material';
import { ReactNode } from 'react';

export type BasicColors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'purple'
  | 'red'
  | 'chatbot'
  | 'messenger'
  | 'bulk';

export type BasicInfoColors = 'error' | 'warning' | 'success' | 'info';

export type TextColors = 'primary' | 'secondary' | 'disabled';

export const BASIC_COLORS_VALUES = [
  'primary',
  'secondary',
  'error',
  'warning',
  'success',
  'info',
  'purple',
  'red',
  'chatbot',
  'messenger',
  'bulk',
];

export const TEXT_COLORS_VALUES = ['primary', 'secondary', 'disabled'];

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface TooltipWrapperProps {
  tooltip?: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  tooltipProps?: Omit<TooltipProps, 'children' | 'title' | 'placement'>;
}
