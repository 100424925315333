import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { format, formatDistanceToNowStrict } from 'date-fns';
import useMessageStatus from 'pages/app-pages/messages-scheduler-page/useMessageStatus';

import { ChatGroupMessage } from 'types';

function ScheduleDateCell({ row }: GridRenderCellParams<ChatGroupMessage>) {
  const { deliveryDate } = row;
  const { isExpiredMessage, isDraftMessage } = useMessageStatus(row);

  const formattedSendDate = deliveryDate
    ? format(new Date(deliveryDate), "do 'of' MMM yyyy")
    : 'TBD';
  const formattedDateAndTime = deliveryDate ? format(new Date(deliveryDate), 'PPp') : 'TBD';

  const relativeTime = deliveryDate
    ? formatDistanceToNowStrict(new Date(deliveryDate), { addSuffix: true })
    : 'pending';

  return (
    <Stack>
      <Typography noWrap variant='body2' title={formattedSendDate}>
        {formattedDateAndTime}
      </Typography>
      <Typography noWrap variant='caption' color='text.secondary'>
        {isExpiredMessage ? 'expired' : relativeTime} {isDraftMessage ? '- draft' : ''}
      </Typography>
    </Stack>
  );
}

export default ScheduleDateCell;
