import { Stack } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormSection, FormTextField, FormTextFieldInlineEdit } from '@common-components';
import { useFormProvider, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';

import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { WaitForTimeStepFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/wait-for-time/types';

import {
  defaultWaitForTimeStepFormValues,
  waitForFormFieldsConfig,
  waitForTimeStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/wait-for-time/utils';

interface WaitForTimeProps {
  id: string;
  defaultValues?: Partial<WaitForTimeStepFormFields>;
  defaultName: string;
}

const WaitForTime = ({
  defaultValues,

  id,
  defaultName,
}: WaitForTimeProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultWaitForTimeStepFormValues, // default values
    ...defaultValues, // this will override the defaults if present
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<WaitForTimeStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<WaitForTimeStepFormFields>({
    schema: waitForTimeStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: WaitForTimeStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, labels, days, hours, minutes } = waitForFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />

        <FormSection
          title='Wait for'
          infoIcon={{
            icon: 'info',
            tooltip:
              '  This step will wait for the specified amount of time before continuing. The maximum wait time is 365 days, 24 hours, and 60 minutes.',
          }}
        >
          <Stack direction='row' gap={1}>
            <FormTextField
              {...days}
              type='number'
              size='small'
              inputProps={{
                min: 0,
                max: 365,
              }}
            />
            <FormTextField
              {...hours}
              type='number'
              size='small'
              inputProps={{
                min: 0,
                max: 24,
              }}
            />
            <FormTextField
              {...minutes}
              type='number'
              size='small'
              inputProps={{
                min: 0,
                max: 60,
              }}
            />
          </Stack>
        </FormSection>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons initiallyEnabled formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(WaitForTime);
