import { ButtonBase, StackProps } from '@mui/material';

export const selectCardProps = ({ disabled }: { disabled?: boolean }): Partial<StackProps> => ({
  sx: {
    backgroundColor: (theme) => theme.palette.background.default,
    border: 1,
    flexDirection: 'row',
    borderRadius: 1,
    borderColor: 'divider',
    textAlign: 'left',
    p: 2,
    gap: 2,
    width: 1,
    height: 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents: 'auto',
    position: 'relative',
    overflow: 'hidden',
    transition: (theme) =>
      theme.transitions.create(['background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
      }),
    '&:hover': {
      boxShadow: disabled ? 'none' : (theme) => theme.shadows[4],
    },
    '&:after': {
      content: 'none',
      position: 'absolute',
      top: 18,
      right: -30,
      backgroundColor: (theme) => theme.palette.warning.main,
      color: (theme) => theme.palette.error.contrastText,
      typography: 'caption',
      lineHeight: 1,
      px: 3,
      py: 0.75,
      borderTopRightRadius: 1,
      borderBottomLeftRadius: 1,
      zIndex: 1,
      transform: 'rotate(45deg)',
    },
  },
  component: ButtonBase,
});
