import { Box, Stack, useTheme } from '@mui/material';
import {
  Background,
  BackgroundVariant,
  Edge,
  MarkerType,
  Node,
  NodeTypes,
  PanOnScrollMode,
  ReactFlow,
} from 'reactflow';

import { useIsMobile } from 'hooks';
import { useUndoRedoContext } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import AiModal from 'pages/app-pages/chatbot-builder-page/dialogs/ai-form/AiModal';
import edgeTypes from 'pages/app-pages/chatbot-builder-page/EdgeTypes';
import { useBuilderView } from 'pages/app-pages/chatbot-builder-page/hooks/useBuilderView';
import { useConfirmUnsavedNodeChanges } from 'pages/app-pages/chatbot-builder-page/hooks/useConfirmUnsavedNodeChanges';
import useLayout from 'pages/app-pages/chatbot-builder-page/hooks/useLayout';
import { useSelectNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSelectNode';
import BuilderMiniMap from 'pages/app-pages/chatbot-builder-page/mini-map/BuilderMiniMap';
import { InputNode, StartNode } from 'pages/app-pages/chatbot-builder-page/nodes';
import { NodeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import BuilderToolbox from 'pages/app-pages/chatbot-builder-page/toolbox/BuilderToolbox';
import BuilderTopBar from 'pages/app-pages/chatbot-builder-page/top-bar/BuilderTopBar';
import { useDirtyStore } from 'store';
import { BaseNodeProps } from 'types';
import BuilderPanels from './builder-panels/BuilderPanels';
import 'reactflow/dist/style.css';

const nodeTypes: NodeTypes = {
  [NodeType.Input]: InputNode,
  [NodeType.Start]: StartNode,
};

export type BuilderMainProps = {
  isLoading: boolean;
  name: string;
  initialNodes?: Node<BaseNodeProps>[];
  initialEdges?: Edge[];
};

function BuilderMain({ name, initialEdges, initialNodes }: BuilderMainProps) {
  const theme = useTheme();
  const { isMobile } = useIsMobile();
  const selectNode = useSelectNode();

  const { setSelectedStepType, closeToolbox, setSelectedNodeId, setEditorIsFocused } =
    useBuilderStore((state) => ({
      setSelectedStepType: state.setSelectedStepType,
      setSelectedNodeId: state.setSelectedNodeId,
      setEditorIsFocused: state.setEditorIsFocused,
      closeToolbox: state.closeToolbox,
    }));

  const { isDirty, setIsDirty } = useDirtyStore((state) => ({
    isDirty: state.isDirty,
    setIsDirty: state.setIsDirty,
  }));

  const closeToolboxAndResetSelection = () => {
    closeToolbox();
    setIsDirty(false);
    setSelectedStepType(null);
    setEditorIsFocused(false);
    if (!isMobile) {
      setSelectedNodeId(null);
      selectNode(null);
    }
  };

  // const { startTour, stopTour, currentStep, run } = useBuilderTour();

  const { boxRef, resetView, fitView, zoomOut, zoomIn } = useBuilderView();

  const confirmUnsavedNodeChanges = useConfirmUnsavedNodeChanges();

  useLayout();

  const { undo, redo, canUndo, canRedo } = useUndoRedoContext();

  return (
    <>
      <Stack width={1}>
        <BuilderTopBar name={name} />
        <AiModal />
        <Box
          bgcolor='grey.100'
          flexGrow={1}
          ref={boxRef}
          sx={{
            '& .react-flow__edge': {
              pointerEvents: 'none',
              cursor: 'inherit',
              '& .react-flow__edge-textwrapper': {
                pointerEvents: 'none',
                cursor: 'inherit',
              },
            },
            '& .react-flow__handle': {
              '&.connectionindicator': {
                pointerEvents: 'none',
                cursor: 'inherit',
              },
            },
          }}
        >
          <ReactFlow
            defaultNodes={initialNodes}
            defaultEdges={initialEdges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            deleteKeyCode={null}
            zoomOnDoubleClick={false}
            minZoom={0.2}
            maxZoom={1}
            proOptions={{ hideAttribution: true }}
            nodeOrigin={[0.5, 0.5]}
            nodesDraggable={false}
            nodesConnectable={false}
            edgesFocusable={false}
            onPaneClick={() => {
              if (isDirty) {
                confirmUnsavedNodeChanges(() => {
                  closeToolboxAndResetSelection();
                });
                return;
              }
              closeToolboxAndResetSelection();
            }}
            defaultEdgeOptions={{
              markerEnd: {
                type: MarkerType.Arrow,
              },
            }}
            panOnScrollMode={PanOnScrollMode.Horizontal}
          >
            <BuilderPanels
              resetView={resetView}
              fitView={fitView}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              undo={undo}
              redo={redo}
              canUndo={canUndo}
              canRedo={canRedo}
            />
            <Background
              variant={BackgroundVariant.Dots}
              style={{ visibility: 'hidden' }}
              color={theme.palette.grey[800]}
              gap={12}
              size={1}
            />
            {!isMobile && <BuilderMiniMap />}
          </ReactFlow>
        </Box>
      </Stack>
      <BuilderToolbox />
    </>
  );
}

export default BuilderMain;
