import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { StringKeys } from 'utils';

interface SearchProps<T> {
  data?: T[];
  searchKey: StringKeys<T>[];
}
export const useSearch = <T extends Record<string, any>>({
  data = [],
  searchKey,
}: SearchProps<T>) => {
  const [search, setSearch] = useState<string>('');

  const debounceSearch = useDebounce(search, 400);
  const handleSearchChange = useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  const filteredItems = useMemo(
    () =>
      data.filter((item) => {
        if (debounceSearch.length > 1) {
          return searchKey.some((key) =>
            item[key].toLowerCase().includes(debounceSearch.toLowerCase()),
          );
        }
        return true;
      }) || [],
    [data, debounceSearch, searchKey],
  );

  const noSearchResults = useMemo(
    () => debounceSearch.length > 1 && filteredItems.length === 0 && data.length > 0,
    [data.length, filteredItems.length, debounceSearch.length],
  );

  const resetSearch = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  return {
    search,
    handleSearchChange,
    filteredItems,
    noSearchResults,
    resetSearch,
  };
};
