import { Link, Stack, Typography } from '@mui/material';
import { FormTextField } from 'components/index';
import StepTitle from 'forms/create-project/components/StepTitle';
import { createProjectFieldsConfig } from 'forms/create-project/utils';
import { useIsMobile } from 'hooks';

const ProjectDetailsStep = () => {
  const { name, description } = createProjectFieldsConfig;
  const { isMobile } = useIsMobile();

  return (
    <Stack width={1} gap={2}>
      <StepTitle title='Project Details' />
      <FormTextField autoFocus={!isMobile} {...name} />
      <FormTextField multiline rows={2} {...description} />
      <Typography color='text.secondary' variant='body2'>
        By proceeding with creating your project, you acknowledge and agree to adhere to the laws of
        your country. You commit not to use our service for the promotion of violence or to
        distribute pornographic content through any connected bots. Please be aware that these{' '}
        <Link
          fontWeight='bold'
          target='_blank'
          href='https://www.teleform.io/terms-conditions.html'
          rel='noreferrer'
        >
          Terms of Service
        </Link>{' '}
        are subject to updates and changes in the future.
      </Typography>
    </Stack>
  );
};

export default ProjectDetailsStep;
