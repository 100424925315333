import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { AppLoader } from '@common-components';
import { LocalStorageKey } from 'config';
import { AccountPlanType, RoutePath } from 'enums';
import { useAppBarHeight, useAuth, useHotjarIdentify } from 'hooks';
import { mainBackground } from 'theme/utils';
import AppHeader from './app-header/AppHeader';
import UpgradeAccountBanner from './UpgradeAccountBanner';

const AppLayout = () => {
  const appBarHeight = useAppBarHeight();
  useHotjarIdentify();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [showCoupon] = useLocalStorage(LocalStorageKey.ShowCoupon, false);
  const { user } = useAuth();
  const { pathname } = useLocation();

  const isRoot = pathname === '/';

  useEffect(() => {
    if (
      user &&
      showCoupon &&
      (!user?.organization?.accountPlan?.type ||
        user?.organization?.accountPlan?.type === AccountPlanType.Basic)
    ) {
      navigate(`${RoutePath.Dashboard}/${RoutePath.ApplyCoupon}`);
    }

    if (user?.isPending) {
      navigate(`${RoutePath.Dashboard}/${RoutePath.AcceptInvitation}`);
    }
  }, [
    navigate,
    searchParams,
    showCoupon,
    user,
    user?.isPending,
    user?.organization?.accountPlan?.type,
  ]);

  return (
    <>
      <UpgradeAccountBanner />
      <AppHeader />
      <Box display='flex' flexGrow={1} bgcolor={mainBackground} mt={`${appBarHeight}px`}>
        {isRoot && (
          <Stack flex={1}>
            <AppLoader />
          </Stack>
        )}
        <Outlet />
      </Box>
    </>
  );
};

export default AppLayout;
