import { array, bool, object, ObjectSchema, string } from 'yup';
import messages from 'messages';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  OptionsQuestionStepFormFields,
  OptionsQuestionStepFormFieldsNames,
  OptionsQuestionStepFormFieldsOptionsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-question/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import {
  editorValidation,
  emptyEditorValue,
  maxTelegramButtonLength,
  variableValidation,
} from 'utils';

export const OptionsQuestionStepFormSchema: ObjectSchema<OptionsQuestionStepFormFields> = object({
  [OptionsQuestionStepFormFieldsNames.NodeId]: string().required('Node is required'),
  [OptionsQuestionStepFormFieldsNames.Name]: string().required('Name is required'),
  [OptionsQuestionStepFormFieldsNames.Input]: editorValidation,
  [OptionsQuestionStepFormFieldsNames.Variable]: variableValidation,
  [OptionsQuestionStepFormFieldsNames.Answers]: array(
    object({
      [OptionsQuestionStepFormFieldsOptionsNames.Answer]: string()
        .max(
          maxTelegramButtonLength,
          `Answer should be less than ${maxTelegramButtonLength} characters`,
        )
        .required('Option is required'),
      [OptionsQuestionStepFormFieldsOptionsNames.NodeId]: string().required('Node is required'),
      [OptionsQuestionStepFormFieldsOptionsNames.Name]: string().required('Name is required'),
    }),
  ).required(),
  [OptionsQuestionStepFormFieldsNames.RemoveAnswerOptions]: bool(),
  [OptionsQuestionStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultOptionsQuestionStepFormValues: OptionsQuestionStepFormFields = {
  [OptionsQuestionStepFormFieldsNames.NodeId]: '',
  [OptionsQuestionStepFormFieldsNames.Name]: 'Closed-Ended Question',
  [OptionsQuestionStepFormFieldsNames.Input]: emptyEditorValue,
  [OptionsQuestionStepFormFieldsNames.Answers]: [],
  [OptionsQuestionStepFormFieldsNames.RemoveAnswerOptions]: false,
  [OptionsQuestionStepFormFieldsNames.Labels]: [],
};

export const OptionsQuestionFormFieldsConfig: Record<
  OptionsQuestionStepFormFieldsNames,
  FormFieldProps
> = {
  [OptionsQuestionStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: OptionsQuestionStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [OptionsQuestionStepFormFieldsNames.Input]: {
    placeholder: '* Question',
    name: OptionsQuestionStepFormFieldsNames.Input,
    type: 'textarea',
  },
  [OptionsQuestionStepFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: OptionsQuestionStepFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [OptionsQuestionStepFormFieldsNames.Answers]: {
    label: 'Answers',
    placeholder: 'Add Answer',
    name: OptionsQuestionStepFormFieldsNames.Answers,
    autoComplete: 'off',
    type: 'text',
  },
  [OptionsQuestionStepFormFieldsNames.NodeId]: {
    name: OptionsQuestionStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [OptionsQuestionStepFormFieldsNames.RemoveAnswerOptions]: {
    label: messages.builder.removeAnswerOptions,
    name: OptionsQuestionStepFormFieldsNames.RemoveAnswerOptions,
    type: 'checkbox',
  },
  [OptionsQuestionStepFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const OptionsQuestionFormOptionsFieldsConfig: Record<
  OptionsQuestionStepFormFieldsOptionsNames,
  FormFieldProps
> = {
  [OptionsQuestionStepFormFieldsOptionsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: OptionsQuestionStepFormFieldsOptionsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [OptionsQuestionStepFormFieldsOptionsNames.Answer]: {
    label: 'Answer',
    name: OptionsQuestionStepFormFieldsOptionsNames.Answer,
    autoComplete: 'off',
    type: 'text',
  },
  [OptionsQuestionStepFormFieldsOptionsNames.NodeId]: {
    name: OptionsQuestionStepFormFieldsOptionsNames.NodeId,
    type: 'hidden',
  },
};
