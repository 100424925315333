import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'enums';
import { Mode } from 'forms/create-project/types';
import { appConfig } from 'forms/create-project/utils';
import AppBox from 'pages/projects-page/AppBox';
import { Project } from 'types';
import { areAllAppsConnected } from 'utils';

interface AppsIconsListProps {
  id: Project['id'];
  apps: Project['apps'];
  projectName: Project['name'];
  isPublic: boolean;
}

const AppsIconsList = ({ id, apps, projectName, isPublic }: AppsIconsListProps) => {
  const navigate = useNavigate();

  const isAllAppsConnected = areAllAppsConnected(apps);

  return (
    <Stack flexWrap='wrap' direction='row' alignItems='center' gap={1}>
      {Object.values(appConfig).map(({ type: appName, cta, icon, color, appUrl }) => {
        const isActive = apps?.find((app) => app.type === appName)?.isActive;
        if (apps?.some((app) => app.type === appName)) {
          return (
            <AppBox
              isActive={isActive}
              isPublic={isPublic}
              key={appName}
              tooltip={`${cta} ►`}
              icon={icon}
              color={color}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(`/${appUrl}/${id}`);
              }}
            />
          );
        }
        return null;
      })}
      {!isAllAppsConnected && (
        <AppBox
          tooltip='Connect more Apps'
          icon='add'
          color='info'
          borderStyle='dashed'
          sx={{
            bgcolor: 'transparent',
            borderColor: 'info.lighter',
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigate(RoutePath.CreateProject, {
              state: {
                mode: Mode.Connect,
                apps,
                projectId: id,
                projectName,
              },
            });
          }}
        />
      )}
    </Stack>
  );
};

export default AppsIconsList;
