import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormSection, FormTextField, FormTextFieldInlineEdit } from '@common-components';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { RequestContactFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-contact/types';

import {
  defaultContactDataFormValues,
  requestContactFieldsConfig,
  requestContactStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-contact/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface RequestContactProps {
  id: string;
  defaultValues?: Partial<RequestContactFormFields>;
  defaultName: string;
  mentions?: Mention[];
  commands?: Mention[];
}

const RequestContact = ({
  defaultValues,
  mentions,
  commands,
  id,
  defaultName,
}: RequestContactProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultContactDataFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || defaultContactDataFormValues.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<RequestContactFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<RequestContactFormFields>({
    schema: requestContactStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { reset } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: RequestContactFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, labels, button, input } = requestContactFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
        <FormSection title='Action Button'>
          <FormTextField {...button} size='small' />
        </FormSection>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formMethods.formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(RequestContact);
