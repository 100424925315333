import { GridColDef } from '@mui/x-data-grid';
import { formatDistanceToNowStrict } from 'date-fns';
import { TextCell } from '@common-components';
import MessageCell from 'pages/app-pages/private-bulk-message-page/MessageCell';
import MessageDropdownWrapper from 'pages/app-pages/private-bulk-message-page/MessageDropdownWrapper';
import QueryCell from 'pages/app-pages/private-bulk-message-page/QueryCell';
import RecipientsCell from 'pages/app-pages/private-bulk-message-page/RecipientsCell';
import ScheduleDateCell from 'pages/app-pages/private-bulk-message-page/ScheduleDateCell';
import StatusCell from 'pages/app-pages/private-bulk-message-page/StatusCell';
import { BulkMessageColumns } from 'pages/app-pages/private-bulk-message-page/types';

export const bulkMessagesTableConfig: GridColDef[] = [
  {
    field: 'id',
    headerName: '',
    width: 64,
    align: 'right',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => <MessageDropdownWrapper id={value} icon='more_vert' />,
  },
  {
    field: BulkMessageColumns.Status,
    headerName: '',
    headerAlign: 'center',
    width: 64,
    align: 'left',
    renderCell: StatusCell,
    sortable: false,
  },
  {
    field: BulkMessageColumns.DeliveryDate,
    headerName: 'Delivery Date & Time',
    renderCell: ScheduleDateCell,
    width: 300,
  },
  {
    field: BulkMessageColumns.Recipients,
    headerName: 'Delivery Status',
    width: 240,
    renderCell: RecipientsCell,
    sortable: false,
  },
  {
    field: BulkMessageColumns.ChatFilter,
    headerName: 'Delivery Group',
    width: 240,
    renderCell: QueryCell,
    sortable: false,
  },
  {
    field: BulkMessageColumns.Message,
    headerName: 'Message',
    flex: 1,
    minWidth: 200,
    renderCell: MessageCell,
  },
  {
    field: BulkMessageColumns.UpdatedAt,
    headerName: 'Modified',
    width: 200,
    valueFormatter: ({ value }) => formatDistanceToNowStrict(new Date(value), { addSuffix: true }),
    renderCell: TextCell,
  },
];
