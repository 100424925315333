import { Box, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { Image } from 'mui-image';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { Button, ConfirmationDialog } from '@common-components';
import { api, ApiKey } from 'api';
import robotSleepImage from 'assets/images/robot-sleep.svg';
import { useMenu, useServerError, useToast } from 'hooks';
import LinkBox from 'pages/app-pages/chatbot-builder-page/dialogs/publish/LinkBox';

import { SubmitButton } from 'pages/auth-pages/components';
import { useDirtyStore } from 'store';
import { Project } from 'types';

const BotSettings = ({ project, isLoading }: { project?: Project; isLoading: boolean }) => {
  const { id } = project ?? {};
  const navigate = useNavigate();
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const toastServerError = useServerError();
  const toast = useToast();
  const { value: revoking, setTrue: startRevoking, setFalse: stopRevoking } = useBoolean(false);
  const { openMenu: openRevokePrompt, ...restRevokePrompt } = useMenu();

  const handleOnRevokeToken = async () => {
    setIsDirty(false);
    startRevoking();
    try {
      await api.patch<Project, AxiosResponse<Project>, Partial<Project>>(
        `${ApiKey.Project}/${id}`,
        {
          token: '',
        },
      );
      await mutate(`${ApiKey.Project}/${id}`);
      toast({
        message: 'Bot token revoked successfully',
        variant: 'success',
      });

      stopRevoking();
    } catch (error: any) {
      stopRevoking();
      toastServerError(error);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Stack width={1} alignItems='flex-start' gap={2}>
        {project?.token ? (
          <Stack mt={2} width={1} gap={2}>
            {project?.botUsername && (
              <>
                <LinkBox
                  value={`https://t.me/${project.botUsername}`}
                  label='Direct Bot Link'
                  copyText='Bot Link copied'
                  icon='smart_toy'
                  infoIcon
                  helpText='This is the direct link to your bot. Share it with your users to start a conversation.'
                />
                <LinkBox
                  value={project?.trackingUrl || ''}
                  copyText='Magic Link copied'
                  label='Magic Link'
                  icon='link'
                  infoIcon
                  helpText={`This magic link captures the visitor's IP, browser information, and any additional URL parameters before redirecting to your Telegram bot. You can utilize this data within your chatbot builder and forward it to your Webhook for enhanced interaction.`}
                />
              </>
            )}
            <LinkBox
              value={`**** **** **** ${project?.token}`}
              label='Bot Token'
              icon='lock'
              hideCta
              //   helpText='Share this link to collect query parameters from url, send them to your bot and start a conversation.'
            />
            <Box>
              <SubmitButton
                submitting={revoking}
                variant='outlined'
                fullWidth
                size='medium'
                onClick={openRevokePrompt}
                color='error'
              >
                Revoke Token
              </SubmitButton>
            </Box>
          </Stack>
        ) : (
          <Stack width={1} alignItems='center' gap={2}>
            <Stack width={1} alignItems='center' bgcolor='grey.50' borderRadius={1} p={2}>
              <Image width={200} duration={0} src={robotSleepImage} />
            </Stack>
            <Button
              fullWidth
              variant='outlined'
              size='medium'
              color='primary'
              onClick={() => {
                navigate(`publish`);
              }}
            >
              Activate Bot Token
            </Button>
          </Stack>
        )}
      </Stack>
      <ConfirmationDialog
        submittingText='Revoking...'
        text='Are you sure you want to revoke the bot token? It will disconnect the bot from Telegram.'
        onConfirm={handleOnRevokeToken}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        {...restRevokePrompt}
      />
    </>
  );
};

export default BotSettings;
