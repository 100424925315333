import { KeyboardEvent, MouseEvent, useCallback, useMemo, useState } from 'react';

type EventTypes =
  | MouseEvent<HTMLButtonElement>
  | MouseEvent<HTMLDivElement>
  | MouseEvent<HTMLSpanElement>
  | KeyboardEvent<HTMLButtonElement>
  | KeyboardEvent<HTMLDivElement>
  | KeyboardEvent<HTMLSpanElement>;

export interface OptionsMenuState {
  isMenuOpen: boolean;
  closeMenu: () => void;
  openMenu: (event?: EventTypes) => void;
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchor: HTMLElement | null) => void;
}

export function useMenu(): OptionsMenuState {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const openMenu = useCallback((event?: EventTypes) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return useMemo(
    () => ({ anchorEl, isMenuOpen, openMenu, closeMenu, setAnchorEl }),
    [anchorEl, closeMenu, isMenuOpen, openMenu, setAnchorEl],
  );
}
