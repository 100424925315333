import { useCallback, useMemo } from 'react';
import { PrivateChatBulkMessageStatus } from 'enums';
import { PrivateChatBulkMessage } from 'types';

const useMessageStatus = (message?: PrivateChatBulkMessage) => {
  const isScheduleMessage = useMemo(() => !!message?.startDate, [message]);

  const isMessageSent = useMemo(() => !!message?.sendDate, [message]);

  const isMessageHasError = useMemo(() => !!message?.error, [message]);

  const isFutureMessage = useMemo(
    () => !!message?.startDate && new Date(message?.startDate) > new Date(),
    [message?.startDate],
  );

  const isDraftMessage = useCallback(
    () => message?.status === PrivateChatBulkMessageStatus.Draft,
    [message?.status],
  );

  return {
    isScheduleMessage,
    isMessageSent,
    isMessageHasError,
    isFutureMessage,
    isDraftMessage: isDraftMessage(),
  };
};

export default useMessageStatus;
