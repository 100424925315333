import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormArrayFields, FormSection, FormTextFieldInlineEdit } from 'components/index';
import { useFormProvider, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import RandomMenuFormArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/RandomMenuFormArrayField';
import {
  RandomMenuStepFormFields,
  RandomMenuStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/types';
import {
  defaultRandomMenuStepFormValues,
  RandomMenuFormFieldsConfig,
  RandomMenuStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/random/utils';

interface OptionsMenuProps {
  id: string;
  defaultValues?: Partial<RandomMenuStepFormFields>;
}

const RandomMenu = ({ defaultValues, id }: OptionsMenuProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultRandomMenuStepFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
    menu: isEmpty(defaultValues?.menu)
      ? [
          { name: 'Node 1', option: 50, nodeId: uuid() },
          { name: 'Node 2', option: 50, nodeId: uuid() },
        ]
      : defaultValues?.menu,
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<RandomMenuStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<RandomMenuStepFormFields>({
    schema: RandomMenuStepFormSchema,
    defaultValues: localDefaultValues,
  });
  const { name, labels, menu } = RandomMenuFormFieldsConfig;

  const updateNodeFormData = useUpdateNodeFormData();

  const { reset, formState, setValue, getValues, watch } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const watchSlider = watch(RandomMenuStepFormFieldsNames.Menu);

  const distributeSliderValues = useCallback(
    (changedIndex: number = -1, newValue: number = -1) => {
      const currentValues = getValues(RandomMenuStepFormFieldsNames.Menu);

      if (changedIndex !== -1 && newValue !== -1) {
        // Adjust the changed slider's value if parameters are provided
        currentValues[changedIndex].option = Math.max(0, Math.min(newValue, 100));
      }

      const total = currentValues.reduce((acc, val) => acc + val.option, 0);

      if (total === 100) {
        setValue(RandomMenuStepFormFieldsNames.Menu, currentValues);
        return;
      }

      // Determine if we need to add or subtract
      let amountToAdjust = 100 - total;

      // Redistribute the amount, starting from the last slider
      for (let i = currentValues.length - 1; i >= 0; i -= 1) {
        if (i !== changedIndex) {
          const currentOption = currentValues[i].option;
          const adjustment =
            amountToAdjust > 0
              ? Math.min(100 - currentOption, amountToAdjust)
              : Math.max(-currentOption, amountToAdjust);

          currentValues[i].option += adjustment;
          amountToAdjust -= adjustment;

          if (amountToAdjust === 0) {
            break;
          }
        }
      }

      setValue(RandomMenuStepFormFieldsNames.Menu, currentValues);
    },
    [getValues, setValue],
  );

  useEffect(() => {
    distributeSliderValues();
  }, [distributeSliderValues, watchSlider.length]);

  const handleOnSubmit = async (formData: RandomMenuStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <FormSection title={menu.label}>
          <Stack mt={1}>
            <FormArrayFields
              gap={1}
              appendValue={{ name: `Option ${watchSlider.length + 1}`, nodeId: uuid(), option: 0 }}
              name={menu.name}
              FormArrayFieldComponent={RandomMenuFormArrayField}
              addButtonText='Add Random Split'
              noAppend={watchSlider.length >= 5}
              onBlurCallback={(index, value) => {
                distributeSliderValues(index, value);
              }}
            />
          </Stack>
        </FormSection>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons initiallyEnabled formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(RandomMenu);
