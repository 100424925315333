import {
  Box,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfToday,
  format,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { Dictionary, isEmpty, times } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { Button, CardHeader, Icon, NoDataOverlay } from '@common-components';
import CustomDataGridSlots from 'components/data-grid/CustomDataGridSlots';
import { LocalStorageKey } from 'config';
import { AccountPlanType, ExtendedUserRole, RoutePath } from 'enums';
import { Mode } from 'forms/create-project/types';
import {
  useFeatureAccess,
  useGetChatActivity,
  useGetProjects,
  useGetSupportTickets,
  useIsMobile,
  useMenu,
  useTableHeight,
} from 'hooks';
import { useGetOrganizationDashboardData } from 'hooks/api/project/useGetOrganizationDashboardData';
import { getUpdatedColumns } from 'pages/activity-page/columnsConfig';
import { ChatColumns } from 'pages/activity-page/types';
import useChatTable from 'pages/activity-page/useChatTable';
import { ChartData, chartOptions } from 'pages/dashboard-page/config';
import DisplayBox from 'pages/dashboard-page/DisplayBox';
import GridCard from 'pages/projects-page/GridCard';
import GridCardSkeleton from 'pages/projects-page/GridCardSkeleton';
import { menuMinWidth } from 'theme/utils';
import { sortByDate, truncateMultiLineText } from 'utils';

const columnsToHide = [
  ChatColumns.Assignee,
  ChatColumns.Status,
  ChatColumns.ConversationCount,
  ChatColumns.IsRead,
  ChatColumns.IsArchived,
];

const ticketColumnsToHide = [
  ChatColumns.Labels,
  ChatColumns.ConversationCount,
  ChatColumns.Assignee,
  ChatColumns.IsRead,
  ChatColumns.IsArchived,
];
const ticketColumnsToHideForOrganization = [
  ChatColumns.Labels,
  ChatColumns.ConversationCount,
  ChatColumns.Note,
  ChatColumns.IsRead,
  ChatColumns.IsArchived,
];

const gridStyle: SxProps<Theme> = {
  cursor: 'pointer',
  [`& .${gridClasses.columnHeader}`]: {
    outline: 'none !important',
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  [`& .${gridClasses['row--lastVisible']}`]: {
    [`& .${gridClasses.cell}`]: {
      borderBottom: 'none',
    },
  },
  [`& .${gridClasses.cell}:focus-within`]: {
    outline: 'none !important',
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    typography: (theme) => ({ ...theme.typography.caption }),
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
};

const chartHeight = 300;
const drawerWidth = 360;
const DashboardPage = () => {
  const gridTableHeight = useTableHeight({ subtract: -24 });
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const allowEdit = useFeatureAccess({
    minRole: ExtendedUserRole.Editor,
  });

  const hasAccessToAssigneePlan = useFeatureAccess({
    minPlan: AccountPlanType.Premium,
  });

  const { projects, isLoading } = useGetProjects();

  const teleformTheme = useTheme();

  const updatedColumns = getUpdatedColumns(columnsToHide);

  const updatedTicketColumns = getUpdatedColumns(ticketColumnsToHide);
  const updatedTicketColumnsForOrganization = getUpdatedColumns(ticketColumnsToHideForOrganization);

  const renderColumnsForTickets = hasAccessToAssigneePlan
    ? updatedTicketColumnsForOrganization
    : updatedTicketColumns;

  const { chats, isLoading: isLoadingActivity } = useGetChatActivity();
  const { chatData, sortModel, onSortModelChange } = useChatTable(chats);
  const {organizationDashboardData} = useGetOrganizationDashboardData()

  const { supportTickets, isLoading: isLoadingsSupportTickets } = useGetSupportTickets();
  const {
    chatData: supportTicketsChatData,
    sortModel: supportTicketsSortModel,
    onSortModelChange: supportTicketsOnSortModelChange,
  } = useChatTable(supportTickets);

  const [paginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();

  const [selectedChartOption, setSelectedChartOption] = useLocalStorage(
    LocalStorageKey.DashboardChartType,
    chartOptions[0].value,
  );

  const [chartData, setChartData] = useState<ChartData>({ data: [], xAxis: [] });

  const updateChartData = useCallback(() => {
    let countsByTimePeriod: Dictionary<number>;
    let timePeriods;

    switch (selectedChartOption) {
      case 'lastWeek': {
        const sevenDaysAgo = subDays(endOfToday(), 6);
        countsByTimePeriod = organizationDashboardData.lastWeekChatCount;
        timePeriods = eachDayOfInterval({ start: sevenDaysAgo, end: endOfToday() }).map((day) =>
          format(day, 'yyyy-MM-dd'),
        );
        break;
      }
      case 'last6Months': {
        const sixMonthsAgo = startOfMonth(subMonths(endOfToday(), 5));
        countsByTimePeriod = organizationDashboardData.semiAnnualChatCount
        timePeriods = eachMonthOfInterval({ start: sixMonthsAgo, end: endOfToday() }).map((month) =>
          format(month, 'yyyy-MM'),
        );
        break;
      }
      default:
        return;
    }


    const newBarChartData = timePeriods.map((timePeriod) => countsByTimePeriod[timePeriod] || 0);
    const newXAxisData = timePeriods.map((timePeriod) =>
      format(new Date(timePeriod), selectedChartOption === 'last6Months' ? 'MMM yyyy' : 'MMM dd'),
    );

    setChartData({ data: newBarChartData, xAxis: newXAxisData });
  }, [selectedChartOption, organizationDashboardData.lastWeekChatCount, organizationDashboardData.semiAnnualChatCount]);

  useEffect(() => {
    updateChartData();
  }, [selectedChartOption, chatData, updateChartData]);

  const shouldRenderActivity = !isMobile || (isMobile && !isEmpty(chatData));

  return (
    <Stack
      width={1}
      direction={isMobile ? 'column' : 'row'}
      height={isMobile ? 'auto' : gridTableHeight}
      bgcolor='grey.50'
      gap={isMobile ? 2 : 0}
    >
      <Stack
        p={2}
        m={isMobile ? 0 : 2}
        borderRadius={isMobile ? 0 : 1}
        border={1}
        borderColor='divider'
        bgcolor='background.default'
        maxWidth={isMobile ? '100%' : `${drawerWidth}px`}
        flex={`0 0 ${drawerWidth}px`}
        position='relative'
      >
        <CardHeader
          title='Recent Projects'
          {...(allowEdit && {
            onClick: () => navigate(`${RoutePath.CreateProject}`, { state: { mode: Mode.Create } }),
            buttonText: 'Create Project',
          })}
          buttonProps={{
            variant: 'contained',
            color: 'secondary',
          }}
        />
        <Stack overflow='auto' height={1}>
          <Grid
            container
            spacing={2}
            height={1}
            sx={{
              alignContent: 'flex-start',
            }}
          >
            {isLoading &&
              times(9, (i) => (
                <Grid item xs={12} key={i}>
                  <GridCardSkeleton hideDescription />
                </Grid>
              ))}
            {!isEmpty(projects) ? (
              <>
                {projects
                  ?.sort((a, b) => sortByDate(a.updatedAt, b.updatedAt))
                  .slice(0, 5)
                  .map(({ id, ...rest }) => (
                    <Grid item xs={12} key={id}>
                      <GridCard hideDescription id={id} {...rest} />
                    </Grid>
                  ))}
                {allowEdit && (
                  <Grid item xs={12}>
                    <Button
                      onClick={() => navigate(`/${RoutePath.Projects}`)}
                      variant='outlined'
                      fullWidth
                      color='primary'
                    >
                      View All
                    </Button>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <NoDataOverlay
                  text='No Projects Yet'
                  {...(allowEdit && {
                    onClick: () =>
                      navigate(`/${RoutePath.Projects}`, { state: { mode: Mode.Create } }),
                  })}
                  buttonTitle='Create First Project'
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Stack>
      <Stack
        flex={isMobile ? 'none' : 1}
        gap={2}
        p={isMobile ? 0 : 2}
        pl={0}
        overflow={isMobile ? 'visible' : 'scroll'}
      >
        {allowEdit ? (
          <Stack flex={isMobile ? '0 0 auto' : 1} gap={2}>
            <Stack direction={isMobile ? 'column' : 'row'} gap={2} flex={isMobile ? 'none' : 1}>
              {!isMobile && (
                <Stack
                  direction={{
                    xs: 'row',
                    md: 'column',
                  }}
                  gap={2}
                  flex={isMobile ? 'none' : '0 0 200px'}
                >
                  <DisplayBox
                    tooltip='Total Projects'
                    title='Projects'
                    value={projects?.length || 0}
                    loading={isLoading}
                  />
                  <DisplayBox
                    loading={isLoading}
                    tooltip='Total Active Bots'
                    title='Active Bots'
                    value={
                      projects?.filter((project) => project.apps?.some((app) => app.isActive))
                        .length || 0
                    }
                  />
                  <DisplayBox
                    loading={isLoading}
                    tooltip='Total Conversations made by all chatbots'
                    title='Conversations'
                    value={organizationDashboardData.chatCount || 0}
                  />
                </Stack>
              )}

              <Stack
                px={2}
                pb={1}
                flex={1}
                gap={2}
                borderRadius={isMobile ? 0 : 1}
                border={1}
                borderColor='divider'
                bgcolor='background.default'
              >
                <Stack
                  direction='row'
                  pt={3}
                  justifyContent='space-between'
                  alignItems='center'
                  gap={1}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{
                      ...truncateMultiLineText(1),
                    }}
                    component='h2'
                  >
                    Activity Chart
                  </Typography>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={openMenu}
                    sx={{
                      '& .MuiButton-endIcon': {
                        ml: 0.5,
                      },
                    }}
                    size='small'
                    endIcon={<Icon icon='expand_more' fontSize='small' />}
                  >
                    {chartOptions.find((item) => item.value === selectedChartOption)?.label}
                  </Button>
                  <Menu
                    id='demo-positioned-menu'
                    aria-labelledby='demo-positioned-button'
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={closeMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    slotProps={{
                      paper: { sx: { minWidth: menuMinWidth } },
                    }}
                  >
                    {chartOptions.map((item) => (
                      <MenuItem
                        selected={item.value === selectedChartOption}
                        key={item.value}
                        onClick={() => {
                          setSelectedChartOption(item.value);
                          closeMenu();
                        }}
                      >
                        <ListItemText>{item.label}</ListItemText>
                      </MenuItem>
                    ))}
                  </Menu>
                </Stack>
                <Box height={chartHeight}>
                  {chartData.data.length > 0 && chartData.xAxis.length > 0 && (
                    <BarChart
                      bottomAxis={{
                        axisId: 'barCategories',
                        label: chartOptions.find((item) => item.value === selectedChartOption)
                          ?.xAxisLabel,
                        labelStyle: {
                          fontSize: teleformTheme.typography.caption.fontSize,
                          fontWeight: 'bolder',
                        },
                      }}
                      xAxis={[
                        {
                          id: 'barCategories',
                          data: chartData.xAxis, // Updated to use state
                          scaleType: 'band',
                        },
                      ]}
                      series={[
                        {
                          color: teleformTheme.palette.primary.dark,
                          data: chartData.data, // Updated to use state
                          valueFormatter: (value) => `${value} Conversations`,
                        },
                      ]}
                      height={chartHeight}
                    />
                  )}
                </Box>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack
            overflow={isMobile ? 'visible' : 'auto'}
            flex={1}
            p={2}
            borderRadius={isMobile ? 0 : 1}
            border={1}
            borderColor='divider'
            bgcolor='background.default'
          >
            <CardHeader
              title='Recent Tickets'
              onClick={() => navigate(`/${RoutePath.Activity}`)}
              buttonText='View All'
            />
            <DataGrid
              hideFooter
              rowHeight={54}
              loading={isLoadingsSupportTickets}
              rows={supportTicketsChatData.filter((item) => !item.isArchived) || []}
              onRowClick={({ row }) => {
                navigate(`${RoutePath.Tickets}/${row.id}`);
              }}
              slots={CustomDataGridSlots({})}
              sx={gridStyle}
              columns={renderColumnsForTickets}
              sortModel={supportTicketsSortModel}
              onSortModelChange={supportTicketsOnSortModelChange}
              sortingOrder={['asc', 'desc']}
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              paginationModel={paginationModel}
            />
          </Stack>
        )}

        {shouldRenderActivity && (
          <Stack
            overflow={isMobile ? 'visible' : 'auto'}
            flex={1}
            p={2}
            borderRadius={isMobile ? 0 : 1}
            border={1}
            borderColor='divider'
            bgcolor='background.default'
          >
            <CardHeader
              title='Recent Activity'
              onClick={() => navigate(`/${RoutePath.Activity}`)}
              buttonText='View All'
            />
            <DataGrid
              hideFooter
              rowHeight={54}
              loading={isLoadingActivity}
              rows={chatData.filter((item) => !item.isArchived) || []}
              onRowClick={({ row }) => {
                navigate(`${RoutePath.ChatActivity}/${row.id}`);
              }}
              slots={CustomDataGridSlots({})}
              sx={gridStyle}
              columns={updatedColumns}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              sortingOrder={['asc', 'desc']}
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              paginationModel={paginationModel}
            />
          </Stack>
        )}
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default DashboardPage;
