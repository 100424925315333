import { Box, useTheme } from '@mui/material';
import { MiniMap, Node } from 'reactflow';
import { StepCategoryConfig, StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { NodeType } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { BaseNodeProps } from 'types';

const BuilderMiniMap = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        '& .react-flow__minimap': {
          p: 0,
          pb: 0,
          backgroundColor: theme.palette.grey[50],
          borderRadius: 1,
          '& svg': {
            borderRadius: 1,
          },
        },
      }}
    >
      <MiniMap
        maskColor='transparent'
        maskStrokeColor={theme.palette.grey[800]}
        maskStrokeWidth={2}
        className='react-flow__minimap'
        style={{
          borderRadius: '20px',
          border: `1px solid ${theme.palette.grey[100]}`,
          backgroundColor: 'transparent',
        }}
        inversePan
        zoomable
        pannable
        position='bottom-left'
        nodeStrokeWidth={5}
        //   nodeBorderRadius={20}
        nodeStrokeColor={(n: Node<BaseNodeProps>) => {
          if (n.type === NodeType.Start) {
            return theme.palette.info.lighter;
          }

          if (!n.data.selectedStep) {
            return theme.palette.grey[200];
          }

          return n.data.formData ? theme.palette.success.main : theme.palette.warning.main;
        }}
        nodeColor={(n: Node<BaseNodeProps>) => {
          if (n.type === NodeType.Start) {
            return theme.palette.info.lighter;
          }

          if (!n.data.selectedStep) {
            return theme.palette.grey[200];
          }
          const selectedStepConfig = n.data.selectedStep && StepsConfig[n.data.selectedStep];
          if (!selectedStepConfig) {
            return theme.palette.info.lighter;
          }

          return theme.palette[StepCategoryConfig[selectedStepConfig.category].color].lighter;
        }}
      />
    </Box>
  );
};

export default BuilderMiniMap;
