import { CreateProjectFieldsNames, CreateProjectSteps, Mode } from 'forms/create-project/types';
import StepBackButton from './StepBackButton';

interface BackButtonProps {
  activeStep: CreateProjectSteps;
  changeStep(step: CreateProjectSteps): void;
  isSubmitting: boolean;
  setValue(name: CreateProjectFieldsNames, value: string | undefined): void;
  mode?: Mode;
}
const BackButton = ({ activeStep, changeStep, isSubmitting, setValue, mode }: BackButtonProps) => {
  if (activeStep === CreateProjectSteps.ProjectDetails) {
    return null;
  }
  if (activeStep === CreateProjectSteps.SelectApp) {
    return mode !== Mode.Connect ? (
      <StepBackButton
        disabled={isSubmitting}
        onClick={() => {
          setValue(CreateProjectFieldsNames.SelectedApp, undefined);
          changeStep(CreateProjectSteps.ProjectDetails);
        }}
        text='Change Project Details'
      />
    ) : null;
  }
  return null;
};

export default BackButton;
