import { Divider, Stack } from '@mui/material';
import { map } from 'lodash';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  Button,
  FormAutoComplete,
  FormRadioGroup,
  FormSelect,
  FormSlider,
  FormSwitch,
  FormTextField,
} from '@common-components';
import FormRating from 'components/form-fields/FormRating';
import { useFormProvider } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import { findLabelOrValue } from 'utils';
import {
  builderFlatSelectOptions,
  builderMenuOptions,
  builderModeOptions,
  builderRankOptions,
  builderRuleOptions,
  builderSelectOptions,
  builderSyncOptions,
} from './mock';
import { BuilderSettingsFields, BuilderType } from './types';
import {
  builderSettingsFormFieldsConfig,
  builderSettingsSchema,
  defaultBuilderSettingsFormValues,
} from './utils';

interface BuilderSettingsFormProps {
  defaultValues?: Partial<BuilderSettingsFields>;
}

const BuilderSettingsForm = ({
  defaultValues = defaultBuilderSettingsFormValues,
}: BuilderSettingsFormProps) => {
  const {
    name,
    selectOptions,
    flatSelectOptions,
    rating,
    syncOptions,
    type,
    mode,
    rank,
    rules,
    menuOptions,
    active,
    slider,
    multiSlider,
  } = builderSettingsFormFieldsConfig;

  const [results, setResults] = useState<BuilderSettingsFields | undefined>(undefined);
  const formMethods = useFormProvider<BuilderSettingsFields>({
    reValidateMode: 'onChange',
    schema: builderSettingsSchema,
    defaultValues,
  });

  const { watch } = formMethods;

  const watchType = watch('slider');

  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = async (formData: BuilderSettingsFields) => {
    const {
      rank: rankLabel,
      rules: rulesLabels,
      menuOptions: menuOptionsLabels,
      ...rest
    } = formData;
    const rankValue = findLabelOrValue(builderRankOptions, { label: rankLabel });
    const rulesValues = findLabelOrValue(builderRuleOptions, { label: rulesLabels });
    const menuOptionsValues = findLabelOrValue(builderMenuOptions, { label: menuOptionsLabels });
    // create promise to simulate async validation
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
    setResults({
      ...rest,
      rank: rankValue,
      rules: rulesValues,
      menuOptions: menuOptionsValues,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Stack
        width={1}
        pt={2}
        gap={3}
        component='form'
        noValidate
        onSubmit={handleSubmit(handleOnSubmit)}
        onReset={() => {
          formMethods.reset();
        }}
      >
        <FormSlider valueLabelDisplay='auto' {...slider} />
        <FormSlider {...multiSlider} />
        <FormSelect options={builderSelectOptions} variant='outlined' multiple {...selectOptions} />
        <FormSelect options={builderFlatSelectOptions} variant='outlined' {...flatSelectOptions} />
        <FormRating {...rating} />
        <FormSwitch {...active} />
        <FormTextField {...name} />
        <FormRadioGroup row options={builderModeOptions} {...mode} />
        <FormRadioGroup options={builderSyncOptions} {...syncOptions} />
        <FormAutoComplete options={Object.values(BuilderType)} {...type} />
        <FormAutoComplete options={Object.values(BuilderType)} {...type} />
        <FormAutoComplete
          autoSelect
          freeSolo
          options={map(builderRankOptions, 'label')}
          {...rank}
        />
        <FormAutoComplete multiple options={map(builderRuleOptions, 'label')} {...rules} />
        <FormAutoComplete
          freeSolo
          multiple
          autoSelect
          options={map(builderMenuOptions, 'label')}
          {...menuOptions}
        />
        <Divider />
        <FormTextField size='small' {...name} />
        <FormAutoComplete size='small' options={Object.values(BuilderType)} {...type} />
        <Divider />
        <FormTextField size='small' variant='standard' {...name} />
        <FormAutoComplete
          size='small'
          variant='filled'
          options={Object.values(BuilderType)}
          {...type}
        />
        <Stack direction='row' gap={1} px={2} justifyContent='flex-end'>
          <Button type='reset' color='inherit'>
            Reset
          </Button>
          <SubmitButton variant='contained' type='submit' submitting={formState.isSubmitting}>
            Create Bot
          </SubmitButton>
        </Stack>
        <pre> {JSON.stringify(results, null, 2)}</pre>
        <pre>{JSON.stringify(watchType, null, 2)}</pre>
      </Stack>
    </FormProvider>
  );
};

export default BuilderSettingsForm;
