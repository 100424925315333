import { Stack } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useRef, useState } from 'react';
import { api, ApiKey } from 'api';
import { useAuth, useServerError } from 'hooks';

const GoogleButton = () => {
  const { login } = useAuth();
  const toastServerError = useServerError();

  const [buttonWidth, setButtonWidth] = useState('300px'); // Initialize buttonWidth with 100% as the default width
  const boxRef = useRef<HTMLDivElement | null>(null); // Create a ref for the Box component

  useEffect(() => {
    const updateButtonWidth = () => {
      if (boxRef.current) {
        setButtonWidth(`${boxRef.current.clientWidth}px`);
      }
    };

    updateButtonWidth();

    window.addEventListener('resize', updateButtonWidth);

    return () => {
      window.removeEventListener('resize', updateButtonWidth);
    };
  }, []);

  const submitGoogleToken = async (token: string) => {
    try {
      const response = await api.post(ApiKey.GoogleLogin, { token });
      login?.({
        ...response.data,
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  return (
    <Stack width={1} ref={boxRef} alignItems='center' justifyContent='center'>
      <GoogleLogin
        useOneTap
        size='large'
        width={buttonWidth}
        theme='outline'
        auto_select
        onSuccess={async (credentialResponse) => {
          if (credentialResponse.credential) {
            await submitGoogleToken(credentialResponse.credential);
          }
        }}
        onError={() => {
          toastServerError('Login Failed');
        }}
      />
    </Stack>
  );
};

export default GoogleButton;
