import { object, ObjectSchema, string } from 'yup';
import { FormFieldProps } from 'types';
import { BotFormFields, BotFormFieldsNames, ProjectUpdateDescriptionFields } from './types';

export const botSchema: ObjectSchema<BotFormFields> = object({
  [BotFormFieldsNames.Name]: string().required('Bot Name is required'),
  [BotFormFieldsNames.Description]: string().optional(),
});

export const defaultBotFormValues: BotFormFields = {
  [BotFormFieldsNames.Name]: '',
  [BotFormFieldsNames.Description]: '',
};

export const botFormFieldsConfig: Record<BotFormFieldsNames, FormFieldProps> = {
  [BotFormFieldsNames.Name]: {
    label: '* Name',
    placeholder: 'Project Name',
    name: BotFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [BotFormFieldsNames.Description]: {
    label: 'Description',
    placeholder: 'Project Description',
    name: BotFormFieldsNames.Description,
    autoComplete: 'off',
    type: 'text',
  },
};

export const projectUpdateDescriptionSchema: ObjectSchema<ProjectUpdateDescriptionFields> = object({
  [BotFormFieldsNames.Description]: string().optional(),
});
