import { useCallback, useEffect, useState } from 'react';
import { Node, useReactFlow } from 'reactflow';
import { StepType } from 'enums';
import { useDeepCompareMemo } from 'hooks';
import { StepsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/config';
import { BaseNodeProps } from 'types';

function useAllNodesValidation(): [boolean, string] {
  const [allValid, setAllValid] = useState(true); // Validation status
  const [errorMessage, setErrorMessage] = useState(''); // State to hold validation error messages
  const { getNodes } = useReactFlow<BaseNodeProps>();

  const nodes = useDeepCompareMemo(getNodes());

  const validateNode = useCallback(async (node: Node<BaseNodeProps>): Promise<string> => {
    if (!node.data.selectedStep) {
      return ''; // No error message for nodes without a selectedStep
    }

    const schema =
      StepsConfig[node.data.selectedStep]?.schema || StepsConfig[StepType.Message].schema;

    try {
      await schema.validate(node.data.formData, { abortEarly: false });
      return ''; // No error message for valid nodes
    } catch (error) {
      return `Step ${node.data.step}: Node type of - ${StepsConfig[node.data.selectedStep].name} is not valid`;
    }
  }, []);

  useEffect(() => {
    const validateAllNodes = async () => {
      const validationPromises = nodes.map((node) => validateNode(node));
      const results = await Promise.all(validationPromises);
      const errorMessages = results.filter((message) => message.length > 0);

      if (errorMessages.length > 0) {
        setAllValid(false);
        setErrorMessage(errorMessages.join(', '));
      } else {
        setAllValid(true);
        setErrorMessage('');
      }
    };

    validateAllNodes();
  }, [nodes, validateNode]); // Ensure these dependencies are stable

  return [allValid, errorMessage];
}

export default useAllNodesValidation;
