import { Grid, Paper, Stack } from '@mui/material';
import { times } from 'lodash';
import { NoDataOverlay } from '@common-components';
import { Project } from 'types';
import GridCard from './GridCard';
import GridCardSkeleton from './GridCardSkeleton';

type CardsViewProps = {
  bots: Project[];
  isLoading: boolean;
  noSearchResults: boolean;
  resetSearch: () => void;
  openNewBotDialog: () => void;
};
const GridView = ({
  bots,
  isLoading,
  noSearchResults,
  resetSearch,
  openNewBotDialog,
}: CardsViewProps) => {
  const noBots = !bots.length && !isLoading && !noSearchResults;
  const showBots = !!bots.length && !isLoading && !noSearchResults;

  if (noSearchResults) {
    return (
      <Stack flex={1} component={Paper} variant='outlined'>
        <NoDataOverlay text='No Search Results' onClick={resetSearch} buttonTitle='Clear Search' />
      </Stack>
    );
  }

  if (noBots) {
    return (
      <Stack flex={1} component={Paper} variant='outlined'>
        <NoDataOverlay
          text='No Projects Yet'
          onClick={openNewBotDialog}
          buttonTitle='Create First Project'
        />
      </Stack>
    );
  }

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        sm: 3,
      }}
      height={1}
      pb={{
        xs: 2,
        sm: 3,
      }}
    >
      {isLoading &&
        times(9, (i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <GridCardSkeleton />
          </Grid>
        ))}

      {showBots &&
        bots.map(({ id, ...rest }) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <GridCard id={id} {...rest} />
          </Grid>
        ))}
    </Grid>
  );
};

export default GridView;
