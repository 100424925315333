import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import {
  NavigateStepFormFields,
  NavigateStepFormFieldsLinksNames,
  NavigateStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/navigate/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue, maxTelegramButtonLength } from 'utils';

export const navigateStepFormSchema: ObjectSchema<NavigateStepFormFields> = object({
  [NavigateStepFormFieldsNames.NodeId]: string().required(),
  [NavigateStepFormFieldsNames.Name]: string().required('Name is required'),
  [NavigateStepFormFieldsNames.Input]: editorValidation,
  [NavigateStepFormFieldsNames.Links]: array(
    object({
      [NavigateStepFormFieldsLinksNames.Name]: string().required('Option is required'),
      [NavigateStepFormFieldsLinksNames.NodeId]: string().required('Node is required'),
      [NavigateStepFormFieldsLinksNames.Text]: string()
        .max(
          maxTelegramButtonLength,
          `Text should be less than ${maxTelegramButtonLength} characters`,
        )
        .required('Name is required'),
      [NavigateStepFormFieldsLinksNames.Url]: string()
        .required('Url is required')
        .test('is-url-or-telegram', 'Invalid URL', (value) => {
          // Regular expression for URL validation (allowing URLs without http/https protocols)
          const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)(\?.*)?$/;
          // Regular expression for Telegram username validation (@ followed by username)
          //  const telegramRegex = /^@([A-Za-z0-9_]{5,})$/;

          return urlRegex.test(value);
          // || telegramRegex.test(value);
        }),
    }),
  ).required(),
  [NavigateStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultNavigateStepFormValues: NavigateStepFormFields = {
  [NavigateStepFormFieldsNames.Name]: 'Navigate to URLs',
  [NavigateStepFormFieldsNames.Input]: emptyEditorValue,
  [NavigateStepFormFieldsNames.NodeId]: '',
  [NavigateStepFormFieldsNames.Links]: [],
  [NavigateStepFormFieldsNames.Labels]: [],
};

export const navigateFormFieldsConfig: Record<NavigateStepFormFieldsNames, FormFieldProps> = {
  [NavigateStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: NavigateStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [NavigateStepFormFieldsNames.Input]: {
    placeholder: '* Message',
    name: NavigateStepFormFieldsNames.Input,
    type: 'text',
  },
  [NavigateStepFormFieldsNames.Links]: {
    label: 'Links',
    placeholder: 'Add Link',
    name: NavigateStepFormFieldsNames.Links,
    autoComplete: 'off',
    type: 'text',
  },
  [NavigateStepFormFieldsNames.NodeId]: {
    name: NavigateStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [NavigateStepFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const NavigateFormOptionsFieldsConfig: Record<
  NavigateStepFormFieldsLinksNames,
  FormFieldProps
> = {
  [NavigateStepFormFieldsLinksNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: NavigateStepFormFieldsLinksNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [NavigateStepFormFieldsLinksNames.Text]: {
    label: 'Text',
    placeholder: 'Text',
    name: NavigateStepFormFieldsLinksNames.Text,
    autoComplete: 'off',
    type: 'text',
  },
  [NavigateStepFormFieldsLinksNames.Url]: {
    label: 'Url',
    placeholder: 'Url',
    name: NavigateStepFormFieldsLinksNames.Url,
    autoComplete: 'off',
  },
  [NavigateStepFormFieldsLinksNames.NodeId]: {
    name: NavigateStepFormFieldsLinksNames.NodeId,
    type: 'hidden',
  },
};
