import { Box } from '@mui/material';
import { useCopyToClipboard } from 'usehooks-ts';
import { useToast } from 'hooks';

const CopyText = ({ text, bold, color }: { text: string; color?: string; bold?: boolean }) => {
  const [, copy] = useCopyToClipboard();
  const toast = useToast();
  return (
    <Box
      sx={{
        color,
        cursor: 'copy',
      }}
      component={bold ? 'strong' : 'span'}
      onClick={async () => {
        await copy(text);
        toast({
          message: 'Command copied to clipboard',
          variant: 'success',
        });
      }}
    >
      {text}
    </Box>
  );
};

export default CopyText;
