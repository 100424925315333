import { Typography } from '@mui/material';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import ToolboxLayout from 'pages/app-pages/chatbot-builder-page/toolbox/ToolboxLayout';

export default function StartNodeEditor() {
  const { selectedCommand } = useBuilderStore((state) => ({
    selectedCommand: state.selectedCommand,
  }));
  return (
    <ToolboxLayout title={`${selectedCommand?.name || ''} Command Builder`}>
      <Typography variant='body1' pb={2}>
        <b>This is the {selectedCommand?.name} nodes editor.</b>
        <br />
        You can add more nodes to this command by clicking the + button.
        <br />
        You can also edit the nodes by clicking on them.
        <br />
        <br />
        <b>What is a node?</b>
        <br />
        A node is a single step in a bot workflow. It can be a message, a question, or an action.
        <br />
        <br />
        <b>What is a message?</b>
        <br />
        A message is a text that is sent to the user.
        <br />
        <br />
        <b>What is a question?</b>
        <br />
        A question is a message that expects a response from the user.
        <br />
        <br />
        <b>What is an action?</b>
        <br />
        An action is a node that performs an action. For example, sending an email, or sending to
        webhook.
        <br />
      </Typography>
    </ToolboxLayout>
  );
}
