import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface StepFormWrapperProps<T extends FieldValues> {
  children: ReactNode;
  formMethods: UseFormReturn<T>;
  handleOnSubmit: (formData: T) => void;
}
const StepFormWrapper = <T extends FieldValues>({
  children,
  formMethods,
  handleOnSubmit,
}: StepFormWrapperProps<T>) => (
  <FormProvider {...formMethods}>
    <Stack
      width={1}
      height={1}
      gap={2}
      component='form'
      noValidate
      onSubmit={formMethods.handleSubmit(handleOnSubmit)}
    >
      {children}
    </Stack>
  </FormProvider>
);

export default StepFormWrapper;
