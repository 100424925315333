import { FormControl, FormLabel, InputBase, Slider, SliderProps, Stack } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';

export interface FormSliderProps extends Omit<SliderProps, 'id'> {
  name: string;
  label?: string;
  onBlurCallback?: () => void;
}
export const FormSlider = (props: FormSliderProps) => {
  const {
    name,
    label,
    min = 0,
    max = 100,
    step = 5,
    size = 'small',
    sx,
    onBlurCallback,
    ...rest
  } = props;
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ref, ...field }, fieldState: { error } }) => (
        <FormControl className='notranslate'>
          {label && <FormLabel>{label}</FormLabel>}
          <InputBase
            // hide input but available focus
            sx={{ height: 0, width: 0, position: 'absolute' }}
            inputRef={ref}
          />
          <Stack px={1} width={1}>
            <Slider
              {...field}
              marks
              max={max}
              min={min}
              step={step}
              value={value}
              size={size}
              onChangeCommitted={onBlurCallback}
              {...rest}
            />
          </Stack>
          <FormCollapseHelperText error={error} />
        </FormControl>
      )}
    />
  );
};

export default memo(FormSlider);
