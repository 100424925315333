import { Stack } from '@mui/material';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormTextFieldInlineEdit } from 'components/index';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';

import FormValidationField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormValidationField';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { FreeQuestionFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/free-question/types';
import {
  defaultFreeQuestionFormValues,
  freeQuestionFormFieldsConfig,
  freeQuestionFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/free-question/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface FreeQuestionProps {
  id: string;
  defaultValues?: Partial<FreeQuestionFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}

const FreeQuestion = ({ defaultValues, mentions, commands, id }: FreeQuestionProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultFreeQuestionFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });
  const processNewLabels = useProcessNewLabels();

  const formMethods = useFormProvider<FreeQuestionFormFields>({
    schema: freeQuestionFormSchema,
    defaultValues: localDefaultValues,
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<FreeQuestionFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { reset, formState } = formMethods;

  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: FreeQuestionFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  const { name, labels, variable, validation, input } = freeQuestionFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />
          <Stack direction='row' alignItems='center' gap={0.5}>
            <FormVariableField {...variable} />
            <FormValidationField {...validation} />
          </Stack>
        </Stack>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(FreeQuestion);
