import { alpha, inputBaseClasses } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

const ToolbarSearchInput = () => (
  <GridToolbarQuickFilter
    sx={{
      pb: 0,
      [`& .${inputBaseClasses.root}`]: {
        px: 1,
        py: 0.25,
        gap: 1,
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
        typography: 'body2',
        borderRadius: 1,
        '& .MuiSvgIcon-root': {
          color: 'grey.500',
        },
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.12),
        },
        '&:before': {
          content: 'none',
        },
        '&:after': {
          content: 'none',
        },
      },
    }}
  />
);

export default ToolbarSearchInput;
