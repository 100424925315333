import { BASIC_COLORS_VALUES, BasicColors, TEXT_COLORS_VALUES, TextColors } from './types';

export function isBasicColorValid(color: string): color is BasicColors {
  return BASIC_COLORS_VALUES.includes(color as BasicColors);
}

export function isTextColorValid(color: string): color is TextColors {
  return TEXT_COLORS_VALUES.includes(color as TextColors);
}

export const menuMinWidth = 220;

export const mainBackground = 'grey.50';
