import { AutoCompleteLabel } from 'types';

export enum MultiChoiceQuestionStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Variable = 'variable',
  Answers = 'answers',
  RemoveAnswerOptions = 'removeAnswerOptions',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface MultiChoiceQuestionStepFormFields {
  [MultiChoiceQuestionStepFormFieldsNames.Name]: string;
  [MultiChoiceQuestionStepFormFieldsNames.Input]: string;
  [MultiChoiceQuestionStepFormFieldsNames.Variable]?: string;
  [MultiChoiceQuestionStepFormFieldsNames.NodeId]: string;
  [MultiChoiceQuestionStepFormFieldsNames.Answers]: MultiChoiceQuestionStepFormFieldsOptions[];
  [MultiChoiceQuestionStepFormFieldsNames.RemoveAnswerOptions]?: boolean;
  [MultiChoiceQuestionStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export enum MultiChoiceQuestionStepFormFieldsOptionsNames {
  Name = 'name',
  Option = 'option',
  IsCorrect = 'isCorrect',
  NodeId = 'nodeId',
}

export interface MultiChoiceQuestionStepFormFieldsOptions {
  [MultiChoiceQuestionStepFormFieldsOptionsNames.Name]: string;
  [MultiChoiceQuestionStepFormFieldsOptionsNames.Option]: string;
  [MultiChoiceQuestionStepFormFieldsOptionsNames.NodeId]: string;
  [MultiChoiceQuestionStepFormFieldsOptionsNames.IsCorrect]: boolean;
}
