import { AutoCompleteLabel } from 'types';

export enum RequestContactFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Button = 'button',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface RequestContactFormFields {
  [RequestContactFormFieldsNames.NodeId]: string;
  [RequestContactFormFieldsNames.Name]: string;
  [RequestContactFormFieldsNames.Button]: string;
  [RequestContactFormFieldsNames.Input]: string;
  [RequestContactFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
