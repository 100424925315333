import { createFilterOptions } from '@mui/material';
import { ChatFilterDirection, ChatFilterMatching } from 'enums';
import {
  ChatFilterFieldsNames,
  ChatFilterFormFields,
} from 'pages/app-pages/private-bulk-message-page/message-modal/types';
import { AutoCompleteLabel, ChatTableProps, UserLabel } from 'types';

export const countMatchingChats = ({
  chats,
  filter,
  projectId,
  excludeArchived = false,
}: {
  chats: ChatTableProps[];
  filter: ChatFilterFormFields;
  projectId: string;
  excludeArchived?: boolean; // This should match the parameter added above
}): number => {
  const { Direction, Labels, Matching } = ChatFilterFieldsNames;

  // Determine if we should default to including or excluding chats based on labels and direction.
  const defaultToAll =
    filter[Labels].length === 0 && filter[Direction] === ChatFilterDirection.Include;

  return chats.filter((chat) => {
    // Exclude chats that are blocked.
    if (chat.isBlocked || (excludeArchived && chat.isArchived)) {
      return false;
    }

    if (chat.projectId !== projectId) {
      return false;
    }

    // If labels array is empty and direction is "include", include all non-blocked chats.
    if (defaultToAll) {
      return true;
    }

    const chatLabels = chat.labels?.map((label) => label.id) || [];
    const filterLabels = filter[Labels];

    const hasMatchingLabel = filterLabels.some((label) => chatLabels.includes(label.value));
    const allLabelsMatch = filterLabels.every((label) => chatLabels.includes(label.value));

    switch (filter[Matching]) {
      case ChatFilterMatching.All:
        return filter[Direction] === ChatFilterDirection.Include ? allLabelsMatch : !allLabelsMatch;
      case ChatFilterMatching.Any:
        return filter[Direction] === ChatFilterDirection.Include
          ? hasMatchingLabel
          : !hasMatchingLabel;
      default:
        // If unknown matching criteria, exclude chat.
        return false;
    }
  }).length;
};

/**
 * Sorts an array of UserLabel objects by updatedAt (newest first),
 * falling back to createdAt if updatedAt is not provided.
 *
 * @param {UserLabel[]} userLabels - The array of UserLabel objects to sort.
 * @return {UserLabel[]} A new array sorted by the criteria.
 */
export const sortUserLabelsByDate = (userLabels: UserLabel[] = []): UserLabel[] =>
  [...userLabels].sort((a, b) => {
    // Use updatedAt if available, otherwise fall back to createdAt
    const dateA = a.updatedAt || a.createdAt;
    const dateB = b.updatedAt || b.createdAt;

    // Convert to timestamps for comparison
    const timeA = new Date(dateA).getTime();
    const timeB = new Date(dateB).getTime();

    // Return the difference for sorting (newer first)
    return timeB - timeA;
  });

export const mapLabelsAutocompleteOptions = (userLabels?: UserLabel[]): AutoCompleteLabel[] =>
  userLabels?.map((label) => ({
    value: label.id,
    label: label.name,
    color: label.color,
    icon: label.icon,
    isDisabled: label.isDisabled,
  })) || [];

type SelectedLabelItem = AutoCompleteLabel | string;

export const getUserLabelsByAutoCompleteLabels = (
  selectedLabels: SelectedLabelItem[],
  userLabels: UserLabel[],
): { foundLabels: UserLabel[]; notFoundLabels: string[] } => {
  const { foundLabels, notFoundLabels } = selectedLabels.reduce(
    (acc, selectedLabelItem) => {
      // Check if the selectedLabelItem is a string directly
      if (typeof selectedLabelItem === 'string') {
        // For strings, if they are not found as user labels, add them to notFoundLabels
        const foundLabel = userLabels.find((userLabel) => selectedLabelItem === userLabel.id);
        if (!foundLabel) {
          acc.notFoundLabels.push(selectedLabelItem);
        }
      } else {
        // For AutoCompleteLabel objects, try to find the corresponding user label
        const foundLabel = userLabels.find((userLabel) => selectedLabelItem.value === userLabel.id);
        if (foundLabel) {
          acc.foundLabels.push(foundLabel);
        }
        // If not found, do nothing with the AutoCompleteLabel object
      }

      return acc;
    },
    { foundLabels: [] as UserLabel[], notFoundLabels: [] as string[] }, // Initial accumulator values
  );

  return { foundLabels, notFoundLabels };
};
export const updateSelectedLabels = (
  selectedLabels: AutoCompleteLabel[] = [],
  userLabels: UserLabel[] = [],
): AutoCompleteLabel[] =>
  selectedLabels
    .filter((item) => userLabels.some((updateItem) => updateItem.id === item.value))
    .map((item) => {
      const update = userLabels.find((updateItem) => updateItem.id === item.value);
      if (update) {
        return {
          ...item,
          label: update.name,
          color: update.color || item.color,
          icon: update.icon || item.icon,
          isDisabled: update.isDisabled,
        };
      }
      return item;
    });

const colors = [
  '#E7DFEE',
  '#FADEC9',
  '#FEE2DF',
  '#FEEFC8',
  '#EEDFDA',
  '#D9E8F5',
  '#F5E9F4',
  '#F4C7F3',
  '#FAD2E1',
  '#FDF6C1',
  '#FFC8A2',
  '#D6E2F0',
];

export const getRandomColor = (): string => colors[Math.floor(Math.random() * colors.length)];

export const filterOptions = createFilterOptions<AutoCompleteLabel>({
  ignoreCase: true,
  matchFrom: 'start',
  stringify: (option) => option.label,
});
