import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormArrayFields,
  FormSection,
  FormSwitch,
  FormTextFieldInlineEdit,
  List,
  ListItem,
} from '@common-components';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import messages from 'messages';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import MenuFormArrayField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-menu/MenuFormArrayField';
import { OptionsMenuStepFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-menu/types';
import {
  defaultOptionsMenuStepFormValues,
  OptionsMenuFormFieldsConfig,
  OptionsMenuStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-menu/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface OptionsMenuProps {
  id: string;
  defaultValues?: Partial<OptionsMenuStepFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
  backButton?: boolean;
}
const OptionsMenu = ({ defaultValues, mentions, commands, id, backButton }: OptionsMenuProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultOptionsMenuStepFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    menu: isEmpty(defaultValues?.menu)
      ? [
          { name: 'Option 1', option: '', nodeId: uuid() },
          { name: 'Option 2', option: '', nodeId: uuid() },
        ]
      : defaultValues?.menu,
    backButton: defaultValues?.backButton || false,
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<OptionsMenuStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<OptionsMenuStepFormFields>({
    schema: OptionsMenuStepFormSchema,
    defaultValues: localDefaultValues,
  });
  const {
    name,
    variable,
    input,
    menu,
    labels,
    backButton: backButtonConfig,
    removeAnswerOptions,
  } = OptionsMenuFormFieldsConfig;

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const processNewLabels = useProcessNewLabels();

  const { reset, formState } = formMethods;

  const handleOnSubmit = async (formData: OptionsMenuStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />
          <FormVariableField {...variable} />
        </Stack>
        <FormSection title={menu.label}>
          <FormArrayFields
            name={menu.name}
            FormArrayFieldComponent={MenuFormArrayField}
            addButtonText='Add Menu Item'
          />
        </FormSection>
        <List divider label='Aditional Options'>
          {/*  TODO ALLOW THIS FEATURE */}
          {backButton && false && (
            <ListItem>
              <FormSwitch
                {...backButtonConfig}
                infoIcon={{
                  tooltip:
                    'If enabled, a back button will be displayed as the last option in the menu.',
                  icon: 'info',
                }}
              />
            </ListItem>
          )}
          <ListItem>
            <FormSwitch
              {...removeAnswerOptions}
              infoIcon={{
                tooltip: messages.builder.removeAnswerOptionsTooltip,
                icon: 'info',
              }}
            />
          </ListItem>
        </List>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons initiallyEnabled formState={formState} reset={reset} />
    </StepFormWrapper>
  );
};

export default memo(OptionsMenu);
