import { create } from 'zustand';

export type IsDirtyState = {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  isSavingToServer: boolean;
  setIsSavingToServer: (isSavingToServer: boolean) => void;
};

export const useDirtyStore = create<IsDirtyState>((set) => ({
  isDirty: false,
  setIsDirty: (isDirty) => set({ isDirty }),
  isSavingToServer: false,
  setIsSavingToServer: (isSavingToServer) => set({ isSavingToServer }),
}));
