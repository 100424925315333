import { mixed, object, ObjectSchema, string } from 'yup';
import { UserRole } from 'enums';
import { FormFieldProps } from 'types';
import { InviteOtOrganizationFields, InviteOtOrganizationFormFieldsNames } from './types';

export const roleOptions: { value: UserRole; label: string }[] = [
  { value: UserRole.Admin, label: 'Administrator' },
  { value: UserRole.Editor, label: 'Editor' },
  { value: UserRole.Agent, label: 'Agent' },
];

export const inviteOtOrganizationSchema: ObjectSchema<InviteOtOrganizationFields> = object({
  [InviteOtOrganizationFormFieldsNames.Email]: string().email().required('Bot Name is required'),
  [InviteOtOrganizationFormFieldsNames.Role]: mixed<UserRole>()
    .oneOf(Object.values(UserRole))
    .required('The Role is required.'),
});

export const defaultInviteOtOrganizationFormValues: InviteOtOrganizationFields = {
  [InviteOtOrganizationFormFieldsNames.Email]: '',
  [InviteOtOrganizationFormFieldsNames.Role]: UserRole.Editor,
};

export const inviteOtOrganizationFormFieldsConfig: Record<
  InviteOtOrganizationFormFieldsNames,
  FormFieldProps
> = {
  [InviteOtOrganizationFormFieldsNames.Email]: {
    label: 'New Member Email',
    placeholder: 'Email Address',
    name: InviteOtOrganizationFormFieldsNames.Email,
    autoComplete: 'off',
    type: 'text',
  },
  [InviteOtOrganizationFormFieldsNames.Role]: {
    placeholder: 'Role',
    name: InviteOtOrganizationFormFieldsNames.Role,
    type: 'select',
  },
};
