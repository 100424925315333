import { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import { BaseNodeProps } from 'types';

export const useSelectNode = () => {
  const { setNodes } = useReactFlow<BaseNodeProps>();

  return useCallback(
    (id: string | null) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          node.selected = node.id === id;
          return node;
        }),
      );
    },
    [setNodes],
  );
};
