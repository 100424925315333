import {
  Box,
  BoxProps,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';
import { FormTextField, FormTextFieldProps } from 'components/form-fields/FormTextField';
import Icon, { IconProps } from 'components/Icon';
import { TooltipWrapperProps } from 'theme';

export interface FormTextFieldInlineEditProps extends FormTextFieldProps, TooltipWrapperProps {
  textVariant?: Variant;
  icon?: string;
  iconSize?: number;
  text?: string;
  startAdornment?: boolean;
  disabled?: boolean;
  prefix?: string;
  onFinishedEditing?: () => void;
  boxProps?: BoxProps;
  textProps?: TypographyProps;
  iconProps?: Omit<IconProps, 'icon' | 'ref'>;
  startInEditingMode?: boolean;
  allowEmpty?: boolean;
  dynamicWidth?: boolean;
  tooltipError?: boolean;
}

const FormTextFieldInlineEdit = ({
  name,
  textVariant = 'h6',
  icon = 'edit',
  iconSize,
  text = '',
  startAdornment,
  tooltip = '',
  disabled,
  prefix = '',
  tooltipPlacement,
  tooltipProps,
  onFinishedEditing,
  boxProps,
  textProps,
  iconProps,
  startInEditingMode = false,
  allowEmpty,
  dynamicWidth,
  helperText,
  error,
  ...rest
}: FormTextFieldInlineEditProps) => {
  const {
    value: editing,
    setTrue: startEditing,
    setFalse: stopEditing,
  } = useBoolean(startInEditingMode);

  const [inputWidth, setInputWidth] = useState('auto');

  const textWidthRef = useRef<HTMLSpanElement>(null);

  const {
    trigger,
    watch,
    reset,

    formState: { errors, isSubmitting, defaultValues },
  } = useFormContext();

  const watchName = watch(name);

  useEffect(() => {
    if (dynamicWidth && textWidthRef.current && editing) {
      const width = textWidthRef.current.offsetWidth + 40;
      setInputWidth(`${width}px`);
    }
  }, [watchName, dynamicWidth, editing]);

  const showLoader = isSubmitting && onFinishedEditing;

  const closeEditField = async () => {
    if (
      defaultValues?.[name] !== watchName || // Check if the value has changed
      !allowEmpty || // If not allowing empty, proceed without further checks
      (allowEmpty && !isEmpty(defaultValues?.[name])) // If allowing empty, proceed only if it's an "edit" of a non-empty value
    ) {
      await trigger(name);
      if (!errors[name]) {
        stopEditing();
        onFinishedEditing?.();
      }
    } else {
      stopEditing();
    }
  };

  const onStartedEditing = () => {
    if (showLoader) {
      return;
    }
    if (allowEmpty) {
      reset({ [name]: watchName });
    }
    if (disabled) {
      return;
    }
    startEditing();
  };
  // normal primary.main, isEmpty(watchName): text.secondary, disabled: grey.100
  const iconColor = () => {
    if (disabled) {
      return 'grey.300';
    }
    if (!isEmpty(watchName)) {
      return 'text.secondary';
    }
    return 'primary.main';
  };

  return (
    <Box width={1} {...boxProps}>
      {editing ? (
        <>
          <FormTextField
            className='notranslate'
            autoFocus
            style={{ width: inputWidth }}
            onFocus={(e) => e.target.select()}
            confirmCallBack={closeEditField}
            variant='standard'
            onBlur={closeEditField}
            name={name}
            {...rest}
          />
          <Box
            ref={textWidthRef}
            style={{ position: 'absolute', visibility: 'hidden', height: '0' }}
          >
            {watchName || text}
          </Box>
        </>
      ) : (
        <Tooltip title={tooltip} placement={tooltipPlacement} arrow {...tooltipProps}>
          <Stack
            direction='row'
            alignItems='center'
            gap={isEmpty(watchName) && dynamicWidth ? 0 : 1}
            sx={{
              cursor: disabled ? 'not-allowed' : 'pointer',
              transition: (theme) =>
                theme.transitions.create(['opacity'], {
                  duration: theme.transitions.duration.shortest,
                }),
              '&:hover': { opacity: disabled ? 1 : 0.8 },
            }}
            onClick={onStartedEditing}
          >
            {startAdornment && (
              <Icon
                fontSize='small'
                color={iconColor()}
                icon={icon}
                sx={{
                  ...(iconSize && { fontSize: iconSize }),
                }}
              />
            )}
            <Typography
              color={!isEmpty(watchName) && !disabled ? 'text.primary' : 'text.secondary'}
              noWrap={!rest.multiline}
              variant={textVariant}
              {...textProps}
              sx={textProps?.sx}
            >
              {isEmpty(watchName) ? <em>{text}</em> : `${prefix}${watchName}`}
            </Typography>
            {!startAdornment && !showLoader && (
              <Icon
                fontSize='small'
                color={iconColor()}
                icon={icon}
                sx={{
                  ...(iconSize && { fontSize: iconSize }),
                  ...iconProps?.sx,
                }}
                {...iconProps}
              />
            )}
            {showLoader && (
              <Stack width={30} height={30} alignItems='center' justifyContent='center'>
                <CircularProgress size={iconSize ?? 20} />
              </Stack>
            )}
          </Stack>
        </Tooltip>
      )}
    </Box>
  );
};

export default FormTextFieldInlineEdit;
