import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { useTableHeight } from 'hooks';

const ScrollAreaLayout = ({ children, subtract }: { children: ReactNode; subtract: number }) => {
  const gridTableHeight = useTableHeight({ subtract });

  return (
    <Box overflow='auto' flexGrow={1} height={gridTableHeight}>
      {children}
    </Box>
  );
};

export default ScrollAreaLayout;
