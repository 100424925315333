import { Stack, StackProps } from '@mui/material';
import { ReactNode } from 'react';
import Icon from 'components/Icon';

interface IconBadgeProps extends StackProps {
  icon?: string;
  size?: number;
  bgcolor?: string;
  color?: string;
  children?: ReactNode;
}

const IconBadge = ({
  icon,
  children,
  size = 32,
  bgcolor = 'success.main',
  color = 'background.default',
  ...rest
}: IconBadgeProps) => (
  <Stack
    bgcolor={bgcolor}
    color={color}
    borderRadius='50%'
    width={size}
    height={size}
    justifyContent='center'
    alignItems='center'
    {...rest}
  >
    {icon && <Icon icon={icon} fontSize='small' color='inherit' />}
    {children}
  </Stack>
);

export default IconBadge;
