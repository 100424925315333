import { isEqual } from 'lodash';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useDeepCompareEffect = (
  effect: EffectCallback,
  dependencies: DependencyList,
): void => {
  const dependenciesRef = useRef<DependencyList>();

  if (!isEqual(dependenciesRef.current, dependencies)) {
    dependenciesRef.current = dependencies;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [dependenciesRef.current]);
};
