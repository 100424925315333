import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useBoolean, useLocalStorage } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { BasicModal, FormTextField } from 'components/index';
import { BotTemplate } from 'enums';
import { useAuth, useFormProvider, useServerError } from 'hooks';
import SuccessMessage from 'pages/app-pages/chatbot-builder-page/dialogs/ai-form/SuccessMessage';
import { useDirtyStore } from 'store';
import { Project } from 'types';
import { AiFormFields } from './types';
import { aiFormFieldsConfig, aiSchema, defaultAiFormValues } from './utils';

const usersThatWillNotSeeModal = [
  'brsteamwork2021@gmail.com',
  'jaredjock@new.ovh',
  'mohamedguentrah11@gmail.com',
  'con.enlinea@gmail.com',
  'ffrsa.tournament@gmail.com',
  'lavrinoviculia020@gmail.com',
  'ra6434829@gmail.com',
];

const AiModal = () => {
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const { aiPrompt } = aiFormFieldsConfig;
  const { user } = useAuth();
  const includeUser = usersThatWillNotSeeModal.includes(user?.email || '');
  const { id } = useParams();
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const simpleTemplate =
    project?.template === BotTemplate.Basic ||
    project?.template === BotTemplate.Blank ||
    project?.template === undefined;

  const formMethods = useFormProvider<AiFormFields>({
    reValidateMode: 'onChange',
    schema: aiSchema,
    defaultValues: defaultAiFormValues,
  });
  const toastServerError = useServerError();
  const { handleSubmit, formState } = formMethods;
  const [showModal, setShowModal] = useLocalStorage('showModal', true);
  const [successMessage, setSuccessMessage] = useState(false);

  const {
    value: aiIsAnalyzing,
    setTrue: setAiIsAnalyzing,
    setFalse: setAiIsNotAnalyzing,
  } = useBoolean(false);

  const handleOnSubmit = async (formData: AiFormFields) => {
    setIsDirty(false);
    setAiIsAnalyzing();
    try {
      await api.patch<Project>(`${ApiKey.Project}/${id}`, formData);
      await mutate(ApiKey.Project);

      setTimeout(() => {
        setAiIsNotAnalyzing();
        setSuccessMessage(true);
      }, 3000);
    } catch (error: any) {
      toastServerError(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        cancelButton={!successMessage}
        cancelText='Skip'
        disableEscapeKeyDown
        onSubmit={handleSubmit(handleOnSubmit)}
        title='🪄 Bring Your Bot to Life with AI! *Experimental Feature*'
        label='ai_prompt-dialog'
        maxWidth='sm'
        submittingText='AI is analyzing your description...'
        mainAction={
          !successMessage
            ? {
                children: 'Create My Bot',
                type: 'submit',
                isSubmitting: formState.isSubmitting || aiIsAnalyzing,
                color: 'info',
              }
            : undefined
        }
        handleClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown' && reason !== 'swipeDown') {
            setShowModal(false);
          }
        }}
        open={showModal && !includeUser && simpleTemplate && !!user?.isOwner}
      >
        <Stack width={1} gap={2}>
          {successMessage ? (
            <SuccessMessage
              onClick={() => {
                setShowModal(false);
              }}
            />
          ) : (
            <>
              <Typography variant='body1' mb={2}>
                <b>Help us understand your needs!</b> Simply describe the bot you envision, and our
                AI will use your description to start building it automatically. The more details
                you provide, the better we can tailor it to your requirements.
              </Typography>
              <FormTextField
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={5}
                {...aiPrompt}
              />
            </>
          )}
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default AiModal;
