import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { ApiKey } from 'api';
import { Icon, MenuSectionHeader } from 'components/index';
import { RoutePath } from 'enums';
import { OptionsMenuState } from 'hooks';
import { useConfirmUnsavedNodeChanges } from 'pages/app-pages/chatbot-builder-page/hooks/useConfirmUnsavedNodeChanges';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';
import { menuMinWidth } from 'theme/utils';
import { Project } from 'types';

const CommandsMenu = ({ closeMenu, anchorEl, isMenuOpen }: OptionsMenuState) => {
  const { openToolbox, setActiveToolboxTab, closeToolbox } = useBuilderStore((state) => ({
    openToolbox: state.openToolbox,
    closeToolbox: state.closeToolbox,
    setActiveToolboxTab: state.setActiveToolboxTab,
  }));

  const confirmUnsavedNodeChanges = useConfirmUnsavedNodeChanges();

  const { selectedCommand, setSelectedCommand } = useBuilderStore((state) => ({
    selectedCommand: state.selectedCommand,
    setSelectedCommand: state.setSelectedCommand,
  }));
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  const commandsList = project?.bot?.draft.commands;

  return (
    <Menu
      id='demo-positioned-menu'
      aria-labelledby='demo-positioned-button'
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{
        paper: { sx: { minWidth: menuMinWidth, mt: 1 } },
      }}
    >
      <MenuSectionHeader title='Commands List' />
      {Object.values(commandsList || {}).map((command) => (
        <MenuItem
          selected={selectedCommand?.name === command.name}
          key={command.name}
          onClick={() => {
            confirmUnsavedNodeChanges(() => {
              setSelectedCommand(command);
              closeToolbox();
              closeMenu();
            });
          }}
        >
          <ListItemIcon>
            <Icon icon={command.icon} fontSize='small' />
          </ListItemIcon>
          <ListItemText>{command.name}</ListItemText>
        </MenuItem>
      ))}
      <Divider />
      <MenuItem
        onClick={() => {
          confirmUnsavedNodeChanges(() => {
            setActiveToolboxTab(ToolboxTabs.CommandsSettings);
            openToolbox();
            closeMenu();
          });
        }}
      >
        <ListItemIcon>
          <Icon icon=' settings' fontSize='small' />
        </ListItemIcon>
        <ListItemText>Manage Commands</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          closeMenu();
          navigate(`/${RoutePath.ChatbotBuilder}/${id}/${RoutePath.Command}`, { replace: true });
        }}
      >
        <ListItemIcon>
          <Icon icon='note_stack_add' fontSize='small' />
        </ListItemIcon>
        <ListItemText>Add New Command</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          closeMenu();
          navigate(`${RoutePath.RenameProject}`, {
            state: {
              project,
            },
          });
        }}
      >
        <ListItemIcon>
          <Icon icon='edit_note' fontSize='small' />
        </ListItemIcon>
        <ListItemText>Rename Project</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          closeMenu();
          window.open(`https://core.telegram.org/bots/features#commands`, '_blank');
        }}
      >
        <ListItemIcon>
          <Icon icon='info' fontSize='small' />
        </ListItemIcon>
        <ListItemText>Help</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default CommandsMenu;
