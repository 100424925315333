import { SxProps, Theme } from '@mui/material';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

export function scrollIntoView(input?: HTMLElement | null, options: ScrollIntoViewOptions = {}) {
  input?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    ...options,
  });
}

export const scrollToElement = (id: string, options: ScrollIntoViewOptions = {}) => {
  const element = document.getElementById(id);
  scrollIntoView(element, options);
};

interface ConditionalWrapProps {
  condition: boolean;
  wrap: (children: ReactElement) => ReactElement;
  children: ReactElement | ReactNode;
}

export const ConditionalWrap: FunctionComponent<ConditionalWrapProps> = ({
  condition,
  wrap,
  children,
}: ConditionalWrapProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const wrappedChildren = <>{children}</>;
  return condition ? wrap(wrappedChildren) : wrappedChildren;
};

export const extractNumberFromMediaQuery = (mediaQuery: string): number | null => {
  // Use a regular expression to find the number within the media query
  const match = mediaQuery.match(/\d+/);

  // Check if a match was found
  if (match) {
    // Convert the matched string to a number and return it
    return parseInt(match[0], 10);
  }
  return null;
};

export const truncateMultiLineText = (maxLines: number): SxProps<Theme> => ({
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: `${maxLines}`,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'initial',
});

export const calculateColorTemperature = (value: number) => {
  if (value <= 80 && value > 40) {
    return 'warning';
  }
  if (value > 80) {
    return 'error';
  }
  return 'success';
};

export const calculateChargeLevelIcon = (value: number) => {
  if (value <= 80 && value > 40) {
    return 'battery_horiz_050';
  }
  if (value > 80) {
    return 'battery_very_low';
  }
  return 'battery_full_alt';
};

export const defaultMentions = [
  { id: 'firstName', value: 'First Name' },
  { id: 'lastName', value: 'Last Name' },
  {
    id: 'chatId',
    value: 'Chat ID',
  },
];
