import { Divider, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { rossListItemClasses } from 'theme';
import { ConditionalWrap } from 'utils';

interface ListWrapperProps {
  children: ReactNode;
  divider?: boolean;
  label?: string;
  dense?: boolean;
  disablePadding?: boolean;
}
const List = ({ children, divider, label, dense, disablePadding }: ListWrapperProps) => {
  const py = dense ? 0.5 : 0.8;
  return (
    <ConditionalWrap
      condition={!!label}
      wrap={(wrappedChildren) => (
        <Stack width={1} gap={1}>
          {wrappedChildren}
        </Stack>
      )}
    >
      <>
        {label && (
          <Typography px={1} variant='body2'>
            {label}
          </Typography>
        )}
        <Stack
          borderRadius={0.5}
          border={1}
          borderColor='divider'
          bgcolor='grey.50'
          width={1}
          divider={divider && <Divider sx={{ opacity: 0.6 }} orientation='horizontal' flexItem />}
          sx={{ [`& .${rossListItemClasses.root}`]: { px: 1.75, py: disablePadding ? 0 : py } }}
        >
          {children}
        </Stack>
      </>
    </ConditionalWrap>
  );
};

export default List;
