import {
  Collapse,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import {
  BasicModal,
  FormAutoComplete,
  FormDateTimePicker,
  FormRadioGroup,
  FormTextEditor,
  FormTextField,
} from '@common-components';
import { api, ApiKey } from 'api';
import { ChatGroupMessageStatus } from 'enums';
import { useFormProvider, useIsMobile, useNavigateBack, useServerError, useToast } from 'hooks';
import {
  FormMode,
  GroupMessageFormFields,
  GroupMessageFormFieldsNames,
  GroupMessageFormLocalState,
  MessageTypes,
} from 'pages/app-pages/messages-scheduler-page/message-modal/types';
import {
  groupsMessagesFieldsConfig,
  messageDefaultValues,
  messageSchema,
  messageTypesConfig,
} from 'pages/app-pages/messages-scheduler-page/message-modal/utils';
import { useDirtyStore } from 'store';
import { ChatGroupMessage } from 'types';
import { characterLimitValue, isDocumentUrl, isImageUrl } from 'utils';

const MessageFormModal = () => {
  const { isMobile } = useIsMobile();
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const toastServerError = useServerError();

  const [sendMode, setSendMode] = useState<'now' | 'schedule' | undefined>();

  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));

  const locationState = location.state as GroupMessageFormLocalState | undefined;

  const groupsList: string[] = map(locationState?.chatGroups, 'name');

  const { value: scheduleMessage, setValue: setScheduleMessage } = useBoolean(
    !!locationState?.initialValues?.startDate,
  );

  const initialValues: GroupMessageFormFields = {
    type: locationState?.initialValues?.photo ? MessageTypes.Photo : messageDefaultValues.type,
    project: locationState?.initialValues?.project || '',
    message:
      locationState?.initialValues?.message || locationState?.initialValues?.photo
        ? locationState?.initialValues?.message
        : messageDefaultValues.message,
    photo: locationState?.initialValues?.photo || messageDefaultValues.photo,
    startDate: locationState?.initialValues?.startDate || messageDefaultValues.startDate,
    groups: locationState?.initialValues?.groups || groupsList,
  };

  const formMethods = useFormProvider<GroupMessageFormFields>({
    reValidateMode: 'onChange',
    schema: messageSchema,
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitting },
  } = formMethods;

  const typeVal = watch(GroupMessageFormFieldsNames.Type);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setScheduleMessage(event.target.checked);
    setValue(GroupMessageFormFieldsNames.StartDate, event.target.checked ? new Date() : undefined);
  };

  const { message, startDate, groups, type, photo } = groupsMessagesFieldsConfig;

  const { placeholder: messagePlaceHolder, ...restMessageProps } = message;

  const handleOnSubmit = async (
    { photo: photoVal, ...formData }: GroupMessageFormFields,
    publish: boolean,
  ) => {
    setIsDirty(false);

    const dataToSend = {
      ...formData,
      photo: photoVal && isImageUrl(photoVal) ? photoVal : undefined,
      document: photoVal && isDocumentUrl(photoVal) ? photoVal : undefined,
      groups: formData.groups.map((group) => ({
        // find id inr the list of groups
        chatGroup: locationState?.chatGroups?.find((item) => item.name === group)?.id,
      })),
    };

    try {
      const res =
        locationState?.mode === FormMode.Create
          ? await api.post<ChatGroupMessage>(ApiKey.ChatGroupMessage, dataToSend)
          : await api.patch<ChatGroupMessage>(
              `${ApiKey.ChatGroupMessage}/${locationState?.messageId}`,
              { ...dataToSend, startDate: dataToSend.startDate ?? null },
            );

      if (publish) {
        await api.post<ChatGroupMessage>(`${ApiKey.ChatGroupMessage}/publish`, {
          projectId: locationState?.projectId,
          id: res.data?.id,
        });
      }

      await mutate(`${ApiKey.Project}/${locationState?.projectId}`);
      await mutate(`${ApiKey.ChatGroupMessage}?projectId=${locationState?.projectId}`);
      toast({
        message: 'Message created successfully',
        variant: 'success',
      });
      navigate('./..', { replace: true });
    } catch (error: any) {
      toastServerError(error);
    }
  };

  if (!locationState) {
    navigateBack(); // This will navigate back if there is no project
    return null; // This prevents the rest of the component from rendering
  }

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        blockCloseAttempt={isSubmitting}
        cancelButton
        title={locationState?.mode === FormMode.Create ? 'Create Message' : 'Edit Message'}
        label='create-new-project-dialog'
        maxWidth='sm'
        mainAction={{
          children: scheduleMessage ? 'Schedule Message' : 'Send Message',
          onClick: () => {
            setSendMode('now');
            handleSubmit((data) => handleOnSubmit(data, true))();
          },
          type: 'button',
          disabled: isSubmitting,
          isSubmitting: isSubmitting && sendMode === 'now',
        }}
        {...(locationState.messageStatus !== ChatGroupMessageStatus.Published && {
          secondaryAction: {
            children: locationState?.mode === FormMode.Create ? 'Save Draft' : 'Update Draft',
            onClick: () => {
              setSendMode('schedule');
              handleSubmit((data) => handleOnSubmit(data, false))();
            },
            type: 'button',
            disabled: isSubmitting,
            isSubmitting: isSubmitting && sendMode === 'schedule',
          },
        })}
        open
      >
        <Stack width={1} gap={2}>
          <FormRadioGroup
            row
            options={Object.values(messageTypesConfig).map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            {...type}
            onChange={() => {
              setValue(GroupMessageFormFieldsNames.Message, '');
              setValue(GroupMessageFormFieldsNames.Photo, '');
              clearErrors();
            }}
          />
          {typeVal === MessageTypes.Photo && (
            <>
              <Typography mb={1} color='text.secondary' variant='body2'>
                Add a URL to an image/file publicly available; for images you can use{' '}
                <Link target='_blank' href='https://postimages.org/' rel='noreferrer'>
                  postimage
                </Link>
              </Typography>

              <FormTextField autoFocus={!isMobile} {...photo} />
            </>
          )}
          <FormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...restMessageProps}
            placeholder={messagePlaceHolder}
          />

          <FormAutoComplete multiple options={groupsList} {...groups} />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={scheduleMessage}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={scheduleMessage ? 'Schedule Message' : 'Click to Schedule Message'}
            />
          </FormGroup>

          <Collapse in={scheduleMessage}>
            <FormDateTimePicker disablePast {...startDate} />
          </Collapse>
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default MessageFormModal;
