import { Stack } from '@mui/material';
import * as React from 'react';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import FormArrayRemoveButton from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormArrayRemoveButton';
import FormWithCommandAutoComplete from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormWithCommandAutoComplete';
import {
  OptionsQuestionStepFormFieldsNames,
  OptionsQuestionStepFormFieldsOptionsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-question/types';
import { OptionsQuestionFormOptionsFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-question/utils';

const AnswersFormArrayField = ({ getFieldName, index, remove, length }: FormArrayFieldProps) => {
  const { name, label, ...rest } =
    OptionsQuestionFormOptionsFieldsConfig[OptionsQuestionStepFormFieldsOptionsNames.Answer];

  const answerFieldName = getFieldName(name);

  return (
    <Stack direction='row' alignItems='flex-start' gap={0.5}>
      <FormWithCommandAutoComplete
        name={answerFieldName}
        parentName={OptionsQuestionStepFormFieldsNames.Answers}
        placeholder={`${label} ${index + 1}`}
        {...rest}
      />
      <FormArrayRemoveButton onClick={() => remove(index)} disabled={length === 1} />
    </Stack>
  );
};

export default AnswersFormArrayField;
