import { UserRole } from 'enums';

export enum InviteOtOrganizationFormFieldsNames {
  Email = 'email',
  Role = 'role',
}
export interface InviteOtOrganizationFields {
  [InviteOtOrganizationFormFieldsNames.Email]: string;
  [InviteOtOrganizationFormFieldsNames.Role]: UserRole;
}
