import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { BasicModal, FormTextField } from '@common-components';
import { api, ApiKey } from 'api';

import { useFormProvider, useIsMobile, useNavigateBack, useServerError, useToast } from 'hooks';
import { useDirtyStore } from 'store';
import { Project } from 'types';
import { BotFormFields } from './types';
import { botFormFieldsConfig, botSchema, defaultBotFormValues } from './utils';

const RenameProjectModal = () => {
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const { name, description } = botFormFieldsConfig;

  const location = useLocation();
  const { isMobile } = useIsMobile();
  const navigateBack = useNavigateBack();

  const project = location.state?.project as Project | undefined;
  const focusDescription = location.state?.focusDescription as boolean | undefined;

  const formMethods = useFormProvider<BotFormFields>({
    reValidateMode: 'onChange',
    schema: botSchema,
    defaultValues: project
      ? {
          name: project?.name,
          description: project?.description,
        }
      : {
          ...defaultBotFormValues,
        },
  });
  const navigate = useNavigate();
  const toastServerError = useServerError();
  const toast = useToast();
  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = async (formData: BotFormFields) => {
    setIsDirty(false);
    try {
      await api.patch<Project>(`${ApiKey.Project}/${project?.id}`, formData);
      await mutate(ApiKey.Project);
      await mutate(`${ApiKey.Project}/${project?.id}`);
      toast({
        message: 'Project updated successfully',
        variant: 'success',
      });
      navigate(-1);
    } catch (error: any) {
      toastServerError(error);
    }
  };

  if (!project) {
    navigateBack(); // This will navigate back if there is no project
    return null; // This prevents the rest of the component from rendering
  }

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        onSubmit={handleSubmit(handleOnSubmit)}
        title='Rename Project'
        label='create-bot-dialog'
        cancelButton
        maxWidth='sm'
        submittingText={project ? 'Updating...' : 'Creating...'}
        mainAction={{
          children: project ? 'Update' : 'Create',
          type: 'submit',
          isSubmitting: formState.isSubmitting,
          color: formState.isDirty ? 'info' : 'primary',
        }}
        open
      >
        <Stack width={1} gap={2}>
          <FormTextField autoFocus={!isMobile && !focusDescription} {...name} />
          <FormTextField
            multiline
            autoFocus={!isMobile && focusDescription}
            rows={2}
            {...description}
          />
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default RenameProjectModal;
