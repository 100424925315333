import { Stack } from '@mui/material';
import { ReactNode } from 'react';

interface StepFormFieldsWrapperProps {
  children: ReactNode;
}
const StepFormFieldsWrapper = ({ children }: StepFormFieldsWrapperProps) => (
  <Stack gap={2} width={1} flex={1} px={2} overflow='auto'>
    {children}
  </Stack>
);

export default StepFormFieldsWrapper;
