import { ButtonProps, CircularProgress } from '@mui/material';
import { Ref } from 'react';
import Button from 'components/Button';

interface SubmitButtonProps extends Omit<ButtonProps, 'ref'> {
  ref?: Ref<HTMLDivElement>;
  children: string;
  submitting?: boolean;
  submittingText?: string;
}

const SubmitButton = ({
  children,
  onClick,
  submitting,
  disabled,
  size = 'large',
  variant = 'contained',
  ref,
  submittingText = 'Submitting...',
  ...rest
}: SubmitButtonProps) => (
  <Button
    color='secondary'
    ref={ref}
    onClick={onClick}
    startIcon={submitting ? <CircularProgress color='inherit' size='1rem' /> : null}
    disabled={submitting || disabled}
    variant={variant}
    size={size}
    {...rest}
  >
    {submitting ? submittingText : children}
  </Button>
);

export default SubmitButton;
