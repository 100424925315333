import { boolean, object, ObjectSchema } from 'yup';
import { SignUpFormFields, SignUpFormFieldsNames } from 'pages/auth-pages/sign-up/types';
import { FormFieldProps } from 'types';
import { yupPasswordValidation, yupRequiredEmailValidation } from 'utils';

export const signUpSchema: ObjectSchema<SignUpFormFields> = object({
  [SignUpFormFieldsNames.Email]: yupRequiredEmailValidation,
  [SignUpFormFieldsNames.Password]: yupPasswordValidation,
  [SignUpFormFieldsNames.AgreeToTerms]: boolean()
    .required()
    .test('must-be-true', 'You must agree to the terms', (value) => value),
});

export const defaultSignUpFormValues: SignUpFormFields = {
  [SignUpFormFieldsNames.Email]: '',
  [SignUpFormFieldsNames.Password]: '',
  [SignUpFormFieldsNames.AgreeToTerms]: true,
};

export const signUpFormFieldsConfig: Record<SignUpFormFieldsNames, FormFieldProps> = {
  [SignUpFormFieldsNames.Email]: {
    placeholder: 'Email',
    name: SignUpFormFieldsNames.Email,
    autoComplete: 'email',
    type: 'email',
  },
  [SignUpFormFieldsNames.Password]: {
    placeholder: 'Password',
    name: SignUpFormFieldsNames.Password,
    autoComplete: 'current-password',
    type: 'password',
  },
  [SignUpFormFieldsNames.AgreeToTerms]: {
    label: 'I agree to the Terms of Service and Privacy Policy',
    name: SignUpFormFieldsNames.AgreeToTerms,
    type: 'checkbox',
  },
};
