import useSWR from 'swr';
import { ApiKey } from 'api';
import { User } from 'types';

const defaultActiveUsers: User[] = [];

export const useGetOrganizationActiveUser = () => {
  const { data, error, isLoading } = useSWR<User[]>(`${ApiKey.Users}`);

  return {
    activeUsers: data ?? defaultActiveUsers,
    isLoading,
    error,
  };
};
