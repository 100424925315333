import { object, ObjectSchema, string } from 'yup';

import {
  GroupFormFields,
  GroupFormFieldsNames,
} from 'pages/app-pages/messages-scheduler-page/groups-drawer/types';
import { FormFieldProps } from 'types';

export const groupFormDefaultValues: GroupFormFields = {
  [GroupFormFieldsNames.Id]: '',
  [GroupFormFieldsNames.Name]: '',
  [GroupFormFieldsNames.Project]: '',
};

export const groupFormSchema: ObjectSchema<GroupFormFields> = object({
  [GroupFormFieldsNames.Id]: string().required('The Channel ID is required.'),
  [GroupFormFieldsNames.Name]: string().required('The Channel Name is required.'),
  [GroupFormFieldsNames.Project]: string().required('The Project is required.'),
});

export const formFieldsConfig: Record<GroupFormFieldsNames, FormFieldProps> = {
  [GroupFormFieldsNames.Id]: {
    label: 'Channel ID',
    name: GroupFormFieldsNames.Id,
    type: 'text',
    placeholder: 'Channel ID',
  },
  [GroupFormFieldsNames.Name]: {
    label: 'Channel Name',
    name: GroupFormFieldsNames.Name,
    type: 'text',
    placeholder: 'Channel Name',
  },
  [GroupFormFieldsNames.Project]: {
    label: 'Project',
    name: GroupFormFieldsNames.Project,
  },
};
