import useSWR from 'swr';
import { ApiKey } from 'api';
import { OrganizationDashboardData } from 'types';

const defaultData: OrganizationDashboardData = {chatCount:0 , lastWeekChatCount:{}, semiAnnualChatCount:{}}

export const useGetOrganizationDashboardData = () => {
  const { data, error, isLoading } = useSWR<OrganizationDashboardData>(ApiKey.OrganizationDashboardData);

  return {
    organizationDashboardData: data ?? defaultData,
    isLoading,
    error,
  };
};
