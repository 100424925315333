import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
// import SimpleHeader from './simple-header/SimpleHeader';

const SimpleLayout = () => (
  <>
    {/* <SimpleHeader /> */}
    <Box display='flex' flexGrow={1} bgcolor='grey.100'>
      <Container maxWidth='lg'>
        <Outlet />
      </Container>
    </Box>
  </>
);

export default SimpleLayout;
