import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { FormTextField } from '@common-components';
import { useFormProvider } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import { ForgotPasswordFormFields } from 'pages/auth-pages/forgot-password/types';
import {
  defaultForgotPasswordFormValues,
  forgotPasswordFormFieldsConfig,
  forgotPasswordSchema,
} from 'pages/auth-pages/forgot-password/utils';

interface ForgotPasswordFormProps {
  defaultValues?: Partial<ForgotPasswordFormFields>;
}

const ForgotPasswordForm = ({
  defaultValues = defaultForgotPasswordFormValues,
}: ForgotPasswordFormProps) => {
  const formMethods = useFormProvider<ForgotPasswordFormFields>({
    schema: forgotPasswordSchema,
    defaultValues,
  });

  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = (formData: ForgotPasswordFormFields) =>
    // create promise to simulate async validation
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    }).then(() => {
      // eslint-disable-next-line no-console
      console.log(formData, 'formData');
    });

  const { email } = forgotPasswordFormFieldsConfig;

  return (
    <FormProvider {...formMethods}>
      <Stack width={1} gap={2} component='form' noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <FormTextField size='small' {...email} />
        <SubmitButton
          sx={{
            py: 2,
          }}
          fullWidth
          size='medium'
          variant='contained'
          color='primary'
          type='submit'
          submitting={formState.isSubmitting}
        >
          Send password reset email
        </SubmitButton>
      </Stack>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
