import { AutoCompleteLabel } from 'types';

export enum NavigateStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  NodeId = 'nodeId',
  Links = 'links',
  Labels = 'labels',
}

export interface NavigateStepFormFields {
  [NavigateStepFormFieldsNames.NodeId]: string;
  [NavigateStepFormFieldsNames.Name]: string;
  [NavigateStepFormFieldsNames.Input]: string;
  [NavigateStepFormFieldsNames.Links]: NavigateStepFormFieldsLinks[];
  [NavigateStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export enum NavigateStepFormFieldsLinksNames {
  Name = 'name',
  Text = 'text',
  Url = 'url',
  NodeId = 'nodeId',
}

export interface NavigateStepFormFieldsLinks {
  [NavigateStepFormFieldsLinksNames.Name]: string;
  [NavigateStepFormFieldsLinksNames.Text]: string;
  [NavigateStepFormFieldsLinksNames.Url]: string;
  [NavigateStepFormFieldsLinksNames.NodeId]: string;
}
