import { GridColDef } from '@mui/x-data-grid';
import { formatDistanceToNowStrict } from 'date-fns';
import { Label, TextCell, TitleCell } from '@common-components';

import AppsIconsList from 'pages/projects-page/AppsIconsList';
import ProjectDropdownWrapper from 'pages/projects-page/ProjectDropdownWrapper';

import { BotColumns } from 'pages/projects-page/types';
import { Project } from 'types';
import { sortByBoolean, sortByDate } from 'utils';

export const listViewConfig: GridColDef[] = [
  {
    field: BotColumns.Name,
    headerName: 'Name',
    width: 200,
    renderCell: TitleCell,
  },
  {
    field: BotColumns.Description,
    headerName: 'Description',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: TextCell,
  },
  {
    field: BotColumns.Apps,
    headerName: 'Connected apps',
    minWidth: 200,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row, value }) => (
      <AppsIconsList id={row.id} apps={value} projectName={row.name} isPublic={row.token} />
    ),
  },
  {
    field: BotColumns.UpdatedAt,
    headerName: 'Last modified',
    sortComparator: sortByDate,
    valueFormatter: ({ value }) => formatDistanceToNowStrict(new Date(value), { addSuffix: true }),
    width: 200,
    renderCell: TextCell,
  },
  {
    field: BotColumns.IsActive,
    headerName: 'Status',
    width: 120,
    sortComparator: sortByBoolean,
    disableColumnMenu: true,
    renderCell: ({ row }: { row: Project }) => (
      <Label color={row.token ? 'success' : 'error'}>
        {row.token ? 'Published' : 'Not published'}
      </Label>
    ),
  },
  {
    field: 'id',
    headerName: '',
    width: 150,
    align: 'right',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => <ProjectDropdownWrapper id={value} icon='more_horiz' />,
  },
];
