import { FormTextFieldProps } from 'components/form-fields/FormTextField';
import FormTextFieldInlineEdit from 'components/form-fields/FormTextFieldInlineEdit';
import { lowerCaseAndUnderscore } from 'utils';

const FormVariableField = (props: FormTextFieldProps) => (
  <FormTextFieldInlineEdit
    text='add variable'
    icon='data_object'
    size='small'
    textVariant='caption'
    tooltipPlacement='left'
    prefix='@'
    valueManipulator={lowerCaseAndUnderscore}
    tooltip='Click to edit the variable name, you can use @ to mention a variable in the next step'
    startAdornment
    {...props}
  />
);

export default FormVariableField;
