import { Alert, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { RoutePath } from 'enums';
import { useAuth } from 'hooks';
import { daysUntilPlanEnds, planIsOnGoing } from 'utils';

const UpgradeAccountBanner = () => {
  const { user } = useAuth();
  const { value: isBannerOpen, setFalse: closeBanner } = useBoolean(true);

  if (!user?.organization?.accountPlan?.endDate) {
    return null;
  }

  if (planIsOnGoing(user?.organization?.accountPlan?.endDate) || !isBannerOpen) {
    return null;
  }

  const daysLeft = user?.organization?.accountPlan?.endDate
    ? daysUntilPlanEnds(new Date(user?.organization?.accountPlan?.endDate))
    : null;

  return (
    <Alert
      onClose={closeBanner}
      severity='warning'
      sx={{ borderRadius: 0, position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}
    >
      Your current plan will expire in <strong>{daysLeft}</strong> days.{' '}
      <Link component={RouterLink} to={`/${RoutePath.ManageAccount}`}>
        Upgrade now
      </Link>{' '}
      to continue enjoying premium features without interruption!
    </Alert>
  );
};

export default UpgradeAccountBanner;
