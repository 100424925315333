import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { api, ApiKey } from 'api';
import { ConfirmationDialog, Icon } from 'components/index';
import { AppTypes } from 'enums';
import { appConfig } from 'forms/create-project/utils';
import { OptionsMenuState, useMenu, usePauseApp, useServerError, useToast } from 'hooks';
import { menuMinWidth } from 'theme/utils';
import { Project } from 'types';

interface BotItemMenuProps extends OptionsMenuState {
  project: Project;
  appType: AppTypes;
}

function AppDropdownMenu({ project, appType, closeMenu, anchorEl, isMenuOpen }: BotItemMenuProps) {
  const navigate = useNavigate();
  const isAppActive = !!project?.apps?.find((app) => app.type === appType)?.isActive;
  const toast = useToast();
  const toastServerError = useServerError();
  const {
    openMenu: openDeletePrompt,

    ...restDeletePrompt
  } = useMenu();

  const { cta, appUrl } = appConfig[appType];

  const disconnectApp = async () => {
    try {
      await api.delete(`${ApiKey.Project}/${project.id}/app/${appType}`);
      await mutate(ApiKey.Project);
      await mutate(`${ApiKey.Project}/${project.id}`);
      toast({
        message: `${appConfig[appType].label} removed successfully`,
        variant: 'info',
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  const { togglePause, pausing } = usePauseApp({
    project,
    appType,
  });
  return (
    <>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate(`/${appUrl}/${project.id}`);
          }}
        >
          <ListItemIcon>
            <Icon icon='account_tree' fontSize='small' />
          </ListItemIcon>
          <ListItemText>{cta}</ListItemText>
        </MenuItem>
        {/*    <MenuItem
          onClick={() => {
            closeMenu();
            navigate(`/${RoutePath.Activity}`, {
              state: {
                projectName: project?.name,
              },
            });
          }}
        >
          <ListItemIcon>
            <Icon icon='history' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Bot Activity</ListItemText>
        </MenuItem> */}
        <Divider />

        {project?.token && (
          <MenuItem
            onClick={async () => {
              await togglePause();
              closeMenu();
            }}
          >
            <ListItemIcon>
              {pausing ? (
                <CircularProgress color='inherit' size='1rem' />
              ) : (
                <Icon
                  icon={isAppActive ? 'pause_circle_filled' : 'play_circle_filled'}
                  fontSize='small'
                />
              )}
            </ListItemIcon>
            <ListItemText>{isAppActive ? 'Pause App' : 'Activate App'}</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={openDeletePrompt}>
          <ListItemIcon>
            <Icon icon='delete_forever' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Remove App</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        {...restDeletePrompt}
        submittingText='Removing App...'
        text='Are you sure you want to remove this app from the project? All associated data will be lost. This action cannot be undone.'
        onConfirm={async () => {
          await disconnectApp();
          closeMenu();
        }}
      />
    </>
  );
}

export default AppDropdownMenu;
