import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TicketStatus } from 'enums';
import { useGetOrganizationActiveUser, useMount } from 'hooks';
import { ChatColumns } from 'pages/activity-page/types';
import { ChatTableProps, UserDisplay, UserLabel } from 'types';

const unassignedOption = { id: 'unassigned', email: 'Unassigned' };

const useTicketingTable = (data: ChatTableProps[]) => {
  const navigate = useNavigate();
  const location = useLocation();

  const projectName = useMemo(() => location.state?.projectName || '', [location.state]);
  const { activeUsers } = useGetOrganizationActiveUser();

  const [isImportantFilter, setIsImportantFilter] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState<UserLabel[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedProjectNames, setSelectedProjectNames] = useState<string[]>(
    projectName ? [projectName] : [],
  );

  const [selectedAssignees, setSelectedAssignees] = useState<UserDisplay[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<TicketStatus[]>([]);

  const projectNames = useMemo(() => {
    const namesSet = new Set(data?.map((chat) => chat.projectName || ''));
    return Array.from(namesSet);
  }, [data]);

  const assigneeOptions: UserDisplay[] = useMemo(() => {
    const hasUnassignedChats = data.some((chat) => chat.assignee === undefined);

    // Collect all unique assignee IDs from the chats that have an assignee defined
    const assigneeIds = new Set(data.map((chat) => chat.assignee).filter((id) => id));

    // Filter the users array to include only those users whose IDs are in the set of assigneeIds
    const assignedUsers = activeUsers
      .filter((user) => assigneeIds.has(user.id))
      .map((user) => ({
        id: user.id,
        email: user.email,
      }));

    // Include an option for unassigned chats
    // The "Unassigned" option is added with a unique identifier and label

    return hasUnassignedChats ? [unassignedOption, ...assignedUsers] : assignedUsers;
  }, [data, activeUsers]);

  const userLabels: UserLabel[] = useMemo(() => {
    // First, flatten all labels into a single array
    const labels: UserLabel[] = data.flatMap((chat) => chat.labels || []);

    // Use a Map to ensure uniqueness and preserve the original order
    const uniqueLabelsMap = new Map<string, UserLabel>();

    labels.forEach((label) => {
      uniqueLabelsMap.set(label.id, label);
    });

    // Convert back to an array of UserLabel
    return Array.from(uniqueLabelsMap.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  useMount(() => {
    if (projectName) {
      navigate(location.pathname, {
        state: { ...location.state, projectName: undefined },
        replace: true,
      });
    }
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: ChatColumns.UpdatedAt,
      sort: 'asc',
    },
  ]);

  const filteredChatData = useMemo(() => {
    let filteredData = data;
    if (isImportantFilter) {
      filteredData = filteredData.filter((item) => item.isImportant);
    }
    if (selectedLabels.length > 0) {
      filteredData = filteredData.filter((item) =>
        item.labels?.some((label) =>
          selectedLabels.some((selectedLabel) => selectedLabel.id === label.id),
        ),
      );
    }
    // Filter by selected project names
    if (selectedProjectNames.length > 0) {
      filteredData = filteredData.filter((item) => selectedProjectNames.includes(item.projectName));
    }
    if (!showArchived) {
      filteredData = filteredData.filter((item) => !item.isArchived);
    }

    if (selectedStatuses.length > 0) {
      filteredData = filteredData.filter(
        (item) => item.status && selectedStatuses.includes(item.status),
      );
    }

    if (selectedAssignees.length > 0) {
      filteredData = filteredData.filter((chat) => {
        // Check if the chat is unassigned and if 'unassigned' is selected
        if (!chat.assignee) {
          return selectedAssignees.some((assignee) => assignee.id === 'unassigned');
        }
        // Check if the chat's assignee ID matches any selected UserDisplay's ID
        return selectedAssignees.some((assignee) => assignee.id === chat.assignee);
      });
    }

    return filteredData;
  }, [
    data,
    isImportantFilter,
    selectedAssignees,
    selectedLabels,
    selectedProjectNames,
    selectedStatuses,
    showArchived,
  ]);

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
    },
    [setSortModel],
  );

  return {
    chatData: data,
    sortModel,
    onSortModelChange,
    projectNames,
    assigneeOptions,
    isImportantFilter,
    setIsImportantFilter,
    selectedLabels,
    setSelectedLabels,
    selectedProjectNames,
    setSelectedProjectNames,
    selectedAssignees,
    setSelectedAssignees,
    selectedStatuses,
    setSelectedStatuses,
    showArchived,
    setShowArchived,
    userLabels,
    filteredChatData,
    projectName,
  };
};

export default useTicketingTable;
