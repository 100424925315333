import { object, ObjectSchema, string } from 'yup';
import { FormFieldProps } from 'types';
import { BotSettingsFormFields, BotSettingsFormFieldsNames } from './types';

export const botSettingsSchema: ObjectSchema<BotSettingsFormFields> = object({
  [BotSettingsFormFieldsNames.Token]: string().required('Provide Bot Token.'),
});

export const defaultBotSettingsFormValues: BotSettingsFormFields = {
  [BotSettingsFormFieldsNames.Token]: '',
};

export const botSettingsFormFieldsConfig: Record<BotSettingsFormFieldsNames, FormFieldProps> = {
  [BotSettingsFormFieldsNames.Token]: {
    label: 'Bot Token',
    placeholder: 'Provide Bot Name',
    name: BotSettingsFormFieldsNames.Token,
    autoComplete: 'off',
    type: 'password',
  },
};
