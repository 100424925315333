import { PickEnum } from 'utils';

export enum BulkMessageColumns {
  SendAttempt = 'sendAttempt',
  Message = 'message',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  DeliveryDate = 'deliveryDate',
  Recipients = 'recipients',
  ChatFilter = 'chatFilter',
}

export type BulkMessageSortableKeys = PickEnum<
  BulkMessageColumns,
  BulkMessageColumns.Message | BulkMessageColumns.DeliveryDate | BulkMessageColumns.UpdatedAt
>;
