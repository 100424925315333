import { alpha, MenuProps } from '@mui/material';
import { menuMinWidth } from 'theme/utils';

export const addStepMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  slotProps: {
    paper: {
      sx: {
        minWidth: menuMinWidth,
        maxWidth: {
          xs: 'calc(100% - 32px)',
          sm: 480,
        },
      },
    },
  },
  sx: {
    '& .MuiBackdrop-root': {
      backgroundColor: (theme) => alpha(theme.palette.common.black, 0.24),
    },
  },
};
