import { create } from 'zustand';

export type UseHistoryLength = {
  length: number;
  increment: () => void;
};

export const useHistoryLength = create<UseHistoryLength>((set) => ({
  length: 0,
  increment: () => set((state) => ({ length: state.length + 1 })),
}));
