import { DataGrid, GridRowClassNameParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomDataGridSlots from 'components/data-grid/CustomDataGridSlots';
import { AccountPlanType, RoutePath } from 'enums';
import { useFeatureAccess } from 'hooks';
import ActivityCustomToolbar from 'pages/activity-page/activity-toolbar/ActivityCustomToolbar';
import { getUpdatedColumns } from 'pages/activity-page/columnsConfig';
import { tableStyle } from 'pages/activity-page/styles';
import { ActivityTableProps, ChatColumns } from 'pages/activity-page/types';
import { ChatTableProps } from 'types';

const columnsToHide = [ChatColumns.Labels, ChatColumns.ConversationCount, ChatColumns.Assignee];
const columnsToHideForOrganization = [ChatColumns.Labels, ChatColumns.ConversationCount];

const TicketingTable = ({
  isLoading,
  chatData,
  filteredChatData,
  sortModel,
  onSortModelChange,
  projectNames,
  setSelectedProjectNames,
  projectName,
  selectedProjectNames,
  setIsImportantFilter,
  isImportantFilter,
  userLabels,
  showArchived,
  selectedLabels,
  setSelectedLabels,
  selectedStatuses,
  setSelectedStatuses,
  selectedAssignees,
  setSelectedAssignees,
  assigneeOptions,
  setShowArchived,
}: ActivityTableProps) => {
  const navigate = useNavigate();

  const updatedColumns = getUpdatedColumns(columnsToHide);
  const updatedColumnsForOrganization = getUpdatedColumns(columnsToHideForOrganization);

  const hasAccessToAssigneePlan = useFeatureAccess({
    minPlan: AccountPlanType.Premium,
  });

  const renderColumns = hasAccessToAssigneePlan ? updatedColumnsForOrganization : updatedColumns;

  return (
    <DataGrid
      loading={isLoading}
      rows={filteredChatData || []}
      columns={renderColumns}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      rowHeight={70}
      sortingOrder={['asc', 'desc']}
      sx={tableStyle()}
      disableRowSelectionOnClick
      disableColumnSelector
      disableDensitySelector
      disableColumnFilter
      disableColumnMenu
      getRowClassName={(params: GridRowClassNameParams<ChatTableProps>) => {
        if (!params.row.isRead) {
          return 'unread-row';
        }
        if (params.row.isArchived) {
          return 'archived-row';
        }
        return 'chat-row';
      }}
      slots={{
        ...CustomDataGridSlots({
          noRowsOverlayProps: {
            text: 'Tickets will appear here once "Human Handoff" node is triggered in the chatbot builder.',
          },
        }),
        toolbar: ActivityCustomToolbar,
      }}
      onRowClick={({ row }) => {
        navigate(`${RoutePath.ChatActivity}/${row.id}`);
      }}
      slotProps={{
        toolbar: {
          projectNames,
          assigneeOptions,
          isImportantFilter,
          setIsImportantFilter,
          projectName,
          userLabels,
          selectedLabels,
          setSelectedLabels,
          setSelectedProjectNames,
          selectedProjectNames,
          chatCount: chatData.length,
          archivedChatCount: chatData.filter((item) => item.isArchived).length,
          filteredChatCount: filteredChatData.length,
          sortModel,
          onSortModelChange,
          showArchived,
          setShowArchived,
          title: 'Support Tickets',
          selectedStatuses,
          setSelectedStatuses,
          selectedAssignees,
          setSelectedAssignees,
        },
      }}
    />
  );
};

export default TicketingTable;
