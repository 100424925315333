import { Alert, Stack } from '@mui/material';
import { memo, useCallback, useRef, useState } from 'react';
import {
  FormAutoComplete,
  FormSection,
  FormTextEditor,
  FormTextFieldInlineEdit,
} from '@common-components';
import { AccountPlanType } from 'enums';
import {
  useFeatureAccess,
  useFormProvider,
  useGetOrganizationActiveUser,
  useIsMobile,
  useProcessNewLabels,
} from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import ConnectWithTelegram from 'pages/app-pages/chatbot-builder-page/nodes/components/ConnectWithTelegram';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';

import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { HumanHandoffStepFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/human-handoff/types';
import {
  humanHandoffFormFieldsConfig,
  humanHandoffStepFormSchema,
  humanHandoffStepStepFormValues,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/human-handoff/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface HumanHandoffProps {
  id: string;
  defaultValues?: Partial<HumanHandoffStepFormFields>;
  defaultName: string;
  mentions?: Mention[];
  commands?: Mention[];
}

const HumanHandoff = ({
  defaultValues,
  mentions,
  commands,
  id,
  defaultName,
}: HumanHandoffProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...humanHandoffStepStepFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    labels: defaultValues?.labels || humanHandoffStepStepFormValues.labels || [],
    notifyList: defaultValues?.notifyList || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<HumanHandoffStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<HumanHandoffStepFormFields>({
    schema: humanHandoffStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const hasAccessToAssigneePlan = useFeatureAccess({
    minPlan: AccountPlanType.Premium,
  });

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { reset, formState } = formMethods;

  const { activeUsers, isLoading: loadingActiveUsers } = useGetOrganizationActiveUser();

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: HumanHandoffStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, notifyList, labels, input, notificationMessage } = humanHandoffFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        {hasAccessToAssigneePlan && (
          <FormSection
            title='Notify List'
            infoIcon={{
              tooltip:
                'Select the users who should be notified when a user reaches this node. Notifications will be sent to the selected users via Telegram.',
              icon: 'info',
            }}
          >
            <FormAutoComplete
              multiple
              autoSelect
              loading={loadingActiveUsers}
              options={activeUsers
                ?.filter((user) => user.telegram)
                .map((user) => ({
                  value: user.id,
                  label: user.email,
                }))}
              {...notifyList}
              size='small'
            />
          </FormSection>
        )}
        <Stack>
          <ConnectWithTelegram />
          <Alert sx={{ border: 1, borderColor: 'divider' }} icon={false} severity='warning'>
            This node must be the final node in the flow, and no other nodes can be connected to it.
          </Alert>
        </Stack>
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
        <FormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...notificationMessage}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(HumanHandoff);
