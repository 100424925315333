import { AccountPlanType } from 'enums';
import { PlanProps } from 'types';

export const planConfig: Record<AccountPlanType, PlanProps> = {
  [AccountPlanType.Basic]: {
    name: 'Free',
    plan: AccountPlanType.Basic,
    color: 'primary',
    icon: 'redeem',
    level: 1,
  },
  [AccountPlanType.Pro]: {
    name: 'Pro',
    plan: AccountPlanType.Pro,
    color: 'success',
    icon: 'stars',
    level: 2,
  },
  [AccountPlanType.Premium]: {
    name: 'Premium',
    plan: AccountPlanType.Premium,
    color: 'secondary',
    icon: 'award_star',
    level: 3,
  },
};
