import { array, mixed, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  Method,
  SendDataFormFields,
  SendDataFormFieldsNames,
  SendDataPropFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-data/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { variableValidation } from 'utils';

export const sendDataFormSchema: ObjectSchema<SendDataFormFields> = object({
  [SendDataFormFieldsNames.NodeId]: string().required(),
  [SendDataFormFieldsNames.Name]: string().required('Name is required'),
  [SendDataFormFieldsNames.WebHookUrl]: string().url('Invalid URL').required('URL is required'),
  [SendDataFormFieldsNames.Format]: string().required('Format is required'),
  [SendDataFormFieldsNames.Variable]: variableValidation,

  [SendDataFormFieldsNames.Method]: mixed<Method>()
    .oneOf(Object.values(Method))
    .required('The Type is required.'),
  [SendDataFormFieldsNames.PropFields]: array(
    object({
      [SendDataPropFieldsNames.Key]: string().required('Key is required'),
      [SendDataPropFieldsNames.Value]: string().required('Value is required'),
    }),
  ).required(),
  [SendDataFormFieldsNames.MagicPropFields]: array(
    object({
      [SendDataPropFieldsNames.Key]: string().required('Key is required'),
      [SendDataPropFieldsNames.Value]: string().required('Value is required'),
    }),
  ),
  [SendDataFormFieldsNames.Headers]: array(
    object({
      [SendDataPropFieldsNames.Key]: string().required('Key is required'),
      [SendDataPropFieldsNames.Value]: string().required('Value is required'),
    }),
  ).required(),
  [SendDataFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const formatMenuOptions = [
  { value: '$data', label: 'Default' },
  { value: '{"records": [ { "fields":$data } ]}', label: 'Airtable' },
  { value: '$data', label: 'Make.com' },
];

export const defaultSendDataFormValues: Partial<SendDataFormFields> = {
  [SendDataFormFieldsNames.Name]: '',
  [SendDataFormFieldsNames.NodeId]: '',
  [SendDataFormFieldsNames.WebHookUrl]: 'https://',
  [SendDataFormFieldsNames.Method]: Method.POST,
  [SendDataFormFieldsNames.Format]: formatMenuOptions[0].label,
  [SendDataFormFieldsNames.PropFields]: [{ key: '', value: null }],
  [SendDataFormFieldsNames.MagicPropFields]: [],
  [SendDataFormFieldsNames.Headers]: [{ key: 'Content-Type', value: 'application/json' }],
  [SendDataFormFieldsNames.Labels]: [],
};

export const sendDataFieldsConfig: Record<SendDataFormFieldsNames, FormFieldProps> = {
  [SendDataFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: SendDataFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [SendDataFormFieldsNames.PropFields]: {
    label: 'Request Data Object',
    placeholder: 'Add Object Property',
    name: SendDataFormFieldsNames.PropFields,
    autoComplete: 'off',
    type: 'text',
  },
  [SendDataFormFieldsNames.MagicPropFields]: {
    label: 'Magic Properties',
    placeholder: 'Add Magic Property',
    name: SendDataFormFieldsNames.MagicPropFields,
    autoComplete: 'off',
    type: 'text',
  },
  [SendDataFormFieldsNames.Headers]: {
    label: 'HTTP Headers',
    placeholder: 'Add Header',
    name: SendDataFormFieldsNames.Headers,
    autoComplete: 'off',
    type: 'text',
  },
  [SendDataFormFieldsNames.WebHookUrl]: {
    placeholder: 'Input WebHook URL',
    name: SendDataFormFieldsNames.WebHookUrl,
    autoComplete: 'off',
    type: 'text',
  },
  [SendDataFormFieldsNames.Variable]: {
    label: 'Response Variable',
    placeholder: 'Variable to Hold Response',
    name: SendDataFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [SendDataFormFieldsNames.Method]: {
    placeholder: 'Method',
    name: SendDataFormFieldsNames.Method,
    type: 'text',
  },
  [SendDataFormFieldsNames.Format]: {
    // label: 'Provider Format',
    placeholder: 'Select Provider',
    name: SendDataFormFieldsNames.Format,
    type: 'text',
  },
  [SendDataFormFieldsNames.NodeId]: {
    name: SendDataFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [SendDataFormFieldsNames.Labels]: labelFormFieldConfig,
};

export const SendDataPropFieldsConfig: Record<SendDataPropFieldsNames, FormFieldProps> = {
  [SendDataPropFieldsNames.Value]: {
    label: 'Value',
    placeholder: 'Value',
    name: SendDataPropFieldsNames.Value,
    type: 'text',
  },
  [SendDataPropFieldsNames.Key]: {
    label: 'Key',
    placeholder: 'Key',
    name: SendDataPropFieldsNames.Key,
    autoComplete: 'off',
    type: 'text',
  },
};
