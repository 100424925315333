import { Skeleton, Stack } from '@mui/material';

const ChatSkeleton = () => (
  <Stack p={2} height={1}>
    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
      <Stack flex={1}>
        <Stack direction='row' gap={1} flex={1} justifyContent='flex-start' alignItems='center'>
          <Skeleton variant='rounded' width={38} height={38} />
          <Stack flex={1}>
            <Skeleton variant='text' sx={{ fontSize: '1.2rem' }} width='70%' />
            <Skeleton variant='text' sx={{ fontSize: '0.8rem' }} width='60%' />
          </Stack>
          <Skeleton variant='text' width={40} sx={{ alignSelf: 'flex-start' }} />
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export default ChatSkeleton;
