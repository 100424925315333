import { create } from 'zustand';

export type GroupsDrawerState = {
  drawerOpen: boolean;
  setDrawerState: (drawerOpen: boolean) => void;
  toggleDrawer: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
};

export const useGroupsDrawer = create<GroupsDrawerState>((set) => ({
  drawerOpen: false,
  setDrawerState: (drawerOpen) => set({ drawerOpen }),
  toggleDrawer: () => set((state) => ({ drawerOpen: !state.drawerOpen })),
  openDrawer: () => set({ drawerOpen: true }),
  closeDrawer: () => set({ drawerOpen: false }),
}));
