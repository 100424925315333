import { ComponentType } from 'react';
import { ObjectSchema } from 'yup';
import { AccountPlanType, StepType } from 'enums';
import { BasicColors } from 'theme';
import { AutoCompleteLabel, FormDataProps, Mention } from 'types';

export type FormDataWithLabelsProps = Omit<FormDataProps, 'labels'> & {
  labels?: AutoCompleteLabel[];
} & Record<string, any>;

export enum NodeType {
  Input = 'Input',
  Start = 'Start',
  EndCommand = 'EndCommand',
}

export enum EdgeType {
  SingleEdge = 'SingleEdge',
  MultiEdge = 'MultiEdge',
  DeadEnd = 'DeadEnd',
}

export enum StepCategory {
  SingleNodeInput = 'SingleNodeInput',
  MultiNodeInput = 'MultiNodeInput',
  Quiz = 'Quiz',
  Action = 'Action',
  Request = 'Request',
  Integration = 'Integration',
  Logic = 'Logic',
}

export interface StepCategoryProps {
  name: string;
  color: BasicColors;
  icon: string;
  tutorial?: string;
}

export interface StepProps {
  name: string;
  type: StepType;
  edgeType: EdgeType;
  description: string;
  plan: AccountPlanType[];
  stepComponent: ComponentType<{
    id: string;
    defaultName: string;
    defaultValues?: any;
    mentions?: Mention[];
    commands?: Mention[];
    backButton?: boolean;
  }>;
  icon: string;
  category: StepCategory;
  mentions: Mention[];
  schema: ObjectSchema<any>;
}
