import { Divider, Grid, Stack } from '@mui/material';
import { times } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/index';
import { RoutePath } from 'enums';
import { useGetOrganizationPendingUser } from 'hooks';
import SectionWrapper from 'pages/manage-account-page/SectionWrapper';
import EmptyState from 'pages/organization-page/EmptyState';
import PendingUserItem from 'pages/organization-page/PendingUserItem';
import UserItemSkeleton from 'pages/organization-page/UserItemSkeleton';

const PendingUsers = () => {
  const navigate = useNavigate();
  const { pendingUsers, isLoading: loadingPendingUsers } = useGetOrganizationPendingUser();

  return (
    <Grid item xs={12}>
      <SectionWrapper
        disablePadding
        title='Pending Invitations'
        cta={
          <Button
            size='small'
            variant='contained'
            onClick={() => {
              navigate(RoutePath.InviteToOrganization);
            }}
          >
            Invite Member
          </Button>
        }
      >
        <Stack divider={<Divider />}>
          {loadingPendingUsers && times(2, (i) => <UserItemSkeleton key={i} />)}
          {!pendingUsers?.length && !loadingPendingUsers && (
            <EmptyState text='No pending invitations' />
          )}
          {pendingUsers
            ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((pendingUser) => <PendingUserItem key={pendingUser.id} {...pendingUser} />)}
        </Stack>
      </SectionWrapper>
    </Grid>
  );
};

export default PendingUsers;
