import { Alert, AlertColor } from '@mui/material';
import { forwardRef } from 'react';

interface CustomSnackbarProps {
  variant: AlertColor;
  message: string;
}
const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  ({ message, variant = 'info' }, ref) => (
    <Alert
      sx={{ border: 1, borderColor: (theme) => theme.palette[variant].light }}
      ref={ref}
      severity={variant}
    >
      {message}
    </Alert>
  ),
);

export default CustomSnackbar;
