import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Edge, Node, useReactFlow } from 'reactflow';
import { useIsMobile } from 'hooks';
import useSaveToServer from 'pages/app-pages/chatbot-builder-page/hooks/useSaveToServer';
import { useSelectNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSelectNode';
import {
  LayoutDirection,
  useBuilderStore,
  useLayoutDirection,
} from 'pages/app-pages/chatbot-builder-page/store';
import { useDirtyStore } from 'store';
import { BaseNodeProps } from 'types';

type UseUndoRedoOptions = {
  maxHistorySize: number;
  enableShortcuts: boolean;
};

export type UseUndoRedoType = {
  undo: () => void;
  redo: () => void;
  takeSnapshot: () => void;
  canUndo: boolean;
  canRedo: boolean;
  // Add any other types you need here
};

export type UseUndoRedo = (options?: UseUndoRedoOptions) => UseUndoRedoType;

type HistoryItem = {
  nodes: Node[];
  edges: Edge[];
  layoutDirection: LayoutDirection;
};

const defaultOptions: UseUndoRedoOptions = {
  maxHistorySize: 100,
  enableShortcuts: true,
};

// https://redux.js.org/usage/implementing-undo-history
export const useUndoRedo: UseUndoRedo = ({
  maxHistorySize = defaultOptions.maxHistorySize,
  enableShortcuts = defaultOptions.enableShortcuts,
} = defaultOptions) => {
  const saveToServer = useSaveToServer();
  // the past and future arrays store the states that we can jump to
  const [past, setPast] = useState<HistoryItem[]>([]);
  const [future, setFuture] = useState<HistoryItem[]>([]);
  const selectNode = useSelectNode();

  const { id } = useParams();

  if (!id) {
    throw new Error('No bot id provided');
  }

  const {
    closeToolbox,
    editorIsFocused,
    setSelectedStepType,
    setEditorIsFocused,
    setSelectedNodeId,
  } = useBuilderStore((state) => ({
    closeToolbox: state.closeToolbox,
    setSelectedNodeId: state.setSelectedNodeId,
    setSelectedStepType: state.setSelectedStepType,
    editorIsFocused: state.editorIsFocused,
    setEditorIsFocused: state.setEditorIsFocused,
  }));

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  const { setLayoutDirection, layoutDirection } = useLayoutDirection((state) => ({
    setLayoutDirection: state.setLayoutDirection,
    layoutDirection: state.layoutDirection,
  }));

  const { isMobile } = useIsMobile();

  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow<BaseNodeProps>();

  const closeToolboxAndResetSelection = useCallback(() => {
    closeToolbox();
    setIsDirty(false);
    setSelectedStepType(null);
    setEditorIsFocused(false);
    if (!isMobile) {
      setSelectedNodeId(null);
      selectNode(null);
    }
  }, [
    closeToolbox,
    setIsDirty,
    setSelectedStepType,
    setEditorIsFocused,
    isMobile,
    setSelectedNodeId,
    selectNode,
  ]);

  const takeSnapshot = useCallback(async () => {
    // push the current graph to the past state
    setPast((p) => [
      ...p.slice(p.length - maxHistorySize + 1, p.length),
      {
        nodes: getNodes(),
        edges: getEdges(),
        layoutDirection,
      },
    ]);

    // whenever we take a new snapshot, the redo operations need to be cleared to avoid state mismatches
    setFuture([]);
  }, [maxHistorySize, getNodes, getEdges, layoutDirection]);

  const undo = useCallback(async () => {
    // get the last state that we want to go back to
    const pastState = past[past.length - 1];

    if (pastState) {
      // first we remove the state from the history
      setPast((p) => p.slice(0, p.length - 1));
      // we store the current graph for the redo operation

      setFuture((f) => [
        ...f,
        {
          nodes: getNodes(),
          edges: getEdges(),
          layoutDirection,
        },
      ]);

      // now we can set the graph to the past state
      setNodes(pastState.nodes);
      setEdges(pastState.edges);
      setLayoutDirection(pastState.layoutDirection);
      closeToolboxAndResetSelection();
      await saveToServer(); // You wait for the promise to resolve here
    }
  }, [
    past,
    setNodes,
    setEdges,
    setLayoutDirection,
    closeToolboxAndResetSelection,
    saveToServer,
    getNodes,
    getEdges,
    layoutDirection,
  ]);

  const redo = useCallback(() => {
    const futureState = future[future.length - 1]; // Retrieve the last future state to redo

    if (futureState) {
      setFuture((f) => f.slice(0, -1)); // Remove the last state from future
      setPast((p) => [
        ...p,
        {
          nodes: getNodes(), // Current state's nodes
          edges: getEdges(), // Current state's edges
          layoutDirection, // Current layoutDirection
        },
      ]); // Add the current state to past

      // Set the nodes, edges, and layoutDirection to the future state
      setNodes(futureState.nodes);
      setEdges(futureState.edges);
      setLayoutDirection(futureState.layoutDirection);
      closeToolboxAndResetSelection();
    }
  }, [
    future,
    setNodes,
    setEdges,
    setLayoutDirection,
    closeToolboxAndResetSelection,
    getNodes,
    getEdges,
    layoutDirection,
  ]);

  useEffect(() => {
    // this effect is used to attach the global event handlers

    const keyDownHandler = (event: KeyboardEvent) => {
      if (editorIsFocused) {
        return;
      }

      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
        //   redo();
      } else if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        undo();
      }
    };

    if (!enableShortcuts) {
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [undo, redo, enableShortcuts, editorIsFocused]);

  return {
    undo,
    redo,
    takeSnapshot,
    canUndo: !past.length,
    canRedo: !future.length,
  };
};

export default useUndoRedo;
