import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { BasicModal, FormSection, FormSelect, FormTextField } from '@common-components';
import { api, ApiKey } from 'api';

import { useFormProvider, useServerError, useToast } from 'hooks';
import { useDirtyStore } from 'store';
import { InviteOtOrganizationFields } from './types';
import {
  defaultInviteOtOrganizationFormValues,
  inviteOtOrganizationFormFieldsConfig,
  inviteOtOrganizationSchema,
  roleOptions,
} from './utils';

const InviteToOrganizationModal = () => {
  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));
  const { email, role } = inviteOtOrganizationFormFieldsConfig;

  const formMethods = useFormProvider<InviteOtOrganizationFields>({
    reValidateMode: 'onChange',
    schema: inviteOtOrganizationSchema,
    defaultValues: defaultInviteOtOrganizationFormValues,
  });
  const navigate = useNavigate();
  const toastServerError = useServerError();
  const toast = useToast();
  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = async (formData: InviteOtOrganizationFields) => {
    setIsDirty(false);
    try {
      await api.post(`${ApiKey.Users}/${ApiKey.OrganizationPendingUser}`, formData);
      await mutate(`${ApiKey.Users}/${ApiKey.OrganizationPendingUser}`);
      toast({
        message: 'Invitation sent successfully',
        variant: 'success',
      });
      navigate(-1);
    } catch (error) {
      toastServerError(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        onSubmit={handleSubmit(handleOnSubmit)}
        title='Invite to Organization'
        label='create-bot-dialog'
        cancelButton
        maxWidth='sm'
        submittingText='Inviting...'
        mainAction={{
          children: 'Invite',
          type: 'submit',
          isSubmitting: formState.isSubmitting,
          color: formState.isDirty ? 'info' : 'primary',
        }}
        open
      >
        <Stack width={1} gap={2}>
          <FormTextField {...email} />
          <FormSection
            title='Select Permission Role'
            infoIcon={{
              icon: 'info',
              tooltip: (
                <Box>
                  Select the role you want to assign to the user. The role will determine the
                  permissions the user has within the organization. <br /> <br />
                  Administrator: Can view edit and manage projects and users. <br />
                  Editor: Can view, edit and manage projects. <br />
                  Agent: Can view projects and send messages to customers. <br />
                </Box>
              ),
            }}
          >
            <FormSelect options={roleOptions} variant='outlined' nonEmpty {...role} />
          </FormSection>
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default InviteToOrganizationModal;
