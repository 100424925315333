import { Chip, Stack, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { isEmpty, size } from 'lodash';
import { useMemo } from 'react';
import Icon from 'components/Icon';
import { useMenu } from 'hooks';
import LabelForm from 'pages/activity-page/labels/LabelForm';
import { Chat } from 'types';
import { mapLabelsAutocompleteOptions, truncateText } from 'utils';

const LabelCell = ({ row: { labels, id } }: GridRenderCellParams<Chat>) => {
  const { openMenu, ...restMenu } = useMenu();

  const reversedLabels = useMemo(() => labels?.slice().reverse(), [labels]);

  const tooltipText = () => {
    if (isEmpty(reversedLabels)) {
      return 'Click to Add Label';
    }

    if (size(reversedLabels) === 1) {
      return '';
    }

    return reversedLabels?.map((label) => label.name).join(', ');
  };

  return (
    <>
      <Stack
        height={1}
        width={1}
        justifyContent='center'
        sx={{ cursor: 'default' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Tooltip title={tooltipText()} placement='top-start'>
          <Stack
            gap={1}
            direction='row'
            sx={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              openMenu(e);
            }}
          >
            {!isEmpty(reversedLabels) ? (
              <Chip
                sx={{
                  backgroundColor: reversedLabels?.[0].color,
                }}
                label={truncateText({
                  input: reversedLabels?.[0].name || '',
                  length: 8,
                })}
                size='small'
              />
            ) : (
              <Chip icon={<Icon icon='add' />} label='Add Label' size='small' />
            )}

            {size(reversedLabels) > 1 && (
              <Chip label={`+${size(reversedLabels) - 1}`} size='small' />
            )}
          </Stack>
        </Tooltip>
      </Stack>
      {restMenu.isMenuOpen && (
        <LabelForm
          defaultValues={mapLabelsAutocompleteOptions(reversedLabels)}
          menuProps={restMenu}
          chatId={id}
        />
      )}
    </>
  );
};

export default LabelCell;
