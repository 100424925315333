import { StepType } from 'enums';

export type ConversationMessageType =
  | StepType
  | 'validation'
  | 'command'
  | 'userResponse'
  | 'quizAnswer'
  | 'botOwnerManual'
  | 'botOwnerPrivateBulk'
  | 'system';
export enum Sender {
  Bot = 'bot',
  User = 'user',
}

export interface Conversation {
  createdAt: Date;
  type: ConversationMessageType;
  sender: Sender;
  message?: string;
  fileUrl?: string;
}
