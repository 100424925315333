import { array, bool, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  MessageStepFormFields,
  MessageStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/message/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue } from 'utils';

export const messageStepFormSchema: ObjectSchema<MessageStepFormFields> = object({
  [MessageStepFormFieldsNames.NodeId]: string().required(),
  [MessageStepFormFieldsNames.Name]: string().required('Name is required'),
  [MessageStepFormFieldsNames.Input]: editorValidation,
  [MessageStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
  [MessageStepFormFieldsNames.DisablePreviewLink]: bool(),
});

export const defaultMessageStepFormValues: Partial<MessageStepFormFields> = {
  [MessageStepFormFieldsNames.Input]: emptyEditorValue,
  [MessageStepFormFieldsNames.NodeId]: '',
  [MessageStepFormFieldsNames.Labels]: [],
};

export const messageFormFieldsConfig: Record<MessageStepFormFieldsNames, FormFieldProps> = {
  [MessageStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: MessageStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [MessageStepFormFieldsNames.Input]: {
    placeholder: '* Message',
    name: MessageStepFormFieldsNames.Input,
    type: 'text',
  },
  [MessageStepFormFieldsNames.NodeId]: {
    name: MessageStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [MessageStepFormFieldsNames.DisablePreviewLink]: {
    label: 'Disable Preview Link',
    name: MessageStepFormFieldsNames.DisablePreviewLink,
    type: 'checkbox',
  },
  [MessageStepFormFieldsNames.Labels]: labelFormFieldConfig,
};
