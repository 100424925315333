import { Chip, ListItemText, MenuItem } from '@mui/material';
import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormAutoComplete, FormSection } from 'components/index';
import ManageLabelsPopover from 'forms/manage-labels/ManageLabelsPopover';
import { useGetUserLabels, useMenu } from 'hooks';
import { CommonFormFieldsNames } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/types';
import { FormDataWithLabelsProps } from 'pages/app-pages/chatbot-builder-page/nodes/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { filterOptions, mapLabelsAutocompleteOptions, updateSelectedLabels } from 'utils';

interface FormLabelsSelectorProps extends FormFieldProps {
  onLocalDefaultValuesChange: (newDefaultValues: Partial<FormDataWithLabelsProps>) => void;
}

const FormLabelsSelector = ({
  onLocalDefaultValuesChange,
  ...formField
}: FormLabelsSelectorProps) => {
  const { labels: userLabels } = useGetUserLabels();
  const autoCompleteOptions = mapLabelsAutocompleteOptions(userLabels);

  const { openMenu, ...restMenuProps } = useMenu();

  const { setValue, getValues } = useFormContext<FormDataWithLabelsProps>();

  useEffect(() => {
    const selectedLabels = getValues(CommonFormFieldsNames.Labels);
    setValue(CommonFormFieldsNames.Labels, updateSelectedLabels(selectedLabels, userLabels), {
      shouldDirty: false,
    });
    onLocalDefaultValuesChange({ labels: updateSelectedLabels(selectedLabels, userLabels) });
  }, [getValues, onLocalDefaultValuesChange, setValue, userLabels]);

  return (
    <>
      <FormSection
        title='Labels'
        infoIcon={{
          tooltip:
            'Assign personalized labels to your chatbot users at key touchpoints or milestones in their journey. Define labels based on behavior, interests, or any other criteria relevant to your marketing strategy.',
          icon: 'info',
        }}
        cta={{
          icon: 'settings',
          tooltip: 'Manage Labels',
          onClick: openMenu,
        }}
      >
        <FormAutoComplete<AutoCompleteLabel>
          freeSolo
          multiple
          filterSelectedOptions
          filterOptions={filterOptions}
          options={autoCompleteOptions}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object' && typeof value === 'object') {
              return option.value.toLowerCase() === value.value.toLowerCase();
            }
            if (typeof option === 'string' && typeof value === 'string') {
              return option === value;
            }
            return false;
          }}
          renderOption={(props, option) => (
            <MenuItem key={option.value} {...props}>
              <ListItemText disableTypography>
                <Chip
                  size='small'
                  label={option.label}
                  //   icon={option.icon ? <Icon icon={option.icon} fontSize='small' /> : undefined}
                  sx={{ backgroundColor: option.color }}
                />
              </ListItemText>
            </MenuItem>
          )}
          {...formField}
          size='small'
        />
      </FormSection>
      <ManageLabelsPopover menuProps={restMenuProps} />
    </>
  );
};

export default memo(FormLabelsSelector);
