export enum ChatMessageFormFieldsNames {
  Message = 'message',
  FileUrl = 'fileUrl',
}

export interface ChatMessageFormFields {
  [ChatMessageFormFieldsNames.Message]?: string;
  [ChatMessageFormFieldsNames.FileUrl]?: string;
}

export enum FormMode {
  Create = 'create',
  Edit = 'edit',
}
