import { ChatGroupMessageStatus } from 'enums';
import { ChatGroup } from 'types';

export enum GroupMessageFormFieldsNames {
  Type = 'type',
  Message = 'message',
  Photo = 'photo',
  StartDate = 'startDate',
  Project = 'project',
  Groups = 'groups',
}

export enum MessageTypes {
  Message = 'message',
  Photo = 'photo',
}

export interface GroupMessageFormFields {
  [GroupMessageFormFieldsNames.Message]?: string;
  [GroupMessageFormFieldsNames.Photo]?: string;
  [GroupMessageFormFieldsNames.Type]: MessageTypes;
  [GroupMessageFormFieldsNames.StartDate]?: Date | null;
  [GroupMessageFormFieldsNames.Project]: string;
  [GroupMessageFormFieldsNames.Groups]: string[];
}

export enum FormMode {
  Create = 'create',
  Edit = 'edit',
}
export interface GroupMessageFormLocalState {
  projectId: string;
  initialValues?: Partial<GroupMessageFormFields>;
  messageStatus?: ChatGroupMessageStatus;
  chatGroups?: ChatGroup[];
  messageId?: string;
  mode: FormMode;
}

export interface MessageTypesConfig {
  value: MessageTypes;
  label: string;
}
