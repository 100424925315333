import { AutoCompleteLabel } from 'types';

export enum TrueFalseQuestionStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Variable = 'variable',
  Answers = 'answers',
  RemoveAnswerOptions = 'removeAnswerOptions',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface TrueFalseQuestionStepFormFields {
  [TrueFalseQuestionStepFormFieldsNames.Name]: string;
  [TrueFalseQuestionStepFormFieldsNames.Input]: string;
  [TrueFalseQuestionStepFormFieldsNames.Variable]?: string;
  [TrueFalseQuestionStepFormFieldsNames.NodeId]: string;
  [TrueFalseQuestionStepFormFieldsNames.Answers]: TrueFalseQuestionStepFormFieldsOptions[];
  [TrueFalseQuestionStepFormFieldsNames.RemoveAnswerOptions]?: boolean;
  [TrueFalseQuestionStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export enum TrueFalseQuestionStepFormFieldsOptionsNames {
  Name = 'name',
  Option = 'option',
  IsCorrect = 'isCorrect',
  NodeId = 'nodeId',
}

export interface TrueFalseQuestionStepFormFieldsOptions {
  [TrueFalseQuestionStepFormFieldsOptionsNames.Name]: string;
  [TrueFalseQuestionStepFormFieldsOptionsNames.Option]: string;
  [TrueFalseQuestionStepFormFieldsOptionsNames.NodeId]: string;
  [TrueFalseQuestionStepFormFieldsOptionsNames.IsCorrect]: boolean;
}
