// import { map } from 'lodash';
import { array, mixed, number, object, ObjectSchema, string } from 'yup';
import { FormFieldProps } from 'types';
import { findLabelOrValue } from 'utils';
import { builderRankOptions, builderRuleOptions, builderSyncOptions } from './mock';
import { BuilderSettingsFields, BuilderSettingsFormFieldsNames, BuilderType } from './types';

export const builderSettingsSchema: ObjectSchema<BuilderSettingsFields> = object({
  [BuilderSettingsFormFieldsNames.Name]: string().required('Provide Bot Name.'),
  // One of enum values in AutoComplete
  [BuilderSettingsFormFieldsNames.Type]: mixed<BuilderType>()
    .oneOf(Object.values(BuilderType))
    .required('The Type is required.'),
  // One of array values in AutoComplete that is mapped from object
  /*  [BuilderSettingsFormFieldsNames.Rank]: string()
    .oneOf(map(builderRankOptions, 'label'))
    .required('Provide Bot Rank.'), */
  [BuilderSettingsFormFieldsNames.Rank]: string().required('Provide Bot Rank.'),
  [BuilderSettingsFormFieldsNames.Rules]: array<string[]>().defined().min(1).required(),
  [BuilderSettingsFormFieldsNames.MenuOptions]: array<string[]>().defined(),
  [BuilderSettingsFormFieldsNames.Mode]: string().required('Provide Mode.'),
  [BuilderSettingsFormFieldsNames.Active]: mixed<boolean>().oneOf([true, false]).required(),
  [BuilderSettingsFormFieldsNames.Rating]: mixed<number>().oneOf([1, 2, 3, 4, 5]).required(),
  [BuilderSettingsFormFieldsNames.SyncOptions]: string().required('Provide Sync Options.'),
  [BuilderSettingsFormFieldsNames.SelectOptions]: array<string[]>().defined(),
  [BuilderSettingsFormFieldsNames.FlatSelectOptions]: string().required(
    'Provide Flat Select Options.',
  ),
  [BuilderSettingsFormFieldsNames.Slider]: number().min(0).max(100).required(),
  [BuilderSettingsFormFieldsNames.MultiSlider]: array()
    .of(number().min(1, 'Number must be greater than or equal to 1').required())
    .test(
      'second-number-greater',
      'Number must be greater than or equal to 1',
      (value) => !(!value || value[0] === 0),
    )
    .required('Array with two numbers is required'),
});

export const defaultBuilderSettingsFormValues: BuilderSettingsFields = {
  [BuilderSettingsFormFieldsNames.Name]: '',
  [BuilderSettingsFormFieldsNames.Type]: BuilderType.Action,
  // value is mapped from object in AutoComplete
  [BuilderSettingsFormFieldsNames.Rank]: findLabelOrValue(builderRankOptions, {
    value: '66',
  }),
  [BuilderSettingsFormFieldsNames.Rules]: findLabelOrValue(builderRuleOptions, {
    value: ['1', '2', '5'],
  }),
  [BuilderSettingsFormFieldsNames.MenuOptions]: [],
  [BuilderSettingsFormFieldsNames.Mode]: '',
  [BuilderSettingsFormFieldsNames.Active]: false,
  [BuilderSettingsFormFieldsNames.Rating]: 4,
  [BuilderSettingsFormFieldsNames.SyncOptions]: findLabelOrValue(builderSyncOptions, {
    value: '3',
  }),
  [BuilderSettingsFormFieldsNames.SelectOptions]: ['Google'],
  [BuilderSettingsFormFieldsNames.FlatSelectOptions]: 'Google Inline',
  [BuilderSettingsFormFieldsNames.Slider]: 50,
  [BuilderSettingsFormFieldsNames.MultiSlider]: [20, 40],
};

export const builderSettingsFormFieldsConfig: Record<
  BuilderSettingsFormFieldsNames,
  FormFieldProps
> = {
  [BuilderSettingsFormFieldsNames.Name]: {
    label: 'Bot Name',
    placeholder: 'My Awesome Bot',
    name: BuilderSettingsFormFieldsNames.Name,
    type: 'text',
  },
  [BuilderSettingsFormFieldsNames.Type]: {
    label: 'Type',
    placeholder: 'Select Bot Type',
    name: BuilderSettingsFormFieldsNames.Type,
    type: 'text',
  },
  [BuilderSettingsFormFieldsNames.Rank]: {
    label: 'Rank',
    placeholder: 'Select or create Bot Rank',
    name: BuilderSettingsFormFieldsNames.Rank,
    type: 'text',
  },
  [BuilderSettingsFormFieldsNames.Rules]: {
    label: 'Rules',
    placeholder: 'Select Bot Rules',
    name: BuilderSettingsFormFieldsNames.Rules,
    type: 'text',
  },
  [BuilderSettingsFormFieldsNames.MenuOptions]: {
    label: 'Options',
    placeholder: 'Select or Create Bot Menu Options',
    name: BuilderSettingsFormFieldsNames.MenuOptions,
    type: 'text',
  },
  [BuilderSettingsFormFieldsNames.Mode]: {
    label: 'Mode',
    placeholder: 'Select Bot Mode',
    name: BuilderSettingsFormFieldsNames.Mode,
    type: 'radio',
  },
  [BuilderSettingsFormFieldsNames.Active]: {
    label: 'Active',
    placeholder: 'Select Bot Active',
    name: BuilderSettingsFormFieldsNames.Active,
    type: 'checkbox',
  },
  [BuilderSettingsFormFieldsNames.Rating]: {
    label: 'Rating',
    placeholder: 'Select Bot Rating',
    name: BuilderSettingsFormFieldsNames.Rating,
    type: 'number',
  },
  [BuilderSettingsFormFieldsNames.SyncOptions]: {
    label: 'Sync Options',
    placeholder: 'Select Bot Sync Options',
    name: BuilderSettingsFormFieldsNames.SyncOptions,
    type: 'checkbox',
  },
  [BuilderSettingsFormFieldsNames.SelectOptions]: {
    label: 'Select Options',
    placeholder: 'Select Bot Select Options',
    name: BuilderSettingsFormFieldsNames.SelectOptions,
    type: 'select',
  },
  [BuilderSettingsFormFieldsNames.FlatSelectOptions]: {
    label: 'Flat Select Options',
    placeholder: 'Select Bot Flat Select Options',
    name: BuilderSettingsFormFieldsNames.FlatSelectOptions,
    type: 'select',
  },
  [BuilderSettingsFormFieldsNames.Slider]: {
    label: 'Slider',
    placeholder: 'Select Bot Slider',
    name: BuilderSettingsFormFieldsNames.Slider,
    type: 'slider',
  },
  [BuilderSettingsFormFieldsNames.MultiSlider]: {
    label: 'Multi Slider',
    placeholder: 'Select Bot Multi Slider',
    name: BuilderSettingsFormFieldsNames.MultiSlider,
    type: 'slider',
  },
};
