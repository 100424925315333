import { Icon as MuiIcon, IconProps as MuiIconProps } from '@mui/material';
import { forwardRef } from 'react';
import { isBasicColorValid } from 'theme/utils';

export interface IconProps extends Omit<MuiIconProps, 'children' | 'color'> {
  icon?: string;
  color?: string;
}

const Icon = forwardRef<HTMLDivElement, IconProps>(
  ({ icon, sx, color = 'inherit', ...rest }, ref) =>
    icon ? (
      <MuiIcon
        ref={ref}
        baseClassName='material-symbols-rounded'
        sx={{
          color: (theme) => (isBasicColorValid(color) ? theme.palette[color].main : color),
          ...sx,
        }}
        {...rest}
      >
        {icon}
      </MuiIcon>
    ) : null,
);

export default Icon;
