import { GridSlotsComponent } from '@mui/x-data-grid';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import CustomColumnMenu from 'components/data-grid/CustomColumnMenu';
import NoDataOverlay, { NoDataOverlayProps } from 'components/NoDataOverlay';
import TableSkeleton from 'components/TableSkeleton';

interface CustomDataGridSlotsProps {
  noRowsOverlayProps?: NoDataOverlayProps;
}

const CustomDataGridSlots = ({
  noRowsOverlayProps,
}: CustomDataGridSlotsProps): UncapitalizeObjectKeys<Partial<GridSlotsComponent>> => ({
  columnMenu: CustomColumnMenu,
  noRowsOverlay: () => <NoDataOverlay {...noRowsOverlayProps} />,
  loadingOverlay: TableSkeleton,
});

export default CustomDataGridSlots;
