import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  HumanHandoffStepFormFields,
  HumanHandoffStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/human-handoff/types';

import { AutoCompleteLabel, FormFieldProps, UserDisplay } from 'types';
import { editorValidation, emptyEditorValue } from 'utils';

export const humanHandoffStepFormSchema: ObjectSchema<HumanHandoffStepFormFields> = object({
  [HumanHandoffStepFormFieldsNames.Name]: string().required('Name is required'),
  [HumanHandoffStepFormFieldsNames.Input]: editorValidation,
  [HumanHandoffStepFormFieldsNames.NotificationMessage]: editorValidation,
  [HumanHandoffStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
  [HumanHandoffStepFormFieldsNames.NotifyList]: array<UserDisplay[]>().optional(),
});

export const humanHandoffStepStepFormValues: Partial<HumanHandoffStepFormFields> = {
  [HumanHandoffStepFormFieldsNames.Input]: emptyEditorValue,
  [HumanHandoffStepFormFieldsNames.NotificationMessage]: `<p><span class="mention" data-index="0" data-denotation-char="@" data-id="firstName" data-value="First Name"><span contenteditable="false">@First Name</span></span>, has requested to be contacted.</p>`,
  [HumanHandoffStepFormFieldsNames.Labels]: [
    {
      label: 'Human Handoff',
      color: '#FDF6C1',
      icon: 'support_agent',
      value:
        process.env.REACT_APP_ENV === 'production'
          ? '6609a61a9a48f147ca0f14ad'
          : '6609a59c9a48f147ca0f14ac',
    },
  ],
};

export const humanHandoffFormFieldsConfig: Record<HumanHandoffStepFormFieldsNames, FormFieldProps> =
  {
    [HumanHandoffStepFormFieldsNames.Name]: {
      label: 'Name',
      placeholder: 'Name',
      name: HumanHandoffStepFormFieldsNames.Name,
      autoComplete: 'off',
      type: 'text',
    },
    [HumanHandoffStepFormFieldsNames.Input]: {
      label: 'Message',
      placeholder:
        '* Message that will be sent to the user. Example: "A human representative will contact you soon."',
      name: HumanHandoffStepFormFieldsNames.Input,
      type: 'text',
    },
    [HumanHandoffStepFormFieldsNames.NotificationMessage]: {
      label: 'Notification Message',
      placeholder:
        'Notification message for me via Telegram. Example: "@First Name, has requested to be contacted."',
      name: HumanHandoffStepFormFieldsNames.NotificationMessage,
      type: 'text',
    },
    [HumanHandoffStepFormFieldsNames.NotifyList]: {
      placeholder: 'Select users to notify',
      name: HumanHandoffStepFormFieldsNames.NotifyList,
      type: 'text',
    },
    [HumanHandoffStepFormFieldsNames.Labels]: labelFormFieldConfig,
  };
