import { Box, Button, CircularProgress, Stack, SxProps, Theme, Tooltip } from '@mui/material';
import { MouseEvent } from 'react';
import { TooltipPlacement } from 'theme';
import Icon from './Icon';

type GroupButtonItemProps = {
  label?: string;
  tooltip?: string;
  onClick?: ((e: MouseEvent<HTMLButtonElement>) => void) | (() => void);
  icon?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  iconColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit'
    | string;
  tooltipPlacement?: TooltipPlacement;
  disabled?: boolean;
  loading?: boolean;
  iconSx?: SxProps<Theme>;
};
function GroupButtonItem({
  label,
  onClick,
  tooltip = '',
  tooltipPlacement = 'bottom',
  icon,
  color,
  iconSx,
  iconColor,
  disabled,
  loading,
}: GroupButtonItemProps) {
  return (
    <Button
      disabled={disabled}
      color={color}
      onClick={(e) => {
        onClick?.(e);
      }}
      sx={{ p: 0, lineHeight: 1 }}
    >
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        <Stack
          position='relative'
          direction='row'
          justifyContent='center'
          gap={1}
          width={1}
          height={1}
          alignItems='center'
          py={0.75}
          px={1.5}
        >
          {loading && (
            <Box
              position='absolute'
              top={0}
              bottom={0}
              left={0}
              right={0}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress color='inherit' size='1rem' />
            </Box>
          )}
          {label && (
            <Box px={0.5} visibility={loading ? 'hidden' : 'visible'}>
              {label}
            </Box>
          )}
          <Icon
            icon={icon}
            color={iconColor}
            fontSize='small'
            sx={{
              visibility: loading ? 'hidden' : 'visible',
              ...iconSx,
            }}
          />
        </Stack>
      </Tooltip>
    </Button>
  );
}

export default GroupButtonItem;
