import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AppLogo } from '@common-components';
import { RoutePath } from 'enums';
import { AuthDialogWrapper } from 'pages/auth-pages/components';
import ForgotPasswordForm from 'pages/auth-pages/forgot-password/ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <AuthDialogWrapper areaLabel='sign-up-form'>
    <Box mb={2}>
      <AppLogo height={42} />
    </Box>
    <Stack mb={2}>
      <Typography align='center' variant='h4' component='h1'>
        Forgot password?
      </Typography>
      <Typography align='center' variant='body1'>
        Enter your email address to reset your password.
      </Typography>
    </Stack>
    <ForgotPasswordForm />
    <Typography align='center' variant='subtitle2'>
      Back to{' '}
      <Link component={RouterLink} variant='subtitle2' to={`/${RoutePath.Login}`} underline='none'>
        Login
      </Link>
    </Typography>
  </AuthDialogWrapper>
);

export default ForgotPasswordPage;
