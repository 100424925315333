import { AutoCompleteLabel } from 'types';

export enum OpenEndQuestionFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Answer = 'answer',
  NodeId = 'nodeId',
  Variable = 'variable',
  Labels = 'labels',
}

export interface OpenEndQuestionFormFields {
  [OpenEndQuestionFormFieldsNames.NodeId]: string;
  [OpenEndQuestionFormFieldsNames.Name]: string;
  [OpenEndQuestionFormFieldsNames.Input]: string;
  [OpenEndQuestionFormFieldsNames.Answer]: string;
  [OpenEndQuestionFormFieldsNames.Variable]?: string;
  [OpenEndQuestionFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
