export enum UserLabelFieldsNames {
  Name = 'name',
  Color = 'color',
  IsDisabled = 'isDisabled',
}
export interface UserLabelFormFields {
  [UserLabelFieldsNames.Name]: string;
  [UserLabelFieldsNames.Color]?: string;
  [UserLabelFieldsNames.IsDisabled]?: boolean;
}
