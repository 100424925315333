import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary as RollbarErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Rollbar from 'rollbar';
import { SWRConfig } from 'swr';
import { fetcher } from 'api';
import CustomSnackbar from 'components/CustomSnackbar';
import { Layout } from 'layout';
import { theme } from 'theme';
import App from './App';
import { LocalStorageKey } from './config';
import { writeToLocalStorage } from './utils';

const rollbarConfig: Rollbar.Configuration | (() => Rollbar.Configuration) = {
  accessToken: 'cedc7e5ec47b4f45a1c60148bf3a86be',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.REACT_APP_ENV === 'production',
};

function saveCurrentUrlToLocalStorage() {
  const currentUrl = window.location.pathname + window.location.search + window.location.hash;

  writeToLocalStorage(LocalStorageKey.RedirectUrl, currentUrl); // Save the current URL to local storage
}

saveCurrentUrlToLocalStorage(); // Call the function to save the URL

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <RollbarProvider config={rollbarConfig}>
    <RollbarErrorBoundary>
      <GoogleOAuthProvider clientId='26377680274-rbg20te8rf8h7bmg0l0u3kp2d65v0mae.apps.googleusercontent.com'>
        <SWRConfig
          value={{
            fetcher,
            // suspense: true,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Layout>
                <SnackbarProvider
                  Components={{
                    success: CustomSnackbar,
                    info: CustomSnackbar,
                    warning: CustomSnackbar,
                    error: CustomSnackbar,
                    default: CustomSnackbar,
                  }}
                  maxSnack={3}
                >
                  <App />
                </SnackbarProvider>
              </Layout>
            </ThemeProvider>
          </LocalizationProvider>
        </SWRConfig>
      </GoogleOAuthProvider>
    </RollbarErrorBoundary>
  </RollbarProvider>,
);
