const messages = {
  buttons: {
    submit: 'Submit',
    cancel: 'Cancel',
    back: 'Back',
    next: 'Next',
    save: 'Save',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    close: 'Close',
    open: 'Open',
    send: 'Send',
    accept: 'Accept',
    reject: 'Reject',
    approve: 'Approve',
    decline: 'Decline',
    confirm: 'Confirm',
    discard: 'Discard',
  },
  general: {
    loading: 'Loading...',
    error: 'Error',
    success: 'Success',
    saving: 'Saving...',
    saved: 'Saved',
    changesSaved: 'Changes saved',
    customerSupportText: 'Teleform Support Bot, your virtual assistant within Teleform',
  },
  builder: {
    backButton: 'Show Back Button in Menu',
    removeAnswerOptions: 'Remove Options after Selection',
    removeAnswerOptionsTooltip:
      'By Checking this, the answer options will be removed from the chatbot after the user answers the question.',
    confirmLoseNodeChanges:
      'Are you sure you want to close the form? All unsaved changes will be lost.',
    toast: {
      publishSuccess: 'Workflow published successfully',
      publishError: 'Workflow publish failed',
      pausedSuccess: 'App paused successfully',
      playSuccess: 'App activated successfully',
    },
  },
};

export default messages;
