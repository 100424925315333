import { useCallback, useMemo } from 'react';
import { ChatGroupMessageStatus } from 'enums';
import { ChatGroupMessage } from 'types';

const useMessageStatus = (message?: ChatGroupMessage) => {
  const isScheduleMessage = useMemo(() => !!message?.startDate, [message]);

  const isMessageSent = useMemo(() => !!message?.sendDate, [message]);

  const isExpiredMessage = useMemo(
    () =>
      message?.sendAttempt &&
      !message?.sendDate &&
      message?.startDate &&
      new Date(message?.startDate) < new Date(),
    [message],
  );

  const getIsExpiredMessage = useCallback(
    (localMessage?: ChatGroupMessage) =>
      localMessage?.sendAttempt &&
      !localMessage?.sendDate &&
      localMessage?.startDate &&
      new Date(localMessage?.startDate) < new Date(),
    [],
  );

  const isFutureMessage = useMemo(
    () => !!message?.startDate && new Date(message?.startDate) > new Date(),
    [message?.startDate],
  );

  const isDraftMessage = useCallback(
    () => message?.status === ChatGroupMessageStatus.Draft,
    [message?.status],
  );

  return {
    isScheduleMessage,
    isMessageSent,
    isExpiredMessage,
    isFutureMessage,
    isDraftMessage: isDraftMessage(),
    getIsExpiredMessage,
  };
};

export default useMessageStatus;
