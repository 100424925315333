import { get } from 'lodash';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { cache, SWRResponse } from 'swr/_internal';
import { ApiKey } from 'api';
import { FormAutocomplete } from 'components/form-fields/FormAutoComplete';
import { Project } from 'types';

interface FormWithCommandAutoCompleteProps {
  name: string;
  parentName: string;
}
const FormWithCommandAutoComplete = ({
  name,
  parentName,
  ...rest
}: FormWithCommandAutoCompleteProps) => {
  const [open, setOpen] = useState(false);
  const [commandsList, setCommandsList] = useState<string[]>([]);
  const { formState, watch, setValue } = useFormContext();
  const { id } = useParams();
  const allOptions = watch(parentName); // Watch the entire options array

  const { data: project } = cache.get(`${ApiKey.Project}/${id}`) as SWRResponse<Project>;

  useEffect(() => {
    if (project?.bot?.draft.commands) {
      const allCommands = Object.values(project.bot.draft.commands).map(
        (command) => `/${command.name}`,
      );
      // Filter out commands that are already selected in any of the repeater items
      const availableCommands = allCommands?.filter(
        (cmd) => !allOptions?.some((optionItem: { option: any }) => cmd === optionItem.option),
      );
      setCommandsList(availableCommands);
    }
  }, [project, allOptions]); // Make sure to include allOptions in the dependency array

  const [inputRefValue, setInputRefValue] = useState<string>(
    get(formState.defaultValues, name, ''),
  );

  return (
    <FormAutocomplete
      open={open}
      freeSolo={!inputRefValue.startsWith('/')}
      options={commandsList || []}
      name={name}
      autoSelect
      onInputChangeCallback={(value) => {
        setInputRefValue(value);
        if (!value.startsWith('/')) {
          setOpen(false);
          setValue(name, value);
        } else {
          setOpen(true);
        }
      }}
      onChangeCallback={() => {
        setOpen(false);
      }}
      size='small'
      filterOptions={(options, { inputValue }) => {
        // Custom filter logic here
        if (inputValue.startsWith('/')) {
          const filtered = options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase()),
          );
          setOpen(filtered.length > 0); // Open dropdown if there are matches
          return filtered;
        }
        setOpen(false); // Close dropdown
        return [];
      }}
      textFieldProps={{
        InputProps: {
          endAdornment: null,
        },
      }}
      {...rest}
    />
  );
};

export default FormWithCommandAutoComplete;
