import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { alpha, Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useSWR, { mutate } from 'swr';
import { FloatingActionButton } from '@common-components';
import { api, ApiKey } from 'api';
import { RoutePath } from 'enums';
import { useServerError, useToast } from 'hooks';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import CommandItem from 'pages/app-pages/chatbot-builder-page/toolbox/tabs/commands-manager/CommandItem';
import ToolboxLayout from 'pages/app-pages/chatbot-builder-page/toolbox/ToolboxLayout';
import { Command, Project } from 'types';

const CommandsManager = () => {
  const { selectedCommand, setSelectedCommand, setSelectedStepType, closeToolbox } =
    useBuilderStore((state) => ({
      selectedCommand: state.selectedCommand,
      setSelectedCommand: state.setSelectedCommand,
      closeToolbox: state.closeToolbox,
      setSelectedStepType: state.setSelectedStepType,
    }));
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: project, isLoading } = useSWR<Project>(`${ApiKey.Project}/${id}`);
  const [commandsList, setCommandsList] = useState<Command[]>([]);

  const toast = useToast();
  const toastError = useServerError();

  useEffect(() => {
    if (project?.bot?.draft.commands) {
      setCommandsList(Object.values(project.bot.draft.commands));
    }
  }, [project]);
  if (isLoading) {
    return null;
  }

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || destination.index === 0) {
      toast({
        message: 'Start command must be in the first position',
        variant: 'error',
      });
      return;
    }

    // Step 1: Store a copy of the original list before any changes
    const originalList = Array.from(commandsList);

    // Proceed to reorder based on the drag-and-drop action
    const items = Array.from(commandsList);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    // Optimistically update the UI
    setCommandsList(items);

    try {
      // Step 2: Attempt to update the backend with the new order
      await api.patch<Command>(
        `${ApiKey.Project}/${id}/${ApiKey.Bot}/${ApiKey.Command}/${ApiKey.Order}`,
        { order: items.map((command) => command.name) },
      );

      // Success: you might want to show a confirmation message or perform other actions here
      await mutate(`${ApiKey.Project}/${id}`);
    } catch (error) {
      // Step 3: On failure, revert to the original commands list
      setCommandsList(originalList);
      toastError(error);
    }
  };

  return (
    <ToolboxLayout
      title='Commands Manager'
      subtitle='Manage your bot commands'
      additionalActions={[
        {
          icon: 'info',
          onClick: () => {
            window.open(`https://core.telegram.org/bots/features#commands`, '_blank');
          },
        },
        {
          icon: 'add_circle',
          color: 'info',
          onClick: () => {
            navigate(`/${RoutePath.ChatbotBuilder}/${id}/${RoutePath.Command}`, { replace: true });
          },
        },
      ]}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='commands'>
          {(droppableProvided, wrapperSnapshot) => (
            <Stack
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              gap={1}
              mt={2}
              mx={1}
              px={1}
              pt={wrapperSnapshot.isDraggingOver ? 1 : 0}
              bgcolor={(theme) =>
                wrapperSnapshot.isDraggingOver
                  ? alpha(theme.palette.primary.main, 0.12)
                  : 'transparent'
              }
              borderRadius={1}
              border={1}
              borderColor={wrapperSnapshot.isDraggingOver ? 'primary.main' : 'transparent'}
              sx={{
                borderStyle: 'dashed',
              }}
            >
              {commandsList.map((command, index) => (
                <Draggable
                  isDragDisabled={index === 0}
                  key={command.id}
                  draggableId={command.id}
                  index={index}
                >
                  {(draggableProvided, snapshot) => (
                    <Box
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      onTouchStart={(event) => {
                        // @ts-ignore
                        event.nativeEvent.defaultMuiPrevented = true;
                      }}
                    >
                      <CommandItem
                        dragHandleProps={draggableProvided.dragHandleProps}
                        dragSnapshot={snapshot}
                        key={command.id}
                        description={command.description}
                        name={command.name}
                        disableDrag={command.name === 'start'}
                        icon={command.icon}
                        selected={selectedCommand?.name === command.name}
                        active={command.isMenuItem}
                        inMenu={command.isMenuItem}
                        onClick={() => {
                          if (selectedCommand?.name !== command.name) {
                            closeToolbox();
                            setSelectedStepType(null);
                            setTimeout(() => {
                              setSelectedCommand(command);
                            }, 200);
                          }
                        }}
                        onEdit={() => {
                          navigate(`/${RoutePath.ChatbotBuilder}/${id}/${RoutePath.Command}`, {
                            state: {
                              command,
                            },
                            replace: true,
                          });
                        }}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <FloatingActionButton
        label='Add command'
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        onClick={() => {
          navigate(`/${RoutePath.ChatbotBuilder}/${id}/${RoutePath.Command}`, { replace: true });
        }}
      />
    </ToolboxLayout>
  );
};

export default CommandsManager;
