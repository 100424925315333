import { mutate } from 'swr';
import { object, ObjectSchema, string } from 'yup';
import { api, ApiKey } from 'api';
import { useAuth, useToast } from 'hooks';
import { FormFieldProps, User } from 'types';
import { ProfileFormFields, ProfileFormFieldsNames } from './types';

export const profileSchema: ObjectSchema<ProfileFormFields> = object({
  [ProfileFormFieldsNames.FullName]: string().required('Full Name is required'),
});

export const defaultProfileFormValues: ProfileFormFields = {
  [ProfileFormFieldsNames.FullName]: '',
};
export const profileFormFieldsConfig: Record<ProfileFormFieldsNames, FormFieldProps> = {
  [ProfileFormFieldsNames.FullName]: {
    label: '* Full Name',
    placeholder: 'Full Name',
    name: ProfileFormFieldsNames.FullName,
    autoComplete: 'off',
    type: 'text',
  },
};

export function useApplyPaypalSubscription() {
  const { user, setUser } = useAuth();

  const toast = useToast();

  return async (subscriptionId: string) => {
    const res = await api.post<User>(`${ApiKey.Organization}/paypal-subscription`, {
      subscriptionId,
    });
    await mutate(ApiKey.User);

    await mutate(`${ApiKey.Users}/${user?.id}`);

    setUser?.(res.data);

    toast({
      message: 'Great! Your subscription has been updated. Enjoy your new features!',
      variant: 'success',
    });
  };
}
