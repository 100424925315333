import { Stack } from '@mui/material';

import ToolboxHeader from 'components/ToolboxHeader';

import { useBulkDrawer } from 'pages/app-pages/private-bulk-message-page/store/useBulkDrawer';

const ChatRecipientsDrawer = () => {
  const { closeDrawer } = useBulkDrawer((state) => ({
    closeDrawer: state.closeDrawer,
  }));

  return (
    <Stack height={1}>
      <Stack
        alignItems='flex-start'
        position='sticky'
        top={0}
        bgcolor='background.default'
        width={1}
      >
        <ToolboxHeader title='Groups Manager' subtitle='Connected Groups' onClose={closeDrawer} />
      </Stack>
      <Stack flex={1} overflow='auto'>
        fsdfsdfdsf fdsfsdfsdf
      </Stack>
    </Stack>
  );
};

export default ChatRecipientsDrawer;
