import { alpha, Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { formatDistanceToNowStrict } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import Button from 'components/Button';
import { Icon, Label } from 'components/index';
import { SingleTextFieldEditorFrom } from 'forms';
import { appConfig } from 'forms/create-project/utils';
import { useServerError, useToast } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import AppDropdownWrapper from 'pages/project-manager-page/app-card/AppDropdownWrapper';
import {
  AppUpdateDescriptionFieldsNames,
  appUpdateSchema,
} from 'pages/project-manager-page/app-card/utils';
import { App, AppCreateProps, Project } from 'types';
import { truncateMultiLineText } from 'utils';

interface SelectCardProps extends AppCreateProps {
  project: Project;
  connected: boolean;
  updateAt?: Date;
  loading?: boolean;
}
const AppCard = ({ project, type, loading, isActive, connected, updateAt }: SelectCardProps) => {
  const navigate = useNavigate();
  const appProps = appConfig[type];

  const { label, icon, color, description, cta, appUrl } = appProps;
  const { id } = useParams();
  const { value: connecting, setTrue: startConnecting, setFalse: stopConnecting } = useBoolean();
  const toastServerError = useServerError();
  const toast = useToast();

  const appDescription = project.apps?.find((app) => app.type === type)?.description;

  const onConnect = async () => {
    startConnecting();
    try {
      await api.post<Project, AxiosResponse<Project>, Partial<App>>(
        `${ApiKey.Project}/${id}/${ApiKey.App}`,
        {
          type,
        },
      );
      await mutate(`${ApiKey.Project}/${id}`);
      stopConnecting();
      toast({
        message: `${label} connected successfully`,
        variant: 'success',
      });
    } catch (error) {
      toastServerError(error);
      stopConnecting();
    }
  };

  const onEdit = async (updateProps: Partial<App>) => {
    try {
      await api.patch<Project, AxiosResponse<Project>, Partial<App>>(
        `${ApiKey.Project}/${id}/${ApiKey.App}/${type}`,
        updateProps,
      );
      await mutate(`${ApiKey.Project}/${id}`);
      toast({
        message: `Description updated successfully`,
        variant: 'success',
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  return (
    <Stack
      p={2}
      bgcolor='background.default'
      height={1}
      border={1}
      borderRadius={1}
      borderColor='divider'
      alignItems='flex-start'
      gap={2}
    >
      <Stack
        mb={1}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        width={1}
      >
        <Stack direction='row' alignItems='center' gap={2} overflow='hidden'>
          <Stack
            borderRadius={1}
            p={1}
            bgcolor={(theme) => alpha(theme.palette[color].light, 0.18)}
          >
            <Icon icon={icon} fontSize='small' color={color} />
          </Stack>
          <Stack>
            <Stack direction='row' alignItems='center' gap={1} width={1} overflow='hidden'>
              <Typography variant='subtitle1' title={label} sx={{ ...truncateMultiLineText(1) }}>
                {label}
              </Typography>
              {isActive && <Label color='success'>active</Label>}
            </Stack>

            <Typography color='text.secondary' noWrap variant='caption' component='div'>
              {connected
                ? `modified ${formatDistanceToNowStrict(new Date(updateAt!), { addSuffix: true })}`
                : 'Not connected'}
            </Typography>
          </Stack>
        </Stack>
        {connected && <AppDropdownWrapper project={project} appType={type} icon='more_horiz' />}
      </Stack>
      <Stack flex={1} width={1} justifyContent='flex-start'>
        {connected ? (
          <SingleTextFieldEditorFrom
            fieldProps={{
              name: AppUpdateDescriptionFieldsNames.Description,
              placeholder: 'App Description',
              autoFocus: true,
              textVariant: 'body2',
              multiline: true,
              fullWidth: true,
            }}
            defaultValues={{ description: appDescription }}
            emptyStateText='No description provided'
            onSubmit={onEdit}
            schema={appUpdateSchema}
          />
        ) : (
          <Typography title={description} variant='body2' color='text.secondary'>
            {description}
          </Typography>
        )}
      </Stack>
      <Stack alignContent='flex-end' overflow='hidden' alignItems='flex-end' width={1}>
        {connected ? (
          <Button
            size='small'
            variant='outlined'
            color='primary'
            endIcon={<Icon icon='arrow_forward' />}
            onClick={() => {
              navigate(`/${appUrl}/${project.id}`);
            }}
          >
            {cta}
          </Button>
        ) : (
          <SubmitButton
            size='small'
            submittingText='Connecting App...'
            submitting={connecting}
            endIcon={<Icon icon='power' fontSize='small' />}
            onClick={onConnect}
            variant='contained'
            color='inherit'
            disabled={loading}
            fullWidth
          >
            Connect App
          </SubmitButton>
        )}
      </Stack>
    </Stack>
  );
};

export default AppCard;
