import { Stack } from '@mui/material';

import { Breadcrumbs } from '@common-components';
import { Breadcrumb } from 'components/Breadcrumbs';
import { useAppBarHeight } from 'hooks';
import { mainBackground } from 'theme/utils';

export interface HeaderWithBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const HeaderWithBreadcrumbs = ({ breadcrumbs }: HeaderWithBreadcrumbsProps) => {
  const appBarHeight = useAppBarHeight();

  return (
    <Stack
      position='sticky'
      top={appBarHeight}
      justifyContent='flex-end'
      bgcolor={mainBackground}
      zIndex={101}
      pt={{ xs: 4, sm: 7 }}
      pb={4}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Stack>
  );
};

export default HeaderWithBreadcrumbs;
