import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  SendTelegramNotificationStepFormFields,
  SendTelegramNotificationStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-telegram-notification/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue } from 'utils';

export const telegramNotificationStepFormSchema: ObjectSchema<SendTelegramNotificationStepFormFields> =
  object({
    [SendTelegramNotificationStepFormFieldsNames.Name]: string().required('Name is required'),
    [SendTelegramNotificationStepFormFieldsNames.Input]: editorValidation,
    [SendTelegramNotificationStepFormFieldsNames.NodeId]: string().required(),
    [SendTelegramNotificationStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
  });

export const defaultTelegramNotificationStepStepFormValues: Partial<SendTelegramNotificationStepFormFields> =
  {
    [SendTelegramNotificationStepFormFieldsNames.Input]: emptyEditorValue,
    [SendTelegramNotificationStepFormFieldsNames.NodeId]: '',
    [SendTelegramNotificationStepFormFieldsNames.Labels]: [],
  };

export const telegramNotificationFormFieldsConfig: Record<
  SendTelegramNotificationStepFormFieldsNames,
  FormFieldProps
> = {
  [SendTelegramNotificationStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: SendTelegramNotificationStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [SendTelegramNotificationStepFormFieldsNames.Input]: {
    placeholder: '* Format your message',
    name: SendTelegramNotificationStepFormFieldsNames.Input,
    type: 'text',
  },
  [SendTelegramNotificationStepFormFieldsNames.NodeId]: {
    name: SendTelegramNotificationStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [SendTelegramNotificationStepFormFieldsNames.Labels]: labelFormFieldConfig,
};
