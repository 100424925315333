import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { isFuture } from 'date-fns';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { Icon } from '@common-components';
import { api, ApiKey } from 'api';
import ConfirmationDialog from 'components/modals/ConfirmationDialog';
import { RoutePath } from 'enums';
import {
  OptionsMenuState,
  useGetPrivateChatBulkMessages,
  useGetUserLabels,
  useMenu,
  useServerError,
  useToast,
} from 'hooks';
import {
  FormMode,
  PrivateBulkMessageFormLocalState,
} from 'pages/app-pages/private-bulk-message-page/message-modal/types';
import useMessageStatus from 'pages/app-pages/private-bulk-message-page/useMessageStatus';
import { menuMinWidth } from 'theme/utils';
import { PrivateChatBulkMessage } from 'types';
import { mapLabelsAutocompleteOptions } from 'utils';

interface BotItemMenuProps extends OptionsMenuState {
  id: string;
}
function MessageDropdownMenu({ id: messageId, closeMenu, anchorEl, isMenuOpen }: BotItemMenuProps) {
  const navigate = useNavigate();
  const { id } = useParams();

  const { openMenu: openDeletePrompt, ...restDeletePrompt } = useMenu();

  const toastServerError = useServerError();
  const toast = useToast();
  const { chatGroupMessages } = useGetPrivateChatBulkMessages({ projectId: id || '' });

  const { labels: userLabels } = useGetUserLabels();

  const message = chatGroupMessages.find((item) => item.id === messageId);

  const { isDraftMessage, isMessageHasError, isScheduleMessage } = useMessageStatus(message);

  const {
    value: publishing,
    setTrue: startPublishing,
    setFalse: stopPublishing,
  } = useBoolean(false);

  const deleteMessage = async () => {
    try {
      await api.delete(`${ApiKey.PrivateChatBulkMessage}/${messageId}`);
      await mutate(`${ApiKey.PrivateChatBulkMessage}?projectId=${id}`);
      toast({
        message: `Message deleted successfully`,
        variant: 'info',
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  const publishMessage = async () => {
    startPublishing();
    try {
      let publishResponse;

      if (isDraftMessage) {
        await api.post<PrivateChatBulkMessage>(`${ApiKey.PrivateChatBulkMessage}/publish`, {
          projectId: id,
          id: messageId,
        });
      } else {
        publishResponse = await api.patch<PrivateChatBulkMessage>(
          `${ApiKey.PrivateChatBulkMessage}/${messageId}`,
          {
            startDate: null,
          },
        );
      }

      await mutate(`${ApiKey.Project}/${id}`);
      await mutate(
        `${ApiKey.PrivateChatBulkMessage}?projectId=${id}`,
        async (currentGlobalData) => JSON.parse(JSON.stringify(currentGlobalData)),
        {
          revalidate: true,
        },
      );
      toast({
        message: publishResponse?.data.error || 'Message published successfully',
        variant: publishResponse?.data.error ? 'error' : 'info',
      });
      stopPublishing();
    } catch (error) {
      toastServerError(error);
      stopPublishing();
    }
  };

  return (
    <>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        {((message && isScheduleMessage && isFuture(new Date(message?.startDate || ''))) ||
          (message && isDraftMessage)) && (
          <MenuItem
            onClick={() => {
              closeMenu();

              navigate(RoutePath.Composer, {
                state: {
                  projectId: id,
                  mode: FormMode.Edit,
                  messageStatus: message.status,
                  messageId: message.id,
                  initialValues: {
                    project: id,
                    message: message?.message,
                    linkText: message?.linkText,
                    linkUrl: message?.linkUrl,
                    excludeArchived: message?.excludeArchived,
                    fileUrl: message?.fileUrl,
                    ...(isScheduleMessage && { startDate: new Date(message?.startDate || '') }),
                    chatFilter: !isEmpty(message?.chatFilter)
                      ? message?.chatFilter?.map(({ direction, labelIds, matching }) => {
                          const labels = userLabels.filter((label) => labelIds.includes(label.id));
                          return {
                            direction,
                            labels: labels ? mapLabelsAutocompleteOptions(labels) : [],
                            matching,
                          };
                        })
                      : undefined,
                  },
                } as PrivateBulkMessageFormLocalState,
              });
            }}
          >
            <ListItemIcon>
              <Icon icon='edit_note' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Edit Message</ListItemText>
          </MenuItem>
        )}

        {!isMessageHasError && (
          <MenuItem
            onClick={async () => {
              await publishMessage();
              closeMenu();
            }}
          >
            <ListItemIcon>
              {publishing ? (
                <CircularProgress color='inherit' size='1rem' />
              ) : (
                <Icon icon='send' fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>{publishing ? 'Sending...' : 'Send Now'}</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={async () => {
            navigate(RoutePath.Composer, {
              replace: true,
              state: {
                projectId: id,
                mode: FormMode.Create,
                messageStatus: message?.status,
                initialValues: {
                  project: id,
                  fileUrl: message?.fileUrl,
                  message: message?.message,
                  linkText: message?.linkText,
                  linkUrl: message?.linkUrl,
                },
              } as PrivateBulkMessageFormLocalState,
            });
            closeMenu();
          }}
        >
          <ListItemIcon>
            <Icon icon='content_copy' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={openDeletePrompt}>
          <ListItemIcon>
            <Icon icon='delete' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        {...restDeletePrompt}
        text='Are you sure you want to delete this message?'
        submittingText='Deleting...'
        onConfirm={async () => {
          await deleteMessage();
          closeMenu();
        }}
      />
    </>
  );
}

export default MessageDropdownMenu;
