import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import {
  RequestEmailFormFields,
  RequestEmailFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-email/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue } from 'utils';

export const requestEmailStepFormSchema: ObjectSchema<RequestEmailFormFields> = object({
  [RequestEmailFormFieldsNames.NodeId]: string().required(),
  [RequestEmailFormFieldsNames.Name]: string().required('Name is required'),
  [RequestEmailFormFieldsNames.Input]: editorValidation,
  [RequestEmailFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultRequestEmailFormValues: Partial<RequestEmailFormFields> = {
  [RequestEmailFormFieldsNames.Input]: emptyEditorValue,
  [RequestEmailFormFieldsNames.NodeId]: '',
  [RequestEmailFormFieldsNames.Labels]: [
    {
      label: 'Email Shared',
      color: '#DBEEDB',
      icon: 'mail',
      value:
        process.env.REACT_APP_ENV === 'production'
          ? '6609a37a42362ebd0e619bf7'
          : '660200c1caa2c91dc92aaf44',
    },
  ],
};

export const requestEmailFieldsConfig: Record<RequestEmailFormFieldsNames, FormFieldProps> = {
  [RequestEmailFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: RequestEmailFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [RequestEmailFormFieldsNames.Input]: {
    placeholder: '* Message',
    name: RequestEmailFormFieldsNames.Input,
    type: 'text',
  },

  [RequestEmailFormFieldsNames.NodeId]: {
    name: RequestEmailFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [RequestEmailFormFieldsNames.Labels]: labelFormFieldConfig,
};
