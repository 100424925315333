import { Stack } from '@mui/material';
import { memo } from 'react';
import { FitView, Panel, ZoomInOut } from 'reactflow';
import { useIsMobile } from 'hooks';
import BuilderPanelButtonItem from 'pages/app-pages/chatbot-builder-page/builder-panels/BuilderPanelButtonItem';
import BuilderButtonsGroup from 'pages/app-pages/chatbot-builder-page/BuilderButtonsGroup';
import { useBuilderStore, useLayoutDirection } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabs } from 'pages/app-pages/chatbot-builder-page/toolbox/types';

export type BuilderPanelsProps = {
  resetView: () => void;
  fitView: FitView;
  zoomIn: ZoomInOut;
  zoomOut: ZoomInOut;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
};
const BuilderPanels = ({
  resetView,
  fitView,
  zoomOut,
  zoomIn,
  undo,
  // redo,
  // canRedo,
  canUndo,
}: BuilderPanelsProps) => {
  const { openToolbox, setActiveToolboxTab } = useBuilderStore((state) => ({
    openToolbox: state.openToolbox,
    setActiveToolboxTab: state.setActiveToolboxTab,
  }));

  const { toggleLayoutDirection, layoutDirection } = useLayoutDirection((state) => ({
    toggleLayoutDirection: state.toggleLayoutDirection,
    layoutDirection: state.layoutDirection,
  }));

  const { isSmallMobile, isMobile } = useIsMobile();

  return (
    <>
      <Panel position='top-left'>
        <Stack direction='row' gap={2}>
          <BuilderButtonsGroup>
            <BuilderPanelButtonItem
              tooltip='Toggle Layout Direction'
              onClick={() => {
                toggleLayoutDirection();
              }}
              icon={
                layoutDirection === 'horizontal' ? 'format_letter_spacing' : 'format_line_spacing'
              }
            />
            <BuilderPanelButtonItem tooltip='Reset View' onClick={resetView} icon='crop_free' />
            <BuilderPanelButtonItem
              tooltip='Fit View'
              onClick={() => {
                fitView({ padding: 0.1, duration: 800 });
              }}
              icon='fit_screen'
            />
            {!isSmallMobile && (
              <BuilderPanelButtonItem tooltip='Zoom In' onClick={zoomIn} icon='zoom_in' />
            )}
            {!isSmallMobile && (
              <BuilderPanelButtonItem tooltip='Zoom Out' onClick={zoomOut} icon='zoom_out' />
            )}
          </BuilderButtonsGroup>
          <BuilderButtonsGroup>
            <BuilderPanelButtonItem tooltip='Undo' disabled={canUndo} onClick={undo} icon='undo' />
            {/*
            <BuilderPanelButtonItem tooltip='Redo' disabled={canRedo} onClick={redo} icon='redo' />
*/}
          </BuilderButtonsGroup>
        </Stack>
      </Panel>
      <Panel position='top-right'>
        <Stack direction='row' gap={2}>
          <BuilderButtonsGroup>
            <BuilderPanelButtonItem
              tooltip='General Bot Settings'
              label={!isMobile ? 'Settings' : undefined}
              tooltipPlacement='bottom'
              onClick={() => {
                setActiveToolboxTab(ToolboxTabs.BotSettings);
                openToolbox();
              }}
              icon='settings'
            />
            <BuilderPanelButtonItem
              tooltip='Commands Manager'
              label={!isMobile ? 'Commands' : undefined}
              tooltipPlacement='bottom'
              onClick={() => {
                setActiveToolboxTab(ToolboxTabs.CommandsSettings);
                openToolbox();
              }}
              icon='note_stack'
            />
          </BuilderButtonsGroup>
        </Stack>
      </Panel>
    </>
  );
};
export default memo(BuilderPanels);
