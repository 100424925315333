import { array, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  RequestLocationFormFields,
  RequestLocationFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/request-location/types';
import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidation, emptyEditorValue } from 'utils';

export const requestLocationStepFormSchema: ObjectSchema<RequestLocationFormFields> = object({
  [RequestLocationFormFieldsNames.NodeId]: string().required(),
  [RequestLocationFormFieldsNames.Name]: string().required('Name is required'),
  [RequestLocationFormFieldsNames.Button]: string().required('Button is required'),
  [RequestLocationFormFieldsNames.Input]: editorValidation,
  [RequestLocationFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultLocationDataFormValues: Partial<RequestLocationFormFields> = {
  [RequestLocationFormFieldsNames.Input]: emptyEditorValue,
  [RequestLocationFormFieldsNames.NodeId]: '',
  [RequestLocationFormFieldsNames.Button]: 'Request Location',
  [RequestLocationFormFieldsNames.Labels]: [
    {
      label: 'Location Shared',
      color: '#DBEEDB',
      icon: 'location_on',
      value:
        process.env.REACT_APP_ENV === 'production'
          ? '6609a37a42362ebd0e619bfb'
          : '6609645a63ebf0a979048596',
    },
  ],
};

export const requestLocationFieldsConfig: Record<RequestLocationFormFieldsNames, FormFieldProps> = {
  [RequestLocationFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: RequestLocationFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [RequestLocationFormFieldsNames.Input]: {
    placeholder: '* Message',
    name: RequestLocationFormFieldsNames.Input,
    type: 'text',
  },
  [RequestLocationFormFieldsNames.Button]: {
    placeholder: 'Will be displayed on the button',
    name: RequestLocationFormFieldsNames.Button,
    autoComplete: 'off',
    type: 'text',
  },
  [RequestLocationFormFieldsNames.NodeId]: {
    name: RequestLocationFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [RequestLocationFormFieldsNames.Labels]: labelFormFieldConfig,
};
