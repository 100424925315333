import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { useMenu } from 'hooks';
import { menuMinWidth } from 'theme/utils';

export interface FormIconPickerProps {
  name: string;
  options: { value: string; label: string }[];
  label?: string;
}
export const FormIconPicker = (props: FormIconPickerProps) => {
  const { name, options, label, ...rest } = props;
  const { control } = useFormContext();
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
        <>
          <IconButton
            tooltip={label}
            tooltipPlacement='top'
            ref={ref}
            size='large'
            sx={{
              border: 1,
              borderColor: 'divider',
              bgcolor: 'grey.50',
              borderRadius: 1,
            }}
            {...field}
            {...rest}
            onClick={openMenu}
            onKeyUp={(e) => {
              if (e.code === 'Space') {
                openMenu(e);
              }
            }}
            icon={field.value}
          />
          <Menu
            id='icon-picker-menu'
            aria-labelledby='icon-picker-button'
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            slotProps={{
              paper: { sx: { minWidth: menuMinWidth, maxWidth: 280 } },
            }}
          >
            {options.map((option) => (
              <MenuItem
                selected={field.value === option.value}
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <Icon icon={option.value} fontSize='small' />
                </ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
          <FormCollapseHelperText error={error} />
        </>
      )}
    />
  );
};

export default memo(FormIconPicker);
