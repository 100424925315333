import { Box, Button as MuiButton, ButtonProps as MuiIButtonProps, Tooltip } from '@mui/material';
import { forwardRef } from 'react';
import { TooltipWrapperProps } from 'theme';

export interface ButtonProps
  extends Omit<MuiIButtonProps, 'ref' | 'disableElevation'>,
    TooltipWrapperProps {}

const Button = forwardRef<HTMLDivElement, ButtonProps>(
  ({ children, fullWidth, tooltip = '', tooltipPlacement, tooltipProps, ...other }, ref) => (
    <Tooltip title={tooltip} ref={ref} placement={tooltipPlacement} {...tooltipProps}>
      <Box
        sx={{
          width: fullWidth ? '100%' : 'unset',
        }}
      >
        <MuiButton
          fullWidth={fullWidth}
          disableElevation
          sx={{
            minWidth: 'unset',
            ...other.sx,
          }}
          {...other}
        >
          <Box
            component='span'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {children}
          </Box>
        </MuiButton>
      </Box>
    </Tooltip>
  ),
);

export default Button;
