import { GridSortModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { TicketStatus } from 'enums';
import { ChatTableProps, UserDisplay, UserLabel } from 'types';
import { PickEnum } from 'utils';

export enum ChatColumns {
  ProjectName = 'projectName',
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  LanguageCode = 'languageCode',
  UserName = 'userName',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  IsActive = 'isActive',
  IsRead = 'isRead',
  IsImportant = 'isImportant',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ConversationCount = 'conversationCount',
  Notifications = 'notifications',
  Note = 'note',
  Labels = 'labels',
  IsArchived = 'isArchived',
  Assignee = 'assignee',
  Status = 'status',
}

export interface ActivityTableProps {
  isLoading: boolean;
  chatData: ChatTableProps[];
  filteredChatData: ChatTableProps[];
  sortModel: GridSortModel;
  onSortModelChange: (newSortModel: GridSortModel) => void;
  projectNames: string[];
  setSelectedProjectNames: Dispatch<SetStateAction<string[]>>;
  projectName: string;
  selectedProjectNames: string[];
  setIsImportantFilter: (value: boolean) => void;
  isImportantFilter: boolean;
  showArchived: boolean;
  setShowArchived: Dispatch<SetStateAction<boolean>>;
  userLabels: UserLabel[];
  selectedLabels: UserLabel[];
  setSelectedLabels: Dispatch<SetStateAction<UserLabel[]>>;
  selectedStatuses?: TicketStatus[];
  setSelectedStatuses?: Dispatch<SetStateAction<TicketStatus[]>>;
  selectedAssignees?: UserDisplay[];
  setSelectedAssignees?: Dispatch<SetStateAction<UserDisplay[]>>;
  assigneeOptions?: UserDisplay[];
}

export type ChatSortableKeys = PickEnum<
  ChatColumns,
  ChatColumns.ProjectName | ChatColumns.UpdatedAt | ChatColumns.IsImportant | ChatColumns.IsRead
>;
