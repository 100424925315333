import { Stack } from '@mui/material';
import * as React from 'react';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import FormArrayRemoveButton from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormArrayRemoveButton';
import FormWithCommandAutoComplete from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormWithCommandAutoComplete';
import {
  OptionsMenuStepFormFieldsMenuNames,
  OptionsMenuStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-menu/types';
import { OptionsMenuFormMenuFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/options-menu/utils';

const MenuFormArrayField = ({ getFieldName, index, remove, length }: FormArrayFieldProps) => {
  const { name, label, ...rest } =
    OptionsMenuFormMenuFieldsConfig[OptionsMenuStepFormFieldsMenuNames.Option];

  const menuFieldName = getFieldName(name);

  return (
    <Stack direction='row' alignItems='flex-start' gap={0.5}>
      <FormWithCommandAutoComplete
        placeholder={`${label} ${index + 1}`}
        name={menuFieldName}
        parentName={OptionsMenuStepFormFieldsNames.Menu}
        {...rest}
      />
      <FormArrayRemoveButton onClick={() => remove(index)} disabled={length === 2} />
    </Stack>
  );
};

export default MenuFormArrayField;
