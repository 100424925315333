import { Divider, Stack, Typography } from '@mui/material';

const OrDivider = () => (
  <Stack direction='row' width={1}>
    <Divider flexItem sx={{ flex: 1, alignSelf: 'center' }} />
    <Typography px={3} align='center' variant='body1'>
      OR
    </Typography>
    <Divider flexItem sx={{ flex: 1, alignSelf: 'center' }} />
  </Stack>
);

export default OrDivider;
