import { Box, Chip, ListItemText, MenuItem, Stack } from '@mui/material';
import * as React from 'react';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { FormAutoComplete, FormSelect } from 'components/index';
import { ChatFilterFieldsNames } from 'pages/app-pages/private-bulk-message-page/message-modal/types';
import {
  chatFilterFieldsConfig,
  directionOptions,
  matchOptions,
} from 'pages/app-pages/private-bulk-message-page/message-modal/utils';
import { AutoCompleteLabel } from 'types';
import { filterOptions } from 'utils';

const BulkMessageChatFilterFormArrayField = ({
  getFieldName,
  options,
}: FormArrayFieldProps & {
  options: AutoCompleteLabel[];
}) => {
  const { name, ...rest } = chatFilterFieldsConfig[ChatFilterFieldsNames.Direction];

  const { name: labelsName, ...labelsRest } = chatFilterFieldsConfig[ChatFilterFieldsNames.Labels];

  const { name: matchingName, ...matchingRest } =
    chatFilterFieldsConfig[ChatFilterFieldsNames.Matching];

  const directionFieldName = getFieldName(name);
  const labelsFieldName = getFieldName(labelsName);
  const matchingFieldName = getFieldName(matchingName);

  return (
    <Stack direction='row' alignItems='flex-start' gap={1} flexWrap='wrap'>
      <Box
        flex={4}
        maxWidth={0.4}
        minWidth={{
          xs: 1,
          // sm: 'auto',
        }}
      >
        <FormAutoComplete<AutoCompleteLabel>
          name={labelsFieldName}
          freeSolo
          multiple
          filterSelectedOptions
          filterOptions={filterOptions}
          options={options}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object' && typeof value === 'object') {
              return option.value.toLowerCase() === value.value.toLowerCase();
            }
            if (typeof option === 'string' && typeof value === 'string') {
              return option === value;
            }
            return false;
          }}
          renderOption={(props, option) => (
            <MenuItem key={option.value} {...props}>
              <ListItemText disableTypography>
                <Chip size='small' label={option.label} sx={{ backgroundColor: option.color }} />
              </ListItemText>
            </MenuItem>
          )}
          {...labelsRest}
          size='small'
        />
      </Box>
      <Box
        flex={1}
        maxWidth={{
          xs: 0.5,
          // sm: 0.3,
        }}
        minWidth={{
          xs: 'auto',
          sm: 'auto',
        }}
      >
        <FormSelect
          size='small'
          name={directionFieldName}
          options={directionOptions}
          variant='outlined'
          nonEmpty
          {...rest}
        />
      </Box>
      <Box
        flex={1}
        maxWidth={{
          xs: 0.5,
          //  sm: 0.3,
        }}
        minWidth={{
          xs: 'auto',
          sm: 'auto',
        }}
      >
        <FormSelect
          size='small'
          name={matchingFieldName}
          options={matchOptions}
          variant='outlined'
          nonEmpty
          {...matchingRest}
        />
      </Box>
    </Stack>
  );
};

export default BulkMessageChatFilterFormArrayField;
