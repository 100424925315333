import { Box, ButtonBase, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import {
  ConfirmationDialog,
  FormTextEditor,
  FormTextField,
  IconButton,
  ImagePreview,
} from '@common-components';
import { api, ApiKey } from 'api';
import {
  useAuth,
  useFormProvider,
  useIsMobile,
  useMenu,
  useNavigateBack,
  useServerError,
  useToast,
} from 'hooks';
import { useDirtyStore } from 'store';
import { Chat } from 'types';
import { characterLimitValue, defaultMentions } from 'utils';
import { ChatMessageFormFields, ChatMessageFormFieldsNames } from './types';
import { groupsMessagesFieldsConfig, messageDefaultValues, messageSchema } from './utils';

const minHeight = 50;

interface ChatFormProps {
  isBlocked?: boolean;
}

const ChatForm = ({ isBlocked }: ChatFormProps) => {
  const { isMobile } = useIsMobile();
  const navigateBack = useNavigateBack();
  const toast = useToast();
  const toastServerError = useServerError();
  const { user } = useAuth();
  const {
    value: submitting,
    setTrue: startSubmitting,
    setFalse: stopSubmitting,
  } = useBoolean(false);

  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));

  const { chatId } = useParams();

  const formMethods = useFormProvider<ChatMessageFormFields>({
    reValidateMode: 'onChange',
    schema: messageSchema,
    defaultValues: messageDefaultValues,
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = formMethods;

  const imageVal = watch(ChatMessageFormFieldsNames.FileUrl);

  const [prevImageVal, setPrevImageVal] = useState<string | undefined>(imageVal);

  const { message, fileUrl } = groupsMessagesFieldsConfig;

  const { placeholder: messagePlaceHolder, ...restMessageProps } = message;

  const { openMenu: openImagePrompt, ...restImagePrompt } = useMenu();

  const handleOnSubmit = async (formData: ChatMessageFormFields) => {
    setIsDirty(false);
    startSubmitting();

    if (user?.organization?.featureGates.sendPrivateChat) {
      try {
        const res = await api.post<Chat>(
          `${ApiKey.Chat}/${chatId}/${ApiKey.SendMessage}`,
          formData,
        );
        reset();
        setPrevImageVal(undefined);
        await mutate(`${ApiKey.Project}/${res.data.project?.id}`);
        await mutate(`${ApiKey.Chat}/${chatId}`);
        stopSubmitting();
      } catch (error: any) {
        stopSubmitting();
        toastServerError(error);
      }
    } else {
      reset();
      setPrevImageVal(undefined);
      stopSubmitting();
      toast({
        message:
          'This feature is not available for your account. Upgrade to Pro Plan to unlock this feature.',
        variant: 'error',
      });
    }
  };

  if (!chatId) {
    navigateBack(); // This will navigate back if there is no project
    return null; // This prevents the rest of the component from rendering
  }

  return (
    <FormProvider {...formMethods}>
      <Stack width={1} gap={2} position='relative'>
        {isBlocked && (
          <Tooltip
            title='The user has blocked the Bot, preventing message delivery.'
            arrow
            placement='top'
          >
            <Box
              position='absolute'
              bottom={-8}
              left={-4}
              right={-4}
              height={minHeight + 16}
              bgcolor='grey.200'
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 1,
                borderBottomRightRadius: 1,
                opacity: 0.5,
                cursor: 'not-allowed',
              }}
              zIndex={1}
            />
          </Tooltip>
        )}
        <ConfirmationDialog
          {...restImagePrompt}
          disableAutoClose
          disabled={!imageVal}
          submitButtonColor='primary'
          node={
            <>
              <Typography mb={1} color='text.secondary' variant='body2'>
                Add a URL to an image/file publicly available; for images you can use{' '}
                <Link target='_blank' href='https://postimages.org/' rel='noreferrer'>
                  postimage
                </Link>
              </Typography>

              <FormTextField autoFocus={!isMobile} {...fileUrl} />
            </>
          }
          onConfirm={async () => {
            await trigger(ChatMessageFormFieldsNames.FileUrl);
            if (!errors[ChatMessageFormFieldsNames.FileUrl]) {
              clearErrors(ChatMessageFormFieldsNames.FileUrl);
              setPrevImageVal(imageVal);
              restImagePrompt.closeMenu();
            }
          }}
          onClose={async () => {
            setValue(ChatMessageFormFieldsNames.FileUrl, prevImageVal);
            clearErrors(ChatMessageFormFieldsNames.FileUrl);
            restImagePrompt.closeMenu();
          }}
        />

        <Stack direction='row' width={1} gap={0.5} alignItems='flex-end'>
          <Stack
            height={minHeight}
            width={minHeight}
            alignItems='center'
            justifyContent='center'
            onClick={(e) => {
              openImagePrompt(e);
              setPrevImageVal(imageVal);
            }}
            component={ButtonBase}
            disableRipple
            disableTouchRipple
          >
            {prevImageVal ? (
              <Box width={minHeight} height={minHeight} position='relative'>
                <Stack
                  position='absolute'
                  zIndex={1}
                  width={20}
                  height={20}
                  top={-6}
                  right={-6}
                  borderRadius={2}
                  border={1}
                  bgcolor='grey.200'
                  borderColor='grey.400'
                  alignItems='center'
                  justifyContent='center'
                >
                  <IconButton
                    icon='close_small'
                    size='small'
                    color='default'
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(ChatMessageFormFieldsNames.FileUrl, '');
                      setPrevImageVal(undefined);
                    }}
                    iconProps={{ sx: { fontSize: 16 } }}
                    boxProps={{ sx: { width: 20, height: 20, boxSizing: 'border-box' } }}
                  />
                </Stack>
                <ImagePreview size={minHeight - 1} imageUrl={prevImageVal} />
              </Box>
            ) : (
              <IconButton disabled={submitting} icon='image' size='large' color='primary' />
            )}
          </Stack>

          <Stack flex={1}>
            <FormTextEditor
              characterLimit={characterLimitValue}
              {...restMessageProps}
              placeholder={messagePlaceHolder}
              minHeight={minHeight - 2}
              showToolbarOnFocus
              mentions={defaultMentions}
            />
          </Stack>
          <Stack height={minHeight} width={minHeight} alignItems='center' justifyContent='center'>
            <IconButton
              disabled={submitting}
              loading={submitting}
              icon='send'
              size='large'
              color='primary'
              onClick={handleSubmit((data) => handleOnSubmit(data))}
            />
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default ChatForm;
