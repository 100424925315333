import { Avatar, Badge, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Icon from 'components/Icon';

interface UserTableItemProps {
  title: string;
  subtitle: string;
  icon: string;
  iconColor?: string;
  suffix?: ReactNode;
  hasNotification?: boolean;
}
const UserTableItem = ({
  title,
  subtitle,
  suffix,
  icon,
  iconColor = 'primary.main',
  hasNotification,
}: UserTableItemProps) => (
  <Stack p={2} flex={1} direction='row' alignItems='center' gap={2}>
    <Stack direction='row' alignItems='center' gap={2} flex={1} overflow='hidden'>
      <Badge
        invisible={!hasNotification}
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Avatar sx={{ bgcolor: 'success.main', width: 22, height: 22, border: 2 }}>
            <Icon
              icon='notifications_active'
              fontSize='small'
              sx={{
                color: 'white',
                fontSize: 13,
              }}
            />
          </Avatar>
        }
      >
        <Avatar
          alt={title[0].toUpperCase() + title[1].toUpperCase()}
          sx={{ bgcolor: iconColor, width: 36, height: 36 }}
        >
          <Icon icon={icon} fontSize='small' />
        </Avatar>
      </Badge>
      <Stack justifyContent='space-between' overflow='hidden'>
        <Typography noWrap variant='body1' title={title}>
          {title}
        </Typography>
        <Typography variant='caption' noWrap color='textSecondary'>
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
    {suffix}
  </Stack>
);

export default UserTableItem;
