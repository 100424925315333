import {
  StaticDateTimePicker,
  StaticDateTimePickerProps,
} from '@mui/x-date-pickers/StaticDateTimePicker';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';

export interface FormDateTimePickerProps extends StaticDateTimePickerProps<Date> {
  name: string;
  disabled?: boolean;
  // Add other props that you need to pass to the DateTimePicker
}

export const FormStaticDateTimePicker = ({ name, disabled, ...rest }: FormDateTimePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref, ...field }, fieldState: { error } }) => (
        <>
          <StaticDateTimePicker
            ampm={false}
            ampmInClock={false}
            value={value || null}
            onChange={onChange}
            minutesStep={5}
            disabled={disabled}
            {...rest}
            {...field}
          />
          <FormCollapseHelperText error={error} />
        </>
      )}
    />
  );
};

export default React.memo(FormStaticDateTimePicker);
