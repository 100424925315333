import { SwipeableDrawer } from '@mui/material';
import { memo } from 'react';
import { useOnSelectionChange } from 'reactflow';
import { useAppBarHeight, useIsMobile } from 'hooks';
import messages from 'messages';
import { useSelectNode } from 'pages/app-pages/chatbot-builder-page/hooks/useSelectNode';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ToolboxTabsConfig } from 'pages/app-pages/chatbot-builder-page/toolbox/config';
import {
  iOS,
  toolboxDrawerConfig,
} from 'pages/app-pages/chatbot-builder-page/toolbox/props-config';
import { useDirtyStore } from 'store';

function BuilderToolbox() {
  const { isMobile } = useIsMobile();
  const appBarHeight = useAppBarHeight();

  const {
    isToolboxOpen,
    setSelectedStepType,
    openToolbox,
    closeToolbox,
    activeToolboxTab,
    selectedNodeId,
    setSelectedNodeId,
    setEditorIsFocused,
  } = useBuilderStore((state) => ({
    isToolboxOpen: state.isToolboxOpen,
    openToolbox: state.openToolbox,
    closeToolbox: state.closeToolbox,
    activeToolboxTab: state.activeToolboxTab,
    setActiveToolboxTab: state.setActiveToolboxTab,
    selectedNodeId: state.selectedNodeId,
    setSelectedNodeId: state.setSelectedNodeId,
    setSelectedStepType: state.setSelectedStepType,
    setEditorIsFocused: state.setEditorIsFocused,
  }));

  const { setIsDirty, isDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
    isDirty: state.isDirty,
  }));

  const selectNode = useSelectNode();

  const ToolboxActiveTab = activeToolboxTab && ToolboxTabsConfig[activeToolboxTab].component;

  const closeToolboxAndResetSelection = () => {
    closeToolbox();
    setIsDirty(false);
    setSelectedStepType(null);
    setEditorIsFocused(false);
    if (!isMobile) {
      setSelectedNodeId(null);
      selectNode(null);
    }
  };

  const handleConfirmClose = () => {
    if (isMobile && !isDirty) {
      closeToolboxAndResetSelection();
      return;
    }
    // eslint-disable-next-line no-alert
    const userResponse = window.confirm(`${messages.builder.confirmLoseNodeChanges}`);
    if (userResponse) {
      closeToolboxAndResetSelection();
    } else {
      selectNode(selectedNodeId);
    }
  };

  useOnSelectionChange({
    onChange: () => {
      setEditorIsFocused(true);
    },
  });

  return (
    <SwipeableDrawer
      className='notranslate'
      disableBackdropTransition={!iOS}
      disableDiscovery
      disableSwipeToOpen
      open={isToolboxOpen}
      onOpen={openToolbox}
      // @ts-ignore
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          handleConfirmClose();
        } else {
          closeToolboxAndResetSelection();
        }
      }}
      {...toolboxDrawerConfig({ isMobile, appBarHeight })}
    >
      <ToolboxActiveTab />
    </SwipeableDrawer>
  );
}

export default memo(BuilderToolbox);
