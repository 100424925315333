import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { useWindowSize } from 'usehooks-ts';
import { useIsMobile } from 'hooks';
import { useLayoutDirection } from 'pages/app-pages/chatbot-builder-page/store';

export const useBuilderView = () => {
  const { isMobile } = useIsMobile();

  const { setViewport, fitView, zoomIn, zoomOut, getViewport, viewportInitialized } =
    useReactFlow();

  const { layoutDirection } = useLayoutDirection((state) => ({
    toggleLayoutDirection: state.toggleLayoutDirection,
    layoutDirection: state.layoutDirection,
  }));

  const boxRef = useRef<HTMLDivElement>(null);
  const boxRect = boxRef.current?.getBoundingClientRect();
  const [rectHeight, setRectHeight] = useState(boxRect?.height || 0);
  const [rectWidth, setRectWidth] = useState(boxRect?.width || 0);

  const size = useWindowSize();

  useLayoutEffect(() => {
    setRectHeight(boxRect?.height || 0);
    setRectWidth(boxRect?.width || 0);
  }, [boxRect?.height, boxRect?.width, size]);

  const onResetView = useCallback(
    ({ height, width, duration = 800 }: { height: number; width: number; duration?: number }) => {
      const nodeWidth = isMobile ? 124 : 400;
      const nodeHeight = 250;

      const xOffSet = layoutDirection === 'horizontal' ? nodeWidth : 0;
      const yOffSet = layoutDirection === 'horizontal' ? 0 : nodeHeight;

      setViewport(
        {
          x: width / 2 - xOffSet,
          y: height / 2 - yOffSet,
          zoom: isMobile ? 0.6 : 1,
        },
        { duration: duration ?? 0 },
      );
    },
    [isMobile, layoutDirection, setViewport],
  );

  const resetView = useCallback(() => {
    onResetView({ height: rectHeight, width: rectWidth });
  }, [onResetView, rectHeight, rectWidth]);

  useLayoutEffect(() => {
    if (viewportInitialized) {
      onResetView({ height: rectHeight, width: rectWidth, duration: 0 });
    }
  }, [getViewport, onResetView, rectHeight, rectWidth, setViewport, viewportInitialized]);

  return { boxRef, resetView, fitView, rectHeight, rectWidth, zoomIn, zoomOut };
};
