import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { useFieldArray, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Button from 'components/Button';
import Icon from 'components/Icon';

export interface FormArrayFieldProps {
  getFieldName: (fieldName: string) => string;
  remove: UseFieldArrayRemove;
  index: number;
  disabled?: boolean;
  defaultValue?: any;
  length?: number;
  onBlurCallback?: (index: number, value: any) => void;
}

export interface FormArrayFieldsProps {
  name: string;
  addButtonText?: string;
  noAppend?: boolean;
  appendValue?: any;
  gap?: number;
  onBlurCallback?: (index: number, value: any) => void;
  FormArrayFieldComponent: FC<FormArrayFieldProps>;
}

const FormArrayFields = ({
  name,
  addButtonText = 'Add',
  FormArrayFieldComponent,
  noAppend,
  appendValue,
  gap,
  onBlurCallback,
}: FormArrayFieldsProps) => {
  const { control } = useFormContext();

  const formArrayProps = useFieldArray({ control, name });
  const { fields, append, remove } = formArrayProps;

  const renderFormArrayFieldComponent = (field: Record<'id', string>, index: number) => (
    <FormArrayFieldComponent
      key={field.id}
      getFieldName={(fieldName: string) => `${name}.${index}.${fieldName}`}
      index={index}
      remove={remove}
      length={fields.length}
      onBlurCallback={(i, value) => {
        onBlurCallback?.(i, value);
      }}
    />
  );

  return (
    <>
      {fields?.length > 0 && (
        <Stack gap={gap ?? 1}>
          {fields.map((field, index) => renderFormArrayFieldComponent(field, index))}
        </Stack>
      )}
      <Box mt={0.5}>
        {!noAppend && (
          <Button
            startIcon={<Icon icon='add' fontSize='small' />}
            onClick={() =>
              append(appendValue ?? { name: `Path ${fields.length}`, nodeId: uuid(), option: '' })
            }
          >
            {addButtonText}
          </Button>
        )}
      </Box>
    </>
  );
};

export default FormArrayFields;
