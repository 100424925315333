import { Badge, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Icon from 'components/Icon';
import { Button } from 'components/index';
import { ActivityTableProps } from 'pages/activity-page/types';

export interface FilterToggleButtonProps
  extends Omit<ActivityTableProps, 'isLoading' | 'chatData' | 'filteredChatData' | 'projectName'> {
  chatCount: number;
  filteredChatCount: number;
  archivedChatCount: number;
  closeDrawer: () => void;
  openDrawer: () => void;
}

const FilterToggleButton = ({
  setSelectedProjectNames,
  setIsImportantFilter,
  isImportantFilter,
  selectedLabels,
  setSelectedLabels,
  selectedProjectNames,
  showArchived,
  setShowArchived,
  filteredChatCount,
  archivedChatCount,
  selectedStatuses,
  setSelectedStatuses,
  selectedAssignees,
  setSelectedAssignees,
  closeDrawer,
  openDrawer,
  chatCount,
}: FilterToggleButtonProps) => {
  const isOtherFilterApplied =
    selectedProjectNames.length > 0 ||
    isImportantFilter ||
    selectedLabels.length > 0 ||
    (selectedStatuses?.length && selectedStatuses?.length > 0) ||
    (selectedAssignees?.length && selectedAssignees?.length > 0);

  const isOnlyArchivedFilterApplied = showArchived && !isOtherFilterApplied;

  // Correctly compute total chats based on filter status
  const localChatCount = showArchived ? chatCount : chatCount - archivedChatCount;

  // Adjust filteredChatCount if only the archived filter is applied
  const effectiveFilteredChatCount = isOnlyArchivedFilterApplied ? chatCount : filteredChatCount;

  // Determine if the display should show the clear button and badge
  const shouldShowClearAndBadge = isOtherFilterApplied || showArchived;

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      {shouldShowClearAndBadge && (
        <Button
          size='small'
          onClick={() => {
            setSelectedLabels([]);
            setIsImportantFilter(false);
            setSelectedProjectNames([]);
            setShowArchived(false);
            setSelectedStatuses?.([]);
            setSelectedAssignees?.([]);
            closeDrawer();
          }}
        >
          {isOnlyArchivedFilterApplied ? 'Hide Archived' : 'Clear'}
        </Button>
      )}
      <Badge
        badgeContent={
          shouldShowClearAndBadge ? `${effectiveFilteredChatCount}/${localChatCount}` : 0
        }
        invisible={!shouldShowClearAndBadge}
        color='primary'
        showZero={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ToggleButtonGroup
          color='primary'
          size='small'
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              border: 0,
              '&.Mui-disabled': {
                border: 0,
              },
              '&:not(:first-of-type)': {
                borderRadius: 1,
                ml: 0.5,
              },
              '&:first-of-type': {
                borderRadius: 1,
              },
            },
          }}
          value='filter'
          aria-label='text alignment'
        >
          <ToggleButton onClick={openDrawer} value='filter' aria-label='list'>
            <Icon icon='filter_list' fontSize='small' />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    </Stack>
  );
};
export default FilterToggleButton;
