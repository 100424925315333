import { stratify } from 'd3-hierarchy';
import { useCallback } from 'react';
import { Node, useReactFlow } from 'reactflow';
import { BaseNodeProps } from 'types';

const useValidateTreeStructure = () => {
  const { getNodes, getEdges } = useReactFlow<BaseNodeProps>();

  return useCallback(() => {
    const allNodes = getNodes(); // Assume this gets the current state of nodes
    const allEdges = getEdges(); // Assume this gets the current state of edges

    // Identify root nodes (nodes with no incoming edges)
    try {
      const stratifyFunction = stratify<Node<BaseNodeProps>>()
        .id((d) => d.id)
        .parentId((d) => {
          const edge = allEdges.find((e) => e.target === d.id);
          return edge ? edge.source : undefined;
        });
      stratifyFunction(allNodes); // This will throw an error if the hierarchy is invalid
      // If stratify doesn't throw an error, the hierarchy is considered valid.
      // eslint-disable-next-line no-console
      //  console.log('Hierarchy is valid');
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Hierarchy is invalid');
      // If there's an error in stratifying, it means the hierarchy is invalid.
      return false;
    }
  }, [getNodes, getEdges]);
};

export default useValidateTreeStructure;
