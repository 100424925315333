import { Divider, Stack, Typography } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';

import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { BasicModal, BulletWrapper } from '@common-components';

import { api, ApiKey } from 'api';
import { AppTypes, RoutePath } from 'enums';
import { useIsMobile, useServerError, useToast } from 'hooks';
import { useGetChatGroupMessages } from 'hooks/api/messenger/useGetChatGroupMessages';
import TutorialStep from 'pages/app-pages/chatbot-builder-page/dialogs/connect-groups/TutorialStep';
import { Project } from 'types';

const ConnectGroups = () => {
  const { id } = useParams();

  const { isMobile } = useIsMobile();

  const { value: verifying, setTrue: startVerifying, setFalse: stopVerifying } = useBoolean();

  const { chatGroupMessages } = useGetChatGroupMessages({
    projectId: id || '',
  });

  const navigate = useNavigate();
  const toast = useToast();
  const serverError = useServerError();
  return (
    <BasicModal
      title='Connect The Bot To Your Groups and Channels'
      label='create-command-dialog'
      open
      maxWidth='md'
      mainAction={{
        isSubmitting: verifying,
        children: 'Verify & Continue',
        type: 'button',
        onClick: async () => {
          startVerifying();
          try {
            await api.patch<Project>(
              `${ApiKey.Project}/${id}/${ApiKey.App}/${AppTypes.Messenger}`,
              {
                isActive: true,
              },
            );
            const response = await mutate<Project>(`${ApiKey.Project}/${id}`);
            if (response?.chatGroups?.length) {
              toast({
                message: `Bot connected to ${response.chatGroups.length} groups/channels`,
                variant: 'success',
              });
              if (chatGroupMessages?.length) {
                navigate('./../');
              } else {
                navigate(`/${RoutePath.Messenger}/${id}/${RoutePath.Onboarding}`);
              }
            } else {
              toast({
                message: 'Please connect at least one group/channel',
                variant: 'error',
              });
            }
            stopVerifying();
          } catch (error) {
            serverError(error);
            stopVerifying();
          }
        },
      }}
      cancelButton
    >
      <Stack width={1}>
        <Stack
          width={1}
          direction={isMobile ? 'column' : 'row'}
          gap={5}
          divider={<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />}
        >
          <TutorialStep />
          <Stack flex={1} gap={1} alignItems='flex-start'>
            <Typography variant='h6' gutterBottom>
              2. Activate The Bot
            </Typography>
            <BulletWrapper>
              <Typography variant='body2' mb={1}>
                To activate the bot, send any message in the group or channel. This could be a
                simple greeting or any text.
              </Typography>
            </BulletWrapper>
            <BulletWrapper>
              <Typography variant='body2' mb={1}>
                Once the message is shared, click on the <b>Verify</b> button. This button will
                confirm that the bot is active and properly connected to your group or channel.
              </Typography>
            </BulletWrapper>
          </Stack>
        </Stack>
      </Stack>
    </BasicModal>
  );
};

export default ConnectGroups;
