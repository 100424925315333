import { Alert, Box, DialogActions, Link, Popover, Stack } from '@mui/material';
import { every, isEmpty } from 'lodash';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import {
  BasicModal,
  FormArrayFields,
  FormSection,
  FormStaticDateTimePicker,
  FormSwitch,
  FormTextEditor,
  FormTextField,
  ImagePreview,
  List,
  ListItem,
} from '@common-components';
import { api, ApiKey } from 'api';
import Button from 'components/Button';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { PrivateChatBulkMessageStatus } from 'enums';
import {
  useFormProvider,
  useGetChatActivity,
  useGetUserLabels,
  useIsMobile,
  useNavigateBack,
  useServerError,
  useToast,
} from 'hooks';
import BulkMessageChatFilterFormArrayField from 'pages/app-pages/private-bulk-message-page/message-modal/BulkMessageChatFilterFormArrayField';
import SplitButton from 'pages/app-pages/private-bulk-message-page/message-modal/SplitButton';
import TestBulkButton from 'pages/app-pages/private-bulk-message-page/message-modal/TestBulkButton';

import {
  FormMode,
  PrivateBulkMessageFormLocalState,
  PrivateMessageFormFields,
  PrivateMessageFormFieldsNames,
} from 'pages/app-pages/private-bulk-message-page/message-modal/types';
import {
  bulkMessagesFieldsConfig,
  messageDefaultValues,
  messageSchema,
} from 'pages/app-pages/private-bulk-message-page/message-modal/utils';
import {
  chatGroupMessageStatusConfig,
  generateMessage,
} from 'pages/app-pages/private-bulk-message-page/utils';
import { useDirtyStore } from 'store';
import { PrivateChatBulkMessage } from 'types';
import {
  characterLimitValue,
  countMatchingChats,
  defaultMentions,
  formatShortTimeDifference,
  mapLabelsAutocompleteOptions,
} from 'utils';

const BulkMessageFormModal = () => {
  const { isMobile } = useIsMobile();
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const toastServerError = useServerError();

  const { chats } = useGetChatActivity();

  const { setIsDirty } = useDirtyStore((state) => ({ setIsDirty: state.setIsDirty }));

  const locationState = location.state as PrivateBulkMessageFormLocalState | undefined;

  const initStatus = locationState?.messageStatus || PrivateChatBulkMessageStatus.Published;

  const {
    value: submittingTest,
    setTrue: startSubmittingTest,
    setFalse: stopSubmittingTest,
  } = useBoolean();

  const { value: submitting, setTrue: startSubmitting, setFalse: stopSubmitting } = useBoolean();

  const { value: scheduleMessage, setValue: setScheduleMessage } = useBoolean(
    !!locationState?.initialValues?.startDate,
  );

  const [localStartDate, setLocalStartDate] = useState<Date | null | undefined>(
    locationState?.initialValues?.startDate,
  );

  const initialValues: PrivateMessageFormFields = {
    [PrivateMessageFormFieldsNames.Project]: locationState?.initialValues?.project || '',
    [PrivateMessageFormFieldsNames.Message]:
      locationState?.initialValues?.message || locationState?.initialValues?.fileUrl
        ? locationState?.initialValues?.message
        : messageDefaultValues.message,
    [PrivateMessageFormFieldsNames.LinkText]: locationState?.initialValues?.linkText,
    [PrivateMessageFormFieldsNames.LinkUrl]: locationState?.initialValues?.linkUrl,
    [PrivateMessageFormFieldsNames.FileUrl]: locationState?.initialValues?.fileUrl,
    [PrivateMessageFormFieldsNames.StartDate]: locationState?.initialValues?.startDate,
    [PrivateMessageFormFieldsNames.ExcludeArchived]:
      locationState?.initialValues?.excludeArchived ||
      messageDefaultValues[PrivateMessageFormFieldsNames.ExcludeArchived],
    [PrivateMessageFormFieldsNames.ChatFilter]:
      locationState?.initialValues?.chatFilter ||
      messageDefaultValues[PrivateMessageFormFieldsNames.ChatFilter],
  };

  const formMethods = useFormProvider<PrivateMessageFormFields>({
    reValidateMode: 'onChange',
    schema: messageSchema,
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;

  const startDateValue = watch(PrivateMessageFormFieldsNames.StartDate);
  const fileUrlValue = watch(PrivateMessageFormFieldsNames.FileUrl);
  const chatFilterWatchValue = watch(PrivateMessageFormFieldsNames.ChatFilter);
  const excludeArchivedValue = watch(PrivateMessageFormFieldsNames.ExcludeArchived);
  const fileUrlError = errors[PrivateMessageFormFieldsNames.FileUrl];

  const chatCount = countMatchingChats({
    chats,
    filter: !isEmpty(chatFilterWatchValue)
      ? chatFilterWatchValue[0]
      : messageDefaultValues[PrivateMessageFormFieldsNames.ChatFilter][0],
    projectId: locationState?.projectId || '',
    excludeArchived: excludeArchivedValue,
  });

  const { labels: userLabels } = useGetUserLabels();
  const autoCompleteOptions = mapLabelsAutocompleteOptions(userLabels);

  const resetDate = () => {
    setValue(PrivateMessageFormFieldsNames.StartDate, undefined);
  };

  const { message, excludeArchived, startDate, linkUrl, linkText, fileUrl, chatFilter } =
    bulkMessagesFieldsConfig;

  const { placeholder: messagePlaceHolder, ...restMessageProps } = message;

  const [selectedIndex, setSelectedIndex] = useState(
    scheduleMessage ? 2 : chatGroupMessageStatusConfig[initStatus].index,
  );

  const [prevIndex, setPrevIndex] = useState(selectedIndex);

  const chatFilterValueMapped = chatFilterWatchValue.map((filter) => ({
    direction: filter.direction,
    matching: filter.matching,
    labelIds: filter.labels.map((label) => label.value),
  }));

  const queryMessage = generateMessage(
    true,
    chatFilterValueMapped,
    userLabels,
    excludeArchivedValue,
  );

  const handleTestBulkMessage = async (formData: PrivateMessageFormFields) => {
    startSubmittingTest();
    try {
      await api.post(`${ApiKey.Chat}/${ApiKey.TestMessage}`, {
        message: formData.message,
        fileUrl: formData.fileUrl,
        linkUrl: formData.linkUrl,
        linkText: formData.linkText,
      });

      toast({
        message: 'Test message sent successfully, please check your Telegram',
        variant: 'success',
      });
    } catch (error: any) {
      toastServerError(error);
    } finally {
      stopSubmittingTest();
    }
  };

  const handleOnSubmit = async (
    formData: PrivateMessageFormFields,
    options: { publish: boolean },
  ) => {
    setIsDirty(false);
    startSubmitting();

    const chatFilterValue = formData.chatFilter.map((filter) => ({
      direction: filter.direction,
      matching: filter.matching,
      labelIds: filter.labels.map((label) => label.value),
    }));

    const dataToSend = {
      ...formData,
      chatFilter: every(chatFilterValue, (obj) => isEmpty(obj.labelIds)) ? [] : chatFilterValue,
      startDate: formData.startDate || undefined,
    };

    try {
      const res =
        locationState?.mode === FormMode.Create
          ? await api.post<PrivateChatBulkMessage>(ApiKey.PrivateChatBulkMessage, dataToSend)
          : await api.patch<PrivateChatBulkMessage>(
              `${ApiKey.PrivateChatBulkMessage}/${locationState?.messageId}`,
              dataToSend,
            );

      let publishResponse;
      if (options.publish) {
        publishResponse = await api.post<PrivateChatBulkMessage>(
          `${ApiKey.PrivateChatBulkMessage}/publish`,
          {
            projectId: locationState?.projectId,
            id: res.data?.id,
          },
        );
      }

      await mutate(`${ApiKey.Project}/${locationState?.projectId}`);
      await mutate(
        `${ApiKey.PrivateChatBulkMessage}?projectId=${locationState?.projectId}`,
        async (currentGlobalData) => JSON.parse(JSON.stringify(currentGlobalData)),
        { revalidate: true },
      );

      toast({
        message: locationState?.mode === FormMode.Create ? 'Message created' : 'Message updated',
        variant: 'success',
      });

      if (options.publish) {
        toast({
          message: publishResponse?.data.error || 'Message published successfully',
          variant: publishResponse?.data.error ? 'error' : 'info',
        });
      }

      navigate('./..', { replace: true });
    } catch (error: any) {
      toastServerError(error);
    } finally {
      stopSubmitting();
    }
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    if (localStartDate === startDateValue || !startDateValue || !localStartDate) {
      setSelectedIndex(prevIndex); // Revert to previous selection if no new date is picked
    }
    setValue(PrivateMessageFormFieldsNames.StartDate, localStartDate);
    setOpen(false);
  };

  const BulkMessageChatFilterFormArrayFieldWrapper = useCallback(
    (props: FormArrayFieldProps) => (
      <BulkMessageChatFilterFormArrayField {...props} options={autoCompleteOptions} />
    ),
    [autoCompleteOptions],
  );

  if (!locationState) {
    navigateBack(); // This will navigate back if there is no project
    return null; // This prevents the rest of the component from rendering
  }

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        blockCloseAttempt={isSubmitting}
        showCloseButton={isMobile}
        title={locationState?.mode === FormMode.Create ? 'Create Message' : 'Edit Message'}
        label='create-new-project-dialog'
        maxWidth='sm'
        headerProps={{ zIndex: 1 }}
        customActions={
          <DialogActions
            sx={{
              p: 2,
              position: 'sticky',
              bottom: 0,
              bgcolor: 'background.paper',
              flexWrap: 'wrap-reverse',
              zIndex: 1,
              justifyContent: 'flex-end',
              '& > *': {
                ml: '0 !important',
              },
              gap: 1,
            }}
          >
            {!isMobile && (
              <Button
                size='large'
                color='inherit'
                onClick={() => {
                  navigate('./..', { replace: true });
                }}
              >
                cancel
              </Button>
            )}

            <TestBulkButton
              disabled={submittingTest || isSubmitting}
              submitting={submittingTest}
              onClick={handleSubmit(handleTestBulkMessage)}
            />

            <Popover
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              disablePortal
              onClose={handleClose}
            >
              <FormStaticDateTimePicker
                onAccept={(value) => {
                  setLocalStartDate(value);
                  setOpen(false);
                }}
                disablePast
                displayStaticWrapperAs='desktop'
                {...startDate}
              />
            </Popover>
            <Box ref={anchorRef}>
              <SplitButton
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                submitting={submitting}
                disabled={isSubmitting}
                options={[
                  {
                    title: 'Save as Draft',
                    onClick: () => {
                      resetDate();
                      handleSubmit((data) =>
                        handleOnSubmit(data, {
                          publish: false,
                        }),
                      )();
                    },
                    icon: chatGroupMessageStatusConfig[PrivateChatBulkMessageStatus.Draft].icon,
                  },
                  {
                    title: 'Send Instantly',
                    onClick: () => {
                      resetDate();
                      handleSubmit((data) =>
                        handleOnSubmit(data, {
                          publish: true,
                        }),
                      )();
                    },
                    icon: chatGroupMessageStatusConfig[PrivateChatBulkMessageStatus.Published].icon,
                  },
                  {
                    title: `Send in ${startDateValue ? formatShortTimeDifference(startDateValue) : ''}`,
                    menuTitle: !startDateValue ? 'Schedule Message' : 'Change Schedule',
                    onClick: () => {
                      handleSubmit((data) =>
                        handleOnSubmit(data, {
                          publish: true,
                        }),
                      )();
                    },
                    onOptionClick: () => {
                      setPrevIndex(selectedIndex);
                      setLocalStartDate(startDateValue);
                      setScheduleMessage(true);
                      setOpen(true);
                    },
                    icon: 'schedule_send',
                    menuIcon: 'schedule',
                  },
                ]}
              />
            </Box>
          </DialogActions>
        }
        open
      >
        <Stack width={1} gap={2}>
          <FormSection
            title='Recipients List'
            infoIcon={{
              tooltip:
                'Fill in the labels that the recipient must have to receive the message. If no labels are filled, the message will be sent to all recipients.',
              icon: 'info',
            }}
          >
            <FormArrayFields
              noAppend
              addButtonText='Add Object Property'
              FormArrayFieldComponent={BulkMessageChatFilterFormArrayFieldWrapper}
              {...chatFilter}
            />
            <Stack gap={1} width={1}>
              <List divider>
                <ListItem>
                  <FormSwitch
                    {...excludeArchived}
                    infoIcon={{
                      tooltip: 'Exclude archived recipients from the message',
                      icon: 'info',
                    }}
                  />
                </ListItem>
              </List>
              <Alert
                sx={{ width: 1, border: 1, borderColor: 'divider', borderRadius: 0.5 }}
                severity={chatCount === 0 ? 'warning' : 'info'}
                icon={false}
              >
                {queryMessage}, and will reach a total of <b>{chatCount}</b> recipient
                {chatCount !== 1 ? 's' : ''}.
              </Alert>
            </Stack>
          </FormSection>
          <FormSection title='Message'>
            <FormTextEditor
              mentions={defaultMentions}
              characterLimit={characterLimitValue}
              autoFocus={!isMobile}
              {...restMessageProps}
              placeholder={messagePlaceHolder}
            />
          </FormSection>
          <FormSection
            title='File/Image URL'
            infoIcon={{
              tooltip: (
                <Box>
                  Add a URL to an image/file publicly available
                  <br /> For images you can use{' '}
                  <Link
                    sx={{
                      textDecoration: 'underline',
                    }}
                    color='inherit'
                    target='_blank'
                    href='https://postimages.org/'
                    rel='noreferrer'
                  >
                    postimage
                  </Link>
                </Box>
              ),
              icon: 'info',
            }}
            cta={{
              icon: 'add_photo_alternate',
              tooltip: 'Open PostImage to upload an image or file',
              onClick: () => {
                window.open('https://postimages.org/', '_blank');
              },
            }}
          >
            <Stack direction='row' height={1} alignItems='center' gap={1}>
              <FormTextField size='small' autoFocus={!isMobile} {...fileUrl} />
              {fileUrlValue && !fileUrlError && <ImagePreview size={40} imageUrl={fileUrlValue} />}
            </Stack>
          </FormSection>

          <FormSection
            title='Link Button'
            infoIcon={{
              tooltip: 'Add Link Button to Message to redirect to a specific URL',
              icon: 'info',
            }}
          >
            <Stack direction='row' gap={1}>
              <FormTextField size='small' {...linkUrl} />
              <FormTextField size='small' {...linkText} />
            </Stack>
          </FormSection>
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default BulkMessageFormModal;
