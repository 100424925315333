import { array, number, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';
import {
  QuizSummaryFormFields,
  QuizSummaryFormFieldsMenuNames,
  QuizSummaryFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/summary/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';
import { editorValidationOptional, emptyEditorValue, variableValidation } from 'utils';

export const quizSummaryFormSchema: ObjectSchema<QuizSummaryFormFields> = object({
  [QuizSummaryFormFieldsNames.NodeId]: string().required(),
  [QuizSummaryFormFieldsNames.Name]: string().required('Name is required'),
  [QuizSummaryFormFieldsNames.Variable]: variableValidation,
  [QuizSummaryFormFieldsNames.Input]: editorValidationOptional,
  [QuizSummaryFormFieldsNames.PassScore]: number()
    .min(0)
    .max(100)
    .required('Pass Score is required'),
  [QuizSummaryFormFieldsNames.Menu]: array(
    object({
      [QuizSummaryFormFieldsMenuNames.Option]: string().required('Option is required'),
      [QuizSummaryFormFieldsMenuNames.NodeId]: string().required('Node is required'),
    }),
  ).required(),
  [QuizSummaryFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const quizSummaryFormValues: QuizSummaryFormFields = {
  [QuizSummaryFormFieldsNames.Name]: 'Announce Quiz Winner',
  [QuizSummaryFormFieldsNames.Input]: emptyEditorValue,
  [QuizSummaryFormFieldsNames.NodeId]: '',
  [QuizSummaryFormFieldsNames.PassScore]: 1,
  [QuizSummaryFormFieldsNames.Menu]: [],
  [QuizSummaryFormFieldsNames.Labels]: [],
};

export const QuizSummaryFieldsConfig: Record<QuizSummaryFormFieldsNames, FormFieldProps> = {
  [QuizSummaryFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: QuizSummaryFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [QuizSummaryFormFieldsNames.Input]: {
    placeholder: 'Provide a general message to the user (optional)',
    name: QuizSummaryFormFieldsNames.Input,
    type: 'textarea',
  },
  [QuizSummaryFormFieldsNames.Variable]: {
    placeholder: 'Variable',
    name: QuizSummaryFormFieldsNames.Variable,
    autoComplete: 'off',
    type: 'text',
  },
  [QuizSummaryFormFieldsNames.Menu]: {
    label: 'Menu',
    placeholder: 'Menu',
    name: QuizSummaryFormFieldsNames.Menu,
    autoComplete: 'off',
    type: 'text',
  },
  [QuizSummaryFormFieldsNames.PassScore]: {
    placeholder: 'Between 0 and The number of questions',
    name: QuizSummaryFormFieldsNames.PassScore,
    autoComplete: 'off',
    type: 'number',
  },
  [QuizSummaryFormFieldsNames.NodeId]: {
    name: QuizSummaryFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [QuizSummaryFormFieldsNames.Labels]: labelFormFieldConfig,
};
