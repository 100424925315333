import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import Icon from 'components/Icon';
import { useMount } from 'hooks';

interface SuccessMessageProps {
  title: string;
  description: ReactNode;
  triggerConfetti?: boolean;
  icon?: string;
  iconColor?: string;
}

const SuccessMessage = ({
  title,
  description,
  triggerConfetti = true,
  icon = 'check_circle',
  iconColor = 'success',
}: SuccessMessageProps) => {
  const theme = useTheme();
  const flagInitTrigger = useRef(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useMount(() => {
    if (triggerConfetti && !flagInitTrigger.current) {
      setShowConfetti(true);
    }
    flagInitTrigger.current = true;
  });

  useEffect(() => {
    if (triggerConfetti && flagInitTrigger.current) {
      setShowConfetti(true);
      flagInitTrigger.current = false;
    }
  }, [triggerConfetti]);

  return (
    <Stack alignItems='center' pb={2}>
      <Box mb={2}>
        {showConfetti && (
          <ConfettiExplosion
            force={0.6}
            duration={4000}
            particleCount={150}
            zIndex={theme.zIndex.modal + 1}
            onComplete={() => setShowConfetti(false)}
          />
        )}
        <Icon icon={icon} fontSize='large' color={iconColor} />
      </Box>
      <Typography variant='h5' align='center'>
        {title}
      </Typography>
      {description}
    </Stack>
  );
};

export default SuccessMessage;
