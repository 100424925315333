import { array, number, object, ObjectSchema, string } from 'yup';
import { labelFormFieldConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/utils';

import {
  WaitForTimeStepFormFields,
  WaitForTimeStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/wait-for-time/types';

import { AutoCompleteLabel, FormFieldProps } from 'types';

export const waitForTimeStepFormSchema: ObjectSchema<WaitForTimeStepFormFields> = object({
  [WaitForTimeStepFormFieldsNames.NodeId]: string().required(),
  [WaitForTimeStepFormFieldsNames.Name]: string().required('Name is required'),
  [WaitForTimeStepFormFieldsNames.Minutes]: number().min(0).max(59).required('Minutes is required'),
  [WaitForTimeStepFormFieldsNames.Hours]: number().min(0).max(23).required('Hours is required'),
  [WaitForTimeStepFormFieldsNames.Days]: number().min(0).required('Days is required'),
  [WaitForTimeStepFormFieldsNames.Labels]: array<AutoCompleteLabel[]>().optional(),
});

export const defaultWaitForTimeStepFormValues: Partial<WaitForTimeStepFormFields> = {
  [WaitForTimeStepFormFieldsNames.NodeId]: '',
  [WaitForTimeStepFormFieldsNames.Name]: '',
  [WaitForTimeStepFormFieldsNames.Minutes]: 1,
  [WaitForTimeStepFormFieldsNames.Hours]: 0,
  [WaitForTimeStepFormFieldsNames.Days]: 0,
  [WaitForTimeStepFormFieldsNames.Labels]: [],
};

export const waitForFormFieldsConfig: Record<WaitForTimeStepFormFieldsNames, FormFieldProps> = {
  [WaitForTimeStepFormFieldsNames.Name]: {
    label: 'Name',
    placeholder: 'Name',
    name: WaitForTimeStepFormFieldsNames.Name,
    autoComplete: 'off',
    type: 'text',
  },
  [WaitForTimeStepFormFieldsNames.NodeId]: {
    name: WaitForTimeStepFormFieldsNames.NodeId,
    type: 'hidden',
  },
  [WaitForTimeStepFormFieldsNames.Minutes]: {
    label: 'Minutes',
    placeholder: 'Minutes',
    name: WaitForTimeStepFormFieldsNames.Minutes,
    autoComplete: 'off',
    type: 'number',
  },
  [WaitForTimeStepFormFieldsNames.Hours]: {
    label: 'Hours',
    placeholder: 'Hours',
    name: WaitForTimeStepFormFieldsNames.Hours,
    autoComplete: 'off',
    type: 'number',
  },
  [WaitForTimeStepFormFieldsNames.Days]: {
    label: 'Days',
    placeholder: 'Days',
    name: WaitForTimeStepFormFieldsNames.Days,
    autoComplete: 'off',
    type: 'number',
  },
  [WaitForTimeStepFormFieldsNames.Labels]: labelFormFieldConfig,
};
