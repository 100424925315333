import { object, ObjectSchema, string } from 'yup';
import { FormFieldProps } from 'types';
import { CouponFormFields, CouponFormFieldsNames } from './types';

export const couponSchema: ObjectSchema<CouponFormFields> = object({
  [CouponFormFieldsNames.Coupon]: string().trim().required('Please enter a coupon code'),
});

export const defaultCouponFormValues: CouponFormFields = {
  [CouponFormFieldsNames.Coupon]: '',
};

export const couponFormFieldsConfig: Record<CouponFormFieldsNames, FormFieldProps> = {
  [CouponFormFieldsNames.Coupon]: {
    label: 'Coupon Code',
    placeholder: 'Enter your coupon code',
    name: CouponFormFieldsNames.Coupon,
    autoComplete: 'off',
    type: 'text',
  },
};
