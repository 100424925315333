import { Stack, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { formatDistanceToNowStrict } from 'date-fns';
import Icon from 'components/Icon';
import useMessageStatus from 'pages/app-pages/messages-scheduler-page/useMessageStatus';
import { ChatGroupMessage } from 'types';

function StatusCell({ row }: GridRenderCellParams<ChatGroupMessage>) {
  const { isDraftMessage, isExpiredMessage, isMessageSent, isFutureMessage } =
    useMessageStatus(row);

  const getStatusIcon = () => {
    if (isExpiredMessage) {
      return (
        <Tooltip
          title={`Message expired ${
            row.deliveryDate
              ? formatDistanceToNowStrict(new Date(row.deliveryDate), {
                  addSuffix: true,
                })
              : ''
          } and was not sent`}
          placement='top'
        >
          <Icon color='error' icon='cancel' />
        </Tooltip>
      );
    }
    if (isDraftMessage) {
      return (
        <Tooltip placement='top' title='Message is in draft mode'>
          <Icon color='warning' icon='draft_orders' />
        </Tooltip>
      );
    }
    if (isMessageSent) {
      return (
        <Tooltip
          title={`Message was sent ${
            row.sendDate
              ? formatDistanceToNowStrict(new Date(row.sendDate), {
                  addSuffix: true,
                })
              : ''
          } `}
          placement='top'
        >
          <Icon color='success' icon='check_circle' />
        </Tooltip>
      );
    }
    if (isFutureMessage) {
      return (
        <Tooltip
          placement='top'
          title={`Message will be sent ${
            row.startDate
              ? formatDistanceToNowStrict(new Date(row.startDate), {
                  addSuffix: true,
                })
              : ''
          }`}
        >
          <Icon color='info' icon='schedule' />
        </Tooltip>
      );
    }
    return '';
  };

  return (
    <Stack position='relative' alignItems='center' gap={1}>
      {getStatusIcon()}
    </Stack>
  );
}

export default StatusCell;
