import { useEffect } from 'react';
import useHotjar from 'react-use-hotjar';
import { useAuth } from './useAuth';

// eslint-disable-next-line no-console
const myCustomLogger = console.log;
export const useHotjarIdentify = () => {
  const { identifyHotjar, readyState } = useHotjar();

  const { user } = useAuth();

  useEffect(() => {
    if (!readyState) {
      return;
    }
    if (user && identifyHotjar) {
      const { id, organization, telegram, ...restUserInfo } = user;
      identifyHotjar(id, restUserInfo, myCustomLogger);
    }
  }, [identifyHotjar, readyState, user]);
};
