import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import { Image } from 'mui-image';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useBoolean } from 'usehooks-ts';
import { BulletWrapper, CopyText, Icon } from '@common-components';
import { ApiKey } from 'api';
import addToGroupGif from 'assets/images/telegram/screenshots/add_to_group.gif';
import { Project } from 'types';

const TutorialStep = () => {
  const { id } = useParams();

  const { value: showTutorial, toggle: toggleShowTutorial } = useBoolean(false);
  const { data: project } = useSWR<Project>(`${ApiKey.Project}/${id}`);

  return (
    <Stack flex={1} gap={1} alignItems='flex-start'>
      <Typography variant='h6' gutterBottom>
        1. Add The Bot To A Group
      </Typography>
      <BulletWrapper>
        <Typography variant='body2'>
          Open Telegram and go to the channel or group where you want to add the bot.
        </Typography>
      </BulletWrapper>
      <BulletWrapper>
        <Typography variant='body2'>
          In your channel or group&apos;s info section, tap on the &quot;Add Member&quot; icon
          (usually represented by a &quot;Add+&quot; symbol).
        </Typography>
      </BulletWrapper>
      <BulletWrapper>
        <Typography variant='body2'>
          Search for your bot by its username{' '}
          <CopyText color='info.darker' bold text={`@${project?.botUsername}` || ''} /> and select
          it to add to the group.
        </Typography>
      </BulletWrapper>
      <Button onClick={toggleShowTutorial} endIcon={<Icon icon='play_arrow' fontSize='small' />}>
        <Box
          component='span'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {showTutorial ? 'Hide Tutorial' : 'View Tutorial'}
        </Box>
      </Button>
      <Collapse in={showTutorial} timeout='auto'>
        <Image width='100%' duration={1000} src={addToGroupGif} />
      </Collapse>
    </Stack>
  );
};

export default TutorialStep;
