enum StepType {
  FreeQuestion = 'FreeQuestion',
  OptionsQuestion = 'OptionsQuestion',
  OptionsMenu = 'OptionsMenu',
  YesNo = 'YesNo',
  Navigate = 'Navigate',
  Invite = 'Invite',
  Message = 'Message',
  File = 'File',
  WaitForTime = 'WaitForTime',
  RandomSplit = 'RandomSplit',
  Email = 'Email',
  Contact = 'Contact',
  Location = 'Location',
  SendData = 'SendData',
  SendEmail = 'SendEmail',
  SendTelegramNotification = 'SendTelegramNotification',
  HumanHandoff = 'HumanHandoff',
  QuizOpenQuestion = 'QuizOpenQuestion',
  QuizMultipleChoiceQuestion = 'QuizMultipleChoiceQuestion',
  QuizTrueFalseQuestion = 'QuizTrueFalseQuestion',
  QuizSummary = 'QuizSummary',
}

export default StepType;
