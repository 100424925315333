import { Suspense, useEffect } from 'react';
import { Await, useLoaderData, useLocation, useOutlet, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { AppLoader } from '@common-components';
import { LocalStorageKey } from 'config';
import { AuthProvider, useMount, useScrollToTop, useUnsavedChangesWarning } from 'hooks';
import { useDirtyStore, useHistoryLength } from 'store';
import { User } from 'types';
import ErrorPage from './ErrorPage';

const AuthLayout = () => {
  const outlet = useOutlet();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [, setShowCoupon] = useLocalStorage(LocalStorageKey.ShowCoupon, false);

  useMount(() => {
    if (searchParams.get('coupon')) {
      setShowCoupon(true);
    }
  });

  const { incrementHistoryLength } = useHistoryLength((state) => ({
    incrementHistoryLength: state.increment,
  }));

  useUnsavedChangesWarning();

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));
  useEffect(() => {
    setIsDirty(false);
  }, [location, setIsDirty]);

  useEffect(() => {
    incrementHistoryLength();
  }, [incrementHistoryLength, location.pathname]);

  useScrollToTop();

  const { userPromise } = useLoaderData() as { userPromise: () => Promise<User> };

  return (
    <Suspense fallback={<AppLoader />}>
      <Await resolve={userPromise} errorElement={<ErrorPage />}>
        {(user) => <AuthProvider userData={user}>{outlet}</AuthProvider>}
      </Await>
    </Suspense>
  );
};

export default AuthLayout;
