import { Stack, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import QuillContentDisplay from 'components/QuillContentDisplay';
import { useMenu } from 'hooks';
import NoteForm from 'pages/activity-page/note/NoteForm';
import { Chat } from 'types';

const ActivityNote = ({ row }: GridRenderCellParams<Chat>) => {
  const { openMenu, ...restMenuProps } = useMenu();

  return (
    <Stack height={1} justifyContent='center' width={1} overflow='hidden'>
      <Stack
        justifyContent='center'
        height={1}
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          openMenu(e);
        }}
      >
        {row.note ? (
          <Tooltip title={<QuillContentDisplay content={row.note} />} placement='top' arrow>
            <Stack>
              <QuillContentDisplay content={row.note} height={70} />
            </Stack>
          </Tooltip>
        ) : (
          <Typography noWrap variant='body2' fontStyle='italic' color='text.secondary'>
            Add Note...
          </Typography>
        )}
      </Stack>
      {restMenuProps.isMenuOpen && (
        <NoteForm menuProps={restMenuProps} defaultNote={row.note} chatId={row.id || ''} />
      )}
    </Stack>
  );
};

export default ActivityNote;
