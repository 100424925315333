import { alpha, ButtonBase, StackProps } from '@mui/material';
import { isBasicColorValid } from 'theme/utils';

export const stepSelectorItemProps = ({
  color,
  clickable,
  selected,
}: {
  color: string;
  clickable?: boolean;
  selected?: boolean;
}): Partial<StackProps> => ({
  sx: {
    backgroundColor: (theme) =>
      alpha(isBasicColorValid(color) ? theme.palette[color].light : color, 0.16),
    border: 1,
    borderRadius: 1,
    borderColor: 'transparent',
    p: 1,
    width: 1,
    gap: 1,
    cursor: 'pointer',
    ...(clickable && {
      transition: (theme) =>
        theme.transitions.create(['background-color', 'box-shadow'], {
          duration: theme.transitions.duration.shortest,
        }),
      '&:hover': {
        boxShadow: (theme) => theme.shadows[4],
      },
    }),
    ...(selected && {
      borderColor: (theme) =>
        alpha(isBasicColorValid(color) ? theme.palette[color].light : color, 0.24),
    }),
  },
  component: ButtonBase,
});
