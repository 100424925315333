import { Link, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { FormCheckBox, FormTextField } from '@common-components';
import { api, ApiKey } from 'api';
import { useAuth, useFormProvider, useServerError } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import { SignUpFormFields } from 'pages/auth-pages/sign-up/types';
import {
  defaultSignUpFormValues,
  signUpFormFieldsConfig,
  signUpSchema,
} from 'pages/auth-pages/sign-up/utils';
import { useDirtyStore } from 'store';

interface SignUpFormProps {
  defaultValues?: Partial<SignUpFormFields>;
}

const SignUpForm = ({ defaultValues = defaultSignUpFormValues }: SignUpFormProps) => {
  const { login } = useAuth();
  const toastServerError = useServerError();

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  const formMethods = useFormProvider<SignUpFormFields>({
    schema: signUpSchema,
    defaultValues,
  });

  const { handleSubmit, formState } = formMethods;

  const handleOnSubmit = async (formData: SignUpFormFields) => {
    setIsDirty(false);
    try {
      const response = await api.post(ApiKey.UserRegister, formData);
      login?.({
        ...response.data,
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  const { email, password, agreeToTerms } = signUpFormFieldsConfig;
  const { label, ...restTerms } = agreeToTerms;

  return (
    <FormProvider {...formMethods}>
      <Stack width={1} gap={2} component='form' noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <FormTextField size='small' {...email} />
        <Stack width={1} gap={1}>
          <FormTextField size='small' {...password} />
          <Link
            variant='subtitle2'
            href='https://t.me/TeleformSupportBot'
            target='_blank'
            underline='none'
          >
            Forgot password?
          </Link>
        </Stack>
        <Stack width={1}>
          <FormCheckBox
            label={
              <>
                I agree to the{' '}
                <Link
                  target='_blank'
                  href='https://www.teleform.io/terms-conditions.html'
                  rel='noreferrer'
                >
                  Terms of Service{' '}
                </Link>{' '}
                and
                <Link
                  target='_blank'
                  href='https://www.teleform.io/privacy-policy.html'
                  rel='noreferrer'
                >
                  {' '}
                  Privacy Policy.
                </Link>
              </>
            }
            {...restTerms}
          />
          <SubmitButton
            sx={{
              py: 2,
            }}
            size='medium'
            variant='contained'
            color='primary'
            fullWidth
            type='submit'
            submitting={formState.isSubmitting}
          >
            Sign Up
          </SubmitButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default SignUpForm;
