import {
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon, Label } from '@common-components';
import avatar from 'assets/images/avatar_placeholder.png';
import { planConfig, roleConfig } from 'config';
import { AccountPlanType, RoutePath } from 'enums';
import { useAuth, useMenu } from 'hooks';
import { BasicInfoColors } from 'theme';
import { menuMinWidth } from 'theme/utils';
import { User, UserLimitations } from 'types';
import { calculateChargeLevelIcon, calculateColorTemperature, truncateMultiLineText } from 'utils';
import AvatarIcon from './AvatarIcon';

const calculateUsedPercentages = (limitations: UserLimitations): number[] =>
  Object.keys(limitations).map((key) => {
    const limitation = limitations[key as keyof UserLimitations];
    return (limitation.used / limitation.total) * 100;
  });

const determineOverallStatusColor = (
  user?: User,
): {
  color: BasicInfoColors;
  icon: string;
} => {
  if (!user) {
    return {
      color: 'success',
      icon: 'battery_full_alt',
    };
  }
  const usagePercentages = () => {
    if (!user.organization?.limitations) {
      return [0, 0, 0];
    }
    return calculateUsedPercentages(user.organization?.limitations);
  };

  // Determine the highest usage percentage
  const maxUsagePercentage = Math.max(...usagePercentages());

  // Use the calculateColor function to determine the color based on the highest usage percentage
  return {
    color: calculateColorTemperature(maxUsagePercentage),
    icon: calculateChargeLevelIcon(maxUsagePercentage),
  };
};

function UserAvatar() {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useMenu();
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  if (!user) {
    return null;
  }

  const plan = user?.organization?.accountPlan?.type || AccountPlanType.Basic;

  const { fullName = 'Logged-in User', email, profileImageUrl = avatar } = user;

  return (
    <>
      <Tooltip title='Account'>
        <IconButton
          onClick={openMenu}
          size='small'
          aria-controls={isMenuOpen ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={isMenuOpen ? 'true' : undefined}
        >
          <Badge
            variant='dot'
            color={determineOverallStatusColor(user).color}
            overlap='circular'
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <AvatarIcon fullName={fullName} profileImageUrl={profileImageUrl} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id='user-menu'
        aria-labelledby='user-menu-button'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth } },
        }}
      >
        <MenuItem
          disableRipple
          sx={{
            '&:hover': {
              bgcolor: 'transparent',
              cursor: 'default',
            },
          }}
        >
          <Stack gap={1} direction='row'>
            <AvatarIcon fullName={fullName} profileImageUrl={profileImageUrl} />
            <Stack>
              <Stack direction='row' alignItems='center' gap={1} width={1}>
                <Typography
                  color='text.primary'
                  sx={{
                    ...truncateMultiLineText(1),
                  }}
                  variant='subtitle2'
                >
                  {fullName}
                </Typography>
                <Label color={planConfig[plan].color}>{planConfig[plan].name}</Label>
              </Stack>

              <Typography noWrap variant='caption'>
                {email}
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate(RoutePath.ManageAccount);
          }}
        >
          <ListItemIcon>
            <Icon icon='account_circle' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Manage Account</ListItemText>
          <Icon
            icon={determineOverallStatusColor(user).icon}
            fontSize='small'
            color={determineOverallStatusColor(user).color}
          />
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => {
            closeMenu();
            navigate(RoutePath.Organization);
          }}
        >
          <ListItemIcon>
            <Icon icon='corporate_fare' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Organization</ListItemText>
          <Label color={roleConfig[user.isOwner ? 'Owner' : user.role].color}>
            {roleConfig[user.isOwner ? 'Owner' : user.role].shortName}
          </Label>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => {
            closeMenu();
            window.open('https://www.teleform.io/terms-conditions.html', '_blank');
          }}
        >
          <ListItemIcon>
            <Icon icon='gavel' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Terms</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            window.open('https://www.teleform.io/privacy-policy.html', '_blank');
          }}
        >
          <ListItemIcon>
            <Icon icon='lock' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Privacy</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            closeMenu();
            logout?.();
          }}
        >
          <ListItemIcon>
            <Icon icon='logout' color='error' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserAvatar;
