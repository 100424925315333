import { api, ApiKey } from 'api';
import { User } from 'types';

export const getUserData = async () => {
  try {
    const response = await api.get<User>(ApiKey.User);
    return {
      ...response.data,
    };
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error?.message);
    return null; // Return null when there's an error
  }
};
