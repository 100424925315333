import { Box } from '@mui/material';
import { AppLogo as AppLogoSvg } from 'assets/images';

function AppBetaLogo({ height = 28 }: { height?: number }) {
  return (
    <Box
      height={height}
      width={height * 6}
      sx={{
        '& svg': {
          width: 1,
          height: 1,
        },
      }}
    >
      <AppLogoSvg />
    </Box>
  );
}

export default AppBetaLogo;
