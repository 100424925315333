import { Box, Button, Stack } from '@mui/material';
import { DefaultValues, FieldValues, FormState } from 'react-hook-form';
import { SubmitButton } from 'pages/auth-pages/components';

interface StepFormSubmitButtonsProps<T extends FieldValues> {
  formState: FormState<T>;
  reset: (values?: DefaultValues<T>) => void;
  initiallyEnabled?: boolean;
}
const StepFormSubmitButtons = <T extends FieldValues>({
  formState,
  reset,
  initiallyEnabled,
}: StepFormSubmitButtonsProps<T>) => (
  <Stack direction='row' gap={1} px={2} justifyContent='flex-end'>
    <Button
      size='large'
      disabled={(formState.isSubmitting || !formState.isDirty) && !initiallyEnabled}
      onClick={() => reset()}
      color='inherit'
    >
      <Box
        component='span'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        Reset
      </Box>
    </Button>
    <SubmitButton
      variant='contained'
      type='submit'
      color='secondary'
      disabled={!formState.isDirty && !initiallyEnabled}
      submitting={formState.isSubmitting}
    >
      Save Step
    </SubmitButton>
  </Stack>
);

export default StepFormSubmitButtons;
