import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormSwitch, FormTextFieldInlineEdit, List, ListItem } from '@common-components';

import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';

import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { MessageStepFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/message/types';
import {
  defaultMessageStepFormValues,
  messageFormFieldsConfig,
  messageStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/message/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface MessageProps {
  id: string;
  defaultValues?: Partial<MessageStepFormFields>;
  defaultName: string;
  mentions?: Mention[];
  commands?: Mention[];
}

const Message = ({ defaultValues, mentions, commands, id, defaultName }: MessageProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultMessageStepFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<MessageStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<MessageStepFormFields>({
    schema: messageStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);
  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };
  const handleOnSubmit = async (formData: MessageStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, disablePreviewLink, input, labels } = messageFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
        <List divider label='Aditional Options'>
          <ListItem>
            <FormSwitch
              {...disablePreviewLink}
              infoIcon={{
                tooltip: 'Disable the preview link feature for this message on Telegram',
                icon: 'info',
              }}
            />
          </ListItem>
        </List>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(Message);
