import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useBoolean } from 'usehooks-ts';
import { BasicModal, ConfirmationDialog, SuccessMessage } from '@common-components';
import { api, ApiKey } from 'api';
import { useAuth, useIsMobile, useMenu, useServerError } from 'hooks';
import { SubmitButton } from 'pages/auth-pages/components';
import { User } from 'types';

const AcceptInvitationModal = () => {
  const navigate = useNavigate();
  const toastServerError = useServerError();
  const { setUser, user } = useAuth();

  const {
    value: isIsAcceptingInvitation,
    setTrue: setIsAcceptingInvitation,
    setFalse: setIsNotAcceptingInvitation,
  } = useBoolean(false);

  const {
    value: isDecliningInvitation,
    setTrue: setIsDecliningInvitation,
    setFalse: setIsNotDecliningInvitation,
  } = useBoolean(false);

  const { isSmallMobile } = useIsMobile();

  const { openMenu: openDeletePrompt, ...restDeclinePrompt } = useMenu();

  const handleSubmit = async (state: boolean) => {
    try {
      const res = await api.post<User>(`${ApiKey.Users}/${ApiKey.OrganizationDecision}`, {
        acceptOrganization: state,
      });
      setIsNotAcceptingInvitation();
      setIsNotDecliningInvitation();
      setUser?.(res.data);
      navigate('./..', { replace: true });
    } catch (error: any) {
      setIsNotAcceptingInvitation();
      setIsNotDecliningInvitation();
      toastServerError(error);
    }
  };

  return (
    <BasicModal
      cancelButton={false}
      disableEscapeKeyDown
      label='ai_prompt-dialog'
      maxWidth='sm'
      submittingText='Submitting...'
      blockCloseAttempt
      open
    >
      <Stack width={1} mt={2}>
        <SuccessMessage
          title={`Invitation to Join Teleform by ${user?.pendingOrganizationName || user?.pendingOrganizationOwnerEmail}`}
          triggerConfetti={false}
          icon='person_add'
          description={
            <Stack gap={3}>
              <Typography variant='body1' align='center'>
                {`The organization ${user?.pendingOrganizationName || user?.pendingOrganizationOwnerEmail} has invited you to join Teleform.`}
                <br />
                Please accept your invitation to get started.
              </Typography>
              <Stack
                direction={{ xs: 'column-reverse', sm: 'row' }}
                alignItems='center'
                justifyContent='center'
                gap={2}
              >
                <SubmitButton
                  fullWidth={isSmallMobile}
                  variant='outlined'
                  color='error'
                  onClick={openDeletePrompt}
                  submitting={isDecliningInvitation}
                >
                  Decline
                </SubmitButton>
                <SubmitButton
                  variant='contained'
                  fullWidth={isSmallMobile}
                  color='primary'
                  onClick={async () => {
                    setIsAcceptingInvitation();
                    await handleSubmit(true);
                  }}
                  submitting={isIsAcceptingInvitation}
                >
                  Accept Invitation
                </SubmitButton>
              </Stack>
            </Stack>
          }
        />
      </Stack>
      <ConfirmationDialog
        text='Are you sure you want to decline this invitation?'
        onConfirm={async () => {
          setIsDecliningInvitation();
          await handleSubmit(false);
        }}
        submittingText='Deleting...'
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        {...restDeclinePrompt}
      />
    </BasicModal>
  );
};

export default AcceptInvitationModal;
