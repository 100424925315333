export enum MessagePosition {
  Left,
  Right,
}

export enum MessageOrder {
  First,
  Middle,
  Last,
  Only,
}
