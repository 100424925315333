import { drawerClasses, SwipeableDrawerProps } from '@mui/material';

export const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const toolboxDrawerConfig = ({
  isMobile,
  appBarHeight,
}: {
  isMobile: boolean;
  appBarHeight: number;
}): Partial<SwipeableDrawerProps> => ({
  hideBackdrop: !isMobile,
  variant: isMobile ? 'temporary' : 'persistent',
  anchor: isMobile ? 'bottom' : 'right',
  sx: {
    [`& .${drawerClasses.paper}`]: {
      mt: isMobile ? 0 : `${appBarHeight}px`,
      boxSizing: 'border-box',
      width: isMobile ? 1 : 360,
      height: isMobile ? 0.8 : `calc(100% - ${appBarHeight}px)`,
      maxWidth: 1,
      boxShadow: (theme) => theme.shadows[12],
      borderTopLeftRadius: 12,
      borderTopRightRadius: isMobile ? 12 : 0,
      borderBottomLeftRadius: isMobile ? 0 : 12,
      border: 'none',
    },
  },
  ModalProps: {
    keepMounted: true,
  },
});
