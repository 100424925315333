import { memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormTextFieldInlineEdit } from '@common-components';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import ConnectWithTelegram from 'pages/app-pages/chatbot-builder-page/nodes/components/ConnectWithTelegram';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';

import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import { SendTelegramNotificationStepFormFields } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-telegram-notification/types';
import {
  defaultTelegramNotificationStepStepFormValues,
  telegramNotificationFormFieldsConfig,
  telegramNotificationStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/send-telegram-notification/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface SendTelegramNotificationProps {
  id: string;
  defaultValues?: Partial<SendTelegramNotificationStepFormFields>;
  defaultName: string;
  mentions?: Mention[];
  commands?: Mention[];
}

const SendTelegramNotification = ({
  defaultValues,
  mentions,
  commands,
  id,
  defaultName,
}: SendTelegramNotificationProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultTelegramNotificationStepStepFormValues,
    ...defaultValues,
    name: defaultValues?.name || defaultName,
    nodeId: defaultValues?.nodeId || uuid(),
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<SendTelegramNotificationStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<SendTelegramNotificationStepFormFields>({
    schema: telegramNotificationStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(mentions);

  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: SendTelegramNotificationStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
    });
  };

  const { name, labels, input } = telegramNotificationFormFieldsConfig;

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <ConnectWithTelegram />
        <NodesFormTextEditor
          characterLimit={characterLimitValue}
          autoFocus={!isMobile}
          {...input}
          mentions={localMentions.current}
          commands={localCommands.current}
        />
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(SendTelegramNotification);
