import { useMediaQuery, useTheme } from '@mui/material';
import { extractNumberFromMediaQuery } from 'utils';

export const useModalSize = () => {
  const theme = useTheme();

  const xl = useMediaQuery(theme.breakpoints.down('xl'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const size = () => {
    if (xs) {
      return extractNumberFromMediaQuery(theme.breakpoints.down('xs'));
    }
    if (sm) {
      return extractNumberFromMediaQuery(theme.breakpoints.down('sm'));
    }
    if (md) {
      return extractNumberFromMediaQuery(theme.breakpoints.down('md'));
    }
    if (lg) {
      return extractNumberFromMediaQuery(theme.breakpoints.down('lg'));
    }
    return extractNumberFromMediaQuery(theme.breakpoints.down('xl'));
  };

  return { size: size(), fullScreen, xs, sm, md, lg, xl };
};
