import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useGetUserLabels } from 'hooks';
import useMessageStatus from 'pages/app-pages/private-bulk-message-page/useMessageStatus';
import { generateMessage } from 'pages/app-pages/private-bulk-message-page/utils';

import { PrivateChatBulkMessage } from 'types';

function QueryCell({ row }: GridRenderCellParams<PrivateChatBulkMessage>) {
  const { isMessageSent } = useMessageStatus(row);
  const { labels } = useGetUserLabels();

  const message = generateMessage(!isMessageSent, row.chatFilter, labels, row.excludeArchived);

  return (
    <Stack
      sx={{
        whiteSpace: 'normal',
      }}
    >
      <Typography variant='caption'>{message}</Typography>
    </Stack>
  );
}

export default QueryCell;
