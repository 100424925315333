import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import React, { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import { ObjectWithRequiredProp } from 'utils';

export interface FormSelectProps<T> extends Omit<SelectProps, 'id'> {
  name: string;
  confirmCallBack?: () => void;
  options: T[];
  isOpen?: boolean;
  nonEmpty?: boolean;
}

export const FormSelect = <T extends string | ObjectWithRequiredProp<'value' | 'label', string>>(
  props: FormSelectProps<T>,
) => {
  const {
    name,
    type,
    isOpen = false,
    multiple,
    nonEmpty,
    confirmCallBack,
    label,
    options,
    ...rest
  } = props;
  const { control } = useFormContext();

  const [menuOpen, setMenuOpen] = useState(isOpen);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, onBlur, ...field }, fieldState: { error } }) => (
        <FormControl className='notranslate' sx={{ width: 1 }}>
          {label && <InputLabel id={name}>{label}</InputLabel>}
          <Select
            {...field}
            {...rest}
            inputRef={ref}
            error={error !== undefined}
            fullWidth
            open={menuOpen}
            onClose={() => {
              setMenuOpen(false);
              confirmCallBack?.();
            }}
            id={name}
            multiple={multiple}
            label={label}
            onChange={(e) => {
              const selectedOption = options.find((option) => {
                const value = typeof option === 'string' ? option : option.value;
                return value === e.target.value;
              });
              const valueToPass =
                selectedOption && typeof selectedOption === 'object'
                  ? selectedOption.value
                  : e.target.value;
              onChange(valueToPass);
              confirmCallBack?.();
            }}
            onBlur={() => {
              onBlur();
              confirmCallBack?.();
            }}
            onFocus={() => {
              if (menuOpen) {
                setMenuOpen(true);
              }
            }}
            onOpen={() => {
              setMenuOpen(true);
            }}
          >
            {!multiple && !nonEmpty && (
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
            )}
            {options.map((option) => {
              const optionValue = typeof option === 'string' ? option : option.value;
              const optionLabel = typeof option === 'string' ? option : option.label;
              return (
                <MenuItem key={optionValue} value={optionValue}>
                  {optionLabel}
                </MenuItem>
              );
            })}
          </Select>
          <FormCollapseHelperText error={error} />
        </FormControl>
      )}
    />
  );
};

export default memo(FormSelect);
