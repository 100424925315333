import { boolean, object, ObjectSchema } from 'yup';
import { LoginFormFields, LoginFormFieldsNames } from 'pages/auth-pages/login/types';
import { FormFieldProps } from 'types';
import { yupPasswordValidation, yupRequiredEmailValidation } from 'utils';

export const loginSchema: ObjectSchema<LoginFormFields> = object({
  [LoginFormFieldsNames.Email]: yupRequiredEmailValidation,
  [LoginFormFieldsNames.Password]: yupPasswordValidation,
  [LoginFormFieldsNames.RememberMe]: boolean().optional(),
});

export const defaultLoginFormValues: LoginFormFields = {
  [LoginFormFieldsNames.Email]: '',
  [LoginFormFieldsNames.Password]: '',
  [LoginFormFieldsNames.RememberMe]: false,
};

export const loginFormFieldsConfig: Record<LoginFormFieldsNames, FormFieldProps> = {
  [LoginFormFieldsNames.Email]: {
    placeholder: 'Email',
    name: LoginFormFieldsNames.Email,
    autoComplete: 'email',
    type: 'email',
  },
  [LoginFormFieldsNames.Password]: {
    placeholder: 'Password',
    name: LoginFormFieldsNames.Password,
    autoComplete: 'current-password',
    type: 'password',
  },
  [LoginFormFieldsNames.RememberMe]: {
    label: 'Remember me for 30 days',
    name: LoginFormFieldsNames.RememberMe,
    type: 'checkbox',
  },
};
