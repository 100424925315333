import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { ChangeEvent, memo, useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FormSwitch, FormTextFieldInlineEdit, List, ListItem } from 'components/index';
import { useFormProvider, useIsMobile, useProcessNewLabels } from 'hooks';
import messages from 'messages';
import useUpdateNodeFormData from 'pages/app-pages/chatbot-builder-page/hooks/useUpdateNodeFormData';
import FormLabelsSelector from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormLabelsSelector';
import FormVariableField from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/FormVariableField';
import NodesFormTextEditor from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/NodesFormTextEditor';
import StepFormFieldsWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormFieldsWrapper';
import StepFormSubmitButtons from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormSubmitButtons';
import StepFormWrapper from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/form-components/StepFormWrapper';
import {
  TrueFalseQuestionStepFormFields,
  TrueFalseQuestionStepFormFieldsNames,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/true-false-question/types';
import {
  defaultTrueFalseQuestionStepFormValues,
  TrueFalseQuestionFormFieldsConfig,
  trueFalseQuestionStepFormSchema,
} from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/quiz/true-false-question/utils';
import { Mention } from 'types';
import { characterLimitValue } from 'utils';

interface OptionsQuestionProps {
  id: string;
  defaultValues?: Partial<TrueFalseQuestionStepFormFields>;
  mentions?: Mention[];
  commands?: Mention[];
}
const TrueFalseQuestion = ({ defaultValues, id, mentions, commands }: OptionsQuestionProps) => {
  const [localDefaultValues, setLocalDefaultValues] = useState({
    ...defaultTrueFalseQuestionStepFormValues,
    ...defaultValues,
    nodeId: defaultValues?.nodeId || uuid(),
    answers: isEmpty(defaultValues?.answers)
      ? [
          { name: 'True', option: 'True', isCorrect: true, nodeId: uuid() },
          { name: 'False', option: 'False', isCorrect: false, nodeId: uuid() },
        ]
      : defaultValues?.answers,
    labels: defaultValues?.labels || [],
  });

  const onLocalDefaultValuesChange = useCallback(
    (newDefaultValues: Partial<TrueFalseQuestionStepFormFields>) => {
      setLocalDefaultValues((prev) => ({
        ...prev,
        ...newDefaultValues,
      }));
    },
    [],
  );

  const formMethods = useFormProvider<TrueFalseQuestionStepFormFields>({
    schema: trueFalseQuestionStepFormSchema,
    defaultValues: localDefaultValues,
  });

  const { name, labels, variable, input, removeAnswerOptions } = TrueFalseQuestionFormFieldsConfig;

  const updateNodeFormData = useUpdateNodeFormData();

  const { isMobile } = useIsMobile();

  const localCommands = useRef(commands);
  const localMentions = useRef(
    mentions?.filter((mention) => mention.id !== 'score' && mention.id !== 'quiz_summary'),
  );
  const { reset, formState } = formMethods;

  const processNewLabels = useProcessNewLabels();
  const onReset = () => {
    reset(localDefaultValues);
  };

  const handleOnSubmit = async (formData: TrueFalseQuestionStepFormFields) => {
    const labelIds = await processNewLabels(formData.labels);

    await updateNodeFormData({
      id,
      formData: {
        ...formData,
        labels: labelIds,
      },
      oldVariable:
        formState.defaultValues?.variable !== formData.variable
          ? formState.defaultValues?.variable
          : undefined,
    });
  };

  const initialRadioValue =
    defaultValues?.answers?.find((answer) => answer.isCorrect)?.option || 'True';

  const [value, setValue] = useState(initialRadioValue);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);

    const currentAnswers = formMethods.getValues(TrueFalseQuestionStepFormFieldsNames.Answers);

    const updatedAnswers = currentAnswers.map((answer) => ({
      ...answer,
      isCorrect: answer.option === selectedValue,
    }));

    formMethods.setValue(TrueFalseQuestionStepFormFieldsNames.Answers, updatedAnswers, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return (
    <StepFormWrapper formMethods={formMethods} handleOnSubmit={handleOnSubmit}>
      <StepFormFieldsWrapper>
        <FormTextFieldInlineEdit {...name} variant='standard' size='small' />
        <FormLabelsSelector {...labels} onLocalDefaultValuesChange={onLocalDefaultValuesChange} />
        <Stack gap={0.5}>
          <NodesFormTextEditor
            characterLimit={characterLimitValue}
            autoFocus={!isMobile}
            {...input}
            mentions={localMentions.current}
            commands={localCommands.current}
          />
          <FormVariableField {...variable} />
        </Stack>

        <List divider disablePadding label='True or False'>
          <ListItem>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='true-false-question-label'
                name='true-false-question'
                value={value}
                onChange={handleRadioChange}
              >
                <FormControlLabel value='True' control={<Radio size='small' />} label='True' />
                <FormControlLabel value='False' control={<Radio size='small' />} label='False' />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
        <List divider label='Aditional Options'>
          <ListItem>
            <FormSwitch
              {...removeAnswerOptions}
              infoIcon={{
                tooltip: messages.builder.removeAnswerOptionsTooltip,
                icon: 'info',
              }}
            />
          </ListItem>
        </List>
      </StepFormFieldsWrapper>
      <StepFormSubmitButtons formState={formState} reset={onReset} />
    </StepFormWrapper>
  );
};

export default memo(TrueFalseQuestion);
