import { Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Icon from 'components/Icon';
import IconButton, { IconButtonProps } from 'components/IconButton';
import { TooltipWrapperProps } from 'theme';

interface FormSectionProps extends StackProps {
  title?: string;
  disablePadding?: boolean;
  infoIcon?: {
    tooltip: ReactNode;
    icon: string;
    tooltipProps?: TooltipWrapperProps;
  };
  cta?: IconButtonProps;
}

const FormSection = ({
  children,
  title = '',
  disablePadding,
  infoIcon,
  cta,
  ...props
}: FormSectionProps) => (
  <Stack width={1} gap={0.5} {...props}>
    <Stack
      width={1}
      direction='row'
      gap={1}
      px={disablePadding ? 0 : 1}
      pr={cta || disablePadding ? 0 : 1}
      alignItems='center'
      justifyContent={cta ? 'space-between' : 'flex-start'}
      sx={{ height: 22 }}
    >
      <Tooltip
        enterDelay={400}
        enterTouchDelay={400}
        enterNextDelay={400}
        title={infoIcon?.tooltip}
        placement='top'
        arrow
        {...infoIcon?.tooltipProps}
      >
        <Stack direction='row' gap={1} alignItems='center'>
          <Typography
            noWrap
            variant='body2'
            sx={{
              userSelect: 'none',
            }}
          >
            {title}
          </Typography>
          {infoIcon && (
            <Icon icon={infoIcon.icon} color='grey.500' fontSize='small' sx={{ fontSize: 16 }} />
          )}
        </Stack>
      </Tooltip>
      {cta && (
        <IconButton
          edge='end'
          size='small'
          tooltipPlacement='left'
          iconProps={{ fontSize: 'small', sx: { fontSize: 18 } }}
          tooltipProps={{ arrow: true }}
          {...cta}
        />
      )}
    </Stack>

    {children}
  </Stack>
);

export default FormSection;
