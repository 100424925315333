import { ButtonGroup } from '@mui/material';
import { ReactNode } from 'react';

type BuilderButtonsGroupProps = {
  children?: ReactNode;
};
function BuilderButtonsGroup({ children }: BuilderButtonsGroupProps) {
  return (
    <ButtonGroup size='small' color='inherit' variant='contained'>
      {children}
    </ButtonGroup>
  );
}

export default BuilderButtonsGroup;
