import { Stack, Typography } from '@mui/material';
import Button, { ButtonProps } from 'components/Button';
import { useAppBarHeight, useIsMobile } from 'hooks';
import { truncateMultiLineText } from 'utils';

interface CardHeaderProps {
  title: string;
  onClick?: () => void;
  buttonText?: string;
  buttonProps?: ButtonProps;
}
const CardHeader = ({ title, onClick, buttonText = 'View All', buttonProps }: CardHeaderProps) => {
  const appBarHeight = useAppBarHeight();
  const { isMobile } = useIsMobile();

  return (
    <Stack
      top={appBarHeight}
      bgcolor='background.default'
      zIndex={1}
      position={isMobile ? 'sticky' : 'static'}
      direction='row'
      pb={3}
      pt={1}
      justifyContent='space-between'
      alignItems='center'
      gap={1}
    >
      <Typography
        variant='subtitle1'
        component='h1'
        sx={{
          ...truncateMultiLineText(1),
        }}
      >
        {title}
      </Typography>
      {onClick && (
        <Button onClick={onClick} variant='outlined' color='primary' size='small' {...buttonProps}>
          {buttonText}
        </Button>
      )}
    </Stack>
  );
};

export default CardHeader;
