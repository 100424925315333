export const truncateText = ({ input, length = 5 }: { input: string; length?: number }) =>
  input.length > length ? `${input.substring(0, length)}...` : input;

export const formatFullNameWithInitial = (firstName: string, lastName?: string): string =>
  lastName ? `${firstName} ${lastName.charAt(0)}.` : firstName;

export const lowerCaseAndUnderscore = (value: string) => {
  // Trim, convert to lower case, replace spaces with underscores
  let processedValue = value.trim().toLowerCase().replace(/\s+/g, '_');

  // Attempt to remove invisible characters
  processedValue = processedValue.replace(/[\u200B-\u200D\uFEFF]/g, '');

  return processedValue;
};
export const lowerCaseUnderscoreAndDigits = (value: string) =>
  value.toLowerCase().replace(/[^a-z0-9]+/g, '_');
