import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { DefaultValues, FieldValues, Resolver, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { useDirtyStore } from 'store';

interface FormProps<DataType> {
  schema: AnyObjectSchema;
  defaultValues?: DefaultValues<DataType>;
  viewMode?: boolean;
  mode?: 'onSubmit' | 'onChange' | 'onTouched' | 'onBlur';
  reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit';
}

export function useFormProvider<DataType extends FieldValues>({
  schema,
  defaultValues,
  mode = 'onSubmit',
  reValidateMode = 'onChange',
}: FormProps<DataType>) {
  const methods = useForm<DataType>({
    mode,
    reValidateMode,
    resolver: yupResolver(schema) as Resolver<DataType, any>,
    defaultValues,
    delayError: 200,
    criteriaMode: 'firstError',
  });

  const { setIsDirty } = useDirtyStore((state) => ({
    setIsDirty: state.setIsDirty,
  }));

  const { reset, formState, getValues } = methods;

  const { isDirty: isFormDirty, isSubmitSuccessful } = formState;

  useEffect(() => {
    setIsDirty(isFormDirty);
    if (isSubmitSuccessful) {
      setIsDirty(false);
      reset(getValues());
    }
  }, [getValues, isFormDirty, isSubmitSuccessful, methods, reset, setIsDirty]);

  return methods;
}
