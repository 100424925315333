export const builderRankOptions = [
  { value: '65', label: 'A' },
  { value: '66', label: 'B' },
  { value: '67', label: 'C' },
];

export const builderRuleOptions = [
  { value: '1', label: 'Strong' },
  { value: '2', label: 'Medium' },
  { value: '3', label: 'Weak' },
];

export const builderMenuOptions = [
  { value: '1', label: 'Bot' },
  { value: '2', label: 'Skill' },
  { value: '3', label: 'Action' },
  { value: '4', label: 'Trigger' },
  { value: '5', label: 'Intent' },
  { value: '6', label: 'Entity' },
];

export const builderModeOptions = ['Bot', 'Skill', 'Action', 'Trigger', 'Intent', 'Entity'];

export const builderSyncOptions = [
  { value: '1', label: 'Crm' },
  { value: '2', label: 'Google' },
  { value: '3', label: 'DropBox' },
];

export const builderSelectOptions = [
  { value: '1', label: 'Crm' },
  { value: '2', label: 'Google' },
  { value: '3', label: 'DropBox' },
];

export const builderFlatSelectOptions = ['Crm Inline', 'Google Inline', 'DropBox Inline'];
