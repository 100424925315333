import { AutoCompleteLabel } from 'types';

export enum WaitForTimeStepFormFieldsNames {
  Name = 'name',
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface WaitForTimeStepFormFields {
  [WaitForTimeStepFormFieldsNames.NodeId]: string;
  [WaitForTimeStepFormFieldsNames.Name]: string;
  [WaitForTimeStepFormFieldsNames.Minutes]: number;
  [WaitForTimeStepFormFieldsNames.Hours]: number;
  [WaitForTimeStepFormFieldsNames.Days]: number;
  [WaitForTimeStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}
