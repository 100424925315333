import { MenuItem, Typography } from '@mui/material';

const MenuSectionHeader = ({ title }: { title: string }) => (
  <MenuItem
    disableRipple
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
        cursor: 'default',
      },
    }}
  >
    <Typography color='text.secondary' noWrap variant='caption'>
      {title}
    </Typography>
  </MenuItem>
);

export default MenuSectionHeader;
