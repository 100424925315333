import { Box, IconButton, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { isEmpty, size } from 'lodash';
import IconWithTooltip from 'components/IconWithTooltip';
import { Icon } from 'components/index';
import { ChatNotificationType } from 'enums';
import { useMenu } from 'hooks';
import ActivityDropdownMenu from 'pages/activity-page/ActivityDropdownMenu';
import { Chat } from 'types';

const ActivityDropdownWrapper = ({ row }: GridRenderCellParams<Chat>) => {
  const { openMenu, isMenuOpen, ...restMenu } = useMenu();

  const hasErrorNotifications = !!row.notifications?.some(
    (notification) => notification.type === ChatNotificationType.Error,
  );

  return (
    <Stack position='relative' height={1} justifyContent='center'>
      {!isEmpty(row.notifications) && (
        <Box position='absolute' top={2} left={2}>
          <IconWithTooltip
            icon={hasErrorNotifications ? 'error' : 'warning'}
            tooltip={
              <Stack gap={1}>
                <Typography variant='body2' fontWeight='bolder'>
                  Error Notifications
                  {size(row.notifications) > 5 && ' (Showing last 5)'}
                </Typography>
                {row.notifications
                  ?.slice(Math.max(row.notifications.length - 5, 0))
                  .map((notification, index) => (
                    <Stack
                      overflow='auto'
                      // eslint-disable-next-line react/no-array-index-key
                      key={`notification-${index}`}
                      direction='row'
                      alignItems='flex-start'
                      gap={1}
                    >
                      <Icon
                        icon={
                          notification.type === ChatNotificationType.Error ? 'error' : 'warning'
                        }
                        sx={{ fontSize: 16 }}
                      />
                      <Stack gap={0.5}>
                        <Typography variant='caption'>{notification.message}</Typography>
                        {notification.createdAt && (
                          <Typography variant='caption' color='grey.300'>
                            {format(new Date(notification.createdAt || ''), 'MMM d, yyyy h:mm a')}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  ))}
              </Stack>
            }
            tooltipPlacement='top'
            tooltipProps={{ arrow: true }}
            iconProps={{ sx: { fontSize: 16 }, color: hasErrorNotifications ? 'error' : 'warning' }}
          />
        </Box>
      )}

      <IconButton
        onClick={openMenu}
        color='default'
        aria-label='settings'
        size='large'
        sx={{ ml: 1 }}
      >
        <Icon icon='more_horiz' fontSize='small' />
      </IconButton>

      {isMenuOpen && (
        <ActivityDropdownMenu
          openMenu={openMenu}
          isMenuOpen={isMenuOpen}
          id={row.id}
          {...restMenu}
        />
      )}
    </Stack>
  );
};

export default ActivityDropdownWrapper;
