import { Box, Button, Collapse, Link, Stack, Typography } from '@mui/material';
import { Image } from 'mui-image';
import { useBoolean } from 'usehooks-ts';
import { BulletWrapper, CopyText, Icon } from '@common-components';
import createGif from 'assets/images/telegram/screenshots/bot_creation.gif';

const TutorialStep = () => {
  const { value: showTutorial, toggle: toggleShowTutorial } = useBoolean(false);

  return (
    <Stack flex={1} gap={1} alignItems='flex-start'>
      <Typography variant='h6' gutterBottom>
        1. Create a bot in Telegram
      </Typography>
      <BulletWrapper>
        <Typography variant='body2'>
          Navigate to{' '}
          <Link
            fontWeight='bolder'
            href='https://t.me/BotFather'
            target='_blank'
            underline='none'
            display='inline-flex'
            alignItems='center'
            gap={0.5}
          >
            BotFather <Icon icon='open_in_new' color='primary' fontSize='small' />
          </Link>{' '}
          in Telegram.
        </Typography>
      </BulletWrapper>
      <BulletWrapper>
        <Typography variant='body2'>
          Type <CopyText text='/newbot' bold />, choose a name and username for your bot.
        </Typography>
      </BulletWrapper>
      <BulletWrapper>
        <Typography variant='body2' gutterBottom>
          Copy the API token that BotFather gives you.
        </Typography>
      </BulletWrapper>
      <Button onClick={toggleShowTutorial} endIcon={<Icon icon='play_arrow' fontSize='small' />}>
        <Box
          component='span'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {showTutorial ? 'Hide Tutorial' : 'View Tutorial'}
        </Box>
      </Button>
      <Collapse in={showTutorial} timeout='auto'>
        <Image width='100%' duration={1000} src={createGif} />
      </Collapse>
    </Stack>
  );
};

export default TutorialStep;
