import { AutoCompleteLabel } from 'types';

export enum OptionsMenuStepFormFieldsNames {
  Name = 'name',
  Input = 'input',
  Variable = 'variable',
  Menu = 'menu',
  RemoveAnswerOptions = 'removeAnswerOptions',
  BackButton = 'backButton',
  NodeId = 'nodeId',
  Labels = 'labels',
}

export interface OptionsMenuStepFormFields {
  [OptionsMenuStepFormFieldsNames.Name]: string;
  [OptionsMenuStepFormFieldsNames.Input]: string;
  [OptionsMenuStepFormFieldsNames.Variable]?: string;
  [OptionsMenuStepFormFieldsNames.NodeId]: string;
  [OptionsMenuStepFormFieldsNames.Menu]: OptionsMenuStepFormFieldsMenu[];
  [OptionsMenuStepFormFieldsNames.RemoveAnswerOptions]?: boolean;
  [OptionsMenuStepFormFieldsNames.BackButton]?: boolean;
  [OptionsMenuStepFormFieldsNames.Labels]?: AutoCompleteLabel[];
}

export enum OptionsMenuStepFormFieldsMenuNames {
  Name = 'name',
  Option = 'option',
  NodeId = 'nodeId',
}

export interface OptionsMenuStepFormFieldsMenu {
  [OptionsMenuStepFormFieldsMenuNames.Name]: string;
  [OptionsMenuStepFormFieldsMenuNames.Option]: string;
  [OptionsMenuStepFormFieldsMenuNames.NodeId]: string;
}
