import { Container, Stack } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { NoDataOverlay } from '@common-components';
import HeaderWithSearchBar from 'components/HeaderWithSearchBar';
import { LocalStorageKey } from 'config';
import { RoutePath, ViewTypes } from 'enums';
import { Mode } from 'forms/create-project/types';
import { useGetProjects, useIsMobile, useSearch, useTableHeight } from 'hooks';
import { ScrollAreaLayout } from 'layout';
import SortByProject, { sortByMenuItems } from 'pages/projects-page/SortByProject';
import GridView from './GridView';
import ListView from './ListView';
import { BotColumns } from './types';

function ProjectsPage() {
  const { isSmallMobile } = useIsMobile();

  const gridTableHeight = useTableHeight({ subtract: isSmallMobile ? 130 : 160 });

  const [view, setView] = useLocalStorage<ViewTypes>(LocalStorageKey.BotsViewType, ViewTypes.Grid);

  const { projects, error, isLoading } = useGetProjects();

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(LocalStorageKey.BotsViewSort, [
    {
      field: BotColumns.UpdatedAt,
      sort: 'asc',
    },
  ]);

  const {
    search,
    handleSearchChange,
    filteredItems: filteredBots,
    noSearchResults,
    resetSearch,
  } = useSearch({
    data: projects,
    searchKey: ['name', 'description'],
  });

  const onSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const botConfig = sortByMenuItems.find((item) => item.field === sortModel[0].field);
  const currentSortDirection = sortModel[0].sort;

  const navigate = useNavigate();
  const filteredAndSortedBots =
    filteredBots.sort(
      currentSortDirection === 'asc' ? botConfig?.sorter : (a, b) => botConfig?.sorter(b, a) || 0,
    ) || [];

  if (error) {
    return (
      <NoDataOverlay
        text='Error Loading bots, Please Reload the Page'
        onClick={() => {
          window.location.reload();
        }}
        buttonTitle='Reload'
      />
    );
  }

  const openCreateModal = () => {
    navigate(RoutePath.CreateProject, {
      state: {
        mode: Mode.Create,
      },
    });
  };

  return (
    <>
      <Container maxWidth='lg'>
        <HeaderWithSearchBar
          SortBy={SortByProject}
          view={view}
          handleViewChange={setView}
          mainAction={{
            label: 'Create Project',
            icon: 'add',
            onClick: openCreateModal,
          }}
          onSortModelChange={onSortModelChange}
          sortModel={sortModel}
          handleSearchChange={handleSearchChange}
          search={search}
          headerText='My Projects'
        />
        <Stack minHeight={gridTableHeight}>
          {view === ViewTypes.Grid ? (
            <GridView
              resetSearch={resetSearch}
              noSearchResults={noSearchResults}
              bots={filteredAndSortedBots}
              openNewBotDialog={openCreateModal}
              isLoading={isLoading}
            />
          ) : (
            <ScrollAreaLayout subtract={isSmallMobile ? 130 : 160}>
              <ListView
                bots={filteredBots}
                isLoading={isLoading}
                onSortModelChange={onSortModelChange}
                sortModel={sortModel}
              />
            </ScrollAreaLayout>
          )}
        </Stack>
      </Container>
      <Outlet />
    </>
  );
}

export default ProjectsPage;
