import { InputAdornment, Stack, TextField, TextFieldProps } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';
import ShowPasswordAdornment from 'components/form-fields/components/ShowPasswordAdornment';
import FormFieldWrapper, { FormFieldWrapperProps } from 'components/form-fields/FormFieldWrapper';
import IconButton from 'components/IconButton';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ConditionalWrap } from 'utils';

export interface FormTextFieldProps
  extends Omit<TextFieldProps, 'name' | 'id'>,
    FormFieldWrapperProps {
  name: string;
  confirmCallBack?: () => void;
  onBlurCallback?: () => void;
  valueManipulator?: (value: string) => string; // New property for manipulating the input value
  tooltipError?: boolean;
}
export const FormTextField = (props: FormTextFieldProps) => {
  const {
    name,
    label,
    disablePadding,
    cta,
    infoIcon,
    fullWidth = true,
    type,
    confirmCallBack,
    helperText,
    InputProps: externalInputProps,
    onBlurCallback,
    valueManipulator,
    tooltipError,
    ...rest
  } = props;

  const { control } = useFormContext();
  const {
    value: passwordVisible,
    setTrue: showPassword,
    setFalse: hidePassword,
  } = useBoolean(false);

  // Merge internal and external inputProps
  const mergedInputProps = {
    className: 'data-hj-allow',
    ...externalInputProps?.inputProps, // External inputProps take precedence
  };

  // Handle endAdornment based on type or confirmCallBack
  let endAdornment;
  if (type === 'password') {
    endAdornment = (
      <ShowPasswordAdornment
        showPassword={showPassword}
        hidePassword={hidePassword}
        passwordVisible={passwordVisible}
      />
    );
  } else if (confirmCallBack) {
    endAdornment = (
      <InputAdornment position='end'>
        <IconButton icon='keyboard_return' size='small' onClick={confirmCallBack} />
      </InputAdornment>
    );
  }

  // Merge external InputProps with internal properties
  const mergedInputPropsFull = {
    ...externalInputProps, // Spread external InputProps first
    inputProps: mergedInputProps, // Overwrite inputProps
    ...(endAdornment && { endAdornment }), // Add endAdornment if exists
  };

  const { setEditorIsFocused } = useBuilderStore((state) => ({
    setEditorIsFocused: state.setEditorIsFocused,
  }));

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, onBlur, value, ...field }, fieldState: { error } }) => {
        // Use the valueManipulator if provided

        const processedValue = valueManipulator ? valueManipulator(value ?? '') : value ?? '';

        const handleChange = (event: { target: { value: string } }) => {
          const transformedValue = valueManipulator
            ? valueManipulator(event.target.value)
            : event.target.value;
          onChange(transformedValue); // Now we directly call the onChange method from field
        };
        return (
          <ConditionalWrap
            condition={!!label}
            wrap={(wrappedChildren) => (
              <FormFieldWrapper
                disablePadding={disablePadding}
                cta={cta}
                infoIcon={infoIcon}
                label={label}
              >
                {wrappedChildren}
              </FormFieldWrapper>
            )}
          >
            <Stack width={fullWidth ? 1 : 'auto'}>
              <TextField
                className='notranslate'
                inputRef={ref}
                {...field}
                id={name}
                fullWidth={fullWidth}
                onBlur={() => {
                  onBlur();
                  onBlurCallback?.();
                  setEditorIsFocused(false);
                }}
                onChange={handleChange}
                onFocus={() => setEditorIsFocused(true)}
                value={processedValue} // Use the processed value here
                error={error !== undefined}
                InputProps={mergedInputPropsFull}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    confirmCallBack?.();
                  }
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    confirmCallBack?.();
                  }
                }}
                type={type === 'password' && passwordVisible ? 'text' : type}
                {...rest}
              />
              {!tooltipError && <FormCollapseHelperText error={error} helperText={helperText} />}
            </Stack>
          </ConditionalWrap>
        );
      }}
    />
  );
};

export default memo(FormTextField);
