import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import useSWR from 'swr';
import { AppLoader, CustomerSupportFloatingButton } from '@common-components';

import { ApiKey } from 'api';
import {
  // AppTypes,
  RoutePath,
} from 'enums';
import { useGetUserLabels, useHotjarIdentify, useUnmount } from 'hooks';
import { UndoRedoProvider } from 'pages/app-pages/chatbot-builder-page/context/UndoRedoContext';
import { defaultEdges, defaultNodes } from 'pages/app-pages/chatbot-builder-page/defaults';
import { useBuilderStore, useLayoutDirection } from 'pages/app-pages/chatbot-builder-page/store';
import { Project } from 'types';
import BuilderMain from './BuilderMain';

function ChatbotBuilderPage() {
  const navigate = useNavigate();

  useHotjarIdentify();

  const {
    selectedCommand,
    closeToolbox,
    setSelectedCommand,
    setPublishedLastUpdatedAt,
    setDraftLastUpdatedAt,
  } = useBuilderStore((state) => ({
    selectedCommand: state.selectedCommand,
    setSelectedCommand: state.setSelectedCommand,
    setDraftLastUpdatedAt: state.setDraftLastUpdatedAt,
    setPublishedLastUpdatedAt: state.setPublishedLastUpdatedAt,
    closeToolbox: state.closeToolbox,
  }));

  const { layoutDirection } = useLayoutDirection((state) => ({
    layoutDirection: state.layoutDirection,
  }));

  const { id } = useParams();

  const { data: project, isLoading } = useSWR<Project>(`${ApiKey.Project}/${id}`, {
    onSuccess: (data) => {
      setPublishedLastUpdatedAt(data?.bot?.published?.updatedAt || null);
      setDraftLastUpdatedAt(data?.bot?.draft?.updatedAt || null);
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        navigate(`/${RoutePath.NotFound}`, { replace: true });
      }
    },
  });

  useGetUserLabels();

  useEffect(() => {
    if (!selectedCommand) {
      setSelectedCommand(
        project?.bot?.draft.commands.find((command) => command.name === 'start') || null,
      );
    }
  }, [project, project?.bot?.draft.commands, selectedCommand, setSelectedCommand]);

  useUnmount(() => {
    closeToolbox();
    setSelectedCommand(null);
  });

  /*   useEffect(() => {
    if (
      typeof project?.apps.find((app) => app.type === AppTypes.Chatbot)?.selectTemplateShown !==
      'boolean'
    ) {
      return;
    }
    if (!project?.apps.find((app) => app.type === AppTypes.Chatbot)?.selectTemplateShown) {
      navigate(`/${RoutePath.ChatbotBuilder}/${id}/${RoutePath.Template}`, { replace: true });
    }
  }, [id, navigate, project]); */

  if (isLoading) {
    return <AppLoader />;
  }

  const initialNodes = !isEmpty(selectedCommand?.flow.nodes)
    ? selectedCommand?.flow.nodes
    : defaultNodes({ direction: layoutDirection, label: selectedCommand?.name || '' });
  const initialEdges = !isEmpty(selectedCommand?.flow.edges)
    ? selectedCommand?.flow.edges
    : defaultEdges;

  return (
    <>
      <Box display='flex' flexGrow={1} key={selectedCommand?.id}>
        <ReactFlowProvider>
          <UndoRedoProvider>
            <BuilderMain
              isLoading={isLoading}
              name={project?.name || ''}
              initialNodes={initialNodes}
              initialEdges={initialEdges}
            />
          </UndoRedoProvider>
        </ReactFlowProvider>
      </Box>
      <Outlet />
      <CustomerSupportFloatingButton />
    </>
  );
}

export default ChatbotBuilderPage;
