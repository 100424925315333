import { Stack, Typography } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { ComponentType } from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SearchBar from 'components/SearchBar';
import { ViewTypes } from 'enums';
import { useAppBarHeight, useIsMobile } from 'hooks';
import { mainBackground } from 'theme/utils';

type HeaderProps = {
  headerText: string;
  mainAction: {
    label: string;
    icon: string;
    onClick: () => void;
  };
  secondaryAction?: {
    label: string;
    icon: string;
    onClick: () => void;
  };
  sortModel: GridSortModel;
  onSortModelChange: (newSortModel: GridSortModel) => void;
  handleSearchChange: (value: string) => void;
  search: string;
  view?: ViewTypes;
  handleViewChange?: (newAlignment: ViewTypes) => void;
  SortBy: ComponentType<{
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
  }>;
};
const HeaderWithSearchBar = ({
  mainAction,
  view,
  handleViewChange,
  sortModel,
  onSortModelChange,
  handleSearchChange,
  search,
  headerText,
  SortBy,
  secondaryAction,
}: HeaderProps) => {
  const appBarHeight = useAppBarHeight();
  const { isSmallMobile, isMobile } = useIsMobile();

  return (
    <Stack
      position='sticky'
      top={appBarHeight}
      justifyContent='flex-end'
      bgcolor={mainBackground}
      zIndex={1}
    >
      <Stack
        direction='row'
        height={isSmallMobile ? 70 : 100}
        alignItems='flex-end'
        pb={2}
        justifyContent='space-between'
      >
        <Typography noWrap variant='h6' component='h1'>
          {headerText}
        </Typography>
        <Stack direction='row' gap={1} alignItems='center'>
          {secondaryAction && (
            <Button
              startIcon={<Icon icon={secondaryAction.icon} fontSize='small' />}
              onClick={() => {
                secondaryAction.onClick();
              }}
              variant='outlined'
              color='secondary'
              size={isMobile ? 'small' : 'medium'}
            >
              {secondaryAction.label}
            </Button>
          )}
          <Button
            startIcon={<Icon icon={mainAction.icon} fontSize='small' />}
            onClick={() => {
              mainAction.onClick();
            }}
            variant='contained'
            color='secondary'
            size={isMobile ? 'small' : 'medium'}
          >
            {mainAction.label}
          </Button>
        </Stack>
      </Stack>
      <SearchBar
        view={view}
        handleViewChange={handleViewChange}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        handleSearchChange={handleSearchChange}
        search={search}
        SortBy={SortBy}
      />
    </Stack>
  );
};

export default HeaderWithSearchBar;
