import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@common-components';
import { ApiKey } from 'api';
import { RoutePath } from 'enums';
import { OptionsMenuState, useGetChatActivity, useUpdateBooleanProperty } from 'hooks';
import { menuMinWidth } from 'theme/utils';
import { Chat } from 'types';

interface ActivityItemMenuProps extends OptionsMenuState {
  id: string;
}
function ActivityDropdownMenu({ id, closeMenu, anchorEl, isMenuOpen }: ActivityItemMenuProps) {
  const navigate = useNavigate();

  const { chats } = useGetChatActivity();

  const chat = chats.find((item) => item.id === id);

  const {
    entity: isRead,
    updateProperty: toggleChatRead,
    isLoading: isReadLoading,
  } = useUpdateBooleanProperty<Chat>({
    id: id || '',
    property: 'isRead',
    apiKey: ApiKey.Chat,
    mutateKey: [`${ApiKey.Chat}/${ApiKey.Ticket}`],
  });

  const {
    entity: isImportant,
    updateProperty: toggleIsImportant,
    isLoading: isImportantLoading,
  } = useUpdateBooleanProperty<Chat>({
    id: id || '',
    property: 'isImportant',
    apiKey: ApiKey.Chat,
    mutateKey: [`${ApiKey.Chat}/${ApiKey.Ticket}`],
  });

  const {
    entity: isArchived,
    updateProperty: toggleSingleChatArchived,
    isLoading: isArchivedLoading,
  } = useUpdateBooleanProperty<Chat>({
    id: id || '',
    property: 'isArchived',
    apiKey: ApiKey.Chat,
    mutateKey: [`${ApiKey.Chat}/${ApiKey.Ticket}`],
  });

  return (
    <Menu
      id='demo-positioned-menu'
      aria-labelledby='demo-positioned-button'
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: { sx: { minWidth: menuMinWidth } },
      }}
    >
      <MenuItem
        onClick={() => {
          closeMenu();
          navigate(`${RoutePath.ChatActivity}/${chat?.id}`, {
            state: {
              id: chat?.id,
              projectId: chat?.projectId,
            },
          });
        }}
      >
        <ListItemIcon>
          <Icon icon='forum' fontSize='small' />
        </ListItemIcon>
        <ListItemText>View Conversation</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          closeMenu();
          navigate(`/${RoutePath.ProjectManager}/${chat?.projectId}`);
        }}
      >
        <ListItemIcon>
          <Icon icon='smart_toy' fontSize='small' />
        </ListItemIcon>
        <ListItemText>View Project</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={isReadLoading}
        onClick={async () => {
          await toggleChatRead(!chat?.isRead);
          closeMenu();
        }}
      >
        <ListItemIcon>
          {isReadLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Icon icon={isRead ? 'mark_unread_chat_alt' : 'mark_chat_read'} fontSize='small' />
          )}
        </ListItemIcon>
        <ListItemText>{isRead ? 'Mark as Unread' : 'Mark as Read'}</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={async () => {
          await toggleIsImportant(!chat?.isImportant);
          closeMenu();
        }}
      >
        <ListItemIcon>
          {isImportantLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Icon icon={isImportant ? 'star_outline' : 'stars'} fontSize='small' />
          )}
        </ListItemIcon>
        <ListItemText>{isImportant ? 'Remove from Important' : 'Mark as Important'}</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={async () => {
          await toggleSingleChatArchived(!chat?.isArchived);
          closeMenu();
        }}
      >
        <ListItemIcon>
          {isArchivedLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Icon icon={isArchived ? 'unarchive' : 'archive'} fontSize='small' />
          )}
        </ListItemIcon>
        <ListItemText>{isArchived ? 'Unarchive' : 'Archive'}</ListItemText>
      </MenuItem>
    </Menu>
  );
}

export default ActivityDropdownMenu;
