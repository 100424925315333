import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import { api, ApiKey } from 'api';
import { useServerError } from 'hooks';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { useDirtyStore } from 'store';
import { BaseNodeProps, Project } from 'types';

const useSaveToServer = () => {
  const { setSaving, incrementActionCount } = useBuilderStore((state) => ({
    setSaving: state.setSaving,
    incrementActionCount: state.incrementActionCount,
  }));
  const { setIsSavingToServer } = useDirtyStore((state) => ({
    setIsSavingToServer: state.setIsSavingToServer,
  }));
  const { selectedCommand, setDraftLastUpdatedAt } = useBuilderStore((state) => ({
    selectedCommand: state.selectedCommand,
    setDraftLastUpdatedAt: state.setDraftLastUpdatedAt,
  }));

  const { getEdges, getNodes } = useReactFlow<BaseNodeProps>();
  const { id } = useParams();
  const toastServerError = useServerError();

  return useCallback(async () => {
    if (!id) {
      throw new Error('No bot id provided');
    }
    setSaving(true);
    setIsSavingToServer(true);
    // eslint-disable-next-line no-console
    /*    console.log(
      'getNodes',
      getNodes().map(
        (node) =>
          `node Id: ${node.id} - form Data NodeId: ${node.data.formData?.nodeId}, menu: ${node.data.formData?.menu?.map(
            (menu) => menu?.nodeId,
          )}`,
      ),
    ); */
    // eslint-disable-next-line no-console
    // console.log('getEdges', getEdges());

    try {
      const response = await api.patch<Project>(
        `${ApiKey.Project}/${id}/${ApiKey.Bot}/${ApiKey.Command}/${selectedCommand?.id}`,
        {
          flow: {
            nodes: getNodes().map((node) => {
              if ('selected' in node) {
                delete node.selected;
              }
              return node;
            }),
            edges: getEdges(),
          },
        },
      );
      // TODO - fix this type
      setDraftLastUpdatedAt(response.data?.updatedAt || null);
    } catch (error: any) {
      toastServerError(error);
    }
    incrementActionCount();
    setSaving(false);
    setIsSavingToServer(false);
  }, [
    id,
    setSaving,
    setIsSavingToServer,
    incrementActionCount,
    selectedCommand?.id,
    getNodes,
    getEdges,
    setDraftLastUpdatedAt,
    toastServerError,
  ]);
};

export default useSaveToServer;
