import { useMemo } from 'react';
import useSWR from 'swr';
import { ApiKey } from 'api';
import { ChatGroupMessage, Project } from 'types';

const defaultMessages: ChatGroupMessage[] = [];

export const useGetChatGroupMessages = ({ projectId }: { projectId: Project['id'] }) => {
  const { data, error, isLoading } = useSWR<ChatGroupMessage[]>(
    `${ApiKey.ChatGroupMessage}?projectId=${projectId}`,
  );

  const mappedData = useMemo(() => {
    if (!data) {
      return defaultMessages;
    }
    return data.map((message) => ({
      ...message,
      deliveryDate: message.sendDate || message.startDate,
    }));
  }, [data]);
  return {
    chatGroupMessages: mappedData ?? defaultMessages,
    isLoading,
    error,
  };
};
