import { mixed, object, ObjectSchema } from 'yup';
import { TicketStatus } from 'enums';
import { StatusFormFields, StatusFormFieldsNames } from 'pages/activity-page/status/types';

import { FormFieldProps } from 'types';

export const statusFormSchema: ObjectSchema<StatusFormFields> = object({
  [StatusFormFieldsNames.Status]: mixed<TicketStatus>()
    .oneOf(Object.values(TicketStatus))
    .required('The Type is required.'),
});

export const defaultStatusFormValues: Partial<StatusFormFields> = {
  [StatusFormFieldsNames.Status]: TicketStatus.Open,
};

export const statusFieldsConfig: Record<StatusFormFieldsNames, FormFieldProps> = {
  [StatusFormFieldsNames.Status]: {
    placeholder: 'Unassigned',
    name: StatusFormFieldsNames.Status,
    type: 'text',
  },
};
