import { Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { EmptyBox } from 'assets/images';
import Button from 'components/Button';

export type NoDataOverlayProps = {
  text?: string;
  onClick?: () => void;
  buttonTitle?: string;
  sx?: SxProps<Theme>;
};
function NoDataOverlay({
  text = 'No Data Yet',
  onClick,
  buttonTitle = 'Add New',
  sx,
}: NoDataOverlayProps) {
  const theme = useTheme();
  return (
    <Stack
      p={3}
      flex={1}
      alignItems='center'
      justifyContent='center'
      gap={2}
      sx={{
        height: '100%',
        '& .ant-empty-img-1': {
          fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
          fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
          fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
          fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
          fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
          fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
        ...sx,
      }}
    >
      <EmptyBox />
      <Typography variant='body2' color='text.secondary'>
        {text}
      </Typography>
      {onClick && (
        <Button
          variant='outlined'
          color='secondary'
          onClick={onClick}
          sx={{ textTransform: 'none' }}
        >
          {buttonTitle}
        </Button>
      )}
    </Stack>
  );
}

export default NoDataOverlay;
