import { Stack } from '@mui/material';
import { FormArrayFieldProps } from 'components/form-fields/FormArrayFields';
import { FormTextField } from 'components/index';
import { YesNoStepFormFieldsMenuNames } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/types';
import { YesNoFormMenuFieldsConfig } from 'pages/app-pages/chatbot-builder-page/nodes/components/steps/yes-no-menu/utils';

const YesNoFormArrayField = ({ getFieldName, index }: FormArrayFieldProps) => {
  const {
    name: optionName,
    label: labelName,
    ...optionRest
  } = YesNoFormMenuFieldsConfig[YesNoStepFormFieldsMenuNames.Option];

  const menuOptionFieldName = getFieldName(optionName);

  return (
    <Stack gap={0.5}>
      <FormTextField
        size='small'
        placeholder={`${labelName} ${index + 1}`}
        name={menuOptionFieldName}
        {...optionRest}
      />
    </Stack>
  );
};

export default YesNoFormArrayField;
