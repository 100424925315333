import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { MouseEvent as ReactMouseEvent, useRef, useState } from 'react';
import Icon from 'components/Icon';
import { SubmitButton } from 'pages/auth-pages/components';
import { menuMinWidth } from 'theme/utils';

interface SplitButtonProps {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  submitting: boolean;
  disabled: boolean;
  options: Array<{
    title: string;
    onClick: (
      e:
        | ReactMouseEvent<HTMLLIElement, MouseEvent>
        | ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
    icon?: string;
    menuTitle?: string;
    menuIcon?: string;
    disabled?: boolean;
    onOptionClick?: (event: ReactMouseEvent<HTMLLIElement, MouseEvent>) => void;
  }>;
}

export default function SplitButton({
  selectedIndex,
  setSelectedIndex,
  submitting,
  disabled,
  options,
}: SplitButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    options[selectedIndex].onClick(e);
  };

  const handleMenuItemClick = (
    event: ReactMouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    if (options[index].onOptionClick) {
      options[index].onOptionClick?.(event);
    } else {
      options[index].onClick(event);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant='contained' ref={anchorRef} aria-label='Split button'>
        <SubmitButton
          endIcon={<Icon icon={options[selectedIndex].icon} />}
          disabled={disabled || submitting}
          onClick={handleClick}
          submitting={submitting}
        >
          {options[selectedIndex].title}
        </SubmitButton>
        <Button
          color='secondary'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='Select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          disabled={disabled || submitting}
        >
          <Icon icon='arrow_drop_down' />
        </Button>
      </ButtonGroup>
      <Menu
        className='notranslate'
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { minWidth: menuMinWidth, maxWidth: 280 } },
        }}
      >
        <MenuList id='split-button-menu' autoFocusItem>
          {options.map((option, index) => (
            <MenuItem
              key={option.title}
              disabled={option.disabled}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <ListItemIcon>
                <Icon icon={option.menuIcon || option.icon} fontSize='small' />
              </ListItemIcon>
              <ListItemText>{option.menuTitle || option.title}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
