import { useMemo } from 'react';
import useSWR from 'swr';
import { ApiKey } from 'api';
import { UserLabel } from 'types';

const defaultLabel: UserLabel[] = [];

export const useGetUserLabels = () => {
  const { data, isLoading, error } = useSWR<UserLabel[]>(ApiKey.UserLabel);

  const labels = useMemo(
    () =>
      // Only re-compute if `data` changes
      data ? data.filter((label) => !label.isDisabled) : defaultLabel,
    [data],
  );

  return {
    labels,
    isLoading,
    error,
  };
};
