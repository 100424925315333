import useSWR from 'swr';
import { ApiKey, fetchChatData } from 'api';
import { ChatTableProps } from 'types';

const defaultSupportTickets: ChatTableProps[] = [];

export const useGetSupportTickets = () => {
  const { data, isLoading, error } = useSWR<ChatTableProps[]>(
    `${ApiKey.Chat}/${ApiKey.Ticket}`,
    fetchChatData,
  );

  return {
    supportTickets: data ?? defaultSupportTickets,
    isLoading,
    error,
  };
};
