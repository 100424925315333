import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import React, { memo, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormCollapseHelperText from 'components/form-fields/components/FormCollapseHelperText';

export interface OptionType {
  label: string;
  value: string;
  color?: string;
  icon?: string;
  isDisabled?: boolean;
}

export interface FormAutocompleteProps<T extends OptionType | string> // Allow T to be a string or an object with at least label, value, and optionally color
  extends Omit<
    AutocompleteProps<T, boolean, boolean, boolean>,
    'onChange' | 'renderInput' | 'autoComplete' | 'defaultValue' | 'value'
  > {
  name: string;
  label?: string;
  placeholder?: string;
  variant?: TextFieldProps['variant'];
  color?: TextFieldProps['color'];
  textFieldProps?: Omit<TextFieldProps, 'label' | 'placeholder' | 'variant' | 'color'>;
  helperText?: string | ReactNode;
  onChangeCallback?: (value: T) => void;
  onInputChangeCallback?: (value: string) => void;
  textFieldWrapperSx?: SxProps<Theme>;
}
export const FormAutocomplete = <T extends OptionType | string>(
  props: FormAutocompleteProps<T>,
) => {
  const {
    name,
    options,
    color,
    variant,
    label,
    placeholder,
    textFieldProps,
    helperText,
    fullWidth = true,
    multiple,
    onInputChangeCallback,
    sx,
    textFieldWrapperSx = {},
    onChangeCallback,
    ...rest
  } = props;
  const { control } = useFormContext();

  const { InputProps, ...restTextFieldProps } = textFieldProps || {};

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, onChange, value, ...field },
        formState: { defaultValues },
        fieldState: { error },
      }) => (
        <Autocomplete
          {...rest}
          multiple={multiple}
          onInputChange={(_, newInputValue) => {
            onInputChangeCallback?.(newInputValue);
          }}
          className='notranslate'
          sx={{ width: fullWidth ? 1 : 'auto', ...sx }}
          options={options}
          onChange={(_, newValue) => {
            // Define type guards
            const isOptionObject = (option: any): option is { label: string; value: string } =>
              option !== null &&
              typeof option === 'object' &&
              'label' in option &&
              typeof option.label === 'string';

            const processValue = (singleValue: any) => {
              if (typeof singleValue === 'string') {
                // Attempt to match string input with option labels
                const match = options.find(
                  (option) =>
                    isOptionObject(option) &&
                    option.label.toLowerCase() === singleValue.toLowerCase(),
                );
                return match ?? singleValue; // If there's a match, use it; otherwise, return the string as is
              }
              if (isOptionObject(singleValue)) {
                // For object inputs, ensure it directly matches an option
                const match = options.find(
                  (option) =>
                    isOptionObject(option) &&
                    option.label.toLowerCase() === singleValue.label.toLowerCase(),
                );
                return match ?? singleValue; // Use matched object or the input object as is
              }
              return singleValue; // Fallback for any other types
            };

            let processedValue;

            if (multiple) {
              // If 'multiple' is true, process each item in the array
              processedValue = Array.isArray(newValue)
                ? newValue.map(processValue)
                : [processValue(newValue)];
            } else {
              // For single selection, process the newValue directly
              processedValue = processValue(newValue);
            }

            // Invoke onChange with processedValue
            onChange(processedValue);
            onChangeCallback?.(processedValue);
          }}
          defaultValue={defaultValues?.[name]}
          value={value ?? null}
          ChipProps={{ size: 'small' }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size='small'
                label={typeof option === 'string' ? option : option.label}
                {...getTagProps({ index })}
                sx={{
                  backgroundColor:
                    typeof option === 'object' && option.color ? option.color : undefined,
                }}
              />
            ))
          }
          renderInput={(params) => (
            <Stack width={fullWidth ? 1 : 'auto'} sx={{ ...textFieldWrapperSx }}>
              <TextField
                {...params}
                {...field}
                fullWidth={fullWidth}
                inputRef={ref}
                label={label}
                placeholder={placeholder}
                variant={variant}
                color={color}
                error={error !== undefined}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                InputProps={
                  InputProps
                    ? {
                        ...params.InputProps,
                        ...InputProps,
                      }
                    : params.InputProps
                }
                {...restTextFieldProps}
              />
              <FormCollapseHelperText error={error} helperText={helperText} />
            </Stack>
          )}
        />
      )}
    />
  );
};

const MemoizedFormAutocomplete = memo(FormAutocomplete) as typeof FormAutocomplete;

export default MemoizedFormAutocomplete;
