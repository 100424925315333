import {
  Box,
  Breakpoint,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { FormEventHandler, forwardRef, ReactNode, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { useIsMobile, useModalSize, useMount, useSwipeDown, useUnmount } from 'hooks';
import { useBuilderStore } from 'pages/app-pages/chatbot-builder-page/store';
import { ConditionalWrap } from 'utils';

interface MainActionProps extends Omit<ButtonProps, 'disableElevation' | 'ref'> {
  isSubmitting?: boolean;
}

interface BasicModalBaseProps
  extends Omit<DialogProps, 'children' | 'onSubmit' | 'maxWidth' | 'open'> {
  open: boolean;
  children: ReactNode;
  handleClose?: (event: Object, reason: string) => void;
  blockCloseAttempt?: boolean;
  label: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  mainAction?: MainActionProps;
  secondaryAction?: MainActionProps;
  cancelButton?: boolean;
  cancelText?: string;
  maxWidth?: Breakpoint;
  submittingText?: string;
  customActions?: ReactNode;
  contentProps?: Omit<DialogContentProps, 'children'>;
  headerProps?: Omit<StackProps, 'children'>;
  showCloseButton?: boolean;
}

interface BasicModalPropsWithTitleComponent extends BasicModalBaseProps {
  title?: never;
  titleComponent: ReactNode;
}

interface BasicModalPropsWithTitle extends BasicModalBaseProps {
  title: string;
  titleComponent?: never;
}

interface BasicModalPropsWithoutTitle extends BasicModalBaseProps {
  title?: string;
  titleComponent?: never;
}

type BasicModalProps =
  | BasicModalPropsWithTitleComponent
  | BasicModalPropsWithTitle
  | BasicModalPropsWithoutTitle;

const BasicModal = forwardRef<HTMLDivElement, BasicModalProps>(
  (
    {
      title,
      label,
      handleClose,
      onSubmit,
      mainAction,
      secondaryAction,
      cancelButton,
      maxWidth = 'sm',
      submittingText = 'Submitting...',
      cancelText = 'Cancel',
      open,
      children,
      titleComponent,
      blockCloseAttempt,
      customActions,
      contentProps,
      headerProps,
      showCloseButton,
    },
    ref,
  ) => {
    const { fullScreen, size } = useModalSize();
    const navigate = useNavigate();

    const { setEditorIsFocused } = useBuilderStore((state) => ({
      setEditorIsFocused: state.setEditorIsFocused,
    }));

    const { isMobile } = useIsMobile();

    const swipeRef = useRef<HTMLDivElement>(null); // Create a ref for the target element

    const onClose = ({ event, reason }: { event: Object; reason: string }) => {
      if (blockCloseAttempt) {
        return;
      }
      if (handleClose) {
        handleClose(event, reason);
        return;
      }
      navigate('./..', { replace: true });
    };

    const handleSwipeDown = (e: Object) => {
      onClose({ event: e, reason: 'swipeDown' });
    };

    // Using the hook with the ref and the swipe down handler
    useSwipeDown(swipeRef, handleSwipeDown);

    useMount(() => {
      setEditorIsFocused(true);
    });

    useUnmount(() => {
      setEditorIsFocused(false);
    });

    const { isSubmitting, disabled, children: actionChildren, ...rest } = mainAction || {};
    const {
      isSubmitting: secondaryIsSubmitting,
      disabled: secondaryDisabled,
      children: secondaryChildren,
      ...secondaryRest
    } = secondaryAction || {};
    return (
      <Dialog
        className='notranslate'
        ref={ref}
        disableRestoreFocus
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        open={open}
        onClose={(event, reason) => {
          onClose({ event, reason });
        }}
        aria-labelledby={label}
        sx={{ '& .MuiDialog-paper': { width: size } }}
      >
        {(titleComponent || title) && (
          <Stack
            top={0}
            zIndex={2}
            justifyContent='center'
            position='sticky'
            ref={swipeRef}
            width={1}
            height={isMobile ? 68 : 76}
            minHeight={isMobile ? 68 : 76}
            maxHeight={isMobile ? 68 : 76}
            bgcolor='background.paper'
            {...headerProps}
            sx={{ p: isMobile ? 2 : 3, ...headerProps?.sx }}
          >
            {isMobile && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 5,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  height: 4,
                  width: 60,
                  borderRadius: 1,
                  bgcolor: 'divider',
                }}
              />
            )}
            {title && (
              <Stack direction='row' alignItems='center' justifyContent='space-between' width={1}>
                <Typography variant='h6' component='h2'>
                  {title}
                </Typography>
                {showCloseButton && (
                  <IconButton
                    size='large'
                    edge='end'
                    iconProps={{
                      fontSize: 'small',
                    }}
                    icon='close'
                    onClick={(event) => {
                      onClose({ event, reason: '' });
                    }}
                  />
                )}
              </Stack>
            )}
            {titleComponent}
          </Stack>
        )}

        <ConditionalWrap
          condition={!!onSubmit}
          wrap={(wrappedChildren) => (
            <Stack width={1} flex={1} component='form' noValidate onSubmit={onSubmit}>
              {wrappedChildren}
            </Stack>
          )}
        >
          <DialogContent {...contentProps}>
            <Stack py={1} width={1}>
              {children}
            </Stack>
          </DialogContent>
          {(cancelButton || secondaryAction || mainAction) && (
            <DialogActions
              sx={{
                p: 2,
                position: 'sticky',
                bottom: 0,
                bgcolor: 'background.paper',
                zIndex: 1,
                justifyContent: !secondaryAction && !mainAction ? 'flex-start' : 'flex-end',
              }}
            >
              {cancelButton && (
                <Button
                  size='large'
                  color='inherit'
                  onClick={(event) => {
                    onClose({ event, reason: '' });
                  }}
                >
                  {cancelText}
                </Button>
              )}

              {secondaryAction && (
                <Button
                  variant='outlined'
                  disabled={secondaryIsSubmitting || secondaryDisabled}
                  size='large'
                  startIcon={
                    secondaryIsSubmitting ? <CircularProgress color='inherit' size='1rem' /> : null
                  }
                  {...secondaryRest}
                >
                  {secondaryIsSubmitting ? submittingText : secondaryChildren}
                </Button>
              )}
              {mainAction && (
                <Button
                  variant='contained'
                  disabled={isSubmitting || disabled}
                  size='large'
                  color='primary'
                  startIcon={isSubmitting ? <CircularProgress color='inherit' size='1rem' /> : null}
                  {...rest}
                >
                  {isSubmitting ? submittingText : actionChildren}
                </Button>
              )}
            </DialogActions>
          )}
        </ConditionalWrap>
        {customActions}
      </Dialog>
    );
  },
);

export default BasicModal;
