import { Stack, SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useLocalStorage } from 'usehooks-ts';
import { AppLoader } from '@common-components';
import { ApiKey } from 'api';
import { LocalStorageKey } from 'config';
import { RoutePath } from 'enums';
import { useIsMobile, useSearch, useTableHeight } from 'hooks';
import { useGetChatGroupMessages } from 'hooks/api/messenger/useGetChatGroupMessages';
import { iOS } from 'pages/app-pages/chatbot-builder-page/toolbox/props-config';
import GroupsDrawer from 'pages/app-pages/messages-scheduler-page/groups-drawer/GroupsDrawer';
import {
  FormMode,
  GroupMessageFormLocalState,
} from 'pages/app-pages/messages-scheduler-page/message-modal/types';
import MessagesTableWrapper from 'pages/app-pages/messages-scheduler-page/MessagesTableWrapper';
import { sortByMenuItems } from 'pages/app-pages/messages-scheduler-page/SortByMessages';
import { useGroupsDrawer } from 'pages/app-pages/messages-scheduler-page/store/useGroupsDrawer';
import SchedulerTopBar from 'pages/app-pages/messages-scheduler-page/top-bar/SchedulerTopBar';
import { MessageColumns } from 'pages/app-pages/messages-scheduler-page/types';
import { Project } from 'types';

const drawerWidth = 360;

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  backgroundColor: theme.palette.grey[50],
  flexGrow: 1,
  overflowX: isMobile ? 'visible' : 'clip',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: isMobile ? 0 : `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const MessagesSchedulerPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const { openDrawer, toggleDrawer, drawerOpen, closeDrawer } = useGroupsDrawer((state) => ({
    closeDrawer: state.closeDrawer,
    openDrawer: state.openDrawer,
    toggleDrawer: state.toggleDrawer,
    drawerOpen: state.drawerOpen,
  }));

  const gridTableHeight = useTableHeight({ subtract: -24 });

  const { data: project, isLoading } = useSWR<Project>(`${ApiKey.Project}/${id}`, {
    onError: (error) => {
      if (error?.response?.status === 404) {
        navigate(`/${RoutePath.NotFound}`, { replace: true });
      }
    },
  });

  const { chatGroupMessages, isLoading: isMessagesLoading } = useGetChatGroupMessages({
    projectId: id || '',
  });

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    LocalStorageKey.MessagesViewSort,
    [
      {
        field: MessageColumns.UpdatedAt,
        sort: 'desc',
      },
    ],
  );

  const navigateToOnboarding = useCallback(() => {
    if (isLoading || isMessagesLoading) {
      return;
    }

    if (!project?.token && isEmpty(project?.chatGroups)) {
      navigate(RoutePath.Onboarding, { replace: true });
    }
  }, [isLoading, isMessagesLoading, navigate, project?.chatGroups, project?.token]);

  useEffect(() => {
    navigateToOnboarding();
  }, [navigateToOnboarding]);

  const {
    search,
    handleSearchChange,
    filteredItems: filteredMessages,
    //  noSearchResults,
    //  resetSearch,
  } = useSearch({
    data: chatGroupMessages,
    searchKey: ['message'],
  });

  const messagesConfig = sortByMenuItems.find((item) => item.field === sortModel[0].field);
  const currentSortDirection = sortModel[0].sort;

  const filteredAndSortedMessages =
    filteredMessages.sort(
      currentSortDirection === 'asc'
        ? messagesConfig?.sorter
        : (a, b) => messagesConfig?.sorter(b, a) || 0,
    ) || [];

  const onSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <>
      <Stack width={1} height={1}>
        <SchedulerTopBar name={project?.name || ''} />
        <Stack
          flex={1}
          direction={isMobile ? 'column' : 'row'}
          sx={{
            overflowX: 'clip',
          }}
        >
          <Main open={drawerOpen} isMobile={isMobile}>
            <MessagesTableWrapper
              onCreateMessage={() => {
                if (!project?.token || isEmpty(project?.chatGroups)) {
                  navigate(RoutePath.Onboarding, { replace: true });
                } else {
                  navigate(RoutePath.Composer, {
                    replace: true,
                    state: {
                      projectId: id,
                      mode: FormMode.Create,
                      chatGroups: project?.chatGroups || [],
                      initialValues: {
                        project: id,
                      },
                    } as GroupMessageFormLocalState,
                  });
                }
              }}
              handleSearchChange={handleSearchChange}
              search={search}
              toggleDrawer={toggleDrawer}
              drawerIsOpen={drawerOpen}
              isLoading={isLoading}
              messages={filteredAndSortedMessages}
              onSortModelChange={onSortModelChange}
              sortModel={sortModel}
            />
          </Main>
          <SwipeableDrawer
            anchor={isMobile ? 'bottom' : 'right'}
            variant={isMobile ? 'temporary' : 'persistent'}
            className='notranslate'
            disableBackdropTransition={!iOS}
            disableDiscovery
            disableSwipeToOpen
            open={drawerOpen}
            onOpen={openDrawer}
            sx={{
              //  order: isMobile ? 1 : 0,
              width: isMobile ? '100%' : drawerWidth,
              height: isMobile ? '80%' : gridTableHeight,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                borderTopLeftRadius: isMobile ? 8 : 0,
                borderTopRightRadius: isMobile ? 8 : 0,
                width: isMobile ? '100%' : drawerWidth,
                boxSizing: 'border-box',
                position: isMobile ? 'inherit' : 'static',
              },
            }}
            // @ts-ignore
            onClose={(_, reason) => {
              if (reason === 'backdropClick') {
                closeDrawer();
              } else {
                closeDrawer();
              }
            }}
          >
            <GroupsDrawer />
          </SwipeableDrawer>
        </Stack>
      </Stack>
      <Outlet />
    </>
  );
};

export default MessagesSchedulerPage;
