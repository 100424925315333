import { Chip, CircularProgress, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { mutate } from 'swr';
import { useBoolean } from 'usehooks-ts';
import { api, ApiKey } from 'api';
import { SingleTextFieldEditorFrom } from 'forms/index';
import { useFormProvider, useServerError, useToast } from 'hooks';
import { UserLabel } from 'types';
import { getRandomColor } from 'utils';
import { UserLabelFieldsNames, UserLabelFormFields } from './types';
import { userLabelDefaultValues, userLabelFieldsConfig, userLabelSchema } from './utils';

interface LabelEditorProps {
  label?: UserLabel;
  icon: string;
}

const iconSize = 16;
const LabelEditor = ({ label, icon }: LabelEditorProps) => {
  const toastServerError = useServerError();
  const toast = useToast();
  const theme = useTheme();

  const { value: isDeleting, setTrue: setIsDeleting, setFalse: setIsNotDeleting } = useBoolean();

  const formMethods = useFormProvider<UserLabelFormFields>({
    reValidateMode: 'onChange',
    schema: userLabelSchema,
    defaultValues: {
      name: label?.name || userLabelDefaultValues.name,
      color: label?.color || userLabelDefaultValues.color,
      isDisabled: label?.isDisabled || userLabelDefaultValues.isDisabled,
    },
  });

  const handleOnSubmit = async (formData: UserLabelFormFields) => {
    try {
      if (label?.id) {
        await api.patch<UserLabel>(`${ApiKey.UserLabel}/${label.id}`, formData);
      } else {
        await api.post<UserLabel>(`${ApiKey.UserLabel}`, {
          ...formData,
          icon: 'label',
          color: getRandomColor(),
        });
      }
      await mutate(ApiKey.UserLabel);

      toast({
        message: label?.id ? 'Label updated successfully' : 'Label created successfully',
        variant: 'success',
      });
    } catch (error) {
      toastServerError(error);
    }
  };

  const onDelete = async () => {
    setIsDeleting();
    try {
      await api.patch(`${ApiKey.UserLabel}/${label?.id}`, { isDisabled: true });
      await mutate(ApiKey.UserLabel);
      setIsNotDeleting();
    } catch (error) {
      toastServerError(error);
      setIsNotDeleting();
    }
  };

  const { name } = userLabelFieldsConfig;

  return (
    <FormProvider {...formMethods}>
      <Chip
        size='small'
        disabled={isDeleting}
        sx={{ backgroundColor: label?.color }}
        // icon={<Icon icon={label?.icon} fontSize='small' />}
        onDelete={label && !label?.type ? onDelete : undefined}
        deleteIcon={
          isDeleting ? (
            <CircularProgress
              sx={{ mr: '10px !important', ml: '-5px !important' }}
              size={iconSize}
            />
          ) : undefined
        }
        label={
          <SingleTextFieldEditorFrom
            fieldProps={{
              tooltipError: true,
              dynamicWidth: true,
              allowEmpty: true,
              icon,
              tooltip: label?.type ? 'This label is system generated and cannot be edited' : '',
              tooltipPlacement: 'top',
              disabled: !!label?.type,
              name: UserLabelFieldsNames.Name,
              placeholder: name.placeholder,
              autoComplete: name.autoComplete,
              autoFocus: true,
              textVariant: 'caption',
              fullWidth: true,
              textProps: {
                fontWeight: 'bolder',
              },
              sx: {
                '& .MuiInputBase-input': {
                  ...theme.typography.caption,
                  fontWeight: 'bolder',
                },
              },
              iconSize,
            }}
            schema={userLabelSchema}
            onSubmit={handleOnSubmit}
            defaultValues={label}
          />
        }
      />
    </FormProvider>
  );
};

export default LabelEditor;
